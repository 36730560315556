import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { Header } from '../components/header';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { SurveyActionType } from '../../../code/survey/survey';
import { geocodeAddress } from '../../../code/geocoding';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const ConfirmLocationStep = ({ adminEstimate }) => {
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [geocodeInProgress, setGeocodeInProgress] = useState(false);
    const initialLatLng = { lat: survey.lead.property.postcodeLocation?.[0] || 0, lng: survey.lead.property.postcodeLocation?.[1] || 0 };
    const [latLng, setLatLng] = React.useState({ lat: initialLatLng.lat, lng: initialLatLng.lng });
    const handleOnDragEnd = (event) => {
        setLatLng({ lat: event.latLng?.lat() || initialLatLng.lat, lng: event.latLng?.lng() || initialLatLng.lng });
    };
    // Do I have a lat long from my address lookup?
    // If so, show that on the map
    // If not, get the lat/long from the geocode.
    useEffect(() => {
        const getLatLngFromPostcode = async () => {
            setGeocodeInProgress(true);
            const result = await geocodeAddress(survey.lead.property.address, survey.lead.property.postcode);
            if (result) {
                setLatLng({ lat: result.lat, lng: result.lng });
            }
            setGeocodeInProgress(false);
        };
        if (initialLatLng.lat === 0 && initialLatLng.lng === 0) {
            // No address. Get the lat long from postcode.
            getLatLngFromPostcode();
        }
    }, []);
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                lead: {
                    ...survey.lead,
                    property: {
                        ...survey.lead.property,
                        postcodeLocation: [latLng.lat, latLng.lng]
                    }
                }
            }
        });
    };
    if (geocodeInProgress) {
        return _jsx(Loader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsxs("div", { className: "flex flex-col gap-3", children: [_jsx(Header, { content: `Confirm the location of ${adminEstimate ? 'the' : 'your'} property` }), _jsxs("div", { className: "text-gray-600", children: ["Drag the pin on the map below to the exact location of ", adminEstimate ? 'the' : 'your', " property."] })] }), _jsx("div", { className: "space-y-3", children: latLng &&
                    _jsx(Map, { defaultZoom: 18, className: "h-[400px] w-full rounded overflow-hidden", mapTypeId: "satellite", defaultCenter: latLng, children: _jsx(Marker, { position: latLng.lat === 0 && latLng.lng === 0 ? initialLatLng : latLng, draggable: true, onDragEnd: handleOnDragEnd }) }) }), _jsx(Button, { size: 'LG', onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Confirm location" }), _jsx(ChevronRightIcon, { className: "h-5 w-5" })] }) })] }));
};
