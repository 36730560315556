import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { acceptInvitation, getInvitationByToken } from '../code/models/invitations';
import { getUser } from '../code/models/user';
import { validateNotNull, validatePassword, validatePasswordMatch } from '../code/validators';
import { SpruceLogoWhite } from '../assets/images/spruce_logo/spruce_logo';
import { AuthContext, AuthSDK, AuthProvider } from '../code/utils/auth_provider';
import { Button } from '../components/buttons/button';
import { Section } from '../components/containers/section';
import { Card } from '../components/content_display/card';
import { Loader } from '../components/indicators_and_messaging/loader';
import { Input } from '../components/inputs_and_selections/input';
const INVALID_ERROR_MESSAGE = 'This invitation no longer exists. Please request a new invitation.';
const SignedInForm = ({ handleAcceptInvitation, email, context }) => {
    const auth = useContext(AuthContext);
    const [user, setUser] = useState(undefined);
    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();
            setUser(user);
        };
        fetchUser();
    }, []);
    if (email !== user?.email) {
        return (_jsxs("div", { className: 'flex flex-col items-center', children: [_jsx("div", { className: 'pb-4 text-sm', children: _jsx("div", { children: "You need to sign out of your current account to accept this invitation." }) }), _jsx(Button, { onClick: () => auth.signOut(() => window.location.reload()), className: "mt-4", children: "Sign Out" })] }));
    }
    return (_jsxs("div", { className: 'flex flex-col items-center', children: [_jsx("div", { className: 'pb-4 text-sm', children: _jsxs("div", { children: ["You've been invited to access a ", context, " on Spruce."] }) }), _jsx(Button, { onClick: () => handleAcceptInvitation({ email: user.email, first_name: user.first_name, last_name: user.last_name }), className: "mt-4", children: "Accept Invitation" })] }));
};
const SignedOutForm = ({ handleAcceptInvitation, email, context }) => {
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const isValidForm = [validatePassword(password), validatePasswordMatch(password, passwordRepeated), validateNotNull(firstName), validateNotNull(lastName)].every(x => x.value);
    const onSubmit = () => {
        handleAcceptInvitation({ email, password, first_name: firstName, last_name: lastName });
    };
    return (_jsxs("div", { children: [_jsx("div", { className: 'pb-4 text-sm', children: _jsxs("div", { children: ["You've been invited to access a ", context, " on Spruce. Please create an account for the email address ", _jsx("span", { className: 'font-bold', children: email }), " to continue."] }) }), _jsxs("div", { className: "space-y-4", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Email" }), _jsx(Card, { color: 'DARK', children: _jsx("div", { className: "text-sm text-gray-500", children: email }) })] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Password" }), _jsx(Input, { onEnter: onSubmit, type: "password", value: password, setValue: (e) => {
                                    setPassword(e);
                                }, placeholder: "Enter your password", validator: validatePassword })] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Repeat your password" }), _jsx(Input, { onEnter: onSubmit, type: "password", value: passwordRepeated, setValue: (e) => {
                                    setPasswordRepeated(e);
                                }, placeholder: "Enter your password again", validator: (value) => validatePasswordMatch(value, password) })] }), _jsxs("div", { className: "flex space-x-4", children: [_jsxs("div", { className: "space-y-2 w-1/2", children: [_jsx("div", { className: "font-semibold", children: "First Name" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: firstName, setValue: (e) => {
                                            setFirstName(e);
                                        }, placeholder: "Enter your first name", validator: validateNotNull })] }), _jsxs("div", { className: "space-y-2 w-1/2", children: [_jsx("div", { className: "font-semibold", children: "Last Name" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: lastName, setValue: (e) => {
                                            setLastName(e);
                                        }, placeholder: "Enter your last name", validator: validateNotNull })] })] }), _jsx(Button, { disabled: !isValidForm, block: true, onClick: onSubmit, children: "Sign Up" })] })] }));
};
const AcceptInvitationPageInner = ({ token, navigateTo, context }) => {
    const authContext = useContext(AuthContext);
    const [invitedUser, setInvitedUser] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const handleAcceptInvitation = async ({ email, password, first_name, last_name }) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        await acceptInvitation({ email, password, first_name, last_name, token });
        if (!authContext.isSignedIn()) {
            await AuthSDK.signIn(email, password);
        }
        // Redirect to the all surveys page
        navigateTo('/admin/enquiries');
    };
    useEffect(() => {
        const fetchInvitation = async () => {
            if (!token) {
                console.error('No token found');
                return;
            }
            try {
                const invitedUser = await getInvitationByToken(token);
                if (!invitedUser) {
                    throw new Error('No invitation found');
                }
                setInvitedUser(invitedUser);
            }
            catch (e) {
                setError(INVALID_ERROR_MESSAGE);
            }
            finally {
                setLoading(false);
            }
        };
        fetchInvitation();
    }, []);
    if (error) {
        return (_jsx("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto", children: _jsx(Section, { border: true, title: "Accept Invitation", children: _jsx("div", { children: error }) }) }));
    }
    if (loading || !invitedUser) {
        return (_jsx("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto", children: _jsx(Section, { border: true, title: "Accept Invitation", children: _jsx(Loader, {}) }) }));
    }
    // If the invitation has already been accepted, but we're not signed in, redirect to the sign in page
    if (!authContext.isSignedIn() && invitedUser.status === 'active') {
        navigateTo('/login');
        return;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "w-full bg-gray-900 flex items-center justify-between p-4", children: _jsx("img", { alt: "Spruce logo", className: "w-32 h-12", src: SpruceLogoWhite }) }), _jsx("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto", children: _jsx(Section, { border: true, title: "Accept Invitation", children: authContext.isSignedIn()
                        ? _jsx(SignedInForm, { handleAcceptInvitation: handleAcceptInvitation, email: invitedUser.email, context: context })
                        : _jsx(SignedOutForm, { handleAcceptInvitation: handleAcceptInvitation, email: invitedUser.email, context: context }) }) })] }));
};
export const AcceptInvitationPage = ({ token, navigateTo, context }) => {
    if (context !== 'job' && context !== 'company') {
        return null;
    }
    return (_jsx(AuthProvider, { children: _jsx(AcceptInvitationPageInner, { token: token, navigateTo: navigateTo, context: context }) }));
};
