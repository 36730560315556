import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCompanyBookingConfirmationActionEmailTemplate, getCompanyBookingConfirmationNoActionEmailTemplate } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName } from '../../../../../code/tiptap_placeholders';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
const BookingConfirmationWithAction = ({ company, setCompany }) => {
    return _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Message', helperText: 'Book in directly' }), input: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                setCompany({
                    ...company,
                    booking_confirmation_action_template: JSON.stringify(editor.getJSON())
                });
            }, placeholders: [
                TTPlaceholderCustomerFirstName,
                TTPlaceholderCustomerLastName,
                ...defaultPlaceholders
            ], content: getCompanyBookingConfirmationActionEmailTemplate(company.booking_confirmation_action_template ?? '') }) });
};
const BookingConfirmationWithoutAction = ({ company, setCompany }) => {
    return _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Message', helperText: 'Wait for callback' }), input: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                setCompany({
                    ...company,
                    booking_confirmation_noaction_template: JSON.stringify(editor.getJSON())
                });
            }, placeholders: [
                TTPlaceholderCustomerFirstName,
                TTPlaceholderCustomerLastName,
                ...defaultPlaceholders
            ], content: getCompanyBookingConfirmationNoActionEmailTemplate(company.booking_confirmation_noaction_template ?? '') }) });
};
export const BookingConfirmationOptionsBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: "Booking survey options", children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(HorizontalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Take user straight to booking link' }), input: _jsx(Toggle, { value: company.booking_redirect_enabled ?? false, setValue: async () => setCompany({
                            ...company,
                            booking_redirect_enabled: !company.booking_redirect_enabled
                        }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Booking redirect URL' }), input: _jsx(Input, { validator: (value) => value?.length > 0 ? { value, message: '' } : {
                            value: undefined,
                            message: 'Please enter a valid URL'
                        }, disabled: !company.booking_redirect_enabled, placeholder: 'Enter url here', value: company.booking_redirect_url ?? '', setValue: url => setCompany({
                            ...company,
                            booking_redirect_url: url
                        }) }) }), company.booking_redirect_enabled
                    ? _jsx(BookingConfirmationWithAction, { company: company, setCompany: setCompany })
                    : _jsx(BookingConfirmationWithoutAction, { company: company, setCompany: setCompany })] }) });
};
