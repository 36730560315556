import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { fileToBase64, proxyImageURL } from '../../code/helpers';
import { noop } from 'lodash';
import { Icon } from '../buttons/icon';
import { createPortal } from 'react-dom';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { Camera, Plus, Wifi, XCircle } from 'lucide-react';
export const MAX_IMAGE_WIDTH = 1800;
export const MAX_IMAGE_HEIGHT = 1800;
export const PhotoScroll = ({ images, deleteImage, addImage, doNotScale, companyUUID, viewMode }) => {
    const [currentImageId, setCurrentImageId] = useState(undefined);
    // sometimes we got inconsistent data, so we need to filter out undefined images
    const cleanedImages = images.filter(x => x !== undefined);
    const currentImage = cleanedImages.find(x => x.uuid === currentImageId);
    const randomUUID = crypto.randomUUID();
    const handleFileSelect = addImage ? async (e) => {
        const selectedFile = e.target?.files?.[0];
        if (!selectedFile)
            return;
        const maxFileSize = 10 * 1024 * 1024; // 10mb
        if (selectedFile.size > maxFileSize) {
            alert('File size exceeds the 10 MB limit. Please upload a smaller file.');
            return;
        }
        // if doNotScale is true, we don't need to scale the image
        if (doNotScale) {
            const base64 = await fileToBase64(selectedFile);
            const newFileWrapper = selectedFile && {
                uuid: crypto.randomUUID(),
                file_base64: base64,
                created_at: new Date().getTime(),
                updated_at: new Date().getTime(),
                is_modified: true,
                server_updated_at: 0,
                deleted_at: undefined,
                company_uuid: companyUUID
            };
            newFileWrapper && addImage(newFileWrapper);
            return;
        }
        // otherwise, we need to scale the image
        // change image size
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        // Ensure image is loaded before drawing
        image.onload = () => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            // Maintain aspect ratio and scale if necessary
            if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = MAX_IMAGE_WIDTH;
                    height = MAX_IMAGE_WIDTH / aspectRatio;
                }
                else {
                    height = MAX_IMAGE_HEIGHT;
                    width = MAX_IMAGE_HEIGHT * aspectRatio;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            if (!context)
                return;
            context.drawImage(image, 0, 0, width, height);
            const base64 = canvas.toDataURL();
            const newFileWrapper = {
                uuid: crypto.randomUUID(),
                file_base64: base64,
                file_url: undefined,
                created_at: new Date().getTime(),
                updated_at: new Date().getTime(),
                is_modified: true,
                server_updated_at: 0,
                deleted_at: undefined,
                company_uuid: companyUUID
            };
            addImage(newFileWrapper);
        };
    } : noop;
    const isCompactMode = viewMode === 'COMPACT' && cleanedImages.length === 0;
    return _jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex w-full overflow-auto gap-2', children: [addImage && _jsxs(_Fragment, { children: [_jsxs("label", { htmlFor: `dropzone-file-${randomUUID}`, children: [isCompactMode &&
                                        _jsxs("div", { className: "px-4 py-3 rounded-md border border-gray-300 flex gap-2 items-center cursor-pointer", children: [_jsx(WrappedIcon, { className: 'text-sm', icon: Plus }), _jsx("div", { className: "text-sm font-bold", children: "Add photos" })] }), !isCompactMode &&
                                        _jsxs("div", { className: 'flex flex-col gap-2 w-32 h-32 bg-gray-100 items-center justify-center cursor-pointer text-gray-600 rounded-lg', children: [_jsx(WrappedIcon, { className: 'text-lg', icon: Camera }), _jsx("div", { children: "Add photo" })] })] }), _jsx("input", { id: `dropzone-file-${randomUUID}`, className: 'hidden', type: "file", accept: 'image/*', onChange: handleFileSelect })] }), cleanedImages.reverse().map(x => _jsx(ImageWithFallback, { fileWrapper: x, deleteImage: deleteImage, setCurrentImageId: setCurrentImageId }, x.uuid))] }), currentImage && createPortal(_jsx("div", { onMouseDown: (e) => e.target === e.currentTarget ? setCurrentImageId(undefined) : noop, className: 'fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-20', children: _jsx("img", { className: 'rounded-lg max-h-full max-w-full object-contain', src: currentImage.file_base64 || `${process.env.S3_BUCKET_URL}/${currentImage.file_url}` }) }), document.body)] });
};
const ImageWithFallback = ({ deleteImage, fileWrapper, setCurrentImageId }) => {
    const [fallback, setFallback] = useState(false);
    return _jsxs("div", { className: 'relative flex-shrink-0', children: [fallback && _jsxs("div", { className: 'rounded-lg h-32 flex flex-col gap-1 items-center justify-center bg-gray-200 p-4', children: [_jsx(Icon, { icon: Wifi, className: 'mb-2' }), _jsx("div", { className: 'text-sm font-bold text-gray-900', children: "Image not available" }), _jsxs("div", { className: 'text-sm text-gray-600', children: ["Images will reappear ", _jsx("br", {}), "when you are online"] })] }), deleteImage && !fallback && _jsx(Icon, { size: 'w-8 h-8', colour: 'text-white', onClick: () => deleteImage(fileWrapper.uuid), className: 'cursor-pointer absolute top-0 right-0 z-10 p-2', icon: XCircle }), !fallback && _jsx("img", { className: 'rounded-lg h-32 cursor-pointer', onError: () => setFallback(true), onClick: () => setCurrentImageId(fileWrapper.uuid), src: fileWrapper.file_base64 || proxyImageURL('x128', fileWrapper.file_url ?? '') })] }, fileWrapper.uuid);
};
export const resizeBase64Image = async (base64Str, maxWidth = MAX_IMAGE_WIDTH, maxHeight = MAX_IMAGE_HEIGHT) => {
    return await new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            let { width, height } = image;
            // Calculate new dimensions while maintaining aspect ratio
            if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = maxWidth;
                    height = maxWidth / aspectRatio;
                }
                else {
                    height = maxHeight;
                    width = maxHeight * aspectRatio;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            const resizedBase64 = canvas.toDataURL('image/png');
            resolve(resizedBase64);
        };
        image.onerror = (error) => reject(error);
        image.src = base64Str;
    });
};
