import { getFloorAreaM2, getRoomTemp, getRoomWatts } from './heat_loss';
import { PIPE_MODELS, getVelocityInPipeMPerS } from './pipes';
import { getUnderfloorHeatingOutput } from './underfloor_heating';
export const getRadiatorWatts = (radiator, flowTemp, deltaTFlowReturnC, roomTemp) => {
    if (!radiator)
        return 0;
    const meanRadiatorTemperatureC = flowTemp - deltaTFlowReturnC / 2;
    const deltaTRadiatorAirC = meanRadiatorTemperatureC - roomTemp;
    const deratingFactor = Math.pow(deltaTRadiatorAirC / 50, radiator.n_factor);
    const outputCapacityDerated = radiator.output_capacity_w_at_50_c * deratingFactor;
    const bottomOppositeEnds = 0.96; // assume bottom opposite ends in all cases for now
    return Math.round(outputCapacityDerated * bottomOppositeEnds);
};
export const getEmitterWatts = (em, r, design, survey, designTempC, groundTempC) => {
    const floor = survey.floors.find(x => x.rooms.some(y => y.uuid === r.uuid));
    if (em.emitter_type === 'SECONDARY')
        return ((em.room_heating_load_covered_percentage ?? 0) / 100) * getRoomWatts(r, floor.rooms, designTempC, groundTempC, survey);
    if (em.emitter_type === 'UNDERFLOOR')
        return getUnderfloorHeatingOutput(em, survey.manifolds, getRoomTemp(r, survey), getFloorAreaM2(r.walls), design.flow_temp, design.delta_t_flow_return_c).watts;
    return getRadiatorWatts(em.radiator_type, design.flow_temp, design.delta_t_flow_return_c, getRoomTemp(r, survey));
};
export const getEmitterVelocityMPerS = (emitter, watts, deltaTFlowReturnC, flowTempC) => {
    if (emitter.emitter_type === 'SECONDARY')
        return 0;
    const pipeModel = PIPE_MODELS.find(x => x.uuid === emitter?.pipe_model_uuid);
    if (!pipeModel)
        return 0;
    const velocityMPerS = getVelocityInPipeMPerS(watts, pipeModel, deltaTFlowReturnC, flowTempC)?.velocityMPerS;
    return velocityMPerS;
};
export const RADIATOR_MODELS = [
    { uuid: '1c2589dc-1847-4670-9b02-383220d0b1e1', height_mm: 650, width_mm: 400, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 174, n_factor: 1.21 },
    { uuid: 'd6d8b38b-5670-45ab-b4fd-6650e921c9a2', height_mm: 678, width_mm: 400, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 240, n_factor: 1.2535 },
    { uuid: 'b1863262-92a2-4ec6-8e06-47ec81deedb7', height_mm: 760, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 366, n_factor: 1.2519 },
    { uuid: '901d3d9d-5689-4506-87f1-3d004c4d9887', height_mm: 760, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 305, n_factor: 1.2545 },
    { uuid: 'edc6ab92-cb92-4ea3-a1b5-089e36fc073d', height_mm: 803, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 236, n_factor: 1.18 },
    { uuid: '0a51b0c5-fcd9-4d83-9189-cb743165ae92', height_mm: 803, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 268, n_factor: 1.19 },
    { uuid: '69eca750-855d-4fa5-8732-4eff616424ab', height_mm: 1188, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 345, n_factor: 1.2 },
    { uuid: '4d26a96c-b0e0-4a5f-bcd2-f1015fa6deb2', height_mm: 1188, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 402, n_factor: 1.21 },
    { uuid: '01f6a3a7-5614-41ca-82ca-608358023b20', height_mm: 1211, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 454, n_factor: 1.2757 },
    { uuid: '385e3844-ccd9-4009-8411-c58082bb6ae6', height_mm: 1211, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 539, n_factor: 1.2726 },
    { uuid: '209e9b85-b9da-4df4-b519-9e8d8cea3946', height_mm: 1600, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 467, n_factor: 1.23 },
    { uuid: '8281389a-0a7a-4288-964a-a85976e3760a', height_mm: 1600, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 548, n_factor: 1.23 },
    { uuid: '9bd96484-3ebe-4202-98a9-628a99111873', height_mm: 1744, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 670, n_factor: 1.3015 },
    { uuid: 'b72a4b08-6a8b-41be-a37b-5ee304e47cfe', height_mm: 1744, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 792, n_factor: 1.2978 },
    { uuid: 'ae8ee310-03e4-4b00-9bf6-cbc4fd70672a', height_mm: 1785, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 524, n_factor: 1.24 },
    { uuid: '9c9406ae-b8e6-478f-8b95-3f2b26a992c1', height_mm: 1785, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight chrome', output_capacity_w_at_50_c: 614, n_factor: 1.24 },
    { uuid: '6b1f4aa8-f98f-4b22-a045-d57f93a35a20', height_mm: 760, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 393, n_factor: 1.2519 },
    { uuid: 'ac38eb0d-5cbb-48e0-b6db-77ec68078918', height_mm: 760, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 328, n_factor: 1.2545 },
    { uuid: 'b213be8e-1861-4441-97df-e07bbf27a61b', height_mm: 1211, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 579, n_factor: 1.2726 },
    { uuid: '1301f937-734a-4d12-97ac-89853fafe3e7', height_mm: 1211, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 488, n_factor: 1.2757 },
    { uuid: 'cfdc44aa-68d7-42b8-90b2-a1ef3c54fe0b', height_mm: 1744, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 852, n_factor: 1.2978 },
    { uuid: '71094435-b6c3-483d-bbc5-b050938f6961', height_mm: 1744, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved chrome', output_capacity_w_at_50_c: 720, n_factor: 1.3015 },
    { uuid: '38a4a61e-7b42-4085-a1c1-906a815ec31f', height_mm: 678, width_mm: 400, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 280, n_factor: 1.2232 },
    { uuid: 'ac797878-fd35-4c91-919d-5a4ed7b9a763', height_mm: 760, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 367, n_factor: 1.232 },
    { uuid: '6a9d9a0d-458f-45a8-84f9-beba33ad5bec', height_mm: 760, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 439, n_factor: 1.2332 },
    { uuid: '06394b30-4889-4f77-90f5-c483ca2d8ed4', height_mm: 1211, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 545, n_factor: 1.2528 },
    { uuid: '7c40bd7a-8830-41ce-af78-6a761d4c3920', height_mm: 1211, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 646, n_factor: 1.2464 },
    { uuid: 'f53d7320-1aa0-48f6-8241-fb37ab916fd4', height_mm: 1744, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 951, n_factor: 1.2625 },
    { uuid: 'ff2c8efd-7c08-43a2-aca0-13d999ce09d7', height_mm: 1744, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Straight white', output_capacity_w_at_50_c: 804, n_factor: 1.2783 },
    { uuid: '3586bee1-af2c-4d4f-b034-9a71999c3bfc', height_mm: 760, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 394, n_factor: 1.232 },
    { uuid: '329a4e3c-3ba7-4bd4-bb01-099a09e32983', height_mm: 760, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 472, n_factor: 1.2332 },
    { uuid: '48ec7e6b-88f4-4386-8be1-a71980466567', height_mm: 1211, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 586, n_factor: 1.2528 },
    { uuid: 'c5f57f20-86c2-46a3-8c8d-857be761ae5e', height_mm: 1211, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 695, n_factor: 1.2464 },
    { uuid: '6a607092-3d22-4ac5-a336-0fa830c33ae9', height_mm: 1744, width_mm: 500, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 864, n_factor: 1.2783 },
    { uuid: '70fb2f70-4f63-477b-b62c-596ae9573ffc', height_mm: 1744, width_mm: 600, category: 'Towel rail', type: 'Towel rail - Curved white', output_capacity_w_at_50_c: 1022, n_factor: 1.2625 },
    { uuid: '2632cc91-ce4b-4e33-6649-708745070870', height_mm: 300, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 98, n_factor: 1.3 },
    { uuid: 'c86926a3-c6ba-e06d-b21d-b81a0e855bca', height_mm: 300, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 131, n_factor: 1.3 },
    { uuid: '5bfc6eed-10d3-2bbf-07cb-84c81a05c1ce', height_mm: 300, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 164, n_factor: 1.3 },
    { uuid: 'c8d5aff0-1c37-4d16-a599-193a6c85b35e', height_mm: 300, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 196, n_factor: 1.3 },
    { uuid: '6bf8e8d0-57ce-169c-d628-d7f6d5f98630', height_mm: 300, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 229, n_factor: 1.3 },
    { uuid: '29a5f0de-de8c-3e6e-142f-700972f2cc16', height_mm: 300, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 262, n_factor: 1.3 },
    { uuid: 'b63d75be-2b04-a07e-ec18-c632568e5443', height_mm: 300, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 294, n_factor: 1.3 },
    { uuid: 'd0663f7e-4e87-6345-07cf-eeb06add552a', height_mm: 300, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 327, n_factor: 1.3 },
    { uuid: '517ad921-68d1-1dc8-2e55-e9f67b6d5e8b', height_mm: 300, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 360, n_factor: 1.3 },
    { uuid: '51b74d7f-bd34-549e-ae6b-bc93740f4b45', height_mm: 300, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 392, n_factor: 1.3 },
    { uuid: '658c2aff-9663-6835-d403-47847989da99', height_mm: 300, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 425, n_factor: 1.3 },
    { uuid: 'f940b986-ec58-88f7-fc91-10ef983aae43', height_mm: 300, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 458, n_factor: 1.3 },
    { uuid: '693eabb9-0755-a788-2ed8-89d6b7870882', height_mm: 300, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 491, n_factor: 1.3 },
    { uuid: '894566b9-d1d5-5925-f500-e6ca4f3760db', height_mm: 300, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 523, n_factor: 1.3 },
    { uuid: '1d582952-f0ac-b893-5b4d-96c384e55423', height_mm: 300, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 556, n_factor: 1.3 },
    { uuid: '3010534a-f256-3a0b-1fb3-b4213c171ede', height_mm: 300, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 589, n_factor: 1.3 },
    { uuid: '1bc6a17b-dfb7-6dd7-2cc6-d64db7f5cbfc', height_mm: 300, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 621, n_factor: 1.3 },
    { uuid: 'a980afc9-3900-2c45-4c47-3abc793f3c7e', height_mm: 300, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 654, n_factor: 1.3 },
    { uuid: '1b83194b-260c-53bc-5de4-3d9d453e7952', height_mm: 300, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 687, n_factor: 1.3 },
    { uuid: '8cdfb4f4-d5aa-16de-f1ec-0bf1c193e005', height_mm: 300, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 719, n_factor: 1.3 },
    { uuid: '9ac5c31a-a3b4-dd77-b00b-59fb58c8b15d', height_mm: 300, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 752, n_factor: 1.3 },
    { uuid: '7ec60163-a15a-0b94-ebdb-5e360b2290a5', height_mm: 300, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 785, n_factor: 1.3 },
    { uuid: 'd8560ece-98d4-6c2f-09b7-b623da7ecb69', height_mm: 300, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 818, n_factor: 1.3 },
    { uuid: '33bfd972-4542-cb49-7336-d00fe09b0679', height_mm: 300, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 850, n_factor: 1.3 },
    { uuid: '300bb65d-f05c-8f5f-c787-d837a3abba46', height_mm: 300, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 883, n_factor: 1.3 },
    { uuid: 'aa5bd503-38f9-070d-0701-4891a517dd6a', height_mm: 300, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 916, n_factor: 1.3 },
    { uuid: '93fc64d3-7464-4b5b-e33f-66934eb1f7b5', height_mm: 300, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 948, n_factor: 1.3 },
    { uuid: 'cc627aea-c97d-d5d9-a8f0-92dbe24337ea', height_mm: 300, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 981, n_factor: 1.3 },
    { uuid: '1e0854a2-b5bf-8d1c-2149-74c953d17919', height_mm: 400, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 124, n_factor: 1.3 },
    { uuid: 'c0c742eb-6c3a-8590-3903-bf09bc1461ff', height_mm: 400, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 165, n_factor: 1.3 },
    { uuid: '12ca94e1-e0cd-0c9f-0eb9-53c282cdf2e9', height_mm: 400, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 206, n_factor: 1.3 },
    { uuid: '408072d3-2635-bc31-62c1-49c44a3070f6', height_mm: 400, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 248, n_factor: 1.3 },
    { uuid: 'bcb3ab82-0684-765e-892f-dd9299077071', height_mm: 400, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 289, n_factor: 1.3 },
    { uuid: '082624c1-4e5c-c8e9-97aa-4b7b670a7c1f', height_mm: 400, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 330, n_factor: 1.3 },
    { uuid: 'd5ad51ec-b68b-65c7-61e6-d14fb671d990', height_mm: 400, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 372, n_factor: 1.3 },
    { uuid: '45ca5017-47be-b98c-79d0-7844261a49fe', height_mm: 400, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 413, n_factor: 1.3 },
    { uuid: 'f24e8a01-7d66-ed34-bc4e-75900469f0f5', height_mm: 400, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 454, n_factor: 1.3 },
    { uuid: '40eda8c6-fdc0-578e-2af1-b4ccef894b8c', height_mm: 400, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 496, n_factor: 1.3 },
    { uuid: '69b858ce-45fd-b827-38ea-f7912d67c448', height_mm: 400, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 537, n_factor: 1.3 },
    { uuid: 'a2f765ee-e5b2-2b2c-57d8-a93770ac1ad0', height_mm: 400, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 578, n_factor: 1.3 },
    { uuid: '649a31ce-b9e5-184d-3321-aaeecc41e625', height_mm: 400, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 619, n_factor: 1.3 },
    { uuid: '69b7db65-580e-3821-23f6-4d3a1e9a6a0f', height_mm: 400, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 661, n_factor: 1.3 },
    { uuid: '743d6de2-1461-ed47-c1c9-33ab43341354', height_mm: 400, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 702, n_factor: 1.3 },
    { uuid: 'b48f98a1-884b-1e24-8175-8d1d99743f7b', height_mm: 400, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 743, n_factor: 1.3 },
    { uuid: '555c41b8-369c-4b19-3f42-8c1701f3059b', height_mm: 400, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 785, n_factor: 1.3 },
    { uuid: '8d3dd2ca-4048-2de8-e13a-66e48004fb16', height_mm: 400, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 826, n_factor: 1.3 },
    { uuid: 'e8ef8632-eb2e-202c-cfb7-daa86e86e2d0', height_mm: 400, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 867, n_factor: 1.3 },
    { uuid: 'e0e7a350-8381-b8ee-4c8c-bd901ba5287a', height_mm: 400, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 909, n_factor: 1.3 },
    { uuid: '837c68e1-2075-4ae1-4bff-243a06d5261d', height_mm: 400, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 950, n_factor: 1.3 },
    { uuid: '017ffb63-d159-5963-32f7-507053c0aa07', height_mm: 400, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 991, n_factor: 1.3 },
    { uuid: 'f303afbc-f55a-fa05-fdc0-bf2133d3dd8e', height_mm: 400, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1032, n_factor: 1.3 },
    { uuid: '9ba57d20-10bc-3190-6224-60c9516cdde6', height_mm: 400, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1074, n_factor: 1.3 },
    { uuid: 'a0e62119-09bd-1938-e2d7-8b187feaaa84', height_mm: 400, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1115, n_factor: 1.3 },
    { uuid: 'caed94b1-e8e4-bc07-a390-bf08ecce12e8', height_mm: 400, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1156, n_factor: 1.3 },
    { uuid: '8e18c1f5-5340-1a0e-b5ea-d5d24a4d9b26', height_mm: 400, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1198, n_factor: 1.3 },
    { uuid: 'dc3853c9-eae5-79de-5df5-6bb3abf61834', height_mm: 400, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1239, n_factor: 1.3 },
    { uuid: 'ef3011b6-7ac3-4a4e-8dc0-f77d466ec691', height_mm: 450, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 664, n_factor: 1.3 }, // MCS data as not in the copa data sheet
    { uuid: '73840499-7cc7-4eb5-8bd0-6970a1a5805e', height_mm: 450, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 758, n_factor: 1.3 }, // MCS data as not in the copa data sheet
    { uuid: '79b9cd30-a390-5c18-f06b-6cef04cce433', height_mm: 500, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 149, n_factor: 1.3 },
    { uuid: '786b4625-00e7-ca7b-b59b-cec8a1e822a7', height_mm: 500, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 199, n_factor: 1.3 },
    { uuid: '1eb87e1b-4d1c-c0e5-77df-8eed84788f98', height_mm: 500, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 249, n_factor: 1.3 },
    { uuid: '84be2cb1-35b8-80e5-ef89-d3b5f86cf559', height_mm: 500, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 299, n_factor: 1.3 },
    { uuid: '9869f428-1909-097f-6a87-52c13db7e65b', height_mm: 500, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 349, n_factor: 1.3 },
    { uuid: '8604d159-fe2f-5f6e-adec-0bd705b39ea4', height_mm: 500, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 398, n_factor: 1.3 },
    { uuid: 'df33339a-a2ab-92ee-646a-a7c4302fc830', height_mm: 500, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 448, n_factor: 1.3 },
    { uuid: '9c8514e6-e17a-f294-8461-ff829b49cf4f', height_mm: 500, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 498, n_factor: 1.3 },
    { uuid: 'e70e37aa-bdd4-9345-f862-1915191d14c9', height_mm: 500, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 548, n_factor: 1.3 },
    { uuid: '59a476e5-b88c-ce8b-cc28-944f64114404', height_mm: 500, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 598, n_factor: 1.3 },
    { uuid: '475f94a5-ce46-502a-3cea-97470f2185c8', height_mm: 500, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 647, n_factor: 1.3 },
    { uuid: '5c0b7094-75e7-418f-6bd4-cb2146031556', height_mm: 500, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 697, n_factor: 1.3 },
    { uuid: 'dfcc4ab7-8410-dd3d-0fec-d3c733e0e07f', height_mm: 500, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 747, n_factor: 1.3 },
    { uuid: '699f3bc1-03e7-e41a-7b1b-24ffaafd025d', height_mm: 500, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 797, n_factor: 1.3 },
    { uuid: '61c3e335-7f88-4fd1-dfa9-6bce2a1150a3', height_mm: 500, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 847, n_factor: 1.3 },
    { uuid: 'c43b5c64-5af8-8be5-213c-ee0d1d8ffc06', height_mm: 500, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 896, n_factor: 1.3 },
    { uuid: '8e8c726e-19b6-c266-1e0a-7e7ff99e5b43', height_mm: 500, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 946, n_factor: 1.3 },
    { uuid: '1408abdc-4442-0e35-248e-6d3997126356', height_mm: 500, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 996, n_factor: 1.3 },
    { uuid: '3a62b264-2d68-ee82-5a69-8fd9fd5ff66a', height_mm: 500, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1046, n_factor: 1.3 },
    { uuid: '1cef7e83-5891-5500-12f5-fd2bf94b0d30', height_mm: 500, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1096, n_factor: 1.3 },
    { uuid: '08cddf72-db9d-a763-8f58-cbb7c15743a0', height_mm: 500, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1145, n_factor: 1.3 },
    { uuid: 'a347fa87-249a-0315-d86e-67fe221072cc', height_mm: 500, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1195, n_factor: 1.3 },
    { uuid: 'a4b40fec-55d2-571b-0b34-1be39ef63918', height_mm: 500, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1245, n_factor: 1.3 },
    { uuid: '7c5fda21-995b-254b-d67b-acab527519ce', height_mm: 500, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1295, n_factor: 1.3 },
    { uuid: 'a18ea084-8468-a89d-7dae-d4e3d6f91e0e', height_mm: 500, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1345, n_factor: 1.3 },
    { uuid: '38d0625c-d366-7906-6a15-698d43d413be', height_mm: 500, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1394, n_factor: 1.3 },
    { uuid: 'fcc45ec6-87c4-7f70-3624-18c1c4ef87a5', height_mm: 500, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1444, n_factor: 1.3 },
    { uuid: '3fe69dc0-64c1-6a72-4bef-41d7d572628c', height_mm: 500, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1494, n_factor: 1.3 },
    { uuid: 'e55c072f-425c-d2f4-b05f-f012efe10f5a', height_mm: 600, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 174, n_factor: 1.3 },
    { uuid: 'ccd71d37-91c4-4714-8250-fdc0c36a4482', height_mm: 600, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 233, n_factor: 1.3 },
    { uuid: '3365e877-73b0-4a5a-8890-05d802e8dae6', height_mm: 600, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 291, n_factor: 1.3 },
    { uuid: '9b33fde5-fec8-4f7e-b2b6-2f4ba07a638f', height_mm: 600, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 349, n_factor: 1.3 },
    { uuid: '109d6e6b-d57c-4de9-b225-a715928c23bd', height_mm: 600, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 407, n_factor: 1.3 },
    { uuid: 'b3fe0e75-dbbf-4f44-a9b0-aef179b2c456', height_mm: 600, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 466, n_factor: 1.3 },
    { uuid: '5fc672c0-2fa6-42fc-bb69-eeabe9b8accd', height_mm: 600, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 524, n_factor: 1.3 },
    { uuid: '81d8f856-74ee-4781-9077-3d71354ad8b7', height_mm: 600, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 582, n_factor: 1.3 },
    { uuid: '375d5f40-a8c4-69f2-ebf9-470ee406114c', height_mm: 600, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 640, n_factor: 1.3 },
    { uuid: '4f1e3d24-52c8-4f3d-b1ae-e9f6b439f9e4', height_mm: 600, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 698, n_factor: 1.3 },
    { uuid: '9279ef72-abca-5600-32b2-b87c703fce1a', height_mm: 600, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 757, n_factor: 1.3 },
    { uuid: 'f161ef59-165f-4f21-b3dc-5db36f69ba21', height_mm: 600, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 815, n_factor: 1.3 },
    { uuid: 'a2482173-ae64-24b7-1504-dcb2d39bb2f5', height_mm: 600, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 873, n_factor: 1.3 },
    { uuid: '7f6eb61e-324f-408c-a916-db10adb1f5d4', height_mm: 600, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 931, n_factor: 1.3 },
    { uuid: 'ab7cfc63-2f49-9e16-cd8e-f2a709346e65', height_mm: 600, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 989, n_factor: 1.3 },
    { uuid: '8c21a1e1-37b5-c374-7f9b-cb85eb8c6516', height_mm: 600, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1048, n_factor: 1.3 },
    { uuid: '796301bc-bc42-71d3-6613-023825349109', height_mm: 600, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1106, n_factor: 1.3 },
    { uuid: '20dcf611-8bc2-4a30-772c-a99b5dab1c62', height_mm: 600, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1164, n_factor: 1.3 },
    { uuid: 'ad43986e-e6e1-1d0e-0011-ac79d60c32e0', height_mm: 600, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1222, n_factor: 1.3 },
    { uuid: '1797d79d-b627-3dea-10da-44935d4f9686', height_mm: 600, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1280, n_factor: 1.3 },
    { uuid: '1588ea52-2d5a-4f1b-daba-0ad40db35d0c', height_mm: 600, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1339, n_factor: 1.3 },
    { uuid: 'ba7d83e4-aca4-7284-eeb0-3da96873ea6c', height_mm: 600, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1397, n_factor: 1.3 },
    { uuid: '93528a07-7a9f-538e-c68d-1d63f53ec379', height_mm: 600, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1455, n_factor: 1.3 },
    { uuid: '25cdb34a-911d-2608-32ab-6c5107bbf31c', height_mm: 600, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1513, n_factor: 1.3 },
    { uuid: '9a1e6d0f-9fbb-5c80-cf71-d157301cb784', height_mm: 600, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1571, n_factor: 1.3 },
    { uuid: 'd256a33a-f63c-adec-c0e9-76558d90e464', height_mm: 600, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1630, n_factor: 1.3 },
    { uuid: '2386e729-2af1-73a0-cb15-fcd305ca239b', height_mm: 600, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1688, n_factor: 1.3 },
    { uuid: 'd1178ed6-efc5-6a94-3678-23b09b7da12e', height_mm: 600, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1746, n_factor: 1.3 },
    { uuid: '24ac03f0-1696-dd19-d294-eb2d2a39e010', height_mm: 750, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 213, n_factor: 1.3 },
    { uuid: 'e5b404d8-e909-d5d0-6be6-d7eaf207432b', height_mm: 750, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 284, n_factor: 1.3 },
    { uuid: '0707bd79-40be-3f3c-ea47-48b6ea6470b8', height_mm: 750, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 354, n_factor: 1.3 },
    { uuid: '85a9453b-4635-6e40-84f9-ed32809df473', height_mm: 750, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 425, n_factor: 1.3 },
    { uuid: 'ddeb8084-c5c5-a4c6-1002-69e75d715f1d', height_mm: 750, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 496, n_factor: 1.3 },
    { uuid: 'b28290df-5fe6-49a8-3167-c2e4212303a0', height_mm: 750, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 567, n_factor: 1.3 },
    { uuid: 'debfe798-4890-320e-a795-f151375deab2', height_mm: 750, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 638, n_factor: 1.3 },
    { uuid: '04bf0580-f263-7a6f-2915-dfefe7497e10', height_mm: 750, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 709, n_factor: 1.3 },
    { uuid: '4b857b30-48b5-504d-6976-66101495e6fd', height_mm: 750, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 780, n_factor: 1.3 },
    { uuid: 'fc19e81e-4cb6-890f-bee5-d75feaa89579', height_mm: 750, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 851, n_factor: 1.3 },
    { uuid: 'f7a0c51d-e76c-36da-5b16-b8dc8659bd4f', height_mm: 750, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 922, n_factor: 1.3 },
    { uuid: '104796ac-c117-1957-c1b7-740812fb61b7', height_mm: 750, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 993, n_factor: 1.3 },
    { uuid: '073f694f-58aa-7063-4c36-01420b58187d', height_mm: 750, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1063, n_factor: 1.3 },
    { uuid: '83655fd4-dae2-a69d-526c-5f767b8c2b69', height_mm: 750, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1134, n_factor: 1.3 },
    { uuid: 'abe11e33-45c5-59c7-a98e-940c9892ffe1', height_mm: 750, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1205, n_factor: 1.3 },
    { uuid: 'dd2f0743-50e1-eb11-285e-3dad62c42907', height_mm: 750, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1276, n_factor: 1.3 },
    { uuid: 'd78136b4-0b26-df79-cba2-b714e041a2a0', height_mm: 750, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1347, n_factor: 1.3 },
    { uuid: 'fd4b137b-7548-6a3c-77ee-dc10c38ac09b', height_mm: 750, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1418, n_factor: 1.3 },
    { uuid: 'b70a41bf-d573-f80f-b386-3e4156cff261', height_mm: 750, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1489, n_factor: 1.3 },
    { uuid: 'e1786272-ee64-0859-b1cd-b0409a98b51c', height_mm: 750, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1560, n_factor: 1.3 },
    { uuid: 'accf5026-9289-124a-99f6-69b9931efece', height_mm: 750, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1631, n_factor: 1.3 },
    { uuid: 'da4133b8-5c13-315d-e856-b6975effdbe1', height_mm: 750, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1702, n_factor: 1.3 },
    { uuid: 'b2428000-d85b-50a5-e1f5-655cf3159630', height_mm: 750, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1772, n_factor: 1.3 },
    { uuid: 'b7e600f2-6ab8-6dce-6873-cd09c470ba80', height_mm: 750, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1843, n_factor: 1.3 },
    { uuid: '4bb495c7-f62e-f283-9766-f804f1f41cd4', height_mm: 750, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1914, n_factor: 1.3 },
    { uuid: '6f6e65eb-a883-6c49-fd45-b08f9e6f1706', height_mm: 750, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1985, n_factor: 1.3 },
    { uuid: '21f23a3a-bb81-0bc5-0f50-38715da4b214', height_mm: 750, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2056, n_factor: 1.3 },
    { uuid: '07310cf7-6ef9-1ffd-b621-b7243d784487', height_mm: 750, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2127, n_factor: 1.3 },
    { uuid: '57e3b2e2-115a-ba28-a382-5f1427919997', height_mm: 900, width_mm: 300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 251, n_factor: 1.3 },
    { uuid: '29b8f106-97ec-b772-65a9-d0d76daf7bca', height_mm: 900, width_mm: 400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 334, n_factor: 1.3 },
    { uuid: '91a6934e-9f72-a58f-cba5-c53b0c8fd63e', height_mm: 900, width_mm: 500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 418, n_factor: 1.3 },
    { uuid: '6d9fc7a4-a896-e9a7-9e9b-5026116cf4b3', height_mm: 900, width_mm: 600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 502, n_factor: 1.3 },
    { uuid: '2bc4a327-c851-f4b6-2419-5000a8140665', height_mm: 900, width_mm: 700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 585, n_factor: 1.3 },
    { uuid: 'edc3db52-999c-25ac-12d2-2ff845567755', height_mm: 900, width_mm: 800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 669, n_factor: 1.3 },
    { uuid: '18af2f12-2af2-ea60-f49e-ab7e2433b57a', height_mm: 900, width_mm: 900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 752, n_factor: 1.3 },
    { uuid: '03b2e13a-2f80-359f-4454-53b8164675ab', height_mm: 900, width_mm: 1000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 836, n_factor: 1.3 },
    { uuid: '1872525d-5cff-e42b-7a94-ffc66212c822', height_mm: 900, width_mm: 1100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 920, n_factor: 1.3 },
    { uuid: 'b96fcf07-8f9b-909c-52e9-eb2a30e719a4', height_mm: 900, width_mm: 1200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1003, n_factor: 1.3 },
    { uuid: '9776262c-792a-9daa-a675-1e566ffcb59e', height_mm: 900, width_mm: 1300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1087, n_factor: 1.3 },
    { uuid: '44cd47d1-185f-210d-1c72-bb95b20c5160', height_mm: 900, width_mm: 1400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1170, n_factor: 1.3 },
    { uuid: '41d3aee1-ff09-d309-35f4-ae5bb94d5bab', height_mm: 900, width_mm: 1500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1254, n_factor: 1.3 },
    { uuid: '09a00a15-c1a9-d8b4-9e56-f31042968403', height_mm: 900, width_mm: 1600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1338, n_factor: 1.3 },
    { uuid: 'a2c1f9b9-92de-c5e6-2c57-62a42716ce85', height_mm: 900, width_mm: 1700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1421, n_factor: 1.3 },
    { uuid: '246a3093-b86e-dae0-8f2f-e4819ba6887d', height_mm: 900, width_mm: 1800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1505, n_factor: 1.3 },
    { uuid: 'a5408a45-53cd-0218-2304-7e5238b0a709', height_mm: 900, width_mm: 1900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1588, n_factor: 1.3 },
    { uuid: 'cf83c158-b705-0cd1-dd1b-7b91f5144178', height_mm: 900, width_mm: 2000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1672, n_factor: 1.3 },
    { uuid: '8156f7c3-410a-ba6c-6ed1-55dd0f8e6055', height_mm: 900, width_mm: 2100, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1756, n_factor: 1.3 },
    { uuid: 'f19d168c-0319-a32f-a7d4-fb5439908fd4', height_mm: 900, width_mm: 2200, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1839, n_factor: 1.3 },
    { uuid: '5c51399b-3b97-e49d-b5ed-8ef103f578ae', height_mm: 900, width_mm: 2300, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 1923, n_factor: 1.3 },
    { uuid: '1954a1ce-fb27-e48c-df8e-dfb8bdae8abb', height_mm: 900, width_mm: 2400, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2006, n_factor: 1.3 },
    { uuid: 'c15bae3a-951f-42a1-ea97-98ca022a55d4', height_mm: 900, width_mm: 2500, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2090, n_factor: 1.3 },
    { uuid: 'a4ad5521-d28f-89ce-d08e-80f33752ddca', height_mm: 900, width_mm: 2600, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2174, n_factor: 1.3 },
    { uuid: '1d444e83-723f-27c2-acf7-b524ac1a7061', height_mm: 900, width_mm: 2700, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2257, n_factor: 1.3 },
    { uuid: '5c624567-7447-2810-453c-9567185296c5', height_mm: 900, width_mm: 2800, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2341, n_factor: 1.3 },
    { uuid: 'b45b2b44-bb2b-b485-c700-7c26629586e3', height_mm: 900, width_mm: 2900, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2424, n_factor: 1.3 },
    { uuid: 'cb1e7110-ae29-c367-bc76-d10116730b4f', height_mm: 900, width_mm: 3000, category: 'Radiator', type: 'Type 10 (P1)', output_capacity_w_at_50_c: 2508, n_factor: 1.3 },
    { uuid: '5f7410ca-e082-7be1-5c3a-6a9aceadc9c1', height_mm: 300, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 209, n_factor: 1.3 },
    { uuid: 'ab44b11b-92d0-49a4-b95b-495382882c0a', height_mm: 300, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 262, n_factor: 1.3 },
    { uuid: 'deda32f2-a146-7c7c-c057-401fa28116cb', height_mm: 300, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 314, n_factor: 1.3 },
    { uuid: 'b5df1593-ab7a-3445-fe2a-de6d0735c02d', height_mm: 300, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 366, n_factor: 1.3 },
    { uuid: '9d736758-2919-4150-ae8f-234a6c5b9c12', height_mm: 300, width_mm: 1000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 523, n_factor: 1.3 },
    { uuid: '897eae85-333f-61ec-67c4-981e3f01ae8d', height_mm: 300, width_mm: 1200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 628, n_factor: 1.3 },
    { uuid: '22b39dfd-8d73-795a-1c61-deb959bee0fd', height_mm: 300, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 732, n_factor: 1.3 },
    { uuid: '359a068b-0fe9-8acd-a993-639882adfec9', height_mm: 300, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 837, n_factor: 1.3 },
    { uuid: 'a40e514a-264c-baa0-55ef-43ef54795667', height_mm: 300, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1046, n_factor: 1.3 },
    { uuid: '01325ad5-255d-2f0a-8641-fa1d39883260', height_mm: 400, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 270, n_factor: 1.3 },
    { uuid: '690526e5-808e-db10-1d9b-2fe91aee681b', height_mm: 400, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 337, n_factor: 1.3 },
    { uuid: '5c3f0244-4bf2-bff5-cb37-f43a1c3dd1f4', height_mm: 400, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 405, n_factor: 1.3 },
    { uuid: '204129ab-8467-2bc3-4aef-92bb7a1c9fd9', height_mm: 400, width_mm: 700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 472, n_factor: 1.3 },
    { uuid: '61d2897d-bfc5-de58-d286-b3b5f67a1bbe', height_mm: 400, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 540, n_factor: 1.3 },
    { uuid: 'cf48831d-b5b8-fee0-d2bf-8173570e2de7', height_mm: 400, width_mm: 900, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 607, n_factor: 1.3 },
    { uuid: 'fbf7c287-df50-34a9-0f5e-fddd117da50a', height_mm: 400, width_mm: 1000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 675, n_factor: 1.3 },
    { uuid: '76889e28-78b9-32ac-f551-ca6de91b2481', height_mm: 400, width_mm: 1100, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 742, n_factor: 1.3 },
    { uuid: '253acd1b-91f6-2bdc-7be9-fcd92f65e126', height_mm: 400, width_mm: 1200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 810, n_factor: 1.3 },
    { uuid: '259609d2-59e8-4316-1f6d-72f85c0c9274', height_mm: 400, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 945, n_factor: 1.3 },
    { uuid: '467f1adf-5e59-7b90-abf4-0d9b00079c82', height_mm: 400, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1079, n_factor: 1.3 },
    { uuid: '7cf8ed2c-4b61-0cf4-82c4-6a93d69e6a40', height_mm: 400, width_mm: 1800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1214, n_factor: 1.3 },
    { uuid: 'c0a67d4c-a2b1-de00-29e5-77438b3bdbfe', height_mm: 400, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1349, n_factor: 1.3 },
    { uuid: '6fc64f4b-30a0-ebdf-562c-5bd8643dd3ca', height_mm: 400, width_mm: 2200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1484, n_factor: 1.3 },
    { uuid: '4597e560-b64b-a57d-a818-c11ab3296522', height_mm: 400, width_mm: 2700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1822, n_factor: 1.3 },
    { uuid: '8457e994-fcbe-3adb-5316-a0f6a4afda96', height_mm: 400, width_mm: 3000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2024, n_factor: 1.3 },
    { uuid: '93e586cd-2071-4bce-83a0-57425f9dd809', height_mm: 450, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1058, n_factor: 1.3 },
    { uuid: '6cc5cdcc-6827-42fc-a810-57f8e87ce419', height_mm: 450, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1210, n_factor: 1.3 },
    { uuid: 'd565645e-ea4b-4279-a6bd-96d3c5469581', height_mm: 450, width_mm: 1800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1361, n_factor: 1.3 },
    { uuid: '8ab77898-0ac1-4e17-8dae-ad0053a7e9a2', height_mm: 450, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1512, n_factor: 1.3 },
    { uuid: 'cfbc3fc8-355e-41f1-941f-27406fac1df7', height_mm: 450, width_mm: 2200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1663, n_factor: 1.3 },
    { uuid: 'a1a147de-6559-44dd-b295-207d0b3bfeb5', height_mm: 450, width_mm: 2400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1814, n_factor: 1.3 },
    { uuid: '2bd6aadd-d32b-44fc-8d81-35e11fe77fd9', height_mm: 450, width_mm: 2600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1966, n_factor: 1.3 },
    { uuid: '0635020d-86e1-4dc4-a29f-f2cd310366f3', height_mm: 450, width_mm: 2800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2117, n_factor: 1.3 },
    { uuid: '37278886-86f7-4fad-b0b7-6d37003cf0cc', height_mm: 450, width_mm: 3000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2268, n_factor: 1.3 },
    { uuid: 'bec17da4-2363-53e4-4e2c-e44def32c067', height_mm: 500, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 326, n_factor: 1.3 },
    { uuid: '55b5ecdd-721b-6b8b-af5e-470d0898d29a', height_mm: 500, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 408, n_factor: 1.3 },
    { uuid: '96bd1b8f-235c-660d-745a-fceb5d61b5ef', height_mm: 500, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 490, n_factor: 1.3 },
    { uuid: '6654684a-2610-4b2f-91b3-6d9a7897d829', height_mm: 500, width_mm: 700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 571, n_factor: 1.3 },
    { uuid: '0b5dba64-01f7-4c31-beb7-d7c21b69d8a7', height_mm: 500, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 653, n_factor: 1.3 },
    { uuid: '1b193326-9d37-ac83-0c6e-d6e6844e1915', height_mm: 500, width_mm: 900, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 734, n_factor: 1.3 },
    { uuid: '97d76543-ab02-e2b5-2d3c-690e98d06910', height_mm: 500, width_mm: 1000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 816, n_factor: 1.3 },
    { uuid: 'c93a1599-a4f9-ce97-09db-9c03af7482e3', height_mm: 500, width_mm: 1100, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 897, n_factor: 1.3 },
    { uuid: '1bf07752-ab97-bb46-8c37-8328e5d7af0f', height_mm: 500, width_mm: 1200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 979, n_factor: 1.3 },
    { uuid: '8292620f-433a-bebf-b241-2168db350e84', height_mm: 500, width_mm: 1300, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1061, n_factor: 1.3 },
    { uuid: '1191355a-1639-6353-3287-532ee3519304', height_mm: 500, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1142, n_factor: 1.3 },
    { uuid: '9e48a93d-d217-8b50-3e19-c70013e1b2c5', height_mm: 500, width_mm: 1500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1224, n_factor: 1.3 },
    { uuid: 'ff153187-c187-445c-f2e9-63ddce85587b', height_mm: 500, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1305, n_factor: 1.3 },
    { uuid: '9eade1be-d7cd-9ec6-d94c-e0ac12c4ed2b', height_mm: 500, width_mm: 1800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1469, n_factor: 1.3 },
    { uuid: '9515ddea-9a2c-8d72-d173-f167743f3309', height_mm: 500, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1632, n_factor: 1.3 },
    { uuid: 'ec681007-ad83-3684-5201-13835ed18b9b', height_mm: 500, width_mm: 2200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1795, n_factor: 1.3 },
    { uuid: 'e116afd0-16d5-29db-d685-93e2956c58ce', height_mm: 500, width_mm: 2400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1958, n_factor: 1.3 },
    { uuid: '00c0b846-0baf-91df-5ddb-c8367856570e', height_mm: 500, width_mm: 2700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2203, n_factor: 1.3 },
    { uuid: 'eb0fd61e-4926-369f-8f24-375b381a5629', height_mm: 500, width_mm: 3000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2448, n_factor: 1.3 },
    { uuid: '16d68fd2-b153-4de5-bf1f-fd740b7a4987', height_mm: 600, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 380, n_factor: 1.3 },
    { uuid: 'dc384bed-741d-4919-8e27-e67e4a96b660', height_mm: 600, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 475, n_factor: 1.3 },
    { uuid: '8884c0be-d903-4e28-8049-7d3604c1e25e', height_mm: 600, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 570, n_factor: 1.3 },
    { uuid: '2d5d9c3d-5035-4aa0-a34b-d6d3d377231a', height_mm: 600, width_mm: 700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 665, n_factor: 1.3 },
    { uuid: '1ef5c02a-3edc-4e1b-9730-7c28b0241aeb', height_mm: 600, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 760, n_factor: 1.3 },
    { uuid: '4890a9c9-a262-4048-aca0-1dc1d0f0032a', height_mm: 600, width_mm: 900, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 855, n_factor: 1.3 },
    { uuid: '387404ae-c17e-4f74-9671-bccb9461c96c', height_mm: 600, width_mm: 1000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 950, n_factor: 1.3 },
    { uuid: '60e67981-429f-4c33-a589-3a514f01a8bf', height_mm: 600, width_mm: 1100, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1045, n_factor: 1.3 },
    { uuid: '5ba45491-bf37-41a8-9ce3-2b33d9b3ed2f', height_mm: 600, width_mm: 1200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1140, n_factor: 1.3 },
    { uuid: '7d438dd6-8fd2-d7fd-72fd-be04af3e1da6', height_mm: 600, width_mm: 1300, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1235, n_factor: 1.3 },
    { uuid: 'bcb348fa-0669-4d3f-af5c-04e83493c999', height_mm: 600, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1330, n_factor: 1.3 },
    { uuid: '8dae3e17-49fc-0b41-9c0c-b16dd2800a48', height_mm: 600, width_mm: 1500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1425, n_factor: 1.3 },
    { uuid: '2d47ad32-1646-4688-af72-42eedd2b6ccf', height_mm: 600, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1520, n_factor: 1.3 },
    { uuid: '88dca25e-9892-47af-8151-8b8cbe4bbb26', height_mm: 600, width_mm: 1800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1710, n_factor: 1.3 },
    { uuid: '0849f0d1-77a1-4628-a138-26f4c24c5c72', height_mm: 600, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1900, n_factor: 1.3 },
    { uuid: '7b4c97a8-6061-458c-aa58-953d4ac98dd6', height_mm: 600, width_mm: 2200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2089, n_factor: 1.3 },
    { uuid: '5b9d4d50-30f2-465a-8dbb-e31966c4c201', height_mm: 600, width_mm: 2400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2280, n_factor: 1.3 },
    { uuid: '96be02f8-e555-45bb-aa3b-550649139c25', height_mm: 600, width_mm: 2600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2548, n_factor: 1.3 }, // MCS data still as not in K rad data sheet
    { uuid: 'bfe95be1-1b20-4d2f-b7ce-92258c60728b', height_mm: 600, width_mm: 2800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2744, n_factor: 1.3 }, // MCS data still as not in K rad data sheet
    { uuid: '23664433-644c-462d-9903-e20b4c1b1960', height_mm: 600, width_mm: 3000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2849, n_factor: 1.3 },
    { uuid: 'eb9dc86c-ec0e-49b7-9776-b656e6d68b95', height_mm: 700, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 447, n_factor: 1.3 },
    { uuid: '555c0f40-0e4e-4c65-a2a8-41e8db1b4e92', height_mm: 700, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 559, n_factor: 1.3 },
    { uuid: '22228dae-38e9-4313-ac40-0f996009e01c', height_mm: 700, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 670, n_factor: 1.3 },
    { uuid: '3e7ec5b3-2638-46f9-af56-eedb0db261d5', height_mm: 700, width_mm: 700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 782, n_factor: 1.3 },
    { uuid: '40de0e4b-9d53-487c-b9b0-d9df09b49d10', height_mm: 700, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 894, n_factor: 1.3 },
    { uuid: '772727ad-b90f-4f50-bd94-fd50686b7407', height_mm: 700, width_mm: 900, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1005, n_factor: 1.3 },
    { uuid: '6f51ee92-ef7b-4e18-b9ba-6fa25d63e686', height_mm: 700, width_mm: 1000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1117, n_factor: 1.3 },
    { uuid: '264fa361-45ef-486f-bec5-a50d491f82bd', height_mm: 700, width_mm: 1100, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1229, n_factor: 1.3 },
    { uuid: 'ba1e6d72-256a-45d3-af28-d758433a3d25', height_mm: 700, width_mm: 1200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1340, n_factor: 1.3 },
    { uuid: '986da1d3-c93b-4a42-bfd5-38e2c9568860', height_mm: 700, width_mm: 1400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1564, n_factor: 1.3 },
    { uuid: '250517ea-2c45-45ca-b53a-c8c0bd5afb62', height_mm: 700, width_mm: 1600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1787, n_factor: 1.3 },
    { uuid: 'e2f53f86-3818-44ab-9015-08c7d0b1f66f', height_mm: 700, width_mm: 1800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2011, n_factor: 1.3 },
    { uuid: '7b3e33c9-138a-4319-b030-10bafdf0a11f', height_mm: 700, width_mm: 2000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2234, n_factor: 1.3 },
    { uuid: '414904b6-13c9-400c-88bf-d03c912d36c4', height_mm: 700, width_mm: 2200, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2457, n_factor: 1.3 },
    { uuid: '3601e0aa-51d3-43ba-9eee-9bd7e8ed2cdf', height_mm: 700, width_mm: 2400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2681, n_factor: 1.3 },
    { uuid: 'b15f4774-2adc-4008-a79e-21f1cddd835c', height_mm: 700, width_mm: 2600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 2904, n_factor: 1.3 },
    { uuid: '23e8092f-2dd8-4d04-8c67-b1ac87c17548', height_mm: 700, width_mm: 2800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 3128, n_factor: 1.3 },
    { uuid: '892095c3-2588-4241-8b33-f422bc87746f', height_mm: 700, width_mm: 3000, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 3351, n_factor: 1.3 },
    { uuid: '67884729-6d11-823d-1559-dba560ed617a', height_mm: 900, width_mm: 400, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 520, n_factor: 1.3 },
    { uuid: '0896fcc5-d715-12d2-f1fb-5cff150ada96', height_mm: 900, width_mm: 500, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 649, n_factor: 1.3 },
    { uuid: 'a658f7de-6d5b-5f35-0660-5e9cd83f9295', height_mm: 900, width_mm: 600, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 779, n_factor: 1.3 },
    { uuid: 'f4731b4b-8d81-38db-e8cc-cf1622987da0', height_mm: 900, width_mm: 700, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 909, n_factor: 1.3 },
    { uuid: '94527cf1-0d12-d2e8-67be-bf05cc8af6c9', height_mm: 900, width_mm: 800, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1039, n_factor: 1.3 },
    { uuid: '5de37504-6b12-c004-ce3e-5c91fd31e571', height_mm: 900, width_mm: 900, category: 'Radiator', type: 'Type 11 (K1)', output_capacity_w_at_50_c: 1169, n_factor: 1.3 },
    { uuid: '76f032b1-b2fc-44ad-84d8-beb074dc5941', height_mm: 300, width_mm: 500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 373, n_factor: 1.33 },
    { uuid: '71947ee1-c69b-4269-8578-14d2320a636f', height_mm: 300, width_mm: 1000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 745, n_factor: 1.33 },
    { uuid: '8ea7cb38-852c-40a1-b7a8-6bd82b916b64', height_mm: 300, width_mm: 1500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1118, n_factor: 1.33 },
    { uuid: '856b79a1-04d3-4e39-98cb-bfa86be13032', height_mm: 300, width_mm: 2500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1863, n_factor: 1.33 },
    { uuid: '2c92d8c9-b402-4a8a-9d75-9b65c48e5ff0', height_mm: 400, width_mm: 1000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 977, n_factor: 1.33 },
    { uuid: '47f12c1a-8cfb-4d61-b957-542f9ee5a622', height_mm: 450, width_mm: 400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 422, n_factor: 1.33 },
    { uuid: 'd5a99594-0771-4d35-808d-f24a473d76fc', height_mm: 450, width_mm: 500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 528, n_factor: 1.33 },
    { uuid: 'ca027328-0bf7-4153-a078-5d4d83a8e80d', height_mm: 450, width_mm: 600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 633, n_factor: 1.33 },
    { uuid: '519a9c3a-909e-479d-9a50-4b02e892f9a4', height_mm: 450, width_mm: 700, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 739, n_factor: 1.33 },
    { uuid: 'a57ec6a0-dacc-422c-86a9-44850e80b455', height_mm: 450, width_mm: 800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 844, n_factor: 1.33 },
    { uuid: '99adb447-3857-4ad9-a9be-7f904ac17170', height_mm: 450, width_mm: 900, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 950, n_factor: 1.33 },
    { uuid: '4c3e9270-f024-4dcb-b260-296d6790fac2', height_mm: 450, width_mm: 1100, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1161, n_factor: 1.33 },
    { uuid: 'd1689ca5-2630-485d-954d-db42ed2725f8', height_mm: 450, width_mm: 1400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1477, n_factor: 1.33 },
    { uuid: '7ec97d1e-40fa-444b-81f1-648016b159d4', height_mm: 450, width_mm: 1600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1688, n_factor: 1.33 },
    { uuid: '76690deb-5ec7-42fd-852a-a132ddcb3c45', height_mm: 450, width_mm: 1800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1899, n_factor: 1.33 },
    { uuid: 'b7fe822b-92d7-44d0-a53b-3f786093e428', height_mm: 450, width_mm: 2000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2110, n_factor: 1.33 },
    { uuid: '62279e20-801e-4e6a-aed5-d5d3ad001a68', height_mm: 450, width_mm: 2200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2321, n_factor: 1.33 },
    { uuid: '604d4b5b-9762-468f-95a0-af1ee1fcf903', height_mm: 450, width_mm: 2400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2532, n_factor: 1.33 },
    { uuid: '1e2f1868-b5d1-41ef-b37a-07c561536c13', height_mm: 450, width_mm: 2600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2743, n_factor: 1.33 },
    { uuid: '6b2f650b-86ec-4630-bb23-5a9a9425b85d', height_mm: 450, width_mm: 2800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2954, n_factor: 1.33 },
    { uuid: '21f53bd4-3abd-49e9-8151-f430e882d6b9', height_mm: 450, width_mm: 3000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3165, n_factor: 1.33 },
    { uuid: '48b55852-d5ab-42f1-bc56-af9d7704b2cb', height_mm: 500, width_mm: 800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 940, n_factor: 1.33 },
    { uuid: 'a97b5ac0-781a-4942-bd72-9bbe4e0ea2ac', height_mm: 500, width_mm: 1000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1175, n_factor: 1.33 },
    { uuid: 'c97093a4-dbf6-4f1b-8faa-ac8573ca7345', height_mm: 500, width_mm: 1200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1410, n_factor: 1.33 },
    { uuid: 'eba37a46-0adb-438a-b72a-383c92faff2e', height_mm: 500, width_mm: 1400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1645, n_factor: 1.33 },
    { uuid: 'a593a2bf-c843-4581-a0b3-50bfd130bd85', height_mm: 500, width_mm: 1600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1880, n_factor: 1.33 },
    { uuid: '71dfe96f-d7bc-4ae4-b2b1-27d1ceee07a5', height_mm: 600, width_mm: 400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 545, n_factor: 1.33 },
    { uuid: '3f835611-8aaf-4c0f-85ac-5b4c58c887a3', height_mm: 600, width_mm: 500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 681, n_factor: 1.33 },
    { uuid: '773c8a75-7800-4a9a-bcb7-94b44701efbe', height_mm: 600, width_mm: 600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 817, n_factor: 1.33 },
    { uuid: 'e92afd91-6a2b-4017-b6fe-907d232866d8', height_mm: 600, width_mm: 700, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 953, n_factor: 1.33 },
    { uuid: '3e03d1bf-b959-4e48-9128-3fcdc1082a4c', height_mm: 600, width_mm: 800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1090, n_factor: 1.33 },
    { uuid: '70f52d6d-e731-4a87-86eb-8266217266d4', height_mm: 600, width_mm: 900, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1226, n_factor: 1.33 },
    { uuid: '4a11d7f2-ae9e-4124-be02-c034a0d374dc', height_mm: 600, width_mm: 1000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1362, n_factor: 1.33 },
    { uuid: '1bc9d14c-c78e-4d84-8a7b-e3ec4b249c1e', height_mm: 600, width_mm: 1100, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1498, n_factor: 1.33 },
    { uuid: '0bfb50b7-4993-4e25-bf43-6840799a39ca', height_mm: 600, width_mm: 1200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1634, n_factor: 1.33 },
    { uuid: 'da4ab887-f78f-46a6-bc25-1bfb533155a9', height_mm: 600, width_mm: 1400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1907, n_factor: 1.33 },
    { uuid: '29b3bdf0-4c45-44c2-a4c6-9aaccdf4d571', height_mm: 600, width_mm: 1600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2179, n_factor: 1.33 },
    { uuid: 'eb2e612f-551f-4845-bb9e-4115c1e1931d', height_mm: 600, width_mm: 1800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2452, n_factor: 1.33 },
    { uuid: 'c6b847f6-3647-483e-a5a0-8383ffd43b32', height_mm: 600, width_mm: 2000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2690, n_factor: 1.33 },
    { uuid: 'ce7c63f4-68d5-4640-9abc-3e44372f8523', height_mm: 600, width_mm: 2200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2959, n_factor: 1.33 },
    { uuid: '186ae4ce-1e3d-4ae0-9491-a7bcc3fc01bc', height_mm: 600, width_mm: 2400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3228, n_factor: 1.33 },
    { uuid: '0ce5cef8-5be3-4d17-b2a2-ded72c1158a7', height_mm: 600, width_mm: 2600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3497, n_factor: 1.33 },
    { uuid: '17c80066-4fd0-4ce7-ae00-f26822c75b48', height_mm: 600, width_mm: 2800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3766, n_factor: 1.33 },
    { uuid: 'ed72e154-558c-4639-a9e8-94062e0d921a', height_mm: 600, width_mm: 3000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 4035, n_factor: 1.33 },
    { uuid: 'd5f6ec6b-2633-4183-869c-9c9fbcc53dcf', height_mm: 700, width_mm: 400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 612, n_factor: 1.34 },
    { uuid: 'ffa4027e-7728-4cee-a4fa-04d3534f473e', height_mm: 700, width_mm: 500, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 770, n_factor: 1.34 },
    { uuid: '4f1f5524-3f42-4f34-844d-9e9f3a34b6f5', height_mm: 700, width_mm: 600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 923, n_factor: 1.34 },
    { uuid: '0c073cb0-d59f-4c91-a12e-e31392b01b6d', height_mm: 700, width_mm: 700, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1071, n_factor: 1.34 },
    { uuid: 'f751f404-d47b-4721-a75c-d930e6041019', height_mm: 700, width_mm: 800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1224, n_factor: 1.34 },
    { uuid: 'ba058a25-407f-4a66-939f-d070e4481724', height_mm: 700, width_mm: 900, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1377, n_factor: 1.34 },
    { uuid: '512ae194-f020-4764-8553-4f75d2edf5ff', height_mm: 700, width_mm: 1000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1530, n_factor: 1.34 },
    { uuid: 'd8c9fdfb-6c5a-4e95-b26a-d0ee3c2d04aa', height_mm: 700, width_mm: 1100, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1683, n_factor: 1.34 },
    { uuid: '9a1499d0-1889-4213-8c3a-708c696f4bbc', height_mm: 700, width_mm: 1200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 1836, n_factor: 1.34 },
    { uuid: 'c7b7c376-509a-4033-8672-b065ca2e439f', height_mm: 700, width_mm: 1400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2142, n_factor: 1.34 },
    { uuid: '6554e6e8-ac45-49be-8964-a2c02172c10e', height_mm: 700, width_mm: 1600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2448, n_factor: 1.34 },
    { uuid: 'b9742693-774a-4ad5-ac39-0f06d87ebf6a', height_mm: 700, width_mm: 1800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 2754, n_factor: 1.34 },
    { uuid: '04c5640a-a09d-474b-8d11-28575ed15a16', height_mm: 700, width_mm: 2000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3060, n_factor: 1.34 },
    { uuid: 'b94ac49f-d1c9-4ba6-8a5a-9efaad5a7fbc', height_mm: 700, width_mm: 2200, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3366, n_factor: 1.34 },
    { uuid: '05ff6686-78f2-4703-8527-3fc482f34126', height_mm: 700, width_mm: 2400, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3672, n_factor: 1.34 },
    { uuid: '42ab92f5-1dbf-42c8-83e0-093b27231301', height_mm: 700, width_mm: 2600, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 3978, n_factor: 1.34 },
    { uuid: '7eac4c2f-8059-4810-9f42-851d85992949', height_mm: 700, width_mm: 2800, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 4284, n_factor: 1.34 },
    { uuid: '903f346b-4c76-45c6-b5a1-667c9eb9ed37', height_mm: 700, width_mm: 3000, category: 'Radiator', type: 'Type 21 (P+)', output_capacity_w_at_50_c: 4590, n_factor: 1.34 },
    { uuid: 'dd6cc896-7bc7-4b7d-a96d-63fa3b83b393', height_mm: 300, width_mm: 500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 491, n_factor: 1.33 },
    { uuid: '6453b053-fbb4-4520-8876-c8588884fde0', height_mm: 300, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 983, n_factor: 1.33 },
    { uuid: '7b81bdf2-70f1-4e0a-a640-f4a19d2d28cf', height_mm: 300, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1376, n_factor: 1.33 },
    { uuid: 'bf491ae9-635c-4277-b8fd-b867f06762f4', height_mm: 300, width_mm: 1500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1259, n_factor: 1.33 },
    { uuid: '4193488f-cad1-4fe1-a81b-a0ae3545e58a', height_mm: 300, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1769, n_factor: 1.33 },
    { uuid: 'b56a4d69-d68a-4f04-aa0b-b5295d4bde57', height_mm: 300, width_mm: 2000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1964, n_factor: 1.33 },
    { uuid: '5fc5b134-641f-4aa4-bb72-a65f87b5acf1', height_mm: 300, width_mm: 2500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2455, n_factor: 1.33 },
    { uuid: '46395625-54ca-45e9-b34c-5b2c7819b7a7', height_mm: 300, width_mm: 3000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2946, n_factor: 1.33 },
    { uuid: 'f1d1dd2f-570f-45e2-a8d0-bfa79da361ca', height_mm: 400, width_mm: 400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 693, n_factor: 1.33 },
    { uuid: 'e56b529e-df3c-4401-b1ad-5d1a30dcc46e', height_mm: 400, width_mm: 800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1007, n_factor: 1.33 },
    { uuid: '54d3cfcf-3ef7-45a2-9eda-7d2219b18c6c', height_mm: 400, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1259, n_factor: 1.33 },
    { uuid: 'def3ff40-71e2-4abe-80bb-b650bba28483', height_mm: 400, width_mm: 1200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1511, n_factor: 1.33 },
    { uuid: '3937dbb6-3eed-40db-8e52-1529cbf5568a', height_mm: 400, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1763, n_factor: 1.33 },
    { uuid: '13ce20eb-1033-4aad-ad44-37e95669f04b', height_mm: 400, width_mm: 1600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2014, n_factor: 1.33 },
    { uuid: 'e079cd0c-e8e6-4862-853c-17cede28ffb2', height_mm: 400, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2266, n_factor: 1.33 },
    { uuid: 'e4f8cf94-202b-4568-8865-87639fa13d3a', height_mm: 400, width_mm: 2000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2518, n_factor: 1.33 },
    { uuid: '0a192587-0862-4f2d-a8c7-ca80f374d801', height_mm: 450, width_mm: 400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 548, n_factor: 1.33 },
    { uuid: 'e6c58008-ee64-496b-9f27-f802d9249b8b', height_mm: 450, width_mm: 500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 686, n_factor: 1.33 },
    { uuid: 'a87259da-440e-4c97-895e-232a7a1a907d', height_mm: 450, width_mm: 600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 823, n_factor: 1.33 },
    { uuid: '4fac4b3e-dd48-460f-b08b-c873602973fa', height_mm: 450, width_mm: 700, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 960, n_factor: 1.33 },
    { uuid: '831d3fd2-0a3e-45a9-aa1f-f025e8136dae', height_mm: 450, width_mm: 800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1097, n_factor: 1.33 },
    { uuid: 'e076b916-7c4a-4597-8ebf-3e6492c5f176', height_mm: 450, width_mm: 900, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1234, n_factor: 1.33 },
    { uuid: 'f2545467-2713-4d7f-92f1-0b105bd1ef31', height_mm: 450, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1371, n_factor: 1.33 },
    { uuid: 'bab3a464-b3d0-4863-8b73-219e27dfe864', height_mm: 450, width_mm: 1100, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1508, n_factor: 1.33 },
    { uuid: 'fb18e25d-4d95-467e-9629-4631f21d8550', height_mm: 450, width_mm: 1200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1645, n_factor: 1.33 },
    { uuid: 'd5a3a7db-2fd2-44da-bdb5-fe15d2c5b2bb', height_mm: 450, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1919, n_factor: 1.33 },
    { uuid: 'c0db2baf-e73d-47a2-afa4-ee840df40888', height_mm: 450, width_mm: 1600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2194, n_factor: 1.33 },
    { uuid: 'bcd980a6-ea3a-4d2c-bec7-ce5579be0b80', height_mm: 450, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2468, n_factor: 1.33 },
    { uuid: '213ff225-ce7a-40b6-aedb-f6e3c2fb5d21', height_mm: 450, width_mm: 2000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2742, n_factor: 1.33 },
    { uuid: 'dbb6b13d-62da-4af5-9c5c-9900b6569dc9', height_mm: 450, width_mm: 2200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3016, n_factor: 1.33 },
    { uuid: '5a44914c-6a29-407a-b6f6-59d4af312bff', height_mm: 450, width_mm: 2400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3290, n_factor: 1.33 },
    { uuid: '481817dd-02b6-405c-be32-8e6e5a5b58f8', height_mm: 450, width_mm: 2600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3565, n_factor: 1.33 },
    { uuid: 'b2542c80-9022-4397-a37d-3f63e958f724', height_mm: 450, width_mm: 2800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3839, n_factor: 1.33 },
    { uuid: '55f36ed4-59c0-4458-ba89-3273fd40374c', height_mm: 450, width_mm: 3000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4113, n_factor: 1.33 },
    { uuid: 'c9815f3b-8088-4e76-be12-71fdd18b7d17', height_mm: 500, width_mm: 600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 908, n_factor: 1.33 },
    { uuid: 'a438c49d-9bee-42b4-ada5-41adbd83fbff', height_mm: 500, width_mm: 800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1210, n_factor: 1.33 },
    { uuid: 'af745ab4-a44e-4ed8-a2ce-93ff6c66e4cf', height_mm: 500, width_mm: 900, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1362, n_factor: 1.33 },
    { uuid: 'c954451d-e1ab-4d55-aec4-eb2eeae688ce', height_mm: 500, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1513, n_factor: 1.33 },
    { uuid: 'ff5da38e-60e5-4b85-906d-76afe74e3213', height_mm: 500, width_mm: 1100, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1664, n_factor: 1.33 },
    { uuid: 'b0fdd171-a55d-44fc-8773-5a9d84108cd8', height_mm: 500, width_mm: 1200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1816, n_factor: 1.33 },
    { uuid: 'fc713868-b685-4e3e-bb4f-dfcc08aa0a08', height_mm: 500, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2118, n_factor: 1.33 },
    { uuid: '2b8b2cc8-3e4a-43d3-84e8-ae8322f0b0e7', height_mm: 500, width_mm: 1600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2421, n_factor: 1.33 },
    { uuid: 'f896e022-6701-4a20-b6d2-e18747f9ea52', height_mm: 500, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2723, n_factor: 1.33 },
    { uuid: 'b3f308c0-4b06-40c4-8dd7-76d7bcf1bc55', height_mm: 600, width_mm: 300, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 520, n_factor: 1.33 },
    { uuid: '1e53ed77-37f6-4c05-beb5-25c561f55bde', height_mm: 600, width_mm: 400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 693, n_factor: 1.33 },
    { uuid: 'cb4740bb-3e09-4871-9463-3ffbcbc30bdd', height_mm: 600, width_mm: 500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 874, n_factor: 1.33 },
    { uuid: 'f4e31949-7b75-4e4a-9434-b8f1ccbe9ab0', height_mm: 600, width_mm: 600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1048, n_factor: 1.33 },
    { uuid: '3bee996b-df32-4c74-9636-b90730116fe0', height_mm: 600, width_mm: 700, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1223, n_factor: 1.33 },
    { uuid: 'b0834005-6544-4396-baaf-76571edff51f', height_mm: 600, width_mm: 800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1398, n_factor: 1.33 },
    { uuid: '7dc61143-3ef7-43cb-be99-8af2d825b506', height_mm: 600, width_mm: 900, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1572, n_factor: 1.33 },
    { uuid: '1b06550f-861f-4201-a0c3-6e930ca2079b', height_mm: 600, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1747, n_factor: 1.33 },
    { uuid: '127856fc-408c-4440-8ac3-d0421060c7df', height_mm: 600, width_mm: 1100, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1922, n_factor: 1.33 },
    { uuid: 'e2344409-c8a2-41ff-b2f2-222fe87fb007', height_mm: 600, width_mm: 1200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2096, n_factor: 1.33 },
    { uuid: 'fb7b7b49-5fc7-4ec0-afaa-84f63f80eed9', height_mm: 600, width_mm: 1300, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2271, n_factor: 1.33 },
    { uuid: '65fb55b8-11de-43d4-bc68-1134c4e8adf2', height_mm: 600, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2446, n_factor: 1.33 },
    { uuid: '862bd52f-9072-463f-aeae-603939434930', height_mm: 600, width_mm: 1600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2795, n_factor: 1.33 },
    { uuid: '2f5b760d-af92-4e81-94a2-7df51e5785c1', height_mm: 600, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3145, n_factor: 1.33 },
    { uuid: '1b86c87c-708e-433f-a1ab-3e71e72c9c6d', height_mm: 600, width_mm: 2000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3494, n_factor: 1.33 },
    { uuid: '0f15038f-e414-4fb3-9639-1ccf9f908dfe', height_mm: 600, width_mm: 2200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3810, n_factor: 1.33 },
    { uuid: '11262f10-cd8a-4ccc-823e-82bb3aea2822', height_mm: 600, width_mm: 2400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4157, n_factor: 1.33 },
    { uuid: '0f7d7e8a-481b-4d12-a0cf-033780840594', height_mm: 600, width_mm: 2600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4503, n_factor: 1.33 },
    { uuid: '63c56565-25c6-48cf-9497-f0f01bb944f4', height_mm: 600, width_mm: 2800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4850, n_factor: 1.33 },
    { uuid: 'd50643b3-064a-4843-b4ad-281857464c09', height_mm: 600, width_mm: 3000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 5196, n_factor: 1.33 },
    { uuid: '65355c5b-77f5-44c1-a8a1-02c1f038ce3c', height_mm: 700, width_mm: 300, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 588, n_factor: 1.34 },
    { uuid: '96486676-8b33-4e94-9d66-e8b6f582d91b', height_mm: 700, width_mm: 400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 785, n_factor: 1.34 },
    { uuid: '7a1db0ab-807a-41d1-97a7-78762712172b', height_mm: 700, width_mm: 500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 982, n_factor: 1.34 },
    { uuid: '2acf5207-fa3e-4a92-8748-dd2878b4fad8', height_mm: 700, width_mm: 600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1178, n_factor: 1.34 },
    { uuid: 'c488e4d4-5a89-4e8c-96c1-c2cd55d5e7ee', height_mm: 700, width_mm: 700, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1374, n_factor: 1.34 },
    { uuid: '4ece2900-df1d-4778-be6a-ff10c1d5ef41', height_mm: 700, width_mm: 800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1570, n_factor: 1.34 },
    { uuid: '6daa3fe9-8a89-4b9c-9c7a-29c1445646ba', height_mm: 700, width_mm: 900, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1767, n_factor: 1.34 },
    { uuid: 'b2a425b3-4002-4fef-9631-0e56a192b979', height_mm: 700, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1963, n_factor: 1.34 },
    { uuid: '89fc4728-dfc2-4eb2-b7ef-edc99f7aef13', height_mm: 700, width_mm: 1100, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2159, n_factor: 1.34 },
    { uuid: '988d37e3-c4a2-49af-91fb-646205a23b8f', height_mm: 700, width_mm: 1200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2356, n_factor: 1.34 },
    { uuid: 'a9a6e07c-31f3-45df-8908-1663efddbab7', height_mm: 700, width_mm: 1400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2748, n_factor: 1.34 },
    { uuid: '8371f26e-9fbf-4188-9f13-7bc107597bac', height_mm: 700, width_mm: 1600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3138, n_factor: 1.34 },
    { uuid: 'd003670e-88e1-474b-b3d6-1799cbdb4bf8', height_mm: 700, width_mm: 1800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3530, n_factor: 1.34 },
    { uuid: 'a26518db-70b9-473e-89a0-ebd9e172f0e1', height_mm: 700, width_mm: 2000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 3922, n_factor: 1.34 },
    { uuid: 'c840d6a6-4acf-4707-b6a3-392626038fa7', height_mm: 700, width_mm: 2200, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4314, n_factor: 1.34 },
    { uuid: 'd38580be-46a0-49ec-9ad7-a7a3a4deb892', height_mm: 700, width_mm: 2400, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 4706, n_factor: 1.34 },
    { uuid: '88c85930-e2b6-4176-9ebe-381ea33dd527', height_mm: 700, width_mm: 2600, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 5099, n_factor: 1.34 },
    { uuid: 'a4b370e1-7f9c-4e38-ac32-5338f1ba58cd', height_mm: 700, width_mm: 2800, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 5491, n_factor: 1.34 },
    { uuid: 'bcc54548-b72a-490b-a007-1e5b561ea161', height_mm: 700, width_mm: 3000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 5883, n_factor: 1.34 },
    { uuid: '7c18d47d-c3cb-4afd-bf71-34e614a9e6c7', height_mm: 900, width_mm: 500, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1198, n_factor: 1.35 },
    { uuid: '5f52edfb-99bf-42cc-bb05-926346b24def', height_mm: 900, width_mm: 700, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 1677, n_factor: 1.35 },
    { uuid: 'b1ef4ed4-30cd-4237-aaad-584f724c3fe1', height_mm: 900, width_mm: 1000, category: 'Radiator', type: 'Type 22 (K2)', output_capacity_w_at_50_c: 2395, n_factor: 1.35 },
    { uuid: '2719731a-faaa-40cc-8650-f85c62413ce9', height_mm: 300, width_mm: 1000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1349, n_factor: 1.31 },
    { uuid: 'a9cbc58a-3462-4345-a40b-fb1f25edf2bb', height_mm: 300, width_mm: 2000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2698, n_factor: 1.31 },
    { uuid: 'fdbfa460-f628-4398-9d72-a0b8d4dd4553', height_mm: 500, width_mm: 600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1234, n_factor: 1.32 },
    { uuid: '2c8690d1-3439-4de8-beb8-7e22ce2b0185', height_mm: 500, width_mm: 700, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1439, n_factor: 1.32 },
    { uuid: '917bf368-7083-480c-9cad-0560c14ba595', height_mm: 500, width_mm: 800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1645, n_factor: 1.32 },
    { uuid: 'd90310e2-c284-4f10-8f76-a5e0cbc6ce37', height_mm: 500, width_mm: 900, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1850, n_factor: 1.32 },
    { uuid: '4b391186-4d87-465a-b0c9-d91b5bea72ef', height_mm: 500, width_mm: 1000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2056, n_factor: 1.32 },
    { uuid: '34e821cd-bf3e-4881-9d2c-e1b8356ff201', height_mm: 500, width_mm: 1100, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2262, n_factor: 1.32 },
    { uuid: 'ad81cd4e-7e92-48d7-88db-8d7902e77aa6', height_mm: 500, width_mm: 1200, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2467, n_factor: 1.32 },
    { uuid: '3a837601-53ae-4286-b5ce-247d9fa53233', height_mm: 500, width_mm: 1400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2878, n_factor: 1.32 },
    { uuid: '91cfb4ec-84b4-42a8-a6c2-8b04b0809725', height_mm: 500, width_mm: 1600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3290, n_factor: 1.32 },
    { uuid: '62f2283a-ccd8-447c-bdfa-76ee925965ed', height_mm: 500, width_mm: 1800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3701, n_factor: 1.32 },
    { uuid: 'a82ce1ad-b1f2-4a5b-8cb4-570abd9fae06', height_mm: 500, width_mm: 2000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4112, n_factor: 1.32 },
    { uuid: 'dcc5a1d9-9013-4c84-af35-a1053f494cb7', height_mm: 500, width_mm: 2400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4934, n_factor: 1.32 },
    { uuid: '80a5a6c5-4391-4b75-8ecd-f8c638221b14', height_mm: 600, width_mm: 400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 956, n_factor: 1.32 },
    { uuid: '97d43249-a495-43ee-8a12-8499940b9052', height_mm: 600, width_mm: 500, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1195, n_factor: 1.32 },
    { uuid: 'b69b6f41-6d32-4dfc-a3c8-b81b399e40bd', height_mm: 600, width_mm: 600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1433, n_factor: 1.32 },
    { uuid: 'f204de79-c40e-4d8b-9de1-b858f0210936', height_mm: 600, width_mm: 700, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1672, n_factor: 1.32 },
    { uuid: 'f42bbc5d-2876-46e5-87d3-69a585e0cb01', height_mm: 600, width_mm: 800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1911, n_factor: 1.32 },
    { uuid: 'e77b9f50-7207-4223-8313-0d59db174d2d', height_mm: 600, width_mm: 900, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2150, n_factor: 1.32 },
    { uuid: '49e8b46f-fb66-4e0c-9d82-29498c9e02af', height_mm: 600, width_mm: 1000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2389, n_factor: 1.32 },
    { uuid: '3229c854-2311-4626-9d88-4c2418c34e3a', height_mm: 600, width_mm: 1100, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2628, n_factor: 1.32 },
    { uuid: '69d9d0b4-7f20-41d3-9606-1d771071de40', height_mm: 600, width_mm: 1200, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2867, n_factor: 1.32 },
    { uuid: 'ed4ff2e2-7ae8-4d7a-aa2f-6fc0273168ca', height_mm: 600, width_mm: 1400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3345, n_factor: 1.32 },
    { uuid: '96d4773f-d66e-4027-8f33-a29ed82aacb7', height_mm: 600, width_mm: 1600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3822, n_factor: 1.32 },
    { uuid: '9f7a0ad7-73f2-47ee-906e-0f9f6e31e4aa', height_mm: 600, width_mm: 1800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4300, n_factor: 1.32 },
    { uuid: '03d773b6-227b-479c-be58-692885f79642', height_mm: 600, width_mm: 2000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4778, n_factor: 1.32 },
    { uuid: '2e96621e-e6e6-4ab0-92fb-929fe4c3ceca', height_mm: 600, width_mm: 2400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 5734, n_factor: 1.32 },
    { uuid: '1d6a2f0d-30ee-4620-ab24-ea4f7cb170fd', height_mm: 700, width_mm: 500, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1356, n_factor: 1.34 },
    { uuid: '07bd6003-7995-493d-9908-ba2a8c5620ca', height_mm: 700, width_mm: 600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1627, n_factor: 1.34 },
    { uuid: '98cd7a17-377c-4c8d-894a-284c94b2d17b', height_mm: 700, width_mm: 700, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 1898, n_factor: 1.34 },
    { uuid: 'e3a6456a-95fd-40d3-b6f3-7647b353e30f', height_mm: 700, width_mm: 800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2170, n_factor: 1.34 },
    { uuid: 'f4ebe015-5ccb-4fcf-89fe-beb06c85eb7d', height_mm: 700, width_mm: 900, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2441, n_factor: 1.34 },
    { uuid: '92beaa31-e92b-45b3-8542-b1589de81ce0', height_mm: 700, width_mm: 1000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2712, n_factor: 1.34 },
    { uuid: '20d8bc6f-f2ad-4096-b70c-b2f07e614ce3', height_mm: 700, width_mm: 1100, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 2983, n_factor: 1.34 },
    { uuid: '4f57ac18-b071-421a-b765-cce4e4c1a9f5', height_mm: 700, width_mm: 1200, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3254, n_factor: 1.34 },
    { uuid: '6c2882af-669e-44ba-97e6-d0deeaebfb44', height_mm: 700, width_mm: 1400, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 3797, n_factor: 1.34 },
    { uuid: 'cfee8c4d-a963-4e2d-b38c-fdbab042571b', height_mm: 700, width_mm: 1600, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4439, n_factor: 1.34 },
    { uuid: '771fb1ba-7532-4869-8b66-87ea971fa401', height_mm: 700, width_mm: 1800, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 4882, n_factor: 1.34 },
    { uuid: 'c0e8a3c7-466a-49c9-a50a-c2cc5884575f', height_mm: 700, width_mm: 2000, category: 'Radiator', type: 'Type 33 (K3)', output_capacity_w_at_50_c: 5424, n_factor: 1.34 }
];
