import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const SiteDetailsParkingBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const { survey, setSurvey } = prContext;
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Parking" }), _jsxs("div", { className: "items-center gap-3 flex justify-between", children: [_jsx("div", { className: "flex-col flex", children: _jsx("div", { className: "text-gray-900 text-base font-bold", children: "Parking available" }) }), _jsx(Toggle, { size: 'LG', value: survey.parking_available, setValue: () => setSurvey({ ...survey, parking_available: !survey.parking_available }) })] }), _jsxs("div", { className: "items-center gap-3 flex justify-between", children: [_jsx("div", { className: "flex-col flex", children: _jsx("div", { className: "text-gray-900 text-base font-bold", children: "Permit required" }) }), _jsx(Toggle, { size: 'LG', value: survey.permit_required, setValue: () => setSurvey({ ...survey, permit_required: !survey.permit_required }) })] }), _jsxs("div", { className: "items-center gap-3 flex justify-between", children: [_jsx("div", { className: "flex-col flex", children: _jsx("div", { className: "text-gray-900 text-base font-bold", children: "Space for kit to be delivered" }) }), _jsx(Toggle, { size: 'LG', value: survey.space_for_kit_to_be_delivered, setValue: () => setSurvey({
                            ...survey,
                            space_for_kit_to_be_delivered: !survey.space_for_kit_to_be_delivered
                        }) })] }), _jsxs("div", { className: "gap-3 flex flex-col justify-between", children: [_jsx("div", { className: "flex-col flex", children: _jsx("div", { className: "text-gray-900 text-base font-bold", children: "Notes" }) }), _jsx(TextArea, { size: 'LG', value: survey.notes, setValue: (e) => setSurvey({ ...survey, notes: e }) })] })] });
};
