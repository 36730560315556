import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BubbleMenu, EditorContent, NodeViewWrapper, ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { useCallback, useEffect } from 'react';
import { Editor, mergeAttributes, Node } from '@tiptap/core';
import { Button } from '../buttons/button';
export function tiptapHTMLToJSON(html) {
    // a hack to get the default estimate email text in Tiptap JSON format: apply HTML and then get JSON
    const editor = new Editor({
        extensions: TiptapExtensions,
        content: html
    });
    return editor.getJSON();
}
const InlinePlaceholderWrapper = (props) => {
    return (_jsx(NodeViewWrapper, { as: 'span', children: _jsx("span", { className: 'bg-amber-100 rounded', children: props.node.attrs.label }) }));
};
const ButtonPlaceholderWrapper = (props) => {
    return (_jsx(NodeViewWrapper, { as: 'div', children: _jsx("div", { className: 'table m-auto text-center', children: _jsx(Button, { children: props.node.attrs.label }) }) }));
};
const ButtonPlaceholderComponent = Node.create({
    name: 'button-ph',
    inline: false,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            id: { default: '' },
            label: { default: '' }
        };
    },
    parseHTML() { return [{ tag: 'button-ph' }]; },
    renderHTML({ HTMLAttributes }) { return ['button-ph', mergeAttributes(HTMLAttributes)]; },
    addNodeView() { return ReactNodeViewRenderer(ButtonPlaceholderWrapper); }
});
const InlinePlaceholderComponent = Node.create({
    name: 'inline-ph',
    inline: true,
    group: 'inline',
    atom: true,
    addAttributes() {
        return {
            id: { default: '' },
            label: { default: '' }
        };
    },
    parseHTML() { return [{ tag: 'inline-ph' }]; },
    renderHTML({ HTMLAttributes }) { return ['inline-ph', mergeAttributes(HTMLAttributes)]; },
    addNodeView() { return ReactNodeViewRenderer(InlinePlaceholderWrapper); }
});
// share extensions between all editors and for background rendering
export const TiptapExtensions = [
    StarterKit,
    Link.configure({
        openOnClick: false,
        autolink: true
    }),
    InlinePlaceholderComponent,
    ButtonPlaceholderComponent
];
export const Tiptap = ({ editable, className, content, onUpdateCallback, placeholders, noPlaceholderButtons, onEditorInit }) => {
    const editor = useEditor({
        editable,
        extensions: TiptapExtensions,
        editorProps: {
            attributes: {
                class: className
            }
        },
        onUpdate: ({ editor }) => {
            onUpdateCallback(editor);
        },
        content
    });
    // apply className
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor?.setOptions({
            editorProps: {
                attributes: { class: className }
            }
        });
    }, [className]);
    useEffect(() => {
        if (!onEditorInit) {
            return;
        }
        if (editor) {
            onEditorInit(editor);
        }
    }, [onEditorInit, editor]);
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor?.setEditable(editable);
    }, [editable]);
    const setLink = useCallback(() => {
        if (!editor) {
            return null;
        }
        const previousUrl = editor.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);
        // cancelled
        if (url === null) {
            return;
        }
        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run();
            return;
        }
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run();
    }, [editor]);
    if (!editor) {
        return null;
    }
    return (_jsxs("div", { children: [editor && _jsxs(BubbleMenu, { className: "flex flex-row gap-2 bg-black p-1 rounded text-sm", tippyOptions: { duration: 100 }, editor: editor, children: [_jsx("button", { onClick: () => editor.chain().focus().toggleBold().run(), className: `${editor.isActive('bold') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`, children: "Bold" }), _jsx("button", { onClick: () => editor.chain().focus().toggleItalic().run(), className: `${editor.isActive('italic') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`, children: "Italic" }), _jsx("button", { onClick: setLink, className: `${editor.isActive('link') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`, children: "Link" }), editor.isActive('link') &&
                        _jsx("button", { onClick: () => editor.chain().focus().unsetLink().run(), className: `${editor.isActive('link') ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`, children: "Unlink" }), _jsx("button", { onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(), className: `${editor.isActive('heading', { level: 1 }) ? 'text-blue-300' : 'text-white'} border-0 bg-none p-1 hover:text-gray-300`, children: "Heading" })] }), _jsxs("div", { children: [placeholders && !noPlaceholderButtons && _jsx("div", { className: "pb-2 justify-start items-start gap-2.5 flex overflow-x-auto relative no-scrollbar md:flex-wrap", children: placeholders?.map((control) => {
                            return _jsx("button", { onClick: () => editor.chain().focus().insertContent(control.code).run(), className: `${control.type === 'button-ph' ? 'hover:bg-black text-white bg-gray-700' : 'hover:bg-amber-300 bg-amber-100'} border-0 bg-none py-1 px-2 whitespace-nowrap text-nowrap text-sm rounded`, children: control.label }, control.id);
                        }) }), _jsx(EditorContent, { editor: editor, className: `${editable ? '' : 'bg-gray-100'}` })] })] }));
};
