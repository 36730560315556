import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PageHeader } from '../components/design_page_header';
import { getEmitterSizeName, getEmitterTypeName, getRadiatorHeightWidthText } from '../../../../code/models/radiator';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Button } from '../../../../components/buttons/button';
import { getRoomTemp, getRoomWatts } from '../../../../code/models/heat_loss';
import { getEmitterVelocityMPerS, getEmitterWatts, getRadiatorWatts } from '../../../../code/models/radiator_model';
import { chain, noop, sum } from 'lodash';
import { Slider } from '../../../../components/inputs_and_selections/slider';
import { getRoomEmitterWatts } from '../../../../code/models/room';
import { DEFAULT_DESIGN_RADIATOR, DEFAULT_DESIGN_UNDERFLOOR, DEFAULT_SURVEY_RADIATOR } from '../../../../code/survey_defaults';
import { ClickableCard } from '../../../../components/content_display/card';
import { Link } from '../../../../components/buttons/link';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
import { SelectRadiatorModelInner } from './radiator_model_selector';
import { getDefaultUnderfloorHeating } from '../../../../code/models/underfloor_heating';
import { getEmitterOutputVsDemandText } from '../../floor/code/utils';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { ListItem } from '../../../../components/content_display/list_item';
import { UnderfloorEmitter } from '../../underfloor/underfloor_emitter';
import { numberFormat } from '../../../../code/number_format';
import { useAnimate } from 'framer-motion';
import { ArrowRight, Trash } from 'lucide-react';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
export const EmitterDesignPage = ({ customRadiatorModels, setCustomRadiatorModels, setFlowTemp, minFlowTemp, maxFlowTemp, designTempC, groundTempC, survey, design, setDesign, annualBillEstimateGBP, files, emitterDemandStatus, totalHeatLossKw, companyUuid, setSurvey, setFiles, isOffline, allSynced }) => {
    // control link between each radiator card and the radiator type inner modal
    const [addRadiatorModal, setAddRadiatorModal] = useState({
        room: undefined,
        floor: undefined,
        roomTempC: undefined,
        requiredOutputW: undefined,
        originalEmitter: undefined,
        replacementEmitter: undefined
    });
    const [emitterUUIDToHighlight, setEmitterUUIDToHighlight] = useState(undefined);
    const setUnderfloorHeating = async (updateFnOrValue) => setAddRadiatorModal(prev => {
        const updatedEmitter = typeof updateFnOrValue === 'function' ? updateFnOrValue(prev.replacementEmitter) : updateFnOrValue;
        return { ...prev, replacementEmitter: updatedEmitter };
    });
    const [pages, setPages] = useState(['RADIATOR_MODEL']);
    const page = pages[pages.length - 1];
    const onBack = () => setPages(prev => prev.slice(0, -1));
    const setPage = (page) => setPages(prev => [...prev, page]);
    const totalWattsEmitted = sum(survey.floors.map(f => sum(f.rooms.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)))));
    const defaultUnderfloorHeating = getDefaultUnderfloorHeating({
        allPreviousUfh: design.radiators?.filter(x => x.emitter_type === 'UNDERFLOOR'),
        fallbackDefaultUfh: DEFAULT_DESIGN_UNDERFLOOR,
        manifolds: survey.manifolds
    });
    // Select an emitter type.
    return _jsxs(_Fragment, { children: [addRadiatorModal.room && !addRadiatorModal.replacementEmitter &&
                _jsxs("div", { className: 'flex flex-col h-full', children: [_jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Select emitter', onBack: () => setAddRadiatorModal({
                                room: undefined,
                                roomTempC: undefined,
                                requiredOutputW: undefined,
                                originalEmitter: undefined,
                                replacementEmitter: undefined,
                                floor: undefined
                            }) }), _jsxs("div", { className: 'flex flex-col divide-y divide-gray-200', children: [_jsx(ListItem, { primaryText: 'Radiator', onClick: () => {
                                        setAddRadiatorModal(prev => ({
                                            ...prev,
                                            replacementEmitter: {
                                                ...DEFAULT_SURVEY_RADIATOR,
                                                survey_or_design: 'DESIGN',
                                                emitter_type: 'RADIATOR',
                                                room_uuid: prev.room.uuid,
                                                pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                                                updated_at: new Date().getTime(),
                                                created_at: new Date().getTime()
                                            }
                                        }));
                                    } }), !addRadiatorModal.originalEmitter && _jsx(ListItem, { primaryText: 'Underfloor heating', onClick: () => {
                                        setAddRadiatorModal(prev => ({
                                            ...prev,
                                            replacementEmitter: {
                                                ...defaultUnderfloorHeating,
                                                room_uuid: prev.room.uuid,
                                                updated_at: new Date().getTime(),
                                                created_at: new Date().getTime()
                                            }
                                        }));
                                    } })] })] }), addRadiatorModal.room && addRadiatorModal.replacementEmitter && addRadiatorModal.replacementEmitter.emitter_type === 'UNDERFLOOR' &&
                _jsxs("div", { className: 'flex flex-col h-full', children: [_jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: `Underfloor heating - ${addRadiatorModal.room.name}`, onBack: () => setAddRadiatorModal({
                                room: undefined,
                                roomTempC: undefined,
                                requiredOutputW: undefined,
                                originalEmitter: undefined,
                                replacementEmitter: undefined,
                                floor: undefined
                            }) }), _jsx(UnderfloorEmitter, { underfloorHeating: addRadiatorModal.replacementEmitter, setUnderfloorHeating: setUnderfloorHeating, room: addRadiatorModal.room, floor: addRadiatorModal.floor, survey: survey, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, onSave: () => {
                                const newUUID = crypto.randomUUID();
                                setDesign({
                                    ...design,
                                    radiators: addRadiatorModal.replacementEmitter?.uuid
                                        ? design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter.uuid ? addRadiatorModal.replacementEmitter : x)
                                        : [...design.radiators, { ...addRadiatorModal.replacementEmitter, uuid: newUUID }]
                                });
                                setAddRadiatorModal({
                                    room: undefined,
                                    roomTempC: undefined,
                                    requiredOutputW: undefined,
                                    originalEmitter: undefined,
                                    replacementEmitter: undefined,
                                    floor: undefined
                                });
                                setEmitterUUIDToHighlight(addRadiatorModal.replacementEmitter?.uuid ?? newUUID);
                            }, files: files, setFiles: setFiles, design: design, setSurvey: setSurvey, page: page, setPage: setPage, onBack: onBack, companyUUID: companyUuid })] }), addRadiatorModal.room && addRadiatorModal.replacementEmitter && addRadiatorModal.replacementEmitter.emitter_type !== 'UNDERFLOOR' &&
                _jsxs("div", { className: 'flex flex-col h-full', children: [page === 'RADIATOR_MODEL' && _jsx(PageHeader, { title: `Select radiator - ${addRadiatorModal.room.name}`, isOffline: isOffline, allSynced: allSynced, onBack: () => setAddRadiatorModal({
                                room: undefined,
                                roomTempC: undefined,
                                requiredOutputW: undefined,
                                originalEmitter: undefined,
                                replacementEmitter: undefined,
                                floor: undefined
                            }) }), page === 'ADD_CUSTOM_RADIATOR' && _jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Add custom radiator', onBack: onBack }), _jsx("div", { className: 'overflow-y-auto', children: _jsx(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, originallySelectedModelUuid: (addRadiatorModal.replacementEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.replacementEmitter?.radiator_type?.uuid : '') ??
                                    (addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.radiator_type?.uuid : ''), maxWidthMm: addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.maximum_width : undefined, maxHeightMm: addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' ? addRadiatorModal.originalEmitter?.maximum_height : undefined, flowTemp: design.flow_temp, deltaTFlowReturnC: design.delta_t_flow_return_c, roomTemp: addRadiatorModal.roomTempC, requiredOutput: addRadiatorModal.requiredOutputW, companyUuid: companyUuid, addRadiator: (radiatorModel) => {
                                    // a variable to hightlight the newly added radiator
                                    let highlightUUID;
                                    if (addRadiatorModal.replacementEmitter?.uuid) {
                                        highlightUUID = addRadiatorModal.replacementEmitter.uuid;
                                        setDesign({
                                            ...design,
                                            radiators: design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter.uuid ? {
                                                ...x,
                                                radiator_type: radiatorModel,
                                                radiator_type_uuid: radiatorModel.uuid,
                                                updated_at: new Date().getTime()
                                            } : x)
                                        });
                                    }
                                    else { // add
                                        // if replacing another rad, use survey pipework diameter if defined. Otherwise assume same as default rad pipework for now. Should really let user choose on add
                                        const pipeModelUuid = addRadiatorModal.originalEmitter?.emitter_type === 'RADIATOR' || addRadiatorModal.originalEmitter?.emitter_type === 'UNDERFLOOR'
                                            ? addRadiatorModal.originalEmitter.pipe_model_uuid
                                            : survey.existing_system_radiator_pipework_uuid;
                                        const newRadiator = {
                                            ...DEFAULT_DESIGN_RADIATOR,
                                            uuid: crypto.randomUUID(),
                                            emitter_type: 'RADIATOR',
                                            radiator_type: radiatorModel,
                                            radiator_type_uuid: radiatorModel.uuid,
                                            pipe_model_uuid: pipeModelUuid,
                                            pipe_diameter_uuid: undefined,
                                            survey_design_uuid: design.uuid,
                                            room_uuid: addRadiatorModal.room.uuid,
                                            replaces_uuid: addRadiatorModal.originalEmitter?.uuid,
                                            created_at: new Date().getTime(),
                                            updated_at: new Date().getTime(),
                                            maximum_height: 0,
                                            maximum_width: 0,
                                            notes: ''
                                        };
                                        highlightUUID = newRadiator.uuid;
                                        setDesign({ ...design, radiators: [...design.radiators, newRadiator] });
                                    }
                                    setAddRadiatorModal({
                                        room: undefined,
                                        roomTempC: undefined,
                                        requiredOutputW: undefined,
                                        originalEmitter: undefined,
                                        replacementEmitter: undefined,
                                        floor: undefined
                                    });
                                    setEmitterUUIDToHighlight(highlightUUID);
                                }, designContextContent: _jsx(SelectRadiatorModelDesignContext, { files: files, requiredOutput: addRadiatorModal.requiredOutputW, originalEmitter: addRadiatorModal.originalEmitter, companyUUID: companyUuid }), setPage: setPage, page: page, onBack: onBack }) })] }), _jsxs("div", { className: `${addRadiatorModal.room ? 'hidden' : 'flex'} flex-col h-full min-h-0`, children: [_jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Emitters', onBack: () => window.history.back() }), _jsxs("div", { className: 'flex flex-col gap-6 p-4 overflow-y-auto', children: [_jsx(ClickableCard, { variant: 'WHITE', children: _jsxs("div", { className: 'flex flex-col gap-4 justify-between flex-grow', children: [_jsx(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "text-gray-900 text-xs font-bold", children: "Running costs" }), _jsxs("div", { className: "text-gray-600 text-sm", children: [annualBillEstimateGBP, " per year"] })] }), _jsxs("div", { className: "flex-col gap-1.5 flex", children: [_jsx("div", { className: "text-gray-900 text-xs font-bold", children: "Emitters by room" }), _jsx(Badge, { color: emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED', text: emitterDemandStatus })] }), _jsxs("div", { className: "flex-col gap-1.5 flex", children: [_jsx("div", { className: "text-gray-900 text-xs font-bold", children: "Emitters overall" }), _jsx("div", { className: "text-gray-600 text-sm", children: `${numberFormat(2).format(totalWattsEmitted / 1000)} kW of ${numberFormat(2).format(totalHeatLossKw)} kW` })] })] })] }) }), survey.floors.map((f, fIdx) => {
                                const roomGroups = chain(f.rooms)
                                    .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
                                    .map((values, key) => ({ key, values }))
                                    .value();
                                const floorEmitterWatts = sum(f.rooms.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)));
                                const floorDemandWatts = sum(f.rooms.map(r => getRoomWatts(r, f.rooms, designTempC, groundTempC, survey)));
                                return _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: "border border-gray-200" }), _jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: 'font-bold text-lg text-gray-900', children: f.name }), _jsx("div", { className: "font-bold text-xs text-gray-900", children: getEmitterOutputVsDemandText(floorEmitterWatts, floorDemandWatts) })] }), roomGroups.map(rg => _jsx(_Fragment, { children: getRoomGroupRadiatorCards(emitterUUIDToHighlight, setEmitterUUIDToHighlight, rg, f, designTempC, groundTempC, survey, design, setAddRadiatorModal, setDesign) }))] }, fIdx);
                            })] })] })] });
};
const UndersizedToggle = ({ design, roomOrRoomGroupUUID, setDesign }) => {
    const undersized = design.undersized_emitter_rooms.find(x => x.room_uuid === roomOrRoomGroupUUID);
    return _jsxs("div", { className: "px-3 py-4 flex-col justify-center gap-4 flex bg-white border border-gray-200 rounded-lg", children: [_jsxs("div", { className: "items-center gap-4 flex justify-between", children: [_jsx("div", { className: "text-gray-900 text-sm font-bold", children: "Accept undersized emitters?" }), _jsx(Toggle, { value: !!undersized, setValue: () => setDesign({
                            ...design,
                            undersized_emitter_rooms: undersized
                                ? design.undersized_emitter_rooms.filter(x => x.room_uuid !== roomOrRoomGroupUUID)
                                : [...design.undersized_emitter_rooms, { room_uuid: roomOrRoomGroupUUID, notes: '' }]
                        }) })] }), !!undersized && _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 text-sm font-bold", children: "Details" }), _jsx("div", { className: "text-gray-500 text-xs", children: "Please provide reasoning that will show on homeowner report" })] }), _jsx(TextArea, { value: undersized.notes, setValue: (e) => setDesign({ ...design, undersized_emitter_rooms: design.undersized_emitter_rooms.map(x => x.room_uuid === undersized.room_uuid ? { ...undersized, notes: e } : x) }) })] })] });
};
const getRoomGroupRadiatorCards = (emitterUUIDToHighlight, setEmitterUUIDToHighlight, roomGroup, floor, designTempC, groundTempC, survey, design, setAddRadiatorModal, setDesign) => {
    const roomGroupEmitterWatts = sum(roomGroup.values.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)));
    const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, designTempC, groundTempC, survey)));
    const roomGroupShortfall = roomGroupRoomWatts - roomGroupEmitterWatts;
    const isMultiRoomGroup = roomGroup.values.length > 1;
    return _jsxs("div", { className: 'flex flex-col gap-3', children: [isMultiRoomGroup && _jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: 'text-gray-900 font-bold', children: roomGroup.values.map(x => x.name).join(', ') }), _jsx(Badge, { color: roomGroupEmitterWatts >= roomGroupRoomWatts
                            ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === roomGroup.key)
                            ? 'YELLOW'
                            : 'RED', text: getEmitterOutputVsDemandText(roomGroupEmitterWatts, roomGroupRoomWatts) })] }), isMultiRoomGroup && roomGroupShortfall > 0 && _jsx(UndersizedToggle, { design: design, roomOrRoomGroupUUID: roomGroup.key, setDesign: setDesign }), roomGroup.values.map(r => getRoomRadiatorCards(emitterUUIDToHighlight, setEmitterUUIDToHighlight, r, floor, designTempC, groundTempC, survey, design, isMultiRoomGroup, roomGroupShortfall, setAddRadiatorModal, setDesign))] }, roomGroup.key);
};
const getRoomRadiatorCards = (emitterUUIDToHighlight, setEmitterUUIDToHighlight, room, floor, designTempC, groundTempC, survey, design, inMultiRoomGroup, roomGroupShortfall, setAddRadiatorModal, setDesign) => {
    const replacementEmitters = design.radiators.filter(x => x.room_uuid === room.uuid);
    const roomEmitterWatts = getRoomEmitterWatts(room, design, survey, designTempC, groundTempC);
    const roomWatts = getRoomWatts(room, floor.rooms, designTempC, groundTempC, survey);
    const roomTempC = getRoomTemp(room, survey);
    const [scope, animate] = useAnimate();
    useEffect(() => {
        if (emitterUUIDToHighlight) {
            const element = document.getElementById(emitterUUIDToHighlight);
            if (!element)
                return;
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            animate([
                [`[id="${emitterUUIDToHighlight}"]`, { scale: 0.98, backgroundColor: '#dcfce7' }, { ease: 'easeInOut', duration: 0.5 }],
                [`[id="${emitterUUIDToHighlight}"]`, { scale: 1 }, { ease: 'easeIn', duration: 0.1 }],
                [`[id="${emitterUUIDToHighlight}"]`, { backgroundColor: '#ffffff' }, { ease: 'easeOut', duration: 4 }]
            ]);
            setEmitterUUIDToHighlight(undefined);
        }
    }, 
    // looking for design.radiators changes as well, because emitterUUIDToHighlight may be set before the radiator is really rendered
    [design.radiators, emitterUUIDToHighlight]);
    return _jsxs("div", { ref: scope, className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex justify-between items-center', children: [_jsx("div", { className: `${inMultiRoomGroup ? '' : 'font-bold text-gray-900'}`, children: room.name }), _jsxs("div", { className: 'flex gap-2 items-center', children: [!inMultiRoomGroup &&
                                _jsx(Badge, { color: roomEmitterWatts >= roomWatts
                                        ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === room.uuid)
                                        ? 'YELLOW'
                                        : 'RED', text: getEmitterOutputVsDemandText(roomEmitterWatts, roomWatts) }), inMultiRoomGroup && _jsxs("div", { className: 'text-sm', children: [Math.round(roomWatts), " W"] }), _jsx(Button, { onClick: () => {
                                    setAddRadiatorModal({
                                        room,
                                        floor,
                                        roomTempC,
                                        requiredOutputW: roomGroupShortfall,
                                        originalEmitter: undefined,
                                        replacementEmitter: undefined
                                    });
                                }, colour: 'LIGHT', size: 'SM', children: "Add" })] })] }), !inMultiRoomGroup && roomGroupShortfall > 0 && _jsx(UndersizedToggle, { roomOrRoomGroupUUID: room.uuid, design: design, setDesign: setDesign }), _jsx("div", { className: 'rounded-md bg-white flex flex-col gap-2', children: [...replacementEmitters.filter(x => !x.replaces_uuid), ...room.radiators].map(ra => {
                    const emitterWatts = Math.round(getEmitterWatts(ra, room, design, survey, designTempC, groundTempC));
                    const velocity = getEmitterVelocityMPerS(ra, emitterWatts, design.delta_t_flow_return_c, design.flow_temp);
                    // Survey rad is defined if "surveyed/kept" or "replaced"
                    const originalEmitter = ra.survey_or_design === 'SURVEY' ? ra : undefined;
                    // Design radiator will be defined in cases 'Added' and 'Replacement'
                    // Replacement rads filtered out above, so if it's a design rad it's an added rad
                    const addedRadiator = ra.survey_or_design === 'DESIGN' ? ra : undefined;
                    // If the survey rad is being replaced then we have a replacement radiator, we can only replace Radiators not any other Emitters
                    const replacementRadiator = originalEmitter && replacementEmitters.find(x => x.replaces_uuid === originalEmitter?.uuid);
                    const replacementEmitter = addedRadiator ?? replacementRadiator ?? undefined;
                    const replacementRadiatorWatts = replacementRadiator ? getRadiatorWatts(replacementRadiator.radiator_type, design.flow_temp, design.delta_t_flow_return_c, roomTempC) : 0;
                    const replacementRadiatorVelocity = replacementRadiator
                        ? getEmitterVelocityMPerS(replacementRadiator, replacementRadiatorWatts, design.delta_t_flow_return_c, design.flow_temp)
                        : undefined;
                    // If there is a replacement emitter we are editing, then we need the required output excluding that replacement emitter
                    // If we are replacing the selected emitter, then it's the required output excluding the existing emitter that we are replacing
                    const requiredOutputW = roomGroupShortfall + (replacementRadiator ? replacementRadiatorWatts : emitterWatts);
                    if (ra.emitter_type === 'UNDERFLOOR') {
                        return _jsx(UnderfloorCard, { radiator: ra, watts: emitterWatts, velocity: velocity, removedRadiators: design.removed_radiator_uuids, survey: survey, removeRadiator: () => {
                                setDesign({
                                    ...design,
                                    radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                                    removed_radiator_uuids: originalEmitter
                                        ? [...design.removed_radiator_uuids, originalEmitter.uuid]
                                        : design.removed_radiator_uuids
                                });
                            }, editRadiator: () => {
                                setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter });
                            }, restoreRadiator: () => {
                                setDesign({
                                    ...design,
                                    radiators: design.radiators.filter(x => x.uuid !== replacementRadiator?.uuid),
                                    removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid)
                                });
                            } }, ra.uuid);
                    }
                    return _jsxs("div", { className: 'flex flex-col border border-gray-200 rounded-lg', children: [_jsx(RadiatorCard, { hasReplacement: !!replacementRadiator, velocity: velocity, deltaTFlowReturnC: design.delta_t_flow_return_c, radiator: ra, editRadiator: () => {
                                    setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter });
                                }, watts: emitterWatts, removeRadiator: () => {
                                    setDesign({
                                        ...design,
                                        radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                                        removed_radiator_uuids: originalEmitter
                                            ? [...design.removed_radiator_uuids, originalEmitter.uuid]
                                            : design.removed_radiator_uuids
                                    });
                                }, replaceRadiator: () => {
                                    setAddRadiatorModal({
                                        room,
                                        floor,
                                        roomTempC,
                                        requiredOutputW,
                                        originalEmitter,
                                        replacementEmitter: addedRadiator ?? {
                                            ...DEFAULT_SURVEY_RADIATOR,
                                            survey_or_design: 'DESIGN',
                                            emitter_type: 'RADIATOR',
                                            room_uuid: room.uuid,
                                            pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                                            updated_at: new Date().getTime(),
                                            created_at: new Date().getTime()
                                        }
                                    });
                                }, restoreRadiator: () => {
                                    setDesign({
                                        ...design,
                                        radiators: design.radiators.filter(x => x.uuid !== replacementRadiator?.uuid),
                                        removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid)
                                    });
                                }, isReplacement: false, removedRadiators: design.removed_radiator_uuids }, ra.uuid), replacementRadiator && _jsx(RadiatorCard, { hasReplacement: !!replacementRadiator, isReplacement: true, radiator: replacementRadiator, watts: replacementRadiatorWatts, removeRadiator: noop, replaceRadiator: noop, restoreRadiator: noop, editRadiator: () => {
                                    setAddRadiatorModal({
                                        room,
                                        floor,
                                        roomTempC,
                                        requiredOutputW,
                                        originalEmitter,
                                        replacementEmitter: replacementRadiator
                                    });
                                }, velocity: replacementRadiatorVelocity, deltaTFlowReturnC: design.delta_t_flow_return_c, removedRadiators: design.removed_radiator_uuids }, replacementRadiator.uuid)] }, ra.uuid);
                }) })] }, room.uuid);
};
const RadiatorCard = ({ radiator, watts, replaceRadiator, removeRadiator, restoreRadiator, editRadiator, isReplacement, velocity, removedRadiators, hasReplacement }) => {
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const isReplaced = radiator.survey_or_design !== 'DESIGN' && hasReplacement;
    const isRemoved = radiator.survey_or_design !== 'DESIGN' && removedRadiators.includes(radiator.uuid);
    const badge = getRadiatorBadge(radiator, hasReplacement, removedRadiators, false);
    const removedOrReplaced = isRemoved || isReplaced;
    return _jsxs("div", { id: radiator.uuid, className: 'text-gray-600 p-4 flex items-center justify-between', children: [_jsxs("div", { className: 'flex gap-4 items-center', children: [isReplacement && _jsx(WrappedIcon, { icon: ArrowRight }), _jsxs("div", { className: 'flex flex-col gap-1 text-sm', children: [_jsx("div", { className: 'mb-2 self-start', children: badge }), _jsx("div", { className: `${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold`, children: getEmitterTypeName(radiator) }), _jsxs("div", { children: [getEmitterSizeName(radiator), " \u2022 ", watts, " W ", velocity ? `• ${velocity.toFixed(2)} m/s` : '', " "] })] })] }), _jsxs("div", { className: 'flex gap-4 items-center', children: [(isAdded || isReplacement) && _jsx(Link, { text: 'Edit', className: 'text-sm', onClick: editRadiator }), (!isReplacement && (removedOrReplaced
                        ? _jsx(Button, { onClick: restoreRadiator, size: 'SM', colour: 'LIGHT', children: "Restore" })
                        : radiator.survey_or_design === 'SURVEY' && radiator.emitter_type === 'RADIATOR' && _jsx(Button, { onClick: replaceRadiator, size: 'SM', colour: 'LIGHT', children: "Replace" }))), !isReplacement && (!removedOrReplaced && _jsx(WrappedIcon, { className: 'cursor-pointer', onClick: removeRadiator, icon: Trash }))] })] });
};
export const UnderfloorCard = ({ radiator, watts, velocity, removedRadiators, restoreRadiator, removeRadiator, editRadiator, survey }) => {
    const badge = getRadiatorBadge(radiator, false, removedRadiators, false);
    const isReplaced = false;
    const isRemoved = removedRadiators.includes(radiator.uuid);
    const isReplacement = false;
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const removedOrReplaced = isRemoved || isReplaced;
    return _jsxs("div", { id: radiator.uuid, className: 'text-gray-600 p-4 flex items-center justify-between', children: [_jsx("div", { className: 'flex gap-4 items-center', children: _jsxs("div", { className: 'flex flex-col gap-1 text-sm', children: [_jsx("div", { className: 'mb-2 self-start', children: badge }), _jsxs("div", { className: `${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold`, children: ["UFH - ", radiator.floor_construction_type] }), _jsxs("div", { children: [survey.manifolds.find(x => x.uuid === radiator.manifold_uuid)?.name, " \u2022 ", radiator.pipe_centres_uuid, " centres \u2022 ", watts, " W \u2022 ", velocity.toFixed(2), " m/s"] })] }) }), _jsxs("div", { className: 'flex gap-4 items-center', children: [(isAdded || isReplacement) && _jsx(Link, { text: 'Edit', className: 'text-sm', onClick: editRadiator }), (!isReplacement && (removedOrReplaced
                        ? _jsx(Button, { onClick: restoreRadiator, size: 'SM', colour: 'LIGHT', children: "Restore" })
                        : _jsx("div", {}))), !isReplacement && (!removedOrReplaced && _jsx(WrappedIcon, { className: 'cursor-pointer', onClick: removeRadiator, icon: Trash }))] })] });
};
const SelectRadiatorModelDesignContext = ({ files, requiredOutput, originalEmitter, companyUUID }) => {
    return _jsxs("div", { className: 'flex flex-col gap-5', children: [originalEmitter && _jsxs("div", { className: 'flex flex-col gap-5', children: [originalEmitter.photos.length > 0 &&
                        _jsx(PhotoScroll, { images: files.filter(x => originalEmitter?.photos.map(x => x.image_uuid).includes(x.uuid)), companyUUID: companyUUID }), originalEmitter.notes !== '' && _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Notes:" }), _jsx("div", { className: 'text-sm', children: originalEmitter.notes })] }), _jsxs("div", { className: 'flex flex-row justify-between', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Current emitter:" }), _jsx("div", { className: 'flex gap-2 items-center justify-between p1', children: _jsxs("div", { className: 'text-sm', children: [originalEmitter.radiator_type?.type, " - ", getRadiatorHeightWidthText(originalEmitter.radiator_type)] }) })] }), (originalEmitter.maximum_height > 0 || originalEmitter.maximum_width > 0) && _jsxs("div", { className: 'flex flex-row justify-between', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Max size:" }), _jsxs("div", { className: 'text-sm', children: [originalEmitter.maximum_height, " x ", originalEmitter.maximum_width, " mm"] })] })] }), requiredOutput && _jsxs("div", { className: 'flex flex-row justify-between', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Output required:" }), _jsx(Badge, { color: requiredOutput > 0 ? 'INDIGO' : 'GREEN', text: requiredOutput > 0 ? requiredOutput.toFixed() + ' W' : 'Demand met' })] })] });
};
export const getRadiatorBadge = (radiator, hasReplacement, removedRadiators, isReport) => {
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const isReplacement = radiator.survey_or_design === 'DESIGN' && radiator.replaces_uuid;
    const isReplaced = radiator.survey_or_design === 'SURVEY' && hasReplacement;
    const isRemoved = removedRadiators.includes(radiator.uuid);
    if (isAdded)
        return _jsx(Badge, { color: 'BLUE', text: 'Added' });
    if (isReplacement)
        return _jsx(Badge, { color: 'BLUE', text: 'Replacement' });
    if (isReplaced)
        return _jsx(Badge, { color: 'RED', text: 'Replaced' });
    if (isRemoved)
        return _jsx(Badge, { color: 'RED', text: 'Removed' });
    return _jsx(Badge, { color: 'LIGHT', text: isReport ? 'Keep' : 'Surveyed' });
};
export const FlowTempSlider = ({ flowTemp, setFlowTemp, minFlowTemp = 35, maxFlowTemp = 65 }) => {
    return _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Flow temperature" }), _jsxs("div", { className: "text-gray-900 font-semibold", children: [flowTemp, " \u00B0C"] })] }), _jsx(Slider, { value: flowTemp, onChange: setFlowTemp, min: minFlowTemp ?? 35, max: maxFlowTemp ?? 65 })] });
};
