import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { updateUserJobRole, addUserToJob, addCompanyToJob, removeUserFromJob, removeCompanyFromJob, updateCompanyJobRole } from '../../../../code/models/lead';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import MultiStageModal from '../../../../components/containers/multi_stage_modal';
import { UserStatusEnum } from '../../../../code/models/user';
import { TableLite } from '../../../../components/content_display/table_lite';
import { Text } from '../../../../components/content_display/text';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { Building2, ChevronRight, PencilIcon, UserIcon, UserMinus2 } from 'lucide-react';
import { EditRoleModalInner } from '../../settings_page/company_settings/user_management';
import { AdminContext } from '../../admin_layout';
import { Icon } from '../../../../components/buttons/icon';
import { Input } from '../../../../components/inputs_and_selections/input';
import { validateEmail } from '../../../../code/validators';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Select } from '../../../../components/inputs_and_selections/select';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { pgRest } from '../../../../code/postgrest';
const JOB_ACCESS_TABS = [{ name: 'All', id: 'all' }, { name: 'External guests', id: 'users' }, { name: 'Organisations', id: 'companies' }];
export const ShareModal = ({ company, job, setJob, isOpen, setIsOpen, setSnackbarText }) => {
    const [currentStageId, setCurrentStageId] = useState('start');
    const [data, setData] = useState({ shareType: null, editingUserRow: null, editingCompanyRow: null, invitationEmail: '', invitationMessage: '', invitationRole: null });
    const adminContext = useContext(AdminContext);
    const [jobAccessTab, setJobAccessTab] = useState(JOB_ACCESS_TABS[0]);
    const [history, setHistory] = useState([]);
    const [partnerOrganisations, setPartnerOrganisations] = useState([]);
    useEffect(() => {
        const main = async () => {
            const { data: companyPartners } = await pgRest
                .from('company_partners')
                .select('*,partner:teams!company_partners_partner_uuid_fk(uuid,name,code),company:teams!company_partners_company_uuid_fk(uuid,name,code)')
                .or(`company_uuid.eq.${job.company_uuid},partner_uuid.eq.${job.company_uuid}`);
            if (companyPartners) {
                setPartnerOrganisations(companyPartners);
            }
        };
        main();
    }, []);
    const canGoBack = history.length > 0;
    const handleResetModal = () => {
        setCurrentStageId('start');
        setData({ shareType: null, editingUserRow: null, editingCompanyRow: null, invitationEmail: '', invitationMessage: '', invitationRole: null });
    };
    const handleNavigateForward = ({ stageId, saveToHistory = true }) => {
        if (!stageId || !currentStage)
            return;
        if (saveToHistory) {
            setHistory((prev) => [...prev, currentStage.id]);
        }
        setCurrentStageId(stageId);
    };
    const handleNavigateBack = () => {
        if (history.length > 0) {
            const goTo = history[history.length - 1];
            setHistory((prev) => prev.slice(0, -1));
            console.log(goTo);
            setCurrentStageId(goTo);
        }
    };
    const handleSendUserInvitation = async () => {
        if (!data.invitationEmail || !data.invitationRole || !('job_users' in job))
            return;
        const userCompany = await addUserToJob({
            companyUuid: job.company_uuid,
            jobUuid: job.uuid,
            roleUuid: data.invitationRole,
            email: data.invitationEmail,
            message: data.invitationMessage
        });
        if (userCompany) {
            setJob({
                ...job,
                job_users: [...job.job_users || [], {
                        user_uuid: userCompany.user_uuid,
                        job_uuid: job.uuid,
                        company_role_uuid: userCompany.company_role_uuid,
                        user: {
                            uuid: userCompany.user_uuid,
                            email: data.invitationEmail,
                            first_name: '',
                            last_name: '',
                            status: UserStatusEnum.enum.staged
                        }
                    }]
            }, adminContext.data.user);
            adminContext.setCompanyJobUsers([...(adminContext.data.companyJobUsers || []), userCompany]);
        }
    };
    const handleSendCompanyInvitation = async () => {
        if (!data.selectedPartnerOrganisationId || !data.invitationRole || !('job_invited_companies' in job))
            return;
        const jobInvitedCompany = await addCompanyToJob({
            companyUuid: job.company_uuid,
            jobUuid: job.uuid,
            roleUuid: data.invitationRole,
            invitedCompanyUuid: data.selectedPartnerOrganisationId,
            message: data.invitationMessage
        });
        if (jobInvitedCompany) {
            setJob({
                ...job,
                job_invited_companies: [...job.job_invited_companies || [], {
                        company_uuid: jobInvitedCompany.company_uuid,
                        job_uuid: job.uuid,
                        company_role_uuid: jobInvitedCompany.company_role_uuid,
                        company: {
                            uuid: jobInvitedCompany.company_uuid,
                            name: jobInvitedCompany.company.name,
                            subdomain: jobInvitedCompany.company.subdomain
                        }
                    }]
            }, adminContext.data.user);
        }
    };
    const handleRemoveUserFromJob = async (userUuid) => {
        if (!('job_users' in job))
            return;
        await removeUserFromJob({
            companyUuid: job.company_uuid,
            jobUuid: job.uuid,
            jobUserUuid: userUuid
        });
        setJob({
            ...job,
            job_users: job.job_users?.filter(x => x.user_uuid !== userUuid)
        }, adminContext.data.user);
    };
    const handleRemoveCompanyFromJob = async (companyUuid) => {
        if (!('job_invited_companies' in job))
            return;
        await removeCompanyFromJob({
            companyUuid: job.company_uuid,
            jobUuid: job.uuid,
            invitedCompanyUuid: companyUuid
        });
        setJob({
            ...job,
            job_invited_companies: job.job_invited_companies?.filter(x => x.company_uuid !== companyUuid)
        }, adminContext.data.user);
    };
    const handleUpdateUserJobRole = async (roleUuid) => {
        if (!roleUuid || !data.editingUserRow)
            return;
        updateUserJobRole({ companyUuid: company?.uuid || '', jobUuid: job?.uuid || '', jobUserUuid: data.editingUserRow?.user_uuid || '', roleUuid });
        setData({ ...data, editingUserRow: null });
        if ('job_users' in job) {
            setJob({
                ...job,
                job_users: job.job_users?.map(user => user.user_uuid === data.editingUserRow?.user_uuid ? { ...user, company_role_uuid: roleUuid } : user) || []
            }, adminContext.data.user);
        }
    };
    const handleUpdateCompanyJobRole = async (roleUuid) => {
        console.log('updating company job role', roleUuid);
        if (!roleUuid || !data.editingCompanyRow)
            return;
        console.log(company?.uuid, job?.uuid, data.editingCompanyRow?.company_uuid, roleUuid);
        await updateCompanyJobRole({ companyUuid: company?.uuid || '', jobUuid: job?.uuid || '', invitedCompanyUuid: data.editingCompanyRow?.company_uuid || '', roleUuid });
        setData({ ...data, editingCompanyRow: null });
        if ('job_invited_companies' in job) {
            setJob({
                ...job,
                job_invited_companies: job.job_invited_companies?.map(company => company.company_uuid === data.editingCompanyRow?.company_uuid ? { ...company, company_role_uuid: roleUuid } : company) || []
            }, adminContext.data.user);
        }
    };
    const stages = [
        {
            id: 'start',
            title: 'Share job',
            component: () => _jsx(StartStage, { goToStage: handleNavigateForward, data: data, updateData: setData, job: job }),
            goNextDisabled: !data.shareType,
            goNext: () => data.shareType === 'user' ? { stageId: 'userShare' } : data.shareType === 'company' ? { stageId: 'companyShare' } : null
        },
        {
            id: 'userShare',
            title: 'Invite an external guest',
            component: () => _jsx(InviteExternalGuestStage, { goToStage: handleNavigateForward, data: data, updateData: setData, roles: company?.company_roles }),
            canGoBack: true,
            goNext: async () => {
                await handleSendUserInvitation();
                handleResetModal();
                setSnackbarText?.('Invitation sent');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Send invitation',
            goNextDisabled: !data.invitationEmail || !data.invitationRole
        },
        {
            id: 'companyShare',
            title: 'Invite a partner organisation',
            component: () => _jsx(InvitePartnerOrganisationStage, { goToStage: handleNavigateForward, data: data, updateData: setData, roles: company?.company_roles, job: job, partnerOrganisations: partnerOrganisations }),
            canGoBack: true,
            goNext: async () => {
                await handleSendCompanyInvitation();
                handleResetModal();
                setSnackbarText?.('Invitation sent');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Send invitation',
            goNextDisabled: !data.selectedPartnerOrganisationId || !data.invitationRole
        },
        {
            id: 'jobAccess',
            title: 'Job access',
            component: () => _jsx(JobAccessStage, { goToStage: handleNavigateForward, data: data, updateData: setData, job: job, roles: company?.company_roles, allTabs: JOB_ACCESS_TABS, tab: jobAccessTab, setTab: setJobAccessTab })
        },
        {
            id: 'editUserRole',
            title: `Editing role for ${data.editingUserRow?.user.email || 'No user'}`,
            component: () => _jsx(EditUserRoleStage, { goToStage: handleNavigateForward, data: data, updateData: setData, roles: company?.company_roles }),
            goNext: async () => {
                await handleUpdateUserJobRole(data.editingUserRow?.company_role_uuid || '');
                setSnackbarText?.('Role updated');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Save and return'
        },
        {
            id: 'editCompanyRole',
            title: `Editing role for ${data.editingCompanyRow?.company.name || 'Unnamed organisation'}`,
            component: () => _jsx(EditCompanyRoleStage, { goToStage: handleNavigateForward, data: data, updateData: setData, roles: company?.company_roles }),
            goNext: async () => {
                await handleUpdateCompanyJobRole(data.editingCompanyRow?.company_role_uuid || '');
                setSnackbarText?.('Role updated');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Save and return'
        },
        {
            id: 'confirmDeleteUser',
            title: 'Confirm deletion',
            component: () => _jsx(ConfirmDeleteUserStage, { goToStage: handleNavigateForward, data: data, updateData: setData }),
            goNext: async () => {
                await handleRemoveUserFromJob(data.editingUserRow?.user_uuid || '');
                setSnackbarText?.('User removed');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Confirm',
            dangerous: true
        },
        {
            id: 'confirmDeleteCompany',
            title: 'Confirm deletion',
            component: () => _jsx(ConfirmDeleteCompanyStage, { goToStage: handleNavigateForward, data: data, updateData: setData }),
            goNext: async () => {
                await handleRemoveCompanyFromJob(data.editingCompanyRow?.company_uuid || '');
                setSnackbarText?.('Organisation removed');
                return { stageId: 'jobAccess', saveToHistory: false };
            },
            goNextLabel: 'Confirm',
            dangerous: true
        }
    ];
    const currentStage = stages.find(x => x.id === currentStageId);
    return (_jsx(MultiStageModal, { isOpen: isOpen, setIsOpen: setIsOpen, currentStage: currentStage, handleNavigateForward: handleNavigateForward, handleNavigateBack: handleNavigateBack, canGoBack: canGoBack }));
};
const StartStage = ({ goToStage, data, updateData, job }) => {
    if (!data)
        return null;
    const handleSelectOption = (option) => {
        updateData?.({ ...data, shareType: option });
    };
    const jobUserNamesToString = (users) => {
        if (!users)
            return '';
        const unnamedUsers = users.filter(user => !user.user.first_name && !user.user.last_name);
        const names = users.map(user => user.user.first_name || user.user.last_name ? `${user.user.first_name} ${user.user.last_name}` : null).filter(Boolean);
        if (unnamedUsers.length) {
            names.push(`${unnamedUsers.length} unnamed`);
        }
        if (names.length > 3) {
            return `${names.slice(0, 3).join(', ')} and ${names.length - 3} ${names.length - 3 === 1 ? 'other' : 'others'}`;
        }
        return names.join(', ');
    };
    const jobCompanyNamesToString = (companies) => {
        if (!companies)
            return '';
        const names = companies.map(company => company.company.name);
        if (names.length > 3) {
            return `${names.slice(0, 3).join(', ')} and ${names.length - 3} ${names.length - 3 === 1 ? 'other' : 'others'}`;
        }
        return names.join(', ');
    };
    return (_jsxs(_Fragment, { children: [_jsx(RadioGroup, { isVertical: true, items: [{
                        name: 'Invite an external guest',
                        description: 'Add guests to collaborate on the job, then assign them roles.',
                        onClick: () => handleSelectOption('user'),
                        variant: data.shareType === 'user' ? 'ACTIVE' : 'DEFAULT'
                    }, {
                        name: 'Invite a partner organisation',
                        description: 'Add a whole organisation to access the job. You can choose to limit access or grant open access for their members.',
                        onClick: () => handleSelectOption('company'),
                        variant: data.shareType === 'company' ? 'ACTIVE' : 'DEFAULT'
                    }] }), _jsxs("div", { className: 'flex flex-col divide-y divide-gray-200 border-y border-gray-200 cursor-pointer w-full', children: [_jsxs("div", { className: 'flex gap-5 py-5 w-full items-center', onClick: () => goToStage({ stageId: 'jobAccess' }), children: [_jsxs("div", { className: 'flex-grow', children: [_jsxs(Text, { size: "SM", bold: true, children: [job.job_users?.length || 0, " external ", job.job_users?.length === 1 ? 'guest' : 'guests', " can access"] }), _jsx(Text, { size: "XS", className: 'text-gray-500', children: jobUserNamesToString(job.job_users) })] }), _jsx(Icon, { icon: ChevronRight })] }), _jsxs("div", { className: 'flex gap-5 py-5 w-full', onClick: () => goToStage({ stageId: 'jobAccess' }), children: [_jsxs("div", { className: 'flex-grow', children: [_jsxs(Text, { size: "SM", bold: true, children: [job.job_invited_companies?.length || 0, " ", job.job_invited_companies?.length === 1 ? 'organisation' : 'organisations', " can access"] }), _jsx(Text, { size: "XS", className: 'text-gray-500', children: jobCompanyNamesToString(job.job_invited_companies) })] }), _jsx(Icon, { icon: ChevronRight })] })] })] }));
};
const JobAccessStage = ({ goToStage, data, updateData, job, roles, allTabs, tab, setTab }) => {
    if (!data)
        return null;
    const tableColumns = [
        { key: 'name', name: 'Name' },
        { key: 'role', name: 'Role' },
        { key: 'actions', name: '' }
    ];
    const hydratedUsers = job.job_users?.map(user => ({
        ...user,
        company_role: roles?.find(role => role.uuid === user.company_role_uuid) || null
    })) || [];
    const hydratedCompanies = job.job_invited_companies?.map(company => ({
        ...company,
        company_role: roles?.find(role => role.uuid === company.company_role_uuid) || null
    })) || [];
    const handleEditUserRole = (user) => {
        updateData?.({ ...data, editingUserRow: user });
        goToStage({ stageId: 'editUserRole' });
    };
    const handleEditCompanyRole = (company) => {
        updateData?.({ ...data, editingCompanyRow: company });
        goToStage({ stageId: 'editCompanyRole' });
    };
    const userRows = hydratedUsers.map(user => ({
        name: _jsxs("div", { className: "grid grid-cols-[2.5rem_1fr] gap-3", children: [_jsx(UserAvatar, {}), _jsxs("div", { className: "flex flex-col", children: [_jsx(Text, { size: "SM", bold: true, children: user.user.first_name || user.user.last_name ? `${user.user.first_name} ${user.user.last_name}` : 'No name' }), _jsx(Text, { size: "XS", className: "text-gray-500", children: user.user.email })] })] }),
        role: user.company_role?.name || 'No role',
        actions: _jsx(DropdownMenu, { items: [
                {
                    label: 'Edit role',
                    onClick: () => handleEditUserRole(user),
                    icon: PencilIcon
                },
                {
                    label: 'Remove from job',
                    onClick: () => {
                        updateData?.({ ...data, editingUserRow: user });
                        goToStage({ stageId: 'confirmDeleteUser' });
                    },
                    icon: UserMinus2
                }
            ] })
    })) || [];
    const companyRows = hydratedCompanies.map(company => ({
        name: _jsxs("div", { className: "grid grid-cols-[2.5rem_1fr] gap-3 items-center", children: [_jsx(CompanyAvatar, {}), _jsx(Text, { size: "SM", bold: true, children: company.company.name })] }),
        role: company.company_role?.name || 'No role',
        actions: _jsx(DropdownMenu, { items: [
                {
                    label: 'Edit role',
                    onClick: () => handleEditCompanyRole(company),
                    icon: PencilIcon
                },
                {
                    label: 'Remove from job',
                    onClick: () => {
                        updateData?.({ ...data, editingCompanyRow: company });
                        goToStage({ stageId: 'confirmDeleteCompany' });
                    },
                    icon: UserMinus2
                }
            ] })
    })) || [];
    const tabNumber = (tab) => {
        switch (tab.id) {
            case 'all': return (hydratedUsers?.length || 0) + (hydratedCompanies?.length || 0);
            case 'users': return hydratedUsers?.length || 0;
            case 'companies': return hydratedCompanies?.length || 0;
            default: return 0;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex gap-8 overflow-x-auto no-scrollbar border-b border-gray-200', children: allTabs.map(x => _jsx("div", { onClick: () => setTab(x), className: `
          outline-none py-4 whitespace-nowrap cursor-pointer text-default text-xs font-semibold leading-none flex gap-3
          ${x.id === tab.id ? 'border-black border-b-2 text-bold' : ''} 
        `, children: _jsxs("div", { className: "flex gap-3 items-center", children: [x.name, " ", _jsx(Text, { size: "XS", className: 'text-gray-500', children: tabNumber(x) })] }) }, x.id)) }), tab.id === 'all' && _jsx(TableLite, { columns: tableColumns, rows: [...userRows, ...companyRows], alignRight: false }), tab.id === 'users' && _jsx(TableLite, { columns: tableColumns, rows: userRows, alignRight: false }), tab.id === 'companies' && _jsx(TableLite, { columns: tableColumns, rows: companyRows, alignRight: false })] }));
};
const EditUserRoleStage = ({ data, updateData, roles }) => {
    if (!data)
        return null;
    if (!roles || !data.editingUserRow)
        return null;
    return (_jsx(EditRoleModalInner, { roles: roles, roleUUID: data.editingUserRow.company_role_uuid, setRoleUUID: (roleUUID) => updateData?.({ ...data, editingUserRow: { ...data.editingUserRow, company_role_uuid: roleUUID, company_role: roles.find(role => role.uuid === roleUUID) || null } }) }));
};
const InviteExternalGuestStage = ({ data, updateData, roles }) => {
    if (!data)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Input, { label: 'Email address', value: data.invitationEmail || '', setValue: (value) => updateData?.({ ...data, invitationEmail: value }), placeholder: 'Email address', validator: validateEmail, doNotValidateWhenEmpty: true }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Role' }), _jsx(Select, { selectedKey: data.invitationRole || '', setSelectedKey: (value) => updateData?.({ ...data, invitationRole: value }), options: roles?.map(role => ({ key: role.uuid, value: role.name })) || [] })] }), _jsx(TextArea, { label: "Message", value: data.invitationMessage || '', setValue: (value) => updateData?.({ ...data, invitationMessage: value }), placeholder: 'Add an optional message' })] }));
};
const InvitePartnerOrganisationStage = ({ data, updateData, roles, job, partnerOrganisations }) => {
    if (!data)
        return null;
    const filteredPartners = partnerOrganisations
        .map(x => x.company_uuid === job.company_uuid ? x.partner : x.company)
        .filter(org => !job.job_invited_companies?.some(company => company.company_uuid === org?.uuid));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { size: "SM", labelText: 'Select a partner organisation' }), _jsx(Select, { selectedKey: data.selectedPartnerOrganisationId || '', setSelectedKey: (value) => updateData?.({ ...data, selectedPartnerOrganisationId: value }), options: filteredPartners?.map(org => ({ key: org.uuid, value: org.name })) || [] }), _jsxs(Text, { size: "SM", className: 'text-gray-500', children: ["You can manage and add new partner organisations in ", _jsx("a", { href: "/admin/settings/company/roles", className: 'underline', children: "Settings" }), "."] })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { size: "SM", labelText: 'Role' }), _jsx(Select, { selectedKey: data.invitationRole || '', setSelectedKey: (value) => updateData?.({ ...data, invitationRole: value }), options: roles?.map(role => ({ key: role.uuid, value: role.name })) || [] })] }), _jsx(TextArea, { label: "Message", value: data.invitationMessage || '', setValue: (value) => updateData?.({ ...data, invitationMessage: value }), placeholder: 'Add an optional message' })] }));
};
const EditCompanyRoleStage = ({ data, updateData, roles }) => {
    if (!data?.editingCompanyRow || !roles)
        return null;
    return (_jsx(EditRoleModalInner, { roles: roles, roleUUID: data.editingCompanyRow.company_role_uuid, setRoleUUID: (roleUUID) => updateData?.({ ...data, editingCompanyRow: { ...data.editingCompanyRow, company_role_uuid: roleUUID, company_role: roles.find(role => role.uuid === roleUUID) || null } }) }));
};
const ConfirmDeleteUserStage = ({ data }) => {
    if (!data?.editingUserRow)
        return null;
    return (_jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs(Text, { size: "SM", children: ["Are you sure you want to remove ", _jsx("span", { className: "font-semibold", children: data.editingUserRow.user.email }), " from this job?"] }), _jsx(Text, { size: "SM", className: "text-gray-500", children: "They will lose access to this job. This action cannot be undone." })] }));
};
const ConfirmDeleteCompanyStage = ({ data }) => {
    if (!data?.editingCompanyRow)
        return null;
    return (_jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs(Text, { size: "SM", children: ["Are you sure you want to remove ", _jsx("span", { className: "font-semibold", children: data.editingCompanyRow.company.name }), " from this job?"] }), _jsx(Text, { size: "SM", className: "text-gray-500", children: "All members of this organisation will lose access to this job. This action cannot be undone." })] }));
};
const UserAvatar = () => {
    return (_jsx("div", { className: "w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center", children: _jsx(Icon, { icon: UserIcon }) }));
};
const CompanyAvatar = () => {
    return (_jsx("div", { className: "w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center", children: _jsx(Icon, { icon: Building2 }) }));
};
