import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { client, url } from '../axios';
import { AppContext } from '../../app_context';
import { jwtDecode } from 'jwt-decode';
export const LOCAL_STORAGE_TOKEN_KEY = 'token';
// This AuthSDK is required to be outside of ANY React components.
// It's used in routers and other places where we need to check if the user is signed in.
export const AuthSDK = {
    isSignedIn: () => {
        return !!localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        // FIXME: get user info from server to check if token is valid
    },
    getToken: () => {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    },
    getTokenPayload: () => {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
            ? jwtDecode(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY))
            : null;
    },
    signIn: async (email, password) => {
        const result = await client.post(`${url}login`, { email, password });
        const token = result.data;
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    },
    signOut: () => {
        localStorage.removeItem('companyUuid');
        localStorage.removeItem('leads');
        localStorage.removeItem('last_synced');
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    }
};
export const AuthContext = React.createContext(null);
// ReactJS wrapper around the AuthSDK
export const AuthProvider = ({ loginCallback, children }) => {
    const appContext = React.useContext(AppContext);
    const isSignedIn = () => {
        return AuthSDK.isSignedIn();
    };
    const getToken = () => {
        return AuthSDK.getToken();
    };
    const signIn = async (email, password, callback) => {
        AuthSDK.signIn(email, password).then(() => {
            callback();
            if (loginCallback)
                loginCallback(true);
        }).catch((e) => {
            appContext.setError(e);
        });
    };
    const signOut = (callback) => {
        AuthSDK.signOut();
        localStorage.removeItem('companyUuid');
        localStorage.removeItem('leads');
        callback();
        if (loginCallback)
            loginCallback(false);
    };
    return _jsx(AuthContext.Provider, { value: { isSignedIn, getToken, signIn, signOut }, children: children });
};
