import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { acceptTermsAndConditions } from '../../../code/models/terms_and_conditions';
import { Modal } from '../../../components/containers/modal';
import { Link } from '../../../components/buttons/link';
import { AdminContext } from '../admin_layout';
export const TermsAndConditionsModal = ({ visible, setVisible, termsAndConditions }) => {
    const adminContext = useContext(AdminContext);
    return (_jsx(Modal, { title: 'Terms & Conditions', visible: visible, setVisible: setVisible, confirmButtonLabel: 'Accept', onConfirm: async () => {
            if (await acceptTermsAndConditions() && termsAndConditions) {
                adminContext.acceptTermsAndConditions(termsAndConditions.id);
                setVisible(false);
            }
        }, cancelButtonLabel: "I'll do it later", children: _jsxs("div", { className: "flex flex-col gap-3", children: [_jsxs("div", { children: ["Hello! Before you carry on, we\u2019d be really grateful if you could please review and accept our ", _jsx(Link, { text: 'Terms & Conditions', className: "inline", onClick: () => window.open(termsAndConditions?.url, '_blank') }), "."] }), _jsx("div", { children: "We\u2019ve recently introduced these to give both us and you requisite legal protections and to improve our GDPR compliance." }), _jsx("div", { children: "If you have any questions about these, please contact Jo on joseph@spruce.eco or 07795533150." }), _jsx("div", { children: "Thank you!" })] }) }));
};
