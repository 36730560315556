import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from '../inputs_and_selections/checkbox';
const sizes = {
    SM: 'text-xs',
    MD: 'text-sm'
};
export const TableLite = ({ selectedIds, setSelectedIds, columns, rows, rowIdName, size = 'MD', showCheckboxes = true, alignRight = true, hasHeader = true }) => {
    const toggleSelected = setSelectedIds && ((id) => setSelectedIds(prev => prev.includes(id) ? prev.filter(x => x !== id) : [...prev, id]));
    const allChecked = selectedIds && rowIdName && rows.every(x => selectedIds.includes(x[rowIdName]));
    return _jsxs("table", { className: `${sizes[size]} text-gray-600 w-full sm:divide-y border-light`, children: [hasHeader && _jsx("thead", { className: 'uppercase tracking-wide text-xs text-gray-500 font-semibold hidden sm:table-header-group', children: _jsxs("tr", { children: [showCheckboxes && setSelectedIds && rowIdName && _jsx("th", { className: 'py-2 text-start', children: _jsx(Checkbox, { checked: allChecked ? 'ON' : 'OFF', setChecked: () => allChecked ? setSelectedIds([]) : setSelectedIds(rows.map(x => x[rowIdName])) }) }), columns.map((x, i) => _jsx("th", { className: `p-2 first:px-0 last:px-0 ${alignRight ? i === 0 ? 'text-start' : 'sm:text-end' : 'text-start'}`, children: x.name }, i))] }) }), _jsx("tbody", { className: 'sm:divide-y sm:border-light', children: rows.map((x, xi) => {
                    return _jsxs("tr", { className: 'flex flex-col sm:table-row border border-light rounded sm:border-0 mb-2 p-2 sm:mb-0 sm:p-0', children: [showCheckboxes && selectedIds && toggleSelected && rowIdName && _jsx("td", { className: 'p-2', children: _jsx(Checkbox, { checked: selectedIds.includes(x[rowIdName]) ? 'ON' : 'OFF', setChecked: () => toggleSelected(x[rowIdName]) }) }), columns.map((y, yi) => _jsxs("td", { className: `grid grid-cols-[1fr_2fr] sm:table-cell border-b last:border-0 border-gray-100 sm:border-0 ${alignRight ? yi === 0 ? 'text-start' : 'sm:text-end' : 'text-start'} py-2 sm:p-2 sm:first:ps-0 sm:last:pe-0`, children: [(x[y.key] !== undefined) && _jsx("span", { className: 'sm:hidden uppercase tracking-wide text-xs text-gray-500 font-semibold', children: columns[yi].name }), y.render ? y.render(x) : _jsx("div", { children: x[y.key] })] }, yi))] }, xi);
                }) })] });
};
