import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ClickableCard } from '../../components/content_display/card';
import { AddExternalAttachmentModal } from './add_external_attachment_modal';
import { AddInternalAttachmentModal } from './add_internal_attachment_modal';
import { Text } from '../../components/content_display/text';
import { Button } from '../../components/buttons/button';
export const ProposalAttachmentPlaceholder = ({ addAttachment, forceTitle }) => {
    const [modal, setModal] = useState(undefined);
    const handleCloseModal = (open) => {
        if (!open) {
            setModal(undefined);
        }
    };
    return _jsxs(_Fragment, { children: [_jsx(ClickableCard, { variant: 'PLACEHOLDER', className: 'flex items-center justify-center', children: _jsxs("div", { className: 'flex flex-wrap gap-3 items-center justify-center p-0 sm:py-4', children: [_jsx(Text, { children: "No file provided" }), _jsx(Text, { children: "-" }), _jsxs("div", { className: 'flex gap-3 items-center', children: [_jsx(Button, { colour: 'LIGHT', onClick: () => setModal('internal'), children: "Choose file" }), _jsx(Text, { children: "or" }), _jsx(Button, { colour: 'LIGHT', onClick: () => setModal('external'), children: "Add link" })] })] }) }), _jsx(AddInternalAttachmentModal, { forceTitle: forceTitle, visible: modal === 'internal', onAddAttachment: (title, url) => addAttachment(title, url, true), setVisible: handleCloseModal }), _jsx(AddExternalAttachmentModal, { forceTitle: forceTitle, visible: modal === 'external', onAddAttachment: (title, url) => addAttachment(title, url, false), setVisible: handleCloseModal })] });
};
