import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../../../components/buttons/icon';
import { Trash } from 'lucide-react';
export const IconDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Icon, { icon: Trash })] })] });
};
const componentProps = [
    { name: 'icon', description: 'Font awesome icon the component should use' },
    { name: 'onClick', description: 'Action to perform when clicked, shows cursor or not if populated' },
    { name: 'confirmTextHeader', description: 'If populated, show a confirmation modal with the specified text in the title before actioning the onClick' },
    { name: 'confirmTextBody', description: 'Text to put in body of the modal. Defaults to `This action cannot be undone`. ' },
    { name: 'colour', description: 'The stroke colour of the icon' },
    { name: 'className', description: 'Any additional styling or positioning required' },
    { name: 'disabled', description: 'If the icon is disabled or not' }
];
const codeBlock = `<Icon
    icon={Trash}
    onClick={noop}
    confirmTextHeader=''
    confirmTextBody='This action cannot be undone.'
    colour=''
    className=''
    disabled={false}
/>`;
