import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useEffect, useReducer, useState, useContext } from 'react';
import { getInitialSurveyState, stepHashPrefix, SurveyActionType, SurveyPagesEnum, surveyReducer } from '../../code/survey/survey';
import { DataLoaderContext } from './components/data_loader_context';
import { LogoHeader } from './components/logo_header';
import { ProgressBar } from './components/progress_bar';
import { BathroomsStep } from './steps/bathrooms_step';
import { BedroomsStep } from './steps/bedrooms_step';
import { ChooseAddressStep } from './steps/choose_address_step';
import { ConfirmLocationStep } from './steps/confirm_location_step';
import { EPCFoundStep } from './steps/epcfound_step';
import { FloorAreaStep } from './steps/floor_area_step';
import { GetContactsStep } from './steps/get_contacts_step';
import { ManualAddressStep } from './steps/manual_address_step';
import { NoEPCFoundStep } from './steps/no_epcfound_step';
import { ThankYouStep } from './steps/thank_you_step';
import { WallTypeStep } from './steps/wall_type_step';
import { ScottishGrantStep } from './steps/scottish_grant_step';
import { AdminContext } from '../admin/admin_layout';
import { OfflinePage } from '../admin/offline_page';
import { Loader } from '../../components/indicators_and_messaging/loader';
import { PageHeader } from '../heat_loss/design/components/design_page_header';
import { WrappedIcon } from '../../components/buttons/wrapped_icon';
import { Menu } from 'lucide-react';
import { OptionsStep } from './steps/options_step';
import { CAVITY_WALL_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, SOLID_WALL_INSULATION, WINDOW_TYPES } from '../../code/models/u_value';
import { BUILT_FORM_TYPES } from '../../code/models/built_form';
import { PROPERTY_TYPES } from '../../code/models/property_type';
import { FUELS } from '../../code/models/fuel';
import { ESTIMATE_AGE_BANDS } from '../../code/models/age_bands';
import { PROJECT_TYPES } from '../../code/models/project_type';
import { BuildingRegsStep } from './steps/building_regs_step';
import { StoreysStep } from './steps/storeys_step';
import { NewBuildNextStepsStep } from './steps/new_build_next_steps_step';
import { Error404Page } from '../error_pages';
import { ErrorNoCompanyPage } from '../admin/no_company_page';
// used for reducer
export const SurveyContext = createContext(getInitialSurveyState(''));
// @ts-expect-error context should not start with null
export const SurveyDispatchContext = createContext(null);
// the whole idea of the wrapper is to reload the survey page when the user navigates to the same page
// because react-router renders component only once and doesn't re-render it when the URL changes back and forth
// inside the wrapper we have a key (a special ReactJS attribute) that changes every time the user navigates to the same page
// it solves the SPR-329: https://linear.app/spruce-eco/issue/SPR-329/enquiry-duplicates-information-from-the-previous-enquiry
export const SurveyPageWrapper = ({ showLogo, isAdmin, navigateTo, companyPublicInfo }) => {
    const [reloadKey, setReloadKey] = useState('');
    const reload = () => {
        setReloadKey(crypto.randomUUID());
    };
    useEffect(() => {
        reload();
    }, []);
    if (isAdmin) {
        // If we've got to this point and we don't have companyPublicInfo, we don't have a company
        if (!companyPublicInfo)
            return _jsx(ErrorNoCompanyPage, {});
        return _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: 'New enquiry', isOffline: false }), _jsx(HeatLossSurveyPage, { showLogo: false, isAdmin: isAdmin, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo }, reloadKey)] });
    }
    // If we've got to this point (public questionnaire page), we will have companyPublicInfo since it's supplied by the router
    if (!companyPublicInfo)
        return _jsx(Error404Page, {});
    return _jsx(HeatLossSurveyPage, { showLogo: showLogo, isAdmin: isAdmin, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo }, reloadKey);
};
export const HeatLossSurveyPage = ({ showLogo, isAdmin, companyPublicInfo, navigateTo }) => {
    // source is used to track the source of the survey
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    const adminContext = useContext(AdminContext);
    const [loadingData, setLoadingData] = useState(false);
    const [page, setPage] = useState(null);
    const [hasProgressBar, setHasProgressBar] = useState(true);
    const [survey, dispatch] = useReducer(surveyReducer, source, (source) => getInitialSurveyState(source ?? '', isAdmin));
    // hasProgressBar: we have a progress bar on the top of the survey page,
    //      and we have to show it everywhere except some pages,
    //      so, this setting is used to hide the progress bar on the address lookup pages
    //      Also, moving it to the component state leads to infinitive re-rendering of the component
    //      this is why it's managed here as the function return value
    const selectPage = () => {
        switch (survey.currentPage) {
            case SurveyPagesEnum.NewBuildOrRetrofitStep:
                return {
                    page: _jsx(OptionsStep, { headerText: "Start a new job", captionText: 'Please select the type of project to get started', noNavigateBack: true, options: PROJECT_TYPES.map(x => ({ key: x.key, label: x.name })), defaultOption: 'retrofit', onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    projectType: selectedOption,
                                    newBuildDefaultComparisonFuelType: selectedOption === 'new-build' ? 'Mains Gas' : undefined
                                }
                            });
                        } }, SurveyPagesEnum.NewBuildOrRetrofitStep),
                    hasProgressBar: true
                };
            // Address lookup pages
            case SurveyPagesEnum.ChooseAddressStep:
                return { page: _jsx(ChooseAddressStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.ManualAddressStep:
                return { page: _jsx(ManualAddressStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.ConfirmLocationStep:
                return { page: _jsx(ConfirmLocationStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.NoEPCFoundStep:
                return { page: _jsx(NoEPCFoundStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.EPCFoundStep:
                return { page: _jsx(EPCFoundStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            // Survey pages
            case SurveyPagesEnum.BuildingRegsStep:
                return { page: _jsx(BuildingRegsStep, {}), hasProgressBar: true };
            case SurveyPagesEnum.PropertyTypeStep:
                return {
                    page: _jsx(OptionsStep, { headerText: isAdmin ? 'What is the property type?' : 'What type of property do you live in?', options: PROPERTY_TYPES.map(x => ({ icon: x.icon, key: x.uuid, label: x.name })), defaultOption: survey.lead.property.propertyType, onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    propertyType: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.PropertyTypeStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.BuiltFormStep:
                return {
                    page: _jsx(OptionsStep, { headerText: isAdmin ? 'What is the built form?' : 'What best describes your home?', options: BUILT_FORM_TYPES.map(x => ({ icon: x.icon, key: x.uuid, label: x.name })), defaultOption: (survey.lead.property).builtForm, onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    builtForm: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.BuiltFormStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.OutdoorSpaceStep:
                return {
                    page: _jsx(OptionsStep, { headerText: isAdmin ? 'Is there space outdoors for a heat pump?' : 'Do you have outdoor space for a heat pump?', captionText: `An air source heat pump will work best if ${isAdmin ? 'there is' : 'you have'} a space approximately 3x2m outside.`, options: OUTDOOR_SPACE, defaultOption: (survey.lead.property).outdoorSpace, onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    outdoorSpace: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.OutdoorSpaceStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.BedroomsStep:
                return { page: _jsx(BedroomsStep, { companyPublicInfo: companyPublicInfo, adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.BathroomsStep:
                return { page: _jsx(BathroomsStep, { companyPublicInfo: companyPublicInfo, adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.StoreysStep:
                return { page: _jsx(StoreysStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.NewBuildNextStepsStep:
                return { page: _jsx(NewBuildNextStepsStep, {}), hasProgressBar: true };
            case SurveyPagesEnum.CurrentHeatingTypeStep:
                return {
                    page: _jsx(OptionsStep, { headerText: `What is the main source of fuel for ${isAdmin ? 'the property\'s' : 'your'} heating?`, captionText: `This will typically be what powers ${isAdmin ? 'the' : 'your'} boiler.`, options: FUELS.map(x => ({ key: x.name, label: x.name })), defaultOption: survey.lead.property.fuelType, onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    mainFuelSource: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.CurrentHeatingTypeStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.ConstructionAgeBandStep:
                return {
                    page: _jsx(OptionsStep, { headerText: `When was ${isAdmin ? 'the' : 'your'} property built?`, options: ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, label: x.name })), defaultOption: survey.lead.property.construction_age_band_uuid, onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    constructionAgeBand: ESTIMATE_AGE_BANDS.find(x => x.uuid === selectedOption)
                                }
                            });
                        } }, SurveyPagesEnum.ConstructionAgeBandStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.FloorAreaStep:
                return { page: _jsx(FloorAreaStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.WallTypeStep:
                return { page: _jsx(WallTypeStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.WallCavityInsulationStep:
                return {
                    page: _jsx(OptionsStep, { headerText: "Cavity wall insulation", captionText: `Let us know if ${isAdmin ? 'the' : 'your'} cavity walls are filled with insulating material.`, options: CAVITY_WALL_INSULATION.map(x => ({ icon: x.icon, key: x.name, label: x.name })), defaultOption: survey.lead.property.wallType ? survey.lead.property.wallType : survey.lead.property.construction_age_band?.wallType ?? '', onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    wallCavityInsulation: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.WallCavityInsulationStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.WallSolidInsulationStep:
                return {
                    page: _jsx(OptionsStep, { headerText: `Are ${isAdmin ? 'the' : 'your'} external walls insulated?`, options: SOLID_WALL_INSULATION.map(x => ({ icon: x.icon, key: x.uuid, label: x.name, sublabel: x.description })), defaultOption: survey.lead.property.wallType ?? 'none', onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    wallType: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.WallSolidInsulationStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.GlazingStep:
                return {
                    page: _jsx(OptionsStep, { headerText: `What are the majority of ${isAdmin ? 'the' : 'your'} windows?`, options: WINDOW_TYPES.map(x => ({ key: x.uuid, label: x.name })), defaultOption: survey.lead.property.windowType ? survey.lead.property.windowType : survey.lead.property.construction_age_band?.windowType ?? '', onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    windowsGlazing: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.GlazingStep),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.LoftInsulationStep:
                return {
                    page: _jsx(OptionsStep, { headerText: `How much insulation does ${isAdmin ? 'the' : 'your'} roof or loft have?`, options: LOFT_INSULATION.map(x => ({ key: x.name, label: x.name })), defaultOption: survey.lead.property.loftInsulation ? survey.lead.property.loftInsulation : survey.lead.property.construction_age_band?.loftInsulation ?? '', onNavigateNextPage: (selectedOption) => {
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    loftInsulation: selectedOption
                                }
                            });
                        } }, SurveyPagesEnum.LoftInsulationStep),
                    hasProgressBar: true
                };
            // Final pages with results and contacts
            case SurveyPagesEnum.ScottishGrantStep:
                return { page: _jsx(ScottishGrantStep, { adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.GetContactsStep:
                return { page: _jsx(GetContactsStep, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, adminEstimate: isAdmin }), hasProgressBar: true };
            case SurveyPagesEnum.ThankYouStep:
                return { page: _jsx(ThankYouStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            default:
                throw new Error('Unknown page');
        }
    };
    const handleHashChange = () => {
        // Handle hash changes
        const stepString = window.location.hash.substring(stepHashPrefix.length);
        // if last page in the stack is the page in the Hash, then we are going back
        if (survey.pagesStack.length !== 0 && survey.pagesStack[survey.pagesStack.length - 1].page === Number(stepString)) {
            dispatch({
                type: SurveyActionType.NavigatePreviousPage
            });
        }
        else {
            // ignore changes in the URL hash
            // this is going to happen probably if user manually changes the URL
            // FIXME: the code below is for Forward navigation, but it doesn't logically work
            // dispatch({
            //   type: SurveyActionType.NavigateToPageFromParams,
            //   payload: {
            //     page: Number(stepString) as SurveyPagesEnum
            //   }
            // })
        }
    };
    // Listen for hash changes
    React.useEffect(() => {
        // this is going to be called ONLY when user changes URL manually or by clicking on the back button
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
    // re-render only when the currentPage changes
    useEffect(() => {
        // ensure the URL hash contains the current page
        // save current page to the browser history
        window.location.hash = `${stepHashPrefix}${survey.currentPage}`;
        // select the page to render
        const { page, hasProgressBar } = selectPage();
        setPage(page);
        setHasProgressBar(hasProgressBar);
    }, [survey.currentPage]);
    if (isAdmin && adminContext.isOffline) {
        return _jsx(OfflinePage, { navigateTo: navigateTo });
    }
    return (
    // pass the survey state and dispatch to all children
    _jsx(SurveyContext.Provider, { value: survey, children: _jsx(SurveyDispatchContext.Provider, { value: dispatch, children: _jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: !hasProgressBar ? 'border-solid border-b border-gray-300 ' : '', children: [_jsxs("div", { className: 'flex items-center px-4', children: [isAdmin && showLogo && _jsx(WrappedIcon, { className: 'cursor-pointer text-gray-600 lg:hidden', icon: Menu, onClick: () => adminContext.isSidebarOpen ? adminContext.hideSidebar() : adminContext.showSidebar() }), showLogo && _jsx(LogoHeader, { companyPublicInfo: companyPublicInfo })] }), hasProgressBar && _jsx(ProgressBar, { progress: survey.pbValue })] }), _jsx(DataLoaderContext.Provider, { value: { loadingData, setLoadingData }, children: _jsxs("div", { className: "px-7 py-8 flex-col gap-8 flex max-w-xl mx-auto w-full", children: [loadingData && _jsx(Loader, {}), !loadingData && page] }) })] }) }) }));
};
