import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { WALL_GROUPS } from '../../../code/models/u_value';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const WallTypeStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [hasSelectionChanged, setHasSelectionChanged] = useState(false);
    const [selectedOption, setSelectedOption] = useState(survey.lead.property.wallGroup ? survey.lead.property.wallGroup : survey.lead.property.construction_age_band?.wallGroup ?? '');
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                wallGroup: selectedOption,
                // If we know it's cavity wall but they select solid brick, we need to remove e.g. Insulated wallType from EPC so our defaults can take over.
                wallType: hasSelectionChanged ? undefined : survey.lead.property.wallType
            }
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: `What are most of ${adminEstimate ? 'the' : 'your'} external walls made of?` }), _jsx(SurveyOptionGroup, { options: WALL_GROUPS.map(x => ({ icon: x.icon, key: x.name, label: x.name })), onSelect: (key) => {
                    setSelectedOption(key);
                    setHasSelectionChanged(true);
                }, selectedOptionKey: selectedOption }), _jsx(Button, { size: 'LG', disabled: !selectedOption, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
