import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { apiUrl, client } from '../code/axios';
import { logEvent } from '../code/log_event';
import { isValidEmail, isValidPassword, isValidPhone } from '../code/validators';
import { Button } from '../components/buttons/button';
import { Input } from '../components/inputs_and_selections/input';
import { Section } from '../components/containers/section';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { signUp } from '../code/models/auth';
import { AuthSDK } from '../code/utils/auth_provider';
export const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [logo, setLogo] = useState('');
    const [stripeCustomerId, setstripeCustomerId] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordRepeatedValid, setPasswordRepeatedValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [companyPhoneValid, setCompanyPhoneValid] = useState(true);
    // we use companyAddressValid == null to store uncertain state.
    // In this state we don't know if the address is valid or not
    // and we don't show any error message
    const [companyAddressValid, setCompanyAddressValid] = useState();
    const [logoValid, setLogoValid] = useState(true);
    const [stripeCustomerIdValid, setstripeCustomerIdValid] = useState(true);
    // to delay user input for address
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [changed, setChanged] = useState({
        email: false,
        password: false,
        passwordRepeated: false,
        firstName: false,
        lastName: false,
        companyName: false,
        companyPhone: false,
        companyAddress: false,
        stripeCustomerId: false
    });
    const isValid = () => {
        return emailValid &&
            passwordValid &&
            passwordRepeatedValid &&
            firstNameValid &&
            lastNameValid &&
            companyNameValid &&
            companyPhoneValid &&
            companyAddressValid &&
            logoValid &&
            stripeCustomerIdValid;
    };
    const handleLogoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const image = new Image();
            image.onload = () => {
                const maxWidth = 640;
                const maxHeight = 480;
                let width = image.width;
                let height = image.height;
                // Calculate the scale factor
                const widthScale = maxWidth / width;
                const heightScale = maxHeight / height;
                const scaleFactor = Math.min(widthScale, heightScale);
                // If the image is larger than the max dimensions, resize it
                if (scaleFactor < 1) {
                    width *= scaleFactor;
                    height *= scaleFactor;
                }
                // Draw the resized image on the canvas
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);
                // Convert canvas to base64
                canvas.toBlob(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setLogo(reader.result?.toString() ?? '');
                    };
                    reader.readAsDataURL(blob);
                });
            };
            image.src = URL.createObjectURL(file);
        }
    };
    const handleAddressChange = (e) => {
        const newAddress = e;
        setCompanyAddress(newAddress);
        setChanged({ ...changed, companyAddress: true });
        // we use null to store uncertain state.
        // In this state we don't know if the address is valid or not
        // and we don't show any error message
        setCompanyAddressValid(undefined);
    };
    const updateDelayedAddress = (address) => {
        const result = async () => {
            if (address === '')
                return;
            // check if address is valid and exists
            const response = await client.get(`${apiUrl}geocode?address=${encodeURIComponent(address)}`);
            if (response?.data?.lat && response.data.lng) {
                setCompanyAddressValid(true);
                setMapCenter(response.data);
            }
            else {
                setCompanyAddressValid(false);
                setMapCenter({ lat: 0, lng: 0 });
            }
        };
        result();
    };
    const onSubmit = async () => {
        if (!isValid())
            return;
        logEvent({ name: 'Sign Up Posted', properties: {} }, null);
        const response = await signUp(email, password, firstName, lastName, companyName, companyPhone, companyAddress, mapCenter, logo, stripeCustomerId);
        await AuthSDK.signIn(email, password);
        if (response?.status === 200) {
            window.location.href = '/admin/enquiries';
        }
        else {
            // FIXME: show error message
            alert('Something went wrong. Please try again.');
        }
    };
    useEffect(() => {
        setEmailValid(isValidEmail(email));
        setPasswordValid(isValidPassword(password));
        setPasswordRepeatedValid(password === passwordRepeated);
        setFirstNameValid(firstName.length > 0);
        setLastNameValid(lastName.length > 0);
        setCompanyNameValid(companyName.length > 0);
        setCompanyPhoneValid(isValidPhone(companyPhone));
        setLogoValid(logo.length > 0);
        setstripeCustomerIdValid(stripeCustomerId.length > 0);
    }, [email, password, passwordRepeated, firstName, lastName, companyName, companyPhone, logo, stripeCustomerId]);
    useEffect(() => {
        const timer = setTimeout(() => {
            updateDelayedAddress(companyAddress);
        }, 800);
        return () => { clearTimeout(timer); };
    }, [companyAddress]);
    return _jsx("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto", children: _jsx(Section, { border: true, title: "Sign up", children: _jsxs("div", { className: "space-y-4", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Email" }), _jsx(Input, { onEnter: onSubmit, type: "email", value: email, setValue: (e) => {
                                    setEmail(e);
                                    setChanged({ ...changed, email: true });
                                }, placeholder: "Enter your email address" })] }), changed.email && !emailValid && _jsx("span", { className: "text-red-500", children: "Invalid email address" }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Password" }), _jsx(Input, { onEnter: onSubmit, type: "password", value: password, setValue: (e) => {
                                    setPassword(e);
                                    setChanged({ ...changed, password: true });
                                }, placeholder: "Enter your password" })] }), changed.password && !passwordValid && _jsxs("span", { className: "text-red-500", children: ["Invalid password. ", _jsx("br", {}), " Must be at least 8 characters long and contain at least one letter and one number."] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Repeat your password" }), _jsx(Input, { onEnter: onSubmit, type: "password", value: passwordRepeated, setValue: (e) => {
                                    setPasswordRepeated(e);
                                    setChanged({ ...changed, passwordRepeated: true });
                                }, placeholder: "Enter your password again" })] }), changed.passwordRepeated && !passwordRepeatedValid && _jsx("span", { className: "text-red-500", children: "Password is not the same" }), _jsxs("div", { className: "flex space-x-4", children: [_jsxs("div", { className: "space-y-2 w-1/2", children: [_jsx("div", { className: "font-semibold", children: "First Name" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: firstName, setValue: (e) => {
                                            setFirstName(e);
                                            setChanged({ ...changed, firstName: true });
                                        }, placeholder: "Enter your first name" }), changed.firstName && !firstNameValid && _jsx("span", { className: "text-red-500", children: "Invalid first name" })] }), _jsxs("div", { className: "space-y-2 w-1/2", children: [_jsx("div", { className: "font-semibold", children: "Last Name" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: lastName, setValue: (e) => {
                                            setLastName(e);
                                            setChanged({ ...changed, lastName: true });
                                        }, placeholder: "Enter your last name" }), changed.lastName && !lastNameValid && _jsx("span", { className: "text-red-500", children: "Invalid last name" })] })] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Company name" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: companyName, setValue: (e) => {
                                    setCompanyName(e);
                                    setChanged({ ...changed, companyName: true });
                                }, placeholder: "Enter your company name" })] }), changed.companyName && !companyNameValid && _jsx("span", { className: "text-red-500", children: "Invalid name" }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Company phone" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: companyPhone, setValue: (e) => {
                                    setCompanyPhone(e);
                                    setChanged({ ...changed, companyPhone: true });
                                }, placeholder: "Enter your company phone number" })] }), changed.companyPhone && !companyPhoneValid && _jsx("span", { className: "text-red-500", children: "Invalid phone" }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Company address" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: companyAddress, setValue: (e) => {
                                    handleAddressChange(e);
                                    setChanged({ ...changed, companyAddress: true });
                                }, placeholder: "Enter your company address" })] }), changed.companyAddress && companyAddressValid === false && _jsx("span", { className: "text-red-500", children: "Invalid address" }), changed.companyAddress && companyAddressValid && mapCenter.lat !== 0 && mapCenter.lng !== 0 &&
                        _jsx(Map, { defaultCenter: mapCenter, className: "h-[200px] w-full", defaultZoom: 13, children: _jsx(Marker, { position: mapCenter }) }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Stripe customer ID" }), _jsx(Input, { onEnter: onSubmit, type: "text", value: stripeCustomerId, setValue: (e) => {
                                    setstripeCustomerId(e);
                                    setChanged({ ...changed, stripeCustomerId: true });
                                }, placeholder: "cus_..." })] }), changed.stripeCustomerId && !stripeCustomerIdValid && _jsx("span", { className: "text-red-500", children: "Invalid Stripe customer ID" }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-semibold", children: "Company Logo" }), _jsx("input", { type: "file", onChange: handleLogoChange, accept: "image/*" })] }), changed.email &&
                        changed.password &&
                        changed.passwordRepeated &&
                        changed.firstName &&
                        changed.lastName &&
                        changed.companyName &&
                        changed.companyPhone &&
                        changed.companyAddress &&
                        changed.stripeCustomerId &&
                        !logo && _jsx("span", { className: "text-red-500", children: "Add logo" }), _jsx(Button, { disabled: !isValid(), block: true, onClick: onSubmit, children: "Sign Up" })] }) }) });
};
