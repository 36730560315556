import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { HLRContext } from './heatloss_report_page';
import { reversePriceOrNumberFormat } from '../../../code/models/performance_estimate';
import { AGILE_PENCE_PER_KWH, OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH, PRICE_CAP_ELECTRICITY_PENCE_PER_KWH } from '../../../code/models/heating_fuel';
export const HLRPEResultsTable = () => {
    const hlrContext = React.useContext(HLRContext);
    const fuelName = hlrContext.performanceEstimateSummary.hddEstimate.existing.fuelName;
    const estimatesGroup = hlrContext.performanceEstimateSummary.estimatesGroup;
    const numberOfMethods = hlrContext.performanceEstimateSummary.numberOfMethods;
    const columnNamesInOrder = Object.keys(estimatesGroup);
    const tables = {
        Energy: [
            {
                Heating: Object.fromEntries(// package list of single key-value pairs into an object
                Object.entries(estimatesGroup).map(([key, value]) => {
                    return [key, value ? [{ text: value.existing.consumptionSpaceHeatingKwh }, { text: value.proposed.consumptionSpaceHeatingKwh }] : undefined];
                }))
            },
            {
                'Hot Water': Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.consumptionHotWaterKwh }, { text: value.proposed.consumptionHotWaterKwh }] : undefined];
                }))
            },
            {
                Total: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.kwh }, { text: value.proposed.kwh }] : undefined];
                }))
            }
        ],
        Bills: [
            {
                [`Price cap (${PRICE_CAP_ELECTRICITY_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.costPriceCap },
                            { text: value.proposed.costPriceCap, badgeText: value.savings.costPriceCap, badgeStyle: reversePriceOrNumberFormat(value.savings.costPriceCap) > 0 ? 'GREEN' : 'RED' }] : undefined];
                }))
            },
            ...hlrContext?.eligibleForHeatPumpPlus
                ? [{
                        [`OVO Heat Pump Plus (${OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                            return [[key], value ? [{ text: value.existing.costOvoHeatPumpPlus },
                                    { text: value.proposed.costOvoHeatPumpPlus, badgeText: value.savings.costOvoHeatPumpPlus, badgeStyle: reversePriceOrNumberFormat(value.savings.costOvoHeatPumpPlus) > 0 ? 'GREEN' : 'RED' }] : undefined];
                        }))
                    }] : [], // empty list if not eligible
            {
                [`Octopus Agile (${AGILE_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value
                            ? [{ text: value.existing.costAgile }, { text: value.proposed.costAgile, badgeText: value.savings.costAgile, badgeStyle: reversePriceOrNumberFormat(value.savings.costAgile) > 0 ? 'GREEN' : 'RED' }]
                            : undefined];
                }))
            }
        ],
        Carbon: [
            {
                Totals: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [key, value
                            ? [{ text: value.existing.emissionsKG }, { text: value.proposed.emissionsKG, badgeText: value.savings.emissionsKG, badgeStyle: reversePriceOrNumberFormat(value.savings.emissionsKG) > 0 ? 'GREEN' : 'RED' }]
                            : undefined];
                }))
            }
        ]
    };
    const colContent = (colData) => {
        return _jsxs(_Fragment, { children: [colData.badgeText !== '' && _jsxs("div", { className: 'inline-flex flex-col gap-2', children: [_jsx("div", { children: colData.text }), _jsx(Badge, { color: colData.badgeStyle, text: colData.badgeText })] }), colData.badgeText === '' && _jsx("div", { children: colData.text })] });
    };
    return (_jsxs("div", { className: 'flex gap-6 flex-col', children: [_jsx(HLRSubheaderBlock, { section: "Performance estimate", title: "Results table" }), _jsxs("div", { className: 'grid grid-cols-7', children: [_jsx("div", { className: 'pb-2' }), _jsx("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end', children: columnNamesInOrder[0] }), _jsx("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end', children: columnNamesInOrder[1] }), _jsx("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end', children: columnNamesInOrder[2] }), Object.entries(tables).map((table, idx) => {
                        const tableTitle = table[0];
                        const tableRows = table[1];
                        return _jsxs(React.Fragment, { children: [_jsx("div", { className: 'bg-gray-100 rounded-tl-md pl-3 pt-6 pb-3 text-gray-900 text-lg font-bold', children: tableTitle }), _jsxs("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Existing ", _jsx("br", {}), "(", fuelName, ")"] }), _jsxs("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Heat pump ", _jsx("br", {}), "(Electricity)"] }), numberOfMethods > 1 && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Existing ", _jsx("br", {}), "(", fuelName, ")"] }), _jsxs("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Heat pump ", _jsx("br", {}), "(Electricity)"] })] }), numberOfMethods <= 1 && _jsx(_Fragment, { children: _jsx("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2', children: "Unavailable" }) }), numberOfMethods > 2 && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Existing ", _jsx("br", {}), "(", fuelName, ")"] }), _jsxs("div", { className: 'bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight', children: ["Heat pump ", _jsx("br", {}), "(Electricity)"] })] }), numberOfMethods <= 2 && _jsx(_Fragment, { children: _jsx("div", { className: 'bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2', children: "Unavailable" }) }), tableRows.map((row, rowIdx) => {
                                    const rowTitle = Object.keys(row)[0];
                                    const rowData = Object.keys(row).map(key => row[key])[0];
                                    const lastRow = rowIdx === tableRows.length - 1;
                                    const padding = lastRow ? 'pb-6 pt-3' : 'py-3';
                                    const firstPairCells = rowData[columnNamesInOrder[0]];
                                    const secondPairCells = rowData[columnNamesInOrder[1]];
                                    const thirdPairCells = rowData[columnNamesInOrder[2]];
                                    return _jsxs(React.Fragment, { children: [_jsx("div", { className: `${lastRow ? 'rounded-bl-md' : ''} bg-gray-100 pl-3 text-gray-900 text-xs font-bold`, children: _jsx("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300 pr-2`, children: rowTitle }) }), _jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`, children: colContent(firstPairCells[0]) }), _jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`, children: colContent(firstPairCells[1]) }), !secondPairCells && _jsx(_Fragment, { children: _jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold col-span-2` }) }), secondPairCells && _jsxs(_Fragment, { children: [_jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`, children: colContent(secondPairCells[0]) }), _jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`, children: colContent(secondPairCells[1]) })] }), !thirdPairCells && _jsx(_Fragment, { children: _jsx("div", { className: `${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold col-span-2`, children: _jsx("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300` }) }) }), thirdPairCells && _jsxs(_Fragment, { children: [_jsx("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold`, children: colContent(thirdPairCells[0]) }), _jsx("div", { className: `${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold`, children: _jsx("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300`, children: colContent(thirdPairCells[1]) }) })] })] }, 'row-' + rowIdx);
                                }), idx < Object.keys(tables).length - 1 && _jsx("div", { className: 'col-span-7 py-3' })] }, 'table-' + idx);
                    })] })] }));
};
