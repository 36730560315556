import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading } from '../../../components/content_display/heading';
export const HeadingDemo = () => {
    return (_jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => (_jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name))) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Sizes" }), _jsxs("div", { className: 'flex flex-col justify-start items-start gap-4', children: [_jsx(Heading, { size: "md", children: "Medium heading (h5)" }), _jsx(Heading, { size: "lg", children: "Large heading (h4)" }), _jsx(Heading, { size: "xl", children: "Extra large heading (h3)" }), _jsx(Heading, { size: "2xl", children: "Double extra large heading (h2)" }), _jsx(Heading, { size: "3xl", children: "Triple extra large heading (h1)" })] }), _jsx("div", { children: "Custom class example" }), _jsx(Heading, { size: "2xl", className: "bg-yellow-300 rounded italic", children: "Custom styled heading" })] })] }));
};
const componentProps = [
    { name: 'children', description: 'React.ReactNode or string to be rendered as heading content' },
    { name: 'size', description: 'md | lg | xl | 2xl | 3xl - Determines both the heading level and text size' },
    { name: 'className', description: 'Additional CSS classes to apply (optional)' }
];
const codeBlock = `<Heading
  size="2xl"
>
  Hello World
</Heading>`;
