import { z } from 'zod';
import { SyncItemSchema } from '../pages/admin/sync';
import { axiosGetV2 } from './axios';
export const StatusSchema = z.object({
    code: z.string(),
    name: z.string(),
    order: z.number(),
    color: z.string(),
    stage_uuid: z.string()
}).merge(SyncItemSchema);
export const StageSchema = z.object({
    code: z.string(),
    name: z.string(),
    order: z.number(),
    statuses: StatusSchema.array()
}).merge(SyncItemSchema);
export const getStages = (company_uuid) => axiosGetV2(`companies/${company_uuid}/stages`);
export const getStatuses = (company_uuid) => axiosGetV2(`companies/${company_uuid}/statuses`);
