import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { buildSingleLineAddressFromLines, checkIfScottish, findAddresses, getAddressForUdprn, parseAddressData } from '../../../code/models/address';
import { ChevronRightIcon } from 'lucide-react';
import { getEpcScottishForUprn, getEpcForUprn, parseEnglishEpc } from '../../../code/models/epc';
import { parseScottishEpc } from '../../../code/models/epc_scotland';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Alert } from '../../../components/indicators_and_messaging/alert';
export const ManualAddressStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    // component state
    const [addressLine1, setAddressLine1] = useState(survey.address?.line_1 || '');
    const [addressLine2, setAddressLine2] = useState(survey.address?.line_2 || '');
    const [addressLine3, setAddressLine3] = useState(survey.address?.line_3 || '');
    const [town, setTown] = useState(survey.address?.town || '');
    const [postcode, setPostcode] = useState(survey.address?.postcode || '');
    const [loading, setLoading] = useState(false);
    const handleNavigateNextPage = async () => {
        const manualAddress = buildSingleLineAddressFromLines({ line_1: addressLine1, line_2: addressLine2, line_3: addressLine3, post_town: town, postcode });
        let lead = survey.lead;
        try {
            setLoading(true);
            // try to find the address
            const addressResponse = await findAddresses(`${addressLine1} ${town} ${postcode}`);
            // ASSUMPTION: if we find a single address, we 99.9% probably have the right EPC.
            if (addressResponse.length === 1) {
                const udprn = addressResponse[0].udprn;
                const address = await getAddressForUdprn(udprn);
                if (address) {
                    lead = parseAddressData(address, survey.lead);
                    const isScottish = checkIfScottish(lead.property.postcode);
                    if (isScottish) {
                        const epc = await getEpcScottishForUprn(address.uprn);
                        if (epc) {
                            lead = await parseScottishEpc(epc, lead);
                        }
                    }
                    else {
                        const epc = await getEpcForUprn(address.uprn);
                        if (epc) {
                            lead = await parseEnglishEpc(epc, lead);
                        }
                    }
                    setLoading(false);
                    dispatch({
                        type: SurveyActionType.NavigateNextPage,
                        payload: {
                            lead,
                            address: {
                                line_1: addressLine1,
                                line_2: addressLine2,
                                line_3: addressLine3,
                                town,
                                postcode
                            }
                        }
                    });
                }
            }
            else {
                // Multiple addresses found, save and continue with no EPC
                setLoading(false);
                dispatch({
                    type: SurveyActionType.NavigateNextPage,
                    payload: {
                        lead: { ...lead, property: { ...lead.property, address: manualAddress, postcode } },
                        address: {
                            line_1: addressLine1,
                            line_2: addressLine2,
                            line_3: addressLine3,
                            town,
                            postcode
                        }
                    }
                });
            }
        }
        catch (e) {
            setLoading(false);
            console.error(e);
            dispatch({
                type: SurveyActionType.NavigateNextPage,
                payload: {
                    lead: { ...lead, property: { ...lead.property, address: manualAddress, postcode } },
                    address: {
                        line_1: addressLine1,
                        line_2: addressLine2,
                        line_3: addressLine3,
                        town,
                        postcode
                    }
                }
            });
        }
    };
    // FYI - Not validating the inputs themselves, but checking they're not empty
    const validateAddress = (x) => ({ value: x, message: x.length > 0 ? '' : 'Please enter a valid address' });
    const validatePostcode = (x) => ({ value: x, message: x.length > 0 ? '' : 'Please enter a valid postal code' });
    if (loading) {
        return _jsx(Loader, {});
    }
    return _jsxs(_Fragment, { children: [_jsx(StepHeader, { headerText: `Enter ${adminEstimate ? 'the' : 'your'} address manually` }), adminEstimate && _jsx(Alert, { type: 'INFO', children: "Unsure of the address? Please provide an accurate city/town and at least the outward postcode, then fill address line 1 with any value." }), _jsxs("div", { className: "w-full flex flex-col gap-3", children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Address line 1', required: true, complete: Boolean(validateAddress(addressLine1).value) }), input: _jsx(Input, { size: 'LG', setValue: setAddressLine1, type: 'text', value: addressLine1, validator: validateAddress }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Address line 2' }), input: _jsx(Input, { size: 'LG', setValue: setAddressLine2, type: 'text', value: addressLine2 }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Address line 3' }), input: _jsx(Input, { size: 'LG', setValue: setAddressLine3, type: 'text', value: addressLine3 }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'City or town', required: true, complete: Boolean(validateAddress(town).value) }), input: _jsx(Input, { size: 'LG', setValue: setTown, type: 'text', value: town, validator: validateAddress }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Postcode', required: true, complete: Boolean(validatePostcode(postcode).value) }), input: _jsx(Input, { size: 'LG', setValue: setPostcode, type: 'text', value: postcode, validator: validatePostcode }) })] }), _jsx(Button, { size: 'LG', disabled: addressLine1.length === 0 || postcode.length === 0, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-5 w-5" })] }) })] });
};
