import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { PageHeader } from '../../design/components/design_page_header';
import { isPreFlowFeatureSurvey } from '../../../../code/models/property';
import { usePagesFlow } from '../../../../code/use_pages_flow';
import { CompleteButton } from '../complete_button';
import { FlowPageHeader } from '../../design/components/flow_page_header';
import { Button } from '../../../../components/buttons/button';
import { ChevronRight } from 'lucide-react';
// is user exits the flow
export const FLOW_CANCELLED_VALUE = -1;
// is user finishes the flow. It's a separate value to track stats
export const FLOW_FINISHED_VALUE = -2;
export const FlowWrapperContext = React.createContext(undefined);
export function SurveySectionFlowWrapper({ completeSectionID, sectionUIName, pages, flags_attribute_name, onPageShow, onPageComplete, onClose, startPage, survey, setSurvey, completionChecks, hideCompleteButton, isOffline, allSynced, onFormComplete }) {
    const isFinishedOrCancelledFlow = survey[flags_attribute_name] === FLOW_FINISHED_VALUE ||
        survey[flags_attribute_name] === FLOW_CANCELLED_VALUE;
    const [viewMode, setViewMode] = useState((isFinishedOrCancelledFlow || isPreFlowFeatureSurvey(survey)) ? 'FULL' : 'FLOW');
    const [currentPageBitFlag, next, prev] = usePagesFlow({
        flagsCompletedPages: survey[flags_attribute_name],
        startPage,
        onEndOfFlow: () => {
            handleOnFlowFinish();
        },
        onPageCompleted: onPageComplete,
        pages
    });
    const currentPage = pages.find(p => p.flagBit === currentPageBitFlag);
    onPageShow && onPageShow(currentPageBitFlag);
    const handleOnFlowFinish = async () => {
        await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_FINISHED_VALUE }));
        onFormComplete && onFormComplete();
        setViewMode('FULL');
    };
    return _jsx(FlowWrapperContext.Provider, { value: { viewMode }, children: _jsxs("div", { className: 'flex flex-col h-full min-h-0', children: [viewMode === 'FULL' &&
                    _jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: sectionUIName, onBack: () => window.history.back() }), viewMode === 'FLOW' &&
                    _jsx(FlowPageHeader, { progress: currentPage?.pbValue ?? 0, title: sectionUIName, onBack: () => {
                            // if the first page, exit the section
                            if (currentPageBitFlag === 0x001) {
                                window.history.back();
                            }
                            else {
                                // otherwise, go to the previous page
                                prev();
                            }
                        }, onClose: async () => {
                            await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_CANCELLED_VALUE }));
                            // if there is a custom onClose function, call it
                            if (onClose) {
                                onClose();
                            }
                            else {
                                setViewMode('FULL');
                            }
                        } }), _jsxs("div", { className: "p-5 bg-white flex-col gap-6 flex flex-grow overflow-y-auto", children: [viewMode === 'FLOW' && currentPage?.page, viewMode === 'FULL' && _jsx(_Fragment, { children: pages.map((p, idx) => {
                                const shouldHide = p.hideOrSkipPage && p.hideOrSkipPage();
                                return _jsxs(React.Fragment, { children: [shouldHide && _jsx(_Fragment, {}), !shouldHide && _jsxs(_Fragment, { children: [idx !== 0 && _jsx("div", { className: "border border-gray-200" }), p.page] })] }, idx);
                            }) })] }), viewMode === 'FLOW' && _jsxs(_Fragment, { children: [currentPageBitFlag !== pages[pages.length - 1].flagBit &&
                            _jsx("div", { className: "p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl", children: _jsx(Button, { block: true, iconRight: ChevronRight, onClick: next, colour: 'DARK', children: "Continue" }) }), completionChecks && completeSectionID && currentPageBitFlag === pages[pages.length - 1].flagBit &&
                            (hideCompleteButton
                                ? _jsx("div", { className: "p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl", children: _jsx(Button, { block: true, iconRight: ChevronRight, onClick: handleOnFlowFinish, colour: 'DARK', children: "Continue" }) })
                                : _jsx(CompleteButton, { page: completeSectionID, survey: survey, setSurvey: setSurvey, pageChecks: completionChecks, onBack: async () => {
                                        await handleOnFlowFinish();
                                    }, secondaryButton: _jsx(Button, { className: 'h-full', block: true, onClick: () => {
                                            handleOnFlowFinish();
                                        }, colour: 'LIGHT', children: "Close section" }) }))] }), !hideCompleteButton && completeSectionID && completionChecks && viewMode === 'FULL' && _jsx(_Fragment, { children: _jsx(CompleteButton, { page: completeSectionID, survey: survey, setSurvey: setSurvey, pageChecks: completionChecks, onBack: async () => {
                            await handleOnFlowFinish();
                        } }) })] }) });
}
