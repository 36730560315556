import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Heading } from '../../../../components/content_display/heading';
import { Icon } from '../../../../components/buttons/icon';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { ChevronLeft, X } from 'lucide-react';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
export const FullscreenPreviewModal = (props) => {
    if (!props.visible)
        return null;
    return _jsx(_Fragment, { children: _jsx("div", { style: {
                paddingTop: 'env(safe-area-inset-top)',
                paddingRight: 'env(safe-area-inset-right)',
                paddingBottom: 'env(safe-area-inset-bottom)',
                paddingLeft: 'env(safe-area-inset-left)'
            }, className: 'fixed top-0 left-0 w-full h-full bg-white z-10', children: _jsxs("div", { className: 'bg-white rounded-md flex flex-col w-full h-full', children: [_jsxs("div", { className: 'p-5 flex justify-between items-center border-b border-gray-200', children: [_jsxs("div", { className: 'flex flex-row gap-4 items-center', children: [_jsx(Icon, { icon: ChevronLeft, onClick: () => props.setVisible(false) }), _jsx(Heading, { size: "xl", children: props.title })] }), _jsx(WrappedIcon, { icon: X, onClick: () => props.setVisible(false), className: 'cursor-pointer' })] }), _jsxs("div", { className: 'flex flex-row justify-between items-center h-[calc(100vh-74px)]', children: [_jsx("div", { className: 'hidden lg:block bg-gray-100 h-[calc(100vh-74px)] flex-grow overflow-x-scroll p-6', children: _jsx("div", { className: 'bg-white max-w-[260mm] m-auto pb-6 rounded', children: props.previewContent }) }), _jsxs("div", { className: 'w-full lg:max-w-96 lg:min-w-96 h-full flax flex-col gap-0', children: [_jsx("div", { className: "text-center text-gray-900 font-bold p-4 border-b border-gray-200", children: props.sidebarTitle }), _jsxs("div", { className: 'h-[calc(100vh-130px)] overflow-scroll w-full flex flex-col gap-0 p-5 items-center', children: [_jsx(Alert, { className: 'block lg:hidden w-full mb-4', type: "INFO", children: "To view the preview, please open Spruce on a tablet or desktop device." }), props.sidebarContent] })] })] })] }) }) });
};
