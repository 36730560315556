import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Input } from '../../../components/inputs_and_selections/input';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
export const VerticalFormGroupDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Hello world', helperText: 'some helper' }), input: _jsx(Input, { value: '1234', setValue: noop }), size: 'MD' })] })] });
};
const componentProps = [
    { name: 'formLabel', description: 'Any react element, but expects FormLabel component' },
    { name: 'input', description: 'Any react element, but expects Input component' },
    { name: 'size', description: 'SM | MD | LG' }
];
const codeBlock = `<VerticalFormGroup
  formLabel={<FormLabel labelText='Hello world' />}
  input={<Input value='1234' setValue={noop} />}
  size='MD'
/>`;
