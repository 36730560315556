import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { calculateLineLength } from './code/utils';
import { Button } from '../../../components/buttons/button';
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
import { Input } from '../../../components/inputs_and_selections/input';
import { ChevronRight } from 'lucide-react';
export const ScalePage = ({ scalingPoints, tempImageAndScale, scaleLength, setScaleLength, addEvent, floor, resetUploadPlan, setHeader, setStageStep, setScalingPoints, setIsPageOpen }) => {
    useEffect(() => {
        setHeader(_jsx(BottomSheetHeader, { onBack: () => {
                setStageStep(prev => prev - 1);
                setScalingPoints(prev => prev.slice(0, -1));
            }, title: 'Enter line length' }));
    }, []);
    return _jsx("div", { className: 'flex flex-col gap-3 p-5', children: scalingPoints.length === 2 && _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Object length" }), _jsx("div", { className: "text-gray-500 text-sm", children: "Enter the length of the object from the floor plan." })] }), _jsxs("div", { className: 'flex flex-col gap-5', children: [_jsx(Input, { type: 'number', className: 'flex-grow', postfix: 'metres', value: scaleLength?.toString() ?? '', setValue: (e) => setScaleLength(parseFloat(e)) }), _jsx(Button, { disabled: !tempImageAndScale?.scale, onClick: () => {
                                const newScale = scaleLength ? scaleLength / (calculateLineLength(scalingPoints[0].x, scalingPoints[0].y, scalingPoints[1].x, scalingPoints[1].y)) : 0;
                                addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, floor_plan_image: tempImageAndScale.image, floor_plan_scale: tempImageAndScale.scale * newScale } }]);
                                resetUploadPlan();
                            }, iconRight: ChevronRight, children: "Apply" })] })] }) });
};
