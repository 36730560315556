import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { SettingsImageInput } from '../../components/settings_image_input';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { Card } from '../../../../../components/content_display/card';
export const BrandSignatureBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: 'Company signature', children: _jsx("div", { className: 'flex flex-col gap-4  ', children: _jsxs("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4', children: [_jsxs("div", { className: 'grid grid-cols-1 gap-4', children: [_jsx(Text, { size: "SM", className: 'text-gray-500', children: "This signature will appear on estimates and proposals." }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Name' }), input: _jsx(Input, { value: company.public_info.estimate_contact_name ?? '', setValue: nameInput => setCompany({
                                        ...company,
                                        public_info: {
                                            ...company.public_info,
                                            estimate_contact_name: nameInput
                                        }
                                    }), placeholder: 'Signature name' }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Job title' }), input: _jsx(Input, { value: company.public_info.estimate_contact_role ?? '', setValue: roleInput => setCompany({
                                        ...company,
                                        public_info: {
                                            ...company.public_info,
                                            estimate_contact_role: roleInput
                                        }
                                    }), placeholder: 'Signature job title' }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Phone' }), input: _jsx(Input, { value: company.public_info.estimate_contact_phone ?? '', setValue: senderInput => setCompany({
                                        ...company,
                                        public_info: {
                                            ...company.public_info,
                                            estimate_contact_phone: senderInput
                                        }
                                    }), placeholder: 'Signature phone number' }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Image', helperText: 'For best results, image should be square' }), input: _jsx(SettingsImageInput, { imgClassName: 'rounded-full aspect-square object-cover w-20 h-20', buttonText: 'Upload image', placeholderText: 'No image', helperText: 'Maximum file size 5MB', b64Image: company.public_info.estimate_contact_portrait ?? '', altText: 'Company signature image', handleUpdateImage: async (imageSrc) => {
                                        const newCompany = {
                                            ...company,
                                            public_info: {
                                                ...company.public_info,
                                                estimate_contact_portrait: imageSrc
                                            }
                                        };
                                        await updateCompanySettings(newCompany);
                                        setCompany(newCompany);
                                    }, handleRemoveImage: async () => {
                                        const newCompany = {
                                            ...company,
                                            public_info: {
                                                ...company.public_info,
                                                estimate_contact_portrait: ''
                                            }
                                        };
                                        await updateCompanySettings(newCompany);
                                        setCompany(newCompany);
                                    } }) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(FormLabel, { labelText: 'Preview' }), company.public_info.estimate_contact_name || company.public_info.estimate_contact_role || company.public_info.estimate_contact_phone || company.public_info.estimate_contact_portrait ? null : _jsx(Text, { size: 'SM', className: 'text-gray-500', children: "Fill in the fields to see a preview." }), company.public_info.estimate_contact_name || company.public_info.estimate_contact_role || company.public_info.estimate_contact_phone || company.public_info.estimate_contact_portrait ? _jsx(Card, { color: 'LIGHT', className: 'bg-slate-50 flex flex-col gap-4', children: _jsxs("div", { className: 'flex justify-start items-center pt-1 gap-4', children: [company.public_info.estimate_contact_portrait && _jsx("img", { src: company.public_info.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsxs("div", { children: [_jsx(Text, { bold: true, size: 'MD', children: company.public_info.estimate_contact_name }), _jsx(Text, { size: 'SM', children: company.public_info.estimate_contact_role })] }), _jsx("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${company.public_info.estimate_contact_phone}`, children: company.public_info.estimate_contact_phone })] })] }) })
                                : null] })] }) }) });
};
