import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
export const CylinderLocationPipeRunsBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Pipe runs" }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Distance" }), _jsx("div", { className: "text-gray-500 text-sm", children: "Rough distance from the cylinder location to the heat pump location." })] }), _jsx(Input, { type: 'number', value: survey.pipe_run_distance_metre?.toString(), postfix: 'm', setValue: (e) => setSurvey({ ...survey, pipe_run_distance_metre: Number(e) }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Pipe run description" }), _jsx("div", { className: "text-gray-500 text-sm", children: "Where the pipe runs should go from the cylinder location to the heat pump location." })] }), _jsx(TextArea, { value: survey.pipe_run_description, setValue: (e) => setSurvey({ ...survey, pipe_run_description: e }) })] })] });
};
