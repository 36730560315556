import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll';
export const PhotoScrollDemo = () => {
    const [files, setFiles] = useState([]);
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(PhotoScroll, { images: files, addImage: (e) => setFiles(prev => [...prev, e]), deleteImage: (id) => setFiles(prev => prev.filter(x => x.uuid !== id)), doNotScale: false, companyUUID: '' })] })] });
};
const componentProps = [
    { name: 'images', description: 'The list of existing images we should render, horizontally scrolls on overflow' },
    { name: 'addImage', description: 'What to do when user uploads an image' },
    { name: 'deleteImage', description: 'What to do when use clicks cross icon on specific image' },
    { name: 'doNotScale', description: 'If false, scale the image to specified max dimensions in codebase' }
];
const codeBlock = `const [files, setFiles] = useState<FileWrapper[]>([])

<PhotoScroll
  images={files}
  addImage={(e) => setFiles(prev => [...prev, e])}
  deleteImage={(id) => setFiles(prev => prev.filter(x => x.uuid !== id))}
  doNotScale={false}
/>
`;
