import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyEHSContext } from '../existing_heating_system';
import { getElectricityPencePerKwh, getHeatingFuel, getHeatingPencePerUnit, HEATING_FUELS } from '../../../../code/models/heating_fuel';
import { Icon } from '../../../../components/buttons/icon';
import { Input } from '../../../../components/inputs_and_selections/input';
import { XCircle } from 'lucide-react';
export const defaultTariffIsOverridden = (fuel, existingSystemPencePerUnitOverride) => {
    return existingSystemPencePerUnitOverride !== undefined && existingSystemPencePerUnitOverride !== fuel.default_tarrif;
};
export const EHSEnergyUseBlock = () => {
    const ehsContext = React.useContext(SurveyEHSContext);
    const { survey, setSurvey } = ehsContext;
    const heatingFuelId = survey.existing_system_fuel_uuid;
    const heatingFuel = getHeatingFuel(heatingFuelId);
    const consumptionFuelUnits = survey.existing_system_annual_consumption_kWh > 0 ? Math.round(survey.existing_system_annual_consumption_kWh / heatingFuel.kWh_per_unit) : '';
    const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity');
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Energy use" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "text-gray-900 font-bold", children: [heatingFuel.name, " annual consumption"] }), _jsx("div", { className: "text-gray-500 text-sm", children: "E.g. from a recent bill or from the smart meter in-home display" }), _jsx(Input, { step: 0.01, type: 'number', value: consumptionFuelUnits.toString(), setValue: (e) => setSurvey({
                                    ...survey,
                                    existing_system_annual_consumption_kWh: Number(e) * heatingFuel.kWh_per_unit
                                }), postfix: heatingFuel.unit })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "text-gray-900 font-bold", children: [heatingFuel.name, " tariff"] }), _jsx(Input, { step: 0.01, type: 'number', value: getHeatingPencePerUnit(heatingFuel, survey.existing_system_p_per_unit_override).toString(), setValue: (e) => setSurvey({ ...survey, existing_system_p_per_unit_override: Number(e), electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? parseFloat(e) : survey.electricity_p_per_kwh_override }), postfix: _jsxs("div", { className: 'flex items-center gap-2.5', children: [_jsx("div", { children: heatingFuel.penceUnit }), _jsx("div", { children: defaultTariffIsOverridden(heatingFuel, survey.existing_system_p_per_unit_override) && _jsx(Icon, { icon: XCircle, onClick: () => setSurvey(({ ...survey, existing_system_p_per_unit_override: undefined, electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? undefined : survey.electricity_p_per_kwh_override })), colour: 'text-gray-400' }) })] }) })] }), heatingFuelId !== 'electricity' && _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Electricity tariff" }), _jsx(Input, { step: 0.01, type: 'number', value: getElectricityPencePerKwh(survey.electricity_p_per_kwh_override).toString(), setValue: (e) => setSurvey({ ...survey, electricity_p_per_kwh_override: Number(e) }), postfix: _jsxs("div", { className: 'flex items-center gap-2.5', children: [_jsx("div", { children: electricity?.penceUnit }), _jsx("div", { children: defaultTariffIsOverridden(electricity, survey.electricity_p_per_kwh_override) &&
                                                _jsx(Icon, { icon: XCircle, onClick: () => setSurvey(({ ...survey, electricity_p_per_kwh_override: undefined })), colour: 'text-gray-400' }) })] }) })] })] })] });
};
