import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from '../buttons/button';
import { Input } from '../inputs_and_selections/input';
import { Check, Edit, Trash, X } from 'lucide-react';
import { Icon } from '../buttons/icon';
export const Table = ({ columns, rows, editable, addNew, onDeleteRow, onSaveRow, editableColumnWidth }) => {
    const newRowEmpty = Object.fromEntries(columns.map(x => ([x.key, ''])));
    const [editingRow, setEditingRow] = useState();
    const [newRow, setNewRow] = useState(newRowEmpty);
    const [addNewDisabled, setAddNewDisabled] = useState(true);
    const isRowValid = (row) => columns.every(c => !c.validator || c.validator(row));
    useEffect(() => {
        // check each column has a value
        const hasValue = columns.every(x => newRow[x.key] !== '');
        setAddNewDisabled(!hasValue);
    }, [newRow]);
    return _jsx("div", { className: "rounded overflow-auto", children: _jsxs("table", { className: "text-sm text-left text-gray-500 w-full shadow table-auto", children: [_jsx("thead", { className: "text-gray-700 bg-gray-50", children: _jsxs("tr", { children: [columns.map((x, i) => _jsx("th", { className: 'px-2 py-4', children: x.name }, i)), editable && _jsx("th", { style: { width: editableColumnWidth } })] }) }), _jsxs("tbody", { children: [rows.map((x, xi) => {
                            const isEditing = editingRow?.uuid === x.uuid;
                            return _jsxs("tr", { className: "bg-white border-b hover:bg-gray-50", children: [columns.map((y, yi) => _jsx("td", { className: "p-2 h-full", children: cell(y, isEditing ? editingRow : x, (e) => { setEditingRow((prev) => ({ ...prev, [y.key]: e })); }, isEditing) }, yi)), editable && _jsx("td", { children: _jsxs("div", { className: "flex items-center space-x-2 justify-end p-2 h-full", children: [!isEditing && _jsx(Button, { onClick: () => { setEditingRow({ ...x }); }, children: _jsx(Icon, { "data-cy": 'table_edit_button', colour: 'text-white', className: " w-3 h-3", icon: Edit }) }), !isEditing && onDeleteRow && _jsx(Button, { onClick: () => { onDeleteRow(x.uuid); }, children: _jsx(Icon, { "data-cy": 'table_delete_button', colour: 'text-white', className: "w-3 h-3", icon: Trash }) }), isEditing && _jsx(Button, { disabled: !isRowValid(editingRow), onClick: () => { onSaveRow?.(editingRow); setEditingRow(undefined); }, children: _jsx(Icon, { "data-cy": 'table_save_button', colour: 'text-white', className: "w-3 h-3", icon: Check }) }), isEditing && _jsx(Button, { onClick: () => { setEditingRow(undefined); }, children: _jsx(Icon, { className: "w-3 h-3", colour: 'text-white', icon: X }) })] }) })] }, xi);
                        }), addNew && newRow && _jsxs("tr", { children: [columns.map((x, i) => _jsx("td", { className: "p-2", children: cell(x, newRow, (e) => { setNewRow((prev) => ({ ...prev, [x.key]: e })); }, true) }, i)), _jsx("td", { children: _jsx("div", { className: "h-full flex items-center justify-end p-2", children: _jsx(Button, { disabled: addNewDisabled, onClick: () => { onSaveRow?.(newRow); setNewRow(newRowEmpty); }, children: "Add New" }) }) })] })] })] }) });
};
const cell = (column, row, onChange, isEditing) => {
    if (isEditing) {
        if (column.editRender)
            return column.editRender(row, onChange);
        if (column.key)
            return _jsx(Input, { setValue: (e) => { onChange(e); }, value: row[column.key]?.toString() ?? '' });
        return;
    }
    return column.render ? column.render(row) : row[column.key]?.toString();
};
