import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getHLReportPublicAPI, setHLOpenedStatusAPI } from '../code/models/heatloss_report';
import { HeatLossReportPage } from './heat_loss/proposal/heatloss_report_page';
import { Loader } from '../components/indicators_and_messaging/loader';
import { createJobEvent, JobEventType } from '../code/models/job_event';
export const HeatLossReportPublicPage = (props) => {
    const [report, setReport] = useState();
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    useEffect(() => {
        let timer;
        const asyncCall = async () => {
            const result = await getHLReportPublicAPI(props.reportUUID, props.companyPublicInfo.uuid);
            if (!result)
                return;
            setReport(result);
            if (!isInstallerClick && !result.is_opened) {
                // When we send this estimate to customers, their email clients are very likely to render/open (prefetch) the page, triggering the update to 'Opened' status
                // To avoid this, we wait for 3 seconds before sending the "Opened" status change, so be (pretty) certain that it's a human opening the page.
                timer = setTimeout(async () => {
                    await setHLOpenedStatusAPI(props.reportUUID, props.companyPublicInfo.uuid);
                    createJobEvent({
                        event_type: JobEventType.enum.ReportOpened,
                        company_uuid: props.companyPublicInfo.uuid,
                        job_uuid: result.lead_uuid,
                        extra_data: {
                            report_uuid: props.reportUUID
                        }
                    });
                }, 3000);
            }
        };
        asyncCall();
        return () => {
            if (timer)
                clearInterval(timer);
        };
    }, []);
    if (!report) {
        return (_jsx("div", { className: "flex flex-col items-center justify-center mt-10 mx-auto", children: _jsx(Loader, {}) }));
    }
    return _jsxs(_Fragment, { children: [_jsx("div", { className: 'print:bg-white bg-gray-100 p-0', children: _jsx("div", { className: 'bg-white print:max-w-none max-w-[260mm] print:m-0 m-auto pb-6 print:rounded-none rounded', children: _jsx(HeatLossReportPage, { reportUUID: props.reportUUID, sections: report.contents_bitmask, snapshot: report.snapshot, company_public_info: props.companyPublicInfo }) }) }), _jsx("div", { id: 'pdf-ready' })] });
};
