import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastInner } from '../../../components/indicators_and_messaging/toast';
import { noop } from 'lodash';
export const ToastDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(ToastInner, { setGlobalState: noop, toast: { message: 'Danger!', colour: 'bg-red-500' } })] })] });
};
const componentProps = [
    { name: 'setGlobalState', description: 'Toast state exists at top level, allows us to dismiss toast' },
    { name: 'toast', description: 'Message bundle we want to show' },
    { name: 'message', description: 'toast prop: actual message' },
    { name: 'colour', description: 'toast prop: bg-red-500 | bg-yellow-500 | bg-blue-500 | bg-green-500' }
];
const codeBlock = `<ToastInner 
  setGlobalState={noop} 
  toast={{ message: 'Danger!', colour: 'bg-red-500' }} 
/>`;
