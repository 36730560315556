import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { HLRTitlePage } from './hlr_title_page';
import { HLRSummaryPage } from './hlr_summary_page';
import { HLRIntroductionPage } from './hlr_introduction_page';
import { HLRCalculationConditionsPage } from './hlr_calculation_conditions_page';
import { HLRHeatlossByElementPage } from './hlr_heatloss_by_element_page';
import { HLRHeatlossByRoomPage } from './hlr_heatloss_by_room_page';
import { HLRFloorHeatlossPage } from './hlr_floor_heatloss_page';
import { HLRRoomHeatlossPage } from './hlr_room_heatloss_page';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getHotWaterCalculationRowLegionella, getHotWaterCalculationRowNormalOperation, getNumberOfOccupants } from '../../../code/models/hot_water_cylinder';
import { eligibleForHeatPumpPlus, getPerformanceEstimateSummary } from '../../../code/models/performance_estimate';
import { HLRHeatPump } from './hlr_heat_pump';
import { HLREmittersIntro } from './hlr_emitters_intro';
import { HLREmittersCurrent } from './hlr_emitters_current';
import { HLREmittersProposed } from './hlr_emitters_proposed';
import { HLRHotWater } from './hlr_hot_water';
import { HLRHotWaterDemand } from './hlr_hot_water_demand';
import { HLRSoundAssessment } from './hlr_sound_assessment';
import { HLRPESummary } from './hlr_pe_summary';
import { HLRPEDetailedResultsIntroduction } from './hlr_pe_detailed_results_intro';
import { HLRPEResultsTable } from './hlr_pe_results_table';
import { HLRPEInputsAndAssumptions } from './hlr_pe_inputs_and_assumptions';
import { HLRPESystemEfficiency1 } from './hlr_pe_system_efficiency_1';
import { HLRPESystemEfficiency2 } from './hlr_pe_system_efficiency_2';
import { HLRPEFlowTempChart } from './hlr_pe_flow_temp_chart';
import { HLRPEMCSKeyFacts } from './hlr_pe_mcs_key_facts';
import { getFullEmittersListByStatus } from '../../../code/models/radiator';
import { buildHLReportPDFURL, HEATLOSS_REPORT_SECTION_DESIGN, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT, HEATLOSS_REPORT_SECTION_SUMMARY } from '../../../code/models/heatloss_report';
import { downloadBlobByURL, isFlagSet } from '../../../code/helpers';
import { convertLatLngListToLatLongLiteral } from '../../../code/geocoding';
import { getDesignConditions } from '../../../code/models/design_temp';
import { getHeatTransferCoefficientWattsPerKelvin, getRoomWatts } from '../../../code/models/heat_loss';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../../code/models/range_heat_pump';
import { getSoundAssessmentData } from '../../../code/sound_assessment';
import { getAreaM2 } from '../floor/code/utils';
import { PrintLayout, PrintLayoutPageBreak } from '../../components/print_layout';
import { Button } from '../../../components/buttons/button';
import { FileText } from 'lucide-react';
export const HLRContext = React.createContext(undefined);
export const HeatLossReportPage = (props) => {
    const survey = props.snapshot.survey;
    const calculatedData = hlrMakeCalcsFromSnapshot(props.snapshot);
    // If ONLY sound assessment don't show header and contents
    const emittersDefined = getFullEmittersListByStatus(survey, calculatedData.design).length > 0;
    const logoImageData = props.company_public_info.logo;
    return (_jsx(HLRContext.Provider, { value: {
            survey,
            property: props.snapshot.property,
            customer: props.snapshot.customer,
            company_public_info: props.company_public_info,
            design: calculatedData.design,
            designTempC: calculatedData.designTempC,
            groundTempC: calculatedData.groundTempC,
            degreeDays: calculatedData.degreeDays,
            currentHeatPump: props.snapshot.currentHeatPump,
            currentHotWaterCylinder: props.snapshot.currentHotWaterCylinder,
            cylinderReheatRow: calculatedData.cylinderReheatRow,
            hotWaterRowNormal: calculatedData.hotWaterRowNormal,
            hotWaterRowLegionella: calculatedData.hotWaterRowLegionella,
            performanceEstimateColumns: calculatedData.performanceEstimateColumns,
            performanceEstimateSummary: calculatedData.performanceEstimateSummary,
            eligibleForHeatPumpPlus: calculatedData.eligibleForHeatPumpPlus,
            soundAssessment: calculatedData.soundAssessment,
            files: props.snapshot.files,
            summaryData: calculatedData.summaryData,
            hpCapacityResult: calculatedData.hpCapacityResult,
            scop: calculatedData.scop
        }, children: _jsx(PrintLayout
        /*
          Carefully estimate this number based on the header's real height.
          This number is used to offset content below the print-version header.
        */
        , { 
            /*
              Carefully estimate this number based on the header's real height.
              This number is used to offset content below the print-version header.
            */
            headerHeightPx: 80, header: _jsxs("div", { className: [
                    'w-full bg-white border-b border-b-gray-200 justify-between items-center gap-6 flex pt-3 pb-1 px-1 top-0 ',
                    'print:px-0 print:pt-0 print:pb-6 ', // print
                    'sm:px-3 sm:pb-3 ', // sm
                    'md:px-6 md:pb-3 ' // md
                ].join(' '), children: [_jsx("div", { className: 'flex flex-row gap-4', children: logoImageData && _jsx("img", { src: logoImageData, className: "max-h-10 md:max-h-12", alt: "Installer logo" }) }), _jsx("div", { className: 'flex flex-row gap-4 print:hidden', children: props.reportUUID && _jsx(Button, { colour: 'LIGHT', iconLeft: FileText, onClick: async () => {
                                downloadBlobByURL(buildHLReportPDFURL(props.reportUUID, props.company_public_info.uuid), props.snapshot.property.address + ' — Heat Loss Report.pdf');
                            }, children: _jsx("span", { className: 'hidden sm:inline', children: "Save to PDF" }) }) })] }), 
            // The gap is used to make space between pages
            content: _jsxs("div", { className: 'print:px-0 sm:px-0 md:px-6 flex flex-col print:gap-0 gap-8', children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRTitlePage, {})] }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SUMMARY) && _jsx(_Fragment, { children: _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRSummaryPage, {})] }) }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY) && _jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRIntroductionPage, { hasIntroduction: isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO) })] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRCalculationConditionsPage, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRHeatlossByElementPage, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRHeatlossByRoomPage, {})] }), survey.floors.map((floor, floorIdx) => {
                                // skip floors without rooms
                                if (floor.rooms.length === 0)
                                    return null;
                                return (_jsxs(Fragment, { children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRFloorHeatlossPage, { floor: floor })] }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM) &&
                                            floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                                                .map((room, roomIdx) => {
                                                return _jsx(Fragment, { children: _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRRoomHeatlossPage, { floor: floor, room: room })] }) }, 'floor-' + floorIdx + '-room-' + roomIdx);
                                            })] }, 'floor-' + floorIdx));
                            })] }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_DESIGN) && _jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRHeatPump, {})] }), emittersDefined && _jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLREmittersIntro, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLREmittersCurrent, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLREmittersProposed, {})] })] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRHotWater, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRHotWaterDemand, {})] })] }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT) && _jsx(_Fragment, { children: _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRSoundAssessment, {})] }) }), isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE) && _jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPESummary, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPEDetailedResultsIntroduction, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPEResultsTable, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPEInputsAndAssumptions, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPESystemEfficiency1, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPESystemEfficiency2, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPEFlowTempChart, {})] }), _jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), _jsx(HLRPEMCSKeyFacts, {})] })] })] }) }) }));
};
// the main function to calculate the report data from the snapshot
// the function could not be in a .ts file because it uses React FCs to render some views
export const hlrMakeCalcsFromSnapshot = (snapshot) => {
    const design = snapshot.survey.designs[0];
    const latLng = convertLatLngListToLatLongLiteral(snapshot.property.postcodeLocation);
    const altitudetoUseM = snapshot.survey.altitude_override_m ?? snapshot.property.altitudeM;
    const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, snapshot.survey.exposed_location); // done separately as needed in survey_settings
    const designTempC = snapshot.survey.design_temp_override_c ?? designTempDefaultC;
    const cylinderReheatRow = getCylinderReheatCalculationRow(snapshot.currentHotWaterCylinder, design.hot_water_storage_temperature_c, snapshot.currentHeatPump?.range_heat_pump, designTempC);
    const numberOfOccupants = getNumberOfOccupants(snapshot.survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, snapshot.survey.volume_per_person_l);
    const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(snapshot.currentHeatPump?.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c);
    const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, snapshot.currentHotWaterCylinder?.litres ?? 0, snapshot.survey.legionnaires_cycle_weeks);
    const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(snapshot.survey, designTempC, groundTempC);
    const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear;
    const scopSpaceHeating = getHeatPumpScopAtFlowTemp(snapshot.currentHeatPump?.range_heat_pump, design.flow_temp);
    const eligibleForHeatPumpPlusResult = eligibleForHeatPumpPlus(snapshot.currentHeatPump);
    const performanceEstimateSummary = getPerformanceEstimateSummary(snapshot.survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, hotWaterRowNormal.heatingEfficiency, scopSpaceHeating, eligibleForHeatPumpPlusResult);
    const performanceEstimateColumns = [
        {
            key: 'name',
            name: 'Name',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.name })
                        : _jsx("div", { children: row.name })] })
        },
        {
            key: 'kwh',
            name: 'Energy',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.kwh })
                        : _jsx("div", { children: row.kwh })] })
        },
        {
            key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
            name: 'Bills',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.costUserEnteredTariff })
                        : _jsx("div", { children: row.costUserEnteredTariff })] })
        },
        {
            key: 'emissionsKG',
            name: 'Emissions',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.emissionsKG })
                        : _jsx("div", { children: row.emissionsKG })] })
        }
    ];
    const soundAssessmentData = getSoundAssessmentData(snapshot.survey.sound_barrier_uuid, snapshot.survey.reflective_surfaces, snapshot.survey.sound_distance, snapshot.currentHeatPump?.range_heat_pump?.sound_power_max_dba);
    const soundAssessment = snapshot.currentHeatPump ? soundAssessmentData?.finalResultDba ?? 0 : 0;
    const summaryData = {
        floorArea: 0,
        heatLoss: 0,
        radiatorsNew: 0,
        radiatorsReplaced: 0,
        ufhNew: 0,
        emittersRemoved: 0,
        emittersUnchanged: 0
    };
    snapshot.survey.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            const roomHeatLoss = getRoomWatts(room, floor.rooms, designTempC, groundTempC, snapshot.survey);
            const roomArea = getAreaM2(room.walls.map(w => ({ x: w.x, y: w.y })));
            summaryData.heatLoss += roomHeatLoss;
            summaryData.floorArea += roomArea;
        });
    });
    const emitters = getFullEmittersListByStatus(snapshot.survey, design);
    emitters.forEach(emitter => {
        if (emitter.status === 'ADDED') {
            if (emitter.radiator.emitter_type === 'UNDERFLOOR') {
                summaryData.ufhNew++;
            }
            else {
                summaryData.radiatorsNew++;
            }
        }
        else if (emitter.status === 'REPLACED') {
            // Currently cannot "Replace" underfloor heating so only need to worry about radiators here
            summaryData.radiatorsReplaced++;
        }
        else if (emitter.status === 'REMOVED') {
            summaryData.emittersRemoved++;
        }
        else if (emitter.status === 'UNCHANGED') {
            summaryData.emittersUnchanged++;
        }
    });
    const hpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(snapshot.currentHeatPump.range_heat_pump, designTempC, design.flow_temp);
    const scop = getHeatPumpScopAtFlowTemp(snapshot.currentHeatPump?.range_heat_pump, design.flow_temp);
    return {
        design: snapshot.survey.designs[0],
        designTempC,
        groundTempC,
        degreeDays,
        cylinderReheatRow,
        hotWaterRowNormal,
        hotWaterRowLegionella,
        performanceEstimateSummary,
        performanceEstimateColumns,
        eligibleForHeatPumpPlus: eligibleForHeatPumpPlusResult,
        soundAssessment,
        summaryData,
        hpCapacityResult,
        scop
    };
};
