import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const colours = {
    DARK: 'text-gray-900',
    LIGHT: 'text-gray-300'
};
export const ListItem = ({ onClick, topBadge, leftIcon, primaryText, primaryTextColour, secondaryText, rightClickableIcon, rightBadge, actionButton, figure }) => {
    const allClickable = !actionButton && !rightClickableIcon && onClick; // if no clickable content on right - make all clickable
    const onlyMiddleClickable = (actionButton || rightClickableIcon) && onClick; // if no clickable content at all - make only middle clickable
    return _jsxs("div", { className: `p-4 justify-between items-center gap-4 flex first:border-none border-t border-gray-200 ${allClickable ? 'cursor-pointer' : ''}`, onClick: allClickable ? onClick : undefined, children: [leftIcon && _jsx("div", { className: "p-2 bg-gray-100 rounded-xl gap-2.5 flex items-center justify-center w-10 h-10", children: leftIcon }), _jsxs("div", { className: `flex flex-col flex-grow ${onlyMiddleClickable ? 'cursor-pointer' : ''}`, onClick: !allClickable ? onClick : undefined, children: [topBadge && _jsx("div", { children: topBadge }), _jsx("div", { className: `${primaryTextColour ? colours[primaryTextColour] : colours.DARK} text-base font-bold`, children: primaryText }), secondaryText && _jsx("div", { className: " text-gray-500 text-xs tracking-tight", children: secondaryText })] }), _jsxs("div", { className: 'flex gap-4 items-center', children: [rightBadge && _jsx("div", { className: onlyMiddleClickable ? 'cursor-pointer' : '', onClick: onlyMiddleClickable ? onClick : undefined, children: rightBadge }), actionButton && _jsx("div", { children: actionButton }), figure && _jsx("div", { children: figure }), rightClickableIcon && _jsx("div", { children: rightClickableIcon })] })] });
};
