import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { Input } from '../../components/inputs_and_selections/input';
import { tryParseFloat } from '../../code/helpers';
import { Button } from '../../components/buttons/button';
export const SecondaryHeatingPage = ({ secondaryHeating, setSecondaryHeating, onSave }) => {
    if (!secondaryHeating)
        return;
    return _jsxs("div", { className: "p-5 bg-white flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "text-gray-900 text-base font-bold", children: "Name of heat source" }), _jsx(Input, { value: secondaryHeating.name, setValue: (e) => setSecondaryHeating(prev => ({ ...prev, name: e })) })] }), _jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "text-gray-900 text-base font-bold", children: "Estimated % of room's heating load covered:" }), _jsx(Input, { postfix: '%', value: secondaryHeating.room_heating_load_covered_percentage ?? '', setValue: (e) => setSecondaryHeating(prev => ({ ...prev, room_heating_load_covered_percentage: tryParseFloat(e, undefined) })) })] }), _jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "text-gray-900 text-base font-bold", children: "Notes" }), _jsx(TextArea, { value: secondaryHeating.notes, setValue: (e) => setSecondaryHeating(prev => ({ ...prev, notes: e })) })] }), _jsx(Button, { disabled: !secondaryHeating.name || !secondaryHeating.room_heating_load_covered_percentage, onClick: onSave, children: "Apply" })] });
};
