import { jsx as _jsx } from "react/jsx-runtime";
export const Caption = ({ content, className = '', size = 'md', uppercase = false }) => {
    let textSize = '';
    switch (size) {
        case 'sm':
            textSize = 'text-sm';
            break;
        case 'md':
            textSize = ' ';
            break;
        case 'lg':
            textSize = 'text-lg ';
            break;
    }
    return _jsx("div", { className: `${className} ${textSize} ${uppercase ? 'uppercase' : ''} text-gray-600`, children: content });
};
