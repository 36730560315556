import { getNumberOfBathrooms, getNumberOfBedrooms } from '../calculate_estimate';
export const getNumberOfBedroomsFromHabitableRooms = (habitableRooms) => {
    // NPH: Technically there could be a studio flat with no bedrooms, but it's extremely unlikely that someone with a studio flat will be getting a heat pump
    // NPH: the -2 intuitively makes sense: the number of bedrooms is probably the number of habitable rooms minus the kitchen and living room. Of course there could also be a study or other rooms but it's a good approximation
    // If no EPC, default to 3 bedrooms as seems a likely average for houses getting heat pumps
    const DEFAULT_NO_BEDROOMS = 3;
    if (!habitableRooms)
        return DEFAULT_NO_BEDROOMS;
    if (isNaN(habitableRooms))
        return DEFAULT_NO_BEDROOMS;
    if (habitableRooms < 3)
        return 1;
    if (habitableRooms > 7)
        return 5; // larger homes will normally have more than 2 non-bedroom rooms
    return habitableRooms - 2;
};
export const getNumberofBathroomsFromBedrooms = (noBedrooms) => {
    // SPR-44: An example which has no basis in real data, but feels right is: up to 3 bedrooms = 1 bathroom, 4-5 bedrooms = 2 bathrooms, 6-8 bedrooms = 3 bathrooms
    // NPH: we're estimating too many bathrooms for homes at the moment, so I checked the code and the bathroom estimation is being based the parsedEPCNoBedrooms (the number of habitable rooms) when it should be based on noBedrooms
    return (noBedrooms <= 3) ? 1 : ([4, 5].includes(noBedrooms) ? 2 : 3);
};
// percentageOfRadiatorsToChange is a number between 0 and 100
export const getApproxNumberOfRadiatorChanges = (lead, percentageOfRadiatorsToChange) => {
    // Assumption - habitable rooms + bathrooms = rooms to heat.
    const habitableRooms = getNumberOfHabitableRoomsFromLead(lead);
    const roomsToHeat = habitableRooms + getNumberOfBathrooms(lead);
    return Math.ceil(roomsToHeat * (percentageOfRadiatorsToChange / 100));
};
// From RdSAP: 10.1.1 Habitable room count: "Habitable rooms include any living room, sitting room, dining room, kitchen/diner, bedroom, study and similar; and also a non-separated conservatory."
// https://files.bregroup.com/SAP/RdSAP10-dt13.02.2024.pdf
export const getNumberOfHabitableRoomsFromLead = (lead) => {
    if (lead.epcData?.numberHabitableRooms)
        return parseInt(lead.epcData.numberHabitableRooms);
    if (lead.epc_scotland?.number_habitable_rooms)
        return lead.epc_scotland.number_habitable_rooms;
    const numberOfBedrooms = getNumberOfBedrooms(lead);
    return numberOfBedrooms + 2;
};
