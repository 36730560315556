import { jsx as _jsx } from "react/jsx-runtime";
export const SubHeader = ({ content, className = '', size = 'lg' }) => {
    let sigingClasses = '';
    switch (size) {
        case 'sm':
            sigingClasses = 'text-sm ';
            break;
        case 'md':
            sigingClasses = ' ';
            break;
        case 'lg':
            sigingClasses = 'text-xl ';
            break;
    }
    return _jsx("div", { className: `${className} ${sigingClasses} text-gray-900 font-bold `, children: content });
};
