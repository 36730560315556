import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { ClickableCard } from '../../../../components/content_display/card';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const CylinderLocationHasExistingHWCBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    return _jsx(_Fragment, { children: _jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: "items-center gap-3 flex justify-between", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Existing cylinder" }), _jsx("div", { className: "text-gray-500 text-sm", children: "Property has an existing hot water cylinder" })] }), _jsx(Toggle, { value: survey.has_existing_cylinder, setValue: () => setSurvey({ ...survey, has_existing_cylinder: !survey.has_existing_cylinder }) })] }) }) });
};
