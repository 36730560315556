import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SettingsLayout } from './components/settings_layout';
import { Section } from '../../../components/containers/section';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Input } from '../../../components/inputs_and_selections/input';
import { validateEmail } from '../../../code/validators';
import { Text } from '../../../components/content_display/text';
import { Info } from '../../../components/buttons/info';
export const PersonalAccount = ({ user, setUser }) => {
    return _jsx(SettingsLayout, { heading: 'Personal details', description: 'Manage your Spruce account', children: _jsx(Section, { border: true, title: "Personal details", children: _jsxs("div", { className: 'grid grid-cols-1 gap-4', children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'First name' }), input: _jsx(Input, { value: user.first_name || '', setValue: (value) => setUser({
                                ...user,
                                first_name: value
                            }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Last name' }), input: _jsx(Input, { value: user.last_name || '', setValue: (value) => setUser({
                                ...user,
                                last_name: value
                            }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Email address', info: _jsx(Info, { infoModalHeader: 'Email address', infoModalBody: _jsxs(Text, { size: "MD", children: ["Please get in touch with Spruce support at ", _jsx("a", { href: "mailto:support@spruce.eco", className: 'font-bold text-sky-700 underline', children: "support@spruce.eco" }), " if you need to change your login email address."] }) }) }), input: _jsx(Input, { disabled: true, value: user.email, setValue: (value) => {
                                if (validateEmail(value).value) {
                                    setUser({
                                        ...user,
                                        email: value
                                    });
                                }
                            }, validator: validateEmail, validateImmediately: true }) })] }) }) });
};
