import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/buttons/button';
import { DoorPage } from './door';
import { WindowPage } from './window';
import { Input } from '../../components/inputs_and_selections/input';
import { MATERIAL_ELEMENT_NAMES } from '../../code/models/material';
import { TabGroup } from '../../components/content_display/tab';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { ListItem } from '../../components/content_display/list_item';
import { ClickableCard } from '../../components/content_display/card';
import { DEFAULT_SURVEY_DOOR, DEFAULT_SURVEY_WINDOW } from '../../code/survey_defaults';
import { MaterialInputField } from './materials/material_input_field';
import { MERGE_ROOM } from '../../assets/images/domain_icons/merge_room';
import { JOIN_ROOM } from '../../assets/images/domain_icons/join_room';
import { validateWallLength } from '../../code/validators';
import { Icon } from '../../components/buttons/icon';
import { BottomSheetHeader } from '../../components/containers/bottom_sheet_header';
import { areLinesSame, calculateLineLength, calculateNewTouchingWalls, getNextWall, mergeRooms, wallToLine } from './floor/code/utils';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { getOtherSideTempWall } from '../../code/models/heat_loss';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { Info } from '../../components/buttons/info';
import { orderBy } from 'lodash';
import { Trash, Plus, XCircle } from 'lucide-react';
import { WrappedIcon } from '../../components/buttons/wrapped_icon';
export const WallPage = ({ currentRoom, floor, setFloor, wall, setWall, onSave, survey, page, setPage, linkedWall, materials, setMsProps, addEvent, defaultMaterials, designTempC, setHeader, window, setWindow, door, setDoor, setScalingWindow, setScalingDoor, setStageStep, onBack }) => {
    const [length, setLength] = useState('');
    const inputRef = useRef(null);
    if (!wall || !linkedWall)
        return;
    useEffect(() => {
        if (page === 'REMOVE_WALL')
            setHeader(_jsx(BottomSheetHeader, { title: 'Remove wall', goBack: onBack }));
        else if (page === 'WINDOW_PAGE')
            setHeader(_jsx(BottomSheetHeader, { goBack: onBack, onBack: () => setWindow(undefined), title: 'Window' }));
        else if (page === 'DOOR_PAGE')
            setHeader(_jsx(BottomSheetHeader, { goBack: onBack, onBack: () => setDoor(undefined), title: 'External door' }));
        else
            setHeader(_jsx(BottomSheetHeader, { title: MATERIAL_ELEMENT_NAMES[wall.material.applicable_to] ?? 'Wall', goBack: onBack }));
    }, [page]);
    const allWalls = survey.floors.flatMap(x => x.rooms.flatMap(y => y.walls));
    const lastEditedWindow = orderBy(allWalls.flatMap(x => x.windows), x => x.updated_at, 'desc')[0];
    const lastEditedDoor = orderBy(allWalls.flatMap(x => x.doors), x => x.updated_at, 'desc')[0];
    const NEW_WINDOW = { ...(lastEditedWindow ?? { ...DEFAULT_SURVEY_WINDOW, material: survey.default_materials.window }), uuid: undefined };
    const NEW_DOOR = { ...(lastEditedDoor ?? { ...DEFAULT_SURVEY_DOOR, material: survey.default_materials.door }), uuid: undefined };
    const otherRoom = floor.rooms.find(x => x.uuid === wall.other_room_uuid);
    const handleMergeRooms = () => {
        const merged = mergeRooms(currentRoom, otherRoom);
        const deletedRoom = merged?.uuid === currentRoom.uuid ? otherRoom : currentRoom;
        if (!merged)
            return;
        const newRooms = [...floor.rooms.filter(x => ![currentRoom.uuid, otherRoom.uuid].includes(x.uuid)), merged];
        const withoutOtherRoomUUID = newRooms.map(x => ({ ...x, walls: x.walls.map(y => ({ ...y, other_room_uuid: y.other_room_uuid === deletedRoom.uuid ? '' : y.other_room_uuid })) }));
        const newTouchingWalls = calculateNewTouchingWalls(withoutOtherRoomUUID, defaultMaterials);
        addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: newTouchingWalls } }]);
    };
    if (page === 'WINDOW_PAGE' && window) {
        return _jsx(WindowPage, { floor: floor, setScalingWindow: setScalingWindow, wallWindow: window, setWindow: setWindow, onSave: () => {
                setWall({ ...wall, windows: window.uuid ? wall.windows.map(x => x.uuid === window.uuid ? window : x) : [...wall.windows, { ...window, uuid: crypto.randomUUID() }] });
            }, survey: survey, materials: materials, setMsProps: setMsProps, setPage: setPage, onBack: onBack, setStageStep: setStageStep });
    }
    if (page === 'DOOR_PAGE' && door) {
        return _jsx(DoorPage, { floor: floor, door: door, setDoor: setDoor, setScalingDoor: setScalingDoor, onSave: () => {
                setWall({ ...wall, doors: door.uuid ? wall.doors.map(x => x.uuid === door.uuid ? door : x) : [...wall.doors, { ...door, uuid: crypto.randomUUID() }] });
            }, survey: survey, materials: materials, setMsProps: setMsProps, setPage: setPage, onBack: onBack });
    }
    if (page === 'REMOVE_WALL') {
        return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: 'flex flex-col divide-y divide-gray-200', children: [_jsx(ListItem, { leftIcon: MERGE_ROOM, primaryText: 'Merge as a single room', onClick: () => {
                            handleMergeRooms();
                            onBack();
                        } }), _jsx(ListItem, { leftIcon: JOIN_ROOM, primaryText: 'Remove wall and create a split room', onClick: () => {
                            const roomGroupUUID = currentRoom.room_group_uuid ?? otherRoom?.room_group_uuid ?? crypto.randomUUID();
                            const newRoom = { ...currentRoom, room_group_uuid: roomGroupUUID };
                            const newOtherRoom = { ...otherRoom, room_group_uuid: roomGroupUUID };
                            addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: floor.rooms.map(x => x.uuid === newRoom.uuid ? newRoom : x.uuid === newOtherRoom.uuid ? newOtherRoom : x) } }]);
                            onBack();
                        } })] }) });
    }
    const removeWindow = (id) => setWall({ ...wall, windows: wall.windows.filter(x => x.uuid !== id) });
    const removeDoor = (id) => setWall({ ...wall, doors: wall.doors.filter(x => x.uuid !== id) });
    const calculatedLength = calculateLineLength(wall.x, wall.y, linkedWall.x, linkedWall.y).toString();
    return _jsxs("div", { className: 'flex flex-col', children: [_jsx("div", { className: 'flex flex-col gap-2', children: _jsx("div", { className: 'px-4 pt-4', children: _jsx(TabGroup, { items: [
                            { name: 'Length', onClick: () => setPage('WALL_LENGTH'), variant: page === 'WALL_LENGTH' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Materials', onClick: () => setPage('WALL_MATERIALS'), variant: page === 'WALL_MATERIALS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Windows', onClick: () => setPage('WALL_WINDOWS'), variant: page === 'WALL_WINDOWS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Doors', onClick: () => setPage('WALL_DOORS'), variant: page === 'WALL_DOORS' ? 'ACTIVE' : 'DEFAULT' }
                        ] }) }) }), _jsxs("div", { className: 'p-5 flex flex-col gap-5', children: [page === 'WALL_LENGTH' && _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx(FormLabel, { labelText: 'Length', size: 'SM' }), _jsxs("div", { className: 'flex gap-2 flex-grow', children: [_jsx(Input, { validator: validateWallLength, className: 'flex-grow', ref: inputRef, step: 0.1, type: 'number', placeholder: calculatedLength, value: length, setValue: setLength, postfix: 'm' }), _jsx(Button, { disabled: !validateWallLength(length).value, onClick: () => {
                                                onSave({ ...wall }, Number(length));
                                                onBack();
                                            }, children: "Apply" })] })] }) }), page === 'WALL_MATERIALS' && _jsxs(_Fragment, { children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Wall type', size: 'SM' }), input: _jsx(RadioGroup, { items: [
                                        { name: 'External', onClick: () => setWall(({ ...wall, material: survey.default_materials.externalWall })), variant: wall.material.applicable_to === 'external-wall' ? 'ACTIVE' : 'DEFAULT' },
                                        { name: 'Party', onClick: () => setWall(({ ...wall, material: survey.default_materials.partyWall })), variant: wall.material.applicable_to === 'party-wall' ? 'ACTIVE' : 'DEFAULT' },
                                        { name: 'Internal', onClick: () => setWall(({ ...wall, material: survey.default_materials.internalWall })), variant: wall.material.applicable_to === 'internal-wall' ? 'ACTIVE' : 'DEFAULT' }
                                    ] }) }), wall.other_room_uuid === undefined && wall.material?.applicable_to === 'internal-wall' && UnsnappedWallAlert, _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: "Wall material", size: 'SM' }), input: _jsx(MaterialInputField, { selectorProps: {
                                        materials,
                                        title: wall.material.applicable_to === 'external-wall' ? 'External wall' : wall.material.applicable_to === 'party-wall' ? 'Party wall' : 'Internal wall',
                                        surfaceType: wall.material.applicable_to,
                                        selectedMaterial: wall.material,
                                        ageBand: survey.age_band,
                                        onSelectCallback: (material) => {
                                            // transform selected wall to absolute coordinates Line
                                            const currentWallAsLine = wallToLine(wall, getNextWall(wall, currentRoom.walls), currentRoom);
                                            const updatedRooms = floor.rooms.map(r => {
                                                return {
                                                    ...r,
                                                    walls: r.walls.map(w => {
                                                        // if this is the wall we've updated the material for
                                                        if (w.uuid === wall.uuid) {
                                                            // update the material
                                                            return { ...w, material };
                                                        }
                                                        // if it's a wall in the opposite room
                                                        if (r.uuid === wall.other_room_uuid) {
                                                            // transform the w in the cycle to absolute coordinates Line
                                                            const wAsLine = wallToLine(w, getNextWall(w, r.walls), r);
                                                            // if the wall is the wall on the other side of the wall
                                                            // sounds confusing, but it's the wall that is on the other side of the wall :)
                                                            if (areLinesSame(currentWallAsLine, wAsLine)) {
                                                                return { ...w, material };
                                                            }
                                                        }
                                                        // in all other cases, return the wall as is
                                                        return { ...w };
                                                    })
                                                };
                                            });
                                            setFloor({ ...floor, rooms: updatedRooms });
                                        }
                                    }, setMsProps: setMsProps, setPage: setPage }) }), _jsx(OtherSideTempInput, { otherSideTemp: getOtherSideTempWall(wall, floor.rooms, designTempC, survey), override: wall.other_side_temp_override_c, setOverride: (e) => setWall({ ...wall, other_side_temp_override_c: e }), info: _jsx(Info, { infoModalHeader: 'Temperature on the other side of the wall', infoModalBody: 'This defaults to the design outdoor temperature for external walls, the temperature in the adjoining room for internal walls, and 10°C for party walls.' }) })] }), page === 'WALL_WINDOWS' && _jsxs("div", { className: 'flex flex-col gap-2', children: [wall.windows.length === 0 && _jsx(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => {
                                    setWindow({ ...NEW_WINDOW, created_at: new Date().getTime(), updated_at: new Date().getTime() });
                                    setPage('WINDOW_PAGE');
                                }, children: _jsx("div", { className: 'text-center', children: "No windows found" }) }), _jsx("div", { className: 'divide-y divide-y-gray-200', children: wall.windows.map((x, i) => _jsx(ListItem, { primaryText: x.material.name, secondaryText: `${x.width_mm} x ${x.height_mm} mm`, onClick: () => {
                                        setWindow(x);
                                        setPage('WINDOW_PAGE');
                                    }, rightClickableIcon: _jsx(Icon, { icon: Trash, confirmTextHeader: 'Delete window?', onClick: () => { removeWindow(x.uuid); } }) }, x.uuid)) }), _jsxs(Button, { onClick: () => {
                                    setWindow(({ ...NEW_WINDOW, created_at: new Date().getTime(), updated_at: new Date().getTime() }));
                                    setPage('WINDOW_PAGE');
                                }, block: true, colour: 'DARK', className: 'gap-2', children: [_jsx(WrappedIcon, { icon: Plus }), _jsx("div", { children: "Add window" })] })] }), page === 'WALL_DOORS' && _jsxs("div", { className: 'flex flex-col gap-2', children: [wall.doors.length === 0 && _jsx(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => {
                                    setDoor({ ...NEW_DOOR, created_at: new Date().getTime(), updated_at: new Date().getTime() });
                                    setPage('DOOR_PAGE');
                                }, children: _jsx("div", { className: 'text-center', children: "No doors found" }) }), _jsx("div", { className: 'divide-y divide-y-gray-200', children: wall.doors.map((x, i) => _jsx(ListItem, { primaryText: x.material.name, secondaryText: `${x.width_mm} x ${x.height_mm} mm`, onClick: () => {
                                        setDoor(x);
                                        setPage('DOOR_PAGE');
                                    }, rightClickableIcon: _jsx(Icon, { icon: Trash, confirmTextHeader: 'Delete door?', onClick: () => { removeDoor(x.uuid); } }) }, x.uuid)) }), _jsxs(Button, { onClick: () => {
                                    setDoor({ ...NEW_DOOR, created_at: new Date().getTime(), updated_at: new Date().getTime() });
                                    setPage('DOOR_PAGE');
                                }, block: true, colour: 'DARK', className: 'gap-2', children: [_jsx(WrappedIcon, { icon: Plus }), _jsx("div", { children: "Add door" })] })] })] })] });
};
export const OtherSideTempInput = ({ otherSideTemp, override, setOverride, info }) => {
    return _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: "Other side temperature", helperText: 'Only edit if you want to override the automatically calculated value', size: 'SM', info: info }), input: _jsx(Input, { type: 'number', step: 1, value: otherSideTemp, setValue: (e) => setOverride(parseFloat(e)), postfix: _jsxs("div", { className: 'flex items-center gap-2.5', children: [_jsx("div", { children: "\u00B0C" }), _jsxs("div", { children: [override && _jsx(Icon, { icon: XCircle, onClick: () => setOverride(undefined), colour: 'text-gray-400' }), " "] })] }) }) });
};
export const UnsnappedWallAlert = _jsxs(Alert, { type: 'DANGER', children: [_jsx("div", { className: 'font-bold', children: "Unsnapped internal wall" }), "A wall is marked as internal but is not touching another room. Snap this wall to its neighbouring room to fix the heat loss calculation. "] });
