import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash';
import { Section } from '../../../components/containers/section';
export const SectionDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Section, { title: 'Section title', className: '', border: true, padding: true, onClick: noop, children: "Hello world" })] })] });
};
const componentProps = [
    { name: 'title', description: 'Title of the section' },
    { name: 'className', description: 'Any additional styling or positioning required' },
    { name: 'border', description: 'If the section has an outer border' },
    { name: 'padding', description: 'If the section should include any padding' },
    { name: 'onClick', description: 'Show right chevron and cursor-pointer if populated, complete some action' }
];
const codeBlock = `<Section
  title='Section title'
  className=''
  border={true}
  padding={true}
  onClick={noop}
>Hello world</Section>`;
