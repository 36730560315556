import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { getFloorAndCeilingDefaultMaterialsForFloor } from '../../code/models/floor';
import { ROOM_TYPES } from './constants';
import { DEFAULT_SURVEY_ROOM, DEFAULT_SURVEY_WALL } from '../../code/survey_defaults';
import { validateWallLength } from '../../code/validators';
import { Select } from '../../components/inputs_and_selections/select';
import { BottomSheetHeader } from '../../components/containers/bottom_sheet_header';
export const getRoomPostfix = (roomTypeId, survey) => {
    if (roomTypeId !== 'bedroom')
        return ''; // only do this for bedrooms for now
    const roomTypeCount = survey.floors.flatMap(x => x.rooms).filter(x => x.room_type_uuid === roomTypeId).length;
    const roomPostfix = ` ${roomTypeCount + 1}`;
    return roomPostfix;
};
export const AddRoomTab = ({ floor, setFloor, setPage, onBack, startingX, startingY, survey, addEvent, setCurrentRoomId, setHeader, setPageOpen }) => {
    const [roomTypeId, setRoomTypeId] = useState('');
    const [roomName, setRoomName] = useState('');
    const [width, setWidth] = useState();
    const [length, setLength] = useState();
    useEffect(() => {
        setHeader(_jsx(BottomSheetHeader, { onBack: onBack, title: 'Add a new room' }));
    }, []);
    return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: 'p-5 flex flex-col gap-5', children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Room type" }), _jsx(Select, { options: ROOM_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: roomTypeId, setSelectedKey: (e) => {
                                const roomType = ROOM_TYPES.find(x => x.uuid === e);
                                setRoomTypeId(roomType.uuid);
                                setRoomName(roomType.name + getRoomPostfix(roomType.uuid, survey));
                            } })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Name" }), _jsx(Input, { value: roomName, setValue: (e) => setRoomName(e) })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Width" }), _jsx(Input, { placeholder: '2.5', postfix: 'm', type: 'number', value: width ?? '', validator: validateWallLength, setValue: (e) => setWidth(parseFloat(e)) })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Length" }), _jsx(Input, { placeholder: '2.5', postfix: 'm', type: 'number', value: length ?? '', validator: validateWallLength, setValue: (e) => setLength(parseFloat(e)) })] })] }), _jsx(Button, { disabled: !roomName || !roomTypeId || (length ? !validateWallLength(length).value : false) || (width ? !validateWallLength(width).value : false), colour: 'DARK', onClick: () => {
                        const newRoom = generateRoom(floor, length && length > 0 ? length : 2.5, width && width > 0 ? width : 2.5, floor.default_room_height, roomName, startingX, startingY, survey, roomTypeId);
                        addEvent([{ type: 'ROOM', action: 'ADD', newValue: newRoom, oldValue: undefined }]);
                        setPage('ROOM_DETAILS');
                        setCurrentRoomId(newRoom.uuid);
                        setPageOpen(false);
                    }, children: "Create room" })] }) });
};
export const generateComplexRoom = (floor, survey, lines, height) => {
    const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey);
    const defaultRoomType = ROOM_TYPES.find(x => x.uuid === 'living_lounge');
    const newRoom = {
        ...DEFAULT_SURVEY_ROOM,
        uuid: crypto.randomUUID(),
        walls: lines.map((x, i) => ({
            ...DEFAULT_SURVEY_WALL,
            uuid: crypto.randomUUID(),
            x: x.x,
            y: x.y,
            material: survey.default_materials.externalWall
        })),
        name: defaultRoomType.name,
        age_band: survey.age_band,
        floor_material: floorMaterial,
        ceiling_material: ceilingMaterial,
        room_type_uuid: defaultRoomType.uuid,
        height_m: height,
        x: 0,
        y: 0
    };
    return newRoom;
};
export const generateRoom = (floor, length, width, height, name, startingX, startingY, survey, roomType) => {
    const roomLengthM = length;
    const roomWidthM = width;
    const distanceLength = Math.round(roomLengthM * 1000);
    const distanceWidth = Math.round(roomWidthM * 1000);
    const rectangleWalls = [
        { x: 0, y: 0 },
        { x: 0, y: distanceLength },
        { x: distanceWidth, y: distanceLength },
        { x: distanceWidth, y: 0 }
    ];
    const [floorMaterial, ceilingMaterial] = getFloorAndCeilingDefaultMaterialsForFloor(floor, survey);
    const newRoom = {
        ...DEFAULT_SURVEY_ROOM,
        uuid: crypto.randomUUID(),
        walls: rectangleWalls.map((x, i) => ({
            ...DEFAULT_SURVEY_WALL,
            uuid: crypto.randomUUID(),
            x: x.x,
            y: x.y,
            material: survey.default_materials.externalWall
        })),
        name,
        age_band: survey.age_band,
        floor_material: floorMaterial,
        ceiling_material: ceilingMaterial,
        room_type_uuid: roomType,
        height_m: height,
        x: Math.round(startingX - (distanceWidth / 2)),
        y: Math.round(startingY - (distanceLength / 2))
    };
    return newRoom;
};
