import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from '../../../../../components/containers/section';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { ClickableCard } from '../../../../../components/content_display/card';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { validateURLAndHasProtocol } from '../../../../../code/validators';
export const EstimateCustomerReviewBlock = ({ company, setCompany }) => {
    const handleUpdateReview = (name, value) => {
        setCompany({
            ...company,
            public_info: {
                ...company.public_info,
                [name]: value
            }
        });
    };
    return _jsx(Section, { border: true, title: 'Customer review', children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ClickableCard, { variant: 'GREY', children: _jsx(HorizontalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Customer review section', helperText: 'Display the customer review section on the estimate' }), input: _jsx(Toggle, { value: company.public_info.show_customer_review_on_estimate, setValue: async () => {
                                const newCompany = {
                                    ...company,
                                    public_info: {
                                        ...company.public_info,
                                        show_customer_review_on_estimate: !company.public_info.show_customer_review_on_estimate
                                    }
                                };
                                setCompany(newCompany);
                            } }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Name', helperText: 'Leave blank to remove name & location from review' }), input: _jsx(Input, { placeholder: 'Customer name', value: company.public_info.customer_review_name ?? '', setValue: reviewerName => handleUpdateReview('customer_review_name', reviewerName) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Location' }), input: _jsx(Input, { placeholder: 'Customer location', value: company.public_info.customer_review_location ?? '', setValue: reviewerLocation => handleUpdateReview('customer_review_location', reviewerLocation) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Review' }), input: _jsx(TextArea, { placeholder: 'Customer review', value: company.public_info.customer_review ?? '', setValue: review => handleUpdateReview('customer_review', review), rows: 6 }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Button label', helperText: 'Leave blank to remove link' }), input: _jsx(Input, { placeholder: 'Link text', value: company.public_info.customer_review_link_text ?? '', setValue: reviewLinkText => handleUpdateReview('customer_review_link_text', reviewLinkText) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Button link' }), input: _jsx(Input, { placeholder: 'URL', value: company.public_info.customer_review_link ?? '', setValue: reviewLink => {
                            // If the URL is empty or valid, update the review link
                            if (reviewLink === '' || validateURLAndHasProtocol(reviewLink).value) {
                                handleUpdateReview('customer_review_link', reviewLink);
                            }
                        }, validator: validateURLAndHasProtocol, doNotValidateWhenEmpty: true }) })] }) });
};
