import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from '../../../components/indicators_and_messaging/tooltip';
export const TooltipDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { className: "p-8 flex justify-center", children: _jsx(Tooltip, { content: "This is a tooltip!", placement: "top", children: _jsx("button", { className: "px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600", children: "Hover me" }) }) })] })] });
};
const componentProps = [
    {
        name: 'content',
        description: 'ReactElement | string - The content to be displayed in the tooltip'
    },
    {
        name: 'placement',
        description: 'Optional placement of the tooltip (top, bottom, left, right, etc.). Defaults to top'
    },
    {
        name: 'children',
        description: 'ReactElement | ReactNode - The element that triggers the tooltip on hover/focus'
    }
];
const codeBlock = `<Tooltip
  content="This is a tooltip!"
  placement="top"
>
  <button>
    Hover over me
  </button>
</Tooltip>`;
export default TooltipDemo;
