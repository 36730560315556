import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
export const ToggleDemo = () => {
    const [toggle, setToggle] = useState(true);
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Toggle, { value: toggle, setValue: () => setToggle(!toggle), size: 'MD' })] })] });
};
const componentProps = [
    { name: 'value', description: 'Is the toggle enabled or not' },
    { name: 'setValue', description: 'Do something on toggle click' },
    { name: 'size', description: 'SM | MD | LG' }
];
const codeBlock = `const [toggle, setToggle] = useState(true)

<Toggle
  value={toggle}
  setValue={() => setToggle(!toggle)}
  size='MD'
/>
`;
