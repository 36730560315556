import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { UnauthorizedPage } from '../pages/admin/unauthorized_page';
import { AdminContext } from '../pages/admin/admin_layout';
import { hasPermission } from '../code/models/user';
import { PageHeaderNoPadding } from '../pages/heat_loss/design/components/design_page_header';
const TabbedDashboardLayout = ({ basePath, tabs, title, subtitle, selectedTabId, selectedSecondaryTabId, isOffline, allSynced, navigateTo, company, globalCompany, lead, setLead, onBack, stages, permissions }) => {
    const adminContext = useContext(AdminContext);
    tabs.forEach(tab => {
        if (!tab.content && !tab.secondaryTabs) {
            throw new Error(`Provide content OR secondaryTabs to tab ${tab.id}`);
        }
    });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedSecondaryIndex, setSelectedSecondaryIndex] = useState(0);
    useEffect(() => {
        const tabIndex = tabs.findIndex(x => x.id === selectedTabId);
        setSelectedIndex(tabIndex !== -1 ? tabIndex : 0);
        if (tabs[tabIndex]?.secondaryTabs) {
            const secondaryTabIndex = tabs[tabIndex].secondaryTabs.findIndex(x => x.id === selectedSecondaryTabId);
            setSelectedSecondaryIndex(secondaryTabIndex !== -1 ? secondaryTabIndex : 0);
        }
    }, [selectedTabId, selectedSecondaryTabId, tabs]);
    const handleTabChange = (index) => {
        const newTabId = tabs[index].id;
        if (tabs[index].secondaryTabs) {
            navigateTo(`${basePath}/${newTabId}/${tabs[index].secondaryTabs?.[0].id}`);
        }
        else {
            navigateTo(`${basePath}/${newTabId}`);
        }
    };
    const handleSecondaryTabChange = (index) => {
        const currentTab = tabs[selectedIndex];
        if (currentTab.secondaryTabs) {
            const newSecondaryTabId = currentTab.secondaryTabs[index].id;
            navigateTo(`${basePath}/${currentTab.id}/${newSecondaryTabId}`);
        }
    };
    return (_jsxs(TabGroup, { selectedIndex: selectedIndex, onChange: handleTabChange, className: 'relative flex flex-col flex-1 bg-white md:flex-col', children: [_jsx("div", { className: "px-5 pt-4 gap-2 flex flex-col border-b border-gray-300 bg-white", children: _jsx(PageHeaderNoPadding, { onBack: onBack, lead: lead, setLead: setLead, title: title, subtitle: subtitle, isOffline: isOffline, allSynced: allSynced, stages: stages, company: company, globalCompany: globalCompany, permissions: permissions, children: _jsx("div", { className: 'overflow-x-auto no-scrollbar w-full', children: _jsx(TabList, { className: 'flex gap-8', children: tabs.map(tab => (_jsx(Tab, { disabled: tab.isDisabled, className: `
                  ${tab.hidden && 'hidden'} 
                  outline-none disabled:opacity-50 py-4 whitespace-nowrap cursor-pointer text-default text-xs font-semibold leading-none 
                  data-[selected]:border-black data-[selected]:border-b-2 data-[selected]:text-bold`, children: tab.label }, tab.id))) }) }) }) }), _jsx(TabPanels, { className: "overflow-y-auto flex-1 px-5 py-6 h-full", children: tabs.map(tab => {
                    if (tab.permission && !hasPermission({
                        user: adminContext.data.user,
                        selectedCompany: globalCompany,
                        jobCompany: company,
                        permissions,
                        job: lead,
                        permissionPath: tab.permission
                    })) {
                        return _jsx(TabPanel, { children: _jsx(UnauthorizedPage, {}) }, tab.id);
                    }
                    return _jsx(TabPanel, { className: 'h-full flex flex-col', children: tab.secondaryTabs && (!isOffline || tab.availableOffline) ? (_jsxs(TabGroup, { vertical: true, selectedIndex: selectedSecondaryIndex, onChange: handleSecondaryTabChange, className: 'flex flex-col md:flex-row gap-6', children: [_jsx(TabList, { className: 'flex flex-row md:flex-col md:min-w-fit md:w-60 overflow-x-auto', children: tab.secondaryTabs.map(secondaryTab => (_jsx(Tab, { className: `${secondaryTab.hidden ? 'hidden' : ''} outline-none p-3 md:py-2.5 md:px-2 rounded-md cursor-pointer text-xs text-default whitespace-nowrap text-left data-[selected]:bg-gray-200 data-[selected]:text-bold data-[selected]:font-semibold`, children: secondaryTab.label }, secondaryTab.id))) }), _jsx(TabPanels, { className: 'w-full', children: tab.secondaryTabs.map(secondaryTab => (_jsx(TabPanel, { children: secondaryTab.content }, secondaryTab.id))) })] })) : tab.content }, tab.id);
                }) })] }));
};
export default TabbedDashboardLayout;
