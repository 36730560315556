import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { getSiteDetailsChecks } from './validations';
import { SiteDetailsLocationBlock } from './site_details/site_details_location_block';
import { SiteDetailsPhotosBlock } from './site_details/site_details_photos_block';
import { SiteDetailsTypeBlock } from './site_details/site_details_type_block';
import { SiteDetailsParkingBlock } from './site_details/site_details_parking_block';
import { SiteDetailsMainsWaterPhotosBlock } from './site_details/site_details_mains_water_photos_block';
import { SiteDetailsMainsWaterDetailsBlock } from './site_details/site_details_mains_water_details_block';
export const SurveySiteDetailsContext = React.createContext(undefined);
export const SiteDetails = (props) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x001, pbValue: 15, page: _jsx(SiteDetailsLocationBlock, {}) },
        { flagBit: 0x002, pbValue: 30, page: _jsx(SiteDetailsPhotosBlock, {}) },
        { flagBit: 0x004, pbValue: 45, page: _jsx(SiteDetailsTypeBlock, {}) },
        { flagBit: 0x008, pbValue: 60, page: _jsx(SiteDetailsParkingBlock, {}) },
        { flagBit: 0x010, pbValue: 75, page: _jsx(SiteDetailsMainsWaterPhotosBlock, {}) },
        { flagBit: 0x020, pbValue: 100, page: _jsx(SiteDetailsMainsWaterDetailsBlock, {}) }
    ];
    return _jsx(SurveySiteDetailsContext.Provider, { value: props, children: _jsx(SurveySectionFlowWrapper, { isOffline: props.isOffline, allSynced: props.allSynced, completeSectionID: 'PROPERTY', sectionUIName: 'Site settings', pages: pages, flags_attribute_name: 'flags_site_details_pages_completed', onPageComplete: (flagBit) => {
                props.setSurvey({ ...props.survey, flags_site_details_pages_completed: props.survey.flags_site_details_pages_completed | flagBit });
            }, survey: props.survey, onFormComplete: () => window.history.back(), setSurvey: props.setSurvey, completionChecks: getSiteDetailsChecks(props.survey) }) });
};
