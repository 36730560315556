import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from '../../components/indicators_and_messaging/badge';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll';
import { Input } from '../../components/inputs_and_selections/input';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
export const SoundAssessmentPage = ({ survey, setSurvey, files, setFiles, soundCalculation, currentHeatPump, companyUUID }) => {
    const reflectiveSurfaces = [{ name: 'One surface', value: 1, icon: SURFACE_ONE }, { name: 'Two surfaces', value: 2, icon: SURFACE_TWO }, { name: 'Three surfaces', value: 3, icon: SURFACE_THREE }];
    const barriers = [{ name: 'Not obscured', value: 'visible', icon: BARRIER_NONE }, { name: 'Partially obscured', value: 'partial', icon: BARRIER_PARTIAL }, { name: 'Fully obscured', value: 'no_view', icon: BARRIER_FULL }];
    const soundAssessmentImagesHydrated = survey.sound_assessment_images.map(x => files.find(y => y.uuid === x.image_uuid));
    const setSoundAssessmentImages = (images) => setSurvey({ ...survey, sound_assessment_images: images });
    return _jsx(_Fragment, { children: _jsxs("div", { className: "flex-col gap-6 flex", children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Sound assessment" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Assessment position description', helperText: 'One metre out from the middle of the closest door or window of a habitable room in a neighbouring property', required: true, complete: survey.assessment_description.length > 0 }), input: _jsx(TextArea, { value: survey.assessment_description, setValue: (e) => setSurvey({ ...survey, assessment_description: e }), size: 'SM' }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Photos of assessment position' }), input: _jsx(PhotoScroll, { images: soundAssessmentImagesHydrated, deleteImage: (id) => {
                                    setSoundAssessmentImages(survey.sound_assessment_images.filter(x => x.image_uuid !== id));
                                }, addImage: (image) => {
                                    setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                                    const newSoundAssessmentImage = {
                                        uuid: crypto.randomUUID(),
                                        image_uuid: image.uuid,
                                        entity_uuid: 'survey',
                                        entity_attribute: 'sound_assessment_images'
                                    };
                                    setSoundAssessmentImages([...survey.sound_assessment_images, newSoundAssessmentImage]);
                                }, companyUUID: companyUUID }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Distance', helperText: 'From the heat pump to the assessment position', required: true, complete: survey.sound_distance > 0 }), input: _jsx(Input, { type: 'number', value: survey.sound_distance?.toString(), setValue: (e) => setSurvey({ ...survey, sound_distance: Number(e) }), postfix: _jsx("span", { className: 'text-gray-500', children: "metres" }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Reflective surfaces', helperText: 'Reflective surfaces (including the ground) within 1 metre of the heat pump', required: true, complete: survey.reflective_surfaces > 0 }), input: _jsx(RadioGroup, { items: reflectiveSurfaces.map(x => ({
                                    name: x.name,
                                    icon: x.icon,
                                    onClick: () => setSurvey({ ...survey, reflective_surfaces: x.value }),
                                    variant: survey.reflective_surfaces === x.value ? 'ACTIVE' : 'DEFAULT'
                                })) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Barriers', required: true, complete: survey.sound_barrier_uuid !== '', helperText: 'Barriers between the heat pump and the assessment position' }), input: _jsx(RadioGroup, { items: barriers.map(x => ({
                                    name: x.name,
                                    icon: x.icon,
                                    onClick: () => setSurvey({ ...survey, sound_barrier_uuid: x.value }),
                                    variant: survey.sound_barrier_uuid === x.value ? 'ACTIVE' : 'DEFAULT'
                                })) }) }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Result" }), _jsxs("div", { className: 'flex gap-3', children: [_jsxs("div", { className: "text-gray-600", children: [soundCalculation, " dB"] }), !!(survey.sound_barrier_uuid && survey.sound_distance && survey.reflective_surfaces && currentHeatPump) &&
                                            _jsx(Badge, { color: soundCalculation <= 42 ? 'GREEN' : 'RED', text: soundCalculation <= 42 ? 'Pass' : 'Fail' })] })] })] })] }) });
};
// Surface and barrier icons
const SURFACE_TWO = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35701)", children: [_jsx("path", { d: "M326.817 49.3695L621.402 219.448V446.22L326.817 276.141V49.3695Z", fill: "#BABABA", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M326.817 49.3695L343.183 39.9207L637.768 209.999L621.402 219.448L326.817 49.3695Z", fill: "#E6E6E6" }), _jsx("path", { d: "M621.402 219.448L637.768 209.999V436.771L621.402 446.22V219.448Z", fill: "#CECECE" }), _jsx("path", { d: "M32.2317 446.22L326.817 276.141L621.402 446.22L326.817 616.299L32.2317 446.22Z", fill: "#DBDBDB" }), _jsx("path", { d: "M326.817 162.755L490.475 257.243L326.817 351.732L163.158 257.243L326.817 162.755Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M163.158 257.244V446.22L326.817 540.708V351.732L163.158 257.244Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M326.817 351.732L490.475 257.244V446.22L326.817 540.708V351.732Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35701", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
const SURFACE_THREE = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35700)", children: [_jsx("path", { d: "M327.067 49.3695L621.652 219.448V446.22L327.067 276.141V49.3695Z", fill: "#BABABA", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M327.067 49.3695L343.433 39.9207L638.018 209.999L621.652 219.448L327.067 49.3695Z", fill: "#E6E6E6" }), _jsx("path", { d: "M621.652 219.448L638.018 209.999V436.771L621.652 446.22V219.448Z", fill: "#CECECE" }), _jsx("path", { d: "M327.067 49.3696L32.4817 219.448V446.22L327.067 276.141V49.3696Z", fill: "#CACACA", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M32.4817 446.22L327.067 276.141L621.652 446.22L327.067 616.299L32.4817 446.22Z", fill: "#DBDBDB" }), _jsx("path", { d: "M327.067 162.755L490.725 257.244L327.067 351.732L163.408 257.244L327.067 162.755Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M163.408 257.244V446.22L327.067 540.708V351.732L163.408 257.244Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M327.067 351.732L490.725 257.244V446.22L327.067 540.708V351.732Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M343.432 39.9207L327.067 30.4719L16.1157 209.999L32.4815 219.448L343.432 39.9207Z", fill: "#E6E6E6" }), _jsx("path", { d: "M16.1157 210L32.4815 219.448V446.22L16.1157 436.771V210Z", fill: "#BABABA" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35700", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
const SURFACE_ONE = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35702)", children: [_jsx("path", { d: "M32.0491 446.953L326.634 276.874L621.219 446.953L326.634 617.032L32.0491 446.953Z", fill: "#DBDBDB" }), _jsx("path", { d: "M326.634 163.488L490.292 257.976L326.634 352.465L162.976 257.976L326.634 163.488Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M162.976 257.976V446.953L326.634 541.441V352.465L162.976 257.976Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M326.634 352.465L490.292 257.976V446.953L326.634 541.441V352.465Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35702", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
const BARRIER_NONE = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35705)", children: [_jsx("path", { d: "M32.0491 377.953L326.634 207.874L621.219 377.953L326.634 548.032L32.0491 377.953Z", fill: "#DBDBDB" }), _jsx("path", { d: "M326.634 94.4882L490.292 188.976L326.634 283.465L162.976 188.976L326.634 94.4882Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M162.976 188.976V377.953L326.634 472.441V283.465L162.976 188.976Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M326.634 283.465L490.292 188.976V377.953L326.634 472.441V283.465Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35705", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
const BARRIER_PARTIAL = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35704)", children: [_jsx("path", { d: "M32.7317 377.953L327.317 207.874L621.902 377.953L327.317 548.032L32.7317 377.953Z", fill: "#DBDBDB" }), _jsx("path", { d: "M327.317 94.4882L490.975 188.976L327.317 283.465L163.658 188.976L327.317 94.4882Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M163.658 188.976V377.953L327.317 472.441V283.465L163.658 188.976Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M327.317 283.465L490.975 188.976V377.953L327.317 472.441V283.465Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M343.683 557.48L638.268 387.402V236.22L343.683 406.299V557.48Z", fill: "#C6C6C6" }), _jsx("path", { d: "M327.317 548.031L343.683 557.48V406.299L327.317 396.85V548.031Z", fill: "#ADADAD" }), _jsx("path", { d: "M327.317 396.85L621.902 226.772L638.268 236.22L343.683 406.299L327.317 396.85Z", fill: "#D7D7D7" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35704", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
const BARRIER_FULL = _jsxs("svg", { width: "72", height: "72", viewBox: "0 0 655 655", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_1423_35703)", children: [_jsx("path", { d: "M32.7318 377.953L327.317 207.874L621.902 377.953L327.317 548.032L32.7318 377.953Z", fill: "#DBDBDB" }), _jsx("path", { d: "M327.317 94.4882L490.975 188.976L327.317 283.465L163.658 188.976L327.317 94.4882Z", fill: "#F3F3F3", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M163.658 188.976V377.953L327.317 472.441V283.465L163.658 188.976Z", fill: "#E6E6E6", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M327.317 283.465L490.975 188.976V377.953L327.317 472.441V283.465Z", fill: "#ECECEC", stroke: "#C2C2C2", strokeWidth: "0.999999" }), _jsx("path", { d: "M343.682 557.48L638.268 387.402V141.732L343.682 311.811V557.48Z", fill: "#C6C6C6" }), _jsx("path", { d: "M327.317 548.031L343.683 557.48V311.811L327.317 302.362V548.031Z", fill: "#ADADAD" }), _jsx("path", { d: "M327.317 302.362L621.902 132.283L638.268 141.732L343.683 311.811L327.317 302.362Z", fill: "#D7D7D7" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_1423_35703", children: _jsx("rect", { width: "654.133", height: "654.094", fill: "white" }) }) })] });
