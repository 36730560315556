import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ClickableCard } from '../../../../components/content_display/card';
import { Button } from '../../../../components/buttons/button';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Text } from '../../../../components/content_display/text';
import { formatPrice } from '../../../../code/format_price';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { Heading } from '../../../../components/content_display/heading';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Icon } from '../../../../components/buttons/icon';
import { roundTo2dp } from '../../../../code/number_format';
import { calculateQuoteSnapshot } from './proposal_configurator';
import { Copy, Percent, Plus, Trash, XCircle } from 'lucide-react';
import { Info } from '../../../../components/buttons/info';
import { formatDateValue } from '../../../../code/helpers';
const percentageToAmount = (percentage, total) => (percentage / 100) * total;
export const PaymentSchedule = ({ proposal, updateProposal, calculatedQuote }) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleAddRow = () => {
        const newSchedule = [
            ...(proposal.payment_schedule || []),
            {
                name: '',
                description: '',
                due_date: undefined,
                percentage: 0
            }
        ];
        updateProposal({ ...proposal, payment_schedule: newSchedule });
    };
    const calculatedQuoteSnapshot = calculateQuoteSnapshot(calculatedQuote, proposal);
    // Payment schedule total is pre-grant
    const total = calculatedQuoteSnapshot.total_pre_deductions + calculatedQuoteSnapshot.vat_on_all + calculatedQuoteSnapshot.vat_on_survey + calculatedQuoteSnapshot.discount_total;
    if (!isEditing) {
        return (_jsxs(ClickableCard, { variant: "LIGHT", border: true, title: _jsx("div", { className: 'w-full flex flex-row gap-2 items-center justify-between', children: _jsxs(Heading, { size: "md", className: 'flex gap-2 items-center', children: ["Payment schedule", _jsx(Info, { infoModalHeader: 'Payment schedule', infoModalBody: 'The payment schedule amounts are calculated as a percentage of the total pre-grant cost of the quote.' })] }) }), children: [proposal.payment_schedule?.map((row, index) => _jsx(PaymentScheduleRow, { row: row, index: index, totalRows: proposal.payment_schedule?.length || 0, total: total }, index)), _jsx(Button, { colour: "LIGHT", onClick: () => {
                        if (!proposal.payment_schedule || proposal.payment_schedule.length === 0) {
                            // Create a default payment schedule row
                            handleAddRow();
                        }
                        setIsEditing(true);
                    }, children: "Edit schedule" })] }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(Heading, { size: "md", className: 'flex gap-2 items-center', children: ["Edit payment schedule", _jsx(Info, { infoModalHeader: 'Payment schedule', infoModalBody: 'The payment schedule amounts are calculated as a percentage of the total pre-grant cost of the quote.' })] }), proposal.payment_schedule?.map((row, index) => _jsx(PaymentScheduleRowEditor, { row: row, index: index, totalRows: proposal.payment_schedule?.length || 0, total: total, proposal: proposal, updateProposal: updateProposal }, index)), _jsxs("div", { className: 'flex gap-2', children: [_jsx(Button, { colour: 'LIGHT', iconLeft: Plus, onClick: handleAddRow, children: "Add stage" }), _jsx(Button, { colour: "DARK", onClick: () => setIsEditing(false), children: "Save changes" })] })] }));
    }
};
export const PaymentScheduleRow = ({ row, index, totalRows, total }) => {
    return (_jsxs("div", { className: "w-full flex flex-row gap-4 items-start", children: [_jsx("div", { children: _jsx(Badge, { color: "LIGHT", text: `${index + 1}/${totalRows}` }) }), _jsxs("div", { children: [_jsx(Text, { size: "SM", bold: true, children: row.name }), row.due_date && _jsx(Text, { size: "SM", className: "text-gray-500", children: row.due_date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }), row.description && _jsx("div", { className: 'border-l-2 border-gray-300 pl-2 mt-2', children: _jsx(Text, { size: "XS", className: 'text-gray-600', children: row.description }) })] }), _jsx("div", { className: 'text-right ml-auto', children: _jsx(Text, { size: "SM", bold: true, children: formatPrice(percentageToAmount(row.percentage, total), 2) }) })] }));
};
export const PaymentScheduleRowEditor = ({ row, index, totalRows, total, proposal, updateProposal }) => {
    const [name, setName] = useState(row.name);
    const [description, setDescription] = useState(row.description);
    const [percentage, setPercentage] = useState(roundTo2dp(row.percentage));
    const [dueDate, setDueDate] = useState(row.due_date || undefined);
    const [amount, setAmount] = useState(roundTo2dp(percentageToAmount(row.percentage, total)));
    const handleSetField = (field, value) => {
        updateProposal({
            ...proposal,
            payment_schedule: proposal.payment_schedule?.map((r, i) => {
                if (i === index) {
                    return {
                        ...r,
                        [field]: value
                    };
                }
                else {
                    return r;
                }
            })
        });
    };
    const handleSetName = (value) => {
        setName(value);
        handleSetField('name', value);
    };
    const handleSetDescription = (value) => {
        setDescription(value);
        handleSetField('description', value);
    };
    const handleSetDueDate = (value) => {
        // Make sure this is a real date
        if (value instanceof Date && !isNaN(value.getTime())) {
            setDueDate(value);
            handleSetField('due_date', value);
        }
        else {
            setDueDate(undefined);
            handleSetField('due_date', undefined);
        }
    };
    const handleSetPercentage = (value) => {
        setPercentage(parseFloat(value));
        setAmount(roundTo2dp(percentageToAmount(parseFloat(value), total)));
        handleSetField('percentage', parseFloat(value));
    };
    const handleSetAmount = (value) => {
        setAmount(roundTo2dp(parseFloat(value)));
        // We round the percentage when we display it...
        setPercentage(roundTo2dp((parseFloat(value) / total) * 100));
        // ...but we don't want to round the percentage when we save it
        // (otherwise we end up with a rounding error on the amount)
        handleSetField('percentage', (parseFloat(value) / total) * 100);
    };
    const handleDeleteStage = () => {
        updateProposal({ ...proposal, payment_schedule: proposal.payment_schedule?.filter((ps, i) => i !== index) });
    };
    const handleDuplicateStage = () => {
        const newSchedule = proposal.payment_schedule || [];
        newSchedule.splice(index, 0, {
            name,
            description,
            due_date: dueDate ? new Date(dueDate) : undefined,
            percentage
        });
        updateProposal({ ...proposal, payment_schedule: newSchedule });
    };
    return (_jsxs(ClickableCard, { variant: "LIGHT", border: true, children: [_jsxs("div", { className: "flex flex-row items-center justify-between", children: [_jsxs(Text, { size: "SM", className: 'flex gap-1 items-center text-gray-600 font-semibold', children: ["Payment stage ", _jsx(Badge, { color: "LIGHT", text: `${index + 1}/${totalRows}` })] }), _jsx(DropdownMenu, { items: [
                            {
                                label: 'Delete',
                                onClick: handleDeleteStage,
                                icon: Trash,
                                confirmText: 'Are you sure you want to delete this payment stage?'
                            },
                            {
                                label: 'Duplicate',
                                onClick: handleDuplicateStage,
                                icon: Copy
                            }
                        ] })] }), _jsx(Input, { placeholder: "Stage name", value: name, setValue: handleSetName }), _jsx(TextArea, { placeholder: "Description", value: description || '', setValue: handleSetDescription }), _jsxs("div", { className: 'grid grid-cols-2 gap-2', children: [_jsx(Input, { type: "number", value: percentage, setValue: handleSetPercentage, postfix: _jsx(Icon, { icon: Percent }) }), _jsx(Input, { type: "number", value: amount, setValue: handleSetAmount, prefix: "\u00A3" })] }), _jsx(Input, { type: "date", value: formatDateValue(dueDate), setValue: (e) => handleSetDueDate(new Date(e)), postfix: _jsx(Icon, { icon: XCircle, onClick: () => handleSetDueDate(undefined) }) })] }));
};
