import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getCompanyPublicInfo, getCompanyPublicInfoSilent } from './code/models/company';
import { AdminLayout } from './pages/admin/admin_layout';
import { LoginPage } from './pages/admin/login_page';
import { RemindPasswordPage } from './pages/admin/remind_password_page';
import { Components } from './pages/components/components';
import { Error404Page } from './pages/error_pages';
import { EstimatePage } from './pages/estimate_page';
import { SignUpPage } from './pages/sign_up_page';
import { SurveyPageWrapper } from './pages/survey/survey_page';
import { AcceptInvitationPage } from './pages/accept_invitation_page';
import { useNavigation } from './code/use_navigation';
import { match } from 'path-to-regexp';
import { AuthSDK } from './code/utils/auth_provider';
import { HeatLossReportPublicPage } from './pages/heatloss_report_public_page';
import { QuotePublicPage } from './pages/quote_public_page';
import { ProposalPublicPage } from './pages/proposal_public_page';
import { getUser } from './code/models/user';
import { LoaderOverlay } from './components/indicators_and_messaging/loader_overlay';
import { useLocalStorage } from 'usehooks-ts';
import { redirectIfNeeded } from './redirect_if_needed';
import { NewPasswordPage } from './pages/admin/new_password';
export const Router = () => {
    const { currentPath, navigateTo } = useNavigation();
    useEffect(() => {
        const userUUID = AuthSDK.getTokenPayload()?.user_uuid;
        redirectIfNeeded(currentPath, navigateTo, userUUID);
    }, []);
    const ROUTES = [
        {
            path: '/login',
            component: () => _jsx(LoginPage, { navigateTo: navigateTo })
        },
        {
            path: '/remind-password',
            component: () => _jsx(RemindPasswordPage, { navigateTo: navigateTo })
        },
        {
            path: '/sign_up',
            component: () => _jsx(SignUpPage, {})
        },
        {
            path: '/new-password',
            component: () => _jsx(NewPasswordPage, { navigateTo: navigateTo })
        },
        {
            path: '/invitation/:context/:token',
            component: ({ context, token }) => {
                return _jsx(AcceptInvitationPage, { token: token, navigateTo: navigateTo, context: context });
            }
        },
        {
            path: '/components{/*subPath}',
            component: () => _jsx(Components, { currentPath: currentPath, navigateTo: navigateTo })
        },
        {
            path: '/admin{/*subPath}',
            component: () => {
                return _jsx(AdminRouter, { currentPath: currentPath, navigateTo: navigateTo });
            }
        },
        {
            path: '/:companySubdomain{/*subPath}',
            component: ({ companySubdomain }) => {
                return _jsx(CompanySubdomainRouter, { currentPath: currentPath, navigateTo: navigateTo, companySubdomain: companySubdomain });
            }
        }
    ];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath), component: x.component })).find(x => x.evaluatedPath);
    if (page?.evaluatedPath)
        return page.component(page.evaluatedPath.params);
    return _jsx(Error404Page, {});
};
// Handles routes beginning with the company subdomain, which are all publicly accessible
const CompanySubdomainRouter = ({ currentPath, companySubdomain, navigateTo }) => {
    const [companyPublicInfo, setCompanyPublicInfo] = useState();
    useEffect(() => {
        const main = async () => {
            const companyPublicInfo = await getCompanyPublicInfoSilent(companySubdomain);
            if (companyPublicInfo)
                setCompanyPublicInfo(companyPublicInfo);
        };
        main();
    }, []);
    if (!companyPublicInfo) {
        return (_jsx(LoaderOverlay, {}));
    }
    const ROUTES = [
        {
            path: '',
            component: () => _jsx(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: true, isAdmin: false })
        },
        {
            path: '/embed',
            component: () => _jsx(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: false, isAdmin: false })
        },
        {
            path: '/estimate/:estimateId',
            component: ({ estimateId }) => {
                return _jsx(EstimatePage, { estimateId: estimateId, companyPublicInfo: companyPublicInfo });
            }
        },
        {
            path: '/report/:reportUUID',
            component: ({ reportUUID }) => {
                return _jsx(HeatLossReportPublicPage, { reportUUID: reportUUID, companyPublicInfo: companyPublicInfo });
            }
        },
        {
            path: '/quote/:quoteUUID',
            component: ({ quoteUUID }) => {
                return _jsx(QuotePublicPage, { quoteUUID: quoteUUID, companyPublicInfo: companyPublicInfo });
            }
        },
        {
            path: '/proposal/:proposalUUID',
            component: ({ proposalUUID }) => {
                return _jsx(ProposalPublicPage, { proposalUUID: proposalUUID, companyPublicInfo: companyPublicInfo });
            }
        }
    ];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace(`/${companySubdomain}`, '')), component: x.component })).find(x => x.evaluatedPath);
    if (page?.evaluatedPath)
        return page.component(page.evaluatedPath.params);
    return _jsx(Error404Page, {});
};
const AdminRouter = ({ currentPath, navigateTo }) => {
    const [companyPublicInfo, setCompanyPublicInfo] = useState();
    const [currentCompanyUUID, setCurrentCompanyUUID] = useLocalStorage('current_company_uuid', undefined);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const main = async () => {
            setIsLoading(true);
            // Are we logged in?
            if (!AuthSDK.isSignedIn()) {
                navigateTo(`/login?redirect=${encodeURIComponent(currentPath)}`);
                return;
            }
            try {
                let calculatedCompanyUUID = currentCompanyUUID;
                if (!currentCompanyUUID) {
                    const user = await getUser();
                    if (user?.user_companies?.length) {
                        const companyUuid = user.user_companies[0].company_uuid;
                        setCurrentCompanyUUID(companyUuid);
                        calculatedCompanyUUID = companyUuid;
                    }
                }
                if (calculatedCompanyUUID) {
                    const companyPublicInfo = await getCompanyPublicInfo(calculatedCompanyUUID);
                    if (companyPublicInfo)
                        setCompanyPublicInfo(companyPublicInfo);
                }
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        };
        main();
    }, []);
    // If we do have an association with a company, we can always get the companyPublicInfo,
    // in which case we need to wait for it to be fetched before rendering AdminLayout.
    // Otherwise, we can render AdminLayout immediately - company info will be fetched on a per-lead basis.
    if (isLoading) {
        return (_jsx(LoaderOverlay, {}));
    }
    return _jsx(AdminLayout, { setCurrentCompanyUUID: setCurrentCompanyUUID, companyPublicInfo: companyPublicInfo, currentPath: currentPath, navigateTo: navigateTo });
};
