import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion } from '../../../components/content_display/accordion';
export const AccordionDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Accordion, { rows: [
                            { title: 'Row 1', body: 'Hello world 1' },
                            { title: 'Row 2', body: 'Hello world 2' },
                            { title: 'Row 3', body: 'Hello world 3' }
                        ] })] })] });
};
const componentProps = [
    { name: 'rows', description: 'An array of items that should appear in the accordion' },
    { name: 'title', description: 'Title of specific row' },
    { name: 'body', description: 'Body of specific row, hidden if accordion item is closed' }
];
const codeBlock = `<Accordion
  rows={[
    { title: 'Row 1', body: 'Hello world 1' },
    { title: 'Row 2', body: 'Hello world 2' },
    { title: 'Row 3', body: 'Hello world 3' }
  ]}
/>`;
