import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from '../../../../../components/containers/modal';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { PermissionTree } from './permission_tree';
import { Input } from '../../../../../components/inputs_and_selections/input';
export const EditCompanyRole = ({ editingRole, setEditingRole, permissions, onSave, setVisible }) => {
    return (_jsx(Modal, { visible: true, confirmDisabled: !editingRole.name, setVisible: setVisible, title: "Role", confirmButtonLabel: 'Save', onConfirm: onSave, children: _jsxs("div", { className: 'flex flex-col gap-6 w-full', children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Role name', required: true }), input: _jsx(Input, { setValue: e => setEditingRole(prev => ({ ...prev, name: e })), value: editingRole.name }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Description' }), input: _jsx(TextArea, { placeholder: 'Add an optional description', setValue: e => setEditingRole(prev => ({ ...prev, description: e })), value: editingRole.description ?? '' }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Permissions' }), input: _jsx(PermissionTree, { permissions: permissions, companyRole: editingRole, setEditingRole: setEditingRole }) })] }) }));
};
