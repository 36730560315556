import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { MaterialInputField } from './materials/material_input_field';
import { validateInMm } from '../../code/validators';
export const DoorPage = ({ door, setDoor, onSave, survey, materials, setMsProps, setPage, onBack, floor, setScalingDoor }) => {
    const isValidForm = [validateInMm(door.width_mm), validateInMm(door.height_mm)].every(x => x.value);
    return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: "p-5 bg-white flex-col gap-5 flex", children: [_jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Door material" }), _jsx(MaterialInputField, { selectorProps: {
                                title: 'Door',
                                surfaceType: 'door',
                                materials,
                                ageBand: survey.age_band,
                                selectedMaterial: door.material,
                                onSelectCallback: (material) => {
                                    setDoor(prev => ({ ...prev, material, updated_at: new Date().getTime() }));
                                }
                            }, setMsProps: setMsProps, setPage: setPage })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Width" }), _jsx(Input, { step: 10, type: 'number', value: door.width_mm.toString(), setValue: (e) => setDoor(prev => ({ ...prev, width_mm: parseFloat(e), updated_at: new Date().getTime() })), validator: validateInMm, postfix: 'mm' })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Height" }), _jsx(Input, { step: 10, type: 'number', value: door.height_mm.toString(), setValue: (e) => setDoor(prev => ({ ...prev, height_mm: parseFloat(e), updated_at: new Date().getTime() })), validator: validateInMm, postfix: 'mm' })] })] }), _jsxs("div", { className: 'flex gap-2', children: [floor.floor_plan_is_showing && (floor.floor_plan_image || floor.floor_plan_url) && _jsx(Button, { className: 'flex-1', colour: 'LIGHT', onClick: () => { setScalingDoor(true); }, children: "Measure width" }), _jsx(Button, { className: 'flex-1', onClick: () => { onSave(); setDoor(undefined); onBack(); }, disabled: !isValidForm, children: "Apply" })] })] }) });
};
