import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { getAddressIncludingPostcode, getLeadsList, patchLead } from '../../code/models/lead';
import { chain } from 'lodash';
import { AdminContext } from './admin_layout';
import { contextSensitiveFormatDate } from '../../code/format_date';
import { Input } from '../../components/inputs_and_selections/input';
import { Icon } from '../../components/buttons/icon';
import { Button } from '../../components/buttons/button';
import { DuplicateLeadModal } from './components/duplicate_lead_modal';
import TabbedDashboardLayout from '../../layouts/tabbed_dashboard_layout';
import { Building2, ChevronLeft, ChevronRight, Columns3, Copy, List, Search, X } from 'lucide-react';
import { ClickableCard } from '../../components/content_display/card';
import { Switch } from '../../components/inputs_and_selections/switch';
import { JobBoard } from './job_board_page';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { DropdownMenu } from '../../components/buttons/dropdown_menu';
import { useLocalStorage } from 'usehooks-ts';
import { captureException } from '@sentry/react';
import { Loader } from '../../components/indicators_and_messaging/loader';
import { createJobEvent, JobEventType } from '../../code/models/job_event';
export const LeadsListPage = (props) => {
    const adminContext = useContext(AdminContext);
    const { filter, basePath, navigateTo, stages, companyPublicInfo } = props;
    const [view, setView] = useLocalStorage('lead_list_vew', 'LIST');
    const [filterString, setFilterString] = useState(undefined);
    const [leads, setLeads] = useLocalStorage('leads', []);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const main = async () => {
            try {
                const allLeads = await getLeadsList(companyPublicInfo?.uuid);
                setLeads(allLeads);
            }
            catch (e) {
                console.error(e);
                captureException(e);
            }
            finally {
                setIsLoading(false);
            }
        };
        main();
    }, []);
    if (isLoading) {
        return _jsx("div", { className: 'p-5 flex justify-center', children: _jsx(Loader, {}) });
    }
    return (_jsx(TabbedDashboardLayout, { navigateTo: navigateTo, basePath: basePath + '/enquiries', title: 'Jobs', selectedTabId: filter, isOffline: adminContext.isOffline, company: props.company, permissions: props.permissions, tabs: [
            { id: 'alljobs', label: 'All jobs', content: _jsx(LeadsListInner, { ...props, view: view, setView: setView, filterString: filterString, setFilterString: setFilterString, leads: leads, setLeads: setLeads }) },
            ...stages.map(x => ({ id: x.code.toLowerCase(), label: x.name, content: _jsx(LeadsListInner, { ...props, view: view, setView: setView, filterString: filterString, setFilterString: setFilterString, leads: leads, setLeads: setLeads }) }))
        ] }));
};
const LeadsListInner = ({ filter, companyPublicInfo, basePath, navigateTo, stages, view, setView, filterString, setFilterString, leads, setLeads }) => {
    // duplicate Lead state
    const [isDuplicateLeadModalOpen, setIsDuplicateLeadModalOpen] = useState(false);
    const [leadToDuplicate, setLeadToDuplicate] = useState(undefined);
    const toggleDuplicateLeadModalVisibility = (visible) => {
        if (!visible)
            setLeadToDuplicate(undefined);
        setIsDuplicateLeadModalOpen(visible);
    };
    const [paginationIndex, setPaginationIndex] = useState(1);
    const pageSize = 20;
    const filterLogic = (lead, filterString) => {
        const filterStringLower = filterString.toLowerCase();
        if (lead.job_name?.toLowerCase().includes(filterStringLower))
            return true;
        if (`${lead.customer?.first_name}${lead.customer?.last_name ? ` ${lead.customer?.last_name}` : ''}`.toLowerCase().includes(filterStringLower))
            return true;
        if (getAddressIncludingPostcode(lead).toLowerCase().includes(filterStringLower))
            return true;
        if (lead.customer?.email?.toLowerCase().includes(filterStringLower))
            return true;
        return false;
    };
    const statuses = stages.flatMap(x => x.statuses);
    const leadsWithStatus = leads?.map(x => ({
        ...x,
        status: statuses.find(y => y.uuid === x.status_uuid)
    }));
    const archivedStage = stages.find(x => x.code === 'Archived');
    const activeStage = stages.find(x => x.code.toLowerCase() === filter);
    // filter leads
    const filteredLeads = chain(leadsWithStatus)
        .filter(x => {
        const filteredByString = !filterString || filterLogic(x, filterString);
        // Archived is temporary until we have filters in views.
        if (!filter || filter === 'alljobs')
            return filteredByString && (!x.status || (x.status?.stage_uuid !== archivedStage?.uuid));
        return filteredByString && x.status?.stage_uuid === activeStage?.uuid;
    })
        .value();
    const maxPageSize = Math.ceil(filteredLeads.length / pageSize);
    const paginatedLeads = chain(filteredLeads)
        .orderBy(x => new Date(x.createdAt), 'desc')
        .drop((paginationIndex - 1) * pageSize)
        .take(pageSize)
        .value();
    const hasStagesAndStatuses = stages.length && statuses.length;
    const leadsHaveDifferentCompanies = leadsWithStatus?.some(x => x.company?.uuid !== leadsWithStatus[0].company?.uuid);
    if (!hasStagesAndStatuses)
        view = 'LIST';
    const FilterAndPagination = _jsxs("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-between w-full mb-6', children: [_jsx(Input, { className: 'w-full', prefix: _jsx(Icon, { icon: Search }), postfix: filterString && _jsx(Icon, { icon: X, onClick: () => setFilterString(undefined) }), placeholder: `Search ${filteredLeads.length} jobs`, value: filterString ?? '', setValue: (e) => {
                    setFilterString(e);
                    setPaginationIndex(1);
                } }), _jsxs("div", { className: 'flex gap-2 justify-end items-center', children: [maxPageSize > 1 && view === 'LIST' && _jsxs("div", { className: "w-full md:w-auto items-center gap-7 flex justify-between", children: [_jsx(Button, { onClick: () => setPaginationIndex(prev => prev - 1), disabled: paginationIndex === 1, iconLeft: ChevronLeft, colour: 'LIGHT' }), _jsxs("div", { className: 'text-default text-sm', children: ["Page ", _jsx("span", { className: 'font-bold', children: paginationIndex }), " of ", _jsx("span", { className: 'font-bold', children: maxPageSize })] }), _jsx(Button, { onClick: () => setPaginationIndex(prev => prev + 1), disabled: paginationIndex === maxPageSize, iconLeft: ChevronRight, colour: 'LIGHT' })] }), hasStagesAndStatuses ? _jsx(Switch, { items: [
                            { icon: List, onClick: () => setView('LIST'), variant: view === 'LIST' ? 'ACTIVE' : 'DEFAULT' },
                            { icon: Columns3, onClick: () => setView('BOARD'), variant: view === 'BOARD' ? 'ACTIVE' : 'DEFAULT' }
                        ] }) : null] })] });
    const setLead = async (lead) => {
        setLeads(leads.map(x => x.uuid === lead.uuid ? lead : x));
        await patchLead({ uuid: lead.uuid, lead: { ...lead, status: lead.status }, companyUUID: companyPublicInfo?.uuid });
    };
    return (_jsxs("div", { className: 'flex flex-col flex-grow', children: [FilterAndPagination, view === 'LIST' ? _jsx("div", { className: 'flex flex-col gap-2', children: paginatedLeads.map(x => {
                    if (!stages)
                        return;
                    const StatusAndDropdown = x.status && _jsxs("div", { className: 'flex items-center gap-2 sm:justify-end', children: [_jsx(Badge, { text: x.status.name, color: x.status.color }), _jsx(DropdownMenu, { items: [...stages.map(y => y.statuses.map(z => ({
                                        label: z.name,
                                        onClick: () => {
                                            if (x.status_uuid !== z.uuid) {
                                                createJobEvent({
                                                    event_type: JobEventType.enum.StatusChanged,
                                                    company_uuid: companyPublicInfo?.uuid,
                                                    job_uuid: x.uuid,
                                                    extra_data: { old_status: x.status_uuid, new_status: z.uuid }
                                                });
                                                setLead({ ...x, status_uuid: z.uuid });
                                            }
                                        },
                                        group: y.name
                                    }))).flat(), {
                                        label: 'Duplicate',
                                        group: 'Actions',
                                        icon: Copy,
                                        onClick: () => {
                                            toggleDuplicateLeadModalVisibility(true);
                                            setLeadToDuplicate(x);
                                        }
                                    }] })] });
                    const Name = _jsxs("div", { className: 'col-span-2 flex flex-col flex-grow cursor-pointer', onClick: () => navigateTo(`${basePath}/quotes/${x.uuid}`), children: [_jsx("div", { className: "text-bold font-bold flex-grow", children: x.job_name || `${x.customer?.first_name}${x.customer?.last_name ? ` ${x.customer.last_name}` : ''}` }), _jsx("div", { className: "text-light text-xs font-medium", children: getAddressIncludingPostcode(x) })] });
                    const Contact = _jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-light text-xs font-medium truncate", children: x.customer?.email }), _jsx("div", { className: "text-light text-xs font-medium", children: x.customer?.phone })] });
                    const DateCell = _jsx("div", { className: 'text-light text-xs', children: contextSensitiveFormatDate(new Date(x.createdAt)) });
                    const Companies = leadsHaveDifferentCompanies ? _jsx("div", { className: 'flex-col flex', children: _jsxs("div", { className: "text-light text-xs font-bold flex gap-2 items-center", children: [_jsx("div", { className: "rounded-full bg-gray-100 p-2", children: _jsx(Icon, { icon: Building2, size: "w-4 h-4 min-w-4 min-h-4" }) }), " ", x.company?.name] }) }) : null;
                    return _jsxs(ClickableCard, { variant: 'WHITE', dataCy: 'job-row', children: [_jsxs("div", { className: `hidden lg:grid ${leadsHaveDifferentCompanies ? 'grid-cols-6' : 'grid-cols-5'} gap-8 items-center justify-between`, children: [Name, Contact, Companies, DateCell, StatusAndDropdown] }), _jsxs("div", { className: 'lg:hidden flex flex-col gap-4', children: [_jsxs("div", { className: 'flex justify-between items-center', children: [Name, StatusAndDropdown] }), _jsxs("div", { className: 'flex gap-8 items-center', children: [Contact, DateCell, Companies] })] })] }, x.uuid);
                }) }) : _jsx(JobBoard, { stageOrStatuses: filter === 'alljobs' || !filter ? 'STAGE' : 'STATUS', stageFilterUUID: activeStage?.uuid, stages: stages, jobs: filteredLeads, setLead: setLead, setLeads: setLeads, companyUUID: companyPublicInfo?.uuid, setLeadToDuplicate: setLeadToDuplicate, toggleDuplicateLeadModalVisibility: toggleDuplicateLeadModalVisibility, navigateTo: navigateTo, basePath: basePath }), leadToDuplicate && isDuplicateLeadModalOpen && _jsx(DuplicateLeadModal, { visible: true, setVisible: toggleDuplicateLeadModalVisibility, lead: leadToDuplicate, navigateTo: navigateTo })] }));
};
