import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Check } from 'lucide-react';
import { SurveySiteDetailsContext } from '../site_details';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const SiteDetailsPhotosBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey, files, setFiles, companyUUID } = prContext;
    const propertyImagesHydrated = survey.property_images.map(x => files.find(y => y.uuid === x.image_uuid));
    const setPropertyImages = (images) => setSurvey({ ...survey, property_images: images });
    const isFullScreen = propertyImagesHydrated.length === 0 && flowContext.viewMode === 'FLOW';
    return _jsx(_Fragment, { children: _jsxs("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4', children: [_jsxs("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`, children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Property location" }), _jsx("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`, children: "Add photos of the property and the surrounding area" })] }), _jsxs("div", { className: "flex-col gap-3 flex", children: [!isFullScreen &&
                            _jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Photos" }), _jsx(Badge, { color: prContext.survey.property_images.length > 0 ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.property_images.length > 0 ? Check : undefined })] }), _jsx(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: propertyImagesHydrated, deleteImage: (imageUUID) => {
                                setPropertyImages(survey.property_images.filter(x => x.image_uuid !== imageUUID));
                            }, addImage: (image) => {
                                setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                                const newImage = { uuid: crypto.randomUUID(), image_uuid: image.uuid, entity_uuid: 'survey', entity_attribute: 'property_images' };
                                setPropertyImages([...survey.property_images, newImage]);
                            }, companyUUID: companyUUID })] })] }) });
};
