import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Icon } from './icon';
import { Text } from '../content_display/text';
import { Modal } from '../containers/modal';
import { Ellipsis } from 'lucide-react';
import { chain } from 'lodash';
export const DropdownMenu = ({ icon, items }) => {
    const [confirmModalItem, setConfirmModalItem] = useState(undefined);
    const itemsGrouped = chain(items)
        .groupBy(x => x.group)
        .map((values, key) => ({ key, values }))
        .value();
    return (_jsxs(_Fragment, { children: [_jsxs(Menu, { children: [_jsx(MenuButton, { children: _jsx(Icon, { icon: icon ?? Ellipsis, dataCy: 'dropdown-button' }) }), _jsx(MenuItems, { anchor: "bottom end", className: "outline-none bg-white rounded-md shadow border border-light flex flex-col z-30 divide-y divide-gray-200", children: itemsGrouped.map(group => {
                            return _jsxs("div", { className: 'flex flex-col py-1', children: [group.key !== 'undefined' && _jsx("div", { className: "text-gray-500 text-xs font-medium px-3 py-2", children: group.key }), group.values.map((item, index) => (_jsx(MenuItem, { children: _jsxs("button", { onClick: item.confirmText ? () => setConfirmModalItem(item) : item.onClick, className: "flex items-center gap-2 px-3 py-2 hover:bg-gray-200 disabled:hover:bg-gray-100 group", disabled: item.disabled, children: [item.icon && _jsx(Icon, { icon: item.icon, className: 'group-disabled:text-gray-300' }), _jsx(Text, { size: "SM", className: 'group-disabled:text-gray-300', children: item.label })] }) }, index)))] }, group.key);
                        }) })] }), confirmModalItem && _jsx(Modal, { onConfirm: confirmModalItem.onClick, visible: true, title: confirmModalItem.confirmText, setVisible: () => setConfirmModalItem(undefined), children: "This action cannot be undone." })] }));
};
