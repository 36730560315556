import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SystemDesign } from '../../enquiry_manager/sections/system_design';
import { SimpleLayout } from '../../../../../layouts/simple_layout';
import { Heading } from '../../../../../components/content_display/heading';
import { Icon } from '../../../../../components/buttons/icon';
import { CostBreakdown } from '../../enquiry_manager/sections/cost_breakdown';
import { ArrowLeft } from 'lucide-react';
const CostEstimateEditorHeader = ({ navigateTo }) => {
    const handleReturn = () => {
        const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
        navigateTo(`${baseUrl}/dashboard`);
    };
    return (_jsx("div", { className: 'flex items-center justify-between', children: _jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(Icon, { className: 'w-6 h-6', onClick: handleReturn, icon: ArrowLeft }), _jsx(Heading, { size: 'xl', children: "Edit cost estimate" })] }) }));
};
export const CostEstimateEditor = ({ lead, setLead, estimate, navigateTo, companyPublicInfo, company }) => {
    return (_jsx("div", { className: 'absolute inset-0 h-screen z-10 overflow-auto', children: _jsx(SimpleLayout, { header: _jsx(CostEstimateEditorHeader, { navigateTo: navigateTo }), children: _jsxs("div", { className: "space-y-6 p-6", children: [_jsx(SystemDesign, { company: company, lead: lead, setLead: setLead, estimate: estimate }), _jsx(CostBreakdown, { company: company, lead: lead, setLead: setLead, estimate: estimate, companyPublicInfo: companyPublicInfo })] }) }) }));
};
