import { jsx as _jsx } from "react/jsx-runtime";
export const ProgressBar = ({ progress }) => {
    if (progress < 0 || progress > 100) {
        throw new Error(`Progress bar progress must be between 0 and 100, but got ${progress}`);
    }
    // progressbar steps number
    const blocksNumber = 20;
    // use only 20 blocks to represent 100% progress
    const progressBlocksNumber = Math.floor(progress / 5);
    const restBlocksNumber = blocksNumber - progressBlocksNumber;
    const blocks = [];
    // FIXME: w-1/12 works but it's not 1/blocksNumber. Confusing.
    const commonClasses = 'h-1 w-1/12';
    for (let i = 0; i < progressBlocksNumber; i++) {
        blocks.push(_jsx("div", { className: `${commonClasses} bg-gray-500` }, 'progress-' + i));
    }
    for (let i = 0; i < restBlocksNumber; i++) {
        blocks.push(_jsx("div", { className: `${commonClasses} bg-gray-100` }, 'rest-' + i));
    }
    return _jsx("div", { className: "h-1 flex flex-row w-full", children: blocks });
};
