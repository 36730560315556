import { jsx as _jsx } from "react/jsx-runtime";
import { getCompanyEstimateCoverNote } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders } from '../../../../../code/tiptap_placeholders';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
export const EstimateCoverNoteTemplateBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: 'Cover note', children: _jsx("div", { className: 'flex flex-col gap-4  ', children: _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Message' }), input: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setCompany({
                            ...company,
                            public_info: {
                                ...company.public_info,
                                calculated_estimate_cover_note: JSON.stringify(editor.getJSON())
                            }
                        });
                    }, placeholders: defaultPlaceholders, content: getCompanyEstimateCoverNote(company.public_info.calculated_estimate_cover_note || '') }) }) }) });
};
