import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SurveyEHSContext } from '../existing_heating_system';
import { HEATING_FUELS } from '../../../../code/models/heating_fuel';
import { getHeatingSystemEfficiency, HEATING_SYSTEM_AGES, HEATING_SYSTEM_TYPES } from '../../../../code/models/heating_system';
import { Select } from '../../../../components/inputs_and_selections/select';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Check } from 'lucide-react';
export const EHSDetailsBlock = () => {
    const ehsContext = React.useContext(SurveyEHSContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey } = ehsContext;
    const [heatingFuelId, setHeatingFuelId] = useState(survey.existing_system_fuel_uuid);
    const heatingSystemType = HEATING_SYSTEM_TYPES.find(x => x.uuid === survey.existing_system_type_uuid);
    const fuelsToIgnoreAgeFor = ['electricity', 'coal'];
    const heatingSystemAge = HEATING_SYSTEM_AGES.find(x => x.name === survey.existing_system_age_uuid);
    return _jsxs(_Fragment, { children: [flowContext.viewMode === 'FLOW' && _jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Existing system" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Fuel" }), _jsx(RadioGroup, { items: HEATING_FUELS.map(x => ({
                                    name: x.name,
                                    onClick: () => {
                                        setHeatingFuelId(x.uuid);
                                        setSurvey({
                                            ...survey,
                                            existing_system_fuel_uuid: x.uuid,
                                            existing_system_p_per_unit_override: undefined,
                                            existing_system_type_uuid: (fuelsToIgnoreAgeFor.includes(x.uuid) || fuelsToIgnoreAgeFor.includes(survey.existing_system_fuel_uuid) || (!heatingSystemType))
                                                ? ''
                                                : HEATING_SYSTEM_TYPES.filter(y => y.fuel_uuid === x.uuid && y.name === heatingSystemType.name)[0].uuid,
                                            existing_system_age_uuid: (fuelsToIgnoreAgeFor.includes(x.uuid) || fuelsToIgnoreAgeFor.includes(survey.existing_system_fuel_uuid))
                                                ? ''
                                                : survey.existing_system_age_uuid
                                        }); // Try to maintain e.g. combi boiler and age when going from mains gas to LGP and Oil. Only reset if going to or from electricity
                                    },
                                    variant: heatingFuelId === x.uuid ? 'ACTIVE' : 'DEFAULT'
                                })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Type" }), _jsx(Badge, { color: survey.existing_system_type_uuid ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.existing_system_type_uuid ? Check : undefined })] }), _jsx(Select, { selectedKey: survey.existing_system_type_uuid, setSelectedKey: (e) => setSurvey({ ...survey, existing_system_type_uuid: e }), options: HEATING_SYSTEM_TYPES.filter(x => x.fuel_uuid === heatingFuelId).map(x => ({
                                    key: x.uuid,
                                    value: x.name
                                })) })] }), !fuelsToIgnoreAgeFor.includes(heatingFuelId) && _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Age of existing system" }), _jsx(Badge, { color: survey.existing_system_age_uuid ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.existing_system_age_uuid ? Check : undefined })] }), _jsx(Select, { selectedKey: survey.existing_system_age_uuid, options: HEATING_SYSTEM_AGES.map(x => ({ key: x.name, value: `${x.name}: ${x.efficiency_percentage}%` })), setSelectedKey: (e) => setSurvey({ ...survey, existing_system_age_uuid: e }) })] }), _jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Efficiency" }), _jsxs("div", { className: "text-gray-600", children: [getHeatingSystemEfficiency(heatingSystemType, heatingSystemAge) * 100, "%"] })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Location" }), _jsx(TextArea, { value: survey.existing_system_location, setValue: (e) => setSurvey({ ...survey, existing_system_location: e }) })] })] })] });
};
