import { jsx as _jsx } from "react/jsx-runtime";
const sizeToElement = {
    md: 'h5',
    lg: 'h4',
    xl: 'h3',
    '2xl': 'h2',
    '3xl': 'h1'
};
const sizeToTailwind = {
    md: 'text-md',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl'
};
export const Heading = ({ size, children, className = '' }) => {
    const Element = sizeToElement[size];
    return _jsx(Element, { className: `font-bold text-bold ${sizeToTailwind[size]} ${className}`, children: children });
};
