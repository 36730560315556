import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { DEFAULT_SURVEY_FLOOR } from '../../../code/survey_defaults';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { FLOOR_NAMES } from '../survey/survey';
import { isFlagSet } from '../../../code/helpers';
import { validateIsPositiveNumber } from '../../../code/validators';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Info } from '../../../components/buttons/info';
import { FloorTypeInfo } from './floor_canvas/floor_type_selector';
export const floorCeilingTypeNameMap = {
    'ground-floor': 'Ground',
    'intermediate-floor-and-ceiling': 'Intermediate',
    'exposed-floor': 'Exposed',
    roof: 'Roof'
};
export const FloorAttributesBlock = ({ floor, floors, onSave }) => {
    const existingFloorNames = floors.filter(x => x.uuid !== floor?.uuid).map(x => x.name);
    const remainingFloorNames = FLOOR_NAMES.filter(x => !existingFloorNames.includes(x));
    // pre-select next available floor name
    const [floorName, setFloorName] = useState(floor?.name ?? (remainingFloorNames.length > 0 ? remainingFloorNames[0] : ''));
    const [roomHeight, setRoomHeight] = useState(floor?.default_room_height ?? DEFAULT_SURVEY_FLOOR.default_room_height);
    const [floorMaterialType, setFloorMaterialType] = useState(floor?.default_floor_material_type ?? DEFAULT_SURVEY_FLOOR.default_floor_material_type);
    const [ceilingMaterialType, setCeilingMaterialType] = useState(floor?.default_ceiling_material_type ?? DEFAULT_SURVEY_FLOOR.default_ceiling_material_type);
    // a flag to track which attributes user has changed
    const [userSelectedAttributesFlags, setUserSelectedAttributesFlags] = useState(0);
    // flag values
    const USER_SELECTED_FLOOR_TYPE = 0x001;
    const USER_SELECTED_CEILING_TYPE = 0x002;
    // do some magic based on the chosen name of the floor
    useEffect(() => {
        // skip magic if we're changing an existing floor, not creating a new one
        if (floor)
            return;
        if (floorName.toLowerCase().includes('ground')) {
            // if user hasn't selected floor type manually, set it to ground
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_FLOOR_TYPE)) {
                // set the floor and ceiling types based on the name
                setFloorMaterialType('ground-floor');
            }
            // if user hasn't selected ceiling type manually, set it to intermediate
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_CEILING_TYPE)) {
                setCeilingMaterialType('intermediate-floor-and-ceiling');
            }
        }
        else {
            // if user hasn't selected floor type manually, set it to intermediate
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_FLOOR_TYPE)) {
                setFloorMaterialType('intermediate-floor-and-ceiling');
            }
            // if user hasn't selected ceiling type manually, set it to roof
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_CEILING_TYPE)) {
                setCeilingMaterialType('roof');
            }
        }
    }, [floorName]);
    // TODO later: add in floor and ceiling materials and other side temps at floor level too
    return _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(FormLabel, { labelText: 'Floor name' }), _jsx(RadioGroup, { items: remainingFloorNames.map(x => ({
                                name: x,
                                onClick: () => setFloorName(x),
                                variant: x === floorName ? 'ACTIVE' : 'DEFAULT'
                            })) }), _jsx(Input, { placeholder: 'Enter floor name', value: floorName, setValue: setFloorName })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(FormLabel, { labelText: 'Ceiling height' }), _jsx(Input, { validator: validateIsPositiveNumber, type: 'number', value: roomHeight.toString(), setValue: (e) => setRoomHeight(Number(e)), postfix: 'm' })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(FormLabel, { labelText: 'Floor type', info: FloorTypeInfo }), _jsx(RadioGroup, { items: ['ground-floor', 'intermediate-floor-and-ceiling', 'exposed-floor'
                            ].map(x => ({
                                name: floorCeilingTypeNameMap[x],
                                onClick: () => {
                                    // mark that user has selected this attribute
                                    setUserSelectedAttributesFlags(prev => prev | USER_SELECTED_FLOOR_TYPE);
                                    // set the value
                                    setFloorMaterialType(x);
                                },
                                variant: x === floorMaterialType ? 'ACTIVE' : 'DEFAULT'
                            })) })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(FormLabel, { labelText: 'Ceiling type', info: CeilingTypeInfo }), _jsx(RadioGroup, { items: ['intermediate-floor-and-ceiling', 'roof'].map(x => ({
                                name: floorCeilingTypeNameMap[x],
                                onClick: () => {
                                    // mark that user has selected this attribute
                                    setUserSelectedAttributesFlags(prev => prev | USER_SELECTED_CEILING_TYPE);
                                    // set the value
                                    setCeilingMaterialType(x);
                                },
                                variant: x === ceilingMaterialType ? 'ACTIVE' : 'DEFAULT'
                            })) })] }), _jsx(Button
                // consider disabling button if user inputs a name of existing floor: `|| existingFloorNames.includes(floorName)`
                , { 
                    // consider disabling button if user inputs a name of existing floor: `|| existingFloorNames.includes(floorName)`
                    disabled: !floorName || !validateIsPositiveNumber(roomHeight.toString()).value, onClick: () => {
                        const newFloor = {
                            ...(floor ?? DEFAULT_SURVEY_FLOOR), // if floor is not provided, use default values
                            uuid: floor?.uuid ?? crypto.randomUUID(),
                            name: floorName,
                            default_room_height: roomHeight,
                            default_floor_material_type: floorMaterialType,
                            default_ceiling_material_type: ceilingMaterialType
                        };
                        onSave(newFloor);
                    }, children: floor ? 'Update floor' : 'Create floor' })] }) });
};
export const CeilingTypeInfo = _jsx(Info, { infoModalHeader: 'Ceiling type', infoModalBody: 'Used to determine the temperature on the other side of the ceiling and the material options for the ceiling. ' +
        'Use "Roof" if there is no room above and "Intermediate" otherwise. ' +
        'Vaulted ceiling options will only show if you select "Roof"' });
