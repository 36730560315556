import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '../../../components/content_display/text';
export const TextDemo = () => {
    return (_jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => (_jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name))) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Sizes" }), _jsxs("div", { className: 'flex flex-col justify-start items-start gap-4', children: [_jsx(Text, { size: "XS", children: "Extra small text" }), _jsx(Text, { size: "SM", children: "Small text" }), _jsx(Text, { size: "MD", children: "Medium text" }), _jsx(Text, { size: "LG", children: "Large text" })] }), _jsx("div", { children: "Variants" }), _jsxs("div", { className: 'flex flex-col justify-start items-start gap-4', children: [_jsx(Text, { children: "Default text" }), _jsx(Text, { bold: true, children: "Bold text" }), _jsx(Text, { className: "bg-orange-500 rounded underline", children: "Custom class text" })] })] })] }));
};
const componentProps = [
    { name: 'children', description: 'React.ReactNode or string to be rendered as text content' },
    { name: 'bold', description: 'Whether the text should be bold (optional)' },
    { name: 'className', description: 'Additional CSS classes to apply (optional)' },
    { name: 'size', description: 'XS | SM | MD | LG (optional, defaults to MD)' }
];
const codeBlock = `<Text
  bold={true}
  size="LG"
>
  Hello World
</Text>`;
