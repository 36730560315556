import { formatDateDDMMYYYY } from '../format_date';
export var BusApplicationStatus;
(function (BusApplicationStatus) {
    BusApplicationStatus[BusApplicationStatus["NotStarted"] = 1] = "NotStarted";
    BusApplicationStatus[BusApplicationStatus["ApplicationSubmitted"] = 2] = "ApplicationSubmitted";
    BusApplicationStatus[BusApplicationStatus["VoucherIssued"] = 3] = "VoucherIssued";
})(BusApplicationStatus || (BusApplicationStatus = {}));
export const calcBusVoucherExpirationDate = (voucherDate) => {
    const expiresAfterMonths = 3;
    return voucherDate
        ? (() => {
            const date = new Date(voucherDate);
            return formatDateDDMMYYYY(new Date(date.setFullYear(date.getFullYear(), date.getMonth() + expiresAfterMonths)));
        })()
        : undefined;
};
export var MCSApplicationStatus;
(function (MCSApplicationStatus) {
    MCSApplicationStatus[MCSApplicationStatus["NotStarted"] = 1] = "NotStarted";
    MCSApplicationStatus[MCSApplicationStatus["ApplicationSubmitted"] = 2] = "ApplicationSubmitted";
    MCSApplicationStatus[MCSApplicationStatus["CertificateUploaded"] = 3] = "CertificateUploaded";
})(MCSApplicationStatus || (MCSApplicationStatus = {}));
