import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Button } from '../../../../../components/buttons/button';
import { Modal } from '../../../../../components/containers/modal';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Section } from '../../../job_layout/components/section';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { Text } from '../../../../../components/content_display/text';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { priceCalculations } from '../../../../../code/price_calculations';
import { Icon } from '../../../../../components/buttons/icon';
import { InventoryTable } from '../inventory_table';
import { formatPrice } from '../../../../../code/format_price';
import { EmptySection } from '../empty_section';
import { Pencil, Plus, Trash, X } from 'lucide-react';
const AddPackModal = ({ visible, setVisible, addPack, heatPumps, hotWaterCylinders }) => {
    const primaryInventoryItems = [...heatPumps, ...hotWaterCylinders];
    const [name, setName] = useState('');
    const [selectedPrimaryInventoryItemIds, setSelectedPrimaryInventoryItemIds] = useState([]);
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Add pack', onConfirm: () => {
            addPack({ uuid: crypto.randomUUID(), name }, selectedPrimaryInventoryItemIds);
        }, confirmButtonLabel: 'Add', children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: 'Name', placeholder: 'Enter pack name', value: name, setValue: setName }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Always include this pack when adding' }), _jsx(Text, { size: "SM", className: 'text-gray-500', children: "The items in this pack will be automatically included when adding the following heat pumps or hot water cylinders to an estimate or quote." }), _jsx(Select, { options: primaryInventoryItems.map(item => ({ key: item.uuid, value: item.name })), selectedKey: selectedPrimaryInventoryItemIds, setSelectedKey: setSelectedPrimaryInventoryItemIds, multiple: true, filter: true })] }), _jsx("div", { className: "flex flex-row gap-2 items-center flex-wrap", children: selectedPrimaryInventoryItemIds.map(itemId => (_jsx(Badge, { color: 'LIGHT', text: primaryInventoryItems.find(item => item.uuid === itemId)?.name, button: _jsx(Icon, { icon: X, onClick: () => setSelectedPrimaryInventoryItemIds(prev => prev.filter(id => id !== itemId)) }) }, itemId))) })] }) }));
};
const EditPackModal = ({ visible, setVisible, updatePack, heatPumps, hotWaterCylinders, pack, setEditingPack }) => {
    if (!pack)
        return null;
    const primaryInventoryItems = [...heatPumps, ...hotWaterCylinders];
    const [name, setName] = useState(pack.name);
    const [selectedPrimaryInventoryItemIds, setSelectedPrimaryInventoryItemIds] = useState(primaryInventoryItems.filter(item => item.default_pack_uuid === pack.uuid).map(item => item.uuid));
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: pack ? 'Edit pack' : 'Add pack', onConfirm: () => {
            // Break the primary IDs back down into heat pump and hot water cylinder IDs
            const selectedHeatPumpIds = selectedPrimaryInventoryItemIds.filter(id => heatPumps.some(hp => hp.uuid === id));
            const selectedHotWaterCylinderIds = selectedPrimaryInventoryItemIds.filter(id => hotWaterCylinders.some(hwc => hwc.uuid === id));
            updatePack({ ...pack, name }, selectedHeatPumpIds, selectedHotWaterCylinderIds);
            setEditingPack(undefined);
        }, confirmButtonLabel: 'Save', children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: 'Name', placeholder: 'Enter pack name', value: name, setValue: setName }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Always include this pack when adding' }), _jsx(Text, { size: "SM", className: 'text-gray-500', children: "The items in this pack will be automatically included when adding the following heat pumps or hot water cylinders to an estimate or quote." }), _jsx(Select, { options: primaryInventoryItems.map(item => ({ key: item.uuid, value: item.name })), selectedKey: selectedPrimaryInventoryItemIds, setSelectedKey: setSelectedPrimaryInventoryItemIds, multiple: true, filter: true })] }), _jsx("div", { className: "flex flex-row gap-2 items-center flex-wrap", children: selectedPrimaryInventoryItemIds
                        .map(itemId => (_jsx(Badge, { color: 'LIGHT', text: primaryInventoryItems.find(item => item.uuid === itemId)?.name, button: _jsx(Icon, { icon: X, onClick: () => setSelectedPrimaryInventoryItemIds(prev => prev.filter(id => id !== itemId)) }) }, itemId))) })] }) }));
};
const PackSection = ({ pack, deletePack, addPartToPack, removePartFromPack, updatePartInPack, setEditingPack, heatPumps, hotWaterCylinders, parts }) => {
    const [selectedPartId, setSelectedPartId] = useState();
    const primaryInventoryItemsIncludingPack = heatPumps.concat(hotWaterCylinders).filter(item => item.default_pack_uuid === pack.uuid);
    const handleAddPart = (key) => {
        if (!key)
            return;
        const part = { ...parts.find(part => part.uuid === key), quantity: 1 };
        if (part) {
            addPartToPack(pack, part);
            setSelectedPartId(undefined);
        }
    };
    const handleSetPartQuantity = (part, quantity) => {
        updatePartInPack(pack, { ...part, quantity });
    };
    const partsTableColumns = [
        { key: 'name', name: 'Name' },
        { key: 'quantity', name: 'Quantity' },
        { key: 'customerPrice', name: 'Customer price', align: 'right' },
        { key: 'controls', name: '', align: 'right' }
    ];
    const partsTableRows = [
        ...(pack.parts
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(part => {
            return ({
                name: part.name,
                quantity: _jsx(Input, { type: 'number', value: part.quantity, setValue: (value) => handleSetPartQuantity(part, parseFloat(value)) }),
                customerPrice: formatPrice(priceCalculations.calculateCustomerPrice(part.cost_price, part.markup) * part.quantity),
                controls: _jsx(Icon, { icon: Trash, onClick: () => removePartFromPack(pack, part), confirmTextHeader: 'Are you sure you want to remove this part from the pack?', confirmTextBody: 'This action cannot be undone.' })
            });
        }) ?? []),
        {
            name: _jsx(Text, { size: "MD", bold: true, children: "Total" }),
            quantity: null,
            customerPrice: _jsx(Text, { size: "MD", bold: true, children: formatPrice(pack.parts?.reduce((acc, part) => acc + priceCalculations.calculateCustomerPrice(part.cost_price, part.markup) * part.quantity, 0) ?? 0) }),
            controls: null
        }
    ];
    return (_jsx(Section, { title: _jsxs("div", { className: 'flex items-center justify-between gap-3 w-full', children: [_jsx(Heading, { size: "xl", children: pack.name }), _jsx(DropdownMenu, { items: [
                        { label: 'Edit', onClick: () => setEditingPack(pack), icon: Pencil },
                        { label: 'Delete', onClick: () => deletePack(pack), icon: Trash, confirmText: 'Are you sure you want to delete this pack?' }
                    ] })] }), children: _jsxs("div", { className: "flex flex-col gap-3 items-start", children: [_jsx("div", { className: "flex flex-row gap-2 items-center flex-wrap", children: primaryInventoryItemsIncludingPack.length > 0 &&
                        _jsxs(_Fragment, { children: [_jsx(Text, { size: "SM", bold: true, children: "Included with:" }), primaryInventoryItemsIncludingPack
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(item => (_jsx(Badge, { color: 'LIGHT', text: item.name }, item.uuid)))] }) }), pack.parts?.length
                    ? _jsx(InventoryTable, { columns: partsTableColumns, rows: partsTableRows })
                    : _jsx(EmptySection, { title: "No parts added", description: "Add parts to this pack to automatically add them to estimates and quotes." }), _jsx(Select, { placeholder: 'Add part', options: parts
                        .filter(part => !pack.parts?.some(packPart => packPart.uuid === part.uuid))
                        .map(part => ({ key: part.uuid, value: `${part.name} - ${formatPrice(priceCalculations.calculateCustomerPrice(part.cost_price, part.markup))}` })), selectedKey: selectedPartId, setSelectedKey: handleAddPart, filter: true })] }) }));
};
export const PacksInventory = ({ addPack, deletePack, updatePack, addPartToPack, removePartFromPack, updatePartInPack, packs, parts, heatPumps, hotWaterCylinders }) => {
    const [addPackModalVisible, setAddPackModalVisible] = useState(false);
    const [editingPack, setEditingPack] = useState();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "space-y-6", children: [_jsxs("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full", children: [_jsxs("div", { className: 'flex flex-col gap-1 w-full lg:w-3/4', children: [_jsx(Heading, { size: "2xl", children: "Packs" }), _jsx(Text, { children: "Packs allow you to link one or more items to an inventory item. For example, you can create a pack for a specific heat pump and include that pack every time you include the heat pump on a quote." })] }), _jsx(Button, { colour: 'DARK', onClick: () => setAddPackModalVisible(true), iconLeft: Plus, children: "Add pack" })] }), !packs.length
                        ? _jsx(EmptySection, { title: "Create a parts pack", description: "Add packs to automatically add parts to estimates and quotes.", button: _jsx(Button, { colour: 'DARK', onClick: () => setAddPackModalVisible(true), iconLeft: Plus, children: "Add pack" }) })
                        : packs
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(pack => _jsx(PackSection, { pack: pack, heatPumps: heatPumps, hotWaterCylinders: hotWaterCylinders, deletePack: deletePack, parts: parts, addPartToPack: addPartToPack, removePartFromPack: removePartFromPack, updatePartInPack: updatePartInPack, setEditingPack: setEditingPack }, pack.uuid))] }), _jsx(AddPackModal, { addPack: addPack, visible: addPackModalVisible || !!editingPack, setVisible: setAddPackModalVisible, heatPumps: heatPumps, hotWaterCylinders: hotWaterCylinders }), _jsx(EditPackModal, { visible: !!editingPack, setVisible: () => setEditingPack(undefined), heatPumps: heatPumps, hotWaterCylinders: hotWaterCylinders, updatePack: updatePack, pack: editingPack, setEditingPack: setEditingPack })] }));
};
