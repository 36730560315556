import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Header } from '../components/header';
import { SurveyActionType, SurveyPagesEnum } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { DataLoaderContext } from '../components/data_loader_context';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { Select } from '../../../components/inputs_and_selections/select';
import { checkIfScottish, findAddresses, getAddressForUdprn, parseAddressData, parseAddressDataForManualEntry } from '../../../code/models/address';
import { getEpcForUprn, getEpcScottishForUprn, parseEnglishEpc } from '../../../code/models/epc';
import { useDebounceCallback } from 'usehooks-ts';
import { Link } from '../../../components/buttons/link';
import { parseScottishEpc } from '../../../code/models/epc_scotland';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
export const ChooseAddressStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [selectedAddress, setSelectedAddress] = useState();
    const [addressList, setAddressList] = useState([]);
    // used to flag that we load some data from the server
    const { loadingData, setLoadingData } = useContext(DataLoaderContext);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const handleNavigateNextPage = async () => {
        setLoadingData(true);
        let lead = survey.lead;
        try {
            const udprn = addressList.find((x) => x.key === selectedAddress)?.key;
            const address = await getAddressForUdprn(udprn);
            if (address) {
                lead = parseAddressData(address, survey.lead);
                const isScottish = checkIfScottish(lead.property.postcode);
                if (isScottish) {
                    const epc = await getEpcScottishForUprn(address.uprn);
                    if (epc) {
                        lead = await parseScottishEpc(epc, lead);
                    }
                }
                else {
                    const epc = await getEpcForUprn(address.uprn);
                    if (epc) {
                        lead = await parseEnglishEpc(epc, lead);
                    }
                }
            }
            dispatch({
                type: SurveyActionType.NavigateNextPage,
                payload: {
                    lead
                }
            });
        }
        catch (e) {
            // We've had a problem fetching the detailed address data, so we can't fetch the EPC for this address.
            // Drop back to the manual address entry page.
            console.error(e);
            dispatch({
                type: SurveyActionType.NavigateToPageFromParams,
                payload: {
                    page: SurveyPagesEnum.ManualAddressStep
                }
            });
        }
        finally {
            setLoadingData(false);
        }
    };
    const handleOnNavigateManualAddressPage = async () => {
        try {
            // Try and get as much data as possible from the address lookup for the manual address entry
            let manualEntryAddress = {};
            if (selectedAddress) {
                const udprn = addressList.find((x) => x.key === selectedAddress)?.key;
                const address = await getAddressForUdprn(udprn);
                if (address) {
                    manualEntryAddress = parseAddressDataForManualEntry(address);
                }
            }
            dispatch({
                type: SurveyActionType.NavigateToPageFromParams,
                payload: {
                    page: SurveyPagesEnum.ManualAddressStep,
                    address: manualEntryAddress
                }
            });
        }
        catch (e) {
            console.error(e);
            // If we can't get the address data, just navigate to the manual address entry page without any data
            dispatch({
                type: SurveyActionType.NavigateToPageFromParams,
                payload: {
                    page: SurveyPagesEnum.ManualAddressStep
                }
            });
        }
    };
    const searchForAddresses = async (query) => {
        try {
            if (!query || query.length < 3) {
                setAddressList([]);
                setLoadingAutocomplete(false);
                return;
            }
            const addresses = await findAddresses(query);
            if (addresses?.length) {
                setAddressList(addresses.map((x) => ({ key: x.udprn, value: x.suggestion })));
            }
            else {
                setAddressList([{ key: 'enter-address-manually', value: 'Enter address manually' }]);
            }
            setLoadingAutocomplete(false);
        }
        catch (e) {
            console.error(e);
            setLoadingAutocomplete(false);
            setAddressList([{ key: 'enter-address-manually', value: 'Enter address manually' }]);
        }
    };
    const debouncedSearch = useDebounceCallback(searchForAddresses, 500);
    const handleOnSearch = async (query) => {
        setLoadingAutocomplete(true);
        debouncedSearch(query);
    };
    const handleSetSelectedAddress = async (key) => {
        if (key === 'enter-address-manually') {
            return await handleOnNavigateManualAddressPage();
        }
        setSelectedAddress(key);
    };
    return _jsxs(_Fragment, { children: [(survey.pagesStack.length > 0 && adminEstimate) && _jsx(QuestionnaireNavigateBack, {}), _jsx("div", { className: "flex flex-col gap-3", children: adminEstimate ? _jsx(_Fragment, { children: _jsx(Header, { content: "Property location" }) })
                    : _jsxs(_Fragment, { children: [_jsx(Header, { content: "Request a heat pump estimate" }), _jsx("div", { className: "text-gray-600", children: "Get a personalised cost estimate by providing a few details about you and your property." }), _jsx("div", { className: 'text-gray-600', children: "Estimated time: 2 minutes." })] }) }), _jsxs("div", { className: "space-y-3", children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Find address' }), input: _jsx(Select, { size: 'LG', placeholder: "Start typing your address or postcode", options: addressList, selectedKey: selectedAddress, setSelectedKey: handleSetSelectedAddress, filter: true, filterCallback: handleOnSearch, isLoading: loadingAutocomplete, autoFocus: true }) }), _jsx(Link, { text: 'Enter address manually', onClick: handleOnNavigateManualAddressPage })] }), _jsx(Button, { size: 'LG', disabled: loadingData || !selectedAddress, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-5 w-5" })] }) })] });
};
