import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Icon } from '../buttons/icon';
import { ClickableCard } from '../content_display/card';
import { Alert } from '../indicators_and_messaging/alert';
import { Text } from '../content_display/text';
import { Upload } from 'lucide-react';
export const FileDropzone = ({ helperText, maxSizeMB = 2, onChange, ...inputProps }) => {
    const [error, setError] = useState(null);
    const handleFileInputChange = (event) => {
        // TODO - This does not yet handle multiple files!
        setError(null);
        const file = event.target.files[0];
        if (!file)
            return;
        if (file.size > maxSizeMB * 1024 * 1024) {
            setError(`File size exceeds the maximum limit of ${maxSizeMB}MB`);
            return;
        }
        onChange(event.target.files);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ClickableCard, { variant: "PLACEHOLDER", className: `hover:bg-gray-100 file:hover:bg-gray-100 relative ${error ? 'border-solid border-red-600' : ''}`, children: _jsxs("label", { htmlFor: "dropzone-file", className: "flex flex-col justify-center items-center text-center py-6 gap-4", children: [_jsx(Icon, { icon: Upload, className: "size-8" }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx(Text, { className: "inline", children: "Click to upload, or drag and drop" }), _jsxs(Text, { size: "SM", className: 'text-light', children: [helperText && `${helperText} - `, "Max ", maxSizeMB, "MB"] })] }), _jsx("input", { id: "dropzone-file", type: "file", className: "absolute w-full h-full top-0 left-0 cursor-pointer opacity-0", onChange: handleFileInputChange, ...inputProps })] }) }), error && _jsx(Alert, { type: 'DANGER', children: error })] }));
};
