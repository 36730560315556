import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { Section } from '../../../../components/containers/section';
import { Button } from '../../../../components/buttons/button';
import { Text } from '../../../../components/content_display/text';
import { db } from '../../../heat_loss/db';
export const AccountTools = () => {
    const [databaseExists, setDatabaseExists] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    useEffect(() => {
        const main = async () => {
            const check = await checkDatabaseExists('spruce');
            setDatabaseExists(check);
            const fileCount = await db.files.count();
            setFileCount(fileCount);
        };
        main();
    }, []);
    return _jsx(SettingsLayout, { heading: "Developer tools", description: "", children: _jsx(Section, { border: true, title: 'Reset local database', children: _jsxs("div", { className: "flex flex-col gap-6", children: [_jsx(Text, { size: 'SM', children: "Warning, this will delete any local data on your device that has not synced with the server. Use this only if your device is failing to sync with the server and you are happy with any local data loss." }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("div", { children: _jsx(Button, { confirmText: "Reset local database", disabled: !databaseExists, onClick: () => {
                                        const request = indexedDB.deleteDatabase('spruce');
                                        request.onsuccess = () => {
                                            setDatabaseExists(false);
                                            localStorage.setItem('last_synced', '0');
                                        };
                                    }, children: "Reset local database" }) }), _jsx("div", { children: _jsx(Button, { confirmText: "Reset images", disabled: fileCount === 0, onClick: async () => {
                                        await db.files.clear();
                                        setFileCount(0);
                                    }, children: "Reset images" }) })] })] }) }) });
};
const checkDatabaseExists = (dbName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);
        request.onsuccess = function (event) {
            event.target.result.close();
            resolve(true);
        };
        request.onupgradeneeded = function (event) {
            event.target.transaction.abort();
            resolve(false);
        };
        request.onerror = function () {
            reject(request.error);
        };
    });
};
