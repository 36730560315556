import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { SubHeader } from '../components/sub_header';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const ScottishGrantStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [selectedIsInTouchOption, setSelectedIsInTouchOption] = useState();
    const [selectedReasonOption, setSelectedReasonOption] = useState();
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                scottishGrantIsInTouch: selectedIsInTouchOption,
                scottishGrantReason: selectedReasonOption
            }
        });
    };
    const NoKey = 'No';
    const YesKey = 'Yes';
    function buildOption(value) {
        return { key: value, label: value };
    }
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: `${adminEstimate ? 'Has the homeowner' : 'Have you'} been in touch with Home Energy Scotland?`, captionText: adminEstimate ? 'Select the response that best suits the homeowner\'s situation.' : undefined }), _jsx(SurveyOptionGroup, { options: [
                    { key: NoKey, label: 'No' },
                    { key: YesKey, label: 'Yes' }
                ], onSelect: (key) => { setSelectedIsInTouchOption(key); setSelectedReasonOption(undefined); }, selectedOptionKey: selectedIsInTouchOption }), selectedIsInTouchOption !== undefined &&
                _jsx(SubHeader, { content: "More detail ..." }), selectedIsInTouchOption === NoKey &&
                _jsx(SurveyOptionGroup, { options: [
                        buildOption('It\'s on my list to do'),
                        buildOption('I\'m not sure what that means?'),
                        buildOption('I\'m happy to proceed without a grant')
                    ], onSelect: (key) => { setSelectedReasonOption(key); }, selectedOptionKey: selectedReasonOption }), selectedIsInTouchOption === YesKey &&
                _jsx(SurveyOptionGroup, { options: [
                        buildOption('I\'m in contact with my local Home Energy Specialist'),
                        buildOption('I\'ve received a Home Energy Improvement Report'),
                        buildOption('I\'ve submitted my application for funding')
                    ], onSelect: (key) => { setSelectedReasonOption(key); }, selectedOptionKey: selectedReasonOption }), _jsx(Button, { size: 'LG', disabled: !selectedIsInTouchOption || !selectedReasonOption, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
