import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { Heading } from '../../../../components/content_display/heading';
import { ClickableCard } from '../../../../components/content_display/card';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { MCSApplicationStatus } from '../../../../code/models/paperwork';
import { TableLite } from '../../../../components/content_display/table_lite';
import _ from 'lodash';
export const PostInstallPage = (props) => {
    const mcsApplicationStatus = props.lead.mcs_application_status ?? MCSApplicationStatus.NotStarted;
    return (_jsx("div", { children: _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsxs(Alert, { type: 'INFO', children: ["Paperwork is in beta: Test this new feature for free and send us your feedback!", _jsx("br", {}), "Any usage during this testing period won't count against your credits."] }), _jsx(Heading, { size: 'xl', children: "Post-Install" }), _jsx(ClickableCard, { variant: 'WHITE', onClick: () => props.navigateTo('/paperwork/post-install/mcs'), title: _jsxs("div", { className: 'flex flex-row gap-4 w-full justify-between', children: [_jsx("span", { children: "MCS certificate" }), mcsApplicationStatus === MCSApplicationStatus.NotStarted && _jsx(Badge, { color: 'LIGHT', text: 'Not started' }), mcsApplicationStatus === MCSApplicationStatus.ApplicationSubmitted &&
                                _jsx(Badge, { color: 'YELLOW', text: 'Application submitted' }), mcsApplicationStatus === MCSApplicationStatus.CertificateUploaded &&
                                _jsx(Badge, { color: 'GREEN', text: 'Certificate uploaded' })] }), children: _jsx("div", { className: 'max-w-96', children: _jsx(TableLite, { size: 'SM', hasHeader: false, alignRight: true, columns: [
                                { key: 'title', name: '' },
                                { key: 'value', name: '' }
                            ], rows: _.compact([
                                mcsApplicationStatus === MCSApplicationStatus.CertificateUploaded
                                    ? { title: 'Certificate number', value: props.lead.mcs_certificate_number || 'n/a' }
                                    : null
                            ]) }) }) })] }) }));
};
