import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { proxyImageURL } from '../../../code/helpers';
import { HLRContext } from './heatloss_report_page';
export const HLRTitlePage = () => {
    const hlrContext = React.useContext(HLRContext);
    const reportGenerationDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    const preparedFor = [
        `${hlrContext.customer.first_name ?? ''}${hlrContext.customer.last_name ? ` ${hlrContext.customer.last_name}` : ''}`,
        hlrContext.property.address,
        hlrContext.property.postcode
    ];
    const preparedBy = [
        hlrContext.company_public_info.estimate_contact_name,
        hlrContext.company_public_info.name,
        hlrContext.company_public_info.notification_email,
        hlrContext.company_public_info.phone
    ];
    const propertyPhotos = hlrContext.files.filter(x => hlrContext.survey.property_images.some(y => y.image_uuid === x.uuid));
    return (_jsxs("div", { className: "flex-col gap-16 flex", children: [_jsxs("div", { className: "flex-col gap-9 flex", children: [_jsxs("div", { className: "flex-col gap-1 flex", children: [_jsx("div", { className: "", children: reportGenerationDate }), _jsxs("div", { className: "self-stretch text-gray-900 lg:text-6xl text-3xl font-bold", children: ["Heat Loss Report", _jsx("br", {}), "& System Design"] })] }), _jsxs("div", { className: "w-full flex", children: [_jsxs("div", { className: "w-1/2 flex-col gap-3 flex", children: [_jsx("div", { className: "text-gray-900 text-lg font-bold", children: "Prepared for" }), _jsx("div", { className: "flex-col gap-1 flex", children: preparedFor.map((x, i) => (_jsx("div", { className: "", children: x }, 'preparedFor-' + i))) })] }), _jsxs("div", { className: "w-1/2 flex-col gap-3 flex", children: [_jsx("div", { className: "text-gray-900 text-lg font-bold", children: "Prepared by" }), _jsx("div", { className: "flex-col gap-1 flex", children: preparedBy.map((x, i) => (_jsx("div", { className: "", children: x }, 'preparedBy' + i))) })] })] })] }), _jsx("div", { className: "text-xs tracking-tight", children: [hlrContext.company_public_info.name, hlrContext.company_public_info.address, hlrContext.company_public_info.phone]
                    .filter(x => x.trim() !== '')
                    .join(', ') }), propertyPhotos.length > 0 &&
                _jsx("img", { className: "max-h-96 max-w-full object-cover", src: propertyPhotos[0].file_base64 || proxyImageURL('x384', propertyPhotos[0].file_url ?? ''), alt: '' })] }));
};
