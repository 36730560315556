import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '../../../components/indicators_and_messaging/alert';
export const AlertDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Alert, { type: 'WARNING', children: "Anything can be here" })] })] });
};
const componentProps = [
    { name: 'type', description: 'WARNING (only this variation exists)' }
];
const codeBlock = `<Alert
  type='WARNING'
>Anything can be here</Alert>`;
