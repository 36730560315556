import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Icon } from '../../../components/buttons/icon';
import { Square } from 'lucide-react';
export const RadioDemo = () => {
    const [selectedRadio, setSelectedRadio] = useState();
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Default" }), _jsx("div", { children: _jsx(RadioGroup, { items: [
                                { name: 'One', description: undefined, icon: undefined, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Two', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Three', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                            ], isVertical: false }) }), _jsx("div", { children: "Vertical" }), _jsx(RadioGroup, { items: [
                            { name: 'One', description: 'Mini description', icon: undefined, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Two', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Three', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                        ], isVertical: true }), _jsx("div", { children: "With icon" }), _jsx(RadioGroup, { items: [
                            { name: 'One', icon: _jsx(Icon, { icon: Square }), onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Two', icon: _jsx(Icon, { icon: Square }), onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Three', icon: _jsx(Icon, { icon: Square }), onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                        ] })] })] });
};
const componentProps = [
    { name: 'isVertical', description: 'Defaults to horizontal, will display each item vertically if true' },
    { name: 'items->name', description: 'The name of the radio item' },
    { name: 'items->info', description: 'Show an info icon next to the name, this should be an <Info /> component' },
    { name: 'items->description', description: 'The secondary text showing below the name if required' },
    { name: 'items->icon', description: 'Should we show an icon to the left of the primary text, should be <Icon /> component' },
    { name: 'items->onClick', description: 'What should happen when the radio is clicked' },
    { name: 'items->variant', description: 'ACTIVE | DEFAULT' },
    { name: 'items->rightContent', description: 'Should anything show in the right section of the radio item, just before the checkmark' }
];
const codeBlock = `<RadioGroup
  items={[
    { name: 'One', icon: <Icon icon={Square} />, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
    { name: 'Two', icon: <Icon icon={Square} />, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
    { name: 'Three', icon: <Icon icon={Square} />, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
  ]}
/>`;
