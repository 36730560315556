/** Estimate the floor area of a property based on the property type, built form, age band, and number of beds
 * To be used in the estimate tool flow in the case we don't have the EPC. We'll have to change the order of the
 * questions to ask the user for age band and number of beds before we ask about floor area
 */
// TODO - We don't currently have the correct ageBandId in the enquiry flow. We're defaulting to the 'All' age band for now by not using ageBandId. The FLOOR_AREA_BRACKETS.floorAreaAgeBand <-> ageBandId mapping is not correct.
export const estimateFloorAreaM2 = (propertyType, builtForm, ageBandId, numberOfBeds) => {
    const areasThisPropertyType = FLOOR_AREA_BRACKETS.filter(bracket => bracket.propertyType === propertyType);
    //   Currently only have 'All' in built form when no distinction between built form for the property type, so ok to just blanket include all Alls
    const areasThisBuiltForm = areasThisPropertyType.filter(bracket => bracket.builtForm === builtForm || bracket.builtForm === 'All');
    const ageBandToUse = ageBandId || 'All';
    const areasThisAgeBand = areasThisBuiltForm.filter(bracket => bracket.floorAreaAgeBand === ageBandToUse);
    if (areasThisAgeBand.length === 0) {
        return { floorAreaM2: 0, justification: `No typical floor area found for ${propertyType} ${builtForm} ${ageBandId} ${numberOfBeds} beds` };
    }
    // If only one result use that, otherwise look for the closest number of beds
    const areaBracket = areasThisAgeBand.length === 1
        ? areasThisAgeBand[0]
        : areasThisAgeBand.reduce((prev, curr) => Math.abs(curr.numberOfBeds - numberOfBeds) < Math.abs(prev.numberOfBeds - numberOfBeds) ? curr : prev);
    if (!areaBracket) {
        // raise sentry error in this case as this shouldn't happen
        return { floorAreaM2: 0, justification: `No typical floor area found for ${propertyType} ${builtForm} ${ageBandId} ${numberOfBeds} beds` };
    }
    const ageBandJustification = areaBracket.floorAreaAgeBand === 'All' ? '' : ` ${ageBandToUse.toLowerCase()}`;
    const numberOfBedsJustification = areaBracket.numberOfBeds === 0 ? '' : ` ${areaBracket.numberOfBeds} bed`;
    const builtFormJustification = areaBracket.builtForm === 'All' ? '' : ` ${builtForm.toLowerCase()}`;
    const justification = `This is a typical floor area for a${ageBandJustification}${numberOfBedsJustification}${builtFormJustification} ${areaBracket.propertyType.toLowerCase()}`;
    return { floorAreaM2: areaBracket.floorAreaM2, justification };
};
// This data is compiled from the english housing survey and is just a set of averages based on these attributes
// The houses and flats data is from: https://assets.publishing.service.gov.uk/media/5b45fd6ee5274a3773e665a3/Floor_Space_in_English_Homes_technical_report.pdf
// The bungalow data is from: https://assets.publishing.service.gov.uk/media/5b4750e6e5274a3770774693/Floor_Space_in_English_Homes_main_report.pdf
// For full references for each line see this spreadsheet (excel only):
// https://docs.google.com/spreadsheets/d/1jjfiJX9fy6581avcMeudqvZiyeTMM4a2/edit?usp=share_link&ouid=100274869301631781793&rtpof=true&sd=true
export const FLOOR_AREA_BRACKETS = [
    { floorAreaAgeBand: 'pre 1919', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 105 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 74 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 73 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 81 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 87 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 73 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 70 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 69 },
    { floorAreaAgeBand: 'All', propertyType: 'Bungalow', builtForm: 'All', numberOfBeds: 0, floorAreaM2: 77 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 50 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 46 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 44 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 47 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 44 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 42 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 45 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 46 },
    { floorAreaAgeBand: 'All', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 1, floorAreaM2: 46 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 77 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 61 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 65 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 66 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 62 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 62 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 60 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 69 },
    { floorAreaAgeBand: 'All', propertyType: 'Flat', builtForm: 'All', numberOfBeds: 2, floorAreaM2: 67 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 89 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 79 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 76 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 78 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 76 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 70 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 71 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 71 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 2, floorAreaM2: 77 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 150 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 123 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 121 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 110 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 107 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 104 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 104 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 129 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 3, floorAreaM2: 116 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 242 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 176 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 176 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 159 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 153 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 151 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 158 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 213 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Detached', numberOfBeds: 4, floorAreaM2: 177 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 73 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 72 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 68 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 72 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 68 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 62 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 64 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 66 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 2, floorAreaM2: 70 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 93 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 83 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 83 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 85 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 81 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 71 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 72 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 92 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'End-Terrace', numberOfBeds: 3, floorAreaM2: 86 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 73 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 72 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 68 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 72 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 68 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 62 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 64 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 66 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 2, floorAreaM2: 70 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 93 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 83 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 83 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 85 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 81 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 71 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 72 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 92 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Mid-Terrace', numberOfBeds: 3, floorAreaM2: 86 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 89 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 79 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 76 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 78 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 76 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 70 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 71 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 71 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 2, floorAreaM2: 77 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 110 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 91 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 91 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 89 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 83 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 82 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 80 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 86 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 3, floorAreaM2: 91 },
    { floorAreaAgeBand: 'pre 1919', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 174 },
    { floorAreaAgeBand: '1919-1944', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 136 },
    { floorAreaAgeBand: '1945-1964', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 126 },
    { floorAreaAgeBand: '1965-1974', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 122 },
    { floorAreaAgeBand: '1975-1980', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 122 },
    { floorAreaAgeBand: '1981-1990', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 99 },
    { floorAreaAgeBand: '1991-2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 127 },
    { floorAreaAgeBand: 'post 2002', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 118 },
    { floorAreaAgeBand: 'All', propertyType: 'House', builtForm: 'Semi-Detached', numberOfBeds: 4, floorAreaM2: 140 }
];
