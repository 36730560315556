import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const SiteDetailsMainsWaterPhotosBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey, files, setFiles, companyUUID } = prContext;
    const stopcockImagesHydrated = survey.stopcock_images.map(x => files.find(y => y.uuid === x.image_uuid));
    const setStopcockImages = (images) => setSurvey({ ...survey, stopcock_images: images });
    const isFullScreen = stopcockImagesHydrated.length === 0 && flowContext.viewMode === 'FLOW';
    return _jsx(_Fragment, { children: _jsxs("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4', children: [_jsxs("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`, children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Mains water" }), _jsx("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`, children: "Add photos of the incoming water main and stopcock" })] }), _jsxs("div", { className: "flex-col gap-3 flex", children: [!isFullScreen &&
                            _jsx("div", { className: 'flex justify-between', children: _jsx("div", { className: "text-gray-900 font-bold", children: "Photos" }) }), _jsx(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: stopcockImagesHydrated, addImage: (image) => {
                                setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                                const newImage = { uuid: crypto.randomUUID(), image_uuid: image.uuid, entity_uuid: 'survey', entity_attribute: 'stopcock_images' };
                                setStopcockImages([...survey.stopcock_images, newImage]);
                            }, deleteImage: (imageUUID) => {
                                setStopcockImages(survey.stopcock_images.filter(x => x.image_uuid !== imageUUID));
                            }, companyUUID: companyUUID })] })] }) });
};
