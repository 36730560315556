import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { insertCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { getCompanyEstimateCoverNote, getCompanyEstimateEmailTemplate } from '../../../../../code/models/company';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../../../code/tiptap_placeholders';
import { numberFormat } from '../../../../../code/number_format';
import { formatPrice } from '../../../../../code/format_price';
import { Modal } from '../../../../../components/containers/modal';
import { logEvent } from '../../../../../code/log_event';
import { sendEstimate } from '../../../../../code/models/lead';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { calculateQuote } from '../../../../../code/calculate_quote';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../../../code/calculate_estimate';
import { sum } from 'lodash';
import { Text } from '../../../../../components/content_display/text';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { ContactCardWrapper } from '../../../../estimate_page';
import { Heading } from '../../../../../components/content_display/heading';
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators';
import { TabGroup } from '../../../../../components/content_display/tab';
import { createJobEvent, JobEventType } from '../../../../../code/models/job_event';
import { calculateSumIncludingVatOnSurvey } from '../sections/cost_breakdown';
export const SendEstimateModalBlock = ({ setEstimates, setIsVisible, adminContext, estimate, calculatedEstimate, lead, setSnackbarText, stages, setLead, company }) => {
    const [customCoverNoteJSON, setCustomCoverNoteJSON] = useState();
    const [customEmailJSON, setCustomEmailJSON] = useState();
    const [overrideCoverNoteJSON, setOverrideCoverNoteJSON] = useState(false); // Flag to mark when cover note is edited
    const [currentTab, setCurrentTab] = useState('email');
    const [calculatedQuote] = calculateQuote({
        company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    useEffect(() => {
        // At the point of opening the modal, we regenerate the estimate email and cover note content.
        const companyEstimateEmailTemplate = getCompanyEstimateEmailTemplate(company.estimate_template);
        const renderedEmailJSON = renderJSONReplacingPlaceholders(companyEstimateEmailTemplate, {
            ...defaultPlaceholderMappings(company.public_info, adminContext.data.user),
            [TTPlaceholderCustomerName.id]: calculatedEstimate.first_name,
            [TTPlaceholderCustomerFirstName.id]: calculatedEstimate.first_name,
            [TTPlaceholderCustomerLastName.id]: calculatedEstimate.last_name,
            [TTPlaceholderCustomerAddress.id]: `${calculatedEstimate.address}, ${calculatedEstimate.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: calculatedEstimate.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(calculatedEstimate.co2_saved_kg)
        });
        const companyEstimateCoverNote = getCompanyEstimateCoverNote(company.public_info.calculated_estimate_cover_note);
        const renderedEstimateCoverNoteJSON = renderJSONReplacingPlaceholders(companyEstimateCoverNote, {
            ...defaultPlaceholderMappings(company.public_info, adminContext.data.user)
        });
        setCustomEmailJSON(renderedEmailJSON);
        setCustomCoverNoteJSON(renderedEstimateCoverNoteJSON);
    }, []);
    const companyUUID = company.public_info.uuid;
    const insertEstimate = async (setEstimates, companyUUID, estimate, lead) => {
        await insertCalculatedEstimate(estimate, calculatedQuote, companyUUID);
        setEstimates(prev => [...prev, estimate]);
        setSnackbarText('Estimate sent');
    };
    return _jsx(Modal, { title: 'Send estimate', visible: true, setVisible: setIsVisible, onConfirm: async () => {
            logEvent({
                name: 'Send Estimate Clicked',
                properties: {}
            }, company.public_info?.subdomain ?? '');
            const estimateToSend = {
                ...calculatedEstimate,
                status: 'Sent',
                created_at: new Date().toString(),
                uuid: crypto.randomUUID(),
                cover_note: overrideCoverNoteJSON ? JSON.stringify(customCoverNoteJSON) : undefined
            };
            const emailHTML = renderHTMLReplacingPlaceholders(customEmailJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + company?.public_info?.subdomain + '/estimate/' + estimateToSend.uuid
            });
            // Save the custom cover note to the estimate and store it.
            await insertEstimate(setEstimates, companyUUID, estimateToSend, lead);
            await sendEstimate({ leadUUID: lead.uuid, companyUUID: lead.company_uuid, emailHTML });
            const statuses = stages.flatMap(x => x.statuses);
            const oldStatus = statuses.find(x => x.uuid === lead.status_uuid);
            const newStatus = statuses.find(x => x.code === 'EstimateSent');
            setLead({ ...lead, status_uuid: newStatus.uuid }, adminContext.data.user);
            await createJobEvent({
                event_type: JobEventType.enum.EstimateSent,
                company_uuid: companyUUID,
                job_uuid: lead.uuid,
                extra_data: {
                    calculated_estimate_uuid: estimateToSend.uuid,
                    email_content: emailHTML,
                    recipients: [lead.customer?.email]
                }
            });
            if (oldStatus?.uuid !== newStatus?.uuid) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyUUID,
                    job_uuid: lead.uuid,
                    extra_data: {
                        old_status: oldStatus?.uuid,
                        new_status: newStatus?.uuid
                    }
                });
            }
            // setLead({ ...lead, status: 'Quoted' })
        }, confirmButtonLabel: "Send", children: _jsxs("div", { className: 'flex flex-col gap-4 h-full w-full', children: [_jsxs(Text, { size: 'MD', children: ["This will send an email to ", _jsx("span", { className: "font-bold", children: lead?.customer?.email }), " with a link to this ", _jsx("span", { className: "font-bold", children: formatPrice(sum(calculatedQuote.map(calculateSumIncludingVatOnSurvey))) }), " estimate."] }), _jsx(TabGroup, { items: [
                        { name: 'Email', onClick: () => setCurrentTab('email'), variant: currentTab === 'email' ? 'ACTIVE' : 'DEFAULT' },
                        { name: 'Cover note', onClick: () => setCurrentTab('cover_note'), variant: currentTab === 'cover_note' ? 'ACTIVE' : 'DEFAULT' }
                    ] }), (currentTab === 'email' && customEmailJSON) &&
                    _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Homeowner Email', helperText: 'Customise the email sent to the homeowner.' }), input: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                                setCustomEmailJSON(editor.getJSON());
                            }, placeholders: [
                                TTPlaceholderViewEstimate
                            ], content: customEmailJSON }) }), (currentTab === 'cover_note' && customCoverNoteJSON) && _jsxs(_Fragment, { children: [_jsx(FormLabel, { labelText: 'Estimate cover note', helperText: 'Customise the cover note content shown on this estimate.' }), _jsxs("div", { className: 'w-full flex flex-col gap-5', children: [_jsxs(Heading, { size: "lg", children: ["Hi ", calculatedEstimate.first_name, "!"] }), _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: (editor) => {
                                        setCustomCoverNoteJSON(editor.getJSON());
                                        if (!overrideCoverNoteJSON) {
                                            setOverrideCoverNoteJSON(true);
                                        }
                                    }, content: customCoverNoteJSON }), _jsx(ContactCardWrapper, { hasContent: Boolean(company.public_info.estimate_contact_name || company.public_info.estimate_contact_phone || company.public_info.estimate_contact_role || company.public_info.estimate_contact_portrait), children: _jsxs("div", { className: 'flex justify-start items-center pt-1 gap-4', children: [company.public_info.estimate_contact_portrait && _jsx("img", { src: company.public_info.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsxs("div", { children: [_jsx(Text, { bold: true, size: 'MD', children: company.public_info.estimate_contact_name }), _jsx(Text, { size: 'SM', children: company.public_info.estimate_contact_role })] }), _jsx("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${company.public_info.estimate_contact_phone}`, children: company.public_info.estimate_contact_phone })] })] }) })] })] })] }) });
};
