import { axiosGetV2 } from '../axios';
import { getBuiltForm, getConstructionAgeScotland, getFloorInsulation, getLoftInsulation, getPropertyType, getUValue, getWallGroup, getWallType, getWindowTypeScotland } from './u_value';
import { parseConstructionAgeBand } from './age_bands';
import { tryParseFloat } from '../helpers';
import { getFuelTypeFromEPC } from './fuel';
import { getNumberofBathroomsFromBedrooms, getNumberOfBedroomsFromHabitableRooms } from './estimated_rooms_and_radiators';
import { z } from 'zod';
import { EPCRatingSchema } from './epc';
export const EpcScotlandSchema = z.object({
    uprn: z.string(),
    construction_age_band: z.string(),
    current_energy_rating: EPCRatingSchema,
    address1: z.string(),
    address2: z.string(),
    address3: z.string(),
    postcode: z.string(),
    total_floor_area: z.number(),
    floor_height: z.number(),
    built_form: z.string(),
    property_type: z.string(),
    main_fuel: z.string(),
    floor_description: z.string(),
    roof_description: z.string(),
    windows_description: z.string(),
    wall_description: z.string(),
    number_heated_rooms: z.number(),
    number_habitable_rooms: z.number(),
    transaction_type: z.string(),
    inspection_date: z.string(),
    improvements: z.string(),
    alternative_improvements: z.string()
});
export const getEpcsScotlandForPostcode = async (postcode) => {
    return await axiosGetV2(`epcs/scotland?postcode=${postcode}`);
};
export const getEpcScotlandByUPRN = async (uprn) => {
    return await axiosGetV2(`epcs/scotland/${uprn}`);
};
export const parseScottishEpc = async (epc, lead) => {
    if (!epc)
        return lead;
    const newLead = { ...lead };
    newLead.epc_scotland_id = epc.uprn;
    newLead.epc_scotland = epc;
    newLead.property = parseScottishEpcInner(epc, newLead.property) || newLead.property;
    // For scottish properties the EPC recommendations are stored in the EPC data on the "Improvements"
    // We should standardise these at some point so stored in the same structure on our side, but leaving for now
    newLead.epc_recommendations = [];
    return newLead;
};
export const parseScottishEpcInner = (epc, property) => {
    if (!epc)
        return;
    const wallGroup = getWallGroup(epc.wall_description);
    const constructionAge = getConstructionAgeScotland(epc);
    const parsedConstructionAgeBand = parseConstructionAgeBand(constructionAge);
    const noBedrooms = getNumberOfBedroomsFromHabitableRooms(epc.number_habitable_rooms);
    return {
        ...property,
        yearBuilt: constructionAge,
        construction_age_band_uuid: parsedConstructionAgeBand?.uuid,
        construction_age_band: parsedConstructionAgeBand,
        epcRating: epc.current_energy_rating,
        floorArea: tryParseFloat(epc.total_floor_area.toString(), property.floorArea) ?? 0,
        roomHeight: tryParseFloat(epc.floor_height.toString(), property.roomHeight) ?? 0,
        propertyType: getPropertyType(epc),
        builtForm: getBuiltForm(epc),
        windowType: getWindowTypeScotland(epc),
        fuelType: getFuelTypeFromEPC(epc.main_fuel),
        wallGroup,
        wallType: (wallGroup && getWallType(epc.wall_description, wallGroup)),
        floorType: getFloorInsulation(epc.floor_description),
        loftInsulation: getLoftInsulation(epc.roof_description),
        windowUValue: getUValue(epc.windows_description),
        floorUValue: getUValue(epc.floor_description),
        roofUValue: getUValue(epc.roof_description),
        wallUValue: getUValue(epc.wall_description),
        noBedrooms,
        noBathrooms: getNumberofBathroomsFromBedrooms(noBedrooms)
    };
};
