import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../../../components/buttons/button';
import { Input } from '../../../../components/inputs_and_selections/input';
import { validateInMm, validateIsPositiveNumber, validateNotNull } from '../../../../code/validators';
export const AddCustomRadiatorPage = ({ tempCustomRadiatorModel, setTempCustomRadiatorModel, setSelectedCustomRadiatorModel, setCustomRadiatorModels, onBack }) => {
    return _jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: 'flex flex-col p-5 gap-5 overflow-y-auto', children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-900 font-bold', children: "Type" }), _jsx(Input, { value: tempCustomRadiatorModel.type, setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, type: e })), validator: validateNotNull })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Height" }), _jsx(Input, { type: 'number', postfix: 'mm', step: 100, value: tempCustomRadiatorModel.height_mm.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, height_mm: parseFloat(e) })), validator: validateInMm })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Width" }), _jsx(Input, { type: 'number', postfix: 'mm', step: 100, value: tempCustomRadiatorModel.width_mm.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, width_mm: parseFloat(e) })), validator: validateInMm })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Nominal output at delta T 50\u00B0C" }), _jsx(Input, { type: 'number', postfix: 'W', step: 1, value: tempCustomRadiatorModel.output_capacity_w_at_50_c.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, output_capacity_w_at_50_c: parseFloat(e) })), validator: (e) => validateIsPositiveNumber(e) })] })] }), _jsx("div", { className: 'p-4 bg-white', children: _jsx(Button, { disabled: !validateNotNull(tempCustomRadiatorModel.type).value ||
                        !validateInMm(tempCustomRadiatorModel.height_mm.toString()).value ||
                        !validateInMm(tempCustomRadiatorModel.width_mm.toString()).value ||
                        !validateIsPositiveNumber(tempCustomRadiatorModel.output_capacity_w_at_50_c.toString()).value, onClick: () => {
                        const selectedCustomRadiatorModel = {
                            ...tempCustomRadiatorModel,
                            uuid: tempCustomRadiatorModel.uuid ?? crypto.randomUUID()
                        };
                        setSelectedCustomRadiatorModel(selectedCustomRadiatorModel);
                        setCustomRadiatorModels(prev => {
                            return tempCustomRadiatorModel.uuid // temp model only has uuid if editing
                                ? prev.map(x => x.uuid === selectedCustomRadiatorModel.uuid ? selectedCustomRadiatorModel : x) // editing
                                : [...prev, selectedCustomRadiatorModel]; // add new
                        });
                        setTempCustomRadiatorModel({ ...tempCustomRadiatorModel, uuid: undefined }); // clear uuid of temp model but keep other characteristics so user can add another similar model
                        onBack();
                    }, children: "Apply" }) })] });
};
