import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Info } from '../../../components/buttons/info';
export const InfoDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { className: 'flex justify-start items-end gap-4', children: _jsx(Info, { infoModalHeader: 'What is this?', infoModalBody: 'Lots of nice info about heat pumps' }) })] })] });
};
const componentProps = [
    { name: 'infoModalHeader', description: 'What text should be in the header of the modal that opens on click' },
    { name: 'infoModalBody', description: 'What text should be in the body of the modal that opens on click [optional]' },
    { name: 'colour', description: 'What colour should the icon be [optional - defaults to text-gray-400]' },
    { name: 'className', description: 'Any additional classes you want to add to the component [optional]' }
];
const codeBlock = `<Info
    infoModalHeader={"What is this?"}
    infoModalBody={undefined}
    colour={"text-red-800"}  // default is text-gray-400
></Info>`;
