import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '../buttons/icon';
import { X } from 'lucide-react';
export const Snackbar = ({ text, onClose }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);
    const fadeTimeoutId = useRef(null);
    const closeTimeoutId = useRef(null);
    const startCloseTimer = () => {
        fadeTimeoutId.current = setTimeout(() => {
            setIsFadingOut(true);
            closeTimeoutId.current = setTimeout(onClose, 5000);
        }, 100);
    };
    const cancelCloseTimer = () => {
        if (fadeTimeoutId.current)
            clearTimeout(fadeTimeoutId.current);
        if (closeTimeoutId.current)
            clearTimeout(closeTimeoutId.current);
        setIsFadingOut(false); // Instantly transition back to full opacity
    };
    useEffect(() => {
        startCloseTimer();
        return () => {
            if (closeTimeoutId.current)
                clearTimeout(closeTimeoutId.current);
            if (fadeTimeoutId.current)
                clearTimeout(fadeTimeoutId.current);
        };
    }, []);
    return createPortal(_jsx("div", { onMouseEnter: cancelCloseTimer, onMouseLeave: startCloseTimer, className: `p-5 fixed bottom-5 right-5 transition-opacity ${isFadingOut ? 'opacity-0 duration-[5000ms]' : 'opacity-100 duration-0'}`, children: _jsxs("div", { className: "w-80 bg-gray-900 p-4 rounded-xl shadow text-white font-medium flex justify-between items-center", children: [_jsx("div", { children: text }), _jsx(Icon, { colour: 'text-white', className: 'cursor-pointer', icon: X, onClick: onClose })] }) }), document.body);
};
