import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EstimateCoverNoteTemplateBlock } from './blocks/estimate_cover_note_template_block';
import { SettingsLayout } from '../components/settings_layout';
import { EstimateConfigurationBlock } from './blocks/estimate_configuration_block';
import { EstimateAboutUsBlock } from './blocks/estimate_about_us_block';
import { EstimateCustomerReviewBlock } from './blocks/estimate_customer_review_block';
import { EstimateNextStepsBlock } from './blocks/estimate_next_steps_block';
export const EstimateResultsPage = ({ company, setCompany }) => {
    return _jsxs(SettingsLayout, { heading: 'Results page', description: 'Customise the content displayed on your estimates', children: [_jsx(EstimateConfigurationBlock, { company: company, setCompany: setCompany }), _jsx(EstimateCoverNoteTemplateBlock, { company: company, setCompany: setCompany }), _jsx(EstimateNextStepsBlock, { company: company, setCompany: setCompany }), _jsx(EstimateAboutUsBlock, { company: company, setCompany: setCompany }), _jsx(EstimateCustomerReviewBlock, { company: company, setCompany: setCompany })] });
};
