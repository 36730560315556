import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { PROPERTY_TYPES } from '../../../code/models/property_type';
export const StoreysStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    // used to represent the "4 or more floors" option
    const uncertainKey = '-1';
    const [selectedOption, setSelectedOption] = useState(PROPERTY_TYPES.find(propertyType => propertyType.name === survey.lead.property.propertyType)?.numberOfStoriesDefault.toString() || '1');
    const [textboxValue, setTextboxValue] = useState(survey.lead.property.noStoreys?.toString() || '4');
    const noStoreys = selectedOption === uncertainKey ? Number(textboxValue) : Number(selectedOption);
    // If the parsed noStoreys exceeds 4 we need to show the input box immediately with that number.
    useEffect(() => {
        if (survey.lead.property.noStoreys && survey.lead.property.noStoreys >= 4)
            setSelectedOption(uncertainKey);
    }, [survey]);
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                noStoreys
            }
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: `How many floors does ${adminEstimate ? 'the' : 'your'} property have?` }), _jsx(SurveyOptionGroup, { options: [
                    { label: '1 floor', key: '1' },
                    { label: '2 floors', key: '2' },
                    { label: '3 floors', key: '3' },
                    { label: '4 or more floors', key: uncertainKey }
                ], onSelect: (key) => {
                    // for the -1 option, we need to set the number of floors to the textbox value
                    if (key === uncertainKey)
                        setTextboxValue('4');
                    setSelectedOption(key);
                }, selectedOptionKey: selectedOption }), selectedOption === uncertainKey && _jsx(Input, { size: 'LG', setValue: setTextboxValue, type: "number", value: textboxValue, placeholder: 'Enter number of floors' }), _jsx(Button, { size: 'LG', disabled: !noStoreys || noStoreys <= 0, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
