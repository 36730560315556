import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatMaterialUValue, getMaterialByUuid, getMaterialUValue } from '../../../code/models/material';
import { Badge } from '../../../components/indicators_and_messaging/badge';
export const MaterialInputField = ({ selectorProps, setMsProps, setPage }) => {
    const uValue = getMaterialUValue(selectorProps.selectedMaterial);
    return _jsxs("div", { className: 'w-full cursor-pointer flex items-center justify-between gap-4 border px-4 py-2 rounded-lg border-gray-200', onClick: () => {
            setMsProps(selectorProps);
            setPage('MATERIALS');
        }, children: [_jsx("div", { className: 'text-gray-600 text-sm font-semibold', children: getMaterialByUuid(selectorProps.selectedMaterial.uuid, selectorProps.materials)?.name }), uValue !== undefined && _jsx(Badge, { color: 'INDIGO', text: formatMaterialUValue(uValue) })] });
};
