import { jsx as _jsx } from "react/jsx-runtime";
import { getCompanyNudgeLeadEmailTemplate } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../../../code/tiptap_placeholders';
export const NudgeLeadEmailTemplateBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: "Nudge follow up email template", children: _jsx("div", { className: 'flex flex-col gap-4', children: _jsx("div", { className: 'space-y-2', children: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setCompany({
                            ...company,
                            nudge_lead_template: JSON.stringify(editor.getJSON())
                        });
                    }, placeholders: [
                        TTPlaceholderViewEstimate,
                        TTPlaceholderCustomerFirstName,
                        TTPlaceholderCustomerLastName,
                        TTPlaceholderCustomerAddress,
                        TTPlaceholderHeatPumpModel,
                        TTPlaceholderCarbonSaving,
                        ...defaultPlaceholders
                    ], content: getCompanyNudgeLeadEmailTemplate(company.nudge_lead_template || '') }) }) }) });
};
