import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '../../../components/containers/modal';
import { duplicateLead } from '../../../code/models/lead';
import { Input } from '../../../components/inputs_and_selections/input';
import { validateNotNull } from '../../../code/validators';
export const DuplicateLeadModal = ({ visible, setVisible, lead, redirectToSurvey, navigateTo }) => {
    const [name, setName] = useState((lead.job_name ?? `${lead.customer?.first_name}${lead.customer?.last_name ? ` ${lead.customer?.last_name}` : ''}`) + ' (copy)');
    const handleConfirm = async () => {
        const newLead = await duplicateLead({ lead, name });
        if (newLead) {
            const url = redirectToSurvey
                ? `/admin/surveys/${newLead.uuid}`
                : `/admin/quotes/${newLead.uuid}`;
            navigateTo(url);
        }
    };
    return _jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Duplicate job?', onConfirm: handleConfirm, confirmButtonLabel: 'Duplicate', confirmDisabled: !validateNotNull(name).value, allowOverflow: true, children: _jsx(Input, { className: 'w-full', value: name, setValue: setName, validator: validateNotNull, label: 'Enter a name for the duplicated job', onEnter: () => {
                if (validateNotNull(name).value) {
                    handleConfirm();
                    setVisible(false);
                }
            } }) });
};
