import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Circle, Line } from 'react-konva';
import { gray200 } from './code/constants';
const GRIDS = {
    MM: 1,
    CM: 10,
    M: 1000
};
// Grid is 100m x 100m
export const Grid = ({ size, scale, position, stageWidth, stageHeight }) => {
    const gridIncrementPixels = GRIDS[size] * scale;
    const gridSize = 100 * (1000 / GRIDS[size]);
    if (gridIncrementPixels < 15)
        return;
    const viewportWidth = stageWidth;
    const viewportHeight = stageHeight;
    const { x: stageX, y: stageY } = position;
    const minX = -stageX;
    const minY = -stageY;
    const maxX = -stageX + viewportWidth;
    const maxY = -stageY + viewportHeight;
    const startingPosition = -((gridIncrementPixels * gridSize) / 2);
    const endPosition = gridIncrementPixels * gridSize + startingPosition;
    const verticalLines = [];
    for (let i = 0; i < gridSize + 1; i++) {
        const x = i * gridIncrementPixels + startingPosition;
        if (x >= minX && x <= maxX) {
            const line = _jsx(Line, { opacity: 0.5, points: [x, startingPosition, x, endPosition], stroke: gray200 }, i);
            verticalLines.push(line);
        }
    }
    const horizontalLines = [];
    for (let i = 0; i < gridSize + 1; i++) {
        const startingPosition = -((gridIncrementPixels * gridSize) / 2);
        const y = i * gridIncrementPixels + startingPosition;
        if (y >= minY && y <= maxY) {
            const line = _jsx(Line, { opacity: 0.5, points: [startingPosition, y, endPosition, y], stroke: gray200 }, i);
            horizontalLines.push(line);
        }
    }
    return _jsxs(_Fragment, { children: [_jsx(Circle, { x: 0, y: 0, fill: gray200, radius: 5 }), verticalLines, horizontalLines] });
};
