import { EPCRatingSchema, getEpcRecommendations } from './epc';
import { getAddressIncludingPostcode, patchLead } from './lead';
import { getExposedFloorMaterial } from './material';
import { DEFAULT_DESIGN_RADIATOR, DEFAULT_PROPERTY_SURVEY, DEFAULT_QUOTE_LINE_ITEM, DEFAULT_SURVEY_DESIGN, DEFAULT_SURVEY_DESIGN_BOILER, DEFAULT_SURVEY_DOOR, DEFAULT_SURVEY_FLOOR, DEFAULT_SURVEY_IMAGE, DEFAULT_SURVEY_RADIATOR, DEFAULT_SURVEY_ROOM, DEFAULT_SURVEY_WALL, DEFAULT_SURVEY_WINDOW } from '../survey_defaults';
import { geocodeAddress } from '../geocoding';
import { EstimateAgeBandSchema } from './age_bands';
import { z } from 'zod';
import { FloorAreaUnitSchema } from '../extra_types';
import { BuildingRegsSchema } from './building_regs';
export const DEFAULT_PROPERTY = {
    roomHeight: 2.4,
    address: '',
    yearBuilt: '',
    postcode: '',
    floorArea: 0,
    floorAreaUnits: 'sqm',
    noBathrooms: 0,
    noBedrooms: 0,
    noStoreys: 2,
    builtForm: '',
    propertyType: '',
    wallGroup: '',
    wallType: '',
    floorType: 'None',
    loftInsulation: '',
    windowType: '',
    fuelType: ''
};
// Set by installer on the lead detail page
export const HouseOverridesSchema = z.object({
    airChangeOverride: z.coerce.number().optional(),
    designTempOverride: z.coerce.number().optional(),
    internalTempOverride: z.coerce.number().optional(),
    externalWallUValueOverride: z.coerce.number().optional(),
    partyWallUValueOverride: z.coerce.number().optional(),
    windowsUValueOverride: z.coerce.number().optional(),
    floorUValueOverride: z.coerce.number().optional(),
    roofUValueOverride: z.coerce.number().optional(),
    floorArea: z.coerce.number().optional(),
    roomHeight: z.coerce.number().optional(),
    noBedrooms: z.coerce.number().optional(),
    noBathrooms: z.coerce.number().optional(),
    noStoreys: z.coerce.number().optional(),
    propertyType: z.string().optional(), // TODO later: make enum
    builtForm: z.string().optional(), // TODO later: make enum
    wallGroup: z.string().optional(), // TODO later: make enum
    wallType: z.string().optional(), // TODO later: make enum
    floorType: z.string().optional(), // TODO later: make enum
    loftInsulation: z.string().optional(), // TODO later: make enum
    windowType: z.string().optional(), // TODO later: make enum
    fuelType: z.string().optional(), // TODO later: make enum
    construction_age_band_uuid: z.string().optional(), // TODO later: make enum
    outdoorSpace: z.string().optional(), // TODO later: make enum
    buildingRegs: BuildingRegsSchema.optional()
});
export const PropertySchema = z.object({
    uuid: z.string().optional(),
    address: z.string(),
    postcodeLocation: z.array(z.number()).optional(), // lat, long  // TODO later: should just be called location or latLng as may not be from postcode
    altitudeM: z.number().optional(),
    yearBuilt: z.union([z.string(), z.number()]).optional(),
    postcode: z.string(),
    uprn: z.string().optional(),
    udprn: z.string().optional(),
    floorArea: z.coerce.number(),
    roomHeight: z.number(),
    noBedrooms: z.coerce.number(),
    noBathrooms: z.coerce.number(),
    noStoreys: z.coerce.number().optional(), // TODO - Should exist on all new jobs after new builds were introduced. Migrate old jobs to have a value also?
    builtForm: z.string(), // TODO later: make enum
    propertyType: z.string(), // TODO later: make enum
    wallGroup: z.string().optional(), // TODO later: make enum
    wallType: z.string().optional(), // TODO later: make enum
    floorType: z.string(), // TODO later: make enum
    loftInsulation: z.string().optional(), // TODO later: make enum
    windowType: z.string(), // TODO later: make enum
    fuelType: z.string(), // TODO later: make enum
    epcRating: EPCRatingSchema.optional(),
    construction_age_band_uuid: z.string().optional(), // Try to match to an age band so we can find materials via property age
    construction_age_band: EstimateAgeBandSchema.optional(), // This is only set if the user directly enters the age band, which thye only get a chance to in the case where there is no EPC
    buildingRegs: BuildingRegsSchema.optional(),
    // Below only defined if it's a new build EPC where the U value itself is passed rather than a description
    // TODO later: Set these to undefined not zero when they not defined. And write migration to set these to undefined if 0
    wallUValue: z.number().optional(),
    windowUValue: z.number().optional(),
    roofUValue: z.number().optional(),
    floorUValue: z.number().optional(),
    houseOverrides: HouseOverridesSchema.optional(),
    newDwelling: z.boolean().optional(),
    floorAreaUnits: FloorAreaUnitSchema.optional(),
    outdoorSpace: z.string().optional() // TODO later: make enum
});
// to avoid mapping something twice, we flag things as mapped when it's done
// we're using the flagging approach because estimate might has more values to be mapped in the future and we need to map them skipping already mapped
export const ESTIMATE_MAPPED_MATERIALS = 0x0001;
export const ESTIMATE_MAPPED_AGE_BAND = 0x0002;
export const ESTIMATE_MAPPED_FUEL_TYPE = 0x0004;
export const ESTIMATE_MAPPED_HEAT_PUMP = 0x0008;
export const ESTIMATE_MAPPED_HOT_WATER_CYLINDER = 0x0010;
export const ESTIMATE_MAPPED_DESIGN_TEMP = 0x0020;
export const ESTIMATE_MAPPED_INDOOR_TEMP = 0x0040;
export const ESTIMATE_MAPPED_ACH = 0x0080;
export const ESTIMATE_MAPPED_QUOTE_LINE_ITEMS = 0x0100;
export const ESTIMATE_MAPPED_PROPERTY_TYPE = 0x0200;
export const ESTIMATE_MAPPED_BUILT_FORM = 0x0400;
export const ESTIMATE_MAPPED_BEDROOMS_BATHROOMS = 0x0800;
export const ESTIMATE_MAPPED_FLOW_TEMP_C = 0x1000;
export const pipeMaterials = ['Copper', 'PEX', 'MLCP'];
export const isPreFlowFeatureSurvey = (survey) => {
    // disable flow for surveys created on or before 2024-08-05 00:00:00
    return !survey.created_at || survey.created_at <= Date.parse('2024-08-05');
};
export const ensurePropertySurveyBackwardsCompatibility = (survey, materials) => {
    const result = {
        ...DEFAULT_PROPERTY_SURVEY,
        ...survey,
        created_at: survey.created_at || new Date().getTime(),
        // Migrations m to mm
        // Heat pump available space
        available_space_width_mm: survey.available_space_width_mm ? survey.available_space_width_mm : survey.available_space_width_m ? survey.available_space_width_m * 1000 : 0,
        available_space_width_m: undefined,
        available_space_height_mm: survey.available_space_height_mm ? survey.available_space_height_mm : survey.available_space_height_m ? survey.available_space_height_m * 1000 : 0,
        available_space_height_m: undefined,
        available_space_depth_mm: survey.available_space_depth_mm ? survey.available_space_depth_mm : survey.available_space_depth_m ? survey.available_space_depth_m * 1000 : 0,
        available_space_depth_m: undefined,
        // Cylinder available space
        cylinder_available_width_mm: survey.cylinder_available_width_mm ? survey.cylinder_available_width_mm : survey.cylinder_available_width_m ? survey.cylinder_available_width_m * 1000 : 0,
        cylinder_available_width_m: undefined,
        cylinder_available_height_mm: survey.cylinder_available_height_mm ? survey.cylinder_available_height_mm : survey.cylinder_available_height_m ? survey.cylinder_available_height_m * 1000 : 0,
        cylinder_available_height_m: undefined,
        cylinder_available_depth_mm: survey.cylinder_available_depth_mm ? survey.cylinder_available_depth_mm : survey.cylinder_available_depth_m ? survey.cylinder_available_depth_m * 1000 : 0,
        cylinder_available_depth_m: undefined,
        // Migrations to manifolds
        manifolds: !survey.manifolds ? [] : survey.manifolds.map(manifold => {
            return {
                ...manifold,
                ufh_temp_differs_from_system_temp: manifold.ufh_temp_differs_from_system_temp ?? true,
                max_mean_water_temp_c: manifold.max_mean_water_temp_c ?? (manifold.flow_temp_c && manifold.return_temp_c && (manifold.flow_temp_c + manifold.return_temp_c) / 2) ?? 40,
                flow_temp_c: undefined, // set these to undefined to avoid confusion
                return_temp_c: undefined
            };
        })
    };
    // explicitly apply nested defaults
    // for floors
    result.floors = result.floors.map(floor => {
        return {
            ...DEFAULT_SURVEY_FLOOR,
            ...{
                ...floor,
                rooms: !floor.rooms ? [] : floor.rooms.map(room => {
                    return {
                        ...DEFAULT_SURVEY_ROOM,
                        ...{
                            ...room,
                            walls: !room.walls ? [] : room.walls.map(wall => {
                                return {
                                    ...DEFAULT_SURVEY_WALL,
                                    ...{
                                        ...wall,
                                        windows: !wall.windows ? [] : wall.windows.map(window => {
                                            return {
                                                ...DEFAULT_SURVEY_WINDOW,
                                                ...window,
                                                //   m to mm migration - delete once happy enough people have opened
                                                width_mm: window.width_mm ? window.width_mm : window.width_m ? window.width_m * 1000 : 0,
                                                width_m: undefined,
                                                height_mm: window.height_mm ? window.height_mm : window.height_m ? window.height_m * 1000 : 0,
                                                height_m: undefined
                                            };
                                        }),
                                        doors: !wall.doors ? [] : wall.doors.map(door => {
                                            return {
                                                ...DEFAULT_SURVEY_DOOR,
                                                ...door
                                            };
                                        })
                                    }
                                };
                            }),
                            radiators: !room.radiators ? [] : room.radiators.map(radiator => {
                                radiator.survey_or_design = !radiator.survey_or_design ? radiator.type === 'DESIGN' ? 'DESIGN' : 'SURVEY' : radiator.survey_or_design;
                                radiator.emitter_type = !radiator.emitter_type ? (radiator.type === 'DESIGN' || radiator.type === 'SURVEY') ? 'RADIATOR' : radiator.type : radiator.emitter_type;
                                if (radiator.emitter_type === 'SECONDARY')
                                    return radiator;
                                if (radiator.emitter_type === 'UNDERFLOOR') {
                                    return { ...radiator, pipe_model_uuid: radiator.pipe_model_uuid ?? (radiator.pipe_diameter_uuid === '16mm' ? 'pex_16' : 'pex_20') };
                                }
                                return {
                                    ...DEFAULT_SURVEY_RADIATOR,
                                    ...{
                                        ...radiator,
                                        // Rename radiator_diameter_uuid to radiator_model_uuid
                                        pipe_model_uuid: radiator.pipe_model_uuid ? radiator.pipe_model_uuid : radiator.pipe_diameter_uuid ? radiator.pipe_diameter_uuid : undefined,
                                        pipe_diameter_uuid: undefined,
                                        // Remove this "migration" after confident that all radiators have pipe_model_uuid
                                        photos: !radiator.photos ? [] : radiator.photos.map(photo => {
                                            return {
                                                ...DEFAULT_SURVEY_IMAGE,
                                                ...photo
                                            };
                                        })
                                    }
                                };
                            }),
                            images: !room.images ? [] : room.images.map(image => {
                                return {
                                    ...DEFAULT_SURVEY_IMAGE,
                                    ...image
                                };
                            })
                        }
                    };
                })
            }
        };
    });
    // for designs
    result.designs = !result.designs ? [] : result.designs.map(design => {
        return {
            ...DEFAULT_SURVEY_DESIGN,
            ...{
                ...design,
                // boilers: SurveyDesignBoiler[]
                quote_line_items: !design.quote_line_items ? [] : design.quote_line_items.map(item => {
                    return {
                        DEFAULT_QUOTE_LINE_ITEM,
                        ...item
                    };
                }),
                boilers: !design.boilers ? [] : design.boilers.map(boiler => {
                    return {
                        ...DEFAULT_SURVEY_DESIGN_BOILER,
                        ...boiler
                    };
                }),
                radiators: !design.radiators ? [] : design.radiators.map(radiator => {
                    return {
                        ...DEFAULT_DESIGN_RADIATOR,
                        ...{
                            ...radiator,
                            // Set design rad to default rad pipework if not defined. This is a temporary migration
                            // Ideally would do off paired survey rad, but not enough people using this that that's worth it
                            pipe_model_uuid: (radiator.emitter_type === 'RADIATOR' || radiator.emitter_type === 'UNDERFLOOR') && radiator.pipe_model_uuid ? radiator.pipe_model_uuid : survey.existing_system_pipework_uuid
                            // Remove this "migration" after confident that all radiators have pipe_model_uuid
                        }
                    };
                })
            }
        };
    });
    // for location_images
    result.location_images = !result.location_images ? [] : result.location_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    // for drain_photos
    result.drain_photos = !result.drain_photos ? [] : result.drain_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for cylinder_photos
    result.cylinder_photos = !result.cylinder_photos ? [] : result.cylinder_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for existing_cylinder_photos
    result.existing_cylinder_photos = !result.existing_cylinder_photos ? [] : result.existing_cylinder_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for property_images
    result.property_images = !result.property_images ? [] : result.property_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    // for electrics_images
    result.electrics_images = !result.electrics_images ? [] : result.electrics_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    // Migration on exposed floor default
    // Will only run if materials passed in, which they are not in the "migrations" run of this, but that shouldn't be called again as it's from the sync change
    if (survey?.default_materials && !survey.default_materials.exposedFloor && materials) {
        survey.default_materials.exposedFloor = getExposedFloorMaterial(materials, survey.age_band);
    }
    return result;
};
export const ensureLeadHasLocation3D = async (lead, companyUUID) => {
    // do not store if there is an information in property.location
    if (lead.property.postcodeLocation && lead.property.altitudeM)
        return lead;
    const location3D = await geocodeAddress(getAddressIncludingPostcode(lead), lead.property.postcode);
    if (!location3D)
        return lead;
    const newLead = {
        ...lead,
        property: {
            ...lead.property,
            postcodeLocation: lead.property.postcodeLocation ?? [location3D.lat, location3D.lng],
            altitudeM: lead.property.altitudeM ?? location3D.altitudeM
        }
    };
    await patchLead({ uuid: lead.uuid, lead: newLead, companyUUID });
    return newLead;
};
export const ensureLeadHasEPCRecommendations = async (lead, companyUUID, save = true) => {
    // do not store if there is an information in property.location
    if (!lead)
        return lead;
    if (lead.epc_recommendations !== undefined && lead.epc_recommendations !== null)
        return lead;
    const recommendations = lead.epcData?.lmkKey ? await getEpcRecommendations(lead.epcData.lmkKey) : [];
    if (save) {
        await patchLead({ uuid: lead.uuid, lead: { epc_recommendations: recommendations }, companyUUID });
    }
    return { ...lead, epc_recommendations: recommendations };
};
