import { z } from 'zod';
import { apiUrl, axiosGetV2, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
const JobEventTypeValues = [
    'DEPRECATED_ReportGenerated',
    'DEPRECATED_ProposalGenerated',
    'DEPRECATED_QuoteGenerated',
    'StatusChanged',
    'JobCreated',
    'EstimateSent',
    'EstimateReminderSent',
    'EnquiryDeclined',
    'EstimateRejected',
    'EstimateAccepted',
    'EstimateCallbackRequested',
    'ReportSent',
    'ProposalSent',
    'QuoteSent',
    'ProposalAccepted',
    'ReportPDFGenerated',
    'ProposalPDFGenerated',
    'QuotePDFGenerated',
    'EstimateOpened',
    'ReportOpened',
    'ProposalOpened',
    'QuoteOpened'
];
export const JobEventType = z.enum(JobEventTypeValues);
export const JobEventSchema = z.object({
    uuid: z.string().uuid(),
    event_type: JobEventType,
    extra_data: z.record(z.string(), z.unknown()).optional(),
    created_at: z.coerce.date(),
    user_name: z.string().optional(),
    company_name: z.string().optional()
});
export const JobEventCreateSchema = z.object({
    event_type: JobEventType,
    company_uuid: z.string().uuid().optional(),
    job_uuid: z.string().uuid(),
    extra_data: z.record(z.string(), z.unknown()).optional()
});
export const createJobEvent = async (event) => {
    // We shouldn't ever be creating a job event without a company_uuid, because we need the job uuid AND the
    // company UUID for accurate billing metering.
    // No company UUID might happen on public pages or pages where the user has very limited permissions.
    if (!event.company_uuid) {
        return undefined;
    }
    try {
        const result = await client.post(`${apiUrl}jobs/${event.job_uuid}/events`, event, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error posting job event', e);
        return undefined;
    }
};
export const getJobEvents = async (jobUuid, companyUuid) => {
    const eventData = await axiosGetV2(`jobs/${jobUuid}/events`, { params: { company_uuid: companyUuid } });
    if (eventData) {
        return eventData;
    }
    else {
        return undefined;
    }
};
