import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from '../../components/section';
import { InfoCard } from '../../components/info_card';
import { numberFormat } from '../../../../../code/number_format';
import { leadWithNewOverride } from '../../../../../code/models/lead';
import { Heading } from '../../../../../components/content_display/heading';
import { Text } from '../../../../../components/content_display/text';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Button } from '../../../../../components/buttons/button';
import { Icon } from '../../../../../components/buttons/icon';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, SOLID_WALL_INSULATION } from '../../../../../code/models/u_value';
import { BillSavingsInfo } from '../../../../estimate_page';
import { XCircle, RefreshCw } from 'lucide-react';
export const HeatLossElementsTable = ({ lead, setOverride, estimate }) => {
    const columns = [
        { key: 'element', name: 'Element' },
        { key: 'uValue', name: 'U-Value (W/m²K)' },
        { key: 'estimate', name: 'Estimate' }
    ];
    const valueIsOverridden = (key) => {
        return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined;
    };
    // TODO: This is kind of an ugly hack - it should be somewhere more general
    // Cf.: https://github.com/SpruceEco/spruce/pull/512#issuecomment-2333580417
    const insulationLabel = (lead) => {
        const wallTypeId = lead.property.houseOverrides?.wallType ?? lead.property.wallType;
        const wallGroup = lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup;
        if (!wallTypeId || !wallGroup)
            return '';
        const wallType = (wallGroup === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).find(x => x.uuid === wallTypeId);
        if (wallGroup === CAVITY_WALL) {
            return wallType?.name || 'Uninsulated'; // Insultated or Uninsulated
        }
        switch (wallType?.uuid) {
            case 'internal':
                return 'Internal insulation';
            case 'external':
                return 'External insulation';
            case 'idk':
                return 'Unknown insulation';
            case 'none':
            default:
                return 'No insulation';
        }
    };
    const rows = [
        {
            element: _jsxs(_Fragment, { children: [_jsx(Text, { bold: true, size: "SM", children: "External walls" }), _jsx(Text, { size: "SM", className: 'text-light', children: [
                            lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup,
                            insulationLabel(lead).toLowerCase()
                        ].filter(Boolean).join(', ') })] }),
            uValue: _jsx(Input, { type: "number", step: 0.1, shrink: true, value: lead.property.houseOverrides?.externalWallUValueOverride?.toString() || estimate.externalWallUValue.toString(), setValue: (e) => setOverride('externalWallUValueOverride', parseFloat(e)), postfix: _jsxs("div", { children: [valueIsOverridden('externalWallUValueOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('externalWallUValueOverride', undefined), colour: 'text-gray-400' }), " "] }) }),
            estimate: `${numberFormat(0).format(estimate.externalWallWatts)} W`
        },
        {
            element: _jsxs(_Fragment, { children: [_jsx(Text, { bold: true, size: "SM", children: "Party walls" }), _jsx(Text, { size: "SM", className: 'text-light', children: [
                            lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup,
                            insulationLabel(lead).toLowerCase()
                        ].filter(Boolean).join(', ') })] }),
            uValue: _jsx(Input, { type: "number", step: 0.1, shrink: true, value: lead.property.houseOverrides?.partyWallUValueOverride?.toString() || estimate.partyWallUValue.toString(), setValue: (e) => setOverride('partyWallUValueOverride', parseFloat(e)), postfix: _jsxs("div", { children: [valueIsOverridden('partyWallUValueOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('partyWallUValueOverride', undefined), colour: 'text-gray-400' }), " "] }) }),
            estimate: `${numberFormat(0).format(estimate.partyWallWatts)} W`
        },
        {
            element: _jsxs(_Fragment, { children: [_jsx(Text, { bold: true, size: "SM", children: "Windows" }), _jsx(Text, { size: "SM", className: 'text-light', children: lead.property.houseOverrides?.windowType ?? lead.property.windowType })] }),
            uValue: _jsx(Input, { type: "number", step: 0.1, shrink: true, value: lead.property.houseOverrides?.windowsUValueOverride?.toString() || estimate.windowsUValue.toString(), setValue: (e) => setOverride('windowsUValueOverride', parseFloat(e)), postfix: _jsxs("div", { children: [valueIsOverridden('windowsUValueOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('windowsUValueOverride', undefined), colour: 'text-gray-400' }), " "] }) }),
            estimate: `${numberFormat(0).format(estimate.windowWatts)} W`
        },
        {
            element: _jsxs(_Fragment, { children: [_jsx(Text, { bold: true, size: "SM", children: "Floors" }), _jsx(Text, { size: "SM", className: 'text-light', children: lead.property.houseOverrides?.floorType ?? lead.property.floorType })] }),
            uValue: _jsx(Input, { type: "number", step: 0.1, shrink: true, value: lead.property.houseOverrides?.floorUValueOverride?.toString() || estimate.floorUValue.toString(), setValue: (e) => setOverride('floorUValueOverride', parseFloat(e)), postfix: _jsxs("div", { children: [valueIsOverridden('floorUValueOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('floorUValueOverride', undefined), colour: 'text-gray-400' }), " "] }) }),
            estimate: `${numberFormat(0).format(estimate.floorWatts)} W`
        },
        {
            element: _jsxs(_Fragment, { children: [_jsx(Text, { bold: true, size: "SM", children: "Roof" }), _jsx(Text, { size: "SM", className: 'text-light', children: lead.property.houseOverrides?.loftInsulation ?? lead.property.loftInsulation })] }),
            uValue: _jsx(Input, { type: "number", step: 0.1, shrink: true, value: lead.property.houseOverrides?.roofUValueOverride?.toString() || estimate.roofUValue.toString(), setValue: (e) => setOverride('roofUValueOverride', parseFloat(e)), postfix: _jsxs("div", { children: [valueIsOverridden('roofUValueOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('roofUValueOverride', undefined), colour: 'text-gray-400' }), " "] }) }),
            estimate: `${numberFormat(0).format(estimate.roofWatts)} W`
        },
        {
            element: _jsx(Text, { bold: true, size: "SM", children: "Ventilation" }),
            uValue: '',
            estimate: `${numberFormat(0).format(estimate.ventilationWatts)} W`
        },
        {
            element: _jsx(Text, { bold: true, children: "Total" }),
            uValue: '',
            estimate: _jsxs(Text, { bold: true, children: [(estimate.totalWatts / 1000).toFixed(2), " kW"] })
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { size: "md", children: "Elements" }), _jsx(TableLite, { columns: columns, rows: rows, alignRight: false })] }));
};
export const HeatLossSummaryCards = ({ lead, estimate, columns }) => {
    return (_jsxs("div", { className: `grid grid-cols-1 ${columns === 4 && 'md:grid-cols-4'} gap-5`, children: [_jsx(InfoCard, { title: "Total heat loss", value: _jsxs(_Fragment, { children: [(estimate.totalWatts / 1000).toFixed(2), " kW"] }) }), _jsx(InfoCard, { title: "Heat loss per m\u00B2", value: _jsxs(_Fragment, { children: [numberFormat(0).format(estimate.totalWatts / lead.property.floorArea), " W/m\u00B2"] }) }), _jsx(InfoCard, { title: _jsxs(_Fragment, { children: ["CO", _jsx("sub", { children: "2" }), " savings/year"] }), value: _jsxs(_Fragment, { children: [numberFormat(0).format(estimate.CO2SavedKg), " kg/year"] }) }), _jsx(InfoCard, { title: "Bill savings/year", value: _jsxs(_Fragment, { children: ["\u00A3", numberFormat(0).format(estimate.annualBillBaselineGBP - estimate.annualBillHeatPumpTariffGBP), "/year"] }), info: _jsx(BillSavingsInfo, { billSavingsInfoContent: {
                        annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
                        annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
                        annual_kwh_heat_required: estimate.annualKwhHeat,
                        average_efficiency_baseline: estimate.efficiencyBaseline,
                        average_efficiency_hp: estimate.averageEfficiencyHeatPump,
                        fuel_name_baseline: estimate.fuelNameBaseline,
                        tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
                        tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
                        electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
                        annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
                        tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
                        price_cap_description: estimate.priceCapDescription,
                        heat_pump_name: estimate.heatPump?.name ?? '',
                        flow_temperature_c: estimate.flowTempC
                    }, homeownerFacing: false }) })] }));
};
export const HeatLossEstimate = ({ lead, setLead, estimate }) => {
    const handleResetEditingData = () => {
        setLead({
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    designTempOverride: undefined,
                    internalTempOverride: undefined,
                    airChangeOverride: undefined,
                    externalWallUValueOverride: undefined,
                    partyWallUValueOverride: undefined,
                    windowsUValueOverride: undefined,
                    floorUValueOverride: undefined,
                    roofUValueOverride: undefined
                }
            }
        });
    };
    const setOverride = (key, value) => {
        setLead(leadWithNewOverride(lead, estimate.defaults, key, value));
    };
    const valueIsOverridden = (key) => {
        return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined;
    };
    return (_jsxs(Section, { title: "Heat loss estimate", controls: _jsx(_Fragment, { children: _jsx(Button, { colour: 'LIGHT', iconLeft: RefreshCw, onClick: handleResetEditingData, size: "SM", children: "Reset to defaults" }) }), children: [_jsx(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 4 }), _jsx(Heading, { size: "md", children: "Variables" }), _jsxs("div", { className: 'grid grid-cols-1 md:grid-cols-3 gap-5', children: [_jsx("div", { children: _jsx(Input, { type: "number", step: 0.1, label: 'Design temp. (\u00B0C)', value: estimate.designTempC.toString(), setValue: (e) => setOverride('designTempOverride', parseFloat(e)), postfix: _jsxs("div", { className: 'flex gap-1', children: [_jsx("div", { children: "\u00B0C" }), valueIsOverridden('designTempOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('designTempOverride', undefined), colour: 'text-gray-400' })] }) }) }), _jsx("div", { children: _jsx(Input, { type: "number", step: 0.1, label: 'Internal temp. (\u00B0C)', value: estimate.internalTempC.toString(), setValue: (e) => setOverride('internalTempOverride', parseFloat(e)), postfix: _jsxs("div", { className: 'flex gap-1', children: [_jsx("div", { children: "\u00B0C" }), valueIsOverridden('internalTempOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('internalTempOverride', undefined), colour: 'text-gray-400' })] }) }) }), _jsx("div", { children: _jsx(Input, { type: "number", step: 0.1, label: "ACH", value: estimate.airChanges.toString(), setValue: (e) => setOverride('airChangeOverride', parseFloat(e)), postfix: _jsxs("div", { children: [" ", valueIsOverridden('airChangeOverride') && _jsx(Icon, { icon: XCircle, onClick: () => setOverride('airChangeOverride', undefined), colour: 'text-gray-400' }), " "] }) }) })] }), _jsx(HeatLossElementsTable, { lead: lead, setOverride: setOverride, estimate: estimate })] }));
};
