import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { updateCalculatedEstimate } from '../../../code/models/calculated_estimate';
import { getCompanyNudgeLeadEmailTemplate } from '../../../code/models/company';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../code/tiptap_placeholders';
import { numberFormat } from '../../../code/number_format';
import { formatPrice } from '../../../code/format_price';
import { Modal } from '../../../components/containers/modal';
import { logEvent } from '../../../code/log_event';
import { sendNudgeEmail } from '../../../code/models/lead';
import { Tiptap } from '../../../components/inputs_and_selections/tiptap';
import { AdminContext } from '../admin_layout';
import { calculateQuote } from '../../../code/calculate_quote';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../code/calculate_estimate';
import { sum } from 'lodash';
import { getApproxNumberOfRadiatorChanges } from '../../../code/models/estimated_rooms_and_radiators';
import { createJobEvent, JobEventType } from '../../../code/models/job_event';
export const SendNudgeLeadEmailModal = ({ isVisible, setIsVisible, calculatedEstimate, estimate, lead, reloadTrigger, company }) => {
    if (!calculatedEstimate)
        return null;
    const adminContext = useContext(AdminContext);
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    const [estimateToSend, setEstimateToSend] = useState();
    const currentEstimateHeatPump = adminContext.data?.heatPumps?.find(x => x.uuid === calculatedEstimate.range_heat_pump_uuid);
    const currentEstimateHotWaterCylinder = adminContext.data?.hotWaterCylinders?.find(x => x.uuid === calculatedEstimate.range_hot_water_cylinder_uuid);
    const [calculatedQuote] = calculateQuote({
        company,
        selectedHeatPump: currentEstimateHeatPump,
        selectedHotWaterCylinder: currentEstimateHotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: calculatedEstimate.calculated_estimate_quote_items
    });
    useEffect(() => {
        if (!company)
            return;
        const estimateToSend = {
            ...calculatedEstimate,
            status: 'Reminder sent',
            updated_at: new Date().toString()
        };
        // the email template used to send the estimate
        const emailTemplate = getCompanyNudgeLeadEmailTemplate(company.nudge_lead_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(emailTemplate, {
            ...defaultPlaceholderMappings(company.public_info, adminContext.data.user),
            [TTPlaceholderCustomerName.id]: estimateToSend.first_name, // Backwards compatibility for old plaeceholder
            [TTPlaceholderCustomerFirstName.id]: estimateToSend.first_name,
            [TTPlaceholderCustomerLastName.id]: estimateToSend.last_name,
            [TTPlaceholderCustomerAddress.id]: `${estimateToSend.address}, ${estimateToSend.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: estimateToSend.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(estimateToSend.co2_saved_kg)
        });
        setEmailTemplateJSON(emailJSON);
        setEstimateToSend(estimateToSend);
    }, [adminContext, calculatedEstimate]);
    if (!company || !estimateToSend)
        return;
    const companyUUID = company.public_info.uuid;
    return _jsx(Modal, { title: 'Send reminder to lead', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            logEvent({
                name: 'Send Nudge Lead Email',
                properties: {}
            }, company?.public_info?.subdomain ?? '');
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + company?.public_info?.subdomain + '/estimate/' + estimateToSend.uuid
            });
            await sendNudgeEmail({ leadUUID: lead.uuid, estimateUUID: calculatedEstimate.uuid, companyUUID: lead.company_uuid, emailHTML });
            await updateCalculatedEstimate(estimateToSend, companyUUID);
            createJobEvent({
                event_type: JobEventType.enum.EstimateReminderSent,
                company_uuid: companyUUID,
                job_uuid: lead.uuid,
                extra_data: {
                    calculated_estimate_uuid: estimateToSend.uuid,
                    email_content: emailHTML,
                    recipients: [lead.customer?.email]
                }
            });
            reloadTrigger();
        }, confirmButtonLabel: "Send", children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs("div", { children: ["This will send an estimate of ", _jsx("span", { className: "font-bold", children: formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0))) }), " to ", _jsxs("span", { className: "font-bold", children: [estimateToSend.first_name, estimateToSend.last_name ? ` ${estimateToSend.last_name}` : ''] }), " at ", _jsx("span", { className: "font-bold", children: lead?.customer?.email }), "."] }), _jsxs("div", { className: "flex flex-col gap-4", children: [_jsx("div", { className: "text-xl font-medium", children: "Customer email" }), _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                                setEmailTemplateJSON(editor.getJSON());
                            }, placeholders: [
                                TTPlaceholderViewEstimate
                            ], content: emailTemplateJSON }), company?.public_info?.logo && _jsx("img", { src: company?.public_info?.logo, className: "max-w-40", alt: "Installer logo" })] })] }) });
};
