import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { Icon } from '../buttons/icon';
import { Check as CheckL, ChevronDown, ChevronUp, LoaderCircle } from 'lucide-react';
import { chain } from 'lodash';
const sizes = {
    font: {
        SM: 'text-xs',
        MD: 'text-sm',
        LG: 'text-base'
    },
    spacing: {
        SM: 'px-3 py-2',
        MD: 'px-4 py-2',
        LG: 'px-4 py-3'
    }
};
const colours = {
    // Primary and secondary are just default and light
    // They're only in here for type consistency
    PRIMARY: 'bg-white border-gray-300 text-gray-600',
    SECONDARY: 'bg-gray-100 text-gray-900 border-gray-100',
    DEFAULT: 'bg-white border-gray-300 text-gray-600',
    DARK: 'bg-gray-900 text-gray-100 border-gray-900',
    LIGHT: 'bg-gray-100 text-gray-900 border-gray-100',
    YELLOW: 'bg-yellow-100 text-yellow-900 border-yellow-100',
    RED: 'bg-red-100 text-red-900 border-red-100',
    BLUE: 'bg-blue-100 text-blue-900 border-blue-100',
    GREEN: 'bg-green-100 text-green-900 border-green-100',
    INDIGO: 'bg-purple-100 border-purple-100 text-purple-900'
};
const buttonStyles = (size, colour, focus) => `relative flex flex-col text-left w-full ${sizes.font[size]} ${sizes.spacing[size]} rounded-lg border ${colours[colour]} cursor-pointer ${focus && 'border-accent outline-none'}`;
const dropdownContainerStyles = (type) => `[--anchor-gap:4px] empty:invisible ${type === 'simple' ? 'w-[var(--button-width)]' : 'w-[var(--input-width)]'} overflow-y-auto [--anchor-max-height:240px] z-20 rounded bg-white shadow border border-gray-200 focus:outline-none divide-y divide-gray-200`;
const dropdownItemStyles = (size, selected, focus) => `group relative text-default cursor-pointer p-2 ${sizes.font[size]} ${sizes.spacing[size]} first:rounded-t last:rounded-b pr-10 ${focus ? 'bg-accent text-white' : selected ? 'bg-gray-100 text-default' : ''}`;
const Label = ({ label }) => (_jsx("div", { className: "uppercase text-xs font-bold", children: label }));
const Arrow = React.forwardRef(({ open, size, colour, loading }, ref) => {
    return (_jsx("div", { className: `group absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} pointer-events-none`, ref: ref, children: loading
            ? _jsx(Icon, { icon: LoaderCircle, spin: true, colour: colours[colour] })
            : _jsx(Icon, { icon: open ? ChevronUp : ChevronDown, colour: colours[colour] }) }));
});
const Check = ({ size, selected, focus }) => (_jsx(Icon, { icon: CheckL, className: `absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} ${selected ? 'hidden sm:block' : 'hidden'} ${focus ? 'text-white' : 'text-default'}` }));
const valueForKey = (options, key) => options.find((option) => option.key === key)?.value;
const DEFAULT_PLACEHOLDER = 'Please select';
const SimpleSelect = ({ options, selectedKey, setSelectedKey, size = 'MD', colour = 'DEFAULT', label, placeholder, dataCy, multiple, icon }) => {
    const optionsGrouped = chain(options)
        .groupBy(x => x.group_name)
        .map((values, key) => ({ key, values }))
        .value();
    return (_jsxs(Listbox, { value: selectedKey, onChange: setSelectedKey, multiple: multiple, children: [_jsx(ListboxButton, { as: Fragment, children: ({ open, focus, active }) => (_jsxs("button", { className: `${buttonStyles(size, colour, [open, focus, active].some(Boolean))} w-full`, "data-cy": dataCy, children: [label && _jsx(Label, { label: label }), _jsxs("div", { className: "flex items-center w-full", children: [icon && _jsx(Icon, { icon: icon, className: "mr-2" }), _jsx("div", { className: "mr-6", children: valueForKey(options, selectedKey) ?? placeholder ?? DEFAULT_PLACEHOLDER }), _jsx(Arrow, { open: open, size: size, colour: colour })] })] })) }), _jsx(ListboxOptions, { className: dropdownContainerStyles('simple'), anchor: "bottom", children: optionsGrouped.map(({ key, values }) => (_jsxs("div", { className: 'flex flex-col py-1', children: [key !== 'undefined' && _jsx("div", { className: 'text-gray-500 text-xs font-medium px-3 py-2', children: key }), values.map(x => _jsx(ListboxOption, { as: Fragment, value: x.key, children: ({ selected, focus }) => (_jsxs("div", { className: dropdownItemStyles(size, selected, focus), children: [x.value, _jsx(Check, { size: size, selected: selected, focus: focus })] })) }, x.key))] }, key))) })] }));
};
const FilterableSelect = ({ options, selectedKey, setSelectedKey, filterCallback, size = 'MD', colour = 'DEFAULT', placeholder, isLoading, autoFocus, dataCy, multiple }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    return (_jsxs(Combobox, { immediate: true, value: selectedKey, onChange: setSelectedKey, as: Fragment, multiple: multiple, children: [_jsxs("div", { className: "relative w-full", children: [_jsx(ComboboxInput, { displayValue: (value) => valueForKey(options, value), as: Fragment, children: ({ focus }) => (_jsx("input", { placeholder: placeholder ?? DEFAULT_PLACEHOLDER, onChange: (event) => {
                                // If a filter function is provided, use it to filter the options (this may be a query to an API)
                                // Otherwise, just do a text search on the options
                                if (filterCallback) {
                                    filterCallback(event.target.value);
                                }
                                else {
                                    setFilteredOptions(options.filter((option) => option.value.toLowerCase().includes(event.target.value.toLowerCase())));
                                }
                            }, className: `${buttonStyles(size, colour, focus)} pr-10`, autoComplete: 'off', autoFocus: autoFocus, "data-cy": dataCy })) }), _jsx(ComboboxButton, { as: Fragment, children: ({ open }) => (_jsx(Arrow, { open: open, size: size, colour: colour, loading: isLoading })) })] }), _jsx(ComboboxOptions, { anchor: "bottom", className: dropdownContainerStyles('filterable'), children: (filterCallback ? options : filteredOptions).map((option) => (_jsx(ComboboxOption, { value: option.key, as: Fragment, children: ({ selected, focus }) => (_jsxs("div", { className: dropdownItemStyles(size, selected, focus), children: [option.value, _jsx(Check, { size: size, selected: selected, focus: focus })] })) }, option.key))) })] }));
};
export const Select = ({ options, selectedKey, setSelectedKey, filter, filterCallback, size = 'MD', colour = 'DEFAULT', label, placeholder, isLoading, dataCy, autoFocus, multiple, icon }) => {
    return filter ? (_jsx(FilterableSelect, { options: options, selectedKey: selectedKey, setSelectedKey: setSelectedKey, filter: filter, size: size, colour: colour, placeholder: placeholder, filterCallback: filterCallback, isLoading: isLoading, dataCy: dataCy, autoFocus: autoFocus, multiple: multiple })) : (_jsx(SimpleSelect, { options: options, selectedKey: selectedKey, setSelectedKey: setSelectedKey, size: size, colour: colour, label: label, placeholder: placeholder, dataCy: dataCy, multiple: multiple, icon: icon }));
};
