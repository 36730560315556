import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getRoomWatts } from '../../../code/models/heat_loss';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { TableLite } from '../../../components/content_display/table_lite';
import { getEmitterSizeName, getEmitterTypeName } from '../../../code/models/radiator';
import { HLRContext } from './heatloss_report_page';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { RadiatorIcon, UFHIcon } from '../../../assets/images/survey_images/survey_images';
import { getEmitterWatts } from '../../../code/models/radiator_model';
import { numberFormat } from '../../../code/number_format';
import { chain, sum } from 'lodash';
import { proxyImageURL } from '../../../code/helpers';
export const HLREmittersCurrent = () => {
    const hlrContext = React.useContext(HLRContext);
    const emitters = hlrContext.survey.floors.flatMap(floor => {
        const roomGroups = chain(floor.rooms)
            .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
            .map((values, key) => ({ key, values }))
            .value();
        return roomGroups.flatMap(roomGroup => {
            const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey)));
            const isMultiRoomGroup = roomGroup.values.length > 1;
            const name = isMultiRoomGroup
                ? roomGroup.values.map(r => r.name).join(' + ')
                : roomGroup.values[0].name;
            const roomGroupRows = roomGroup.values.flatMap((room, idx) => {
                return room.radiators.map((radiator, index) => {
                    const images = radiator.photos.map(x => hlrContext.files.find(y => y.uuid === x.image_uuid));
                    const radiatorWatts = Math.round(getEmitterWatts(radiator, room, hlrContext.design, hlrContext.survey, hlrContext.designTempC, hlrContext.groundTempC));
                    return {
                        firstRadInList: index === 0,
                        firstRoomInGroupList: idx === 0,
                        roomName: name,
                        image: images[0] ? _jsx("img", { src: images[0].file_base64 || proxyImageURL('40x40', images[0].file_url ?? ''), alt: '', className: 'w-10 h-10 rounded' })
                            : (radiator.emitter_type === 'UNDERFLOOR')
                                ? _jsx("img", { alt: '', src: UFHIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" })
                                : _jsx("img", { alt: '', src: RadiatorIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" }),
                        type: radiator.emitter_type === 'UNDERFLOOR' ? 'UFH' : radiator.emitter_type === 'SECONDARY' ? 'Secondary' : 'Radiator',
                        details: _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { children: getEmitterTypeName(radiator) }), _jsx("div", { children: getEmitterSizeName(radiator) })] }),
                        emitterOutput: radiatorWatts,
                        roomDemand: Math.round(roomGroupRoomWatts),
                        roomOutput: 0, // calculated below
                        percentMet: 0 // calculated below
                    };
                });
            });
            // calc roomOutput
            const roomOutput = roomGroupRows.reduce((acc, radiator) => acc + radiator.emitterOutput, 0);
            // return radiators with roomOutput updated
            return roomGroupRows.map(radiator => {
                return {
                    ...radiator,
                    roomOutput,
                    percentMet: Math.round((roomOutput / roomGroupRoomWatts) * 100)
                };
            });
        });
    }).flat();
    if (emitters.length === 0)
        return _jsx("div", {}); // Don't show anything if no emitters
    const emittersTableColumns = [
        {
            name: 'room',
            key: 'roomName',
            render: (row) => {
                // render only if the room name renders first time in the list
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return _jsx("span", { className: 'text-gray-900 text-xs', children: row.roomName });
                }
                else {
                    return _jsx("span", {});
                }
            }
        },
        { name: 'Image', key: 'image', render: (row) => _jsx("div", { className: 'flex justify-end', children: row.image }) },
        { name: 'Type', key: 'type', render: (row) => _jsx("span", { className: 'text-xs', children: row.type }) },
        { name: 'Details', key: 'details', render: (row) => _jsx("span", { className: 'text-xs', children: row.details }) },
        { name: 'Emitter Output', key: 'emitterOutput', render: (row) => _jsxs("span", { className: 'text-xs', children: [row.emitterOutput, " W"] }) },
        {
            name: 'Room Output',
            key: 'roomOutput',
            render: (row) => {
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return _jsxs("span", { className: 'text-xs', children: [row.roomOutput, " W"] });
                }
                else {
                    return _jsx("span", {});
                }
            }
        },
        {
            name: 'Room demand',
            key: 'roomDemand',
            render: (row) => {
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return _jsxs("span", { className: 'text-xs', children: [row.roomDemand, " W"] });
                }
                else {
                    return _jsx("span", {});
                }
            }
        },
        {
            name: '% heat demand met*',
            key: 'percentMet',
            render: (row) => {
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return _jsx("div", { className: 'flex justify-end', children: _jsx(Badge, { color: row.percentMet < 100 ? 'RED' : 'GREEN', text: row.percentMet.toString() + ' %' }) });
                }
                else {
                    return _jsx("span", {});
                }
            }
        }
    ];
    return _jsxs("div", { className: 'flex-col gap-6 flex', children: [_jsx(HLRSubheaderBlock, { section: "System design", title: "Current emitters in your home" }), _jsx(TableLite, { size: 'SM', rows: emitters, columns: emittersTableColumns }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs font-bold", children: "*% Heat demand met: " }), _jsxs("span", { className: "text-xs tracking-tight", children: ["This is calculated for a day when the outdoor temperature is ", numberFormat(1).format(hlrContext.designTempC), " \u00B0C and the flow temperature is ", numberFormat(1).format(hlrContext.design.flow_temp), " \u00B0C"] })] })] });
};
