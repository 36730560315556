import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { orderBy, uniq } from 'lodash';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../code/models/range_heat_pump';
import { PageHeader } from './design/components/design_page_header';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { ListItem } from '../../components/content_display/list_item';
import { Badge } from '../../components/indicators_and_messaging/badge';
export const SelectAHeatPumpPage = ({ heatPumps, designTempC, flowTempC, totalHeatLossKW, setCurrentPage, design, setDesign, isOffline, allSynced }) => {
    const [rangeFilter, setRangeFilter] = useState('All');
    const ranges = ['All', ...uniq(heatPumps.map(x => x.range_heat_pump?.brand_range?.name)).filter(x => !!x)];
    const filteredHeatPumps = orderBy(heatPumps.filter(x => rangeFilter === 'All' || x.range_heat_pump?.brand_range?.name === rangeFilter).map(x => ({
        uuid: x.uuid,
        name: x.name,
        capacity: getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw
    })), x => x.capacity);
    return _jsxs("div", { className: 'flex flex-col', children: [_jsx(PageHeader, { title: 'Select a heat pump', onBack: () => setCurrentPage('MAIN'), isOffline: isOffline, allSynced: allSynced, children: _jsx("div", { className: 'flex flex-col', children: _jsx("div", { className: 'flex flex-col gap-3', children: _jsx("div", { className: "bg-white flex-col flex text-left", children: _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-500 text-xs font-semibold uppercase", children: "Range" }), _jsx(RadioGroup, { items: ranges.map(x => ({
                                            name: x,
                                            onClick: () => setRangeFilter(x),
                                            variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
                                        })) })] }) }) }) }) }), _jsx("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200 ", children: filteredHeatPumps.map(x => _jsx(ListItem, { onClick: () => {
                        setDesign({ ...design, current_heat_pump_uuid: x.uuid });
                        setCurrentPage('MAIN');
                    }, primaryText: x.name, rightBadge: totalHeatLossKW
                        ? _jsx(Badge, { color: x.capacity >= totalHeatLossKW ? 'GREEN' : 'RED', text: x.capacity.toFixed(1) + ' kW' })
                        : _jsx(Badge, { color: 'LIGHT', text: x.capacity.toFixed(1) + ' kW' }) }, x.uuid)) })] });
};
