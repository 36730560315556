import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Icon } from '../../../components/buttons/icon';
import { Modal } from '../../../components/containers/modal';
const variants = {
    BLUE: 'text-blue-800 bg-indigo-100',
    DEFAULT: 'text-gray-600 bg-gray-50'
};
const iconVariants = {
    BLUE: 'text-blue-800',
    DEFAULT: 'text-gray-600'
};
const CanvasNavButton = ({ item }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("button", { onClick: item.confirmText ? () => setModalOpen(true) : item.onClick, className: `p-2 ${variants[item.variant]} rounded-md flex flex-col gap-1 whitespace-nowrap items-center justify-center ${item.disabled ? 'opacity-50' : 'cursor-pointer'}`, children: [_jsx(Icon, { icon: item.icon, colour: iconVariants[item.variant] }), _jsx("div", { className: "text-xs font-semibold", children: item.name })] }), _jsx(Modal, { onConfirm: () => {
                    item.onClick();
                }, visible: modalOpen, title: item.name, setVisible: setModalOpen, children: item.confirmText })] }));
};
export const CanvasNav = ({ items, title, headerContent }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "p-1 bg-white rounded-lg shadow flex flex-col gap-1 items-center", children: [_jsx("div", { className: 'text-gray-900 text-sm font-semibold px-4', children: title }), headerContent, _jsx("div", { className: 'flex gap-1 overflow-x-auto w-full items-center justify-center', children: items.map(x => _jsx(CanvasNavButton, { item: x }, x.name)) })] }) }));
};
