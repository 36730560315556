import { apiUrl, client } from '../axios';
import { RangeHeatPumpSchema } from './range_heat_pump';
import { AuthSDK } from '../utils/auth_provider';
import { z } from 'zod';
// used only to get the list of UUID-name pairs of all possible brand ranges
export const BrandRangeLiteSchema = z.object({
    uuid: z.string(),
    name: z.string()
});
export const BrandRangeSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    brand: z.string(),
    deleted_at: z.coerce.date().nullable().optional(),
    // z.lazy() is necessary to avoid a circular dependency
    // TODO: Can you really have brand ranges without range heat pumps? Check why Python is sending us
    // undefineds here
    range_heat_pumps: z.array(z.lazy(() => RangeHeatPumpSchema)).optional()
});
export const getBrandRange = async (companyUUID, rangeUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/brand_ranges/${rangeUUID}`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error loading Brand Ranges Layers', e);
        return undefined;
    }
};
export const getBrandRangesLite = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/brand_ranges_lite`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.map(x => ({ ...x, deleted_at: x.deleted_at ? new Date(x.deleted_at) : undefined }));
    }
    catch (e) {
        console.error('Error loading Brand Ranges Layers', e);
        return undefined;
    }
};
