import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { MaterialInputField } from './materials/material_input_field';
import { validateInMm } from '../../code/validators';
export const RooflightPage = ({ rooflight, setRooflight, onSave, materials, survey, setMsProps, setPage }) => {
    const isValidForm = [validateInMm(rooflight.width_mm), validateInMm(rooflight.height_mm)].every(x => x.value);
    return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: "p-5 bg-white flex-col gap-5 flex", children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Rooflight material" }), _jsx(MaterialInputField, { selectorProps: {
                        materials,
                        title: 'Rooflight material',
                        surfaceType: 'window',
                        selectedMaterial: rooflight.material,
                        ageBand: survey.age_band,
                        onSelectCallback: (material) => {
                            setRooflight(prev => ({ ...prev, material }));
                        }
                    }, setMsProps: setMsProps, setPage: setPage }), _jsx("div", { className: 'space-y-2', children: _jsxs("div", { className: 'flex gap-2 text-sm text-gray-600', children: [_jsxs("div", { className: 'flex-1 flex flex-col gap-1', children: [_jsx("div", { children: "Width" }), _jsx(Input, { type: 'number', step: 1, value: rooflight.width_mm.toString(), setValue: (e) => setRooflight(prev => ({ ...prev, width_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' })] }), _jsxs("div", { className: 'flex-1 flex flex-col gap-1', children: [_jsx("div", { children: "Height" }), _jsx(Input, { type: 'number', step: 1, value: rooflight.height_mm.toString(), setValue: (e) => setRooflight(prev => ({ ...prev, height_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' })] })] }) }), _jsx(Button, { disabled: !isValidForm, onClick: () => {
                        onSave();
                        setRooflight(undefined);
                    }, children: "Apply" })] }) });
};
