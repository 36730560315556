import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { getBrandRange, getBrandRangesLite } from '../../../../../code/models/brand_range';
import { chain, orderBy } from 'lodash';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { HeatPumpModal } from '../heat_pump_modal';
import { priceCalculations } from '../../../../../code/price_calculations';
import { EmptySection } from '../empty_section';
import { Check, Edit, Plus, Trash } from 'lucide-react';
import { Loader } from '../../../../../components/indicators_and_messaging/loader';
export const HeatPumpsInventory = ({ heatPumps, addHeatPumps, deleteHeatPump, updateHeatPump, company, setCompany, packs }) => {
    const [modalHeatPumpId, setModalHeatPumpId] = useState();
    const [addHeatPumpModalVisible, setAddHeatPumpModalVisible] = useState(false);
    const [addRangeModalVisible, setAddRangeModalVisible] = useState(false);
    // a list of brand ranges
    const [brandRangesLite, setBrandRangesLite] = useState([]);
    // selected brand range in the first modal
    const [selectedBrandRangeUUID, setSelectedBrandRangeUUID] = useState(undefined);
    // when user select a brand range, we're loading it (along with range_heat_pumps) and storing it here
    const [selectedBrandRange, setSelectedBrandRange] = useState(undefined);
    // user selection of range heat pumps is stored here
    const [selectedHeatPumpIds, setSelectedHeatPumpIds] = useState([]);
    const hydratedHeatPumps = chain(orderBy(heatPumps, x => x.range_heat_pump?.name))
        .groupBy(x => x.range_heat_pump?.brand_range?.name ?? '')
        .map((values, key) => ({ key, values }))
        .value();
    const modalHeatPump = heatPumps.find(x => x.uuid === modalHeatPumpId);
    const heatPumpsColumns = [
        { key: 'name', name: 'Name' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const rangeHeatPumpColumns = [
        { key: 'name', name: 'Name' },
        { name: 'Heat Capacity @ 45°C (kW)', render: (row) => _jsx("div", { children: getHeatPumpCapacityAtOutsideTempAndFlowTemp(row, -3, 45).capacityKw }) }
    ];
    // filter out heat pumps that are already in the inventory
    const hpRows = orderBy((selectedBrandRange?.range_heat_pumps ?? []).filter(x => !heatPumps.map(x => x.range_heat_pump_uuid).includes(x.uuid)), x => getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw);
    const setDefaultRange = async (uuid) => {
        if (!uuid)
            return;
        const updatedCompany = { ...company, default_brand_range_uuid: uuid };
        setCompany({ company: updatedCompany, debounce: false });
    };
    const handleLoadBrandRangesLite = async () => {
        // do not load if already loaded
        if (brandRangesLite.length > 0)
            return;
        const response = await getBrandRangesLite(company.public_info.uuid);
        if (response)
            setBrandRangesLite(response);
    };
    const handleLoadAndSelectBrandRange = async () => {
        if (!selectedBrandRangeUUID)
            return;
        const response = await getBrandRange(company.public_info.uuid, selectedBrandRangeUUID);
        if (response)
            setSelectedBrandRange(response);
    };
    const handleAddRangeClick = async () => {
        setAddRangeModalVisible(true);
        await handleLoadBrandRangesLite();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "space-y-6", children: [_jsxs("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full", children: [_jsx(Heading, { size: "2xl", children: "Heat pumps" }), _jsx(Button, { colour: 'DARK', onClick: handleAddRangeClick, iconLeft: Plus, children: "Add range" })] }), !hydratedHeatPumps.length
                        ? _jsx(EmptySection, { title: 'No heat pumps added', description: 'Add heat pumps to your inventory to create estimates and quotes for your customers.', button: _jsx(Button, { onClick: handleAddRangeClick, iconLeft: Plus, children: "Add range" }) })
                        : hydratedHeatPumps.map((brandRange, index) => (_jsx(Section, { title: _jsxs("div", { className: 'flex items-center justify-between gap-3 w-full', children: [_jsxs("div", { className: 'flex items-center gap-2', children: [_jsx(Heading, { size: "xl", children: brandRange.key }), brandRange.values[0].range_heat_pump?.brand_range_uuid === company.default_brand_range_uuid && _jsx(Badge, { color: "LIGHT", text: "Default range" })] }), _jsx(DropdownMenu, { items: [
                                            { label: 'Make default range', onClick: () => setDefaultRange(brandRange.values[0].range_heat_pump?.brand_range_uuid), icon: Check }
                                            // { label: 'Delete range', onClick: noop, icon: Trash } // Not currently implemented
                                        ] })] }), children: _jsx(InventoryTable, { rows: brandRange.values
                                    .sort((a, b) => getHeatPumpCapacityAtOutsideTempAndFlowTemp(a.range_heat_pump, -3, 45).capacityKw - getHeatPumpCapacityAtOutsideTempAndFlowTemp(b.range_heat_pump, -3, 45).capacityKw)
                                    .map(x => {
                                    const packForHeatPump = packs.find(pack => pack.uuid === x.default_pack_uuid);
                                    return {
                                        name: _jsxs("div", { className: "flex flex-col gap-2 items-start", children: [_jsx(Text, { size: "SM", children: x.name }), packForHeatPump && _jsx("div", { className: 'flex gap-1 items-center', children: _jsx(Badge, { color: "LIGHT", text: packForHeatPump.name }) })] }),
                                        costPrice: _jsx(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateCostPrice(x, parseFloat(value)), debounce: true }) }),
                                        markup: _jsx(Input, { type: 'number', step: 1, value: Math.round(x.markup), setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateMarkup(x, parseFloat(value)), debounce: true }), postfix: '%' }),
                                        customerPrice: _jsx(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)), debounce: true }), prefix: '\u00A3' }),
                                        menu: _jsx(DropdownMenu, { items: [
                                                { label: 'Edit', onClick: () => setModalHeatPumpId(x.uuid), icon: Edit },
                                                { label: 'Delete', onClick: () => deleteHeatPump(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this heat pump?' }
                                            ] })
                                    };
                                }), columns: heatPumpsColumns }) }, index)))] }), addRangeModalVisible &&
                _jsx(Modal, { allowOverflow: true, confirmButtonLabel: 'Continue', visible: addRangeModalVisible, setVisible: (val) => setAddRangeModalVisible(val), title: 'Add a heat pump range', onConfirm: async () => {
                        await handleLoadAndSelectBrandRange();
                        setAddRangeModalVisible(false);
                        setAddHeatPumpModalVisible(true);
                    }, children: _jsxs("div", { className: 'flex flex-col gap-2 w-full', children: [brandRangesLite.length === 0 &&
                                _jsx("div", { className: 'flex justify-center items-center w-full h-32', children: _jsx(Loader, {}) }), brandRangesLite.length !== 0 && _jsxs(_Fragment, { children: [_jsx(Select, { dataCy: "add_dropdown", filter: true, selectedKey: selectedBrandRangeUUID, options: orderBy(brandRangesLite.map(x => ({ key: x.uuid, value: x.name })), x => x.value), setSelectedKey: setSelectedBrandRangeUUID }), _jsxs("div", { className: "text-gray-600 text-xs", children: ["Can't find the range you are looking for? ", _jsx("a", { target: "_blank", href: "mailto:support@spruce.eco", className: "underline", rel: "noreferrer", children: "Let us know" }), " and we'll add it for you."] })] })] }) }), addHeatPumpModalVisible && selectedBrandRange &&
                _jsxs(Modal, { onConfirm: async () => {
                        const heatPumpsToAdd = selectedBrandRange.range_heat_pumps?.filter(x => selectedHeatPumpIds.includes(x.uuid)).map(x => ({
                            uuid: crypto.randomUUID(),
                            name: x.name,
                            cost_price: 0,
                            markup: 0,
                            range_heat_pump: { ...x, brand_range: selectedBrandRange },
                            range_heat_pump_uuid: x.uuid
                        }));
                        if (!heatPumpsToAdd)
                            return;
                        await addHeatPumps(heatPumpsToAdd);
                        // reset selected state
                        setSelectedHeatPumpIds([]);
                        setSelectedBrandRange(undefined);
                    }, confirmButtonLabel: 'Add', confirmDisabled: selectedHeatPumpIds.length === 0, visible: addHeatPumpModalVisible, setVisible: (val) => {
                        if (!val) {
                            // reset selected state
                            setSelectedHeatPumpIds([]);
                            setSelectedBrandRange(undefined);
                        }
                        setAddHeatPumpModalVisible(val);
                    }, title: 'Add a heat pump', children: [hpRows.length === 0 &&
                            _jsxs("div", { className: 'w-full', children: ["You have already added all of the ", _jsx("span", { className: 'font-bold', children: selectedBrandRange.name }), " heat pumps to your inventory.", _jsx("br", {})] }), hpRows.length !== 0 &&
                            _jsx(TableLite, { rows: hpRows, rowIdName: 'uuid', columns: rangeHeatPumpColumns, selectedIds: selectedHeatPumpIds, setSelectedIds: setSelectedHeatPumpIds })] }), modalHeatPump && _jsx(HeatPumpModal, { heatPump: modalHeatPump, setVisible: () => setModalHeatPumpId(undefined), updateHeatPump: updateHeatPump, packs: packs })] }));
};
