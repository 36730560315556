import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../calculate_estimate';
import { calculateQuote } from '../calculate_quote';
import { getApproxNumberOfRadiatorChanges } from './estimated_rooms_and_radiators';
export const estimateAndLeadToCalculatedEstimate = (estimate, lead, adminData, company) => {
    const [calculatedQuote] = calculateQuote({
        company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminData.parts,
        labour: adminData.labour,
        packs: adminData.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    return {
        first_name: lead.customer.first_name,
        last_name: lead.customer.last_name,
        address: lead.property.address,
        postcode: lead.property.postcode,
        total_watts: estimate.totalWatts,
        range_heat_pump_uuid: estimate.heatPump?.range_heat_pump?.uuid ?? '',
        range_heat_pump: estimate.heatPump?.range_heat_pump,
        heat_pump_name: estimate.heatPump?.name ?? '',
        range_hot_water_cylinder_uuid: estimate.hotWaterCylinder?.uuid ?? '',
        range_hot_water_cylinder: estimate.hotWaterCylinder,
        hot_water_cylinder_name: estimate.hotWaterCylinder?.name ?? '',
        co2_saved_kg: estimate.CO2SavedKg,
        annual_kwh_heat_required: estimate.annualKwhHeat,
        average_efficiency_baseline: estimate.efficiencyBaseline,
        fuel_name_baseline: estimate.fuelNameBaseline,
        tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
        annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
        average_efficiency_hp: estimate.averageEfficiencyHeatPump,
        tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
        electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
        annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
        tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
        price_cap_description: estimate.priceCapDescription,
        annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
        scop: estimate.sCOP,
        flow_temperature_c: estimate.flowTempC,
        lead_uuid: lead.uuid,
        commutesSaved: estimate.commutesSaved,
        flightsSaved: estimate.flightsSaved,
        lmk_key: lead.epcData?.lmkKey ?? '',
        status: undefined,
        calculated_estimate_quote_items: calculatedQuote,
        sender_snapshot: {
            first_name: adminData.user?.first_name,
            last_name: adminData.user?.last_name,
            email: adminData.user?.email
        }
    };
};
export const getCalculatedEstimate = async (calculatedEstimateId, companyUUID) => await axiosGetV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`);
export const getCalculatedEstimates = async (jobUUID, companyUUID) => await axiosGetV2(`jobs/${jobUUID}/calculated_estimates`, { params: { company_uuid: companyUUID } });
export const insertCalculatedEstimate = async (calculatedEstimate, calculatedEstimateQuoteItems, companyUUID) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates`, {
        ...calculatedEstimate,
        calculated_estimate_quote_items: calculatedEstimateQuoteItems.map(x => ({ ...x, lead_uuid: calculatedEstimate.lead_uuid }))
    });
};
export const updateCalculatedEstimate = async (calculatedEstimate, companyUUID) => {
    await axiosPatchV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}`, calculatedEstimate);
};
// FIXME: UNUSED
export const deleteCalculatedEstimate = async (calculatedEstimateId, companyUUID) => { await axiosDeleteV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`); };
export const sendCallback = async (calculatedEstimateId, companyUUID, discussionContent, callTime) => await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}/callback`, { discussion_content: discussionContent, call_time: callTime });
export const sendSurvey = async (calculatedEstimate, companyUUID, emailHTML) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/survey`, { email_html: emailHTML });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Accepted' }, companyUUID);
};
export const sendRejected = async (calculatedEstimate, companyUUID, reason) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/not_interested`, { reason });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Rejected' }, companyUUID);
};
