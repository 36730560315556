import { apiUrl, axiosDelete, axiosPatch, axiosPost, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
import { parseWithZod } from '../zod';
import { InventoryHeatPumpSchema } from './inventory';
export const getHeatPumps = async (companyUUID, jobUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/heat-pumps${jobUUID ? `?job_uuid=${jobUUID}` : ''}`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        const parsedResult = result.data.data.map(x => parseWithZod(InventoryHeatPumpSchema, ({ ...x.attributes, uuid: x.uuid })));
        return parsedResult;
    }
    catch (e) {
        console.error('Error getting heat pumps', e);
        return undefined;
    }
};
export const updateHeatPump = async (heatPump, companyUUID) => {
    await axiosPatch(`teams/${companyUUID}/heat-pumps/${heatPump.uuid}`, {
        name: heatPump.name,
        description: heatPump.description,
        cost_price: heatPump.cost_price,
        markup: heatPump.markup,
        range_heat_pump_uuid: heatPump.range_heat_pump_uuid,
        default_pack_uuid: heatPump.default_pack_uuid
    }, heatPump.uuid, 'heat-pumps');
};
export const insertHeatPump = async (heatPump, companyUUID) => {
    return await axiosPost(`teams/${companyUUID}/heat-pumps`, {
        uuid: heatPump.uuid,
        name: heatPump.name,
        description: heatPump.description,
        cost_price: heatPump.cost_price,
        markup: heatPump.markup,
        range_heat_pump_uuid: heatPump.range_heat_pump_uuid
    }, 'heat-pumps');
};
export const deleteHeatPump = async (heatPumpUUID, companyUUID) => {
    await axiosDelete(`teams/${companyUUID}/heat-pumps/${heatPumpUUID}`);
};
