import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { toastError } from './components/indicators_and_messaging/toast';
import { noop } from 'lodash';
export const AppContext = React.createContext({
    globalState: {
        source: ''
    },
    setGlobalState: noop,
    setError: noop
});
export const AppProvider = ({ children }) => {
    const [globalState, setGlobalState] = useState({
        source: ''
    });
    const setError = (e) => {
        const axiosError = e;
        if (axiosError.response?.status === 401) {
            // TODO: logout setGlobalState((prev) => ({ ...prev, isLoggedIn: false }));
            // setGlobalState((prev) => ({ ...prev, toastState: toastError("You are not logged in.") }));
            // return;
        }
        let errorMessage = 'We had an issue fulfilling that request, please contact joseph@spruce.eco.';
        if (axiosError.response?.data) {
            errorMessage = axiosError.response.data.detail?.length > 0
                ? axiosError.response.data.detail
                : _jsx("ul", { children: (axiosError.response.data.errors?.map(x => x.detail)).map(x => _jsx("li", { children: x }, x)) });
        }
        setGlobalState((prev) => ({ ...prev, toastState: toastError(errorMessage) }));
        if (axiosError.response?.status !== 401)
            throw e;
    };
    return (_jsx(AppContext.Provider, { value: { globalState, setGlobalState, setError }, children: children }));
};
