import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { ClickableCard } from '../../../../components/content_display/card';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsCableRoutingBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Cable routing" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Distance from main board to heat pump location" }), _jsx(Input, { type: 'number', value: survey.electrics_distance_from_board_to_heat_pump_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_board_to_heat_pump_m: Number(e) }), postfix: 'm' })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Distance from heat pump location to cylinder" }), _jsx(Input, { type: 'number', value: survey.electrics_distance_from_cylinder_to_heat_pump_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_cylinder_to_heat_pump_m: Number(e) }), postfix: 'm' })] }), _jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs("div", { className: "gap-3 flex items-center justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Cylinder immersion feed required" }), _jsx(Toggle, { value: survey.electrics_cylinder_immersion_required, setValue: () => setSurvey({
                                                ...survey,
                                                electrics_cylinder_immersion_required: !survey.electrics_cylinder_immersion_required
                                            }) })] }), survey.electrics_cylinder_immersion_required && _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Distance from main board to cylinder" }), _jsx(Input, { type: 'number', value: survey.electrics_distance_from_board_to_cylinder_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_board_to_cylinder_m: Number(e) }), postfix: 'm' })] }) })] }) }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Notes" }), _jsx(TextArea, { value: survey.electrics_cable_notes, setValue: (e) => setSurvey({ ...survey, electrics_cable_notes: e }) })] })] })] });
};
