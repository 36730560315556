export const createPermissionTree = (permissions) => {
    const graph = permissions.filter(x => !x.parent_uuid).map(x => createPermissionTreeInner(x, permissions));
    return graph;
};
const createPermissionTreeInner = (permission, permissions) => {
    const childPermissions = permissions
        .filter(x => x.parent_uuid === permission.uuid)
        .map(x => createPermissionTreeInner(x, permissions));
    return { ...permission, permissions: childPermissions };
};
export const getDescendents = (permission) => {
    if (permission.permissions.length === 0)
        return [permission];
    const permissions = permission.permissions.flatMap(x => getDescendents(x));
    return permissions;
};
