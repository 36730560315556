import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName, TTPlaceholderCustomerName } from '../../../../../code/tiptap_placeholders';
import { Modal } from '../../../../../components/containers/modal';
import { logEvent } from '../../../../../code/log_event';
import { sendDeclined } from '../../../../../code/models/lead';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { createJobEvent, JobEventType } from '../../../../../code/models/job_event';
export const SendDeclinedModalBlock = ({ isVisible, setIsVisible, adminContext, lead, setSnackbarText, stages, setLead, company }) => {
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    if (!company)
        return;
    const companyUUID = company.public_info.uuid;
    useEffect(() => {
        if (!company)
            return;
        // the email template used to send the estimate
        const companyDeclineEmailTemplate = getCompanyDeclinedEmailTemplate(company.decline_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(companyDeclineEmailTemplate, {
            ...defaultPlaceholderMappings(company.public_info, adminContext.data.user),
            [TTPlaceholderCustomerName.id]: lead.customer?.first_name ?? '',
            [TTPlaceholderCustomerFirstName.id]: lead.customer?.first_name ?? '',
            [TTPlaceholderCustomerLastName.id]: lead.customer?.last_name ?? '',
            [TTPlaceholderCustomerAddress.id]: `${lead.property.address}, ${lead.property.postcode}`
        });
        setEmailTemplateJSON(emailJSON);
    }, [company]);
    return _jsx(Modal, { title: 'Decline enquiry message', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            logEvent({ name: 'Decline Enquiry Clicked', properties: {} }, company?.public_info?.subdomain ?? '');
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON);
            await sendDeclined({ leadUUID: lead.uuid, companyUUID: lead.company_uuid, message: emailHTML });
            const statuses = stages.flatMap(x => x.statuses);
            const oldStatus = statuses.find(x => x.uuid === lead.status_uuid);
            const newStatus = statuses.find(x => x.code === 'Declined');
            setLead({ ...lead, status_uuid: newStatus.uuid }, adminContext.data.user);
            await createJobEvent({
                event_type: JobEventType.enum.EnquiryDeclined,
                company_uuid: companyUUID,
                job_uuid: lead.uuid,
                extra_data: {
                    email_content: emailHTML,
                    recipients: [lead.customer?.email]
                }
            });
            if (oldStatus?.uuid !== newStatus?.uuid) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyUUID,
                    job_uuid: lead.uuid,
                    extra_data: {
                        old_status: oldStatus?.uuid,
                        new_status: newStatus?.uuid
                    }
                });
            }
            setSnackbarText('Decline email sent');
        }, confirmButtonLabel: "Send", children: _jsx("div", { className: "flex flex-col gap-4", children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                            setEmailTemplateJSON(editor.getJSON());
                        }, content: emailTemplateJSON }), company?.public_info?.logo && _jsx("img", { src: company?.public_info?.logo, className: "max-w-40", alt: "Installer logo" })] }) }) });
};
