import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SurveyEHSContext } from '../existing_heating_system';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getPipeModelName, PIPE_MODELS } from '../../../../code/models/pipes';
import { pipeMaterials } from '../../../../code/models/property';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { Check } from 'lucide-react';
export const EHSDefaultRadiatorPipeworkBlock = () => {
    const ehsContext = React.useContext(SurveyEHSContext);
    const { survey, setSurvey } = ehsContext;
    const RADIATOR_VISIBILITY = ['Surface', 'Concealed', 'Mixed'];
    const radiatorPipeModel = PIPE_MODELS.find(x => x.uuid === survey.existing_system_radiator_pipework_uuid);
    const [radiatorPipeMaterial, setRadiatorPipeMaterial] = useState(radiatorPipeModel?.material ?? 'Copper');
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Default radiator pipework" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Radiator pipework visibility" }), _jsx(RadioGroup, { items: RADIATOR_VISIBILITY.map(x => ({
                                    name: x,
                                    onClick: () => setSurvey({ ...survey, existing_system_radiator_pipework_visibility: x }),
                                    variant: survey.existing_system_radiator_pipework_visibility === x ? 'ACTIVE' : 'DEFAULT'
                                })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Radiator pipework material" }), _jsx(RadioGroup, { items: pipeMaterials.map(x => ({
                                    name: x,
                                    onClick: () => setRadiatorPipeMaterial(x),
                                    variant: radiatorPipeMaterial === x ? 'ACTIVE' : 'DEFAULT'
                                })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Radiator pipework diameter" }), _jsx(Badge, { color: survey.existing_system_radiator_pipework_uuid ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.existing_system_radiator_pipework_uuid ? Check : undefined })] }), _jsx(Select, { options: PIPE_MODELS.filter(x => x.material === radiatorPipeMaterial).map(x => ({
                                    key: x.uuid,
                                    value: getPipeModelName(x)
                                })), selectedKey: survey.existing_system_radiator_pipework_uuid, setSelectedKey: (e) => setSurvey({ ...survey, existing_system_radiator_pipework_uuid: e }) })] })] })] });
};
