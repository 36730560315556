import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Section } from '../../components/section';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Button } from '../../../../../components/buttons/button';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../../../code/calculate_estimate';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { CalculatedQuoteDefaultGroups, calculateQuote, quoteItemNameWithQuantity } from '../../../../../code/calculate_quote';
import { AdminContext } from '../../../admin_layout';
import { groupBy, sum } from 'lodash';
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators';
import { AlertTriangle, Edit } from 'lucide-react';
import { calculateSumIncludingVatOnSurvey } from './cost_breakdown';
export const CostEstimate = ({ lead, estimate, navigateTo, company }) => {
    const adminContext = useContext(AdminContext);
    const [calculatedQuote] = calculateQuote({
        company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, company?.estimate_default_radiator_change_percentage ?? DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name');
    const quoteHeatPump = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]?.[0];
    const quoteHotWaterCylinder = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]?.[0];
    const systemDesignRows = [
        { key: 'Heat pump', value: estimate.heatPump?.name || _jsx(Badge, { color: "RED", text: "No heat pump selected", icon: AlertTriangle }) },
        { key: 'Cylinder', value: estimate.hotWaterCylinder?.name || _jsx(Badge, { color: "RED", text: "No hot water cylinder selected", icon: AlertTriangle }) },
        { key: 'Flow temperature (°C)', value: estimate.flowTempC },
        // { key: 'Radiator changes', value: estimate.radiator_changes },
        { key: `SCOP at ${estimate.flowTempC}°C`, value: estimate.sCOP }
    ];
    const costBreakdownRows = [
        ...(quoteHeatPump ? [{ key: quoteItemNameWithQuantity(quoteHeatPump), value: formatPrice(quoteHeatPump.subtotal) }] : []),
        ...(quoteHotWaterCylinder ? [{ key: quoteItemNameWithQuantity(quoteHotWaterCylinder), value: formatPrice(quoteHotWaterCylinder.subtotal) }] : []),
        ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]?.map(part => ({ key: quoteItemNameWithQuantity(part), value: formatPrice(part.subtotal) })) || [],
        ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]?.map(radiator => ({ key: quoteItemNameWithQuantity(radiator), value: formatPrice(radiator.subtotal) })) || [],
        ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]?.map(ufh => ({ key: quoteItemNameWithQuantity(ufh, 'm²'), value: formatPrice(ufh.subtotal) })) || [],
        ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]?.map(labour => ({ key: quoteItemNameWithQuantity(labour, 'days'), value: formatPrice(labour.subtotal) })) || [],
        ...groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]?.map(survey => ({ key: quoteItemNameWithQuantity(survey), value: formatPrice((survey.subtotal ?? 0) * 1.2) })) || [],
        ...(groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.some(grant => grant.selected) ? groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]?.map(grant => ({ key: quoteItemNameWithQuantity(grant), value: formatPrice(grant.subtotal) })) || [] : [])
    ];
    const handleEditCostEstimate = () => {
        const url = `/admin/quotes/${lead.uuid}/estimate/cost-estimate`;
        navigateTo(url);
    };
    return (_jsx(Section, { title: "Cost estimate", controls: _jsx(Button, { size: "SM", iconLeft: Edit, colour: 'LIGHT', onClick: handleEditCostEstimate, children: "Edit cost estimate" }), children: _jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs("div", { className: 'flex items-center justify-between', children: [_jsx(Text, { bold: true, children: "Estimate total" }), _jsx(Text, { bold: true, children: formatPrice(sum(calculatedQuote.map(calculateSumIncludingVatOnSurvey))) })] }), _jsxs("div", { children: [_jsx(Text, { bold: true, className: 'mb-1', children: "System design" }), _jsx(SummaryTable, { rows: systemDesignRows })] }), _jsxs("div", { children: [_jsx(Text, { bold: true, className: 'mb-1', children: "Cost breakdown" }), _jsx(SummaryTable, { rows: costBreakdownRows }), (estimate.isScottish && lead.scottish_rural) && _jsx(Alert, { className: "mt-3", type: 'SUCCESS', children: "This property is likely to be eligible for the HES rural uplift." })] })] }) }));
};
