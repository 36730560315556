import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Icon } from '../../../components/buttons/icon';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { Check, Edit, MoreVertical, Plus, Trash } from 'lucide-react';
const Item = ({ item, handleEditItem, handleDeleteItem, isEditing, setIsEditing, editingText, setEditingText, dragDisabled }) => {
    const editingTextValid = editingText.trim().length > 0;
    return (_jsxs("div", { className: `${item.chosen ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-50 py-2 px-4 flex items-center gap-4`, children: [_jsx("span", { className: `handle ${dragDisabled && 'pointer-events-none'} pr-2 cursor-move`, children: _jsx(Icon, { icon: MoreVertical }) }), !isEditing && (_jsx("span", { className: 'flex-grow select-none', children: item.name })), isEditing && (_jsx(Input, { value: editingText, setValue: setEditingText, className: 'flex-grow' })), !isEditing && _jsx(Icon, { onClick: () => { setEditingText(item.name); setIsEditing(item.id); }, icon: Edit }), !isEditing && _jsx(Icon, { onClick: () => handleDeleteItem(item.id), icon: Trash }), isEditing && _jsx(Icon, { disabled: !editingTextValid, onClick: () => handleEditItem(item.id), "data-cy": 'table_save_button', icon: Check })] }));
};
export const SortableList = ({ items, setItems, extraActionButtons }) => {
    const [editingId, setEditingId] = useState(null);
    const [editingText, setEditingText] = useState('');
    const updateIds = (newItems) => {
        return newItems.map((item, index) => ({
            ...item,
            id: index + 1
        }));
    };
    const handleEditItem = (id) => {
        const newState = items.map((item) => {
            if (item.id === id) {
                return { ...item, name: editingText };
            }
            return item;
        });
        setItems(updateIds(newState));
        setEditingId(null);
    };
    const handleDeleteItem = (id) => {
        const newState = items.filter((item) => item.id !== id);
        setItems(updateIds(newState));
    };
    const handleAddItem = () => {
        const newState = [...items, { id: items.length + 1, name: 'New item' }];
        setItems(updateIds(newState));
    };
    if (!items.length) {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'p-4 border border-gray-200 text-gray-500 text-sm text-center', children: "No options. Add some to get started." }), _jsx(Button, { onClick: () => handleAddItem(), children: "Add option" })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(ReactSortable, { list: items, setList: (newState) => {
                    setItems(updateIds(newState));
                }, handle: ".handle", className: 'divide-y', children: items.map((item) => (_jsx(Item, { dragDisabled: editingId !== null, item: item, handleEditItem: handleEditItem, handleDeleteItem: handleDeleteItem, isEditing: editingId === item.id, setIsEditing: setEditingId, editingText: editingText, setEditingText: setEditingText }, item.id))) }), _jsxs("div", { className: 'flex gap-2', children: [_jsx(Button, { colour: 'LIGHT', onClick: () => handleAddItem(), iconLeft: Plus, children: "Add" }), extraActionButtons?.map(action => action)] })] }));
};
