import { useEffect, useState } from 'react';
// Store the current page of the SPA
// Listen to the browser back button
// On any navigation change check if we have any service worker updates (because we are SPA usual lifecycle check does not work)
export const useNavigation = () => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname.replace(/\/$/, ''));
    useEffect(() => {
        const handlePopState = () => {
            setCurrentPath(window.location.pathname.replace(/\/$/, ''));
            checkServiceWorker();
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState); // cleanup on unmount
        };
    }, []);
    const navigateTo = (url) => {
        history.pushState(null, '', url);
        // In case the URL has a query string, we need to remove it before setting the state
        setCurrentPath(url.split('?')[0].replace(/\/$/, ''));
        checkServiceWorker();
    };
    return { currentPath, navigateTo };
};
const checkServiceWorker = async () => {
    if ('serviceWorker' in navigator && ['production', 'staging'].includes(process.env.APP_ENV)) {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
            registration.update();
        }
    }
};
