import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { BUILD_REG_AGE_BANDS, BUILD_REG_VALUE_TYPES } from '../../../code/models/building_regs';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Info } from '../../../components/buttons/info';
export const BuildingRegsStep = () => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [buildingRegs, setBuildingRegs] = useState({
        jurisdiction: 'england_wales',
        ageBand: '2023-',
        valueType: 'limiting'
    });
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                buildingRegs
            }
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "What regs is the building being built to?" }), _jsx(SurveyOptionGroup, { formLabel: _jsx(FormLabel, { labelText: 'Building regs' }), options: BUILD_REG_AGE_BANDS.map(x => ({ key: x.key, label: x.label })), noCheckmark: true, inline: true, onSelect: (key) => {
                    setBuildingRegs(prev => ({ ...prev, ageBand: key }));
                }, selectedOptionKey: buildingRegs.ageBand }), _jsx(SurveyOptionGroup, { formLabel: _jsx(FormLabel, { labelText: 'Design to', info: _jsx(Info, { infoModalHeader: 'Limiting vs. Notional values', infoModalBody: _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("p", { children: "New build regulations list both \"Limiting\" and \"Notional\" U-value and air tightness values. Using \"Limiting\" values will give a higher heat loss than using \"Notional\" values" }), _jsxs("ul", { className: 'list-disc pl-5 gap-1', children: [_jsx("li", { children: "Limiting values are worst allowable values for that element. So if the limiting U-value for external walls is 0.23 the external wall U-value cannot be higher than that." }), _jsx("li", { children: "Notional values give the performance that the whole home needs to achieve overall. So the performance of the home must be the same or better than it would be if it met these notional values. But if for example you want to put in better external walls and worse windows, that is allowed." })] })] }) }) }), options: BUILD_REG_VALUE_TYPES.map(x => ({ key: x.key, label: x.label })), noCheckmark: true, inline: true, onSelect: (key) => {
                    setBuildingRegs(prev => ({ ...prev, valueType: key }));
                }, selectedOptionKey: buildingRegs.valueType }), _jsx(Button, { size: 'LG', onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
