import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CanvasNav } from './canvas_nav';
import * as pdfjs from 'pdfjs-dist/webpack.mjs';
import { removeUndefinedFromList } from '../../../code/helpers';
import { DEFAULT_IMAGE_SCALE } from './code/constants';
import { FileText, Image, Pencil, Plus } from 'lucide-react';
export const FloorButtons = ({ currentFloor, setPage, setShowFloorPlanButtons, setDrawingRooms, intersectingShapes }) => {
    return _jsx(_Fragment, { children: _jsx(CanvasNav, { title: currentFloor.name, items: removeUndefinedFromList([
                { name: 'Details', icon: FileText, onClick: () => setPage('FLOOR_DETAILS', true), variant: 'DEFAULT' },
                { name: 'Add room', disabled: intersectingShapes.length > 0, icon: Plus, onClick: () => setPage('ADD_ROOM', true), variant: 'DEFAULT' },
                { name: 'Draw room', icon: Pencil, onClick: () => setDrawingRooms(true), variant: 'DEFAULT' },
                {
                    name: `${currentFloor.floor_plan_image || currentFloor.floor_plan_url ? '1 ' : ''}Floor plan`,
                    icon: Image,
                    onClick: () => setShowFloorPlanButtons(true),
                    variant: currentFloor.floor_plan_image || currentFloor.floor_plan_url ? 'BLUE' : 'DEFAULT'
                }
            ]) }) });
};
export const FloorPlanInput = ({ inputRef, setTempImageAndScale, setStageStep }) => {
    return _jsx("input", { ref: inputRef, className: 'hidden', type: "file", accept: '*', onChange: (e) => {
            const selectedFile = e.target?.files?.[0];
            e.target.value = ''; // Make sure user can upload file with same name consecutively. Input will ignore files with same name if already uploaded by default.
            if (!selectedFile)
                return;
            const maxFileSize = 10 * 1024 * 1024; // 10mb
            if (selectedFile.size > maxFileSize) {
                alert('File size exceeds the 10 MB limit. Please upload a smaller file.');
                return;
            }
            const fileReader = new FileReader();
            if (selectedFile.type === 'application/pdf') {
                fileReader.onload = async (e) => {
                    const pdfData = e.target?.result;
                    const image = await stitchPDFPages(pdfData);
                    setTempImageAndScale({ image, scale: DEFAULT_IMAGE_SCALE });
                    setStageStep(1);
                };
                fileReader.readAsArrayBuffer(selectedFile);
            }
            else {
                fileReader.onload = async (e) => {
                    const image = e.target?.result;
                    setTempImageAndScale({ image, scale: DEFAULT_IMAGE_SCALE });
                    setStageStep(1);
                };
                fileReader.readAsDataURL(selectedFile);
            }
        } });
};
const stitchPDFPages = async (pdfData) => {
    const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
    const numPages = pdf.numPages;
    // Create an array to hold the canvas elements for each page
    const canvases = [];
    // Render each page to a canvas
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 3 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const renderContext = {
            canvasContext: context,
            viewport
        };
        await page.render(renderContext).promise;
        canvases.push(canvas);
    }
    // Combine all canvases horizontally
    const totalWidth = canvases.reduce((sum, canvas) => sum + canvas.width, 0);
    const maxHeight = Math.max(...canvases.map(canvas => canvas.height));
    const combinedCanvas = document.createElement('canvas');
    combinedCanvas.width = totalWidth;
    combinedCanvas.height = maxHeight;
    const combinedContext = combinedCanvas.getContext('2d');
    let xOffset = 0;
    canvases.forEach(canvas => {
        combinedContext?.drawImage(canvas, xOffset, 0);
        xOffset += canvas.width;
    });
    // Convert combined canvas to base64 image
    const base64Image = combinedCanvas.toDataURL('image/png');
    return base64Image;
};
