import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const SiteDetailsMainsWaterDetailsBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey } = prContext;
    const isFullScreen = flowContext?.viewMode === 'FLOW';
    return _jsxs(_Fragment, { children: [isFullScreen && _jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Mains water" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "items-center gap-3 flex justify-between", children: [_jsx("div", { className: "flex-col flex", children: _jsx("div", { className: "text-gray-900 text-base font-bold", children: "Stopcock works" }) }), _jsx(Toggle, { size: 'LG', value: survey.stopcock_works, setValue: () => setSurvey({ ...survey, stopcock_works: !survey.stopcock_works }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Stopcock location" }), _jsx(TextArea, { value: survey.stopcock_location, setValue: (e) => setSurvey({ ...survey, stopcock_location: e }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Size of water main" }), _jsx(Input, { type: 'number', value: survey.size_of_water_main_mm.toString(), setValue: (e) => setSurvey({ ...survey, size_of_water_main_mm: Number(e) }), postfix: _jsx("span", { className: 'text-gray-500', children: "mm" }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Mains flow rate" }), _jsx(Input, { type: 'number', value: survey.mains_flow_rate_l_per_min.toString(), setValue: (e) => setSurvey({ ...survey, mains_flow_rate_l_per_min: Number(e) }), postfix: _jsx("span", { className: 'text-gray-500', children: "L/min" }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Mains pressure" }), _jsx(Input, { type: 'number', value: survey.mains_pressure_bar.toString(), setValue: (e) => setSurvey({ ...survey, mains_pressure_bar: Number(e) }), postfix: _jsx("span", { className: 'text-gray-500', children: "bar" }) })] })] })] });
};
