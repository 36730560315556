import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const colorVariants = {
    DEFAULT: [
        'bg-indigo-400',
        'bg-red-400',
        'bg-yellow-400',
        'bg-fuchsia-400',
        'bg-teal-400',
        'bg-violet-400',
        'bg-orange-400',
        'bg-green-400',
        'bg-cyan-400',
        'bg-blue-400',
        'bg-amber-400',
        'bg-emerald-400',
        'bg-sky-400',
        'bg-rose-400',
        'bg-purple-400',
        'bg-pink-400'
    ],
    EMITTER: [
        'bg-red-200',
        'bg-red-400',
        'bg-red-600',
        'bg-red-800'
    ]
};
export const ProgressChart = ({ total, items, colorVariant }) => {
    const itemsFiltered = items.filter(x => x.value > 0);
    // May cause issues that we don't adjust the total to not deal with the negative values - keep an eye on it
    const colours = colorVariant ? colorVariants[colorVariant] : colorVariants.DEFAULT;
    return _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: "w-full bg-gray-200 rounded h-4 flex", children: itemsFiltered.map((x, i) => _jsx("div", { style: { width: `${Math.ceil((x.value / total) * 100)}%` }, className: `${colours[i % colours.length]} h-4 first:rounded-l last:rounded-r` }, i)) }), _jsx("div", { className: 'flex gap-2 flex-wrap', children: itemsFiltered.map((x, i) => _jsxs("div", { className: "justify-center items-center gap-1 flex", children: [_jsx("div", { className: `w-1.5 h-1.5 ${colours[i % colours.length]} rounded-full` }), _jsx("div", { className: "text-gray-600 text-xs font-bold flex-nowrap", children: x.name })] }, i)) })] });
};
