import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from '../../../../../components/containers/section';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Button } from '../../../../../components/buttons/button';
import { Heading } from '../../../../../components/content_display/heading';
import { Text } from '../../../../../components/content_display/text';
import { Link } from '../../../../../components/buttons/link';
import { noop } from 'lodash';
import { DEFAULT_BRAND_COLOUR_PRIMARY, DEFAULT_BRAND_COLOUR_SECONDARY, determineBestTextShade } from '../../../../../code/utils/colour_utils';
import { validateHexColourCode } from '../../../../../code/validators';
import { RefreshCw } from 'lucide-react';
export const BrandColoursBlock = ({ company, setCompany }) => {
    const handleResetColours = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                colour_primary: DEFAULT_BRAND_COLOUR_PRIMARY,
                colour_secondary: DEFAULT_BRAND_COLOUR_SECONDARY
            }
        };
        setCompany(newCompany);
    };
    return _jsx(Section, { border: true, title: "Company colours", children: _jsxs("div", { className: 'grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2', children: [_jsxs("div", { className: 'flex flex-col justify-between gap-4 row-start-2 md:row-start-1', children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(FormLabel, { labelText: 'Primary', helperText: 'Used for main buttons.' }), _jsx(ColorInput, { value: company.public_info.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY.toUpperCase(), setValue: colour => {
                                        if (!colour)
                                            return;
                                        if (validateHexColourCode(colour ?? DEFAULT_BRAND_COLOUR_PRIMARY).value) {
                                            setCompany({ ...company, public_info: { ...company.public_info, colour_primary: colour } });
                                        }
                                    } }), _jsx(FormLabel, { labelText: 'Secondary', helperText: 'Used for background accents.' }), _jsx(ColorInput, { value: company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY.toUpperCase(), setValue: colour => {
                                        if (!colour)
                                            return;
                                        if (validateHexColourCode(colour ?? DEFAULT_BRAND_COLOUR_SECONDARY).value) {
                                            setCompany({ ...company, public_info: { ...company.public_info, colour_secondary: colour } });
                                        }
                                    } })] }), _jsx(Button, { colour: 'LIGHT', iconLeft: RefreshCw, disabled: (!company.public_info.colour_primary || company.public_info.colour_primary === DEFAULT_BRAND_COLOUR_PRIMARY) && (!company.public_info.colour_secondary || company.public_info.colour_secondary === DEFAULT_BRAND_COLOUR_SECONDARY), onClick: handleResetColours, confirmText: 'Are you sure?', children: "Reset to defaults" })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(FormLabel, { labelText: 'Preview', helperText: 'This is what your estimates will look like' }), _jsx("div", { style: { backgroundColor: company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY }, className: 'w-full h-full border rounded-lg', children: _jsxs("div", { className: "px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg", children: [_jsxs("div", { className: "flex flex-col gap-3 text-center", children: [_jsx(Heading, { size: "2xl", className: determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white', children: "Book a full heat loss survey today" }), _jsx(Text, { className: determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white', children: "Or request a callback to discuss your needs in more detail." })] }), _jsxs("div", { className: "w-full max-w-80 flex flex-col gap-3 items-center", children: [_jsx(Button, { style: {
                                                    backgroundColor: company.public_info.colour_primary,
                                                    borderColor: company.public_info.colour_primary,
                                                    color: determineBestTextShade(company.public_info.colour_primary ?? DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                                }, className: 'w-full', onClick: noop, children: "View cost estimate" }), _jsx(Button, { colour: 'LIGHT', className: 'w-full', children: "Request a callback" }), _jsx(Link, { className: determineBestTextShade(company.public_info.colour_secondary ?? DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white', text: 'I\u2019m not interested right now', onClick: noop })] })] }) })] })] }) });
};
const ColorInput = ({ value, setValue, disabled }) => {
    const handleColourChange = (e) => {
        setValue(e.target.value);
    };
    return _jsxs("div", { className: 'flex gap-2', children: [_jsx(Input, { value: value.toUpperCase(), setValue: e => setValue(e), validator: validateHexColourCode, validateImmediately: true }), _jsx("div", { style: { backgroundColor: value }, className: `rounded-lg border max-h-9 border-gray-300 ${disabled === true ? 'opacity-70' : 'opacity-100'}`, children: _jsx("input", { type: 'color', className: 'opacity-0 h-full disabled:cursor-auto cursor-pointer', onChange: handleColourChange, value: value }) })] });
};
