import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { TabGroup } from '../../../components/content_display/tab';
export const TabDemo = () => {
    const [currentTab, setCurrentTab] = useState('One');
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(TabGroup, { items: [
                            { name: 'One', secondaryText: 'secondary', onClick: () => setCurrentTab('One'), variant: currentTab === 'One' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Two', secondaryText: undefined, onClick: () => setCurrentTab('Two'), variant: currentTab === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Three', secondaryText: undefined, onClick: () => setCurrentTab('Three'), variant: currentTab === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                        ] })] })] });
};
const componentProps = [
    { name: 'items', description: 'Action that should happen when clicking' },
    { name: 'name', description: 'Primary tab text' },
    { name: 'secondaryText', description: 'Lighter secondary text, optional' },
    { name: 'onClick', description: 'What should we action onClick, usually update tab state' },
    { name: 'variant', description: 'ACTIVE | DEFAULT' }
];
const codeBlock = `<TabGroup items={[
  { name: 'One', secondaryText: 'secondary', onClick: () => setCurrentTab('One'), variant: currentTab === 'One' ? 'ACTIVE' : 'DEFAULT' },
  { name: 'Two', secondaryText: undefined, onClick: () => setCurrentTab('Two'), variant: currentTab === 'Two' ? 'ACTIVE' : 'DEFAULT' },
  { name: 'Three', secondaryText: undefined, onClick: () => setCurrentTab('Three'), variant: currentTab === 'Three' ? 'ACTIVE' : 'DEFAULT' }
]} />`;
