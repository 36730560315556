import { jsx as _jsx } from "react/jsx-runtime";
import { getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName } from '../../../../../code/tiptap_placeholders';
export const DeclineEnquiryEmailTemplateBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: "Decline enquiry email template", children: _jsx("div", { className: 'flex flex-col gap-4', children: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                    setCompany({
                        ...company,
                        decline_template: JSON.stringify(editor.getJSON())
                    });
                }, placeholders: [
                    TTPlaceholderCustomerFirstName,
                    TTPlaceholderCustomerLastName,
                    TTPlaceholderCustomerAddress,
                    ...defaultPlaceholders
                ], content: getCompanyDeclinedEmailTemplate(company.decline_template ?? '') }) }) });
};
