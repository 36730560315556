import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Check } from 'lucide-react';
export const BadgeDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsxs("div", { className: 'flex gap-2', children: [_jsx(Badge, { text: 'My badge', color: 'BLUE', icon: Check }), _jsx(Badge, { text: 'My badge', color: 'DARK' }), _jsx(Badge, { text: 'My badge', color: 'GREEN' }), _jsx(Badge, { text: 'My badge', color: 'INDIGO' }), _jsx(Badge, { text: 'My badge', color: 'LIGHT' }), _jsx(Badge, { text: 'My badge', color: 'RED' }), _jsx(Badge, { text: 'My badge', color: 'YELLOW' })] })] })] });
};
const componentProps = [
    { name: 'text', description: 'Primary badge text' },
    { name: 'color', description: 'Primary badge colour' },
    { name: 'icon', description: 'Font awesome icon, if present shows on the left' },
    { name: 'button', description: 'Button to show on the right' }
];
const codeBlock = `<Badge
  text='My badge'
  color='BLUE'
  icon={Check}
/>`;
