import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { ClickableCard } from '../../../../components/content_display/card';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Heading } from '../../../../components/content_display/heading';
import { TableLite } from '../../../../components/content_display/table_lite';
import { formatDateDDMMYYYY } from '../../../../code/format_date';
import { BusApplicationStatus, calcBusVoucherExpirationDate } from '../../../../code/models/paperwork';
export const PreInstallPage = (props) => {
    const busApplicationStatus = props.lead.bus_application_status ?? BusApplicationStatus.NotStarted;
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsxs(Alert, { type: 'INFO', children: ["Paperwork is in beta: Test this new feature for free and send us your feedback!", _jsx("br", {}), "Any usage during this testing period won't count against your credits."] }), _jsx(Heading, { size: 'xl', children: "Pre-install" }), _jsx(ClickableCard, { disabled: true, variant: 'WHITE', onClick: () => props.navigateTo('/admin/job_layout/paperwork/pre_install'), title: _jsxs("div", { className: 'flex flex-row gap-4 w-full justify-between', children: [_jsx("span", { children: "DNO application" }), _jsx(Badge, { text: 'Not started', color: 'LIGHT' })] }), children: "Coming soon!" }), _jsx(ClickableCard, { variant: 'WHITE', onClick: () => props.navigateTo('/paperwork/pre-install/bus'), title: _jsxs("div", { className: 'flex flex-row gap-4 w-full justify-between', children: [_jsx("span", { children: "BUS voucher application" }), busApplicationStatus === BusApplicationStatus.NotStarted && _jsx(Badge, { color: 'LIGHT', text: 'Not started' }), busApplicationStatus === BusApplicationStatus.ApplicationSubmitted && _jsx(Badge, { color: 'YELLOW', text: 'Application submitted' }), busApplicationStatus === BusApplicationStatus.VoucherIssued && _jsx(Badge, { color: 'GREEN', text: 'Voucher issued' })] }), children: _jsx("div", { className: 'max-w-96', children: _jsx(TableLite, { size: 'SM', hasHeader: false, alignRight: true, columns: [
                            { key: 'title', name: '' },
                            { key: 'date', name: '' }
                        ], rows: [
                            // TODO: { title: 'Submitted', date: 'Not started' },
                            { title: 'Date of issue', date: props.lead.bus_voucher_date ? formatDateDDMMYYYY(new Date(props.lead.bus_voucher_date)) : 'n/a' },
                            { title: 'Eligible until', date: calcBusVoucherExpirationDate(props.lead.bus_voucher_date) || 'n/a' }
                        ] }) }) }), _jsx(ClickableCard, { disabled: true, variant: 'WHITE', onClick: () => props.navigateTo('/admin/job_layout/paperwork/pre_install'), title: _jsxs("div", { className: 'flex flex-row gap-4 w-full justify-between', children: [_jsx("span", { children: "Installation pack" }), _jsx(Badge, { text: 'Not started', color: 'LIGHT' })] }), children: "Coming soon!" })] }));
};
