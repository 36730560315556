import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { HLRContext } from './heatloss_report_page';
import { HLRListBlock } from './partials/hlr_list';
import _ from 'lodash';
export const HLRPEDetailedResultsIntroduction = () => {
    const hlrContext = React.useContext(HLRContext);
    const fuelName = hlrContext.performanceEstimateSummary.hddEstimate.existing.fuelName;
    const numberOfMethods = hlrContext.performanceEstimateSummary.numberOfMethods;
    const estimateGroup = hlrContext.performanceEstimateSummary.estimatesGroup;
    return (_jsxs("div", { className: "flex-col gap-6 flex", children: [_jsx(HLRSubheaderBlock, { section: "Performance estimate", title: "Detailed results" }), _jsx("div", { className: '', children: "The running costs and carbon emissions of a heating system depends on a few key factors, all of which are explained in (even) more detail further down this report:" }), _jsx(HLRListBlock, { items: [
                    _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "Your home\u2019s heating and hot water demand." }), " This is how much heat your home needs for heating and hot water in the year irrespective of what heating system is used to provide it"] }),
                    _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "The efficiency of your system." }), " How efficiently your system makes what you want (heat) from what you pay for (electricity/gas/oil), both in heating and when making hot water."] }),
                    _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "Energy prices." }), " Heat pump running costs depend on the price of electricity, and the comparison with your old system depends on the price of ", fuelName, "."] }),
                    _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "Emission factors." }), "Emission factors show how much carbon dioxide is emitted per unit of energy provided by a fuel. They are a measure of how \u201Cclean\u201D a fuel is."] })
                ] }), _jsx("div", { children: "We\u2019ve factored all of these into our calculations, and you can see in the table on the next page that the main variables that impact the overall running costs are (1) heating and hot water demand, and (2) energy prices." }), _jsx("div", { children: "Energy prices are quite simple. However, it\u2019s surprisingly hard to work out how much heating and hot water your home needs because it depends on how often you have the heating on and at what temperature, how much hot water you use, and the weather outside." }), numberOfMethods > 1 &&
                _jsxs("div", { children: ["Given this uncertainty we have estimated this in ", numberOfMethods, " different ways to try and give you the best understanding:"] }), _jsx(HLRListBlock, { items: _.compact([
                    estimateGroup['Based on EPC'] === undefined ? undefined : _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "EPC" }), " \u2013 Based on the heating and hot water demand from your EPC. This assumes your home hasn\u2019t changed since your EPC and uses assumptions about your heating and hot water system that don\u2019t apply to a heat pump. If your property has changed since your last EPC or that EPC had errors, this might not be the best measure, but we have to include it."] }),
                    _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "Heating Degree Days" }), " \u2013 Based on the heat loss calculations we have done combined with \u201C", _jsx("a", { className: 'underline', href: 'https://en.wikipedia.org/wiki/Heating_degree_day', target: '_blank', rel: "noreferrer", children: "heating degree day" }), "\u201D data which represents the typical annual weather pattern in your area."] }),
                    estimateGroup['Based on Last years consumption'] === undefined ? undefined : _jsxs(_Fragment, { children: [_jsx("span", { className: 'font-bold', children: "Last year\u2019s consumption" }), " \u2013 Based on your ", fuelName, " consumption from last year. If nothing has changed about your house or heating pattern, this should be a pretty representative estimate."] })
                ]), isNumbered: true }), estimateGroup['Based on EPC'] === undefined &&
                _jsx("div", { children: "We would usually also provide an estimate based on your EPC but we can\u2019t do that in your case because a representative EPC cannot be obtained for this home because of planned building work." })] }));
};
