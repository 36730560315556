import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircle2 } from 'lucide-react';
import { Icon } from '../buttons/icon';
const variants = {
    ACTIVE: 'border-gray-900 bg-white',
    DEFAULT: 'border-gray-100 bg-gray-100'
};
export const RadioGroup = ({ items, isVertical }) => {
    const isVerticalCheck = isVertical || items.some(x => x.description);
    return isVerticalCheck ? _jsx(RadioGroupVertical, { items: items }) : _jsx(RadioGroupHorizontal, { items: items });
};
const RadioGroupHorizontal = ({ items }) => {
    return _jsx("div", { className: "gap-2 flex overflow-x-auto", children: items.map(x => _jsx("div", { onClick: x.onClick, className: `cursor-pointer px-4 py-3 rounded-xl border-2 flex justify-center items-center ${variants[x.variant]}`, children: _jsxs("div", { className: 'flex flex-col items-center justify-center gap-2', children: [_jsx("div", { className: "text-sm whitespace-nowrap", children: x.name }), x.icon] }) }, x.name)) });
};
const RadioGroupVertical = ({ items }) => {
    return _jsx("div", { className: "gap-2 flex flex-col overflow-x-auto", children: items.map(x => _jsxs("div", { className: `cursor-pointer rounded-xl border-2 flex gap-2 justify-between items-center ${variants[x.variant]}`, children: [_jsxs("div", { className: 'flex flex-col grow ps-4 py-3', onClick: x.onClick, children: [_jsxs("div", { className: 'items-center flex gap-0.5', children: [_jsx("div", { className: "text-base font-semibold", children: x.name }), _jsxs("div", { className: 'text-sm', children: [" ", x.info && x.info] })] }), _jsx("div", { className: "text-sm text-gray-500", children: x.description })] }), _jsxs("div", { className: 'flex gap-4 items-center pe-4 py-3', children: [x.rightContent, x.variant === 'ACTIVE'
                            ? _jsx("div", { className: 'w-5 h-5 rounded-full bg-gray-900', children: _jsx(Icon, { colour: 'text-white', icon: CheckCircle2 }) })
                            : _jsx("div", { onClick: x.onClick, className: 'w-5 h-5 rounded-full bg-white border border-gray-300' })] })] }, x.name)) });
};
