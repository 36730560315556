import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Check, Copy } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from './button';
export const CopyButton = (props) => {
    const [copied, setCopied] = useState(false);
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(props.textToCopy);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
        catch (err) {
            console.error('Failed to copy text:', err);
        }
    };
    return (_jsx(AnimatePresence, { mode: "wait", initial: false, children: _jsx(motion.span, { initial: { opacity: 0, y: 10 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: -10 }, transition: { duration: 0.2 }, children: _jsx(Button, { ...props.buttonProps, iconLeft: copied ? Check : Copy, colour: copied ? 'TRANSPARENT' : props.buttonProps.colour, onClick: handleCopy, children: _jsx("span", { className: 'hidden sm:inline', children: copied ? 'Copied!' : 'Copy' }) }) }, copied ? 'check' : 'copy') }));
};
