import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Modal } from '../../../../components/containers/modal';
import { Loader } from '../../../../components/indicators_and_messaging/loader';
import { yellowEnvelope } from '../../../../assets/images/images';
import { Input } from '../../../../components/inputs_and_selections/input';
import _, { noop } from 'lodash';
import { Icon } from '../../../../components/buttons/icon';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { validateEmail } from '../../../../code/validators';
import { Send } from 'lucide-react';
export const SendEmailWithTemplateModal = (props) => {
    const [sendingStatus, setSendingStatus] = React.useState('INIT');
    const [emailRecipients, setEmailRecipients] = React.useState(props.emailRecipients);
    const [emailText, setEmailText] = React.useState(props.emailText);
    const recipientsStringToArray = () => {
        return _.uniq(_.compact(props.emailRecipients.split(',').map(r => validateEmail(r.trim()).value)));
    };
    return _jsx(_Fragment, { children: _jsx(Modal, { visible: props.visible, setVisible: props.setVisible, title: 'Send via Email', cancelDisabled: sendingStatus !== 'INIT', onConfirm: async () => {
                // dual logic for the confirm button: either send the email or close the modal
                if (sendingStatus === 'INIT') {
                    setSendingStatus('SENDING');
                    await props.handleSend(recipientsStringToArray());
                    setSendingStatus('SENT');
                }
                else {
                    props.setVisible(false);
                    setSendingStatus('INIT');
                }
            }, confirmButtonLabel: sendingStatus === 'INIT' ? 'Send' : 'Close', confirmDisabled: sendingStatus === 'SENDING', hideOnConfirm: false, children: _jsxs("div", { className: 'w-full flex flex-col gap-6', children: [sendingStatus === 'SENDING' && _jsx(Loader, {}), sendingStatus === 'SENT' && _jsxs("div", { className: 'px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex', children: [_jsx("img", { alt: 'envelope', src: yellowEnvelope, className: 'w-16 h-16' }), _jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Success" }), _jsxs("div", { className: "text-center flex flex-col gap-1", children: [_jsx("span", { children: "Your email was successfully sent to" }), _jsx("div", { className: 'flex flex-col gap-0', children: recipientsStringToArray().map((r, i) => _jsx("span", { className: 'font-bold', children: r }, i)) })] })] }), sendingStatus === 'INIT' && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex flex-col gap-0', children: [_jsx("p", { children: "Send an email to your recipient(s) with a link to the estimate." }), _jsx("p", { children: "You can edit this templated email in your Settings." })] }), _jsxs("div", { className: 'flex flex-col gap-3 w-full', children: [_jsx(Input, { onEnter: noop, value: emailRecipients, setValue: (e) => {
                                            setEmailRecipients(e);
                                            props.setEmailRecipients(e);
                                        }, type: 'text', label: 'Send to', placeholder: 'jack@example.com, robert@example.com', size: 'MD', shrink: false, validator: (e) => ({ message: '', value: e }), className: '', prefix: _jsx(Icon, { icon: Send }) }), _jsx("div", { className: 'flex flex-wrap gap-3', children: recipientsStringToArray().map((r, i) => _jsx("span", { className: 'rounded-lg px-2 py-1 text-sm bg-gray-200', children: r }, i)) })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(FormLabel, { labelText: 'Customer email', size: 'SM' }), _jsx("div", { className: 'flex flex-col gap-4', children: _jsx("div", { className: 'space-y-2', children: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                                                    setEmailText(editor.getJSON());
                                                    props.setEmailText(editor.getJSON());
                                                }, placeholders: props.editorPlaceholders, content: emailText }) }) })] })] })] }) }) });
};
