import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '../../../../../components/containers/modal';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { Input } from '../../../../../components/inputs_and_selections/input';
export const EditCompanyPartner = ({ onSave, setVisible }) => {
    const [code, setCode] = useState('');
    return (_jsx(Modal, { visible: true, setVisible: setVisible, title: "Role", confirmButtonLabel: 'Add partner', confirmDisabled: !code, onConfirm: () => onSave(code), children: _jsx("div", { className: 'flex flex-col gap-6 w-full', children: _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Company code', helperText: 'Please ask the company you would like to work with for their unique company code. It can be found in the company details settings page.' }), input: _jsx(Input, { className: 'flex-grow', setValue: e => setCode(e), value: code }) }) }) }));
};
