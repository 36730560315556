import { axiosPostUnwrapped } from '../../code/axios';
import { db } from '../heat_loss/db';
import { captureException } from '@sentry/react';
import { z } from 'zod';
import { getImages } from '../../code/models/lead';
import { getAllSurveyImages } from './job_layout/get_all_survey_images';
import { filterExpiresAtRows } from './filter_expires_at_rows';
// We need server_updated_at because:
// t=1, client=A: adds row on client
// t=2, client=B: syncs with server, no changes, update last_synced time to 2
// t=3, client=A: sync with server, push my 1 change at t=1 to db, without setting server_modified to t=3 clientB would never get the change
export const SyncItemSchema = z.object({
    uuid: z.string().optional(),
    created_at: z.number(),
    updated_at: z.number(),
    server_updated_at: z.number(),
    deleted_at: z.number().optional(),
    is_modified: z.boolean(),
    expires_at: z.number().optional()
});
const removeKeyProperty = (data) => data.map(({ key, ...rest }) => rest);
export const DEFAULT_TABLES = [
    { name: 'surveys', last_synced: 0 },
    { name: 'files', last_synced: 0 },
    { name: 'custom_materials', last_synced: 0 },
    { name: 'stages', last_synced: 0 },
    { name: 'statuses', last_synced: 0 }
];
export const syncTick = async (isSyncing, setIsSyncing, setHasSynced, setIsOffline) => {
    // On slow internet, prevent calls tripping over each other.
    if (isSyncing)
        return;
    setIsSyncing(true);
    try {
        const tables = await db.meta.toArray() ?? [];
        const tablesOrDefault = tables.length === 0 ? DEFAULT_TABLES : tables;
        const hydratedTables = await Promise.all(tablesOrDefault.map(async (x) => {
            const data = await db[x.name].toArray() ?? [];
            return {
                name: x.name,
                data: removeKeyProperty(data.filter(y => y.updated_at > (x.last_synced ?? 0) ||
                    !y.server_updated_at ||
                    y.is_modified)),
                last_synced: x.last_synced,
                ids: filterExpiresAtRows(data).map(x => x.uuid)
            };
        }));
        const result = await axiosPostUnwrapped('sync', { tables: hydratedTables });
        for (const table of result.tables) {
            const existingRecords = await db[table.name].where('uuid').anyOf(table.data.map(x => x.uuid)).toArray();
            const existingRecordsMap = new Map(existingRecords.map(record => [record.uuid, record]));
            const filteredData = table.data.filter(incomingRow => {
                const existingRow = existingRecordsMap.get(incomingRow.uuid);
                return !existingRow || incomingRow.updated_at >= existingRow.updated_at;
            });
            // We have no way to know currently NEW images linked to survey.
            if (table.name === 'surveys' && filteredData.length > 0) {
                for (const survey of filteredData) {
                    const lead = await db.jobs.get(survey.uuid);
                    const surveyImages = getAllSurveyImages(survey.data);
                    if (surveyImages.length > 0 && lead?.company_uuid) {
                        const apiSurveyImages = await getImages(surveyImages, survey.uuid, lead.company_uuid);
                        await db.files.bulkPut(apiSurveyImages);
                    }
                }
            }
            if (filteredData.length > 0) {
                for (const apiRow of filteredData) {
                    const dbRow = existingRecordsMap.get(apiRow.uuid);
                    await db[table.name].put({ key: apiRow.uuid, ...apiRow, expires_at: dbRow.expires_at, is_modified: false });
                }
            }
            await db.meta.put({ name: table.name, last_synced: table.last_synced });
        }
        setIsOffline(false);
    }
    catch (e) {
        if (e.code === 'ERR_NETWORK') {
            setIsOffline(true);
        }
        else {
            console.error(e);
            captureException(e);
            setIsOffline(false);
        }
        return true;
    }
    finally {
        setHasSynced(true);
        setIsSyncing(false);
    }
};
