import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { AdminContext } from '../../admin/admin_layout';
import { convertMagicplanPlanDetailsToFloors, getMagicplanPlanDetails, getMagicplanPlans } from '../../../code/floorplan_adapters/magicplan';
import _ from 'lodash';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Modal } from '../../../components/containers/modal';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { Button } from '../../../components/buttons/button';
export const MagicplanModal = ({ survey, setSurvey, showMagicplanModal, setShowMagicplanModal, afterImport, company }) => {
    const adminContext = useContext(AdminContext);
    const [showFetchLoader, setShowFetchLoader] = useState(false);
    const [showImportLoader, setShowImportLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const handleLoadProjects = async () => {
        if (!adminContext)
            return;
        setShowFetchLoader(true);
        const projects = await getMagicplanPlans(company.public_info.uuid);
        if (!projects) {
            alert('Error getting Magicplan projects. Check your Magicplan API key and integration settings');
            setShowFetchLoader(false);
            return;
        }
        setProjects(projects);
        setShowFetchLoader(false);
    };
    const projectsItems = !showMagicplanModal ? [] : projects.map((p) => {
        return {
            name: p.name,
            description: p.address.postal_code ? _.compact([p.address.street, p.address.street_number, p.address.city, p.address.postal_code]).join(', ') : undefined,
            onClick: () => setSelectedProject(p),
            variant: selectedProject === p ? 'ACTIVE' : 'DEFAULT',
            rightContent: p.thumbnail_url ? _jsx("img", { alt: '', className: 'w-20 h-20', src: p.thumbnail_url, onError: (e) => {
                    // if thumbnail fails to load, set it to empty src
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Empty GIF
                } }) : undefined
        };
    });
    const handleConfirmation = async () => {
        if (!selectedProject)
            return;
        setShowImportLoader(true);
        const planDetails = await getMagicplanPlanDetails(selectedProject.id, survey.uuid, company.public_info.uuid);
        if (!planDetails) {
            alert('Error getting plan details');
        }
        else {
            const floors = convertMagicplanPlanDetailsToFloors(planDetails, survey);
            if (floors.length === 0) {
                alert('No floors found in plan');
            }
            else {
                setSurvey(prev => ({
                    ...prev,
                    floors: [
                        ...prev.floors,
                        ...floors
                    ]
                }));
                if (afterImport)
                    afterImport();
            }
        }
        setShowImportLoader(false);
        setShowMagicplanModal(false);
    };
    useEffect(() => {
        if (showMagicplanModal)
            handleLoadProjects();
    }, [showMagicplanModal]);
    if (!showMagicplanModal)
        return null;
    return _jsx(Modal, { visible: showMagicplanModal, setVisible: setShowMagicplanModal, title: 'Import Magicplan project', onConfirm: handleConfirmation, confirmButtonLabel: 'Import', confirmDisabled: !selectedProject || showImportLoader || showFetchLoader, thirdButton: undefined, hideOnConfirm: false, children: _jsxs("div", { className: 'w-full flex flex-col gap-2 h-96', children: [showImportLoader &&
                    _jsxs("div", { className: 'flex flex-col justify-center items-center h-96 gap-4', children: [_jsxs("span", { children: ["Importing Magicplan project: ", selectedProject?.name] }), _jsx(Loader, {})] }), !showImportLoader && _jsxs(_Fragment, { children: [_jsx(Button, { disabled: showFetchLoader, onClick: handleLoadProjects, children: "Reload projects" }), showFetchLoader && _jsx(Loader, {}), !showFetchLoader && projects.length !== 0 && _jsx(_Fragment, { children: _jsx("div", { className: 'h-96 overflow-y-scroll', children: _jsx(RadioGroup, { items: projectsItems, isVertical: true }) }) })] }), !showFetchLoader && projects.length === 0 && _jsx(_Fragment, { children: "No projects found" })] }) });
};
