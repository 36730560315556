import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileDropzone } from '../../../components/inputs_and_selections/file_dropzone';
export const FileDropzoneDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(FileDropzone, { helperText: 'File must be an image or pdf', multiple: true, accept: 'image/*, application/pdf', maxSizeMB: 2, onChange: files => console.log(files) })] })] });
};
const componentProps = [
    { name: 'helperText', description: 'Helper text for input - optional' },
    { name: 'maxSizeMB', description: 'Set the max size for files in MB - optional, defaults to 2MB' },
    { name: 'onChange', description: 'Function to run when a file is selected for upload' },
    { name: '...inputProps', description: 'Extra props spread on the file input - optional' }
];
const codeBlock = `<FileDropzone
  helperText='File must be an image or pdf'
  maxSizeMB={2}
  onChange={files => console.log(files)}
  multiple
  accept='image/*, application/pdf'
/>`;
