import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report_page';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { proxyImageURL } from '../../../code/helpers';
export const HLRHotWater = () => {
    const hlrContext = React.useContext(HLRContext);
    const locationPhotos = hlrContext.files.filter(x => hlrContext.survey.cylinder_photos.some(y => y.image_uuid === x.uuid));
    return _jsxs("div", { className: 'flex-col gap-6 flex', children: [_jsx(HLRSubheaderBlock, { section: "System design", title: "Hot water" }), _jsx("div", { children: "Your heat pump will provide 100% of your hot water requirement. Heat pumps cannot create heat as quickly as combi boilers, so they can\u2019t heat up hot water instantaneously when you need it. Instead we install a cylinder which stores hot water so it's always ready when you need it." }), _jsx("div", { className: 'text-gray-900 font-bold', children: "Proposed cylinder model" }), _jsx("div", { children: "We've selected the following hot water cylinder based on the number of bedrooms in your home." }), hlrContext.currentHotWaterCylinder
                ? _jsx(ClickableCard, { variant: 'WHITE', children: _jsxs("div", { className: "flex-col gap-3 flex", children: [_jsx("div", { className: "text-gray-900 text-sm font-bold", children: hlrContext.currentHotWaterCylinder.name }), _jsxs("div", { className: "gap-2 flex justify-between", children: [_jsx("div", { className: "text-xs font-semibold", children: "Capacity" }), _jsxs("div", { className: "text-xs font-semibold", children: [Math.round(hlrContext.currentHotWaterCylinder.litres), " litres"] })] })] }) })
                : _jsx(ClickableCard, { variant: 'PLACEHOLDER', children: "No cylinder found" }), (hlrContext.survey.cylinder_location_description || locationPhotos.length > 0) && _jsxs(_Fragment, { children: [_jsx("div", { className: 'text-gray-900 font-bold', children: "Cylinder location" }), hlrContext.survey.cylinder_location_description &&
                        _jsx("div", { children: hlrContext.survey.cylinder_location_description }), locationPhotos.length > 0 && _jsx("div", { className: 'flex flex-wrap gap-6', children: locationPhotos.map(x => _jsx("img", { className: 'max-h-64 rounded-md', src: x.file_base64 || proxyImageURL('x256', x.file_url ?? '') }, x.uuid)) })] })] });
};
