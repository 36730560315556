import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { SettingsImageInput } from '../../components/settings_image_input';
import { ClickableCard } from '../../../../../components/content_display/card';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
export function EstimateAboutUsBlock({ company, setCompany }) {
    return (_jsx(Section, { border: true, title: 'About us', children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ClickableCard, { variant: 'GREY', children: _jsx(HorizontalFormGroup, { formLabel: _jsx(FormLabel, { labelText: '\'About us\' section', helperText: 'Display the \'About us\' section on the estimate' }), input: _jsx(Toggle, { value: company.public_info.show_about_us_on_estimate, setValue: async () => {
                                const newCompany = {
                                    ...company,
                                    public_info: {
                                        ...company.public_info,
                                        show_about_us_on_estimate: !company.public_info.show_about_us_on_estimate
                                    }
                                };
                                setCompany(newCompany);
                                await updateCompanySettings(newCompany);
                            } }) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Description' }), input: _jsx(TextArea, { value: company.public_info.about_us ?? '', setValue: aboutUsInput => setCompany({
                            ...company,
                            public_info: {
                                ...company.public_info,
                                about_us: aboutUsInput
                            }
                        }), placeholder: 'A little info about your company...', rows: 6 }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Image' }), input: _jsx(SettingsImageInput, { buttonText: 'Upload image', placeholderText: 'No image', helperText: 'Maximum file size 5MB', b64Image: company.public_info.about_us_image ?? '', altText: 'About us image', handleUpdateImage: async (imageSrc) => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    about_us_image: imageSrc
                                }
                            };
                            await updateCompanySettings(newCompany);
                            setCompany(newCompany);
                        }, handleRemoveImage: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    about_us_image: ''
                                }
                            };
                            await updateCompanySettings(newCompany);
                            setCompany(newCompany);
                        } }) })] }) }));
}
