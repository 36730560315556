import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Emoji } from './emoji';
const defineMajorStyling = (size) => {
    switch (size) {
        case 'SM':
            return 'text-xs font-bold text-default';
        case 'LG':
            return 'text-md text-bold font-bold';
        case 'MD':
        default:
            return 'text-sm font-bold text-default';
    }
};
const defineMinorStyling = (size) => {
    switch (size) {
        case 'SM':
            return 'text-xs text-default';
        case 'LG':
            return 'text-md text-default';
        case 'MD':
        default:
            return 'text-sm text-default';
    }
};
export const SummaryTable = ({ rows }) => {
    if (!rows)
        return;
    return _jsx("div", { className: 'w-full divide divide-y border-light', children: rows.map(({ key, value, size = 'MD', emoji, infoButton }, i) => _jsxs("div", { className: 'flex flex-col gap-1 sm:flex-row justify-between py-2 last:pb-0', children: [_jsxs("div", { className: `flex gap-2 items-center ${defineMajorStyling(size)}`, children: [emoji && _jsx(Emoji, { emoji: emoji }), _jsxs("div", { className: 'flex gap-1 items-center', children: [key, infoButton] })] }), _jsx("div", { className: defineMinorStyling(size), children: value })] }, i)) });
};
