import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SURVEY_AGE_BANDS } from '../../../../code/models/age_bands';
import { SurveySiteDetailsContext } from '../site_details';
import { Select } from '../../../../components/inputs_and_selections/select';
import { PROPERTY_TYPES } from '../../../../code/models/property_type';
import { BUILT_FORM_TYPES } from '../../../../code/models/built_form';
export const SiteDetailsTypeBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const { survey, setSurvey } = prContext;
    return _jsxs(_Fragment, { children: [_jsx("div", { className: " text-gray-900 text-xl font-bold", children: "Property" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Type" }), _jsx(Select, { selectedKey: survey.property_type, options: PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, property_type: e })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Built form" }), _jsx(Select, { selectedKey: survey.built_form, options: BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, built_form: e })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Age band" }), _jsx(Select, { selectedKey: survey.age_band?.id, options: SURVEY_AGE_BANDS.map(x => ({ key: x.id, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, age_band: SURVEY_AGE_BANDS.find(x => x.id === e) })) })] })] })] });
};
