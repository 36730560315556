import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Input } from '../../../../components/inputs_and_selections/input';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
export const CylinderLocationExistingDetailsBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    const cylinderTypes = ['Vented', 'Unvented'];
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Existing cylinder" }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold ", children: "Cylinder volume" }), _jsx(Input, { type: 'number', value: survey.existing_cylinder_volume_l.toString(), setValue: (e) => setSurvey({ ...survey, existing_cylinder_volume_l: Number(e) }), postfix: _jsx("span", { className: 'text-gray-500', children: "litres" }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Type" }), _jsx(RadioGroup, { items: cylinderTypes.map(x => ({
                            name: x,
                            onClick: () => setSurvey({ ...survey, existing_cylinder_type: x }),
                            variant: survey.existing_cylinder_type === x ? 'ACTIVE' : 'DEFAULT'
                        })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold ", children: "Coil surface area" }), _jsx(Input, { type: 'number', value: survey.existing_cylinder_coil_size.toString(), setValue: (e) => setSurvey({ ...survey, existing_cylinder_coil_size: Number(e) }), postfix: 'm\u00B2' })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold ", children: "Brand and model" }), _jsx(Input, { value: survey.existing_cylinder_brand_and_model, setValue: (e) => setSurvey({ ...survey, existing_cylinder_brand_and_model: e }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Location description" }), _jsx(TextArea, { value: survey.existing_cylinder_location_description, setValue: (e) => setSurvey({ ...survey, existing_cylinder_location_description: e }), placeholder: 'Physical location, where is it in relation to existing boiler' })] })] });
};
