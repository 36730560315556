import { apiUrl, client, url } from '../axios';
export const getInvitationByToken = async (token) => {
    try {
        const response = await client.get(`${apiUrl}invitations/${token}`);
        return response.data;
    }
    catch (e) {
        console.error('Error getting invitation', e);
        return undefined;
    }
};
export const acceptInvitation = async ({ email, password, name, first_name, last_name, token }) => {
    try {
        return await client.post(`${url}invitation/accept`, { email, password, name, first_name, last_name, token });
    }
    catch (e) {
        console.error('Error accepting invitation', e);
        return e.response;
    }
};
