import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Table } from '../../../components/content_display/table';
import { noop } from 'lodash';
export const TableDemo = () => {
    const columns = [
        { key: 'column1', name: 'Column 1' },
        { key: 'column2', name: 'Column 2' },
        { key: 'column3', name: 'Column 3' }
    ];
    const [rows, setRows] = useState([
        { uuid: '1', column1: 'Test 1.1', column2: 'Test 2.1', column3: 'Test 3.1' },
        { uuid: '2', column1: 'Test 1.2', column2: 'Test 2.2', column3: 'Test 3.2' },
        { uuid: '3', column1: 'Test 1.3', column2: 'Test 2.3', column3: 'Test 3.3' }
    ]);
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Table, { rows: rows, columns: columns, addNew: true, editable: true, onDeleteRow: noop, onSaveRow: (e) => { setRows(prev => e.uuid ? prev.map(x => x.uuid === e.uuid ? e : x) : [...prev, { ...e, uuid: crypto.randomUUID() }]); }, editableColumnWidth: undefined })] })] });
};
const componentProps = [
    { name: 'rows', description: 'The raw table data, properties should match column headers' },
    { name: 'columns', description: 'How many columns should the table have, each with its own definition below' },
    { name: 'name', description: 'column prop: header text of the column' },
    { name: 'key', description: 'column prop: unique row identifier' },
    { name: 'validator', description: 'column prop: disable save/add button if validation fails' },
    { name: 'render', description: 'column prop: should we render the row differently when viewing' },
    { name: 'editRender', description: 'column prop: shoul we render the row differently when editing, you need to handle your own onChange if overridden' },
    { name: 'addNew', description: 'Allow users to add new rows to the table' },
    { name: 'editable', description: 'Allow users to edit rows in the table' },
    { name: 'onDeleteRow', description: '' },
    { name: 'onSaveRow', description: 'Called when editing or adding a new row' },
    { name: 'editableColumnWidth', description: 'raw number if we want a fixed width column' }
];
const codeBlock = `type TableExample = {
  uuid: string
  column1: string
  column2: string
  column3: string
}

const columns: Array<TableColumn<TableExample>> = [
  { key: 'column1', name: 'Column 1' },
  { key: 'column2', name: 'Column 2' },
  { key: 'column3', name: 'Column 3' }
]

const [rows, setRows] = useState<TableExample[]>([
  { uuid: '1', column1: 'Test 1.1', column2: 'Test 2.1', column3: 'Test 3.1' },
  { uuid: '2', column1: 'Test 1.2', column2: 'Test 2.2', column3: 'Test 3.2' },
  { uuid: '3', column1: 'Test 1.3', column2: 'Test 2.3', column3: 'Test 3.3' }
])

<Table
  rows={rows}
  columns={columns}
  addNew={true}
  editable={true}
  onDeleteRow={noop}
  onSaveRow={(e) => { setRows(prev => e.uuid ? prev.map(x => x.uuid === e.uuid ? e : x) : [...prev, { ...e, uuid: crypto.randomUUID() }]) }}
  editableColumnWidth={undefined}
/>
`;
