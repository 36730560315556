import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsIncomingMainAndFuseDetailsBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    const PHASES = ['Single', 'Three'];
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Incoming main and fuse" }), _jsxs("div", { className: "flex-col gap-5 flex", children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Phase" }), _jsx(RadioGroup, { items: PHASES.map(x => ({
                                    name: x,
                                    onClick: () => setSurvey({ ...survey, electrics_phase: x }),
                                    variant: x === survey.electrics_phase ? 'ACTIVE' : 'DEFAULT'
                                })) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Main fuse size" }), _jsx(Input, { type: 'number', value: survey.electrics_main_fuse_size_amps.toString(), setValue: (e) => setSurvey({ ...survey, electrics_main_fuse_size_amps: Number(e) }), postfix: 'amps' })] }), _jsxs("div", { className: "gap-3 flex justify-between items-center", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Looped service" }), _jsx("div", { className: "text-gray-500 text-sm", children: "A looped service is where two properties share a single electricity service cable from the main network. There will be two cables to the cut out." })] }), _jsx(Toggle, { value: survey.electrics_looped_service, setValue: () => setSurvey({ ...survey, electrics_looped_service: !survey.electrics_looped_service }) })] })] })] });
};
