import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SelectAHeatPumpPage } from '../../select_heat_pump';
import { SelectedHeatPump } from '../../selected_heat_pump';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
export const HPLProposedHeatPumpBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { design, setDesign, currentHeatPump, soundCalculation, heatPumps, designTempC, flowTempC, isOffline, allSynced } = hplContext;
    const [currentPage, setCurrentPage] = useState('MAIN');
    if (currentPage === 'SELECT_HEAT_PUMP') {
        return _jsx(SelectAHeatPumpPage, { isOffline: isOffline, allSynced: allSynced, heatPumps: heatPumps, designTempC: designTempC, flowTempC: flowTempC, setCurrentPage: setCurrentPage, design: design, setDesign: setDesign });
    }
    // TODO later: refactor this to use input group
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Proposed heat pump" }), _jsx(SelectedHeatPump, { currentHeatPump: currentHeatPump, soundCalculation: soundCalculation, variant: 'SURVEY', setCurrentPage: setCurrentPage })] });
};
