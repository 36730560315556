import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const CylinderLocationExistingPhotosBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey, files, setFiles, companyUUID } = cylinderLocationContext;
    const images = survey.existing_cylinder_photos.map(x => files.find(y => y.uuid === x.image_uuid));
    const deleteImage = (imageUUID) => {
        setSurvey({ ...survey, existing_cylinder_photos: survey.existing_cylinder_photos.filter(x => x.image_uuid !== imageUUID) });
    };
    const isFullScreen = images.length === 0 && flowContext.viewMode === 'FLOW';
    return _jsx(_Fragment, { children: _jsxs("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4', children: [_jsxs("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1`, children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Existing cylinder" }), _jsx("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm`, children: "Add photos of the existing cylinder" })] }), _jsxs("div", { className: "flex-col gap-3 flex", children: [!isFullScreen && _jsx("div", { className: "text-gray-900 font-bold", children: "Photos" }), _jsx(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: images, addImage: (fileWrapper) => {
                                fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, updated_at: new Date().getTime(), created_at: new Date().getTime(), is_modified: true }]);
                                fileWrapper && setSurvey(({
                                    ...survey,
                                    existing_cylinder_photos: [...survey.existing_cylinder_photos, {
                                            uuid: crypto.randomUUID(),
                                            entity_uuid: survey.uuid,
                                            image_uuid: fileWrapper.uuid
                                        }]
                                }));
                            }, deleteImage: (id) => deleteImage(id), companyUUID: companyUUID })] })] }) });
};
