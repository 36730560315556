import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SimpleLayout } from '../../../../layouts/simple_layout';
import { Heading } from '../../../../components/content_display/heading';
import { Button } from '../../../../components/buttons/button';
import { CalculatedQuoteDefaultGroups, QuoteOverrideType } from '../../../../code/calculate_quote';
import { groupBy, sum } from 'lodash';
import { Section } from '../components/section';
import { InventoryTable } from '../../costs_and_inventory/components/inventory_table';
import { Input } from '../../../../components/inputs_and_selections/input';
import { formatPrice } from '../../../../code/format_price';
import { Text } from '../../../../components/content_display/text';
import { bulkDeleteProposalQuoteItems, bulkInsertProposalQuoteItems, deleteProposalQuoteItem, insertProposalQuoteItem, updateProposalQuoteItem } from '../../../../code/models/proposal_quote_item';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { AdminContext } from '../../admin_layout';
import { Select } from '../../../../components/inputs_and_selections/select';
import { priceCalculations } from '../../../../code/price_calculations';
import { Modal } from '../../../../components/containers/modal';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { validateIsNumber, validateIsPositiveNumber, validateNotNull } from '../../../../code/validators';
import { ChevronLeft, Edit, Plus, RotateCw, Trash } from 'lucide-react';
const DEFAULT_QUOTE_SECTION_ORDER = [
    CalculatedQuoteDefaultGroups.HEAT_PUMPS,
    CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS,
    CalculatedQuoteDefaultGroups.PARTS,
    CalculatedQuoteDefaultGroups.RADIATORS,
    CalculatedQuoteDefaultGroups.UNDERFLOOR,
    CalculatedQuoteDefaultGroups.LABOUR,
    CalculatedQuoteDefaultGroups.SURVEY,
    CalculatedQuoteDefaultGroups.GRANTS,
    'Other deductions',
    'Optional items'
];
const GROUPS_TO_SHOW_DROPDOWN = [CalculatedQuoteDefaultGroups.HEAT_PUMPS, CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS, CalculatedQuoteDefaultGroups.PARTS, CalculatedQuoteDefaultGroups.LABOUR, CalculatedQuoteDefaultGroups.SURVEY];
export const QuoteBuilder = ({ proposal, companyPublicInfo, navigateTo, setProposal, setAndUpdateProposal, calculatedQuote, quoteOverrideType, company }) => {
    const adminContext = useContext(AdminContext);
    const [addCustomItemModalVisible, setAddCustomItemModalVisible] = useState(false);
    const [addCustomItemModalGroup, setAddCustomItemModalGroup] = useState('');
    const [editItemModalVisible, setEditItemModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    // Returns the same array of quote items, but with the 'order' keys
    // updated sequentially to reflect the new order
    const reorderQuoteItems = (quoteItems) => {
        const groupedQuoteItems = groupBy(quoteItems, 'group_name');
        let order = 0;
        return DEFAULT_QUOTE_SECTION_ORDER.flatMap(groupName => {
            const items = groupedQuoteItems[groupName];
            if (!items)
                return [];
            return items.map(item => ({ ...item, order: order++ }));
        });
    };
    const createQuoteOverrideWithUpdatedItem = async (item) => {
        const updatedPayload = reorderQuoteItems(calculatedQuote.map(x => x.uuid === item.uuid ? item : x).map(x => ({ ...x, uuid: crypto.randomUUID() })));
        setProposal({ ...proposal, quote_items: updatedPayload });
        await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
    };
    const createQuoteOverrideWithNewItem = async (item) => {
        const updatedPayload = reorderQuoteItems([...calculatedQuote, item].map(x => ({ ...x, uuid: crypto.randomUUID() })));
        setProposal({ ...proposal, quote_items: updatedPayload });
        await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
    };
    const createQuoteOverrideWithoutItem = async (item) => {
        const updatedPayload = reorderQuoteItems(calculatedQuote.filter(x => x.uuid !== item.uuid).map(x => ({ ...x, uuid: crypto.randomUUID() })));
        setProposal({ ...proposal, quote_items: updatedPayload });
        await bulkInsertProposalQuoteItems(updatedPayload, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
    };
    const handleUpdateItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverrideType === QuoteOverrideType.FULL) {
            // Just update this specific item
            await updateProposalQuoteItem(item, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
            return setProposal({ ...proposal, quote_items: proposal.quote_items.map(x => x.uuid === item.uuid ? item : x) });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithUpdatedItem(item);
    };
    const handleAddItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverrideType === QuoteOverrideType.FULL) {
            // Create a new item
            await insertProposalQuoteItem(item, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
            return setProposal({ ...proposal, quote_items: [...proposal.quote_items, item] });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithNewItem(item);
    };
    const handleDeleteItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverrideType === QuoteOverrideType.FULL) {
            // Just delete this specific item
            await deleteProposalQuoteItem(item.uuid, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
            return setProposal({ ...proposal, quote_items: proposal.quote_items.filter(x => x.uuid !== item.uuid) });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithoutItem(item);
    };
    const handleResetToDefault = async () => {
        setProposal({ ...proposal, quote_items: [] });
        await bulkDeleteProposalQuoteItems(proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
    };
    const handleEditItem = (item) => {
        setEditingItem(item);
        setEditItemModalVisible(true);
    };
    const QUOTE_SECTION_TO_INVENTORY_ITEMS = {
        [CalculatedQuoteDefaultGroups.HEAT_PUMPS]: adminContext.data.heatPumps,
        [CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]: adminContext.data.hotWaterCylinders,
        [CalculatedQuoteDefaultGroups.PARTS]: adminContext.data.parts,
        [CalculatedQuoteDefaultGroups.LABOUR]: adminContext.data.labour,
        [CalculatedQuoteDefaultGroups.SURVEY]: [{ uuid: 'survey', name: 'Survey', description: '', cost_price: company.survey_cost || 0 }]
    };
    const handleAddAdditionalItem = async (key) => {
        if (!key)
            return;
        if (key === 'radiator' || key === 'underfloor') {
            const item = {
                uuid: crypto.randomUUID(),
                proposal_uuid: proposal.uuid,
                group_name: key === 'radiator' ? CalculatedQuoteDefaultGroups.RADIATORS : CalculatedQuoteDefaultGroups.UNDERFLOOR,
                name: key === 'radiator' ? 'Radiator' : 'Underfloor heating',
                price: key === 'radiator' ? company.default_radiator_cost || 0 : company.default_underfloor_cost || 0,
                quantity: 1,
                include_vat: false,
                selected: true,
                created_at: new Date(),
                updated_at: new Date(),
                order: proposal.quote_items.length
            };
            if (proposal.quote_items.find(x => x.name === item.name)) {
                const existingItem = proposal.quote_items.find(x => x.name === item.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            return await handleAddItem(item);
        }
        // Search through all the inventory items to find the one with the matching key
        const [inventoryItemGroup, inventoryItem] = Object.entries(QUOTE_SECTION_TO_INVENTORY_ITEMS).reduce((acc, [groupName, items]) => {
            if (acc[0])
                return acc;
            const item = items?.find(x => x.uuid === key);
            if (item)
                return [groupName, item];
            return acc;
        }, [undefined, undefined]);
        if (inventoryItem) {
            // Does this part already exist in the quote?
            if (proposal.quote_items.find(x => x.name === inventoryItem.name)) {
                // If so, just increment the quantity
                const existingItem = proposal.quote_items.find(x => x.name === inventoryItem.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            // Otherwise, add a new item with a quantity of 1
            // If the item is a heat pump, we can also attach the image URL
            const imageUrl = inventoryItemGroup === (CalculatedQuoteDefaultGroups.HEAT_PUMPS && inventoryItem.range_heat_pump_uuid)
                ? process.env.S3_BUCKET_URL + '/hp-images/' + inventoryItem.range_heat_pump_uuid + '.png'
                : undefined;
            await handleAddItem({
                uuid: crypto.randomUUID(),
                proposal_uuid: proposal.uuid,
                group_name: inventoryItemGroup,
                name: inventoryItem.name,
                description: inventoryItem.description || '',
                price: priceCalculations.calculateCustomerPrice(inventoryItem.cost_price, Object.prototype.hasOwnProperty.call(inventoryItem, 'markup') ? inventoryItem.markup : 0),
                quantity: 1,
                include_vat: false,
                selected: true,
                created_at: new Date(),
                updated_at: new Date(),
                order: proposal.quote_items.length,
                image_url: imageUrl
            });
        }
    };
    const totalBeforeDeductions = sum(calculatedQuote.filter(x => x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions' && x.selected).map(x => x.subtotal));
    const totalAfterDeductions = sum(calculatedQuote.filter(x => x.selected).map(x => x.subtotal));
    return (_jsxs("div", { className: 'h-full min-h-0 absolute left-0 top-0 w-full bg-white', children: [_jsx(SimpleLayout, { stickyHeader: true, header: _jsxs("div", { className: 'h-full min-h-0 flex flex-col md:flex-row md:items-center md:justify-between gap-4', children: [_jsx(Heading, { size: "xl", children: "Edit quote" }), _jsxs("div", { className: 'flex gap-2', children: [_jsx(Button, { colour: 'LIGHT', iconLeft: RotateCw, onClick: handleResetToDefault, disabled: quoteOverrideType !== QuoteOverrideType.FULL, confirmText: "This will clear any changes you've made and revert back to defaults.", children: "Reset to defaults" }), _jsx(Button, { colour: 'DARK', iconLeft: ChevronLeft, onClick: () => navigateTo('/proposal'), children: "Return to proposal" })] })] }), children: _jsxs("div", { className: 'mt-6 space-y-6 px-6 pb-6', children: [quoteOverrideType === QuoteOverrideType.FULL && _jsx(Alert, { type: "INFO", children: "This quote has been manually overridden. It will not update automatically based on changes to the designed heat pump and hot water cylinder, or your costs and inventory. Reset to default to revert to automatic calculation." }), DEFAULT_QUOTE_SECTION_ORDER.map(groupName => _jsx(QuoteBuilderSection, { groupName: groupName, calculatedQuote: calculatedQuote, handleEditItem: handleEditItem, handleUpdateItem: handleUpdateItem, handleDeleteItem: handleDeleteItem, setAddCustomItemModalVisible: setAddCustomItemModalVisible, setAddCustomItemModalGroup: setAddCustomItemModalGroup, totalBeforeDeductions: totalBeforeDeductions, totalAfterDeductions: totalAfterDeductions }, groupName)), _jsx(Section, { title: "Additional notes", children: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                                    setAndUpdateProposal({
                                        ...proposal,
                                        additional_notes: JSON.stringify(editor.getJSON())
                                    });
                                }, content: JSON.parse(proposal.additional_notes || '{}') }) })] }) }), _jsx(AddCustomItemModal, { addItem: handleAddItem, visible: addCustomItemModalVisible, setVisible: setAddCustomItemModalVisible, group: addCustomItemModalGroup, proposalUUID: proposal.uuid, handleAddAdditionalItem: handleAddAdditionalItem, addableItems: (QUOTE_SECTION_TO_INVENTORY_ITEMS[addCustomItemModalGroup] || []).map((x) => ({ key: x.uuid, value: `${x.name} - ${formatPrice(priceCalculations.calculateCustomerPrice(x.cost_price, Object.prototype.hasOwnProperty.call(x, 'markup') ? x.markup : 0), 2)}` })) }), _jsx(EditQuoteItemModal, { item: editingItem, visible: editItemModalVisible, setVisible: setEditItemModalVisible, handleUpdateItem: handleUpdateItem })] }));
};
const QuoteBuilderSection = ({ groupName, calculatedQuote, handleEditItem, handleUpdateItem, handleDeleteItem, setAddCustomItemModalVisible, setAddCustomItemModalGroup, totalAfterDeductions, totalBeforeDeductions }) => {
    const postfixForGroupName = useCallback((groupName) => {
        if (groupName === CalculatedQuoteDefaultGroups.UNDERFLOOR) {
            return 'm²';
        }
        if (groupName === CalculatedQuoteDefaultGroups.LABOUR) {
            return 'days';
        }
        return '';
    }, []);
    const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name');
    const quoteItemColumns = [
        { key: 'description', name: 'Description' },
        { key: 'price', name: 'Price' },
        { key: 'quantity', name: 'Quantity' },
        { key: 'total', name: 'Total' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const handleClickAddCustomItem = () => {
        setAddCustomItemModalGroup(groupName);
        setAddCustomItemModalVisible(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { title: groupName, children: [_jsx(InventoryTable, { columns: quoteItemColumns, rows: (groupedCalculatedQuote[groupName] || []).map(x => ({
                            description: _jsxs(_Fragment, { children: [_jsx(Text, { size: 'SM', bold: true, children: x.name }), x.description && _jsx(Text, { size: 'XS', className: 'text-gray-500', children: x.description })] }),
                            price: _jsx(Input, { type: 'number', value: x.price, prefix: "\u00A3", setValue: (value) => handleUpdateItem({ ...x, price: parseFloat(value) }) }),
                            quantity: _jsx(Input, { type: 'number', value: x.quantity, postfix: postfixForGroupName(groupName), setValue: (value) => handleUpdateItem({ ...x, quantity: parseFloat(value) }) }),
                            total: _jsx(Text, { size: "SM", children: formatPrice(x.subtotal, 2) }),
                            menu: _jsx(DropdownMenu, { items: [
                                    { label: 'Edit', onClick: () => handleEditItem(x), icon: Edit },
                                    { label: 'Delete', onClick: () => handleDeleteItem(x), icon: Trash, confirmText: 'Are you sure you want to delete this quote item?' }
                                ] })
                        })) }), _jsxs("div", { className: "flex flex-col md:flex-row md:items-center md:justify-between gap-4", children: [_jsx(Button, { iconLeft: Plus, colour: 'LIGHT', onClick: handleClickAddCustomItem, children: "Add item" }), _jsxs("div", { className: "flex items-center md:ml-auto", children: [_jsx(Text, { size: "SM", bold: true, children: "Subtotal:" }), _jsx(Text, { size: "SM", bold: true, className: "ml-2", children: formatPrice(sum((groupedCalculatedQuote[groupName] || []).map(x => x.subtotal)), 2) })] })] })] }, groupName), groupName === CalculatedQuoteDefaultGroups.SURVEY && _jsx(Section, { children: _jsx("div", { className: 'md:text-right', children: _jsxs(Text, { size: "SM", bold: true, children: ["Total before deductions: ", formatPrice(totalBeforeDeductions, 2)] }) }) }), groupName === 'Other deductions' && _jsx(Section, { children: _jsx("div", { className: 'md:text-right', children: _jsxs(Text, { size: "SM", bold: true, children: ["Total after deductions: ", formatPrice(totalAfterDeductions, 2)] }) }) })] }));
};
const AddCustomItemModal = ({ addItem, visible, setVisible, group, proposalUUID, handleAddAdditionalItem, addableItems }) => {
    const [item, setItem] = useState({
        name: '',
        description: '',
        price: 0,
        quantity: 1
    });
    const [selectedItemId, setSelectedItemId] = React.useState(undefined);
    const handleSelectItem = (key) => {
        handleAddAdditionalItem(key);
        setVisible(false);
        setSelectedItemId(undefined);
    };
    const handleAddItem = () => {
        addItem({
            uuid: crypto.randomUUID(),
            proposal_uuid: proposalUUID,
            group_name: group,
            name: item.name,
            description: item.description,
            price: item.price,
            quantity: item.quantity,
            include_vat: false,
            selected: group !== 'Optional items',
            created_at: new Date(),
            updated_at: new Date(),
            order: 0
        });
        setItem({ name: '', description: '', price: 0, quantity: 1 });
    };
    const allFieldsValid = [validateNotNull(item.name), validateIsNumber(item.price), validateIsPositiveNumber(item.quantity)].every(x => x.value !== undefined); // Allow 0 in price
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: `Add item to ${group}`, onConfirm: handleAddItem, confirmDisabled: !allFieldsValid, children: _jsxs("div", { className: "w-full flex flex-col gap-2", children: [GROUPS_TO_SHOW_DROPDOWN.includes(group) && _jsxs("div", { className: "w-full flex flex-col gap-1", children: [_jsx(FormLabel, { labelText: 'Select an item from your inventory' }), _jsx(Select, { placeholder: 'Type to search...', setSelectedKey: handleSelectItem, selectedKey: selectedItemId, options: addableItems, filter: true })] }), GROUPS_TO_SHOW_DROPDOWN.includes(group) && _jsxs(_Fragment, { children: [_jsx("div", { className: "mt-3 mb-2 border-b border-gray-200" }), _jsx(Text, { bold: true, children: "Or add a custom item" })] }), _jsx(Input, { label: 'Name', placeholder: 'Enter item name', value: item.name, setValue: (value) => setItem(prev => ({ ...prev, name: value })), validator: validateNotNull }), _jsx(TextArea, { label: 'Description', placeholder: 'Enter item description', value: item.description || '', setValue: (value) => setItem(prev => ({ ...prev, description: value })) }), _jsx(Input, { type: "number", label: 'Price', placeholder: 'Enter price', value: item.price, setValue: (value) => setItem(prev => ({ ...prev, price: parseFloat(value) })), prefix: '\u00A3', validator: validateIsNumber }), _jsx(Input, { type: "number", label: 'Quantity', placeholder: 'Enter quantity', value: item.quantity, setValue: (value) => setItem(prev => ({ ...prev, quantity: parseFloat(value) })), validator: validateIsPositiveNumber })] }) }));
};
const EditQuoteItemModal = ({ item, visible, setVisible, handleUpdateItem }) => {
    const [editedItem, setEditedItem] = useState(null);
    useEffect(() => {
        setEditedItem(item);
    }, [item]);
    const handleConfirm = () => {
        if (editedItem) {
            handleUpdateItem(editedItem);
            setVisible(false);
        }
    };
    if (!editedItem)
        return null;
    const allFieldsValid = [validateNotNull(editedItem.name), validateIsNumber(editedItem.price), validateIsPositiveNumber(editedItem.quantity)].every(x => x.value !== undefined); // Allow 0 in price
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Edit quote item', onConfirm: handleConfirm, confirmDisabled: !allFieldsValid, children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: 'Name', placeholder: 'Enter item name', value: editedItem.name, setValue: (value) => setEditedItem(prev => prev ? { ...prev, name: value } : null), validator: validateNotNull }), _jsx(TextArea, { label: 'Description', placeholder: 'Enter item description', value: editedItem.description || '', setValue: (value) => setEditedItem(prev => prev ? { ...prev, description: value } : null) }), _jsx(Input, { type: "number", label: 'Price', placeholder: 'Enter price', value: editedItem.price, setValue: (value) => setEditedItem(prev => prev ? { ...prev, price: parseFloat(value) } : null), prefix: '\u00A3', validator: validateIsNumber }), _jsx(Input, { type: "number", label: 'Quantity', placeholder: 'Enter quantity', value: editedItem.quantity, setValue: (value) => setEditedItem(prev => prev ? { ...prev, quantity: parseFloat(value) } : null), validator: validateIsPositiveNumber })] }) }));
};
