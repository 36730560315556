import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { Button } from '../buttons/button';
import { createPortal } from 'react-dom';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { X } from 'lucide-react';
export const Modal = ({ visible, setVisible, onConfirm, children, title, confirmButtonLabel, confirmDisabled = false, thirdButton, allowOverflow = false, cancelDisabled = false, cancelButtonLabel, hideOnConfirm = true }) => {
    const ref = useRef(null);
    const backdropRef = useRef(null);
    if (!visible)
        return;
    document.addEventListener('mousedown', (e) => {
        if (ref.current && !ref.current.contains(e.target) && !cancelDisabled)
            setVisible(false);
    });
    // createPortal creates a new element in the specified location in the DOM
    // (here, document.body, so the top of the DOM) and renders the children into
    // it. The element still magically keeps all the state it had - it's just
    // rendered elsewhere.  We use this for the modal component so it can be
    // rendered outside any parent elements which may have transform or overflow
    // properties which will break its positioning.
    // We prevent some events from propagating up the DOM so that clicks on a Headless UI
    // Combobox/Listbox menu don't close the modal. See:
    // - https://github.com/facebook/react/issues/11387
    // - https://github.com/tailwindlabs/headlessui/discussions/1925#discussioncomment-4678635
    return createPortal(_jsx("div", { onMouseDown: e => {
            // Only propagate the event if it's targeting the backdrop itself
            // not an element inside the modal
            if (backdropRef.current === e.target) {
                if (!cancelDisabled) {
                    setVisible(false);
                }
            }
            else {
                e.stopPropagation();
            }
        }, ref: backdropRef, className: `fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-20 ${visible ? '' : 'hidden'}`, children: _jsxs("div", { ref: ref, className: "bg-white rounded-lg w-full p-6 shadow gap-9 flex flex-col max-h-[75%] md:min-w-[50%] lg:max-w-[50%]", "data-cy": 'modal', children: [_jsxs("div", { className: "flex justify-between text-2xl items-center gap-x-4", children: [title && _jsx("div", { className: 'font-bold text-gray-900', children: title }), !cancelDisabled && _jsx(WrappedIcon, { onClick: () => setVisible(false), className: 'font-base text-gray-500 cursor-pointer', icon: X })] }), _jsx("div", { className: `flex-1 ${allowOverflow ? '' : 'overflow-auto'} flex no-scrollbar`, children: children }), _jsxs("div", { className: `${thirdButton ? 'justify-between' : 'justify-end'} flex gap-x-3`, children: [thirdButton && _jsx(Button, { colour: 'LIGHT', onClick: thirdButton.onClick, children: thirdButton.label }), _jsxs("div", { className: 'flex gap-x-3', children: [!cancelDisabled && _jsx(Button, { colour: 'LIGHT', onClick: () => {
                                        setVisible(false);
                                    }, children: cancelButtonLabel || (onConfirm ? 'Cancel' : 'Close') }), onConfirm && _jsx(Button, { disabled: confirmDisabled, "data-cy": 'modal_confirm', onClick: async () => {
                                        onConfirm();
                                        if (hideOnConfirm) {
                                            setVisible(false);
                                        }
                                    }, children: confirmButtonLabel ?? 'Confirm' })] })] })] }) }), document.body);
};
