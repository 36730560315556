import { match } from 'path-to-regexp';
export const redirectIfNeeded = (currentPath, navigateTo, userUUID) => {
    if (userUUID && match('/:companySubdomain/admin{/*subPath}')(currentPath)) {
        const matchResult = match('/:companySubdomain/admin{/*subPath}')(currentPath);
        if (!matchResult)
            return;
        const subPath = Array.isArray(matchResult.params.subPath) ? matchResult.params.subPath.join('/') : matchResult.params.subPath || '';
        navigateTo(`/admin/${subPath}`);
    }
    if (!match('')(currentPath))
        return;
    if (userUUID) {
        navigateTo('/admin');
        return;
    }
    navigateTo('/login');
};
