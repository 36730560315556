import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Info } from '../../../components/buttons/info';
export const FormLabelDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(FormLabel, { labelText: 'Hello world', helperText: 'helper', required: true, complete: true, size: 'MD', info: _jsx(Info, { infoModalHeader: "What is this?", infoModalBody: "Some info" }) })] })] });
};
const componentProps = [
    { name: 'labelText', description: 'Primary text for label' },
    { name: 'helperText', description: 'Secondary helper text, lighter colour' },
    { name: 'required', description: 'Is the input for this label required, if so show a required badge' },
    { name: 'complete', description: 'If required badge is true, is the step complete?' },
    { name: 'info', description: 'Info component to give more info on the title' },
    { name: 'size', description: 'SM | MD | LG | XL' }
];
const codeBlock = `<FormLabel
  labelText='Hello world'
  helperText='helper'
  required={true}
  complete={true}
  size='MD'
  info={<Info infoModalHeader="What is this?" infoModalBody="Some info"/>}
/>`;
