import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropdownMenu } from '../../../components/buttons/dropdown_menu';
import { Edit, Share, Trash } from 'lucide-react';
export const DropdownMenuDemo = () => {
    const handleEdit = () => console.log('Edit clicked');
    const handleDelete = () => console.log('Delete clicked');
    const handleShare = () => console.log('Share clicked');
    const items = [
        { label: 'Edit', icon: Edit, onClick: handleEdit },
        { label: 'Delete', icon: Trash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
        { label: 'Share', icon: Share, onClick: handleShare }
    ];
    return (_jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => (_jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name))) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Default (with ellipsis icon)" }), _jsx("div", { className: 'flex justify-start items-center gap-4', children: _jsx(DropdownMenu, { items: items }) }), _jsx("div", { children: "Custom icon" }), _jsx("div", { className: 'flex justify-start items-center gap-4', children: _jsx(DropdownMenu, { items: items, icon: Trash }) })] })] }));
};
const componentProps = [
    { name: 'icon', description: 'Custom icon for the dropdown button (optional, defaults to ellipsis)' },
    { name: 'items', description: 'Array of menu items' }
];
const codeBlock = `<DropdownMenu
  icon={faEllipsis}
  items={[
    { label: 'Edit', icon: Edit, onClick: handleEdit },
    { label: 'Delete', icon: Trash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
    { label: 'Share', icon: Share, onClick: handleShare },
  ]}
/>`;
