import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { ChevronDown } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
export const accordionCollapsedAll = -1;
export const Accordion = ({ rows, expandedID = accordionCollapsedAll, setIsCollapsed }) => {
    const [expandedId, setExpandedId] = useState(expandedID ?? accordionCollapsedAll);
    // Effect to handle external collapse control
    useEffect(() => {
        setExpandedId(expandedID);
    }, [expandedID]);
    if (rows.length === 0)
        return;
    const toggleExpanded = (id) => {
        const newExpandedId = expandedId === id ? accordionCollapsedAll : id;
        setExpandedId(newExpandedId);
        if (setIsCollapsed) {
            setIsCollapsed(newExpandedId === undefined);
        }
    };
    return _jsx(_Fragment, { children: _jsx("div", { className: "rounded border px-4 py-2 divide-y bg-white text-sm w-full", children: rows.map((x, i) => (_jsxs("div", { children: [_jsxs(motion.div, { onClick: () => toggleExpanded(i), className: "py-3 flex flex-grow justify-between items-center cursor-pointer", initial: false, animate: { backgroundColor: expandedId === i ? 'white' : 'white' }, transition: { duration: 0.2 }, children: [_jsx("div", { className: 'font-bold text-bold', children: x.title }), _jsx(motion.div, { initial: false, animate: { rotate: expandedId === i ? 180 : 0 }, transition: { duration: 0.2 }, children: _jsx(WrappedIcon, { icon: ChevronDown, className: "min-w-5 min-h-5" }) })] }), _jsx(AnimatePresence, { initial: false, children: expandedId === i && (_jsx(motion.div, { initial: { height: 0, opacity: 0 }, animate: {
                                height: 'auto',
                                opacity: 1,
                                transition: {
                                    height: { duration: 0.3 },
                                    opacity: { duration: 0.2, delay: 0.1 }
                                }
                            }, exit: {
                                height: 0,
                                opacity: 0,
                                transition: {
                                    height: { duration: 0.3 },
                                    opacity: { duration: 0.2 }
                                }
                            }, className: "overflow-hidden", children: _jsx("div", { className: "py-3 text-gray-500", children: x.body }) })) })] }, i))) }) });
};
