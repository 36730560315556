import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash';
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
export const HorizontalFormGroupDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(HorizontalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Hello world', helperText: 'some helper' }), input: _jsx(Toggle, { value: true, setValue: noop }) })] })] });
};
const componentProps = [
    { name: 'formLabel', description: 'Accepts any react component but expects a FormLabel' },
    { name: 'input', description: 'Accepts any react component but should be a Toggle, Input type element' }
];
const codeBlock = `<HorizontalFormGroup
  formLabel={<FormLabel labelText='Hello world' helperText='some helper' />}
  toggle={<Toggle value={true} setValue={noop} />}
/>`;
