import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { createPermissionTree, getDescendents } from './create_permission_tree';
import { Checkbox } from '../../../../../components/inputs_and_selections/checkbox';
export const PermissionTree = ({ permissions, companyRole, setEditingRole }) => {
    const graphs = createPermissionTree(permissions);
    const [tab, setTab] = useState();
    const filteredGraph = graphs.find(x => x.uuid === tab) ?? graphs[0];
    return _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx("div", { className: 'flex gap-8 overflow-x-auto no-scrollbar border-b border-gray-200', children: graphs.map(x => _jsx("div", { onClick: () => setTab(x.uuid), className: `
          outline-none py-4 whitespace-nowrap cursor-pointer text-default text-xs font-semibold leading-none flex gap-3
          ${x.uuid === filteredGraph.uuid ? 'border-black border-b-2 text-bold' : ''} 
        `, children: _jsx("div", { children: x.name }) }, x.uuid)) }), _jsx(PermissionTreeInner, { permission: filteredGraph, level: 0, companyRole: companyRole, setEditingRole: setEditingRole })] });
};
const PermissionTreeInner = ({ permission, level, companyRole, setEditingRole }) => {
    const [collapsed, setCollapsed] = useState(false);
    const isParent = permission.permissions.length > 0;
    const allPermissions = getDescendents(permission).map(x => x.uuid);
    const checkState = allPermissions.every(x => companyRole.company_role_permissions?.some(y => y.permission_uuid === x))
        ? 'ON'
        : allPermissions.some(x => companyRole.company_role_permissions?.some(y => y.permission_uuid === x))
            ? 'INDETERMINATE'
            : 'OFF';
    return _jsxs("div", { className: 'flex flex-col', children: [_jsxs("div", { className: `flex justify-between border-b border-gray-200 p-3 ${level === 0 ? 'bg-gray-100 rounded-t' : ''}`, children: [_jsx("div", { style: { paddingLeft: level > 1 ? level * 10 : 0 }, className: 'flex gap-1 cursor-pointer', onClick: () => setCollapsed(prev => !prev), children: _jsx("div", { className: `${isParent ? 'font-semibold' : 'font-medium'} text-gray-900 text-sm select-none`, children: permission.name }) }), _jsxs("div", { className: 'flex gap-4 items-center', children: [level === 0 && _jsx("div", { className: "text-gray-500 text-xs font-semibold", children: "Select all" }), _jsx(Checkbox, { checked: checkState, setChecked: () => setEditingRole(prev => ({
                                    ...prev,
                                    company_role_permissions: checkState !== 'OFF'
                                        ? prev.company_role_permissions?.filter(x => !allPermissions.includes(x.permission_uuid))
                                        : [...prev.company_role_permissions ?? [], ...allPermissions.map(x => ({
                                                uuid: crypto.randomUUID(),
                                                permission_uuid: x,
                                                created_at: new Date(),
                                                updated_at: new Date(),
                                                company_role_uuid: companyRole.uuid
                                            }))]
                                })), dataCy: `permission-checkbox-${permission.uuid}` })] })] }), !collapsed && permission.permissions.map(x => _jsx(PermissionTreeInner, { companyRole: companyRole, permission: x, level: level + 1, setEditingRole: setEditingRole }, x.uuid))] });
};
