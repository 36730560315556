import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { priceCalculations } from '../../../../../code/price_calculations';
import { EmptySection } from '../empty_section';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Edit, Plus, Trash } from 'lucide-react';
const AddPartModal = ({ addPart, visible, setVisible }) => {
    const [part, setPart] = useState({
        name: '',
        description: '',
        cost_price: 0,
        markup: 0
    });
    const updatePart = (field, value) => {
        setPart(prev => ({ ...prev, [field]: value }));
    };
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Add part', onConfirm: () => {
            addPart({
                part: {
                    ...part,
                    uuid: crypto.randomUUID(),
                    default_include: false
                },
                debounce: false
            });
            setPart({ name: '', description: '', cost_price: 0, markup: 0 });
        }, confirmButtonLabel: 'Add', children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: 'Name', placeholder: 'Enter part name', value: part.name, setValue: (value) => updatePart('name', value) }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(FormLabel, { labelText: 'Description', helperText: 'Part descriptions are displayed on the final quote, but not the estimate.' }), _jsx(TextArea, { placeholder: 'Enter part description', value: part.description, setValue: (value) => updatePart('description', value) })] }), _jsx(Input, { type: "number", label: 'Cost price', placeholder: 'Enter cost price', value: part.cost_price, setValue: (value) => updatePart('cost_price', parseFloat(value)), prefix: '\u00A3' }), _jsx(Input, { type: "number", label: 'Markup', placeholder: 'Enter markup', value: part.markup, setValue: (value) => updatePart('markup', parseFloat(value)), postfix: '%' }), _jsx(Input, { type: "number", label: 'Customer price', placeholder: 'Enter customer price', value: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup), setValue: (value) => {
                        updatePart('markup', 0);
                        updatePart('cost_price', parseFloat(value));
                    }, prefix: '\u00A3' })] }) }));
};
const EditPartModal = ({ part, visible, setVisible, updatePart }) => {
    const [editingName, setEditingName] = useState(part.name);
    const [editingDescription, setEditingDescription] = useState(part.description);
    const handleUpdateDescription = (value) => {
        setEditingDescription(value);
    };
    const handleUpdateName = (value) => {
        setEditingName(value);
    };
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Edit part', confirmButtonLabel: 'Save', onConfirm: () => {
            updatePart({ part: { ...part, name: editingName, description: editingDescription }, debounce: false });
        }, children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: 'Name', placeholder: 'Enter part name', value: editingName, setValue: handleUpdateName }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(FormLabel, { labelText: 'Description', helperText: 'Part descriptions are displayed on the final quote, but not the estimate.' }), _jsx(TextArea, { placeholder: 'Enter part description', value: editingDescription || '', setValue: handleUpdateDescription })] })] }) }));
};
export const PartsInventory = ({ addOrUpdatePart, deletePart, parts }) => {
    const [addPartModalVisible, setAddPartModalVisible] = useState(false);
    const [editPartModalPart, setEditPartModalPart] = useState(undefined);
    const partsColumns = [
        { key: 'name', name: 'Name' },
        { key: 'alwaysInclude', name: 'Always include', infoText: 'If toggled, the part will always be added to new estimates and quotes.' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "space-y-6", children: [_jsxs("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full", children: [_jsx(Heading, { size: "2xl", children: "Parts & materials" }), _jsx(Button, { colour: 'DARK', onClick: () => setAddPartModalVisible(true), iconLeft: Plus, children: "Add part" })] }), !parts.length
                        ? _jsx(EmptySection, { title: 'No parts added', description: 'Add parts to your inventory to quickly add them to estimates and quotes.', button: _jsx(Button, { onClick: () => setAddPartModalVisible(true), iconLeft: Plus, children: "Add part" }) })
                        : _jsx(Section, { children: _jsx(InventoryTable, { rows: parts
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((x) => ({
                                    name: _jsxs(_Fragment, { children: [_jsx(Text, { size: "SM", children: x.name }), _jsx(Text, { size: "XS", className: 'text-gray-500', children: x.description })] }),
                                    alwaysInclude: _jsx(Toggle, { value: x.default_include, setValue: () => addOrUpdatePart({ part: { ...x, default_include: !x.default_include }, debounce: false }) }),
                                    costPrice: _jsx(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => {
                                            addOrUpdatePart({ part: priceCalculations.updateCostPrice(x, parseFloat(value)), debounce: true });
                                        } }),
                                    markup: _jsx(Input, { type: 'number', step: 1, value: x.markup, setValue: (value) => {
                                            addOrUpdatePart({ part: priceCalculations.updateMarkup(x, parseFloat(value)), debounce: true });
                                        }, postfix: '%' }),
                                    customerPrice: _jsx(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => {
                                            addOrUpdatePart({ part: priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)), debounce: true });
                                        }, prefix: '\u00A3' }),
                                    menu: _jsx(DropdownMenu, { items: [
                                            { label: 'Edit', onClick: () => setEditPartModalPart(x), icon: Edit },
                                            { label: 'Delete', onClick: () => deletePart(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this part?' }
                                        ] })
                                })), columns: partsColumns }) })] }), _jsx(AddPartModal, { addPart: addOrUpdatePart, visible: addPartModalVisible, setVisible: setAddPartModalVisible }), editPartModalPart && _jsx(EditPartModal, { part: editPartModalPart, visible: !!editPartModalPart, setVisible: (visible) => {
                    if (!visible) {
                        setEditPartModalPart(undefined);
                    }
                }, updatePart: addOrUpdatePart })] }));
};
