import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { CanvasNav } from './canvas_nav';
import { FloorPlanInput } from './floor_buttons';
import { Slider } from '../../../components/inputs_and_selections/slider';
import { EyeOff, Eye, Upload, X, MinusCircle } from 'lucide-react';
export const FloorPlanButtons = ({ floor, setFloor, setShowFloorPlanButtons, setTempImageAndScale, setStageStep, planOpacity, setPlanOpacity }) => {
    const fileInputRef = useRef(null);
    const showOrHide = floor.floor_plan_is_showing
        ? { name: 'Hide', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: false }); setShowFloorPlanButtons(false); }, icon: EyeOff, variant: 'DEFAULT' }
        : { name: 'Show', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: true }); setShowFloorPlanButtons(false); }, icon: Eye, variant: 'DEFAULT' };
    const uploadOrRemove = floor.floor_plan_image || floor.floor_plan_url
        ? {
            name: 'Remove',
            onClick: () => {
                setFloor({ ...floor, floor_plan_image: '', floor_plan_url: '', floor_plan_scale: 1 });
                setShowFloorPlanButtons(false);
            },
            confirmText: 'Are you sure you want to remove the uploaded floor plan?',
            icon: MinusCircle,
            variant: 'DEFAULT'
        }
        : {
            name: 'Upload',
            onClick: () => {
                fileInputRef.current?.click();
            },
            icon: Upload,
            variant: 'DEFAULT'
        };
    return _jsxs(_Fragment, { children: [_jsx(CanvasNav, { title: 'Floor plan', headerContent: _jsx("div", { className: 'p-1 flex w-full', children: _jsx(Slider, { min: 0, max: 1, step: 0.1, value: planOpacity, onChange: setPlanOpacity }) }), items: [
                    showOrHide,
                    uploadOrRemove,
                    {
                        name: 'Close',
                        onClick: () => {
                            // fileInputRef.current?.click()
                            setShowFloorPlanButtons(false);
                        },
                        icon: X,
                        variant: 'DEFAULT'
                    }
                ] }), _jsx(FloorPlanInput, { inputRef: fileInputRef, setTempImageAndScale: setTempImageAndScale, setStageStep: setStageStep })] });
};
