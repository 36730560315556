import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { leadWithNewOverride } from '../../../../../code/models/lead';
import { Text } from '../../../../../components/content_display/text';
import { Heading } from '../../../../../components/content_display/heading';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { validateEmail, validateIsPositiveNumber, validateNotNull } from '../../../../../code/validators';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { estimateStoreysFromPropertyType, PROPERTY_TYPES } from '../../../../../code/models/property_type';
import { BUILT_FORM_TYPES } from '../../../../../code/models/built_form';
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from '../../../../../code/models/u_value';
import { FUELS } from '../../../../../code/models/fuel';
import { Section } from '../../components/section';
import { Button } from '../../../../../components/buttons/button';
import { HeatLossElementsTable, HeatLossSummaryCards } from '../../enquiry_manager/sections/heat_loss_estimate';
import { noop } from 'lodash';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { parseEnglishEpcInner } from '../../../../../code/models/epc';
import { parseScottishEpcInner } from '../../../../../code/models/epc_scotland';
import { ChevronRight, ChevronLeft, RefreshCw } from 'lucide-react';
import { PROJECT_TYPES } from '../../../../../code/models/project_type';
import { BUILD_REG_AGE_BANDS, BUILD_REG_VALUE_TYPES } from '../../../../../code/models/building_regs';
const responseRow = (question, enquiryResponse, editComponent) => {
    return {
        question: _jsx(Text, { size: "SM", bold: true, children: question }),
        enquiryResponse,
        estimateValue: editComponent
    };
};
export const EnquiryData = ({ lead, setLead, estimate }) => {
    const propertyMappedFromEpc = lead.epcData ? parseEnglishEpcInner(lead.epcData, lead.property) : parseScottishEpcInner(lead.epc_scotland, lead.property);
    const jobColumns = [
        { key: 'question', name: 'Field' },
        { key: 'estimateValue', name: 'Value' }
    ];
    const contactColumns = [
        { key: 'question', name: 'Question' },
        { key: 'estimateValue', name: 'Enquiry response' }
    ];
    const propertyColumns = [
        { key: 'question', name: 'Question' },
        { key: 'enquiryResponse', name: 'Enquiry response' },
        { key: 'estimateValue', name: 'Estimate value' }
    ];
    const jobRows = [
        responseRow('Job name', lead.job_name || '', _jsx(Input, { value: lead.job_name || '', setValue: (v) => setLead({ ...lead, job_name: v }) })),
        responseRow('Project type', lead.projectType, _jsx(Input, { value: PROJECT_TYPES.find(x => x.key === lead.projectType).name, disabled: true, setValue: noop }))
    ];
    const contactRows = [
        responseRow('First name', lead.customer?.first_name || '', _jsx(Input, { value: lead.customer?.first_name || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, first_name: v } }), validator: validateNotNull })),
        responseRow('Last name', lead.customer?.last_name || '', _jsx(Input, { value: lead.customer?.last_name || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, last_name: v } }), validator: validateNotNull })),
        responseRow('Email', lead.customer?.email || '', _jsx(Input, { value: lead.customer?.email || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, email: v } }), validator: validateEmail })),
        responseRow('Phone', lead.customer?.phone || '', _jsx(Input, { value: lead.customer?.phone || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, phone: v } }) })),
        responseRow('Where did you hear about us?', lead.customer?.source || '', _jsx(Input, { value: lead.customer?.source || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, source: v } }) })),
        responseRow('Customer comments', '', _jsx(TextArea, { readonly: true, value: lead.notes || '', setValue: noop, rows: 2 }))
    ];
    const setOverride = (key, value) => {
        setLead(leadWithNewOverride(lead, estimate.defaults, key, value));
    };
    const retrofitMaterialsRows = [
        responseRow('Built', ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)?.name || '', _jsx(Select, { options: ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.construction_age_band_uuid ?? lead.property.construction_age_band_uuid, setSelectedKey: (e) => setOverride('construction_age_band_uuid', e) })),
        responseRow('Wall construction', lead.property.wallGroup || '', _jsx(Select, { options: WALL_GROUPS.map(x => ({ key: x.name, value: x.name })), selectedKey: lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup, setSelectedKey: (e) => setOverride('wallGroup', e) })),
        responseRow('Wall insulation', ((lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION)
            .find(x => x.uuid === lead.property.wallType)?.name || '', (lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) !== TIMBER
            ? _jsx(Select, { options: ((lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).map(x => ({
                    key: x.uuid,
                    value: x.name
                })), selectedKey: lead.property.houseOverrides?.wallType ?? lead.property.wallType, setSelectedKey: (e) => setOverride('wallType', e) })
            : null),
        responseRow('Windows', lead.property.windowType, _jsx(Select, { options: WINDOW_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.windowType ?? lead.property.windowType, setSelectedKey: (e) => setOverride('windowType', e) })),
        responseRow('Floor insulation', lead.property.floorType, _jsx(Select, { options: FLOOR_INSULATION.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.floorType ?? lead.property.floorType, setSelectedKey: (e) => setOverride('floorType', e) })),
        responseRow('Loft insulation', lead.property.loftInsulation || '', _jsx(Select, { options: LOFT_INSULATION.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.loftInsulation ?? lead.property.loftInsulation, setSelectedKey: (e) => setOverride('loftInsulation', e) }))
    ];
    const newbuildMaterialsRows = lead.property.buildingRegs ? [
        // responseRow('New build jurisdiction', BUILD_REG_JURISDICTIONS.find(x => x.key === lead.property.buildingRegs?.jurisdiction)?.label || '',
        //   <Select options={BUILD_REG_JURISDICTIONS.map(x => ({ key: x.key, value: x.label }))}
        //     selectedKey={lead.property.houseOverrides?.buildingRegs?.jurisdiction ?? lead.property.buildingRegs?.jurisdiction}
        //     setSelectedKey={(e) => setOverride('buildingRegs', {
        //       ...(lead.property.houseOverrides?.buildingRegs ?? lead.property.buildingRegs!),
        //       jurisdiction: e
        //     })} />
        // ),
        responseRow('Building regs', BUILD_REG_AGE_BANDS.find(x => x.key === lead.property.buildingRegs?.ageBand)?.label || '', _jsx(Select, { options: BUILD_REG_AGE_BANDS.map(x => ({ key: x.key, value: x.label })), selectedKey: lead.property.houseOverrides?.buildingRegs?.ageBand ?? lead.property.buildingRegs.ageBand, setSelectedKey: (e) => {
                setOverride('buildingRegs', {
                    ...(lead.property.houseOverrides?.buildingRegs ?? lead.property.buildingRegs),
                    ageBand: e
                });
            } })),
        responseRow('Design to', BUILD_REG_VALUE_TYPES.find(x => x.key === lead.property.buildingRegs?.valueType)?.label || '', _jsx(Select, { options: BUILD_REG_VALUE_TYPES.map(x => ({ key: x.key, value: x.label })), selectedKey: lead.property.houseOverrides?.buildingRegs?.valueType ?? lead.property.buildingRegs.valueType, setSelectedKey: (e) => {
                setOverride('buildingRegs', {
                    ...(lead.property.houseOverrides?.buildingRegs ?? lead.property.buildingRegs),
                    valueType: e
                });
            } }))
    ] : [];
    const propertyRows = [
        responseRow('Property type', (lead.property.propertyType || propertyMappedFromEpc?.propertyType || ''), _jsx(Select, { options: PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.propertyType ?? lead.property.propertyType, setSelectedKey: (e) => setOverride('propertyType', e) })),
        responseRow('Built form', lead.property.builtForm, _jsx(Select, { options: BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.property.houseOverrides?.builtForm ?? lead.property.builtForm, setSelectedKey: (e) => setOverride('builtForm', e) })),
        // Swap out retrofit materials with newbuild regs
        ...(lead.projectType === 'retrofit' ? retrofitMaterialsRows : newbuildMaterialsRows),
        responseRow('Bedrooms', lead.property.noBedrooms.toString(), _jsx(Input, { type: 'number', value: (lead.property.houseOverrides?.noBedrooms ?? lead.property.noBedrooms ?? 0).toString(), setValue: (e) => setOverride('noBedrooms', parseFloat(e)) })),
        responseRow('Bathrooms', lead.property.noBathrooms.toString(), _jsx(Input, { type: 'number', value: (lead.property.houseOverrides?.noBathrooms ?? lead.property.noBathrooms ?? 0).toString(), setValue: (e) => setOverride('noBathrooms', parseFloat(e)) })),
        // Older jobs wont have storeys, so we estimate it from property type in these cases.
        responseRow('Floors', (lead.property.noStoreys ? lead.property.noStoreys : estimateStoreysFromPropertyType(lead.property.propertyType)).toString(), _jsx(Input, { type: 'number', value: (lead.property.houseOverrides?.noStoreys ?? lead.property.noStoreys ?? estimateStoreysFromPropertyType(lead.property.propertyType)).toString(), setValue: (e) => {
                // Don't allow someone to set 0 floors.
                if (parseInt(e) < 1) {
                    e = '1';
                }
                setOverride('noStoreys', parseInt(e));
            }, validator: validateIsPositiveNumber, validateImmediately: true })),
        responseRow(lead.projectType === 'new-build' ? 'Comparison system' : 'Fuel type', lead.property.fuelType, _jsx(Select, { options: FUELS.map(x => ({ key: x.name, value: x.name })), selectedKey: lead.property.houseOverrides?.fuelType ?? lead.property.fuelType, setSelectedKey: (e) => setOverride('fuelType', e) })),
        responseRow('Floor area (m²)', lead.property.floorArea?.toString() || '', _jsx(Input, { type: 'number', value: (lead.property.houseOverrides?.floorArea ?? lead.property.floorArea).toString(), setValue: (e) => setOverride('floorArea', parseFloat(e)) })),
        responseRow('Room height (m)', lead.property.roomHeight?.toString() || '', _jsx(Input, { type: 'number', value: (lead.property.houseOverrides?.roomHeight ?? lead.property.roomHeight).toString(), setValue: (e) => setOverride('roomHeight', parseFloat(e)) })),
        responseRow('Outdoor space', lead.property.outdoorSpace || '', _jsx(Select, { options: OUTDOOR_SPACE.map(x => ({ key: x.key, value: x.label })), selectedKey: lead.property.houseOverrides?.outdoorSpace ?? lead.property.outdoorSpace, setSelectedKey: (e) => setOverride('outdoorSpace', e) }))
    ];
    const [heatLossSidebarVisible, setHeatLossSidebarVisible] = useState(true);
    const handleResetEditingData = () => {
        setLead({
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    designTempOverride: undefined,
                    internalTempOverride: undefined,
                    airChangeOverride: undefined,
                    externalWallUValueOverride: undefined,
                    partyWallUValueOverride: undefined,
                    windowsUValueOverride: undefined,
                    floorUValueOverride: undefined,
                    roofUValueOverride: undefined
                }
            }
        });
    };
    return (_jsxs("div", { className: 'flex flex-col lg:flex-row gap-6', children: [_jsx("div", { className: 'flex-1', children: _jsxs(Section, { title: "Enquiry data", controls: _jsxs(Button, { size: "SM", iconRight: heatLossSidebarVisible ? ChevronRight : ChevronLeft, onClick: () => setHeatLossSidebarVisible(!heatLossSidebarVisible), className: "hidden lg:block", children: [heatLossSidebarVisible ? 'Hide' : 'Show', " heat loss sidebar"] }), children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Heading, { size: "lg", children: "Job details" }), _jsx(TableLite, { rows: jobRows, columns: jobColumns, alignRight: false })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Heading, { size: "lg", children: "Contact details" }), _jsx(TableLite, { rows: contactRows, columns: contactColumns, alignRight: false })] }), _jsxs("div", { className: 'flex flex-col gap-2', "data-cy": "property_info", children: [_jsx(Heading, { size: "lg", children: "Property details" }), _jsx(TableLite, { rows: propertyRows, columns: propertyColumns, alignRight: false })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Heading, { size: "lg", children: "Installer notes" }), _jsx(TextArea, { value: lead.installer_notes || '', setValue: (v) => setLead({ ...lead, installer_notes: v }) })] })] }) }), _jsx("div", { className: `flex ${heatLossSidebarVisible ? '' : 'lg:hidden'}`, children: _jsxs(Section, { className: 'lg:max-w-[500px] w-full', title: "Heat loss estimate", controls: _jsx(Button, { colour: 'LIGHT', iconLeft: RefreshCw, onClick: handleResetEditingData, size: "SM", children: "Reset to defaults" }), children: [_jsx(HeatLossElementsTable, { lead: lead, estimate: estimate, setOverride: setOverride }), _jsx("hr", { className: 'my-2 border-light' }), _jsx(Heading, { size: "md", children: "Estimates" }), _jsx(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 1 })] }) })] }));
};
