import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { priceCalculations } from '../../../../../code/price_calculations';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { EmptySection } from '../empty_section';
import { Edit, Plus, Trash } from 'lucide-react';
const CylinderInputs = ({ cylinder, setCylinder, isEditing, packs }) => {
    const [selectedPackId, setSelectedPackId] = useState(cylinder.default_pack_uuid ?? undefined);
    const packOptions = [
        { key: null, value: 'None' },
        ...packs.map(pack => ({ key: pack.uuid, value: pack.name }))
    ];
    const handleSetPack = async (packId) => {
        setCylinder({ ...cylinder, default_pack_uuid: packId });
        setSelectedPackId(packId);
    };
    return (_jsxs("div", { className: 'space-y-4 w-full p-1', children: [_jsx(Input, { label: 'Name', value: cylinder.name || '', setValue: (value) => setCylinder({ ...cylinder, name: value }) }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Associated pack' }), _jsx(Select, { placeholder: "Select a pack", options: packOptions, selectedKey: selectedPackId, setSelectedKey: handleSetPack }), _jsx(Text, { size: "XS", className: "text-gray-500", children: "This pack will be automatically included when this hot water cylinder is added to an estimate or quote." })] }), _jsx(Input, { type: "number", label: 'Cost price', placeholder: 'Enter cost price', value: cylinder.cost_price, setValue: (value) => setCylinder({ ...cylinder, cost_price: parseFloat(value) }), prefix: '\u00A3' }), _jsx(Input, { type: "number", label: 'Markup', placeholder: 'Enter markup', value: cylinder.markup, setValue: (value) => setCylinder({ ...cylinder, markup: parseFloat(value) }), postfix: '%' }), _jsx(Input, { type: "number", label: 'Customer price', placeholder: 'Enter customer price', value: priceCalculations.calculateCustomerPrice(cylinder.cost_price, cylinder.markup), setValue: (value) => {
                    setCylinder({ ...cylinder, markup: 0, cost_price: parseFloat(value) });
                }, prefix: '\u00A3' }), _jsx(Input, { type: 'number', label: 'Capacity', value: cylinder.litres || 0, postfix: "L", setValue: (value) => setCylinder({ ...cylinder, litres: parseInt(value) }) })] }));
};
const AddCylinderModal = ({ visible, setVisible, addHotWaterCylinder, packs }) => {
    const [newCylinder, setNewCylinder] = useState({
        name: '',
        cost_price: 0,
        markup: 0,
        litres: 0
    });
    const handleAddCylinder = () => {
        const cylinder = {
            ...newCylinder,
            uuid: crypto.randomUUID()
        };
        addHotWaterCylinder({ hotWaterCylinder: cylinder, debounce: false });
        setVisible(false);
        setNewCylinder({ name: '', cost_price: 0, markup: 0, litres: 0 });
    };
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Add a hot water cylinder', onConfirm: handleAddCylinder, confirmButtonLabel: 'Add', children: _jsx(CylinderInputs, { cylinder: newCylinder, setCylinder: setNewCylinder, isEditing: false, packs: packs }) }));
};
const EditCylinderModal = ({ cylinder, visible, setVisible, updateHotWaterCylinder, packs }) => {
    const [localCylinder, setLocalCylinder] = useState({
        name: cylinder.name,
        cost_price: cylinder.cost_price,
        markup: cylinder.markup,
        litres: cylinder.litres
    });
    const handleUpdateCylinder = () => {
        updateHotWaterCylinder({
            hotWaterCylinder: {
                ...cylinder,
                ...localCylinder
            },
            debounce: false
        });
        setVisible(false);
    };
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: 'Edit hot water cylinder', onConfirm: handleUpdateCylinder, confirmButtonLabel: 'Save', children: _jsx(CylinderInputs, { cylinder: localCylinder, setCylinder: setLocalCylinder, isEditing: true, packs: packs }) }));
};
export const HotWaterCylindersInventory = ({ hotWaterCylinders, addHotWaterCylinder, deleteHotWaterCylinder, updateHotWaterCylinder, packs }) => {
    const [modalCylinderId, setModalCylinderId] = useState();
    const [addCylinderModalVisible, setAddCylinderModalVisible] = useState(false);
    const modalCylinder = hotWaterCylinders.find(x => x.uuid === modalCylinderId);
    const cylindersColumns = [
        { key: 'name', name: 'Name' },
        { key: 'litres', name: 'Capacity (L)' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "space-y-6", children: [_jsxs("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full", children: [_jsx(Heading, { size: "2xl", children: "Hot Water Cylinders" }), _jsx(Button, { colour: 'DARK', onClick: () => setAddCylinderModalVisible(true), iconLeft: Plus, children: "Add Cylinder" })] }), !hotWaterCylinders.length
                        ? _jsx(EmptySection, { title: 'No hot water cylinders added', description: 'Add hot water cylinders to your inventory to create estimates and quotes for your customers.', button: _jsx(Button, { onClick: () => setAddCylinderModalVisible(true), iconLeft: Plus, children: "Add hot water cylinder" }) })
                        : _jsx(Section, { children: _jsx(InventoryTable, { rows: hotWaterCylinders
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(x => {
                                    const packForHotWaterCylinder = packs.find(pack => pack.uuid === x.default_pack_uuid);
                                    return {
                                        name: _jsxs("div", { className: "flex flex-col gap-2 items-start", children: [_jsx(Text, { size: "SM", children: x.name }), packForHotWaterCylinder && _jsx("div", { className: 'flex gap-1 items-center', children: _jsx(Badge, { color: "LIGHT", text: packForHotWaterCylinder.name }) })] }),
                                        litres: _jsx(Input, { type: 'number', value: x.litres, setValue: (value) => updateHotWaterCylinder({ hotWaterCylinder: { ...x, litres: parseInt(value) }, debounce: true }) }),
                                        costPrice: _jsx(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => updateHotWaterCylinder({ hotWaterCylinder: priceCalculations.updateCostPrice(x, parseFloat(value)), debounce: true }) }),
                                        markup: _jsx(Input, { type: 'number', step: 1, value: Math.round(x.markup), setValue: (value) => updateHotWaterCylinder({ hotWaterCylinder: priceCalculations.updateMarkup(x, parseFloat(value)), debounce: true }), postfix: '%' }),
                                        customerPrice: _jsx(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => updateHotWaterCylinder({ hotWaterCylinder: priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)), debounce: true }), prefix: '\u00A3' }),
                                        menu: _jsx(DropdownMenu, { items: [
                                                { label: 'Edit', onClick: () => setModalCylinderId(x.uuid), icon: Edit },
                                                { label: 'Delete', onClick: () => deleteHotWaterCylinder(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this hot water cylinder?' }
                                            ] })
                                    };
                                }), columns: cylindersColumns }) })] }), _jsx(AddCylinderModal, { visible: addCylinderModalVisible, setVisible: setAddCylinderModalVisible, addHotWaterCylinder: addHotWaterCylinder, packs: packs }), modalCylinder && (_jsx(EditCylinderModal, { cylinder: modalCylinder, visible: !!modalCylinderId, setVisible: (visible) => setModalCylinderId(visible ? modalCylinderId : undefined), updateHotWaterCylinder: updateHotWaterCylinder, packs: packs }))] }));
};
