import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Input } from '../../../../components/inputs_and_selections/input';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Check } from 'lucide-react';
export const HPLInstallationDetailsBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { survey, setSurvey } = hplContext;
    const mountingLocations = ['Ground', 'Wall', 'Flat roof'];
    const baseBuiltBy = ['Installer', 'Builder'];
    const condensateDrain = ['Nearby drain or downpipe', 'Soak away to be dug'];
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Installation" }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Mounting location" }), _jsx(Badge, { color: survey.mounting_location ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.mounting_location ? Check : undefined })] }), _jsx(RadioGroup, { items: mountingLocations.map(x => ({
                            name: x,
                            onClick: () => setSurvey({ ...survey, mounting_location: x }),
                            variant: survey.mounting_location === x ? 'ACTIVE' : 'DEFAULT'
                        })) })] }), survey.mounting_location === 'Ground' && _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Base to be built by" }), _jsx(Badge, { color: survey.base_to_be_build_by ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.base_to_be_build_by ? Check : undefined })] }), _jsx(RadioGroup, { items: baseBuiltBy.map(x => ({
                            name: x,
                            onClick: () => setSurvey({ ...survey, base_to_be_build_by: x }),
                            variant: survey.base_to_be_build_by === x ? 'ACTIVE' : 'DEFAULT'
                        })) })] }), survey.mounting_location === 'Flat roof' && _jsxs(_Fragment, { children: [_jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Roof height" }), _jsx(Input, { type: 'number', value: survey.roof_height_m.toString(), setValue: (e) => setSurvey({ ...survey, roof_height_m: Number(e) }), postfix: 'm' })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Access notes" }), _jsx("div", { className: "text-gray-500 text-sm", children: "How will you access the roof?" })] }), _jsx(TextArea, { value: survey.access_notes, setValue: (e) => setSurvey({ ...survey, access_notes: e }) })] })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Condensate drain" }), _jsx(Badge, { color: survey.condensate_drain ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.condensate_drain ? Check : undefined })] }), _jsx(RadioGroup, { items: condensateDrain.map(x => ({
                            name: x,
                            onClick: () => setSurvey({ ...survey, condensate_drain: x }),
                            variant: survey.condensate_drain === x ? 'ACTIVE' : 'DEFAULT'
                        })) })] })] });
};
