import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { signProposalAPI } from '../../../../code/models/proposal';
import { useState } from 'react';
import confetti from 'canvas-confetti';
import { createJobEvent, JobEventType } from '../../../../code/models/job_event';
import { validateLooksLikeDate, validateNotNull } from '../../../../code/validators';
import { Modal } from '../../../../components/containers/modal';
import { yellowEnvelope } from '../../../../assets/images/images';
import { Text } from '../../../../components/content_display/text';
import { formatPrice } from '../../../../code/format_price';
import { Input } from '../../../../components/inputs_and_selections/input';
export const AcceptProposalModal = ({ companyPublicInfo, proposal, setProposal, quoteSnapshot, visible, setVisible, termsAndConditionsLink }) => {
    const [accepted, setAccepted] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('NOT_ACCEPTED');
    const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant;
    const termsAndConditionsLinkUrl = termsAndConditionsLink
        ? termsAndConditionsLink?.url + (termsAndConditionsLink?.internal ? `?name_override=${encodeURIComponent(termsAndConditionsLink.title)}.${termsAndConditionsLink.url.split('.')[1]}` : '')
        : undefined;
    const handleOnConfirm = async () => {
        if (status === 'ACCEPTED') {
            setVisible(false);
            return;
        }
        try {
            await signProposalAPI({ acceptance_customer_name: name, acceptance_customer_date: date }, proposal.lead_uuid, companyPublicInfo.uuid, proposal.uuid);
            setStatus('ACCEPTED');
            setProposal({
                ...proposal,
                acceptance_status: 1,
                acceptance_customer_name: name,
                acceptance_customer_date: date
            });
            confetti();
            await createJobEvent({
                event_type: JobEventType.enum.ProposalAccepted,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: proposal.lead_uuid,
                extra_data: {
                    proposal_uuid: proposal.uuid
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    };
    const allValid = (validateNotNull(name).value && validateLooksLikeDate(date).value && accepted);
    return (_jsxs(Modal, { visible: visible, setVisible: setVisible, title: 'Accept proposal', confirmButtonLabel: status === 'ACCEPTED' ? 'Done' : 'Sign', onConfirm: handleOnConfirm, hideOnConfirm: false, confirmDisabled: !allValid, cancelDisabled: status === 'ACCEPTED', children: [status === 'ACCEPTED' && _jsxs("div", { className: 'px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex w-full', children: [_jsx("img", { alt: 'envelope', src: yellowEnvelope, className: 'w-16 h-16' }), _jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Success" }), _jsx("div", { className: "text-center flex flex-col gap-1", children: _jsx("span", { children: "Your proposal was successfully signed." }) })] }), status === 'NOT_ACCEPTED' && _jsxs("div", { className: 'flex flex-col gap-5 w-full', children: [_jsxs("div", { className: 'flex flex-col gap-2 w-full border-b border-gray-300 pb-5', children: [_jsxs("div", { className: 'flex flex-row justify-between items-center text-xs', children: [_jsx(Text, { size: "XS", className: "text-gray-600", children: "Total before grant" }), _jsx(Text, { size: "XS", className: "text-gray-600", children: formatPrice(quoteSnapshot.total_pre_deductions, 2) })] }), quoteSnapshot.bus_grant < 0 &&
                                _jsxs("div", { className: 'flex flex-row justify-between items-start', children: [_jsx(Text, { size: "XS", className: "text-gray-600", children: "Boiler Upgrade Scheme (BUS) Grant" }), _jsx(Text, { size: "XS", className: "text-gray-600", children: formatPrice(quoteSnapshot.bus_grant, 2) })] }), _jsxs("div", { className: 'flex flex-row justify-between items-center', children: [_jsx(Text, { bold: true, children: "Total" }), _jsx(Text, { bold: true, children: formatPrice(total, 2) })] })] }), _jsxs("label", { className: "flex items-center gap-2", children: [_jsx("input", { type: "checkbox", className: "h-4 w-4", checked: accepted, onChange: () => setAccepted(!accepted) }), _jsxs("div", { className: "text-gray-600 text-sm", children: ["I accept the proposal", termsAndConditionsLinkUrl ? _jsxs(_Fragment, { children: [" and agree to be bound by the ", _jsx("a", { href: termsAndConditionsLinkUrl, target: "_blank", rel: "noreferrer", className: "underline", children: "terms and conditions" }), "."] }) : _jsx(_Fragment, { children: "." })] })] }), _jsx(Input, { label: 'Your name', placeholder: 'Full name', value: name, setValue: setName, validator: validateNotNull }), _jsx(Input, { label: "Today's date", placeholder: 'dd/mm/yyyy', value: date, setValue: setDate, validator: validateLooksLikeDate })] })] }));
};
