import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowLeft, X } from 'lucide-react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { Icon } from '../buttons/icon';
import { Button } from '../buttons/button';
const MultiStageModal = ({ isOpen, setIsOpen, currentStage, handleNavigateForward, handleNavigateBack, canGoBack }) => {
    if (!currentStage)
        return null;
    return (_jsxs(Dialog, { open: isOpen, onClose: () => setIsOpen(false), className: "relative z-50", children: [_jsx(DialogBackdrop, { className: "fixed inset-0 bg-black/30" }), _jsx(DialogPanel, { className: "fixed inset-0 flex w-screen items-center justify-center p-4", children: _jsxs("div", { className: "flex flex-col max-h-[75%] w-full md:min-w-[50%] lg:max-w-[50%] bg-white rounded-xl shadow justify-start items-start", children: [_jsxs("div", { className: "flex flex-row items-center justify-between gap-4 p-4 border-b border-light w-full", children: [_jsx("div", { className: "w-8 h-8 flex items-center justify-center", children: canGoBack && (_jsx(Icon, { icon: ArrowLeft, onClick: () => handleNavigateBack() })) }), _jsx("div", { className: "font-bold text-gray-900", children: currentStage.title }), _jsx(Icon, { icon: X, onClick: () => setIsOpen(false) })] }), _jsx("div", { className: 'flex flex-col gap-4 p-4 w-full overflow-y-auto', children: currentStage.component() }), _jsxs("div", { className: "flex flex-row items-center justify-end gap-4 p-4 border-t border-light w-full", children: [_jsx(Button, { colour: 'LIGHT', onClick: () => setIsOpen(false), children: "Close" }), currentStage.goNext && _jsx(Button, { colour: currentStage.dangerous ? 'DANGER' : 'DARK', disabled: currentStage.goNextDisabled, onClick: async () => currentStage.goNext && handleNavigateForward(await currentStage.goNext()), children: currentStage.goNextLabel || 'Next' })] })] }) })] }));
};
export default MultiStageModal;
