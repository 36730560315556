import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { SurveySiteDetailsContext } from '../site_details';
import { getAddressIncludingPostcode } from '../../../../code/models/lead';
export const SiteDetailsLocationBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const { lead, postcodeLocation } = prContext;
    return _jsx(_Fragment, { children: _jsxs("div", { className: "flex-col justify-end items-start gap-3 flex", children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Location" }), postcodeLocation &&
                    _jsx(Map, { defaultZoom: 18, className: "h-[200px] w-full rounded overflow-hidden", mapTypeId: "satellite", defaultCenter: postcodeLocation, children: _jsx(Marker, { position: postcodeLocation }) }), _jsx("div", { className: "text-gray-600 text-sm", children: getAddressIncludingPostcode(lead) })] }) });
};
