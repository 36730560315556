import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { remindPassword } from '../../code/models/auth';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { Link } from '../../components/buttons/link';
export const RemindPasswordPage = ({ navigateTo }) => {
    // common admin/ preloaded data
    const [email, setEmail] = useState('');
    // component specific
    const [showSent, setShowSent] = useState(false);
    const handleSubmit = async () => {
        await remindPassword(email);
        setShowSent(true);
    };
    return _jsxs("div", { className: "flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto", children: [_jsx("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: SpruceLogoBlack }), _jsx("div", { className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "text-slate-600 text-lg font-bold", children: "Email" }), _jsx(Input, { onEnter: async () => { await handleSubmit(); }, setValue: setEmail, type: "text", value: email, placeholder: 'Email' })] }), _jsx(Link, { text: 'Back to Login page', onClick: () => navigateTo('/login') }), _jsx(Button, { onClick: handleSubmit, children: "Send link" }), showSent && _jsx("div", { className: "text-sm text-green-600", children: "If your email address is registered on Spruce, we will send you an email with a link to reset your password." })] }) })] });
};
