import { z } from 'zod';
export const BuildingRegsSchema = z.object({
    jurisdiction: z.enum(['england_wales']),
    ageBand: z.enum(['2012-2022', '2023-']),
    valueType: z.enum(['limiting', 'notional'])
});
export const BUILD_REG_JURISDICTIONS = [
    { key: 'england_wales', label: 'England & Wales' }
    // { key: 'scotland', label: 'Scotland' } // We don't have scottish regs yet.
];
export const BUILD_REG_AGE_BANDS = [
    { key: '2023-', label: '2023 onwards' },
    { key: '2012-2022', label: '2012-2022' }
];
export const BUILD_REG_VALUE_TYPES = [
    { key: 'limiting', label: 'Limiting (maximum) values' },
    { key: 'notional', label: 'Notional (average) values' }
];
export const NEW_BUILD_REG_VALUES = [
    {
        jurisdiction: 'england_wales',
        valueType: 'limiting',
        ageBand: '2012-2022',
        values: {
            wallUValue: 0.30,
            partyWallUValue: 0.2,
            windowUValue: 2.0,
            floorUValue: 0.25,
            roofUValue: 0.20
        }
    },
    {
        jurisdiction: 'england_wales',
        valueType: 'limiting',
        ageBand: '2023-',
        values: {
            wallUValue: 0.26,
            partyWallUValue: 0.2,
            windowUValue: 1.6,
            floorUValue: 0.18,
            roofUValue: 0.16
        }
    },
    {
        jurisdiction: 'england_wales',
        valueType: 'notional',
        ageBand: '2012-2022',
        values: {
            wallUValue: 0.18,
            partyWallUValue: 0,
            windowUValue: 1.4,
            floorUValue: 0.13,
            roofUValue: 0.13
        }
    },
    {
        jurisdiction: 'england_wales',
        valueType: 'notional',
        ageBand: '2023-',
        values: {
            wallUValue: 0.18,
            partyWallUValue: 0,
            windowUValue: 1.2,
            floorUValue: 0.13,
            roofUValue: 0.11
        }
    }
];
// Used in materials.ts to generate a unique key for the building regs
export const generateNewBuildRegsReferenceKey = (buildingRegs) => {
    return `${buildingRegs.jurisdiction}.${buildingRegs.ageBand}.${buildingRegs.valueType}`;
};
export const determineDefaultUValuesFromNewBuildRegs = (buildingRegs) => {
    const defaultUValues = NEW_BUILD_REG_VALUES.find(reg => reg.valueType === buildingRegs.valueType &&
        reg.ageBand === buildingRegs.ageBand &&
        reg.jurisdiction === buildingRegs.jurisdiction)?.values;
    if (!defaultUValues) {
        throw new Error('Could not find default u-values for new build regs');
    }
    else {
        return defaultUValues;
    }
};
