import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircle } from 'lucide-react';
export const SurveyOptionGroup = ({ formLabel, options, selectedOptionKey, onSelect, noCheckmark = false, inline = false }) => {
    // a function to calc class for selected option
    const selectedClass = (key) => {
        if (selectedOptionKey === key) {
            return 'border-solid border-2 border-gray-700';
        }
        else {
            // unselected option also has an invisible border (color is the same as background),
            // it prevents the layout from jumping when the selected state visible border appears
            // playing with padding didn't help
            return 'border-solid border-2 border-gray-100 bg-gray-100';
        }
    };
    const positioningClasses = inline ? 'flex-row' : 'flex-col';
    return _jsxs("div", { className: 'flex flex-col gap-2', children: [formLabel || null, _jsx("div", { className: `${positioningClasses} w-full gap-3 flex`, children: options.map((option, index) => {
                    return _jsxs("div", { onClick: () => { onSelect?.(option.key); }, className: `${selectedClass?.(option.key)} cursor-pointer rounded-xl items-center flex justify-between p-2 sm:p-3`, children: [_jsxs("div", { className: "flex gap-x-4 items-center", children: [option.icon && _jsx("img", { className: "h-8 w-8 sm:h-16 sm:w-16", alt: option.label, src: option.icon }), _jsxs("div", { className: "flex-col flex text-sm sm:text-base", children: [_jsx("div", { className: "text-gray-900 font-bold", children: option.label }), option.sublabel && _jsx("div", { className: "select-none text-gray-500", children: option.sublabel })] })] }), !noCheckmark && _jsxs(_Fragment, { children: [selectedOptionKey === option.key && _jsx(CheckCircle, { className: "w-5 h-5" }), selectedOptionKey !== option.key && _jsx("div", { className: "w-5 h-5", children: _jsx("div", { className: "w-5 h-5 bg-white rounded-full border-solid border border-neutral-300" }) })] })] }, index);
                }) })] });
};
