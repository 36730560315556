import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { logEvent } from '../../code/log_event';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { AuthSDK } from '../../code/utils/auth_provider';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { AppContext } from '../../app_context';
import { Link } from '../../components/buttons/link';
export const LoginPage = ({ navigateTo }) => {
    const appContext = React.useContext(AppContext);
    // common admin/ preloaded data
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = async (event) => {
        event?.preventDefault();
        logEvent({ name: 'Login Clicked', properties: {} }, null);
        await AuthSDK.signIn(email, password).then(() => {
            // If there's a redirect query param, go there
            const redirectPath = new URLSearchParams(window.location.search).get('redirect');
            if (redirectPath) {
                navigateTo(redirectPath);
                return;
            }
            // Otherwise, go to the home page
            navigateTo('/admin/enquiries');
        }).catch((e) => {
            console.error('Error logging in', e);
            appContext.setError(e);
        });
    };
    return _jsxs("div", { className: "flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto", children: [_jsx("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: SpruceLogoBlack }), _jsx("div", { onSubmit: handleSubmit, className: "w-full", children: _jsxs("div", { className: "flex flex-col w-full gap-4", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "text-slate-600 text-lg font-bold", children: "Email" }), _jsx(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setEmail, type: 'text', value: email, placeholder: 'Email' })] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "text-slate-600 text-lg font-bold", children: "Password" }), _jsx(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPassword, type: "password", value: password, placeholder: 'Password' })] }), _jsx(Link, { onClick: () => navigateTo('/remind-password'), text: 'Forgot password?' }), _jsx(Button, { onClick: handleSubmit, children: "Login" })] }) })] });
};
