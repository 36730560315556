import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { ArrowLeftIcon } from 'lucide-react';
import { SurveyActionType } from '../../../code/survey/survey';
import { SurveyDispatchContext } from '../survey_page';
import { Button } from '../../../components/buttons/button';
export const QuestionnaireNavigateBack = () => {
    const dispatch = useContext(SurveyDispatchContext);
    const handleNavigatePreviousPage = () => {
        dispatch({
            type: SurveyActionType.NavigatePreviousPage
        });
    };
    return _jsx(Button, { colour: 'LIGHT', onClick: handleNavigatePreviousPage, block: false, className: "self-start", children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx(ArrowLeftIcon, { className: "h-4 w-4" }), _jsx("div", { children: "Back" })] }) });
};
