import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Button } from '../../../../components/buttons/button';
import { resizeBase64Image } from '../../../../components/inputs_and_selections/photo_scroll';
import { Trash, Upload } from 'lucide-react';
export const SettingsImageInput = ({ b64Image, altText = 'Image upload result', handleUpdateImage: handleUpdateLogo, handleRemoveImage: handleRemoveLogo, placeholderText: inputText, helperText, buttonText, imgClassName }) => {
    const [fileSizeValid, setFileSizeValid] = useState(true);
    const fileInputRef = useRef(null);
    return (_jsxs("div", { className: 'flex flex-col gap-4', children: [(b64Image) && _jsxs("div", { className: 'flex flex-wrap gap-4 justify-between', children: [_jsx("img", { src: b64Image, className: ` ${imgClassName}`, alt: altText }), _jsxs("div", { className: "justify-start items-center gap-3 flex", children: [_jsx(Button, { colour: 'LIGHT', iconLeft: Upload, onClick: () => fileInputRef.current?.click(), children: "Change" }), _jsx(Button, { colour: 'LIGHT', iconLeft: Trash, onClick: handleRemoveLogo, children: "Remove" })] })] }), !b64Image && _jsx("div", { className: `h-56 px-6 py-12 bg-gray-50 rounded-lg ${!fileSizeValid && 'border border-red-500'}`, children: _jsxs("div", { className: 'flex flex-col gap-4 items-center justify-center', children: [_jsx("div", { className: 'font-bold', children: inputText }), _jsx("div", { className: 'text-gray-500', children: helperText }), _jsx(Button, { iconLeft: Upload, onClick: () => fileInputRef.current?.click(), children: buttonText })] }) }), _jsx("input", { ref: fileInputRef, id: "company-logo", className: 'hidden', type: "file", accept: 'image/*', capture: "environment", onChange: (e) => {
                    const newFile = e.target?.files?.[0];
                    if (!newFile)
                        return;
                    // Max file size is 5MB
                    if (newFile.size > 5 * 1024 * 1024) {
                        setFileSizeValid(false);
                        return;
                    }
                    setFileSizeValid(true);
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const resized = await resizeBase64Image(e.target?.result);
                        handleUpdateLogo(resized);
                    };
                    reader.readAsDataURL(newFile);
                } }), !fileSizeValid && _jsx("div", { className: 'text-red-700 text-sm', children: "File size must be less than 5MB" })] }));
};
