import { setError } from '../../components/indicators_and_messaging/toast';
import { client, url } from '../axios';
export const remindPassword = async (email) => {
    try {
        await client.post(`${url}remind_password`, { email });
    }
    catch (e) {
        setError(e);
    }
};
export const validatePasswordResetToken = async (token) => {
    try {
        await client.get(`${url}validate_password_reset_token?token=${token}`);
        return true;
    }
    catch (e) {
        setError(e);
        return false;
    }
};
export const updateUserPassword = async (token, password) => {
    try {
        await client.post(`${url}update_user_password`, { token, password });
    }
    catch (e) {
        setError(e);
    }
};
export const signUp = async (email, password, firstName, lastName, companyName, companyPhone, companyAddress, companyLatLng, logo, stripeCustomerId) => {
    try {
        return await client.post(`${url}sign_up`, {
            user: {
                email,
                password,
                first_name: firstName,
                last_name: lastName
            },
            company: {
                name: companyName,
                logo,
                phone: companyPhone,
                address: companyAddress,
                lat: companyLatLng.lat,
                lng: companyLatLng.lng,
                stripe_customer_id: stripeCustomerId
            }
        });
    }
    catch (e) {
        return e.response;
    }
};
