import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '../../../components/buttons/button';
import { centreAndScaleOnFloor, centreOnRoomScaleOnFloor } from './code/utils';
import { Alert } from '../../../components/indicators_and_messaging/alert';
import { UnsnappedWallAlert } from '../wall';
import { Crosshair, Redo, Undo } from 'lucide-react';
export const UndoRedoRecenterButtonsAndAlerts = ({ drawingRooms, undoIndex, undo, redo, undoEvents, currentRoom, floor, setStagePosition, setStageScale, stageSize, intersectingShapes, hasUnsnappedInternalWalls }) => {
    // Use absolute positioning for these buttons rather than a flex container so that the area between the Undo/Redo on the left and the Re-centre on the right is clickable
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "absolute top-4 left-2 flex gap-2", children: !drawingRooms && _jsxs(_Fragment, { children: [_jsx(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === 0, onClick: undo, children: _jsxs("div", { className: "flex justify-center gap-1", children: [_jsx(Undo, { className: "h-4 w-4" }), _jsx("div", { children: "Undo" })] }) }), _jsx(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === undoEvents.length, onClick: () => redo(undoEvents[undoIndex]), children: _jsxs("div", { className: "flex justify-center gap-1", children: [_jsx(Redo, { className: "h-4 w-4" }), _jsx("div", { children: "Redo" })] }) })] }) }), _jsx("div", { className: "absolute top-4 right-2", children: _jsx(Button, { size: 'SM', colour: 'LIGHT', onClick: () => {
                        if (currentRoom) {
                            centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                            return;
                        }
                        if (floor.rooms.length > 0) {
                            centreAndScaleOnFloor(floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                        }
                        else {
                            setStageScale(0.05);
                            setStagePosition({ x: stageSize.width / 2, y: stageSize.height / 2 });
                        }
                    }, children: _jsxs("div", { className: "flex justify-center gap-1", children: [_jsx(Crosshair, { className: "h-4 w-4" }), _jsx("div", { children: "Re-centre" })] }) }) }), _jsx("div", { className: "absolute top-16 left-0 right-0 w-full", children: _jsxs("div", { className: "space-y-2", children: [intersectingShapes.length > 0 && (_jsxs(Alert, { type: "DANGER", children: ["You have ", _jsx("span", { className: "font-bold", children: intersectingShapes.length / 2 }), " rooms overlapping, you cannot continue until this is resolved. Locate the overlapping rooms (highlighted in red) and drag them apart."] })), hasUnsnappedInternalWalls && UnsnappedWallAlert] }) })] }));
};
