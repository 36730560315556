import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Section } from '../../../../components/containers/section';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Map, Marker, useMapsLibrary } from '@vis.gl/react-google-maps';
import { SettingsLayout } from '../components/settings_layout';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { validateEmail, validatePhone } from '../../../../code/validators';
import { Info } from '../../../../components/buttons/info';
import { noop } from 'lodash';
export const CompanyDetails = ({ company, setCompany }) => {
    const [addressLocation, setAddressLocation] = useState();
    const [mapCenter, setMapCenter] = useState();
    const places = useMapsLibrary('places');
    const inputRef = useRef(null);
    useEffect(() => {
        setAddressLocation({ lat: company.lat ?? 0, lng: company.lng ?? 0 });
        setMapCenter({ lat: company.lat ?? 0, lng: company.lng ?? 0 });
    }, []);
    const onPlaceChanged = (place) => {
        // OnPlaceChanged fires when we click an autocompleted suggestion when typing an address in the Address input.
        // Update the company address, company lat and lng with the new details.
        // And update the map view with the pin on the new location.
        if (place) {
            setCompany({
                ...company,
                public_info: {
                    ...company.public_info,
                    address: place.formatted_address ?? place.name ?? ''
                },
                lat: place.geometry?.location?.lat() ?? 0,
                lng: place.geometry?.location?.lng() ?? 0
            });
            setAddressLocation(place.geometry?.location?.toJSON());
            setMapCenter(place.geometry?.location?.toJSON());
        }
    };
    useEffect(() => {
        if (!places || !inputRef.current)
            return;
        const autocomplete = new places.Autocomplete(inputRef.current, { fields: ['geometry', 'formatted_address'] });
        autocomplete.addListener('place_changed', () => {
            onPlaceChanged(autocomplete.getPlace());
        });
    }, [places]);
    return _jsx(SettingsLayout, { heading: 'Company details', description: 'Manage your company details', children: _jsx(Section, { border: true, title: "Company details", children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsxs("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4', children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Company name' }), input: _jsx(Input, { value: company.public_info.name, setValue: (e) => {
                                        setCompany({ ...company, public_info: { ...company.public_info, name: e } });
                                    } }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Company code' }), input: _jsx(Input, { disabled: true, value: company.code ?? '', setValue: noop }) })] }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Notification email', info: _jsx(Info, { infoModalHeader: 'Notification email', infoModalBody: 'This is the email address where you receive notification emails from Spruce when customers submit an enquiry, book a heat loss survey, or request a callback. It can be different from your login email.' }) }), input: _jsx(Input, { value: company.notification_email, setValue: (e) => {
                                if (validateEmail(e).value) {
                                    setCompany({ ...company, notification_email: e });
                                }
                            }, validator: validateEmail, validateImmediately: true }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Phone number' }), input: _jsx(Input, { value: company.phone, setValue: (e) => {
                                if (validatePhone(e).value) {
                                    setCompany({ ...company, phone: e });
                                }
                            }, validator: validatePhone, validateImmediately: true }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Company address' }), input: _jsxs(_Fragment, { children: [_jsx(Input, { ref: inputRef, value: company.public_info.address, setValue: async (e) => await setCompany({ ...company, public_info: { ...company.public_info, address: e } }) }), (addressLocation?.lat !== 0 && addressLocation?.lng !== 0 && mapCenter) &&
                                    _jsx(Map, { onClick: (e) => {
                                            const newLocation = e.detail.latLng;
                                            setAddressLocation(newLocation ?? undefined);
                                            setCompany({
                                                ...company,
                                                lat: e.detail.latLng?.lat ?? undefined,
                                                lng: e.detail.latLng?.lng ?? undefined
                                            });
                                        }, streetViewControl: false, className: "h-[200px] w-full rounded overflow-hidden", mapTypeId: "satellite", defaultCenter: mapCenter, defaultZoom: 18, children: _jsx(Marker, { position: addressLocation }) })] }) })] }) }) });
};
