import { jsx as _jsx } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from './button';
export const AnimatedButton = (props) => {
    const handleClick = async () => {
        props.onClick();
    };
    return (_jsx(AnimatePresence, { mode: "wait", initial: false, onExitComplete: () => {
            if (props.afterAnimation) {
                props.afterAnimation();
            }
        }, children: _jsx(motion.span, { initial: { opacity: 0, y: 10 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: -10 }, transition: { duration: 0.2 }, children: _jsx(Button, { ...(props.condition ? props.stateAProps : props.stateBProps), disabled: props.disabled, onClick: async () => {
                    await handleClick();
                }, children: props.condition ? props.childrenA : props.childrenB }) }, props.condition ? 'stateA' : 'stateB') }));
};
