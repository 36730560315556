import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SummaryTable } from '../../../components/content_display/summary_table';
export const SummaryTableDemo = () => {
    const rows = [
        { key: 'Key 1', value: 'Value 1', emoji: '1️⃣' },
        { key: 'Key 2', value: 'Value 2', emoji: '2️⃣' },
        { key: 'Key 3', value: 'Value 3', emoji: '3️⃣' },
        { key: 'Key 4', value: 'Value 4', emoji: '4️⃣' },
        { key: 'Total', value: 'Total Value', size: 'LG', emoji: '🔢' }
    ];
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(SummaryTable, { rows: rows })] })] });
};
const componentProps = [
    { name: 'rows', description: 'Array of table rows' }
];
const codeBlock = `
const rows: SummaryTableRow[] = [
  { key: 'Key 1', value: 'Value 1', emoji: '1️⃣' },
  { key: 'Key 2', value: 'Value 2', emoji: '2️⃣' },
  { key: 'Key 3', value: 'Value 3', emoji: '3️⃣' },
  { key: 'Key 4', value: 'Value 4', emoji: '4️⃣' },
  { key: 'Total', value: 'Total Value', size: 'LG', emoji: '🔢' }
]

<SummaryTable
  rows={rows}
/>
`;
