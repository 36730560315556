import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getRadiatorHeightWidthText } from '../../code/models/radiator';
import { Input } from '../../components/inputs_and_selections/input';
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll';
import { ClickableCard } from '../../components/content_display/card';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { SelectRadiatorModelInner } from './design/pages/radiator_model_selector';
import { getPipeModelName, PIPE_MODELS } from '../../code/models/pipes';
import { Select } from '../../components/inputs_and_selections/select';
export const RadiatorPage = ({ radiator, setRadiator, customRadiatorModels, setCustomRadiatorModels, files, setFiles, deltaTFlowReturnC, roomTemp, flowTemp, setPage, page, companyUuid, onBack }) => {
    const images = radiator.photos.map(x => files.find(y => y.uuid === x.image_uuid));
    const currentPipe = PIPE_MODELS.find(x => x.uuid === radiator.pipe_model_uuid);
    const [currentPipeMaterial, setCurrentPipeMaterial] = useState(currentPipe?.material ?? 'Copper');
    const deleteImage = (imageUUID) => {
        setRadiator(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }));
    };
    if (page === 'RADIATOR_MODEL' || page === 'ADD_CUSTOM_RADIATOR') {
        return _jsx(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, originallySelectedModelUuid: radiator.radiator_type?.uuid, deltaTFlowReturnC: deltaTFlowReturnC, roomTemp: roomTemp, flowTemp: flowTemp, companyUuid: companyUuid, addRadiator: (rm) => {
                onBack();
                setRadiator(prev => ({ ...prev, radiator_type_uuid: rm.uuid, radiator_type: rm, updated_at: new Date().getTime() }));
            }, setPage: setPage, page: page, onBack: onBack });
    }
    return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: "p-5 bg-white flex-col gap-5 flex overflow-y-auto flex-grow", children: [_jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Radiator type" }), _jsx(ClickableCard, { size: 'SM', variant: 'WHITE', onClick: () => setPage('RADIATOR_MODEL'), children: _jsxs("div", { className: 'flex justify-between text-sm items-center', children: [_jsx("div", { children: radiator.radiator_type.type }), _jsx(Badge, { color: 'INDIGO', text: getRadiatorHeightWidthText(radiator.radiator_type) })] }) })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Maximum possible size" }), _jsxs("div", { className: 'flex gap-2 text-sm text-gray-600', children: [_jsxs("div", { className: 'flex-1 flex flex-col gap-1', children: [_jsx("div", { children: "Height" }), _jsx(Input, { type: 'number', value: radiator.maximum_height.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, maximum_height: parseFloat(e) })), postfix: _jsx("span", { children: "mm" }) })] }), _jsxs("div", { className: 'flex-1 flex flex-col gap-1', children: [_jsx("div", { children: "Width" }), _jsx(Input, { type: 'number', value: radiator.maximum_width.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, maximum_width: parseFloat(e) })), postfix: _jsx("span", { children: "mm" }) })] })] })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Pipe material" }), _jsx(RadioGroup, { items: [
                                {
                                    name: 'Copper',
                                    onClick: () => setCurrentPipeMaterial('Copper'),
                                    variant: currentPipeMaterial === 'Copper' ? 'ACTIVE' : 'DEFAULT'
                                },
                                {
                                    name: 'PEX',
                                    onClick: () => setCurrentPipeMaterial('PEX'),
                                    variant: currentPipeMaterial === 'PEX' ? 'ACTIVE' : 'DEFAULT'
                                },
                                {
                                    name: 'MLCP',
                                    onClick: () => setCurrentPipeMaterial('MLCP'),
                                    variant: currentPipeMaterial === 'MLCP' ? 'ACTIVE' : 'DEFAULT'
                                }
                            ] })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Pipe diameter" }), _jsx(Select, { options: PIPE_MODELS.filter(x => x.material === currentPipeMaterial).map(x => ({
                                key: x.uuid,
                                value: getPipeModelName(x)
                            })), selectedKey: radiator.pipe_model_uuid, setSelectedKey: (e) => setRadiator(prev => ({ ...prev, pipe_model_uuid: e })) })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Photos" }), _jsx(PhotoScroll, { images: images, addImage: (fileWrapper) => {
                                fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                                fileWrapper && setRadiator(prev => ({
                                    ...prev,
                                    photos: [...prev.photos, {
                                            uuid: crypto.randomUUID(),
                                            entity_uuid: prev.uuid,
                                            image_uuid: fileWrapper.uuid
                                        }]
                                }));
                            }, deleteImage: deleteImage, companyUUID: companyUuid })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Notes" }), _jsx(TextArea, { value: radiator.notes.toString(), setValue: (e) => setRadiator(prev => ({ ...prev, notes: e })), size: 'SM' })] })] }) });
};
