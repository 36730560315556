import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { EstimatePageInner } from '../../../../estimate_page';
import { estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { Section } from '../../components/section';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { AdminContext } from '../../../admin_layout';
export const EstimatePreview = ({ estimate, lead, companyPublicInfo, company }) => {
    const adminContext = useContext(AdminContext);
    const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data, company);
    const notGrantEligible = (!lead.epc_scotland && (!lead.epcData?.lmkKey || estimate.busGrantEligibleReasons.some(x => !x.hasPassed)));
    return (_jsxs(Section, { title: "Estimate preview", children: [_jsx(Alert, { type: "INFO", children: "This is a preview of the estimate that the customer will receive by email." }), _jsx("div", { className: "border border-light rounded overflow-hidden", children: _jsx(EstimatePageInner, { estimate: calculatedEstimate, companyPublicInfo: company?.public_info ?? companyPublicInfo, isScottish: estimate.isScottish, grantEligible: !notGrantEligible, isPreview: true }) })] }));
};
