import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../components/buttons/button';
import { useContext } from 'react';
import { AdminContext } from './admin_layout';
import { Menu } from 'lucide-react';
export const OfflinePage = ({ backURL, customHeader, customMessage, navigateTo }) => {
    const adminContext = useContext(AdminContext);
    return _jsxs("div", { className: 'w-full', children: [_jsx("div", { className: 'px-4 py-4 flex gap-x-4 items-center', children: _jsx(Menu, { "data-cy": 'nav_open', className: "lg:hidden h-8 w-8 cursor-pointer", onClick: () => {
                        adminContext.showSidebar();
                    } }) }), _jsxs("div", { className: 'p-8 flex flex-col gap-4', children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-lg', children: customHeader ?? 'Unable to connect to the internet.' }), _jsx("div", { className: '', children: customMessage ?? 'This page is not accessible offline' })] }), backURL && _jsx(Button, { className: 'w-32', onClick: () => {
                            navigateTo(backURL);
                        }, children: "Back" })] })] });
};
