import * as _ from 'lodash';
import { getNumberOfOccupantsNoOverride, getDailyHotWaterVolumeL, getHotWaterCalculationRowNormalOperation } from './models/hot_water_cylinder';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from './models/u_value';
import { yearDiff } from './time_since';
import { getDesignConditions } from './models/design_temp';
import { checkIfScottish } from './models/address';
import { FUELS, getFuelByName } from './models/fuel';
import { makeDictFromScottishImprovements } from './helpers';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from './models/range_heat_pump';
import { convertLatLngListToLatLongLiteral } from './geocoding';
import { estimateStoreysFromPropertyType, PROPERTY_TYPES } from './models/property_type';
import { BUILT_FORM_TYPES } from './models/built_form';
import { z } from 'zod';
import { roundTo2dp } from './number_format';
import { AGILE_PENCE_PER_KWH, AGILE_PRICE_EXPLANATION, PRICE_CAP_DESCRIPTION, PRICE_CAP_ELECTRICITY_PENCE_PER_KWH } from './models/heating_fuel';
import { InventoryHeatPumpSchema, InventoryHotWaterCylinderSchema } from './models/inventory';
import { determineDefaultUValuesFromNewBuildRegs } from './models/building_regs';
const GrantEligibleReasonSchema = z.object({
    id: z.string(),
    hasPassed: z.boolean(),
    warning: z.boolean(),
    message: z.string()
});
const DefaultsSchema = z.object({
    externalWallDefault: z.number(),
    partyWallDefault: z.number(),
    windowDefault: z.number(),
    floorDefault: z.number(),
    roofDefault: z.number(),
    airChangesDefault: z.number(),
    internalTempDefault: z.number(),
    designTempDefault: z.number()
});
export const EstimateSchema = z.object({
    hotWaterCylinder: InventoryHotWaterCylinderSchema.optional(),
    heatPump: InventoryHeatPumpSchema.optional(),
    designTempC: z.number(),
    internalTempC: z.number(),
    externalWallUValue: z.number(),
    partyWallUValue: z.number(),
    windowsUValue: z.number(),
    floorUValue: z.number(),
    roofUValue: z.number(),
    partyWallWatts: z.number(),
    externalWallWatts: z.number(),
    windowWatts: z.number(),
    floorWatts: z.number(),
    roofWatts: z.number(),
    ventilationWatts: z.number(),
    totalWatts: z.number(),
    sCOP: z.number(),
    flowTempC: z.number(),
    CO2SavedKg: z.number(),
    flightsSaved: z.number(),
    commutesSaved: z.number(),
    airChanges: z.number(),
    annualKwhHeat: z.number(),
    efficiencyBaseline: z.number(),
    fuelNameBaseline: z.string(),
    tariffPPerKwhBaseline: z.number(),
    annualBillBaselineGBP: z.number(),
    averageEfficiencyHeatPump: z.number(),
    tariffPPerKwhElectricityHpTariff: z.number(),
    electricHpTariffBasedOn: z.string(),
    annualBillHeatPumpTariffGBP: z.number(),
    tariffPPerKwhElectricityPriceCap: z.number(),
    priceCapDescription: z.string(),
    annualBillHeatPumpPriceCapGBP: z.number(),
    busGrantEligibleReasons: z.array(GrantEligibleReasonSchema),
    hesGrantEligibleReasons: z.array(GrantEligibleReasonSchema),
    defaults: DefaultsSchema,
    isScottish: z.boolean()
});
// Useful to export default values of constants we use in our estimates.
export const DEFAULT_FLOW_TEMP_C = 45;
export const DEFAULT_INTERNAL_TEMP_C = 20;
export const DEFAULT_AIR_CHANGES = 1;
export const DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE = 0;
export const roofUValueFromInsulationThickness = (loftInsulationThickness) => {
    if (!loftInsulationThickness)
        return 0;
    // for back compatability if value is 300mm use value for 250+mm
    const thicknessUUID = loftInsulationThickness === '300mm' ? '250+mm' : loftInsulationThickness;
    const type = LOFT_INSULATION.find(x => x.uuid === thicknessUUID);
    if (!type)
        return 0;
    return type.u_value;
};
export const windowUValueFromType = (windowType) => {
    const type = WINDOW_TYPES.find(x => x.uuid === windowType);
    if (!type)
        return 0;
    return type.u_value;
};
export const floorUValueFromType = (floorType) => {
    const type = FLOOR_INSULATION.find(x => x.uuid === floorType);
    if (!type)
        return 0;
    return type.u_value;
};
export const calculateEstimate = (lead, heatPumps, hotWaterCylinders, company, selectedHeatPumpUUID, selectedHotWaterCylinderUUID) => {
    const { designTempC, internalTempC, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, partyWallWatts, externalWallWatts, windowWatts, floorWatts, roofWatts, ventilationWatts, totalWatts, airChanges, htcWPerK, defaults, degreeDays } = calculatePeakHeatLossW(lead, company);
    const flowTempC = lead.flow_temperature_c ?? company?.default_flow_temp_c ?? DEFAULT_FLOW_TEMP_C;
    const numberOfBedrooms = getNumberOfBedrooms(lead);
    const heatPump = selectHeatPump(totalWatts, heatPumps, selectedHeatPumpUUID, lead.heat_pump_uuid, company, designTempC, flowTempC);
    const hotWaterCylinder = selectCylinder(numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, lead.hot_water_cylinder_uuid);
    // If no range heat pump then default to 3.6, which is the sCOP for
    // the Vaillant Arotherm Plus 7kW, 50C flow. https://energy-stats.uk/how-to-measure-vaillant-arotherm-cop/
    // If we have a range heat pump but return a sCOP of 0, which happens if we don't have data the specified flow temp, stick with 0
    // Will mean we end up with negative infinity carbon savings, but is "correct" in the sense the user has specified a flow temp the heat pump can't do
    const sCOPSpaceHeat = heatPump?.range_heat_pump ? getHeatPumpScopAtFlowTemp(heatPump?.range_heat_pump, flowTempC) : 3.6;
    // heat demand
    const yearlykWhSpaceHeat = htcWPerK * degreeDays / (1000 / 24);
    const numberOfOccupants = getNumberOfOccupantsNoOverride(numberOfBedrooms);
    const dailyWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, 45);
    const hotWaterRow = getHotWaterCalculationRowNormalOperation(heatPump?.range_heat_pump, dailyWaterVolumeL, 50);
    const yearlykWhHeat = yearlykWhSpaceHeat + hotWaterRow.heatEnergyKwhPerCycle * hotWaterRow.cyclesPerYear;
    // Baseline
    // Current fuel type -> House override, fallback to property value, fallback to mains gas.
    // Have split this out into multiple lines for clarity, and because JS wasn't evaluating it as we expected.
    const fuelType = lead.property.houseOverrides?.fuelType || lead.property.fuelType;
    const isValidFuelType = FUELS.some(fuel => fuel.name === fuelType);
    const currentFuelType = isValidFuelType ? fuelType : 'Mains Gas';
    const currentFuel = getFuelByName(currentFuelType);
    const yearlykWhCurrentFuel = yearlykWhHeat / currentFuel.defaultHeatingSystemEfficiency; // use same efficiency for heating and hot water here
    const yearlyCO2CurrentKg = calculateYearlyCO2kg(currentFuel, yearlykWhCurrentFuel);
    const annualBillBaselineGBP = calculateYearlyCostGBPExcludingStandingCharge(currentFuel.pPerkWh, yearlykWhCurrentFuel);
    // Heat pump
    const electricity = FUELS.find(x => x.name === 'Electric');
    const yearlykWhElectricityHP = yearlykWhSpaceHeat / sCOPSpaceHeat + hotWaterRow.electricityKWhPerYear;
    const averageEfficiencyHeatPump = yearlykWhHeat / yearlykWhElectricityHP; // to account for lower hot water efficiency
    const yearlyCO2HeatPumpKg = calculateYearlyCO2kg(electricity, yearlykWhElectricityHP);
    const tariffPPerKwhElectricityHpTariff = AGILE_PENCE_PER_KWH;
    const annualBillHeatPumpTariffGBP = calculateYearlyCostGBPExcludingStandingCharge(tariffPPerKwhElectricityHpTariff, yearlykWhElectricityHP);
    const tariffPPerKwhElectricityPriceCap = PRICE_CAP_ELECTRICITY_PENCE_PER_KWH;
    const annualBillHeatPumpPriceCapGBP = calculateYearlyCostGBPExcludingStandingCharge(tariffPPerKwhElectricityPriceCap, yearlykWhElectricityHP);
    const CO2SavedKg = Math.round(yearlyCO2CurrentKg - yearlyCO2HeatPumpKg);
    const flightsSaved = getFlightsSaved(CO2SavedKg);
    const commutesSaved = getCommutesSaved(CO2SavedKg);
    const isScottish = checkIfScottish(lead.property.postcode);
    const inspectionDate = lead.epcData?.inspectionDate ? new Date(lead.epcData?.inspectionDate) : undefined;
    // BUS grant for English properties
    const busGrantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, lead.epc_recommendations);
    // HES grant for Scottish properties
    const hesGrantEligibleReasons = checkIfEligibleForHesGrant(lead.epc_scotland);
    return {
        hotWaterCylinder,
        heatPump,
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        totalWatts,
        sCOP: sCOPSpaceHeat,
        flowTempC,
        CO2SavedKg,
        flightsSaved,
        commutesSaved,
        airChanges,
        annualKwhHeat: yearlykWhHeat,
        efficiencyBaseline: currentFuel.defaultHeatingSystemEfficiency,
        fuelNameBaseline: currentFuel.name,
        tariffPPerKwhBaseline: currentFuel.pPerkWh,
        annualBillBaselineGBP,
        averageEfficiencyHeatPump,
        tariffPPerKwhElectricityHpTariff,
        electricHpTariffBasedOn: AGILE_PRICE_EXPLANATION,
        annualBillHeatPumpTariffGBP,
        tariffPPerKwhElectricityPriceCap,
        annualBillHeatPumpPriceCapGBP,
        priceCapDescription: PRICE_CAP_DESCRIPTION,
        busGrantEligibleReasons,
        hesGrantEligibleReasons,
        defaults,
        isScottish
    };
};
export const getNumberOfBedrooms = (lead) => {
    return lead.property.houseOverrides?.noBedrooms ?? lead.property.noBedrooms;
};
export const getNumberOfBathrooms = (lead) => {
    return lead.property.houseOverrides?.noBathrooms ?? lead.property.noBathrooms;
};
export const selectHeatPump = (totalWatts, heatPumps, selectedHeatPumpUUID, heatPumpOverrideUUID, company, designTempC = -3, flowTempC = DEFAULT_FLOW_TEMP_C) => {
    // Filter soft deleted heat pumps and calculate kW using getHeatPumpCapacityAtOutsideTempAndFlowTemp, or default to 0 if no range heat pump
    const heatPumpsWithkW = heatPumps
        .filter(hp => !hp.deleted_at)
        .map(x => ({
        ...x,
        kwatts: x.range_heat_pump ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw : 0
    }));
    // Order heat pumps by ascending capacity and filter to those that meet the required power
    const orderedHeatPumps = _.orderBy(heatPumpsWithkW, x => (x.kwatts) * 1000);
    const filteredHeatPumps = orderedHeatPumps.filter(x => (x.kwatts) * 1000 >= totalWatts);
    // Select heat pump based on preferred brand or the first one that meets the requirement otherwise
    const defaultBrandHeatPumpUUID = filteredHeatPumps.find(x => x.range_heat_pump?.brand_range_uuid === company?.default_brand_range_uuid)?.uuid;
    const calculatedHeatPumpUUID = defaultBrandHeatPumpUUID ?? filteredHeatPumps[0]?.uuid;
    // Return the selected heat pump
    return heatPumpsWithkW.find(x => x.uuid === (selectedHeatPumpUUID ?? heatPumpOverrideUUID ?? calculatedHeatPumpUUID));
};
export const selectCylinder = (numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, hotWaterCylinderOverrideUUID) => {
    const litresRequired = (numberOfBedrooms + 1) * 45;
    // Filter soft deleted cylinders to prevent us selecting one (calcultedHotWaterCylinderUUID) that is no longer on the installers inventory.
    const nonSoftDeletedCylinders = hotWaterCylinders.filter(cyl => !cyl.deleted_at);
    // choose cheapest cylinder that is big enough. In edge cases may mean you select a too big cylinder because it's cheaper than a smaller one, but I don't think many people have enough cylinders set up for this to happen
    const calculatedHotWaterCylinderUUID = (_.orderBy(nonSoftDeletedCylinders, x => parseInt(x.cost_price.toString())).filter(x => parseInt(x.litres.toString()) >= litresRequired)[0])?.uuid;
    const hotWaterCylinder = hotWaterCylinders?.find(x => x.uuid === (selectedHotWaterCylinderUUID ?? hotWaterCylinderOverrideUUID ?? calculatedHotWaterCylinderUUID));
    return hotWaterCylinder;
};
export const calculatePeakHeatLossW = (lead, company) => {
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const designConditionDefaults = getDesignConditions(latLng, lead.property.altitudeM, false);
    const designTempC = Number(lead.property?.houseOverrides?.designTempOverride ?? designConditionDefaults.designTempDefaultC); // FIXME - Find root cause of why this is a string sometimes in the database.
    const airChangesDefault = company?.estimate_default_ach ?? DEFAULT_AIR_CHANGES; // Air changes per hour
    const internalTempDefault = company?.estimate_default_internal_temp_c ?? DEFAULT_INTERNAL_TEMP_C;
    const heatCapacityOfAir = 0.33; // Physics number used as multiplier for how much heat the air will take away with it.
    const internalTempC = lead.property?.houseOverrides?.internalTempOverride ?? internalTempDefault;
    const tempDiff = internalTempC - designTempC;
    const tempDiffPartyWall = internalTempC - 10; // From MCS calculator. Conservative given other side of wall is likely heated. Will account for some thermal bridging though
    const roomHeight = lead.property.houseOverrides?.roomHeight ?? lead.property.roomHeight;
    const floorArea = lead.property.houseOverrides?.floorArea ?? lead.property.floorArea;
    const propertyType = lead.property.houseOverrides?.propertyType ?? lead.property.propertyType;
    const storeys = lead.property.houseOverrides?.noStoreys ?? lead.property.noStoreys ?? estimateStoreysFromPropertyType(propertyType);
    const builtForm = lead.property.houseOverrides?.builtForm ?? lead.property.builtForm;
    const wallGroup = lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup;
    const wallType = lead.property.houseOverrides?.wallType ?? lead.property.wallType;
    const floorType = lead.property.houseOverrides?.floorType ?? lead.property.floorType;
    const loftInsulation = lead.property.houseOverrides?.loftInsulation ?? lead.property.loftInsulation;
    const windowType = lead.property.houseOverrides?.windowType ?? lead.property.windowType;
    // Find how conductive each surface is of the house, pulled from EPC and form data.
    // A UValue is how many watts, per degree, per m2 the material will lose.
    // Defaults here account for fact that new builds will have u values defined from the beginning
    // For new builds currently seem to have a lot of 0 values for the U values when there was no EPC first. Fix this before changing to this defaulting to undefined
    let externalWallDefault, partyWallDefault, windowDefault, floorDefault, roofDefault;
    if (lead.projectType === 'new-build') {
        if (!lead.property.buildingRegs)
            throw new Error('No building regs found for new build project');
        const newBuildDefaultUValues = determineDefaultUValuesFromNewBuildRegs(lead.property.houseOverrides?.buildingRegs ?? lead.property.buildingRegs);
        externalWallDefault = newBuildDefaultUValues.wallUValue;
        partyWallDefault = newBuildDefaultUValues.partyWallUValue;
        windowDefault = newBuildDefaultUValues.windowUValue;
        floorDefault = newBuildDefaultUValues.floorUValue;
        roofDefault = newBuildDefaultUValues.roofUValue;
    }
    else {
        externalWallDefault = lead.property.wallUValue > 0
            ? lead.property.wallUValue
            : calculatedExternalWallUValue(wallType, wallGroup);
        partyWallDefault = lead.property.wallUValue > 0
            ? Math.min(lead.property.wallUValue, 0.5) // if U value defined for new build walls in EPC data, use the better of that and 0.5
            : 0.5; // hard coded as 0.5 in MCS. That's also the worst case option in RdSAP Table 15
        windowDefault = lead.property.windowUValue > 0
            ? lead.property.windowUValue
            : windowUValueFromType(windowType);
        floorDefault = lead.property.floorUValue > 0
            ? lead.property.floorUValue
            : floorUValueFromType(floorType);
        roofDefault = lead.property.roofUValue > 0
            ? lead.property.roofUValue
            : roofUValueFromInsulationThickness(loftInsulation);
    }
    const externalWallUValue = (lead.property)?.houseOverrides?.externalWallUValueOverride ?? externalWallDefault;
    const partyWallUValue = (lead.property)?.houseOverrides?.partyWallUValueOverride ?? partyWallDefault;
    const windowsUValue = (lead.property)?.houseOverrides?.windowsUValueOverride ?? windowDefault;
    const floorUValue = (lead.property)?.houseOverrides?.floorUValueOverride ?? floorDefault;
    const roofUValue = (lead.property)?.houseOverrides?.roofUValueOverride ?? roofDefault;
    const airChanges = lead.property?.houseOverrides?.airChangeOverride ?? airChangesDefault;
    const defaults = {
        externalWallDefault,
        partyWallDefault,
        windowDefault,
        floorDefault,
        roofDefault,
        airChangesDefault,
        internalTempDefault,
        designTempDefault: designConditionDefaults.designTempDefaultC
    };
    const areas = calculateBuildingElementAreas(roomHeight, builtForm, propertyType, storeys, floorArea);
    // Multiply area by temperature difference required.
    // Multiply result by UValue of surface to calculate watts lost.
    const partyWallWatts = Math.round(areas.partyWallAreaM2 * tempDiffPartyWall * partyWallUValue);
    const externalWallWatts = Math.round(areas.externalWallAreaM2 * tempDiff * externalWallUValue);
    const windowWatts = Math.round(areas.windowAndDoorAreaM2 * tempDiff * windowsUValue);
    const floorWatts = Math.round(areas.heatLossFloorAreaM2 * tempDiff * floorUValue);
    const roofWatts = Math.round(areas.roofAreaM2 * tempDiff * roofUValue);
    const ventilationWatts = Math.round(areas.volumeM3 * airChanges * heatCapacityOfAir * tempDiff);
    const totalWatts = partyWallWatts + externalWallWatts + windowWatts + floorWatts + roofWatts + ventilationWatts;
    const htcWPerK = totalWatts / tempDiff;
    return {
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        totalWatts,
        airChanges,
        htcWPerK,
        defaults,
        degreeDays: designConditionDefaults.degreeDays
    };
};
export const calculatedExternalWallUValue = (wallType, wallGroupUUID) => {
    const wallGroup = WALL_GROUPS.find(x => x.name === wallGroupUUID);
    if (!wallGroup)
        return 0;
    if (wallGroup.name === CAVITY_WALL)
        return CAVITY_WALL_INSULATION.find(x => x.uuid === wallType)?.u_value ?? 0;
    if (wallGroup.name === TIMBER)
        return wallGroup.u_value;
    const insulationUValue = SOLID_WALL_INSULATION.find(x => x.uuid === wallType)?.u_value;
    if (!insulationUValue)
        return wallGroup.u_value; // If we have no value for historic records, just return the material u_value
    return roundTo2dp(1 / ((1 / wallGroup.u_value) + (1 / insulationUValue)));
};
export const calculateBuildingElementAreas = (roomHeightM, builtForm, propertyType, storeys, totalFloorAreaM2) => {
    const builtFormObject = BUILT_FORM_TYPES.find(x => x.uuid === builtForm);
    const numberOfPartyWalls = builtFormObject?.numberOfPartyWalls ?? 1;
    const propertyTypeObject = PROPERTY_TYPES.find(x => x.uuid === propertyType);
    const numberOfStories = storeys ?? propertyTypeObject?.numberOfStoriesDefault;
    const averageAreaPerStoreyM2 = totalFloorAreaM2 / numberOfStories;
    const wallLengthM = Math.sqrt(averageAreaPerStoreyM2); // TODO later: don't assume square (differentiate between width and depth)
    const windowAndDoorAreaM2 = wallLengthM * 4 * roomHeightM * numberOfStories * 0.1; // TODO later calculate window area based on average percentage of wall area from RdSAP 2012 and doors somehow sensible
    const externalWallAreaM2 = wallLengthM * (4 - numberOfPartyWalls) * roomHeightM * numberOfStories - windowAndDoorAreaM2;
    const partyWallAreaM2 = wallLengthM * numberOfPartyWalls * roomHeightM * numberOfStories;
    const heatLossFloorAreaM2 = averageAreaPerStoreyM2;
    const roofAreaM2 = averageAreaPerStoreyM2;
    const volumeM3 = totalFloorAreaM2 * roomHeightM;
    return {
        externalWallAreaM2,
        partyWallAreaM2,
        windowAndDoorAreaM2,
        heatLossFloorAreaM2,
        roofAreaM2,
        volumeM3
    };
};
export const calculateYearlyCO2kg = (fuel, yearlykWh) => {
    return yearlykWh * fuel.gCO2PerkWh / 1000;
};
export const calculateYearlyCostGBPExcludingStandingCharge = (tariffPPerKwh, yearlykWh) => {
    // Don't include standing charge savings for now - user may not get rid of gas
    return (yearlykWh * tariffPPerKwh) / 100;
};
export const getFlightsSaved = (CO2SavedKg) => {
    const flightsToSpain = 192;
    return Math.floor(CO2SavedKg / flightsToSpain);
};
export const getCommutesSaved = (CO2SavedKg) => {
    const dailyCommute = 3.6;
    return Math.floor(CO2SavedKg / dailyCommute);
};
export const checkIfEligibleForHesGrant = (epcScotland) => {
    if (!epcScotland)
        return [{ id: 'no_valid', warning: false, hasPassed: false, message: 'Has a valid EPC' }];
    // recommendations for Scottish properties are in the epc_scotland object .improvements field
    const scottishImprovements = makeDictFromScottishImprovements(epcScotland?.improvements ?? '');
    return [
        {
            id: 'cavity_wall',
            warning: !scottishImprovements.description?.includes('cavity wall insulation'),
            hasPassed: !scottishImprovements.description?.includes('cavity wall insulation'),
            message: !scottishImprovements.description?.includes('cavity wall insulation') ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
        },
        {
            id: 'loft_insulation',
            warning: !scottishImprovements.description?.includes('increase loft insulation to 270'),
            hasPassed: !scottishImprovements.description?.includes('increase loft insulation to 270'),
            message: !scottishImprovements.description?.includes('increase loft insulation to 270') ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
        }
    ];
};
export const checkIfEligibleForBusGrant = (inspectionDate, recommendations) => {
    // Docs: https://files.bregroup.com/sap/RdSAP_2012_9.94-20-09-2019.pdf page 52 as a reference for improvement-id
    if (!inspectionDate)
        return [{ id: 'no_valid', warning: false, hasPassed: false, message: 'No valid EPC found' }];
    else {
        return [
            {
                id: 'epc_date',
                hasPassed: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99,
                warning: false,
                message: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99 ? 'EPC is under 10 years old' : 'EPC is more than 10 years old'
            },
            {
                id: 'cavity_wall',
                hasPassed: true,
                warning: recommendations.some(x => x['improvement-id-text']?.toLowerCase().includes('cavity') || x['improvement-id'] === '6'),
                message: !recommendations.some(x => x['improvement-id-text']?.toLowerCase().includes('cavity') || x['improvement-id'] === '6') ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
            },
            {
                id: 'loft_insulation',
                hasPassed: true,
                warning: recommendations.some(x => x['improvement-id-text']?.toLowerCase().includes('loft') || x['improvement-id'] === '5'),
                message: !recommendations.some(x => x['improvement-id-text']?.toLowerCase().includes('loft') || x['improvement-id'] === '5') ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
            }
        ];
    }
};
