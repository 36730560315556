import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EmptyState } from '../../../components/content_display/empty_state';
import { Button } from '../../../components/buttons/button';
import { Square } from 'lucide-react';
export const EmptyStateDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(EmptyState, { icon: Square, primaryText: 'Hello world', secondaryText: 'My secondary text', primaryButton: _jsx(Button, { iconLeft: Square, children: "Primary" }), secondaryButton: _jsx(Button, { iconLeft: Square, children: "Secondary" }) })] })] });
};
const componentProps = [
    { name: 'icon', description: 'Main icon of empty state' },
    { name: 'primaryText', description: 'Bold primary text' },
    { name: 'secondaryText', description: 'Secondary text' },
    { name: 'primaryButton', description: 'The left side button, accepts a whole button as props' },
    { name: 'secondaryButton', description: 'The right side button, accepts a whole button as props' }
];
const codeBlock = `<EmptyState
  icon={Square}
  primaryText='Hello world'
  secondaryText='My secondary text'
  primaryButton={<Button iconLeft={Square}>Primary</Button>}
  secondaryButton={<Button iconLeft={Square}>Secondary</Button>}
/>`;
