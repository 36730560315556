import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { estimateFloorAreaM2 } from '../../../code/models/estimated_floor_area';
import { Alert } from '../../../components/indicators_and_messaging/alert';
export const FloorAreaStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    // save user input in state, because when we do calculations we want to use the original value
    // and not the converted one
    const [userInput, setUserInput] = useState(survey.lead.property.floorArea ||
        estimateFloorAreaM2(survey.lead.property.propertyType, survey.lead.property.builtForm, undefined, // FIXME - We dont map the correct ageBandId to what estimateFloorAreaM2 expects. Have left this out for now.
        survey.lead.property.noBedrooms).floorAreaM2);
    const [userInputUnit, setUserInputUnit] = useState('sqm');
    const handleValueChange = (value) => {
        const valueAsNumber = parseInt(value);
        setUserInput(valueAsNumber);
    };
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                floorArea: Number((userInputUnit === 'sqm' ? userInput : userInput / 10.764).toFixed(2))
            }
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), 
                // show different text depending on whether we have an EPC or not
                headerText: survey.newBuildNextStep ? 'Proposed floor area'
                    : survey.lead.epcData !== undefined
                        ? `Confirm ${adminEstimate ? 'the' : 'your'} property’s floor area`
                        : `${adminEstimate ? 'The' : 'Your'} property’s floor area`, 
                // show different text depending on whether we have an EPC or not
                captionText: survey.newBuildNextStep ? 'This is only used for the heat loss estimate, so an approximation is ok.'
                    : survey.lead.epcData !== undefined
                        ? `We have pre-filled this based on ${adminEstimate ? 'the' : 'your'} EPC. Let us know if it has changed or you have a more accurate figure.`
                        : `This is the floor area of all heatable parts of ${adminEstimate ? 'the' : 'your'} home` }), !survey.lead.property.floorArea &&
                _jsx(Alert, { type: 'INFO', children: "We have prefilled this value with a rough estimate, based off of historical averages. Please update with an accurate figure where possible." }), _jsxs("div", { className: "flex-col gap-3 flex", children: [_jsx(Input, { size: 'LG', setValue: handleValueChange, type: "number", value: userInput.toString(), placeholder: 'E.g. 169', postfix: userInputUnit === 'sqm' ? 'm²' : 'sqft' }), _jsx(SurveyOptionGroup, { noCheckmark: true, inline: true, selectedOptionKey: userInputUnit, options: [
                            { label: 'Square meters', key: 'sqm' },
                            { label: 'Square feet', key: 'sqft' }
                        ], onSelect: (value) => {
                            setUserInputUnit(value);
                            setUserInput(value === 'sqm' ? Number((userInput / 10.764).toFixed(2)) : Number((userInput * 10.764).toFixed(2)));
                        } })] }), _jsx(Button, { size: 'LG', disabled: userInput <= 0, onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
