import { jsx as _jsx } from "react/jsx-runtime";
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { SettingsImageInput } from '../../components/settings_image_input';
export const BrandLogoBlock = ({ company, setCompany }) => {
    const handleRemoveLogo = () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: ''
            }
        };
        setCompany(newCompany);
        updateCompanySettings(newCompany);
    };
    const handleUpdateLogo = async (imageSrc) => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: imageSrc
            }
        };
        await updateCompanySettings(newCompany);
        setCompany(newCompany);
    };
    return _jsx(Section, { border: true, title: "Company logo", children: _jsx(SettingsImageInput, { buttonText: 'Upload logo', placeholderText: 'No logo', helperText: 'Maximum file size 5MB', b64Image: company.public_info.logo ?? '', altText: 'Company logo', handleUpdateImage: handleUpdateLogo, handleRemoveImage: handleRemoveLogo, imgClassName: 'h-24' }) });
};
