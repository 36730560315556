import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { updateDefaultMaterial } from '../../../code/models/material';
import { MaterialsSelector } from './materials_selector';
import { PageHeader } from '../design/components/design_page_header';
import { MaterialInputField } from './material_input_field';
import { CompleteButton } from '../survey/complete_button';
import { CustomMaterialPopup } from './custom_material_popup';
export const DefaultMaterialsTab = ({ materials, setMaterials, setSurvey, survey, materialsLayers, companyUUID, isOffline, allSynced }) => {
    const selectedMaterialsSet = survey.default_materials;
    // materials selector props
    const [selectorProps, setSelectorProps] = useState();
    const [innerPages, setInnerPages] = useState(['MATERIAL_HOME']);
    const [tempCustomMaterial, setTempCustomMaterial] = useState();
    const [customMaterialHeader, setCustomMaterialHeader] = useState();
    const innerPage = innerPages[innerPages.length - 1];
    const setInnerPage = (page) => setInnerPages(prev => [...prev, page]);
    const page = 'MATERIALS';
    const handleMaterialUpdate = (material) => {
        return updateDefaultMaterial(survey, setSurvey, material);
    };
    if (!selectedMaterialsSet || !materials)
        return null;
    if (innerPage === 'MATERIALS') {
        return _jsxs("div", { className: 'flex flex-col h-full min-h-0', children: [_jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Choose a material', onBack: () => {
                        setInnerPages(prev => prev.slice(0, -1));
                    } }), _jsx("div", { className: 'overflow-y-auto', children: _jsx(MaterialsSelector, { groupedProps: selectorProps, setGroupedProps: setSelectorProps, setMaterialsCallback: setMaterials, setPage: setInnerPage, onBack: () => setInnerPages(prev => prev.slice(0, -1)), setTempCustomMaterial: setTempCustomMaterial, companyUUID: companyUUID }) })] });
    }
    if (innerPage === 'CUSTOM_MATERIAL') {
        return _jsxs("div", { className: 'flex flex-col h-full', children: [_jsx("div", { className: 'px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white', children: customMaterialHeader }), _jsx("div", { className: 'overflow-y-auto', children: _jsx(CustomMaterialPopup, { material: tempCustomMaterial, materialsLayers: materialsLayers, setMaterial: setTempCustomMaterial, onSave: (newMaterial) => {
                            // NB! do not override materials with the `materials` variable!
                            // because it contains filtered values for the current surface and will override the global materials list
                            setMaterials(prev => ([...prev, newMaterial]));
                            setSelectorProps(prev => ({ ...prev, materials: [...prev.materials, newMaterial], selectedMaterial: newMaterial }));
                            if (selectorProps.onSelectCallback) {
                                // trigger global callback
                                selectorProps.onSelectCallback(newMaterial);
                            }
                        }, setVisible: () => setInnerPages(prev => prev.slice(0, -1)), setHeader: setCustomMaterialHeader, onBack: () => setInnerPages(prev => prev.slice(0, -1)) }) })] });
    }
    return _jsxs("div", { className: 'flex flex-col h-full', children: [_jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Materials', onBack: () => window.history.back(), completed: survey.completed_sections.includes(page) }), _jsxs("div", { className: 'p-5 w-full bg-white flex-col justify-start items-start gap-6 inline-flex overflow-y-auto flex-grow', children: [_jsxs("div", { className: "flex-col justify-start items-start gap-3 flex", children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Default materials" }), _jsx("div", { className: "", children: "Set default materials for the property. You can override these in each room." })] }), _jsxs("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex", children: [_jsx("div", { className: "self-stretch text-gray-900 text-xl font-bold", children: "Walls" }), _jsxs("div", { className: "w-full flex-col justify-start items-start gap-2 flex", children: [_jsx("div", { className: "self-stretch font-bold text-gray-900", children: "External wall" }), _jsx(MaterialInputField, { selectorProps: {
                                            title: 'External wall',
                                            surfaceType: 'external-wall',
                                            materials,
                                            ageBand: survey.age_band,
                                            selectedMaterial: selectedMaterialsSet.externalWall,
                                            onSelectCallback: handleMaterialUpdate
                                        }, setMsProps: setSelectorProps, setPage: setInnerPage })] }), _jsxs("div", { className: "w-full flex-col justify-start items-start gap-2 flex", children: [_jsx("div", { className: "self-stretch font-bold text-gray-900", children: "Party wall" }), _jsx(MaterialInputField, { selectorProps: {
                                            title: 'Party wall',
                                            surfaceType: 'party-wall',
                                            materials,
                                            ageBand: survey.age_band,
                                            selectedMaterial: selectedMaterialsSet.partyWall,
                                            onSelectCallback: handleMaterialUpdate
                                        }, setMsProps: setSelectorProps, setPage: setInnerPage })] }), _jsxs("div", { className: "w-full flex-col justify-start items-start gap-2 flex", children: [_jsx("div", { className: "self-stretch font-bold text-gray-900", children: "Internal wall" }), _jsx(MaterialInputField, { selectorProps: {
                                            title: 'Internal wall',
                                            surfaceType: 'internal-wall',
                                            materials,
                                            ageBand: survey.age_band,
                                            selectedMaterial: selectedMaterialsSet.internalWall,
                                            onSelectCallback: handleMaterialUpdate
                                        }, setMsProps: setSelectorProps, setPage: setInnerPage })] })] }), _jsx("div", { className: "self-stretch h-px border border-gray-200" }), _jsxs("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex", children: [_jsx("div", { className: "self-stretch text-gray-900 text-xl font-bold", children: "Floors" }), _jsxs("div", { className: "w-full flex-col justify-start items-start gap-2 flex", children: [_jsx("div", { className: "self-stretch font-bold text-gray-900", children: "Ground floor" }), _jsx(MaterialInputField, { selectorProps: {
                                            title: 'Ground floor',
                                            surfaceType: 'ground-floor',
                                            materials,
                                            ageBand: survey.age_band,
                                            selectedMaterial: selectedMaterialsSet.groundFloor,
                                            onSelectCallback: handleMaterialUpdate
                                        }, setMsProps: setSelectorProps, setPage: setInnerPage })] }), _jsxs("div", { className: "w-full flex-col justify-start items-start gap-2 flex", children: [_jsx("div", { className: "self-stretch font-bold text-gray-900", children: "Intermediate floors/ceilings" }), _jsx(MaterialInputField, { selectorProps: {
                                            title: 'Intermediate floors/ceilings',
                                            surfaceType: 'intermediate-floor-and-ceiling',
                                            materials,
                                            ageBand: survey.age_band,
                                            selectedMaterial: selectedMaterialsSet.intermediateFloorAndCeiling,
                                            onSelectCallback: handleMaterialUpdate
                                        }, setMsProps: setSelectorProps, setPage: setInnerPage })] })] }), _jsx("div", { className: "self-stretch h-px border border-gray-200" }), _jsxs("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex", children: [_jsx("div", { className: "self-stretch text-gray-900 text-xl font-bold", children: "Roof" }), _jsx(MaterialInputField, { selectorProps: {
                                    title: 'Roof',
                                    surfaceType: 'roof',
                                    materials,
                                    ageBand: survey.age_band,
                                    selectedMaterial: selectedMaterialsSet.roof,
                                    onSelectCallback: handleMaterialUpdate
                                }, setMsProps: setSelectorProps, setPage: setInnerPage })] }), _jsx("div", { className: "self-stretch h-px border border-gray-200" }), _jsxs("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex", children: [_jsx("div", { className: "self-stretch text-gray-900 text-xl font-bold", children: "Doors" }), _jsx(MaterialInputField, { selectorProps: {
                                    title: 'Door',
                                    surfaceType: 'door',
                                    materials,
                                    ageBand: survey.age_band,
                                    selectedMaterial: selectedMaterialsSet.door,
                                    onSelectCallback: handleMaterialUpdate
                                }, setMsProps: setSelectorProps, setPage: setInnerPage })] }), _jsx("div", { className: "self-stretch h-px border border-gray-200" }), _jsxs("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex", children: [_jsx("div", { className: "self-stretch text-gray-900 text-xl font-bold", children: "Windows" }), _jsx(MaterialInputField, { selectorProps: {
                                    title: 'Window',
                                    surfaceType: 'window',
                                    materials,
                                    ageBand: survey.age_band,
                                    selectedMaterial: selectedMaterialsSet.window,
                                    onSelectCallback: handleMaterialUpdate
                                }, setMsProps: setSelectorProps, setPage: setInnerPage })] })] }), _jsx(CompleteButton, { page: page, pageChecks: [], survey: survey, setSurvey: setSurvey, onBack: async () => window.history.back() })] });
};
