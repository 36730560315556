import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EnquiryOverview } from '../sections/enquiry_overview';
import { HeatLossEstimate } from '../sections/heat_loss_estimate';
import { CostEstimate } from '../sections/cost_estimate';
import { OfflinePage } from '../../../offline_page';
export const EnquiryManagerDashboard = ({ navigateTo, lead, company, setLead, estimate, isOffline, setEstimates, stages }) => {
    if (isOffline)
        return _jsx(OfflinePage, { navigateTo: navigateTo });
    return (_jsxs("div", { className: "space-y-6", children: [_jsx(EnquiryOverview, { company: company, lead: lead, estimate: estimate, navigateTo: navigateTo, setEstimates: setEstimates, setLead: setLead, stages: stages }), _jsx(HeatLossEstimate, { lead: lead, setLead: setLead, estimate: estimate }), _jsx(CostEstimate, { company: company, estimate: estimate, lead: lead, navigateTo: navigateTo })] }));
};
