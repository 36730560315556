import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { cloneElement, useRef, useState } from 'react';
import { arrow, autoUpdate, flip, FloatingArrow, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useTransitionStyles } from '@floating-ui/react';
export const Tooltip = ({ children, content, placement }) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        placement: placement || 'top',
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip({ fallbackAxisSideDirection: 'start' }), shift(), arrow({
                element: arrowRef
            })],
        whileElementsMounted: autoUpdate
    });
    const { isMounted, styles } = useTransitionStyles(context);
    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss
    ]);
    const childrenWithRef = React.isValidElement(children) && typeof children.type === 'string'
        ? cloneElement(children, {
            ref: refs.setReference,
            ...getReferenceProps()
        })
        : _jsx("span", { ref: refs.setReference, ...getReferenceProps(), children: children });
    return (_jsxs(_Fragment, { children: [childrenWithRef, isOpen && isMounted && (_jsxs("div", { className: 'bg-gray-800 text-white text-xs p-2 rounded transition-opacity duration-200 ease-in-out w-[max-content] max-w-[360px]', ref: refs.setFloating, style: { ...floatingStyles, ...styles }, ...getFloatingProps(), children: [_jsx(FloatingArrow, { ref: arrowRef, context: context, className: "fill-gray-800" }), content] }))] }));
};
