import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check, Minus } from 'lucide-react';
import { Icon } from '../buttons/icon';
const styles = {
    OFF: 'bg-white border border-gray-400 text-white',
    ON: 'bg-gray-900 border border-gray-900 text-white',
    INDETERMINATE: 'bg-gray-900 border border-gray-900 text-white'
};
export const Checkbox = ({ dataCy, checked, setChecked }) => {
    return (_jsxs("div", { "data-cy": dataCy ?? 'checkbox', className: `cursor-pointer w-5 h-5 rounded ${styles[checked]} flex justify-center items-center`, onClick: setChecked, children: [checked === 'ON' && _jsx(Icon, { dataCy: 'checkbox-on', icon: Check, size: 'w-4 h-4', colour: 'text-white' }), checked === 'INDETERMINATE' && _jsx(Icon, { dataCy: 'checkbox-indeterminate', icon: Minus, size: 'w-4 h-4', colour: 'text-white' })] }));
};
