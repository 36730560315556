import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HLRContext } from './heatloss_report_page';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { numberFormat } from '../../../code/number_format';
import _ from 'lodash';
import { HLRListBlock } from './partials/hlr_list';
export const HLREmittersIntro = () => {
    const hlrContext = React.useContext(HLRContext);
    const designRadiators = hlrContext.design.radiators;
    const emitterReplacementRooms = _.uniq(hlrContext.survey.floors.flatMap(f => f.rooms.flatMap(r => {
        const designRadiatorsForRoom = designRadiators.filter(x => x.room_uuid === r.uuid);
        if (designRadiatorsForRoom.length === 0)
            return [];
        return r.name;
    })));
    return _jsxs("div", { className: 'flex-col gap-6 flex', children: [_jsx(HLRSubheaderBlock, { section: "System design", title: "Emitters" }), _jsx("div", { children: "Emitters (i.e., radiators/fan coils/underfloor heating) are a vital part of your heating system. They take the heat produced by the heat pump and distribute that heat into your rooms." }), _jsxs("div", { children: ["Based on the room by room heat loss results, we propose the following emitter design for your home. This design will ensure each room can be heated to its set point when it's ", numberFormat(1).format(hlrContext.designTempC), " \u00B0C outside whilst maintaining high system efficiency."] }), _jsxs("div", { children: [_jsx("div", { className: "text-gray-900 text-lg font-bold", children: "Design conditions" }), _jsx(HLRListBlock, { items: [
                            _jsxs(_Fragment, { children: ["Flow temperature = ", hlrContext.design.flow_temp, " \u00B0C"] }),
                            _jsxs(_Fragment, { children: ["Temperature drop across the radiator (delta T) = ", hlrContext.design.delta_t_flow_return_c, " \u00B0C"] }),
                            _jsxs(_Fragment, { children: ["Mean radiator temperature = ", hlrContext.design.flow_temp - (hlrContext.design.delta_t_flow_return_c / 2), " \u00B0C"] })
                        ] })] }), emitterReplacementRooms.length > 0 && _jsxs("div", { children: [_jsx("div", { className: "text-gray-900 text-lg font-bold", children: "Emitter replacements" }), _jsx("div", { children: "We propose replacing or adding emitters in the following rooms:" }), _jsx(HLRListBlock, { items: emitterReplacementRooms.map(x => _jsx(_Fragment, { children: x })), isNumbered: true })] })] });
};
