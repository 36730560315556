import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { ChevronRightIcon } from 'lucide-react';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { estimateFloorAreaM2 } from '../../../code/models/estimated_floor_area';
import { ClickableCard } from '../../../components/content_display/card';
import { Text } from '../../../components/content_display/text';
import { WrappedIcon } from '../../../components/buttons/wrapped_icon';
export const NewBuildNextStepsStep = () => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const handleNavigateNextPage = (newBuildNextStep) => {
        const estimatedFloorArea = estimateFloorAreaM2(survey.lead.property.propertyType, survey.lead.property.builtForm, undefined, survey.lead.property.noBedrooms).floorAreaM2;
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                newBuildNextSteps: newBuildNextStep,
                floorArea: newBuildNextStep === 'heatLossSurvey' ? estimatedFloorArea : undefined
            }
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "Next steps" }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ClickableCard, { variant: 'WHITE', className: 'w-full hover:bg-gray-50 border-gray-300 text-default', onClick: () => handleNavigateNextPage('estimate'), children: _jsxs("div", { className: 'grow flex justify-between items-center', children: [_jsxs("div", { className: 'flex flex-col items-left', children: [_jsx(Text, { size: 'LG', bold: true, children: "Generate an estimate" }), _jsx(Text, { size: 'MD', className: 'font-normal', children: "Provide a rough floor area in the following step" })] }), _jsx(WrappedIcon, { icon: ChevronRightIcon })] }) }), _jsx(ClickableCard, { variant: 'WHITE', className: 'w-full hover:bg-gray-50 border-gray-300', onClick: () => handleNavigateNextPage('heatLossSurvey'), children: _jsxs("div", { className: 'grow flex justify-between items-center', children: [_jsxs("div", { className: 'flex flex-col items-left', children: [_jsx(Text, { size: 'LG', bold: true, children: "Start a heat loss survey" }), _jsx(Text, { size: 'MD', className: 'font-normal', children: "Skip the estimate. You can always generate one later" })] }), _jsx(WrappedIcon, { icon: ChevronRightIcon })] }) })] })] });
};
