import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Button } from '../../../components/buttons/button';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext } from '../survey_page';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const OptionsStep = ({ headerText, captionText, noNavigateBack, options, defaultOption, onNavigateNextPage }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    return _jsxs(_Fragment, { children: [noNavigateBack ? null : _jsx(QuestionnaireNavigateBack, {}), _jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: headerText, captionText: captionText }), _jsx(SurveyOptionGroup, { options: options, onSelect: (key) => { setSelectedOption(key); }, selectedOptionKey: selectedOption }), _jsx(Button, { size: 'LG', disabled: !selectedOption, onClick: () => onNavigateNextPage(selectedOption), children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsx("div", { children: "Continue" }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] });
};
