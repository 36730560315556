import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getRadiatorHeightWidthText } from '../../../../code/models/radiator';
import { getRadiatorWatts, RADIATOR_MODELS } from '../../../../code/models/radiator_model';
import { orderBy, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { TabGroup } from '../../../../components/content_display/tab';
import { Button } from '../../../../components/buttons/button';
import { ClickableCard } from '../../../../components/content_display/card';
import { AddCustomRadiatorPage } from './add_custom_radiator_page';
import { DEFAULT_RADIATOR_MODEL } from '../../../../code/survey_defaults';
import { ListItem } from '../../../../components/content_display/list_item';
import { Icon } from '../../../../components/buttons/icon';
import { Trash } from 'lucide-react';
export const SelectRadiatorModelInner = ({ customRadiatorModels, setCustomRadiatorModels, defaultType, defaultHeightMm, originallySelectedModelUuid, maxHeightMm, maxWidthMm, flowTemp, deltaTFlowReturnC, roomTemp, requiredOutput, addRadiator, designContextContent, companyUuid, page, setPage, onBack }) => {
    // Set up custom radiator state
    const [tempCustomRadiatorModel, setTempCustomRadiatorModel] = useState({
        ...DEFAULT_RADIATOR_MODEL,
        company_uuid: companyUuid
    });
    // selectedCustomRadiatorModel is used to select item in list once added and set filters.
    // Distinct form tempCustomRadiatorModel because has a uuid and gets cleared on apply
    // tempCustomRadiatorModel is not cleared on apply so that defaults to same values if you add more rads in same session
    const [selectedCustomRadiatorModel, setSelectedCustomRadiatorModel] = useState(undefined);
    // For this id set is_deleted to true so it won't show up in the list of available options anymore
    const deleteCustomRadiatorModel = (id) => setCustomRadiatorModels(prev => (prev.map(x => x.uuid === id ? {
        ...x,
        deleted_at: new Date().getTime()
    } : x)));
    // Selected model might not be the one defined by selectedModelUuid because if we add a custom model we select that instead
    // Check for selectedCustomRadiatorModel first because if you make a new custom radiator when you came from editing an existing selectedModel then you want to keep the custom one
    const combinedModels = [...RADIATOR_MODELS, ...customRadiatorModels];
    const selectedModel = selectedCustomRadiatorModel || (originallySelectedModelUuid ? combinedModels.find(x => x.uuid === originallySelectedModelUuid) : undefined);
    // Choose the starting tab
    const [tab, setTab] = useState(selectedModel?.company_uuid ? 'CUSTOM' : 'STANDARD');
    // Set up list of models to show
    // Put towel rails at the end
    const standardModelsSorted = [...orderBy(RADIATOR_MODELS.filter(x => !x.type.includes('Towel'))), ...orderBy(RADIATOR_MODELS.filter(x => x.type.includes('Towel')))];
    // Don't show deleted models
    const radiatorModelsToUse = (tab === 'STANDARD' ? standardModelsSorted : customRadiatorModels).filter(x => x.is_deleted !== true);
    // Set up filters
    const availableTypes = uniq(radiatorModelsToUse.map(x => x.type));
    const availableHeights = uniq(radiatorModelsToUse.map(x => x.height_mm));
    const getFilterType = (selectedModel, availableTypes, defaultType) => {
        // If there is a selected model, use its type as the starting type provided it is in the list of available types on this tab
        if (selectedModel && availableTypes.includes(selectedModel.type)) {
            return selectedModel.type;
        }
        if (defaultType && availableTypes.includes(defaultType)) {
            return defaultType;
        }
        return availableTypes[0] ?? 'Type 21 (P+)';
    };
    const getFilterHeight = (selectedModel, defaultHeightMm) => {
        if (selectedModel && availableHeights.includes(selectedModel.height_mm)) {
            return selectedModel.height_mm;
        }
        if (defaultHeightMm && availableHeights.includes(defaultHeightMm)) {
            return defaultHeightMm;
        }
        return undefined;
    };
    const [filterType, setFilterType] = useState(getFilterType(selectedModel, availableTypes, defaultType ?? 'Type 21 (P+)'));
    const [filterHeight, setFilterHeight] = useState(getFilterHeight(selectedModel, defaultHeightMm));
    // If selected model changes or list of available models changes, update the filter
    useEffect(() => {
        setFilterType(getFilterType(selectedModel, availableTypes, defaultType));
        setFilterHeight(getFilterHeight(selectedModel, defaultHeightMm));
    }, [selectedModel, tab]);
    // Filter available heights based on selected type.
    // Not like materials where you can have nothing selected at the top level - you have to have a radiator type selected here
    const heights = orderBy(uniq(radiatorModelsToUse.filter(x => x.type === filterType).map(x => x.height_mm)), x => x);
    // Get list of filtered models to show
    const filteredSortedModels = radiatorModelsToUse.filter(x => (x.type === filterType || !filterType) && (x.height_mm === filterHeight || !filterHeight)).sort((a, b) => a.width_mm - b.width_mm).sort((a, b) => a.height_mm - b.height_mm);
    const currentModelWatts = selectedModel ? getRadiatorWatts(selectedModel, flowTemp, deltaTFlowReturnC, roomTemp) : undefined;
    if (page === 'ADD_CUSTOM_RADIATOR') {
        return _jsx(AddCustomRadiatorPage, { tempCustomRadiatorModel: tempCustomRadiatorModel, setTempCustomRadiatorModel: setTempCustomRadiatorModel, setSelectedCustomRadiatorModel: setSelectedCustomRadiatorModel, setCustomRadiatorModels: setCustomRadiatorModels, onBack: onBack });
    }
    return _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col flex-grow min-h-40 overflow-y-auto', children: [designContextContent && _jsx("div", { className: "flex-col gap-3 flex p-5 border-b border-gray-200", children: designContextContent }), _jsxs("div", { className: "flex-col gap-3 flex p-5", children: [_jsx(TabGroup, { items: [
                                { name: 'Standard', onClick: () => setTab('STANDARD'), variant: tab === 'STANDARD' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Custom', onClick: () => setTab('CUSTOM'), variant: tab === 'CUSTOM' ? 'ACTIVE' : 'DEFAULT' }
                            ] }), radiatorModelsToUse.length > 0 && _jsxs("div", { className: 'flex flex-col gap-3', children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-500 text-xs font-semibold uppercase tracking-wide', children: "Type" }), _jsx(RadioGroup, { items: availableTypes.map(x => ({
                                                name: x,
                                                onClick: () => {
                                                    setFilterType(x);
                                                    if (!checkIfHaveModelsWithThisTypeHeightCombo(radiatorModelsToUse, x, filterHeight ?? 0)) {
                                                        setFilterHeight(undefined);
                                                    }
                                                },
                                                variant: filterType === x ? 'ACTIVE' : 'DEFAULT'
                                            })) })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-500 text-xs font-semibold uppercase tracking-wide', children: "Height" }), _jsx(RadioGroup, { items: heights.map(x => ({
                                                name: x.toString(),
                                                onClick: () => filterHeight === x ? setFilterHeight(undefined) : setFilterHeight(Number(x)),
                                                variant: filterHeight === x ? 'ACTIVE' : 'DEFAULT'
                                            })) })] })] })] }), _jsxs("div", { className: 'flex flex-col flex-grow divide-y divide-gray-200 gap-3', children: [selectedModel && _jsxs(_Fragment, { children: [_jsx("div", { className: "px-5 pt-3 pb-2 bg-gray-50 border-t border-gray-200 justify-left items-center flex", children: _jsx("div", { className: "text-xs font-semibold uppercase", children: "Selected" }) }), _jsx(ListItem // make selected section clickable as confuses people how to actually select the radiator otherwise
                                , { onClick: () => {
                                        addRadiator(selectedModel);
                                        setSelectedCustomRadiatorModel(undefined);
                                    }, primaryText: `${selectedModel.type} - ${getRadiatorHeightWidthText(selectedModel)}`, secondaryText: `${selectedModel.output_capacity_w_at_50_c} W at ∆T = 50°C`, rightBadge: _jsx(Badge, { color: requiredOutput && currentModelWatts && (requiredOutput > 0) ? (currentModelWatts >= requiredOutput ? 'GREEN' : 'RED') : 'LIGHT', text: `${currentModelWatts} W at ${flowTemp} °C` }) }, selectedModel.uuid)] }), _jsx("div", { className: "px-5 pt-3 pb-2 bg-gray-50 border-t border-gray-200 justify-center items-center flex", children: _jsx("div", { className: "grow shrink basis-0 text-xs font-semibold uppercase tracking-wide", children: "All emitters (height x width)" }) }), radiatorModelsToUse.length > 0 && _jsx(_Fragment, { children: filteredSortedModels.map(x => {
                                const radiatorWatts = getRadiatorWatts(x, flowTemp, deltaTFlowReturnC, roomTemp);
                                return _jsx(ListItem, { onClick: () => {
                                        addRadiator(x);
                                        setSelectedCustomRadiatorModel(undefined);
                                    }, primaryText: getRadiatorHeightWidthText(x), primaryTextColour: maxHeightMm && maxWidthMm && maxHeightMm > 0 && maxWidthMm > 0
                                        ? (x.height_mm > maxHeightMm || x.width_mm > maxWidthMm ? 'LIGHT' : undefined)
                                        : undefined, secondaryText: `${x.output_capacity_w_at_50_c} W at ∆T = 50°C`, rightBadge: _jsx(Badge, { color: requiredOutput && (requiredOutput > 0) ? (radiatorWatts >= requiredOutput ? 'GREEN' : 'RED') : 'LIGHT', text: `${radiatorWatts} W at ${flowTemp} °C` }), actionButton: tab === 'CUSTOM' ? _jsx(Button, { onClick: () => {
                                            setPage('ADD_CUSTOM_RADIATOR');
                                            setTempCustomRadiatorModel({ ...x }); // modify a copy of this model
                                        }, colour: 'LIGHT', size: 'SM', children: "Edit" })
                                        : undefined, rightClickableIcon: tab === 'CUSTOM' ? _jsx(Icon, { confirmTextHeader: 'Delete custom radiator?', onClick: () => { deleteCustomRadiatorModel(x.uuid); }, disabled: x.uuid === selectedModel?.uuid, icon: Trash })
                                        : undefined }, x.uuid);
                            }) }), _jsxs("div", { className: 'p-4 flex-col', children: [radiatorModelsToUse.length === 0 && _jsx(_Fragment, { children: _jsx(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => {
                                            setPage('ADD_CUSTOM_RADIATOR');
                                            setTempCustomRadiatorModel({ ...tempCustomRadiatorModel, uuid: undefined }); // clear uuid to prevent overwriting existing model
                                        }, children: _jsx("div", { className: 'text-center', children: "No custom radiators found" }) }) }), tab === 'CUSTOM' &&
                                    _jsx(Button, { block: true, colour: "LIGHT", size: 'MD', onClick: () => {
                                            setPage('ADD_CUSTOM_RADIATOR');
                                            setTempCustomRadiatorModel({ ...tempCustomRadiatorModel, uuid: undefined }); // clear uuid to prevent overwriting existing model
                                        }, children: "Add custom radiator" })] })] })] }) });
};
const checkIfHaveModelsWithThisTypeHeightCombo = (radiatorModelsToUse, type, height) => {
    // If no rads available, clear height filter so you don't end up with an empty list
    // Alternatively you could clear the type filter, but our list cards are only showing the dimensions,
    // so having the type filter undefined  means the user doesn't know what they are selecting
    // Or could height type filters that don't apply to selected height, but then you need an "All" option like for the materials
    // And that brings you back to the problem of having the type undefined
    return radiatorModelsToUse.some(x => x.type === type && x.height_mm === height);
};
