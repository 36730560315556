import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAllCompaniesList, getCompany } from '../../code/models/company';
import { AuthContext, AuthProvider, AuthSDK } from '../../code/utils/auth_provider';
import { noop } from 'lodash';
import { getHeatPumps } from '../../code/models/heat_pump';
import { getHotWaterCylinders } from '../../code/models/hot_water_cylinder';
import { getUser, hasPermission } from '../../code/models/user';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { getGenericMaterials, getMaterialsGenericLayers } from '../../code/models/material';
import { TermsAndConditionsModal } from './components/terms_and_conditions_modal';
import { getTermsAndConditions, userHasAcceptedLatestTerms } from '../../code/models/terms_and_conditions';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { Link } from '../../components/buttons/link';
import { appStoreBadge, googleBadge } from '../../assets/images/store_badges/store_badges';
import { LeadsListPage } from './leads_list_page';
import { Error404Page } from '../error_pages';
import { InstallerAdminPage } from './job_layout/job_layout';
import { match } from 'path-to-regexp';
import { SettingsPage } from './settings_page/settings_page';
import { SurveyPageWrapper } from '../survey/survey_page';
import { getPacks } from '../../code/models/packs';
import { getLabour } from '../../code/models/labour';
import { getParts } from '../../code/models/parts';
import { CostsAndInventoryPage } from './costs_and_inventory/costs_page';
import { CircleHelp, House, LogOut, PanelLeftClose, PlusCircle, ReceiptPoundSterling, Settings } from 'lucide-react';
import { Select } from '../../components/inputs_and_selections/select';
import { WrappedIcon } from '../../components/buttons/wrapped_icon';
import { MenuSection } from './components/menu_section';
import { MenuItem } from './components/menu_item';
import { openInNewTab } from '../../code/helpers';
import { getPermissions, getCompanyRoles, getCompanyRolePermissions } from '../../code/models/company_roles';
import { useInterval } from 'usehooks-ts';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../heat_loss/db';
import { DEFAULT_TABLES, syncTick } from './sync';
import { getStages, getStatuses } from '../../code/stage';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { LoaderOverlay } from '../../components/indicators_and_messaging/loader_overlay';
import { setUser } from '@sentry/react';
import { getJobUsersForCompany, getUsersForCompany } from '../../code/models/users_companies';
const RequireAuth = ({ currentUrl, children, navigateTo }) => {
    const auth = useContext(AuthContext);
    // TODO: These won't get triggered any more, so we can get rid of this logic
    const allowList = [
        '/admin',
        '/admin/login',
        '/admin/remind-password',
        '/admin/new-password'
    ];
    if (auth.isSignedIn() && allowList.includes(currentUrl)) {
        navigateTo('/admin/enquiries');
        return;
    }
    if (!auth.isSignedIn() && !allowList.includes(currentUrl)) {
        navigateTo(`/login?redirect=${encodeURIComponent(currentUrl)}`);
        return;
    }
    return children;
};
const Wrapper = ({ companyPublicInfo, setCurrentCompanyUUID, currentPath, navigateTo, stages, isSyncing, setIsSyncing, setHasSynced, permissions, company }) => {
    const auth = useContext(AuthContext);
    const adminContext = useContext(AdminContext);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const navRef = useRef(null);
    document.addEventListener('mousedown', (e) => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            adminContext.hideSidebar();
        }
    });
    useEffect(() => {
        // We need to do a raw regex check here, react router useMatch does not work.
        const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/;
        const isSidebarVisible = !surveyPage.test(window.location.pathname);
        adminContext.setIsSidebarVisible(isSidebarVisible);
    }, [location]);
    const NEW_ENQUIRY = '/enquiries/new';
    const JOB_LIST = '/enquiries{/:filter}';
    const JOB_DETAIL = '/quotes/:leadUUID{/:tab}{/:secondaryTab}{/*any}';
    const SETTINGS_URL = '/settings{/:tab}{/:secondaryTab}';
    const COSTS_URL = '/costs{/:tab}{/:secondaryTab}';
    const ROUTES = [{
            path: NEW_ENQUIRY,
            component: () => {
                return _jsx(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: true, isAdmin: true });
            }
        }, {
            path: JOB_LIST,
            component: ({ filter }) => {
                return _jsx(LeadsListPage, { company: company, permissions: permissions, stages: stages, filter: filter, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo, basePath: '/admin' }, 'leads_list');
            }
        }, {
            path: JOB_DETAIL,
            component: ({ leadUUID, tab, secondaryTab }) => {
                const basePathQuote = `/admin/quotes/${leadUUID}`;
                return _jsx(InstallerAdminPage, { stages: stages, basePath: basePathQuote, globalCompany: company, companyPublicInfo: companyPublicInfo, leadUUID: leadUUID, tab: tab, secondaryTab: secondaryTab, currentPath: currentPath, navigateTo: navigateTo, isSyncing: isSyncing, setIsSyncing: setIsSyncing, setHasSynced: setHasSynced, permissions: permissions });
            }
        }, {
            path: SETTINGS_URL,
            component: ({ tab, secondaryTab }) => {
                return _jsx(SettingsPage, { company: company, permissions: permissions, navigateTo: navigateTo, tab: tab, secondaryTab: secondaryTab });
            }
        }, {
            path: COSTS_URL,
            component: ({ tab, secondaryTab }) => _jsx(CostsAndInventoryPage, { company: company, permissions: permissions, companyPublicInfo: companyPublicInfo, tab: tab, secondaryTab: secondaryTab, navigateTo: navigateTo })
        }];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace('/admin', '')), path: x.path, component: x.component })).find(x => x.evaluatedPath);
    if (!page?.evaluatedPath)
        return _jsx(Error404Page, {});
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex h-full", children: [auth.isSignedIn() && _jsx(_Fragment, { children: _jsx("div", { ref: navRef, className: `z-10 h-full bg-zinc-100 border-r w-64 px-4 py-6 shadow-lg md:shadow-none ${adminContext.isSidebarVisible ? 'md:flex md:static' : ''} absolute
            ${adminContext.isSidebarOpen && adminContext.isSidebarVisible ? '' : 'hidden'}
          `, "data-cy": "sidebar", children: _jsxs("div", { className: 'flex flex-col gap-10 justify-between h-full overflow-y-auto no-scrollbar w-full', children: [_jsxs("div", { className: 'flex flex-col gap-10', children: [_jsxs("div", { className: 'flex justify-between items-center', children: [_jsx("img", { src: companyPublicInfo?.logo || SpruceLogoBlack, className: 'w-32' }), _jsx("div", { className: 'cursor-pointer md:hidden', onClick: () => adminContext.hideSidebar(), children: _jsx(WrappedIcon, { icon: PanelLeftClose }) })] }), adminContext.data.allCompaniesList && adminContext.data.allCompaniesList?.length > 1 && companyPublicInfo &&
                                                _jsx(Select, { filter: true, options: adminContext.data.allCompaniesList.map(x => ({
                                                        key: x.uuid,
                                                        value: x.name
                                                    })), selectedKey: companyPublicInfo.uuid, setSelectedKey: (uuid) => {
                                                        setCurrentCompanyUUID(uuid);
                                                        window.location.reload();
                                                    } }), (companyPublicInfo && adminContext.data.user?.user_companies && adminContext.data.user?.user_companies.length > 1) &&
                                                _jsx(MenuSection, { name: 'Switch company', children: _jsx(Select, { filter: true, options: adminContext.data.user.user_companies.map(x => ({
                                                            key: x.company_uuid,
                                                            value: x.company.name
                                                        })), selectedKey: companyPublicInfo.uuid, setSelectedKey: (uuid) => {
                                                            setCurrentCompanyUUID(uuid);
                                                            window.location.reload();
                                                        }, dataCy: "company-switcher" }) }), _jsxs(MenuSection, { name: 'Jobs', children: [_jsx(MenuItem, { name: 'Jobs', icon: House, isSelected: [JOB_LIST, JOB_DETAIL].includes(page.path), onClick: () => {
                                                            navigateTo('/admin/enquiries');
                                                            adminContext.hideSidebar();
                                                        } }), adminContext.userHasCompanyPermission('jobs.create') &&
                                                        _jsx(MenuItem, { name: 'New job', icon: PlusCircle, isSelected: page.path === NEW_ENQUIRY, onClick: () => {
                                                                navigateTo('/admin/enquiries/new');
                                                                adminContext.hideSidebar();
                                                            } })] }), _jsxs(MenuSection, { name: 'Configuration', children: [adminContext.userHasCompanyPermission('inventories.edit') &&
                                                        _jsx(MenuItem, { name: 'Costs & inventory', icon: ReceiptPoundSterling, isSelected: page.path === COSTS_URL, onClick: () => {
                                                                navigateTo('/admin/costs');
                                                                adminContext.hideSidebar();
                                                            } }), _jsx(MenuItem, { name: 'Settings', icon: Settings, isSelected: page.path === SETTINGS_URL, onClick: () => {
                                                            navigateTo('/admin/settings');
                                                            adminContext.hideSidebar();
                                                        } })] }), _jsxs(MenuSection, { name: 'User', children: [_jsx(MenuItem, { name: 'Visit help centre', icon: CircleHelp, isSelected: false, onClick: () => openInNewTab('https://spruce-energy.notion.site/Spruce-Guide-de6f73384ba94ffbba3fe76adb096072') }), _jsx(MenuItem, { name: 'Logout', icon: LogOut, isSelected: false, onClick: () => {
                                                            const request = indexedDB.deleteDatabase('spruce');
                                                            request.onsuccess = () => {
                                                                localStorage.removeItem('leads');
                                                                localStorage.removeItem('current_company_uuid');
                                                                auth.signOut(() => navigateTo('/login'));
                                                            };
                                                        } })] })] }), _jsxs("div", { className: "flex flex-col items-center gap-4", children: [_jsx("a", { href: "https://apps.apple.com/gb/app/spruce-energy/id6530588740", target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { alt: "Download on the App Store", className: "w-32 h-auto", src: appStoreBadge }) }), _jsx("a", { href: "https://play.google.com/store/apps/details?id=eco.spruce.app", target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { alt: "Get it on Google Play", className: "w-32 h-auto", src: googleBadge }) })] })] }) }) }), _jsxs("div", { className: "overflow-auto print:overflow-visible w-full h-full flex flex-col", children: [(!adminContext.isLoading && adminContext.data.termsAndConditions && !userHasAcceptedLatestTerms(adminContext.data.user, adminContext.data.termsAndConditions)) && _jsxs(Alert, { type: 'WARNING', children: ["We\u2019ve updated our Terms & Conditions. Please could you review and accept them ", _jsx(Link, { text: 'here', className: 'inline', onClick: () => setTermsModalVisible(true) }), "."] }), page.component(page.evaluatedPath.params)] })] }), _jsx(TermsAndConditionsModal, { visible: termsModalVisible, setVisible: setTermsModalVisible, termsAndConditions: adminContext.data.termsAndConditions })] }));
};
const AdminContextDataDefaultValue = {
    companyUsers: [],
    companyJobUsers: [],
    allCompaniesList: [],
    heatPumps: [],
    hotWaterCylinders: [],
    labour: [],
    parts: [],
    packs: [],
    user: undefined
};
// Used to store global state for the admin UI
// also has triggers to update the high-level state for some objects like sidebar
export const AdminContext = React.createContext({
    showSidebar: noop,
    hideSidebar: noop,
    isSidebarOpen: false,
    isLoading: true,
    isOffline: false,
    data: AdminContextDataDefaultValue,
    // seters for the data
    setUser: noop,
    setCompanyUsers: noop,
    setCompanyJobUsers: noop,
    setHeatPumps: noop,
    setHotWaterCylinders: noop,
    setLabour: noop,
    setParts: noop,
    setPacks: noop,
    acceptTermsAndConditions: noop,
    setIsOffline: noop,
    isSidebarVisible: true,
    setIsSidebarVisible: noop,
    userHasCompanyPermission: () => false
});
export const AdminLayout = ({ companyPublicInfo, setCurrentCompanyUUID, currentPath, navigateTo }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [adminData, setAdminData] = useState(AdminContextDataDefaultValue);
    // the only reason this is here is to force reloading data when the user signs in
    const isLoggedIn = useRef(AuthSDK.isSignedIn());
    // flags to track loading data
    const [loadedDataFlags, setLoadedDataFlags] = useState(0);
    const FLAG_HEAT_PUMPS_LOADED = 0x0002;
    const FLAG_HWCS_LOADED = 0x0004;
    const FLAG_ALL_COMPANIES_LOADED = 0x0008;
    const FLAG_USER_LOADED = 0x0010;
    const FLAG_GENERIC_MATERIALS_LOADED = 0x0020;
    const FLAG_MATERIALS_GENERIC_LAYERS_LOADED = 0x0040;
    const FLAG_LABOUR_LOADED = 0x0100;
    const FLAG_PARTS_LOADED = 0x0200;
    const FLAG_PACKS_LOADED = 0x0400;
    const FLAG_COMPANY_USERS_LOADED = 0x1000;
    const FLAG_COMPANY_JOB_USERS_LOADED = 0x2000;
    const FLAG_ALL_LOADED = FLAG_HEAT_PUMPS_LOADED |
        FLAG_HWCS_LOADED |
        FLAG_ALL_COMPANIES_LOADED |
        FLAG_USER_LOADED |
        FLAG_GENERIC_MATERIALS_LOADED |
        FLAG_MATERIALS_GENERIC_LAYERS_LOADED |
        FLAG_LABOUR_LOADED |
        FLAG_PARTS_LOADED |
        FLAG_PACKS_LOADED |
        FLAG_COMPANY_USERS_LOADED |
        FLAG_COMPANY_JOB_USERS_LOADED;
    const [isOffline, setIsOffline] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    window.addEventListener('offline', () => {
        setIsOffline(true);
    });
    window.addEventListener('online', () => {
        setIsOffline(false);
    });
    const [, , , handleDataHeatPumpsReload] = useIndexedDBFallback('admin_data', 'heat_pumps', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            setLoadedDataFlags(prev => prev | FLAG_HEAT_PUMPS_LOADED);
            return undefined;
        }
        return await getHeatPumps(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HEAT_PUMPS_LOADED);
        setAdminData(prev => ({ ...prev, heatPumps: data }));
    });
    const [, , , handleDataHWCsReload] = useIndexedDBFallback('admin_data', 'hot_water_cylinders', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            setLoadedDataFlags(prev => prev | FLAG_HWCS_LOADED);
            return undefined;
        }
        return await getHotWaterCylinders(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HWCS_LOADED);
        setAdminData(prev => ({ ...prev, hotWaterCylinders: data }));
    });
    const [, , , handleDataLabourReload] = useIndexedDBFallback('admin_data', 'labour', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            setLoadedDataFlags(prev => prev | FLAG_LABOUR_LOADED);
            return undefined;
        }
        return await getLabour(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_LABOUR_LOADED);
        setAdminData(prev => ({ ...prev, labour: data }));
    });
    const [, , , handleDataPartsReload] = useIndexedDBFallback('admin_data', 'parts', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            setLoadedDataFlags(prev => prev | FLAG_PARTS_LOADED);
            return undefined;
        }
        return await getParts(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PARTS_LOADED);
        setAdminData(prev => ({ ...prev, parts: data }));
    });
    const [, , , handleDataPacksReload] = useIndexedDBFallback('admin_data', 'packs', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            setLoadedDataFlags(prev => prev | FLAG_PACKS_LOADED);
            return undefined;
        }
        return await getPacks(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PACKS_LOADED);
        setAdminData(prev => ({ ...prev, packs: data }));
    });
    const [, , , handleDataAllCompaniesReload] = useIndexedDBFallback('admin_data', 'all_companies', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getAllCompaniesList();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_ALL_COMPANIES_LOADED);
        setAdminData(prev => ({ ...prev, allCompaniesList: data }));
    });
    const [, , , handleDataUserReload] = useIndexedDBFallback('admin_data', 'current_user', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        const user = await getUser();
        setUser({ email: user?.email });
        return user;
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_USER_LOADED);
        setAdminData(prev => ({ ...prev, user: data }));
    });
    const [, , , handleDataTermsAndConditionsReload] = useIndexedDBFallback('admin_data', 'terms_and_conditions', undefined, async () => {
        return await getTermsAndConditions();
    }, (data) => {
        setAdminData(prev => ({ ...prev, termsAndConditions: data }));
    });
    const [, , , handleDataGenericMaterialsReload] = useIndexedDBFallback('admin_data', 'generic_materials', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getGenericMaterials();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_GENERIC_MATERIALS_LOADED);
        setAdminData(prev => ({ ...prev, genericMaterials: data }));
    });
    const [, , , handleDataMaterialsGenericLayersReload] = useIndexedDBFallback('admin_data', 'generic_materials_layers', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getMaterialsGenericLayers();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_MATERIALS_GENERIC_LAYERS_LOADED);
        setAdminData(prev => ({ ...prev, materialsGenericLayers: data }));
    });
    const [, , , handleDataCompanyUsersReload] = useIndexedDBFallback('admin_data', 'company_users', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            return undefined;
        }
        return await getUsersForCompany(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_COMPANY_USERS_LOADED);
        setAdminData(prev => ({ ...prev, companyUsers: data }));
    });
    const [, , , handleDataCompanyJobUsersReload] = useIndexedDBFallback('admin_data', 'company_job_users', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        // If we don't have a company UUID, we don't have a company association
        // so there's nothing to fetch
        if (!companyPublicInfo) {
            return undefined;
        }
        return await getJobUsersForCompany(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_COMPANY_JOB_USERS_LOADED);
        setAdminData(prev => ({ ...prev, companyJobUsers: data }));
    });
    const [isSyncing, setIsSyncing] = useState(false);
    const [hasSynced, setHasSynced] = useState(false);
    // If the database is ready, check if we have any migrations to run before syncing, if not start syncing.
    useEffect(() => {
        const main = async () => {
            try {
                // We always want to fetch permissions
                const permissions = await getPermissions();
                if (permissions)
                    await db.permissions.bulkPut(permissions);
                // Everything else is reliant on having a globally available company
                if (companyPublicInfo) {
                    const company = await getCompany(companyPublicInfo.uuid);
                    const stages = await getStages(companyPublicInfo.uuid);
                    const statuses = await getStatuses(companyPublicInfo.uuid);
                    const companyRoles = await getCompanyRoles(companyPublicInfo.uuid);
                    const companyRolePermissions = await getCompanyRolePermissions(companyPublicInfo.uuid);
                    const permissions = await getPermissions();
                    if (company)
                        await db.companies.put(company);
                    if (stages)
                        await db.stages.bulkPut(stages);
                    if (statuses)
                        await db.statuses.bulkPut(statuses);
                    if (permissions)
                        await db.permissions.bulkPut(permissions);
                    const existingCompanyRoles = (await db.company_roles.toArray()).filter(x => x.company_uuid === companyPublicInfo.uuid);
                    if (companyRolePermissions) {
                        const existingPermissions = (await db.company_role_permissions.toArray()).filter(x => existingCompanyRoles.some(y => y.uuid === x.company_role_uuid));
                        const idsToDelete = existingPermissions.map(x => x.uuid).filter(x => !companyRolePermissions.some(y => y.uuid === x));
                        await db.company_role_permissions.bulkDelete(idsToDelete);
                        await db.company_role_permissions.bulkPut(companyRolePermissions);
                    }
                    if (companyRoles) {
                        const idsToDelete = existingCompanyRoles.map(x => x.uuid).filter(x => !companyRoles.some(y => y.uuid === x));
                        await db.company_roles.bulkDelete(idsToDelete);
                        await db.company_roles.bulkPut(companyRoles);
                    }
                }
            }
            catch (e) {
                console.error(e);
            }
            // Required placeholder migration, once everyone is off last_synced we can remove to just syncTick.
            const lastSynced = localStorage.getItem('last_synced');
            const metaTables = await db.meta.toArray();
            await Promise.all(DEFAULT_TABLES.filter(x => !metaTables.some(y => y.name === x.name)).map(async (x) => {
                await db.meta.add({ ...x, last_synced: (lastSynced && ['surveys', 'custom_materials', 'files'].includes(x.name)) ? parseInt(lastSynced) : 0 });
            }));
            localStorage.removeItem('last_synced');
            await syncTick(isSyncing, setIsSyncing, setHasSynced, setIsOffline);
        };
        main();
    }, []);
    useInterval(() => {
        syncTick(isSyncing, setIsSyncing, setHasSynced, setIsOffline);
    }, 10000);
    const stages = useLiveQuery(() => {
        if (!companyPublicInfo)
            return [];
        return db.stages.where('company_uuid').equals(companyPublicInfo.uuid).sortBy('order');
    }, [companyPublicInfo]);
    const statuses = useLiveQuery(() => {
        if (!companyPublicInfo || !stages)
            return [];
        return db.statuses.where('company_uuid').equals(companyPublicInfo.uuid).sortBy('order');
    }, [companyPublicInfo, stages]);
    const globalCompany = useLiveQuery(() => {
        if (!companyPublicInfo)
            return undefined;
        return db.companies.get(companyPublicInfo.uuid);
    }, [companyPublicInfo]);
    const companyRoles = useLiveQuery(() => db.company_roles.toArray()) ?? [];
    const companyRolePermissions = useLiveQuery(() => db.company_role_permissions.toArray()) ?? [];
    const permissions = useLiveQuery(() => db.permissions.toArray()) ?? [];
    const hydratedStages = stages?.map(x => ({ ...x, statuses: statuses?.filter(y => y.stage_uuid === x.uuid) ?? [] })) ?? [];
    const hydratedRoles = companyRoles.map(x => ({ ...x, company_role_permissions: companyRolePermissions.filter(y => y.company_role_uuid === x.uuid) }));
    const hydratedGlobalCompany = globalCompany && {
        ...globalCompany,
        company_roles: hydratedRoles.filter(x => x.company_uuid === globalCompany.uuid)
    };
    // control the loading state
    useEffect(() => {
        // check if the user is logged in
        if (!isLoggedIn.current)
            return;
        // reset the loading state only when all data is loaded
        if (loadedDataFlags === FLAG_ALL_LOADED) {
            setIsLoading(false);
        }
    }, [loadedDataFlags]);
    const toggleSidebar = (arg) => {
        setIsSidebarOpen(arg);
    };
    const handleLoginCallback = async (newLoginStatus) => {
        isLoggedIn.current = newLoginStatus;
        if (newLoginStatus) {
            setIsLoading(true);
            await handleDataUserReload();
            await handleDataCompanyUsersReload();
            await handleDataCompanyJobUsersReload();
            await handleDataHeatPumpsReload();
            await handleDataHWCsReload();
            await handleDataLabourReload();
            await handleDataPartsReload();
            await handleDataPacksReload();
            await handleDataAllCompaniesReload();
            await handleDataGenericMaterialsReload();
            await handleDataMaterialsGenericLayersReload();
            await handleDataTermsAndConditionsReload();
            await handleDataCompanyUsersReload();
            await handleDataCompanyJobUsersReload();
            setIsLoading(false);
        }
    };
    if (!hasSynced) {
        return (_jsx(LoaderOverlay, {}));
    }
    if (!adminData.user)
        return;
    return (_jsx(AuthProvider, { loginCallback: handleLoginCallback, children: _jsx(RequireAuth, { currentUrl: currentPath, navigateTo: navigateTo, children: _jsx(AdminContext.Provider, { value: {
                    showSidebar: () => {
                        toggleSidebar(true);
                    },
                    hideSidebar: () => {
                        toggleSidebar(false);
                    },
                    isSidebarOpen,
                    isLoading,
                    isOffline,
                    setIsOffline,
                    data: adminData,
                    setUser: (data) => setAdminData(prev => ({ ...prev, user: data })),
                    setCompanyUsers: (data) => setAdminData(prev => ({ ...prev, companyUsers: data })),
                    setCompanyJobUsers: (data) => setAdminData(prev => ({ ...prev, companyJobUsers: data })),
                    setHeatPumps: (data) => setAdminData(prev => ({ ...prev, heatPumps: data })),
                    setHotWaterCylinders: (data) => setAdminData(prev => ({ ...prev, hotWaterCylinders: data })),
                    setLabour: (data) => setAdminData(prev => ({ ...prev, labour: data })),
                    setParts: (data) => setAdminData(prev => ({ ...prev, parts: data })),
                    setPacks: (data) => setAdminData(prev => ({ ...prev, packs: data })),
                    acceptTermsAndConditions: async (id) => { setAdminData(prev => ({ ...prev, user: { ...prev.user, accepted_terms_and_conditions_id: id } })); },
                    isSidebarVisible,
                    setIsSidebarVisible,
                    userHasCompanyPermission: (permissionPath, job, jobCompany) => hasPermission({ user: adminData.user, selectedCompany: hydratedGlobalCompany, job, jobCompany, permissions, permissionPath })
                }, children: _jsx(Wrapper, { company: hydratedGlobalCompany, stages: hydratedStages, setCurrentCompanyUUID: setCurrentCompanyUUID, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo, currentPath: currentPath, isSyncing: isSyncing, setIsSyncing: setIsSyncing, setHasSynced: setHasSynced, permissions: permissions }) }) }) }));
};
