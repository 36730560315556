import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '../../components/containers/modal';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { validateURLAndHasProtocol, validateNotNull } from '../validators';
import { Input } from '../../components/inputs_and_selections/input';
export const AddExternalAttachmentModal = ({ visible, setVisible, onAddAttachment, forceTitle }) => {
    const [fileName, setTitle] = useState(forceTitle ?? '');
    const [url, setUrl] = useState('');
    const handleCloseModal = (open) => {
        if (visible) {
            setTitle(forceTitle || '');
            setUrl('');
            setVisible(open);
        }
    };
    const validFields = [validateURLAndHasProtocol(url), validateNotNull(fileName)].every(e => e.value);
    return _jsx(Modal, { visible: visible, setVisible: () => handleCloseModal(false), title: 'Add external attachment', confirmButtonLabel: 'Save', confirmDisabled: !validFields, onConfirm: () => onAddAttachment(fileName, url), children: _jsxs("div", { className: 'w-full flex flex-col gap-4', children: [_jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Document title', required: true, complete: fileName !== '' }), input: _jsx(Input, { placeholder: 'e.g. Case Study', setValue: title => setTitle(title), value: forceTitle ?? fileName ?? '', validator: validateNotNull, disabled: Boolean(forceTitle) }) }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'File URL', required: true, complete: url !== '' && !!validateURLAndHasProtocol(url).value }), input: _jsx(Input, { placeholder: 'URL', setValue: url => setUrl(url), value: url ?? '', validator: validateURLAndHasProtocol, doNotValidateWhenEmpty: true }) })] }) });
};
