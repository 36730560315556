import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Input } from '../../../components/inputs_and_selections/input';
import { noop } from 'lodash';
import { Icon } from '../../../components/buttons/icon';
import { Square } from 'lucide-react';
export const InputDemo = () => {
    const [inputText, setInputText] = useState('');
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Default" }), _jsx(Input, { onEnter: noop, value: inputText, setValue: setInputText, type: 'text', step: undefined, label: 'Label', placeholder: 'placeholder', size: 'MD', shrink: false, validator: (e) => ({ message: '', value: e }), className: '', disabled: false, postfix: _jsx(Icon, { icon: Square }), prefix: _jsx(Icon, { icon: Square }) }), _jsx("div", { children: "Invalid (focus and unfocus to test)" }), _jsx(Input, { value: inputText, setValue: setInputText, validator: (e) => ({ message: 'Not valid', value: undefined }) }), _jsx("div", { children: "Disabled" }), _jsx(Input, { disabled: true, value: inputText, setValue: setInputText })] })] });
};
const componentProps = [
    { name: 'onEnter', description: 'If we hit the enter key while focussed do something, e.g. call the submit form onClick' },
    { name: 'value', description: 'Value of the input' },
    { name: 'setValue', description: 'What to do on keypress of input' },
    { name: 'debounce', description: 'How long to wait in milliseconds after the last keypress before updating the external value. 0 by default, which means no debounce.' },
    { name: 'type', description: 'number | text' },
    { name: 'step', description: 'If input is a number, what should we increment by?' },
    { name: 'label', description: 'Label text above the input' },
    { name: 'placeholder', description: 'Placeholder when no value is present' },
    { name: 'size', description: 'SM | MD | LG' },
    { name: 'shrink', description: 'Prevent the input from being full width of container' },
    { name: 'validator', description: 'Validate the input, if message is not empty show error state' },
    { name: 'className', description: 'Any extra formatting or styling required' },
    { name: 'disabled', description: 'Should the input be disabled' },
    { name: 'prefix', description: 'Should anything exist before the input? Usually an icon' },
    { name: 'postfix', description: 'Should anything exist after the input? Usually an icon' }
];
const codeBlock = `const [inputText, setInputText] = useState('')

<Input
  onEnter={noop}
  value={inputText}
  setValue={setInputText}
  debounce={500}
  type='text'
  step={undefined}
  label='Label'
  placeholder='placeholder'
  size='MD'
  shrink={false}
  validator={(e) => ({ message: '', value: e })}
  className=''
  disabled={false}
  postfix={<Icon icon={Square} />}
  prefix={<Icon icon={Square} />}
/>`;
