import { z } from 'zod';
import { apiUrl, client } from '../axios';
import { setError } from '../../components/indicators_and_messaging/toast';
import { AuthSDK } from '../utils/auth_provider';
export const TermsAndConditionsSchema = z.object({
    id: z.number(),
    timestamp: z.number(),
    url: z.string(),
    message: z.string().optional()
});
export const acceptTermsAndConditions = async () => {
    try {
        await client.post(`${apiUrl}users/terms`, {}, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return true;
    }
    catch (e) {
        setError(e);
        return false;
    }
};
export const getTermsAndConditions = async () => {
    try {
        const result = await client.get(`${apiUrl}terms`);
        return result.data;
    }
    catch (e) {
        setError(e);
    }
};
export const userHasAcceptedLatestTerms = (user, latest) => {
    if (!user?.accepted_terms_and_conditions_id || !latest) {
        return false;
    }
    return user.accepted_terms_and_conditions_id >= latest.id;
};
