import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { orderBy, sum } from 'lodash';
import { SurveyElectricsContext } from '../electrics';
import { ClickableCard } from '../../../../components/content_display/card';
import { Table } from '../../../../components/content_display/table';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsConsumerUnitDetailsBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    const setConsumerUnitItems = (items) => setSurvey({ ...survey, electrics_consumer_unit_items: items });
    const tableColumns = [
        { key: 'name', name: 'Name' },
        { key: 'fuse_size_a', name: 'Fuse size (A)' }
    ];
    // Calculate the maximum demand by getting 100% of the highest fuse item and 40% of all other items.
    const getConsumerUnitMaximumDemand = (consumerUnitItems) => {
        if (consumerUnitItems.length === 0)
            return 0;
        const orderedItems = orderBy(consumerUnitItems, x => x.fuse_size_a, 'desc');
        const maxItem = orderedItems[0].fuse_size_a;
        const rest = sum(orderedItems.slice(1).map(x => x.fuse_size_a));
        return `100% x ${maxItem} A + 40% x ${rest} A = ${maxItem + rest * 0.4} A`;
    };
    // This function assumes a UK 230V system.
    const kwToAmps = (kw) => {
        const voltage = 230;
        const watts = kw * 1000;
        return watts / voltage;
    };
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Consumer unit" }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-900 font-bold', children: "Contents" }), _jsx(Table, { columns: tableColumns, rows: survey.electrics_consumer_unit_items, addNew: true, editable: true, onDeleteRow: (id) => setConsumerUnitItems(survey.electrics_consumer_unit_items.filter(x => x.uuid !== id)), onSaveRow: (row) => row.uuid ? setConsumerUnitItems(survey.electrics_consumer_unit_items.map(x => x.uuid === row.uuid ? {
                            ...row,
                            fuse_size_a: Number(row.fuse_size_a)
                        } : x)) : setConsumerUnitItems([...survey.electrics_consumer_unit_items, {
                                ...row,
                                fuse_size_a: Number(row.fuse_size_a),
                                uuid: crypto.randomUUID()
                            }]) }), _jsx("div", { className: 'font-bold text-gray-900', children: "Calculated maximum demand" }), _jsx("div", { children: getConsumerUnitMaximumDemand(survey.electrics_consumer_unit_items) })] }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Spare way on the consumer unit" }), _jsx(Toggle, { value: survey.electrics_spare_way, setValue: () => setSurvey({ ...survey, electrics_spare_way: !survey.electrics_spare_way }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "MCB Brand" }), _jsx(Input, { value: survey.electrics_breaker_make, placeholder: 'e.g. Hager', setValue: (e) => setSurvey({ ...survey, electrics_breaker_make: e }) })] }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Notes" }), _jsx(TextArea, { value: survey.electrics_notes, setValue: (e) => setSurvey({ ...survey, electrics_notes: e }) })] }), _jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs("div", { className: "justify-between items-center gap-3 flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Smart meter" }), _jsx(Toggle, { value: survey.electrics_has_smart_meter, setValue: () => setSurvey({
                                        ...survey,
                                        electrics_has_smart_meter: !survey.electrics_has_smart_meter
                                    }) })] }), survey.electrics_has_smart_meter && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'text-gray-900', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Peak electricity demand" }), _jsx("div", { className: 'text-sm text-gray-500', children: "If the homeowner has an in-home display or an app connected to their smart meter, find the max half-hourly energy consumption from last year. We'll convert this to the average current in that half hour. This can be used as an alternative measure of max demand." })] }), _jsx(Input, { type: 'number', value: survey.electrics_peak_electricity_demand_kwh.toString(), setValue: (e) => setSurvey({ ...survey, electrics_peak_electricity_demand_kwh: Number(e) }), postfix: 'kWh' })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Calculated maximum demand" }), _jsxs("div", { children: [Math.round(kwToAmps(survey.electrics_peak_electricity_demand_kwh / 0.5)), " A"] })] })] })] }) }), _jsxs("div", { className: "flex-col gap-2 flex", children: [_jsxs("div", { className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "MPAN number" }), _jsx("div", { className: "text-gray-500 text-sm", children: "A 13 digit number typically printed on an electricity bill." })] }), _jsx(Input, { value: survey.electrics_mpan_number, setValue: (e) => setSurvey({ ...survey, electrics_mpan_number: e }) })] })] });
};
