import Dexie from 'dexie';
export const db = new Dexie('spruce');
window.db = db;
// Schema declaration:
db.version(1).stores({
    surveys: '++uuid, updated_at, deleted_at, is_modified, server_updated_at',
    files: '++uuid, updated_at, deleted_at, company_uuid, is_modified, server_updated_at',
    custom_materials: '++uuid, updated_at, deleted_at, company_uuid, is_modified, server_updated_at',
    stages: '++uuid, updated_at, deleted_at, company_uuid, is_modified, server_updated_at',
    statuses: '++uuid, updated_at, deleted_at, company_uuid, is_modified, server_updated_at',
    jobs: '++uuid',
    companies: '++uuid, id',
    heat_pumps: '++uuid, company_uuid',
    hot_water_cylinders: '++uuid, company_uuid',
    meta: '++name',
    permissions: 'uuid',
    company_role_permissions: 'uuid',
    company_roles: 'uuid',
    company_partners: 'uuid'
});
