import { calculateEstimate, DEFAULT_FLOW_TEMP_C } from './calculate_estimate';
import { isFlagSet } from './helpers';
import { estimateAgeBandToSurveyAgeBand } from './models/age_bands';
import { HEATING_FUELS } from './models/heating_fuel';
import { mapEstimateMaterials, instantiateMaterialSet } from './models/material';
import { ESTIMATE_MAPPED_MATERIALS, ESTIMATE_MAPPED_AGE_BAND, ESTIMATE_MAPPED_FUEL_TYPE, ESTIMATE_MAPPED_HEAT_PUMP, ESTIMATE_MAPPED_HOT_WATER_CYLINDER, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS, ESTIMATE_MAPPED_DESIGN_TEMP, ESTIMATE_MAPPED_INDOOR_TEMP, ESTIMATE_MAPPED_ACH, ESTIMATE_MAPPED_PROPERTY_TYPE, ESTIMATE_MAPPED_BUILT_FORM, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS, ESTIMATE_MAPPED_FLOW_TEMP_C } from './models/property';
export const handleEstimateMapping = (lead, genericMaterials, customMaterials, heatPumps, hotWaterCylinders, company, survey, setCustomMaterials) => {
    let newSurvey = { ...survey };
    // MATERIALS
    // if survey does not have default materials, we need to provide them
    // usually applicable to the first time survey creation
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_MATERIALS)) {
        const mappedMaterials = mapEstimateMaterials(lead, genericMaterials, customMaterials, company.public_info.uuid);
        instantiateMaterialSet(mappedMaterials, setCustomMaterials);
        newSurvey = {
            ...newSurvey,
            default_materials: mappedMaterials,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_MATERIALS
        };
    }
    // AGE BAND
    // check either the age_band is set OR has the EstimateAgeBand type (the `id` attribute is only available in the SurveyAgeBand type)
    // in both cases we need to set the age_band attribute from the EPC or the lead (the method chooses it)
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_AGE_BAND)) {
        newSurvey = {
            ...newSurvey,
            age_band: estimateAgeBandToSurveyAgeBand(lead),
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_AGE_BAND
        };
    }
    // HEATING SYSTEM
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_FUEL_TYPE)) {
        const estimateFuelType = lead.property.houseOverrides?.fuelType ? lead.property.houseOverrides.fuelType : lead.property.fuelType;
        let mappedUUID;
        switch (estimateFuelType.toLowerCase()) {
            case 'mains gas':
                mappedUUID = HEATING_FUELS.find(x => x.uuid === 'mains_gas')?.uuid;
                break;
            case 'oil':
                mappedUUID = HEATING_FUELS.find(x => x.uuid === 'oil')?.uuid;
                break;
            case 'lpg':
                mappedUUID = HEATING_FUELS.find(x => x.uuid === 'lpg')?.uuid;
                break;
            case 'electric':
                mappedUUID = HEATING_FUELS.find(x => x.uuid === 'electricity')?.uuid;
                break;
            case 'other':
            default:
                // Map other to mains gas for now
                mappedUUID = HEATING_FUELS.find(x => x.uuid === 'mains_gas')?.uuid;
                break;
        }
        newSurvey = {
            ...newSurvey,
            existing_system_fuel_uuid: mappedUUID ?? '',
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_FUEL_TYPE
        };
    }
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP) ||
        !isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
        // Calculate heat pump and cylinder size based on lead data as is.
        // One time mapping so that the heat loss survey being incomplete doesn't result in us suggesting tiny heat pumps
        const { heatPump: leadHeatPump, hotWaterCylinder: leadHotWaterCylinder } = calculateEstimate(lead, heatPumps, hotWaterCylinders, company, undefined, undefined);
        // heat pump uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP)) {
            const design = newSurvey.designs[0];
            design.current_heat_pump_uuid = leadHeatPump?.uuid;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_HEAT_PUMP
            };
        }
        // flow temp
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_FLOW_TEMP_C)) {
            const design = newSurvey.designs[0];
            design.flow_temp = lead.flow_temperature_c ?? company?.default_flow_temp_c ?? DEFAULT_FLOW_TEMP_C;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_FLOW_TEMP_C
            };
        }
        // hot water cylinder uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
            const design = newSurvey.designs[0];
            design.current_hot_water_cylinder_uuid = leadHotWaterCylinder?.uuid;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_HOT_WATER_CYLINDER
            };
        }
    }
    // quote line items
    // TODO - replace this with the new quote line items or move entirely to proposal quote builder
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS)) {
        // The design's quote_line_items attribute should be empty here, so we just override it with our mapped values
        // It means, if there was something in the quote_line_items, it will be removed
        const items = [];
        const design = newSurvey.designs[0];
        if (!design.quote_line_items)
            design.quote_line_items = [];
        design.quote_line_items = items;
        newSurvey = {
            ...newSurvey,
            designs: [design],
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_QUOTE_LINE_ITEMS
        };
    }
    // design outdoor temp — override if the design temp is set explicitly,
    // otherwise use a default value which is provided separately in the UI
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_DESIGN_TEMP)) {
        newSurvey = {
            ...newSurvey,
            design_temp_override_c: lead.property?.houseOverrides?.designTempOverride ? lead.property?.houseOverrides?.designTempOverride : newSurvey.design_temp_override_c,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_DESIGN_TEMP
        };
    }
    // indoor temp — override if the indoor temp is set explicitly
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_INDOOR_TEMP)) {
        newSurvey = {
            ...newSurvey,
            indoor_temp_overall_c: lead.property?.houseOverrides?.internalTempOverride ? lead.property?.houseOverrides?.internalTempOverride : newSurvey.indoor_temp_overall_c,
            use_cibse_indoor_temps: !lead.property?.houseOverrides?.internalTempOverride, // if the override is set, we don't use the CIBSE values
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_INDOOR_TEMP
        };
    }
    // ACH
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_ACH)) {
        newSurvey = {
            ...newSurvey,
            air_change_per_hour_overall: lead.property?.houseOverrides?.airChangeOverride ? lead.property?.houseOverrides?.airChangeOverride : newSurvey.air_change_per_hour_overall,
            use_cibse_air_change_values: !lead.property?.houseOverrides?.airChangeOverride, // if the override is set, we don't use the CIBSE values
            air_change_year_uuid: newSurvey.age_band ? newSurvey.age_band.ach_age_key : newSurvey.air_change_year_uuid,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_ACH
        };
    }
    // Property type
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_PROPERTY_TYPE)) {
        newSurvey = {
            ...newSurvey,
            property_type: lead.property.propertyType,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_PROPERTY_TYPE
        };
    }
    // Built form
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BUILT_FORM)) {
        newSurvey = {
            ...newSurvey,
            built_form: lead.property.builtForm,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_BUILT_FORM
        };
    }
    // Bedrooms and bathroooms
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS)) {
        newSurvey = {
            ...newSurvey,
            bedrooms: lead.property.noBedrooms,
            bathrooms: lead.property.noBathrooms,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_BEDROOMS_BATHROOMS
        };
    }
    return newSurvey;
    // OTHER ATTRIBUTES: not mapping them because there is no UI to change them in the Survey. Once we have the UI, we can add the mapping for them.
    // ~Address~ — taken from the Lead in the UI
};
