export const getWallUValue = (wall, thermalBridgingUValueAdditionWPerM2K) => {
    // Thermal bridging is only added to external elements
    const uValueAddition = wall.material?.applicable_to === 'external-wall' ? thermalBridgingUValueAdditionWPerM2K : 0;
    if (['custom_layered', 'custom_simple'].includes(wall.material.type)) {
        return (wall.material?.extra_data).u_value + uValueAddition;
    }
    switch (wall.material?.applicable_to) {
        case 'internal-wall':
            return (wall.material?.extra_data).u_value;
        case 'external-wall':
            return (wall.material?.extra_data).u_value + uValueAddition;
        case 'party-wall':
            return (wall.material?.extra_data).u_value;
        default:
            return 0;
    }
};
