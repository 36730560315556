import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trash } from 'lucide-react';
import { formatMaterialUValue, getMaterialUValue } from '../../../code/models/material';
import { WrappedIcon } from '../../../components/buttons/wrapped_icon';
import { Badge } from '../../../components/indicators_and_messaging/badge';
export const MaterialItemBlock = ({ selectedMaterial, material, onClick, onDelete }) => {
    const uValue = getMaterialUValue(material);
    // TODO: should be able to refactor this to use navCard
    return _jsx("div", { className: "cursor-pointer self-stretch px-5 justify-center items-center gap-2 inline-flex bg-white", children: _jsxs("div", { className: "grow shrink basis-0 py-3 border-t border-gray-200 justify-center items-center gap-3 flex", children: [_jsx("div", { className: "grow shrink basis-0 text-gray-600 text-sm font-semibold", onClick: onClick, children: material.name }), uValue !== undefined && _jsx(Badge, { color: 'LIGHT', text: formatMaterialUValue(uValue) }), (onDelete && material.type !== 'generic' && selectedMaterial?.uuid !== material.uuid) && _jsx(WrappedIcon, { className: 'h-5 w-5 text-gray-400', icon: Trash, onClick: onDelete })] }) });
};
