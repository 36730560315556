import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { Section } from '../../../../components/containers/section';
import { AttachmentCard } from '../../../../code/proposal_attachments/attachment_card';
import { deleteDefaultLink, getDefaultLinks, insertDefaultLink } from '../../../../code/models/default_links';
import { proposalCategoryTitles } from '../../job_layout/proposal_configurator/proposal_files';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Button } from '../../../../components/buttons/button';
import { Plus } from 'lucide-react';
export const ProposalAttachments = ({ company }) => {
    const [requiredAttachments, setRequiredAttachments] = useState(Object.keys(proposalCategoryTitles).map(key => ({ key, attachment: undefined })));
    const [customAttachments, setCustomAttachments] = useState([]);
    const [error, setError] = useState(undefined);
    useEffect(() => {
        const fetchDefaultAttachments = async () => {
            const companyDefaultAttachments = await getDefaultLinks(company.public_info.uuid);
            if (companyDefaultAttachments.error) {
                setError(companyDefaultAttachments.error);
            }
            else {
                setRequiredAttachments(Object.keys(proposalCategoryTitles).map(key => ({
                    key,
                    attachment: companyDefaultAttachments.links?.find(link => link.category === key)
                })));
                setCustomAttachments(companyDefaultAttachments.links
                    ? companyDefaultAttachments.links.filter(link => link.category === 'proposal_custom')
                    : []);
            }
        };
        fetchDefaultAttachments();
    }, []);
    const handleRequiredAttachmentChange = async (key, title, url, internal, isAdding) => {
        const initialRequiredAttachments = requiredAttachments;
        // Common uuid for both state and DB updates
        const uuid = crypto.randomUUID();
        // Optimistically update requiredAttachments
        setRequiredAttachments(requiredAttachments.map(e => e.key === key ? { key: e.key, attachment: isAdding ? { uuid, title, url, internal, category: key } : undefined } : e));
        setError(undefined);
        try {
            if (isAdding) {
                await insertDefaultLink({ uuid, title, url, internal, category: key }, company.public_info.uuid);
            }
            else {
                const uuidToDelete = requiredAttachments.find(e => e.key === key)?.attachment?.uuid;
                if (uuidToDelete) {
                    await deleteDefaultLink(uuidToDelete, company.public_info.uuid);
                }
            }
        }
        catch (e) {
            setError(e.message);
            setRequiredAttachments(initialRequiredAttachments);
        }
    };
    const handleCustomAttachmentChange = async (index, title, url, internal, isAdding) => {
        const initialAttachments = customAttachments;
        const uuid = crypto.randomUUID();
        const updatedAttachments = customAttachments.map((attachment, i) => i === index ? (isAdding ? { uuid, title, url, internal, category: 'proposal_custom' } : undefined) : attachment).filter(attachment => attachment !== undefined);
        setCustomAttachments(updatedAttachments);
        try {
            if (isAdding) {
                await insertDefaultLink({ uuid, title, url, internal, category: 'proposal_custom' }, company.public_info.uuid);
            }
            else {
                const uuidToDelete = customAttachments[index]?.uuid;
                if (uuidToDelete) {
                    await deleteDefaultLink(uuidToDelete, company.public_info.uuid);
                }
            }
            setError(undefined);
        }
        catch (e) {
            setError(e.message);
            setCustomAttachments(initialAttachments);
        }
    };
    return _jsx(SettingsLayout, { heading: 'Proposal attachments', description: 'Configure the default attachments added to all new proposals. You can override these on a per-proposal basis.', children: _jsx("div", { className: 'flex flex-col gap-8', children: _jsx(Section, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(FormLabel, { size: 'LG', labelText: 'MCS Recommended Documents' }), error && _jsx(Alert, { type: 'DANGER', children: error }), requiredAttachments
                            .filter(requiredAttachment => requiredAttachment.key === 'proposal_terms_and_conditions')
                            .map(requiredAttachment => {
                            return _jsx(AttachmentCard, { forceTitle: proposalCategoryTitles[requiredAttachment.key], attachment: requiredAttachment.attachment, onAddAttachment: (title, url, internal) => handleRequiredAttachmentChange(requiredAttachment.key, proposalCategoryTitles[requiredAttachment.key], url, internal, true), onRemoveAttachment: () => handleRequiredAttachmentChange(requiredAttachment.key, '', '', false, false) }, requiredAttachment.key);
                        }), _jsx(FormLabel, { size: 'LG', labelText: 'Extra documents' }), customAttachments.map((link, index) => (_jsx(AttachmentCard, { attachment: link, onAddAttachment: (title, url, internal) => handleCustomAttachmentChange(index, title, url, internal, true), onRemoveAttachment: () => handleCustomAttachmentChange(index, '', '', false, false) }, index))), _jsx(Button, { iconLeft: Plus, colour: 'LIGHT', className: 'w-fit', disabled: customAttachments.length > 0 && customAttachments.some(attachment => attachment === undefined), onClick: () => setCustomAttachments(prev => [...prev, undefined]), children: "Add document" })] }) }) }) });
};
