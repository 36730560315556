import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { HPLProposedHeatPumpBlock } from './heat_pump_location/hpl_proposed_heat_pump_block';
import { HPLProposedLocationPhotosBlock } from './heat_pump_location/hpl_proposed_location_photos_block';
import { HPLProposedLocationDetailsBlock } from './heat_pump_location/hpl_proposed_location_details_block';
import { HPLInstallationDetailsBlock } from './heat_pump_location/hpl_installation_details_block';
import { HPLPlanningConsiderationsBlock } from './heat_pump_location/hpl_planning_considerations_block';
import { HPLDrainOrDownpipePhotosBlock } from './heat_pump_location/hpl_drain_or_downpipe_photos_block';
import { HPLSoundAssessmentBlock } from './heat_pump_location/hpl_sound_assessment_block';
import { getHeatPumpChecks } from './validations';
export const SurveyHeatPumpLocationContext = React.createContext(undefined);
export const HeatPumpLocation = (props) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x001, pbValue: 10, page: _jsx(HPLProposedHeatPumpBlock, {}) },
        { flagBit: 0x002, pbValue: 20, page: _jsx(HPLProposedLocationPhotosBlock, {}) },
        { flagBit: 0x004, pbValue: 40, page: _jsx(HPLProposedLocationDetailsBlock, {}) },
        { flagBit: 0x008, pbValue: 50, page: _jsx(HPLInstallationDetailsBlock, {}) },
        { flagBit: 0x010, pbValue: 60, page: _jsx(HPLDrainOrDownpipePhotosBlock, {}) },
        { flagBit: 0x020, pbValue: 80, page: _jsx(HPLPlanningConsiderationsBlock, {}) },
        { flagBit: 0x040, pbValue: 100, page: _jsx(HPLSoundAssessmentBlock, {}) }
    ];
    return _jsx(SurveyHeatPumpLocationContext.Provider, { value: props, children: _jsx(SurveySectionFlowWrapper, { isOffline: props.isOffline, allSynced: props.allSynced, completeSectionID: 'HEAT_PUMP', sectionUIName: 'Heat pump location', pages: pages, flags_attribute_name: 'flags_hpl_pages_completed', onPageComplete: (flagBit) => {
                props.setSurvey({ ...props.survey, flags_hpl_pages_completed: props.survey.flags_hpl_pages_completed | flagBit });
            }, onFormComplete: () => window.history.back(), survey: props.survey, setSurvey: props.setSurvey, completionChecks: getHeatPumpChecks(props.survey, props.currentHeatPump) }) });
};
