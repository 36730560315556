import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { ClickableCard } from '../../../../components/content_display/card';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { checkIfWelsh } from '../../../../code/models/address';
export const HPLPlanningConsiderationsBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { survey, setSurvey, lead } = hplContext;
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Planning considerations" }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsxs("div", { className: "text-gray-900 font-bold", children: ["Within ", checkIfWelsh(lead?.property.postcode) ? '3 metres' : '1 metre', " of boundary"] }), _jsx(Toggle, { value: survey.within_1_metre_of_boundary, setValue: () => setSurvey({
                            ...survey,
                            within_1_metre_of_boundary: !survey.within_1_metre_of_boundary
                        }) })] }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Listed building" }), _jsx(Toggle, { value: survey.listing_building, setValue: () => setSurvey({ ...survey, listing_building: !survey.listing_building }) })] }), _jsxs(ClickableCard, { variant: 'GREY', className: 'gap-6', children: [_jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Conservation area" }), _jsx(Toggle, { value: survey.conservation_area, setValue: () => setSurvey({ ...survey, conservation_area: !survey.conservation_area }) })] }), _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Location fronts a highway" }), _jsx(Toggle, { value: survey.location_fronts_highway, setValue: () => setSurvey({ ...survey, location_fronts_highway: !survey.location_fronts_highway }) })] }), !survey.conservation_area && _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Location is above ground storey" }), _jsx(Toggle, { value: survey.location_above_ground_storey, setValue: () => setSurvey({
                                    ...survey,
                                    location_above_ground_storey: !survey.location_above_ground_storey
                                }) })] }), survey.conservation_area && _jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 font-bold", children: "Location is closer to highway than building" }), _jsx(Toggle, { value: survey.location_closer_to_highway, setValue: () => setSurvey({
                                    ...survey,
                                    location_closer_to_highway: !survey.location_closer_to_highway
                                }) })] })] })] });
};
