import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState, useEffect, useCallback } from 'react';
import { validateIsNumber } from '../../code/validators';
import { FormLabel } from './form_label';
import _ from 'lodash';
const sizes = {
    SM: 'text-xs py-2 px-2.5',
    MD: 'text-sm px-4 py-2',
    LG: 'px-4 py-3'
};
export const Input = forwardRef(({ validator, validateImmediately = false, doNotValidateWhenEmpty = false, debounce = 0, onEnter, value, setValue, step, label, placeholder, type = 'text', size = 'MD', shrink = false, className, disabled, postfix, prefix }, ref) => {
    const [rawValue, setRawValue] = useState(value);
    const [visited, setVisited] = useState(false);
    // Update the rawValue when the value changes. Needed because value may change outside the component if
    // e.g. the user clears the override or another input controls the value of this input
    useEffect(() => { setRawValue(value); }, [value]);
    // Handle component unmount in case onBlur is not called (it does seem like it normally is)
    // Remove component unmount handling because was resetting valid values to 0 where the setValue initial input hadn't updated yet
    // See https://spruceretrofit.slack.com/archives/C06008YPC85/p1718435078288539?thread_ts=1718383595.167479&cid=C06008YPC85
    // useEffect(() => { return () => { handleCleanUp() } }, [])
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (onEnter)
                onEnter();
        }
    };
    const debouncedSetValue = debounce ? useCallback(_.debounce(setValue, debounce), [debounce]) : setValue;
    //   Called on every keystroke. Updates internal state (rawValue). If the value is valid, updates the value
    const handleChange = (e) => {
        const inputValue = e.currentTarget.value;
        setRawValue(inputValue);
        if (type === 'number' && !isNaN(parseFloat(inputValue))) {
            debouncedSetValue(inputValue); //   Only update the value if the input is a valid number
        }
        else if (type !== 'number') {
            //   For other input types you can always set the value
            debouncedSetValue(inputValue);
        }
    };
    //   Called when the user clicks away
    const handleBlur = () => {
        handleCleanUp();
        // needed where other fields in the same page depend on that value (e.g. the domestic hot water demand field)
        setVisited(true);
    };
    const handleCleanUp = () => {
        if (type === 'number') { // if rawValue isn't a valid number, set the value to 0
            if (isNaN(parseFloat(rawValue.toString()))) {
                debouncedSetValue('0');
            }
        }
    };
    // If no validator is provided for numbers then add a default one
    const validatorToUse = validator ?? (type === 'number' ? validateIsNumber : undefined);
    const validation = validatorToUse?.(rawValue);
    // Check explicitly for null or undefined as 0 may be a valid value
    //   Apply validation if input has been visited or we want to validate the input immediately
    const isInvalid = () => {
        if (!validation) {
            return false;
        }
        if (doNotValidateWhenEmpty && rawValue === '') {
            return false;
        }
        return (visited || validateImmediately) && (validation.value === null || validation.value === undefined);
    };
    return (_jsxs("div", { className: `flex flex-col space-y-2 ${className || ''}`, children: [label && _jsx(FormLabel, { labelText: label, size: "SM" }), _jsx("div", { className: `${disabled ? 'bg-gray-100 border-gray-300' : 'bg-white'} placeholder:text-gray-500 text-gray-600 rounded-lg border ${isInvalid() ? 'border-red-500 text-red-800' : 'border-gray-300 text-gray-600'} ${shrink ? '' : 'w-full'} `, children: _jsxs("div", { className: `flex justify-between gap-2   ${sizes[size]} `, children: [prefix && _jsx("div", { className: 'text-sm items-center flex text-gray-500', children: prefix }), _jsx("input", { "data-cy": "input", ref: ref, inputMode: type === 'number' ? 'decimal' : 'text', onBlur: handleBlur, placeholder: placeholder, onKeyDown: handleKeyDown, onWheel: (e) => e.currentTarget.blur(), type: type, step: step, className: `${disabled ? 'bg-gray-100 border-gray-300 text-gray-500' : 'bg-white text-gray-600'} placeholder:text-gray-500 outline-none w-full`, onChange: handleChange, value: rawValue, disabled: disabled ?? false }), postfix && _jsx("div", { className: 'text-sm items-center flex text-gray-500 whitespace-nowrap', children: postfix })] }) }), isInvalid() && _jsx("div", { className: "text-red-700 text-sm", children: validation?.message })] }));
});
