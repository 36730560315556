import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from '../../../components/inputs_and_selections/input';
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll';
import { validateNotNull, validateTemperatureRange } from '../../../code/validators';
import { TextArea } from '../../../components/inputs_and_selections/text_area';
import { ClickableCard } from '../../../components/content_display/card';
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { Info } from '../../../components/buttons/info';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
export const ManifoldDetailPage = ({ manifold, setManifold, files, setFiles, companyUUID }) => {
    const deleteImage = (imageUUID) => {
        setManifold(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }));
    };
    const images = manifold.photos.map(x => files.find(y => y.uuid === x.image_uuid));
    return _jsxs("div", { className: 'flex flex-col gap-5 p-5 overflow-y-auto', children: [_jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Name" }), _jsx(Input, { value: manifold.name, validateImmediately: true, validator: validateNotNull, setValue: (e) => setManifold(prev => ({ ...prev, name: e })) })] }), _jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: 'flex flex-col gap-5', children: [_jsx(HorizontalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'UFH temp is lower than the system temp', helperText: 'I.e. there is a mixing valve on the UFH', info: _jsx(Info, { infoModalHeader: 'UFH temp is lower than the system temp', infoModalBody: 'If a mixing valve is present at the UFH manifold to limit the temperature of the water going through the UFH system,' +
                                        ' enter that temperature limit here. If you modify the system temperature to fall below this limit we will then assume the mixing valve' +
                                        ' no longer functions and the UFH is at the same temp as the rest of the system. ' }) }), input: _jsx(Toggle, { value: manifold.ufh_temp_differs_from_system_temp, setValue: () => setManifold(prev => ({ ...prev, ufh_temp_differs_from_system_temp: !prev.ufh_temp_differs_from_system_temp })) }) }), manifold.ufh_temp_differs_from_system_temp &&
                            _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Mean UFH temperature', helperText: 'The average temperature of the water in the UFH system' }), input: _jsx(Input, { type: 'number', postfix: '\u00B0C', step: 5, value: manifold.max_mean_water_temp_c ?? '', setValue: (e) => setManifold(prev => ({ ...prev, max_mean_water_temp_c: parseFloat(e) })), validator: (e) => validateTemperatureRange(e, 30, 55), validateImmediately: true }) })] }) }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Number of loops" }), _jsx(Input, { type: 'number', value: manifold.number_of_loops ?? '', setValue: (e) => setManifold(prev => ({ ...prev, number_of_loops: parseFloat(e) })) })] }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Location description" }), _jsx(TextArea, { value: manifold.location_description, setValue: (e) => setManifold(prev => ({ ...prev, location_description: e })) })] }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsx("div", { className: 'font-bold text-gray-900', children: "Photos" }), _jsx(PhotoScroll, { images: images, addImage: (fileWrapper) => {
                            fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                            fileWrapper && setManifold(prev => ({
                                ...prev,
                                photos: [...prev.photos, {
                                        uuid: crypto.randomUUID(),
                                        entity_uuid: prev.uuid,
                                        image_uuid: fileWrapper.uuid
                                    }]
                            }));
                        }, deleteImage: deleteImage, companyUUID: companyUUID })] })] });
};
