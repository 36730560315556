import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { PageHeader } from '../design/components/design_page_header';
import { Icon } from '../../../components/buttons/icon';
import { Button } from '../../../components/buttons/button';
import { chain, sum } from 'lodash';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { combineHeatLossesForProgressChart, combineSortConductionHeatLosses, combineVentilationHeatLosses, getConductionHeatLossAllElements, getFloorAreaM2, getRoomTemp, getRoomWatts, getVentilationHeatLoss } from '../../../code/models/heat_loss';
import { ClickableCard } from '../../../components/content_display/card';
import { TabGroup } from '../../../components/content_display/tab';
import { TableLite } from '../../../components/content_display/table_lite';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { CompleteButton } from '../survey/complete_button';
import { ListItem } from '../../../components/content_display/list_item';
import { getEmitterWatts } from '../../../code/models/radiator_model';
import { getAreaM2, getEmitterOutputVsDemandText } from './code/utils';
import { pluralise } from '../../../code/helpers';
import { FlowTempSlider } from '../design/pages/emitter_design_page';
import { AdminContext } from '../../admin/admin_layout';
import { isCompanyHasMagicPlanIntegration } from '../../../code/models/company';
import { MagicplanModal } from '../magicplan/magicplan_modal';
import { FloorplanFlow } from '../survey/floorplan_flow';
import { CloudDownload, Layers } from 'lucide-react';
import { WrappedIcon } from '../../../components/buttons/wrapped_icon';
const pages = ['Rooms', 'Heat loss'];
export const FloorList = ({ setFlowTemp, floors, minFlowTemp, maxFlowTemp, designTempC, groundTempC, survey, design, setCurrentRoomId, setSurvey, navigateTo, isOffline, allSynced, useFloorplanFlow, customMaterials, setCustomMaterials, companyUUID, altitudeM, company }) => {
    const adminContext = useContext(AdminContext);
    const [currentPage, setPage] = useState('Rooms');
    const [showMagicplanModal, setShowMagicplanModal] = useState(false);
    if (useFloorplanFlow) {
        return _jsx(FloorplanFlow, { survey: survey, setSurvey: setSurvey, designTempDefault: designTempC, altitudeDefaultM: altitudeM ?? 0, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, navigateTo: navigateTo, companyUUID: companyUUID, isOffline: isOffline, allSynced: allSynced });
    }
    const roomRows = survey.floors.flatMap(x => x.rooms.flatMap(y => {
        const area = getAreaM2(y.walls.map(w => ({ x: w.x, y: w.y })));
        const heatLoss = getRoomWatts(y, x.rooms, designTempC, groundTempC, survey);
        return {
            name: y.name,
            designTemp: getRoomTemp(y, survey),
            area,
            heatLoss,
            wPerM2: heatLoss / area
        };
    }));
    const roomColumns = [
        { key: 'name', name: '' },
        { key: 'designTemp', name: 'Design Temp', render: (row) => _jsxs("div", { children: [row.designTemp, " \u00B0C"] }) },
        { key: 'area', name: 'Area', render: (row) => _jsxs("div", { children: [row.area.toFixed(1), " m\u00B2"] }) },
        { key: 'heatLoss', name: 'Heat loss', render: (row) => _jsxs("div", { children: [row.heatLoss.toFixed(0), " W"] }) },
        { key: 'wPerM2', name: 'W/m²', render: (row) => _jsxs("div", { children: [(row.heatLoss / row.area).toFixed(0), " W/m\u00B2"] }) }
    ];
    const conductionHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'otherSideTempC', name: 'Other side temp', render: (row) => _jsxs("div", { children: [row.otherSideTempC, " \u00B0C"] }) },
        { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => _jsx("div", { children: row.uValueWPerM2K.toFixed(2) }) },
        { key: 'areaM2', name: 'Area', render: (row) => _jsxs("div", { children: [row.areaM2.toFixed(1), " m\u00B2"] }) },
        { key: 'watts', name: 'Heat loss', render: (row) => _jsxs("div", { children: [row.watts.toFixed(0), " W"] }) }
    ];
    const ventilationHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'heatRecoveryPercentage', name: 'Heat recovery', render: (row) => _jsxs("div", { children: [row.heatRecoveryPercentage * 100, " %"] }) },
        { key: 'ACH', name: 'ACH', render: (row) => _jsx("div", { children: row.ACH }) },
        { key: 'volumeM3', name: 'Volume', render: (row) => _jsxs("div", { children: [row.volumeM3.toFixed(1), " m\u00B3"] }) },
        { key: 'watts', name: 'Heat loss', render: (row) => _jsxs("div", { children: [row.watts.toFixed(0), " W"] }) }
    ];
    const totalFloorAreaM2 = sum(survey.floors.flatMap(floor => floor.rooms.map(room => getFloorAreaM2(room.walls))));
    const conductionHeatLossRows = survey.floors.flatMap(floor => floor.rooms.flatMap(room => getConductionHeatLossAllElements(room, floor.rooms, designTempC, groundTempC, survey)));
    const ventilationHeatLosses = survey.floors.flatMap(floor => floor.rooms.map(room => getVentilationHeatLoss(room, designTempC, survey)));
    // For chart
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, ventilationHeatLosses);
    // For tables
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, true);
    const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses(ventilationHeatLosses);
    const totalHeatLossWFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)));
    const totalHeatLossWFromRooms = Math.round(sum(roomRows.map(x => x.heatLoss)));
    const totalWPm2 = Math.round(totalHeatLossWFromRooms / totalFloorAreaM2);
    // FIXME: enable once Metropix signed and integrated
    // const metropixFileInputRef = useRef<HTMLInputElement>(null)
    const page = 'FLOORPLAN';
    if (!adminContext)
        return null;
    return _jsxs("div", { className: 'h-full flex flex-col min-h-0', children: [_jsx(MagicplanModal, { survey: survey, setSurvey: setSurvey, showMagicplanModal: showMagicplanModal, setShowMagicplanModal: setShowMagicplanModal, company: company }), _jsx(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Floorplan', onBack: () => navigateTo('/survey'), completed: survey.completed_sections.includes(page), children: _jsx(TabGroup, { items: pages.map(x => ({
                        name: x,
                        secondaryText: x === 'Heat loss'
                            ? `${totalHeatLossWFromElements} W`
                            : `${totalFloorAreaM2.toFixed(1)} m²`,
                        onClick: () => setPage(x),
                        variant: x === currentPage ? 'ACTIVE' : 'DEFAULT'
                    })) }) }), currentPage === 'Rooms' && _jsxs("div", { className: "p-5 bg-gray-100 flex-col gap-6 flex flex-grow overflow-y-auto", children: [_jsxs("div", { className: "flex-col gap-3 flex", children: [_jsxs("div", { className: "justify-between items-center flex", children: [_jsx("div", { className: " text-gray-900 text-xl font-bold", children: "Floors" }), _jsxs("div", { className: "flex gap-3", children: [isCompanyHasMagicPlanIntegration(company) &&
                                                _jsxs(Button, { onClick: () => setShowMagicplanModal(prev => !prev), colour: 'LIGHT', children: [_jsx(WrappedIcon, { icon: CloudDownload }), "Magicplan"] }), _jsx(Button, { onClick: () => navigateTo('/survey/new_floor'), colour: 'LOZENGE_DARK', children: "Add floor" })] })] }), floors.length === 0 && _jsx(ClickableCard, { onClick: () => navigateTo('/survey/new_floor'), variant: 'PLACEHOLDER', children: _jsxs("div", { className: 'flex flex-col items-center gap-2', children: [_jsx(Icon, { className: 'text-2xl', icon: Layers }), _jsx("div", { children: "No floors found" })] }) }), _jsx("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200", children: floors.map(x => _jsx(ListItem, { onClick: () => navigateTo(`/survey/floors/${x.uuid}`), primaryText: x.name, secondaryText: `${x.rooms.length} room${x.rooms.length > 1 && x.rooms.length !== 0 ? 's' : ''} • ${Math.round(sum(x.rooms.map(y => getAreaM2(y.walls.map(x => ({ x: x.x, y: x.y }))))))} m²` }, x.uuid)) })] }), floors.length > 0 && _jsxs("div", { className: 'flex flex-col gap-3', children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Rooms" }), _jsx(ClickableCard, { border: false, variant: 'WHITE', children: _jsx("div", { className: 'flex flex-col gap-4 justify-between flex-grow', children: _jsx(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }) }) }), floors.map(x => {
                                // If we have a group, group them otherwise group by individual rooms e.g. just list the rooms as normal.
                                const roomGroups = chain(x.rooms)
                                    .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
                                    .map((values, key) => ({ key, values }))
                                    .value();
                                return _jsxs("div", { children: [_jsx("div", { className: "text-gray-500 font-semibold", children: x.name }), x.rooms.length === 0 && _jsx(ClickableCard, { variant: 'PLACEHOLDER', children: _jsx("div", { className: 'flex flex-col items-center gap-2', children: _jsx("div", { children: "No rooms found" }) }) }), roomGroups.map(({ key, values }) => {
                                            const wattsLost = sum(values.map(y => Math.round(getRoomWatts(y, x.rooms, designTempC, groundTempC, survey))));
                                            const wattsEmitted = sum(values.map(y => Math.round(sum(y.radiators.map(r => getEmitterWatts(r, y, design, survey, designTempC, groundTempC))))));
                                            const windows = sum(values.flatMap(y => y.walls.map(x => x.windows.length)));
                                            const doors = sum(values.flatMap(y => y.walls.map(x => x.doors.length)));
                                            const roomArea = getAreaM2(values.flatMap(y => y.walls.map(x => ({ x: x.x, y: x.y }))));
                                            const wattsPerM2 = Math.round(wattsLost / roomArea);
                                            return _jsxs("div", { className: 'flex flex-col gap-3', children: [values.length > 1 && _jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: 'font-bold text-gray-900', children: values.map(x => x.name).join(', ') }), _jsx(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) })] }), _jsx("div", { className: 'bg-white rounded-md flex flex-col divide-y divide-gray-200', children: values.map((y, i) => _jsx(ListItem, { onClick: () => {
                                                                setCurrentRoomId(y.uuid);
                                                                navigateTo(`/survey/floors/${x.uuid}`);
                                                            }, primaryText: y.name, secondaryText: `${wattsPerM2} W/m² • ${y.radiators.length} ${pluralise('emitter', y.radiators.length)}, ${windows} ${pluralise('win', windows)}, ${doors} ${pluralise('door', doors)}`, rightBadge: values.length === 1 ? _jsx(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) }) : _jsx("div", {}) }, i)) })] }, key);
                                        })] }, x.uuid);
                            })] })] }), currentPage === 'Rooms' && _jsx(CompleteButton, { page: page, pageChecks: [], survey: survey, setSurvey: setSurvey, onBack: async () => window.history.back() }), currentPage === 'Heat loss' && _jsx("div", { className: 'flex flex-col p-5', children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex justify-between text-lg', children: [_jsx("div", { className: 'text-gray-900 font-bold', children: "Heat loss by room" }), _jsxs("div", { children: [totalHeatLossWFromRooms, " W"] })] }), _jsx(ProgressChart, { total: totalHeatLossWFromRooms, items: roomRows.map(x => ({ value: x.heatLoss, name: x.name })) })] }) }), _jsxs("div", { className: 'flex flex-col px-2 gap-4', children: [_jsx(TableLite, { columns: roomColumns, rows: roomRows }), _jsx(HeatLossTotalLines, { totalHeatLossW: totalHeatLossWFromRooms, totalWPerM2: totalWPm2, totalFloorAreaM2: totalFloorAreaM2 })] }), _jsx(ClickableCard, { variant: 'GREY', children: _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex justify-between text-lg', children: [_jsx("div", { className: 'text-gray-900 font-bold', children: "Heat loss by element" }), _jsxs("div", { children: [totalHeatLossWFromElements, " W"] })] }), _jsx(ProgressChart, { total: totalHeatLossWFromElements, items: heatLossForChart })] }) }), _jsxs("div", { className: 'flex flex-col px-2 gap-4', children: [_jsx(TableLite, { columns: conductionHeatLossColumns, rows: conductionHeatLossRowsCombinedForTable }), _jsx(TableLite, { columns: ventilationHeatLossColumns, rows: ventilationHeatLossCombinedForTable }), _jsx(HeatLossTotalLines, { totalHeatLossW: totalHeatLossWFromRooms, totalWPerM2: totalWPm2, totalFloorAreaM2: totalFloorAreaM2 })] })] }) })] });
};
export const HeatLossTotalLines = ({ totalHeatLossW, totalWPerM2, totalFloorAreaM2 }) => {
    return _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex justify-between text-gray-900 font-bold', children: [_jsx("div", { children: "Total" }), _jsxs("div", { children: [totalHeatLossW, " W"] })] }), _jsxs("div", { className: 'flex justify-between text-xs font-semibold', children: [_jsx("div", { children: "Heat loss per m\u00B2" }), _jsxs("div", { children: [totalWPerM2, " W/m\u00B2"] })] }), _jsxs("div", { className: 'flex justify-between text-xs font-semibold', children: [_jsx("div", { children: "Floor area" }), _jsxs("div", { children: [totalFloorAreaM2.toFixed(0), " m\u00B2"] })] })] });
};
