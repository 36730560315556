import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Section } from '../../components/section';
import { Heading } from '../../../../../components/content_display/heading';
import { ClickableCard } from '../../../../../components/content_display/card';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Modal } from '../../../../../components/containers/modal';
import { orderBy, uniq } from 'lodash';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { ListItem } from '../../../../../components/content_display/list_item';
import { RadioGroup } from '../../../../../components/inputs_and_selections/radio';
import { AdminContext } from '../../../admin_layout';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { FlowTempSlider } from '../../../../heat_loss/design/pages/emitter_design_page';
import { DEFAULT_FLOW_TEMP_C } from '../../../../../code/calculate_estimate';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { HeatLossSummaryCards } from './heat_loss_estimate';
const SelectHeatPumpModal = ({ visible, setVisible, designTempC, flowTempC, totalHeatLossKW, lead, setLead, adminContext }) => {
    const [rangeFilter, setRangeFilter] = useState('All');
    const ranges = ['All', ...uniq(adminContext.data.heatPumps?.filter(x => !x.deleted_at).map(x => x.range_heat_pump?.brand_range?.name)).filter(x => !!x)];
    const filteredHeatPumps = orderBy(adminContext.data.heatPumps
        ?.filter(x => (!x.deleted_at))
        .filter(x => rangeFilter === 'All' || x.range_heat_pump?.brand_range?.name === rangeFilter)
        .map(x => {
        const capacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC);
        return {
            uuid: x.uuid,
            name: x.name,
            capacityKw: capacityResult.capacityKw,
            outsideTempC: capacityResult.outsideTempC,
            flowTempC: capacityResult.flowTempC,
            warning: capacityResult.warning
        };
    }), x => x.capacityKw);
    return (_jsx(Modal, { title: 'Select a heat pump', visible: visible, setVisible: setVisible, children: _jsxs("div", { className: 'flex flex-col w-full gap-5', children: [_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: "text-gray-500 text-xs font-semibold uppercase", children: "Range" }), _jsx("div", { className: "flex-col gap-2 flex overflow-x-auto", children: _jsx(RadioGroup, { items: ranges.map(x => ({
                                    name: x,
                                    onClick: () => setRangeFilter(x),
                                    variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
                                })) }) })] }), _jsx("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200 ", children: filteredHeatPumps.map(x => _jsx(ListItem, { onClick: async () => {
                            setLead({ ...lead, heat_pump_uuid: x.uuid });
                            setVisible(false);
                        }, primaryText: x.name, rightBadge: getCapacityBadge(x, totalHeatLossKW) }, x.uuid)) })] }) }));
};
const SelectHotWaterCylinderModal = ({ visible, setVisible, lead, setLead, adminContext }) => {
    return (_jsx(Modal, { title: 'Select a hot water cylinder', visible: visible, setVisible: setVisible, children: _jsx("div", { className: "bg-white rounded-md flex-col flex divide-y divide-light w-full", children: adminContext.data.hotWaterCylinders?.filter(x => (!x.deleted_at)).map(x => _jsx(ListItem, { onClick: async () => {
                    setLead({ ...lead, hot_water_cylinder_uuid: x.uuid });
                    setVisible(false);
                }, primaryText: x.name, rightBadge: _jsx(Badge, { color: 'LIGHT', text: `${Math.round(x.litres)} litres` }) }, x.uuid)) }) }));
};
export const SystemDesign = ({ lead, setLead, estimate, company }) => {
    const adminContext = useContext(AdminContext);
    const [selectHeatPumpModalVisible, setSelectHeatPumpModalVisible] = useState(false);
    const [selectHotWaterCylinderModalVisible, setSelectHotWaterCylinderModalVisible] = useState(false);
    const flowTemp = lead.flow_temperature_c ?? company.default_flow_temp_c ?? DEFAULT_FLOW_TEMP_C;
    const selectedHeatPumpCapacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp(estimate.heatPump?.range_heat_pump, estimate.designTempC, flowTemp);
    const totalHeatLossKW = estimate.totalWatts / 1000;
    const heatPumpRows = [
        { key: 'Sound power level', value: `${estimate.heatPump?.range_heat_pump?.sound_power_max_dba} dB(A)` },
        { key: 'Refrigerant', value: estimate.heatPump?.range_heat_pump?.refrigerant },
        { key: 'Volume', value: _jsxs(_Fragment, { children: [(((estimate.heatPump?.range_heat_pump?.width_mm ?? 0) * (estimate.heatPump?.range_heat_pump?.height_mm ?? 0) * (estimate.heatPump?.range_heat_pump?.depth_mm ?? 0)) / Math.pow(10, 9)).toFixed(2), " m\u00B3"] }) },
        { key: 'Dimensions (WxHxD)', value: `${estimate.heatPump?.range_heat_pump?.width_mm}mm x ${estimate.heatPump?.range_heat_pump?.height_mm}mm x ${estimate.heatPump?.range_heat_pump?.depth_mm}mm` },
        {
            key: `SCOP at ${flowTemp}°C`,
            value: estimate.sCOP > 0 ? `${Math.floor(estimate.sCOP * 100)}%` : _jsx(Badge, { color: 'RED', text: `${Math.floor(estimate.sCOP * 100)}%` })
        },
        {
            key: `Capacity at ${selectedHeatPumpCapacity.flowTempC}°C (${selectedHeatPumpCapacity.outsideTempC} °C)`,
            value: getCapacityBadge(selectedHeatPumpCapacity, totalHeatLossKW)
        }
    ];
    const setFlowTemp = (flowTemp) => {
        setLead({ ...lead, flow_temperature_c: flowTemp });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { title: 'System design', children: [_jsxs("div", { className: 'space-y-3', children: [_jsx(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 4 }), _jsx(Heading, { size: 'lg', children: "Heat pump model" }), !estimate.heatPump && _jsx(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHeatPumpModalVisible(true), children: "Please select a heat pump" }), estimate.heatPump &&
                                _jsxs(ClickableCard, { variant: "WHITE", title: estimate.heatPump.name, onClick: () => setSelectHeatPumpModalVisible(true), showIcon: true, children: [_jsx(SummaryTable, { rows: heatPumpRows }), estimate.heatPump.deleted_at && _jsx(Alert, { type: 'DANGER', children: "This heat pump has been deleted from your inventory. You won't be able to select it again if you change to a different one." }), selectedHeatPumpCapacity.warning && _jsxs(Alert, { type: 'WARNING', children: [selectedHeatPumpCapacity.warning, "."] })] })] }), _jsxs("div", { className: 'space-y-3', children: [_jsx(Heading, { size: 'lg', children: "Cylinder model" }), !estimate.hotWaterCylinder && _jsx(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHotWaterCylinderModalVisible(true), children: "Please select a hot water cylinder" }), estimate.hotWaterCylinder &&
                                _jsxs(ClickableCard, { variant: "WHITE", title: estimate.hotWaterCylinder.name, onClick: () => setSelectHotWaterCylinderModalVisible(true), showIcon: true, children: [_jsx(SummaryTable, { rows: [
                                                { key: 'Capacity', value: `${Math.round(estimate.hotWaterCylinder.litres || 0)} litres` }
                                            ] }), estimate.hotWaterCylinder?.deleted_at && _jsx(Alert, { type: 'DANGER', children: "This hot water cylinder has been deleted from your inventory. You won't be able to select it again if you change to a different one." })] })] }), _jsx(FlowTempSlider, { flowTemp: flowTemp, setFlowTemp: setFlowTemp })] }), selectHeatPumpModalVisible && _jsx(SelectHeatPumpModal, { visible: selectHeatPumpModalVisible, setVisible: setSelectHeatPumpModalVisible, designTempC: estimate.designTempC, flowTempC: flowTemp, totalHeatLossKW: totalHeatLossKW, lead: lead, setLead: setLead, adminContext: adminContext }), selectHotWaterCylinderModalVisible && _jsx(SelectHotWaterCylinderModal, { visible: selectHotWaterCylinderModalVisible, setVisible: setSelectHotWaterCylinderModalVisible, lead: lead, setLead: setLead, adminContext: adminContext })] }));
};
const getCapacityBadge = (selectedHeatPumpCapacity, totalHeatLossKW) => {
    if (totalHeatLossKW) {
        return _jsx(Badge, { color: selectedHeatPumpCapacity.capacityKw >= totalHeatLossKW ? selectedHeatPumpCapacity.warning ? 'YELLOW' : 'GREEN' : 'RED', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' });
    }
    return _jsx(Badge, { color: 'LIGHT', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' });
};
