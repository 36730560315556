import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../buttons/icon';
export const badgeColours = {
    DARK: 'bg-gray-900 text-gray-100',
    LIGHT: 'bg-gray-100 text-gray-900',
    YELLOW: 'bg-yellow-100 text-yellow-900',
    RED: 'bg-red-100 text-red-900',
    BLUE: 'bg-blue-100 text-blue-900',
    INDIGO: 'bg-purple-100 text-purple-900',
    GREEN: 'bg-green-100 text-green-900'
};
export const Badge = ({ color, text, icon, button, spin, dataCy }) => {
    if (!text)
        return;
    return _jsx("div", { className: `${badgeColours[color]} px-2 py-1 rounded flex items-center justify-center`, "data-cy": dataCy, children: _jsxs("div", { className: 'flex gap-2 items-center', children: [icon && _jsx(Icon, { size: 'w-3 h-3', icon: icon, spin: spin }), _jsx("div", { className: "text-xs font-semibold text-center truncate", children: text }), button] }) });
};
