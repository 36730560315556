import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button } from '../../../components/buttons/button';
import { Check, Undo } from 'lucide-react';
export const CompleteButton = ({ page, pageChecks, survey, setSurvey, onBack, secondaryButton }) => {
    const isCompleted = survey.completed_sections.includes(page);
    const addOrRemoveSection = async () => await setSurvey(prev => ({
        ...prev,
        completed_sections: isCompleted
            ? prev.completed_sections.filter(x => x !== page)
            : [...prev.completed_sections, page]
    }));
    return _jsxs("div", { className: "p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl", children: [pageChecks.length > 0 && _jsxs("div", { className: "text-sm font-bold", children: [pageChecks.filter(x => !!x).length, "/", pageChecks.length, " required questions complete"] }), _jsx("div", { className: 'flex flex-col w-full', children: _jsxs("div", { className: 'flex-row flex gap-2.5 flex-grow', children: [secondaryButton && _jsx("div", { className: 'w-1/2', children: secondaryButton }), _jsx("div", { className: secondaryButton ? 'w-1/2' : 'w-full', children: _jsxs(Button, { block: true, colour: 'DARK', onClick: async () => {
                                    await addOrRemoveSection();
                                    await onBack();
                                }, iconLeft: isCompleted ? Undo : Check, children: ["Mark as ", isCompleted ? 'incomplete' : 'complete'] }) })] }) })] });
};
