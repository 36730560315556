import { z } from 'zod';
import { apiUrl, axiosGetV2, axiosPatchV2, axiosPostV2, client } from '../axios';
import { parseWithZod } from '../zod';
import { AuthSDK } from '../utils/auth_provider';
import { QuoteItemSchema } from '../calculate_quote';
import { LinkSchema } from './links';
export const ProposalQuoteItemSchema = QuoteItemSchema.extend({
    proposal_uuid: z.string().uuid(),
    order: z.number(),
    updated_at: z.coerce.date(),
    created_at: z.coerce.date()
});
export const CustomerFacingQuoteItemSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    description: z.string().optional(),
    group_name: z.string(),
    quantity: z.number(),
    selected: z.boolean(),
    image_url: z.string().optional(),
    subtotal: z.number().optional()
});
export const ProposalPaymentScheduleSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    percentage: z.coerce.number(),
    due_date: z.coerce.date().optional().nullable()
});
export const QuoteSnapshotDataSchema = z.object({
    quote_items: z.array(CustomerFacingQuoteItemSchema),
    total_pre_deductions: z.number(),
    discount_total: z.number(),
    vat_on_all: z.number(),
    vat_on_survey: z.number(),
    bus_grant: z.number(),
    group_totals: z.record(z.string(), z.number()),
    payment_schedule: z.array(ProposalPaymentScheduleSchema).optional(),
    additional_notes: z.string().optional()
});
export const QuoteSnapshotSchema = z.object({
    uuid: z.string().uuid().optional(),
    snapshot: QuoteSnapshotDataSchema,
    recipients: z.array(z.string()).optional(),
    email_text: z.string().optional()
});
export const QuoteSnapshotPublicResponseSchema = z.object({
    uuid: z.string().uuid().optional(),
    snapshot: QuoteSnapshotDataSchema,
    recipients: z.array(z.string()).optional(),
    email_text: z.string().optional(),
    is_opened: z.boolean(),
    lead_uuid: z.string().uuid()
});
export const ProposalSnapshotSchema = z.object({
    quote: QuoteSnapshotDataSchema.optional(),
    // TODO: redefine to HeatLossReportSnapshot
    report: z.record(z.unknown()).optional()
});
export var ProposalInstallerStatus;
(function (ProposalInstallerStatus) {
    ProposalInstallerStatus[ProposalInstallerStatus["Draft"] = 0] = "Draft";
    ProposalInstallerStatus[ProposalInstallerStatus["Sent"] = 1] = "Sent";
})(ProposalInstallerStatus || (ProposalInstallerStatus = {}));
export const ProposalSchema = z.object({
    uuid: z.string().uuid(),
    lead_uuid: z.string().uuid(),
    installer_status: z.nativeEnum(ProposalInstallerStatus).optional().nullable(),
    sent_at: z.coerce.date().nullable().optional(),
    show_cost: z.boolean(),
    include_vat_on_all: z.boolean(),
    include_vat_on_survey: z.boolean(),
    valid_days: z.number().optional().nullable(),
    cover_note: z.string(), // a stringified JSON
    contents_bitmask: z.number(),
    additional_notes: z.string().optional().nullable(), // stringified JSON
    recipients: z.array(z.string()).optional().nullable(),
    email_text: z.string().optional().nullable(),
    sender_snapshot: z.object({
        first_name: z.string().optional().nullable(),
        last_name: z.string().optional().nullable(),
        email: z.string().optional()
    }).optional().nullable(),
    snapshot: ProposalSnapshotSchema,
    payment_schedule: z.array(ProposalPaymentScheduleSchema).optional().nullable(),
    acceptance_customer_name: z.string().nullable().optional(),
    acceptance_customer_date: z.string().nullable().optional(),
    acceptance_status: z.number().nullable().optional(),
    acceptance_changed_at: z.coerce.date().nullable().optional(),
    is_opened: z.boolean(),
    quote_items: z.array(ProposalQuoteItemSchema),
    links: z.array(LinkSchema).optional().nullable()
});
export const ProposalSignRequestSchema = z.object({
    acceptance_customer_name: z.string(),
    acceptance_customer_date: z.string()
});
export const getDraftProposalAPI = async (leadUUID, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/proposals/draft`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        const parsedResult = parseWithZod(ProposalSchema, result.data);
        return parsedResult;
    }
    catch (e) {
        console.error('Error getting draft proposal', e);
        return undefined;
    }
};
export const updateProposalAPI = async (proposal, leadUUID, companyUUID) => {
    await client.patch(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/proposals/${proposal.uuid}`, proposal, {
        headers: {
            'x-auth-token': AuthSDK.getToken()
        }
    });
};
export const buildProposalPDFURL = (proposalUUID, companyUUID) => `${apiUrl}teams/${companyUUID}/proposals/${proposalUUID}/pdf`;
export const buildQuotePDFURL = (quoteUUID, companyUUID) => `${apiUrl}teams/${companyUUID}/quotes/${quoteUUID}/pdf`;
export const sendProposalAPI = async (leadUUID, companyUUID, proposalUUID, email_html) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/send`, { email_html });
export const getProposalPublicAPI = async (reportUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/proposals/${reportUUID}`);
export const getAllProposalsAPI = async (leadUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/all`);
export const getProposalPreviewPDFAPI = async (proposalUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/proposals/${proposalUUID}/preview_pdf`, { responseType: 'blob' });
export const createQuoteSnapshotAPI = async (leadUUID, companyUUID, quoteSnapshot) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/quote_snapshot`, quoteSnapshot);
export const sendQuoteAPI = async (leadUUID, companyUUID, quoteSnapshotUUID, email_html) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/quote_snapshot/${quoteSnapshotUUID}/send`, { email_html });
export const getQuotePublicAPI = async (reportUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/quotes/${reportUUID}`);
export const getQuotePreviewPDFAPI = async (quoteUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/quotes/${quoteUUID}/preview_pdf`, { responseType: 'blob' });
export const signProposalAPI = async (proposalSignRequest, leadUUID, companyUUID, proposalUUID) => await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/sign`, proposalSignRequest);
export const setProposalOpenedAPI = async (proposalUUID, companyUUID) => await axiosPatchV2(`teams/${companyUUID}/proposals/${proposalUUID}/opened_status`, { is_opened: true });
export const setQuoteOpenedAPI = async (quoteUUID, companyUUID) => await axiosPatchV2(`teams/${companyUUID}/quotes/${quoteUUID}/opened_status`, { is_opened: true });
