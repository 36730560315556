export const getAllSurveyImages = (survey) => {
    const rooms = survey.floors.flatMap(x => x.rooms);
    const rads = rooms.flatMap(x => x.radiators);
    const surveyImages = [
        ...survey.sound_assessment_images.map(x => x.image_uuid),
        ...survey.location_images.map(x => x.image_uuid),
        ...survey.cylinder_photos.map(x => x.image_uuid),
        ...survey.property_images.map(x => x.image_uuid),
        ...survey.existing_cylinder_photos.map(x => x.image_uuid),
        ...survey.electrics_consumer_unit_images.map(x => x.image_uuid),
        ...survey.electrics_earthing_images.map(x => x.image_uuid),
        ...survey.electrics_images.map(x => x.image_uuid),
        ...survey.existing_system_photos.map(x => x.image_uuid),
        ...survey.drain_photos.map(x => x.image_uuid),
        ...survey.stopcock_images.map(x => x.image_uuid),
        ...survey.manifolds.flatMap(x => x.photos.map(y => y.image_uuid)),
        ...rooms.flatMap(x => x.images.map(y => y.image_uuid)),
        ...rads.flatMap(x => x.photos.flatMap(y => y.image_uuid))
    ];
    return surveyImages;
};
