import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
export const PrintLayoutContext = React.createContext({ headerHeightPx: 0 });
export const PrintLayout = (props) => {
    // It's very tricky to print header on each page. There is a solution reused:
    // https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
    return _jsxs(_Fragment, { children: [_jsx("div", { 
                // using style because arbitrary class names are not allowed in print media
                style: { height: `${props.headerHeightPx}px` }, className: 'w-full print:top-0 print:fixed print:w-[220mm] print:max-w-[220mm]', children: props.header }), _jsxs("table", { className: 'w-full', children: [_jsx("thead", { className: 'print:table-header-group hidden', children: _jsx("tr", { children: _jsx("td", { children: _jsx("div", { 
                                    // using style because arbitrary class names are not allowed in print media
                                    style: { height: `${props.headerHeightPx}px` }, children: "\u00A0" }) }) }) }), _jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { children: _jsx("div", { className: 'print:w-[220mm] print:max-w-[220mm]', children: _jsx(PrintLayoutContext.Provider, { value: { headerHeightPx: props.headerHeightPx }, children: props.content }) }) }) }) })] })] });
};
export const PrintLayoutPageBreak = (props) => {
    return _jsx("div", { className: `${props.className ?? ''} print:break-before-page` });
};
// TODO: switch proposal and report rendering to this component
export const PrintLayoutPaginateBlocks = (props) => {
    return _jsx(_Fragment, { children: props.blocks.map((block, index) => (_jsxs("div", { children: [_jsx(PrintLayoutPageBreak, {}), block] }, index))) });
};
