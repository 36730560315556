import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// We can't use <ul> or <ol> because they have a lot of default styling that we don't want
// e.g. offset between the bullet and the text, padding, etc.
// so we're using a flexbox container with custom styling instead
export const HLRListBlock = ({ items, isNumbered, className, gapSize = 2 }) => {
    if (items.length === 0)
        return _jsx(_Fragment, {});
    // gap between the bullet and the text
    const gapClass = 'gap-' + gapSize;
    // item width class for make the bullets/items column equal width
    const itemWidthClass = 'w-' + (gapSize + 1);
    return (_jsx("div", { className: 'w-full flex flex-col', children: items.map((item, idx) => (_jsxs("div", { className: `${gapClass} flex items-start justify-start`, children: [isNumbered && _jsxs("div", { className: `inline-block ${className} ${itemWidthClass}`, children: [idx + 1, "."] }), !isNumbered && _jsx("div", { className: `${className} ${itemWidthClass}`, children: "\u2022" }), _jsx("div", { className: className, children: item })] }, idx))) }));
};
