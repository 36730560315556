import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { floorCeilingTypeNameMap } from '../floor_attributes_block';
import { Info } from '../../../../components/buttons/info';
export const FloorTypeSelector = ({ floorMaterial, setFloorMaterial, survey }) => {
    return (_jsx(_Fragment, { children: _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Floor type', size: 'SM', info: FloorTypeInfo }), input: _jsx(RadioGroup, { items: [
                    {
                        name: floorCeilingTypeNameMap['ground-floor'],
                        onClick: () => setFloorMaterial(survey.default_materials.groundFloor),
                        variant: floorMaterial.applicable_to === 'ground-floor' ? 'ACTIVE' : 'DEFAULT'
                    },
                    {
                        name: floorCeilingTypeNameMap['intermediate-floor-and-ceiling'],
                        onClick: () => setFloorMaterial(survey.default_materials.intermediateFloorAndCeiling),
                        variant: floorMaterial.applicable_to === 'intermediate-floor-and-ceiling' ? 'ACTIVE' : 'DEFAULT'
                    },
                    {
                        name: floorCeilingTypeNameMap['exposed-floor'],
                        onClick: () => setFloorMaterial(survey.default_materials.exposedFloor),
                        // Default exposed floor won't be set for surveys from before 25th September 2024 so fallback first on the first exposed floor material
                        variant: floorMaterial.applicable_to === 'exposed-floor' ? 'ACTIVE' : 'DEFAULT'
                    }
                ] }) }) }));
};
export const FloorTypeInfo = _jsx(Info, { infoModalHeader: 'Floor type', infoModalBody: 'Used to determine the temperature on the other side of the floor and the material options for the floor. ' +
        'Set the floor type to "Exposed floor" if the floor is above an adjoining property or unheated space. ' });
