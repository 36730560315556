import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CEILING_SHAPE_TYPES, CEILING_TYPES, FLUE_TYPES, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL, ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M } from '../../code/models/room';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { RadiatorPage } from './radiator';
import { TabGroup } from '../../components/content_display/tab';
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll';
import { ROOM_TYPES } from './constants';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { getOtherSideTempCeiling, getOtherSideTempFloor, getRoomACH, getRoomTemp, getThermalBridgingUValueAdditionWPerM2K } from '../../code/models/heat_loss';
import { Icon } from '../../components/buttons/icon';
import { getEmitterSizeName, getEmitterTypeName } from '../../code/models/radiator';
import { DEFAULT_SURVEY_RADIATOR, DEFAULT_SURVEY_ROOFLIGHT, DEFAULT_SURVEY_SECONDARY_EMITTER, DEFAULT_SURVEY_UNDERFLOOR } from '../../code/survey_defaults';
import { ClickableCard } from '../../components/content_display/card';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { MaterialInputField } from './materials/material_input_field';
import { RooflightPage } from './rooflight';
import { getRooflightMaterialUValue, getRooflightUValue, getRoofligthUValuePitchCorrection } from '../../code/models/rooflight';
import { SelectRadiatorModelInner } from './design/pages/radiator_model_selector';
import { getRoomPostfix } from './add_room';
import { getDefaultUnderfloorHeating } from '../../code/models/underfloor_heating';
import { UnderfloorEmitter } from './underfloor/underfloor_emitter';
import { getDefaultFloorMaterialForFloor } from '../../code/models/floor';
import { BottomSheetHeader } from '../../components/containers/bottom_sheet_header';
import { SecondaryHeatingPage } from './secondary_heating_page';
import { Select } from '../../components/inputs_and_selections/select';
import { ListItem } from '../../components/content_display/list_item';
import { validateH1LessThanH2, validateIsPositiveNumber, validateW1LessThanW2 } from '../../code/validators';
import { calculateSimplifiedWallLengths } from './floor/code/utils';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { OtherSideTempInput } from './wall';
import { FloorTypeSelector } from './floor/floor_canvas/floor_type_selector';
import { Info } from '../../components/buttons/info';
import { CeilingTypeInfo } from './floor/floor_attributes_block';
import { XCircle, Trash, Plus } from 'lucide-react';
export const RoomPage = ({ survey, setSurvey, room, floor, setRoom, files, setFiles, page, materials, setMsProps, customRadiatorModels, setCustomRadiatorModels, allRadiatorModels, design, groundTempC, designTempC, setFlowTemp, minFlowTemp, maxFlowTemp, setHeader, currentRooflight, setCurrentRooflight, companyUuid, onBack, setPage }) => {
    const roomImages = room.images.map(x => files.find(y => y.uuid === x.image_uuid));
    const ceilingType = CEILING_TYPES.find(x => x.uuid === room.ceiling_type_uuid);
    const roomTempC = getRoomTemp(room, survey);
    const [currentRadiator, setCurrentRadiator] = useState();
    const [ceilingTypeCategory, setCeilingTypeCategory] = useState(ceilingType?.category_uuid ?? 'Flat');
    useEffect(() => {
        if (!currentRadiator)
            return;
        // handle auto-save only for Radiators
        if (currentRadiator.emitter_type !== 'RADIATOR' || !currentRadiator.uuid)
            return;
        // if Emitter type is Radiator AND user has not selected a radiator model, then do not save the radiator
        if (currentRadiator.radiator_type_uuid === undefined)
            return;
        saveRadiator();
    }, [currentRadiator]);
    const deleteImage = (imageUUID) => {
        setRoom({ ...room, images: room.images.filter(x => x.image_uuid !== imageUUID) });
    };
    const saveRooflight = () => {
        setRoom({
            ...room,
            rooflights: currentRooflight?.uuid
                ? room.rooflights.map(x => x.uuid === currentRooflight.uuid ? currentRooflight : x) // edit existing
                : [...room.rooflights, { ...currentRooflight, uuid: crypto.randomUUID() }] // add new
        });
        onBack();
    };
    const deleteRooflight = (id) => setRoom({ ...room, rooflights: room.rooflights.filter(x => x.uuid !== id) });
    const initNewRooflight = () => setCurrentRooflight({
        ...DEFAULT_SURVEY_ROOFLIGHT,
        material: survey.default_materials?.window
    });
    const saveRadiator = () => {
        const updated = currentRadiator?.uuid
            ? { ...currentRadiator, updated_at: new Date().getTime() }
            : { ...currentRadiator, uuid: crypto.randomUUID(), created_at: new Date().getTime(), updated_at: new Date().getTime() };
        const isExists = room.radiators.find(x => x.uuid === currentRadiator?.uuid);
        const roomRads = isExists
            ? room.radiators.map(x => x.uuid === currentRadiator?.uuid ? updated : x)
            : [...room.radiators, updated];
        setRoom({ ...room, radiators: roomRads });
        // If default radiator values are not set on the survey level then set them from the first radiator added
        if (currentRadiator?.emitter_type === 'RADIATOR' && !survey.existing_system_radiator_pipework_uuid && currentRadiator?.pipe_model_uuid) {
            setSurvey({ ...survey, existing_system_radiator_pipework_uuid: currentRadiator.pipe_model_uuid });
        }
        // keep the onBack() logic only for UFH and Secondary heating.
        // for Radiators, we'll not use it as they are created in another place and onBack is managed by different code
        if (!currentRadiator?.uuid)
            onBack();
    };
    const deleteRadiator = (id) => {
        const newRoom = { ...room, radiators: room.radiators.filter(x => x.uuid !== id) };
        const newFloor = { ...floor, rooms: floor.rooms.map(x => x.uuid === newRoom.uuid ? newRoom : x) };
        setSurvey({
            ...survey,
            floors: survey.floors.map(x => x.uuid === newFloor.uuid ? newFloor : x),
            designs: survey.designs.map(x => ({ ...x, radiators: x.radiators.filter(y => y.replaces_uuid !== id) }))
        });
    };
    const radiatorsHydrated = room.radiators.map(x => ({
        ...x,
        radiator_type: x.emitter_type === 'RADIATOR' ? allRadiatorModels.find(y => y.uuid === x.radiator_type_uuid) : undefined
    }));
    // Get last radiator model used in the survey so can default next radiator model to this
    const surveyRads = survey.floors
        .flatMap(x => x.rooms)
        .flatMap(x => x.radiators)
        .filter(x => x.emitter_type === 'RADIATOR');
    // NB! using `as any` here to make a trick with Linter.
    // It changes code and removes `as SurveyRadiator` thinking that it's not required.
    // Code does not work after that because below it expects some attributes from the SurveyRadiator. So `as any` prevents this behaviour.
    const lastRadiator = surveyRads
        .sort((a, b) => (b.updated_at ?? 0) - (a.updated_at ?? 0))[0];
    const lastRadiatorModelUUID = (lastRadiator)?.radiator_type?.uuid;
    const lastRadiatorModel = allRadiatorModels.find(x => x.uuid === lastRadiatorModelUUID);
    const defaultUnderfloorHeating = getDefaultUnderfloorHeating({
        allPreviousUfh: survey.floors
            .flatMap(x => x.rooms)
            .flatMap(x => x.radiators)
            .filter(x => x.emitter_type === 'UNDERFLOOR'),
        fallbackDefaultUfh: DEFAULT_SURVEY_UNDERFLOOR,
        room,
        manifolds: survey.manifolds
    });
    useEffect(() => {
        if (page === 'CHOOSE_EMITTER')
            setHeader(_jsx(BottomSheetHeader, { title: 'Choose emitter type', goBack: onBack }));
        else if (page === 'RADIATOR_MODEL')
            setHeader(_jsx(BottomSheetHeader, { title: 'Choose a radiator', goBack: onBack }));
        else if (page === 'RADIATOR')
            setHeader(_jsx(BottomSheetHeader, { goBack: onBack, onBack: () => setCurrentRadiator(undefined), title: 'Radiator' }));
        else if (page === 'ADD_CUSTOM_RADIATOR')
            setHeader(_jsx(BottomSheetHeader, { title: 'Add custom radiator', goBack: onBack }));
        else if (page === 'UNDERFLOOR')
            setHeader(_jsx(BottomSheetHeader, { title: 'Underfloor heating', goBack: onBack, onBack: () => setCurrentRadiator(undefined) }));
        else if (page === 'MANIFOLD')
            setHeader(_jsx(BottomSheetHeader, { title: 'Manifold', goBack: onBack }));
        else if (page === 'ROOFLIGHT')
            setHeader(_jsx(BottomSheetHeader, { goBack: onBack, title: 'Rooflight' }));
        else if (page === 'SLOPED_CEILING')
            setHeader(_jsx(BottomSheetHeader, { title: 'Select sloped ceiling type', goBack: onBack }));
        else if (page === 'SECONDARY_HEATING')
            setHeader(_jsx(BottomSheetHeader, { title: 'Secondary heating details', goBack: onBack }));
        else
            setHeader(_jsx(BottomSheetHeader, { title: room.name, goBack: onBack }));
    }, [page]);
    if ((page === 'UNDERFLOOR' || page === 'MANIFOLD') && currentRadiator && currentRadiator.emitter_type === 'UNDERFLOOR') {
        return _jsx(UnderfloorEmitter, { underfloorHeating: currentRadiator, setUnderfloorHeating: setCurrentRadiator, room: room, floor: floor, survey: survey, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, onSave: saveRadiator, files: files, setFiles: setFiles, design: design, setSurvey: setSurvey, page: page, setPage: setPage, onBack: onBack, companyUUID: companyUuid });
    }
    if ((page === 'RADIATOR' || page === 'ADD_CUSTOM_RADIATOR') && currentRadiator && currentRadiator.emitter_type === 'RADIATOR' && !currentRadiator.radiator_type) {
        // New radiator
        return _jsx(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, defaultType: lastRadiatorModel?.type, defaultHeightMm: lastRadiatorModel?.height_mm, deltaTFlowReturnC: design.delta_t_flow_return_c, roomTemp: roomTempC, flowTemp: design.flow_temp, companyUuid: companyUuid, addRadiator: (rm) => {
                setCurrentRadiator(prev => ({
                    ...prev,
                    uuid: crypto.randomUUID(),
                    radiator_type: rm,
                    radiator_type_uuid: rm.uuid,
                    updated_at: new Date().getTime()
                }));
                // a bit ugly hack: reset pages stack: required to delete "Emitter type" selection page from the stack
                onBack();
                onBack();
                setPage('RADIATOR');
            }, page: page, setPage: setPage, onBack: onBack });
    }
    if ((page === 'RADIATOR' || page === 'RADIATOR_MODEL' || page === 'ADD_CUSTOM_RADIATOR') && currentRadiator?.emitter_type === 'RADIATOR') {
        // Modify existing rad
        return _jsx(RadiatorPage, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, deltaTFlowReturnC: design.delta_t_flow_return_c, flowTemp: design.flow_temp, roomTemp: roomTempC, radiator: currentRadiator, setRadiator: setCurrentRadiator, files: files, setFiles: setFiles, setPage: setPage, page: page, companyUuid: companyUuid, onBack: onBack });
    }
    if (page === 'ROOFLIGHT' && currentRooflight) {
        return _jsx(RooflightPage, { onSave: saveRooflight, rooflight: currentRooflight, setRooflight: setCurrentRooflight, materials: materials, survey: survey, setMsProps: setMsProps, setPage: setPage });
    }
    if (page === 'SECONDARY_HEATING' && currentRadiator?.emitter_type === 'SECONDARY') {
        return _jsx(SecondaryHeatingPage, { secondaryHeating: currentRadiator, setSecondaryHeating: setCurrentRadiator, onSave: () => {
                saveRadiator();
                setPage('ROOM_EMITTERS');
            } });
    }
    if (page === 'CHOOSE_EMITTER') {
        return _jsx("div", { className: 'flex flex-col', children: _jsxs("div", { className: 'flex flex-col divide-y divide-gray-200', children: [_jsx(ListItem, { primaryText: 'Radiator', onClick: () => {
                            setPage('RADIATOR');
                            setCurrentRadiator({
                                ...DEFAULT_SURVEY_RADIATOR,
                                emitter_type: 'RADIATOR',
                                room_uuid: room.uuid,
                                pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                                updated_at: new Date().getTime(),
                                created_at: new Date().getTime()
                            });
                        } }), _jsx(ListItem, { primaryText: 'Underfloor heating', onClick: () => {
                            setPage('UNDERFLOOR');
                            setCurrentRadiator({
                                ...defaultUnderfloorHeating,
                                room_uuid: room.uuid,
                                updated_at: new Date().getTime(),
                                created_at: new Date().getTime()
                            });
                        } }), _jsx(ListItem, { primaryText: 'Secondary heating', onClick: () => {
                            setPage('SECONDARY_HEATING');
                            setCurrentRadiator({
                                ...DEFAULT_SURVEY_SECONDARY_EMITTER,
                                room_uuid: room.uuid,
                                updated_at: new Date().getTime(),
                                created_at: new Date().getTime()
                            });
                        } })] }) });
    }
    if (page === 'SLOPED_CEILING') {
        return _jsx("div", { className: 'flex flex-col', children: _jsx("div", { className: 'grid grid-cols-2 gap-3 p-3', children: CEILING_TYPES.filter(x => x.category_uuid === 'Vaulted').map(x => _jsx(CeilingShapeCard, { ceilingShape: x, onClick: () => {
                        setRoom({ ...room, ceiling_type_uuid: x.uuid });
                        onBack();
                    } }, x.uuid)) }) });
    }
    const thermalBridgingUValueAdditionWPerM2K = getThermalBridgingUValueAdditionWPerM2K(survey);
    return _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col w-full pt-4', children: [_jsx("div", { className: 'flex flex-col gap-2 w-full', children: _jsx("div", { className: 'px-4', children: _jsx(TabGroup, { items: [
                                { name: 'Details', onClick: () => setPage('ROOM_DETAILS'), variant: page === 'ROOM_DETAILS' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Floor', onClick: () => setPage('ROOM_FLOOR'), variant: page === 'ROOM_FLOOR' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Ceiling', onClick: () => setPage('ROOM_CEILING'), variant: page === 'ROOM_CEILING' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Emitters', onClick: () => setPage('ROOM_EMITTERS'), variant: page === 'ROOM_EMITTERS' ? 'ACTIVE' : 'DEFAULT' },
                                { name: 'Photos', onClick: () => setPage('ROOM_PHOTOS'), variant: page === 'ROOM_PHOTOS' ? 'ACTIVE' : 'DEFAULT' }
                            ] }) }) }), _jsxs("div", { className: 'p-5 flex flex-col gap-5', children: [page === 'ROOM_DETAILS' && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Room type" }), _jsx(Select, { options: ROOM_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: room.room_type_uuid, setSelectedKey: (e) => {
                                                const roomType = ROOM_TYPES.find(x => x.uuid === e);
                                                setRoom({ ...room, room_type_uuid: e, name: roomType.name + getRoomPostfix(roomType.uuid, survey) });
                                            } })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Name" }), _jsx(Input, { value: room?.name, setValue: (e) => setRoom(({ ...room, name: e })) })] }), _jsxs("div", { className: 'space-y-2', children: [_jsxs("div", { children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Flues or chimney" }), _jsx("div", { className: 'text-gray-500 text-xs', children: "Do not include closed appliances (e.g. a wood-burning stove that only operates with the door closed) as no additional allowance needs to be made for them." })] }), _jsx(Select, { options: FLUE_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: room.flue_type_uuid, setSelectedKey: (e) => setRoom({ ...room, flue_type_uuid: e }) })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Air changes per hour" }), _jsx(Input, { type: "number", step: 0.1, value: getRoomACH(room, survey).toString(), setValue: (e) => setRoom({ ...room, ach_override: Number(e) }), postfix: _jsx("div", { className: "gap-2.5 flex items-center", children: room.ach_override !== undefined && room.ach_override !== getRoomACH(room, survey, true) && _jsx(Icon, { icon: XCircle, onClick: () => setRoom(({ ...room, ach_override: undefined })), colour: 'text-gray-400' }) }) })] }), _jsxs("div", { className: 'space-y-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Internal temperature" }), _jsx(Input, { type: 'number', step: 0.1, value: roomTempC.toString(), setValue: (e) => setRoom({ ...room, indoor_temp_override_c: Number(e) }), postfix: _jsx("div", { className: "gap-2.5 flex items-center", children: room.indoor_temp_override_c !== undefined && room.indoor_temp_override_c !== getRoomTemp(room, survey, true) &&
                                                    _jsx(Icon, { icon: XCircle, onClick: () => setRoom(({ ...room, indoor_temp_override_c: undefined })), colour: 'text-gray-400' }) }) })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Notes" }), _jsx(TextArea, { value: room.notes.toString(), setValue: (e) => setRoom({ ...room, notes: e }) })] })] }), page === 'ROOM_FLOOR' && _jsxs(_Fragment, { children: [_jsx(FloorTypeSelector, { floorMaterial: room.floor_material ?? getDefaultFloorMaterialForFloor(floor, survey), setFloorMaterial: (material) => {
                                        setRoom({
                                            ...room,
                                            floor_material: material,
                                            overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL // flag floor material as overwritten
                                        });
                                    }, survey: survey }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Floor material', size: 'SM' }), input: _jsx(MaterialInputField, { selectorProps: {
                                            materials,
                                            title: room.floor_material.applicable_to === 'ground-floor' ? 'Ground' : 'Intermediate floor',
                                            surfaceType: room.floor_material.applicable_to,
                                            selectedMaterial: room.floor_material,
                                            ageBand: survey.age_band,
                                            onSelectCallback: (material) => {
                                                setRoom({
                                                    ...room,
                                                    floor_material: material,
                                                    // flag floor material as overwritten
                                                    overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL
                                                });
                                            }
                                        }, setMsProps: setMsProps, setPage: setPage }) }), _jsx(OtherSideTempInput, { otherSideTemp: getOtherSideTempFloor(room.floor_material, designTempC, groundTempC, roomTempC, room.floor_other_side_temp_override_c), override: room.floor_other_side_temp_override_c, setOverride: (e) => setRoom({ ...room, floor_other_side_temp_override_c: e }), info: _jsx(Info, { infoModalHeader: 'Temperature on the other side of the floor', infoModalBody: _jsxs("ul", { className: "list-disc pl-5 mt-2 space-y-2", children: [_jsxs("li", { children: ["For ground floors this depends on the floor material:", _jsxs("ul", { className: "list-disc pl-5 space-y-1", children: [_jsx("li", { children: "For suspended floors it defaults to the design outdoor temperature" }), _jsx("li", { children: "For solid floors it defaults to the mean annual air temperature (based on MIS 3005-D)" })] })] }), _jsx("li", { children: "For intermediate floors it defaults to the room temperature" }), _jsx("li", { children: "For exposed floors it assumes the floor is above an unheated space or adjoining property, so defaults to 10\u00B0C based on the CIBSE Domestic Heating Guide section 3.5.3.3" })] }) }) })] }), page === 'ROOM_CEILING' && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'space-y-2', children: [_jsx(FormLabel, { labelText: 'Ceiling type', size: 'SM', info: CeilingTypeInfo }), _jsx(RadioGroup, { items: [
                                                {
                                                    name: 'Intermediate ceiling',
                                                    onClick: () => setRoom({
                                                        ...room,
                                                        ceiling_material: survey.default_materials.intermediateFloorAndCeiling,
                                                        // flag ceiling material as overwritten
                                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                                    }),
                                                    variant: room.ceiling_material.applicable_to === 'intermediate-floor-and-ceiling' ? 'ACTIVE' : 'DEFAULT'
                                                },
                                                {
                                                    name: 'Roof',
                                                    onClick: () => setRoom({
                                                        ...room,
                                                        ceiling_material: survey.default_materials.roof,
                                                        // flag ceiling material as overwritten
                                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                                    }),
                                                    variant: room.ceiling_material.applicable_to === 'roof' ? 'ACTIVE' : 'DEFAULT'
                                                }
                                            ] })] }), _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Ceiling material', size: 'SM' }), input: _jsx(MaterialInputField, { selectorProps: {
                                            materials,
                                            title: room.ceiling_material.applicable_to === 'roof' ? 'Roof' : 'Intermediate ceiling',
                                            surfaceType: room.ceiling_material.applicable_to,
                                            selectedMaterial: room.ceiling_material,
                                            ageBand: survey.age_band,
                                            onSelectCallback: (material) => {
                                                setRoom({
                                                    ...room,
                                                    ceiling_material: material,
                                                    // flag ceiling material as overwritten
                                                    overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                                });
                                            }
                                        }, setMsProps: setMsProps, setPage: setPage }) }), _jsx(OtherSideTempInput, { otherSideTemp: getOtherSideTempCeiling(room.ceiling_material, designTempC, roomTempC, room.ceiling_other_side_temp_override_c), override: room.ceiling_other_side_temp_override_c, setOverride: (e) => setRoom({ ...room, ceiling_other_side_temp_override_c: e }), info: _jsx(Info, { infoModalHeader: 'Other side temperature', infoModalBody: 'The temperature on the other side of the ceiling. This defaults to the design outdoor temperature for roofs and the room temperature for intermediate ceilings.' }) }), room.ceiling_material?.applicable_to === 'intermediate-floor-and-ceiling' && _jsx(_Fragment, { children: _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Ceiling height', size: 'SM' }), input: _jsx(Input, { type: 'number', value: room.height_m?.toString() ?? '', setValue: (e) => setRoom({
                                                ...room,
                                                height_m: Number(e),
                                                // mark the height as overwritten
                                                overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                            }), postfix: 'm' }) }) }), room.ceiling_material?.applicable_to === 'roof' && _jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-grow', children: [_jsx(FormLabel, { labelText: 'Vaulted ceiling?' }), _jsx(RadioGroup, { items: CEILING_SHAPE_TYPES.map(x => ({
                                                        name: x,
                                                        onClick: () => {
                                                            setCeilingTypeCategory(x);
                                                            setRoom({
                                                                ...room,
                                                                ceiling_type_uuid: x === 'Flat'
                                                                    ? CEILING_TYPES.find(x => x.category_uuid === 'Flat').uuid
                                                                    : CEILING_TYPES.filter(x => x.category_uuid !== 'Flat')[0].uuid,
                                                                height_2_m: room.height_2_m ?? room.height_m, // initialise height 2 to height 1 value to reduce risk of user missing this and entering 0 without meaning to
                                                                //   Set width 2 to the length of the shortest wall in the room
                                                                ceiling_width_2_m: room.ceiling_width_2_m ?? Math.min(...calculateSimplifiedWallLengths(room)),
                                                                ceiling_width_1_m: room.ceiling_width_1_m ?? Math.min(...calculateSimplifiedWallLengths(room))
                                                            });
                                                        },
                                                        variant: ceilingTypeCategory === x ? 'ACTIVE' : 'DEFAULT'
                                                    })) })] }), ceilingTypeCategory !== 'Flat' && _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Shape', size: 'SM' }), input: _jsx(CeilingShapeCard, { ceilingShape: ceilingType, onClick: () => setPage('SLOPED_CEILING') }) }), ceilingTypeCategory === 'Flat' ? _jsx(_Fragment, { children: _jsx(VerticalFormGroup, { formLabel: _jsx(FormLabel, { labelText: 'Ceiling height', size: 'SM' }), input: _jsx(Input, { type: 'number', value: room.height_m?.toString() ?? '', setValue: (e) => setRoom({
                                                        ...room,
                                                        height_m: Number(e),
                                                        // mark the height as overwritten
                                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                                    }), postfix: 'm' }) }) }) : _jsxs("div", { className: 'flex flex-col gap-2', children: [ceilingType.showCeilingWidth && _jsxs("div", { className: 'flex gap-3', children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx(FormLabel, { labelText: 'W1', size: 'SM' }), _jsx(Input, { validator: (e) => validateW1LessThanW2(e, room.ceiling_width_2_m), validateImmediately: true, type: 'number', value: room.ceiling_width_1_m?.toString() ?? '', setValue: (e) => setRoom({ ...room, ceiling_width_1_m: e.length === 0 ? undefined : Number(e) }), postfix: 'm' })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx(FormLabel, { labelText: 'W2', size: 'SM' }), _jsx(Input, { validator: validateIsPositiveNumber, validateImmediately: true, type: 'number', value: room.ceiling_width_2_m?.toString() ?? '', setValue: (e) => {
                                                                        setRoom({
                                                                            ...room,
                                                                            ceiling_width_2_m: e.length === 0 ? undefined : Number(e)
                                                                        });
                                                                    }, postfix: 'm' })] })] }), _jsxs("div", { className: 'flex gap-3', children: [_jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx(FormLabel, { labelText: 'H1', size: 'SM' }), _jsx(Input, { validator: (e) => validateH1LessThanH2(e, room.height_2_m), validateImmediately: true, type: 'number', value: room.height_m?.toString() ?? '', setValue: (e) => setRoom({
                                                                        ...room,
                                                                        height_m: e.length === 0 ? undefined : Number(e),
                                                                        // mark the height as overwritten
                                                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                                                    }), postfix: 'm' })] }), _jsxs("div", { className: 'flex flex-col gap-2 flex-1', children: [_jsx(FormLabel, { labelText: 'H2', size: 'SM' }), _jsx(Input, { validator: validateIsPositiveNumber, validateImmediately: true, type: 'number', value: room.height_2_m?.toString() ?? '', setValue: (e) => setRoom({ ...room, height_2_m: e.length === 0 ? undefined : Number(e) }), postfix: 'm' })] })] })] })] }), room.ceiling_material?.applicable_to === 'roof' && _jsxs("div", { className: 'space-y-2', children: [_jsx(FormLabel, { labelText: 'Rooflights', size: 'SM' }), room.rooflights.length === 0 && _jsx(_Fragment, { children: _jsx(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => {
                                                    initNewRooflight();
                                                    setPage('ROOFLIGHT');
                                                }, children: _jsx("div", { className: 'text-center', children: "No rooflights found" }) }) }), room.rooflights.length !== 0 && _jsx("div", { className: 'divide-y divide-y-gray-200', children: room.rooflights.map(rl => _jsx(ListItem, { primaryText: rl.material?.name ?? '', secondaryText: `
                    ${rl.width_mm}  x  ${rl.height_mm} mm
                     • ${getRooflightMaterialUValue(rl).toFixed(2)}
                     + ${getRoofligthUValuePitchCorrection(room.ceiling_type_uuid).toFixed(2)}
                      ${thermalBridgingUValueAdditionWPerM2K > 0 ? `+ ${thermalBridgingUValueAdditionWPerM2K.toFixed(2)}` : ''}
                    = ${getRooflightUValue(rl, room.ceiling_type_uuid, thermalBridgingUValueAdditionWPerM2K).toFixed(2)} W/m²K'
                  `, onClick: () => {
                                                    setCurrentRooflight(rl);
                                                    setPage('ROOFLIGHT');
                                                }, rightClickableIcon: _jsx(Icon, { icon: Trash, confirmTextHeader: 'Delete rooflight?', onClick: () => { deleteRooflight(rl.uuid); } }) }, rl.uuid)) }), _jsx(Button, { iconLeft: Plus, onClick: () => { initNewRooflight(); setPage('ROOFLIGHT'); }, block: true, colour: 'DARK', className: 'gap-2', children: "Add rooflight" })] })] }), page === 'ROOM_EMITTERS' && _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-2', children: [radiatorsHydrated.length === 0 &&
                                        _jsx(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => setPage('CHOOSE_EMITTER'), children: _jsx("div", { className: 'text-center', children: "No emitters found" }) }), radiatorsHydrated.length > 0 && _jsx("div", { className: 'divide-y divide-y-gray-200', children: radiatorsHydrated.map(x => _jsx(ListItem, { primaryText: getEmitterTypeName(x), secondaryText: getEmitterSizeName(x), onClick: () => {
                                                setCurrentRadiator(x);
                                                setPage(x.emitter_type === 'UNDERFLOOR'
                                                    ? 'UNDERFLOOR'
                                                    : x.emitter_type === 'SECONDARY'
                                                        ? 'SECONDARY_HEATING'
                                                        : 'RADIATOR');
                                            }, rightClickableIcon: _jsx(Icon, { icon: Trash, confirmTextHeader: 'Delete emitter?', onClick: () => { deleteRadiator(x.uuid); } }) }, x.uuid)) }), _jsx(Button, { iconLeft: Plus, onClick: () => setPage('CHOOSE_EMITTER'), block: true, colour: 'DARK', className: 'gap-2', children: "Add emitter" })] }) }), page === 'ROOM_PHOTOS' && _jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-900 font-bold text-sm', children: "Photos" }), _jsx(PhotoScroll, { images: roomImages, addImage: (fileWrapper) => {
                                            fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
                                            fileWrapper && setRoom(({ ...room, images: [...room.images, { uuid: crypto.randomUUID(), entity_uuid: room.uuid, image_uuid: fileWrapper.uuid }] }));
                                        }, deleteImage: (id) => deleteImage(id), companyUUID: companyUuid })] }) })] })] }) });
};
const CeilingShapeCard = ({ ceilingShape, onClick }) => _jsx(ClickableCard, { variant: 'WHITE', onClick: onClick, children: _jsxs("div", { className: 'flex flex-col justify-center items-center gap-2', children: [_jsx("div", { className: 'flex justify-center items-center', children: ceilingShape.img?.('w-32 h-32') }), _jsx("div", { className: "text-center text-xs font-bold", children: ceilingShape.name })] }) }, ceilingShape.uuid);
