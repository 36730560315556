import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Slider } from '../../../components/inputs_and_selections/slider';
export const SliderDemo = () => {
    const [value, setValue] = useState(0);
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(Slider, { value: value, min: 0, max: 100, onChange: setValue, className: '', step: 10 })] })] });
};
const componentProps = [
    { name: 'value', description: 'The current value of the slider' },
    { name: 'min', description: 'The minimum value the slider should go to' },
    { name: 'max', description: 'The maximum value the slider should go to' },
    { name: 'onChange', description: 'Do something after the slider moves with the new value' },
    { name: 'className', description: 'Any extra styling or formatting required' },
    { name: 'step', description: 'The increment each step of the slider should take when moving' }
];
const codeBlock = `const [value, setValue] = useState(0)

<Slider
  value={value}
  min={0}
  max={100}
  onChange={setValue}
  className=''
  step={10}
/>
`;
