import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
export const BottomSheetHeaderDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx(BottomSheetHeader, { title: 'Hello world' })] })] });
};
const componentProps = [
    { name: 'title', description: 'Title of the header' },
    { name: 'setPages', description: 'Always show back button, by default pop the last page off the stack to get to the previous page' },
    { name: 'onBack', description: 'Any additional state cleanup that should happen when going back' }
];
const codeBlock = `<BottomSheetHeader
  title: 'Hello world'
  setPages: noop
  onBack: noop
/>`;
