import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateCompanySettings } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { SortableList } from '../../../components/sortable_list';
import { Button } from '../../../../../components/buttons/button';
import { Info } from '../../../../../components/buttons/info';
import { RefreshCw } from 'lucide-react';
const SourcesTooltip = () => {
    return _jsxs("div", { className: 'flex flex-col gap-3', children: [_jsx("p", { children: "Drag and drop using the handle on the left of each option to reorder them." }), _jsx("p", { children: "Use the icons on the right to edit and remove options." }), _jsx("p", { children: "Customers will also have the option to select \"Other\" and provide their own response." })] });
};
export const SourcesBlock = ({ company, setCompany }) => {
    const defaultOptions = ['Social media', 'Search engine', 'Word of mouth', 'Trade show'];
    const resetToDefaults = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                sources: defaultOptions
            }
        };
        setCompany(newCompany);
        await updateCompanySettings(newCompany);
    };
    return _jsx(Section, { border: true, title: "Lead source options", children: _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsxs("div", { className: 'flex gap-1 items-center', children: [_jsx("div", { children: "Choose which options customers can choose from when asked \"Where did you hear about us?\"" }), _jsx(Info, { infoModalHeader: 'Lead source configuration', infoModalBody: _jsx(SourcesTooltip, {}) })] }), _jsx(SortableList, { items: company.public_info.sources.map((name, id) => ({ name, id })), setItems: async (items) => {
                        // If the last item is deleted, fill the list with default options
                        if (items.length === 0) {
                            resetToDefaults();
                            return;
                        }
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                sources: items.map((item) => item.name)
                            }
                        };
                        setCompany(newCompany);
                        await updateCompanySettings(newCompany);
                    }, extraActionButtons: [
                        _jsx(Button, { iconLeft: RefreshCw, colour: "LIGHT", onClick: async () => await resetToDefaults(), children: "Reset to defaults" }, 'resetToDefault')
                    ] })] }) });
};
