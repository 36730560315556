import { jsx as _jsx } from "react/jsx-runtime";
import { getCompanyReportEmailTemplate } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerFirstName, TTPlaceholderCustomerLastName, TTPlaceholderViewReport } from '../../../../../code/tiptap_placeholders';
export const ReportEmailTemplateBlock = ({ company, setCompany }) => {
    return _jsx(Section, { border: true, title: "Report email template", children: _jsx("div", { className: 'flex flex-col gap-4', children: _jsx("div", { className: 'space-y-2', children: _jsx(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setCompany({
                            ...company,
                            report_email_template: JSON.stringify(editor.getJSON())
                        });
                    }, placeholders: [
                        TTPlaceholderViewReport,
                        TTPlaceholderCustomerFirstName,
                        TTPlaceholderCustomerLastName,
                        TTPlaceholderCustomerAddress,
                        ...defaultPlaceholders
                    ], content: getCompanyReportEmailTemplate(company.report_email_template || '') }) }) }) });
};
