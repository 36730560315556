import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Caption } from '../components/caption_1';
import { Button } from '../../../components/buttons/button';
import { ColoredBlockWrapper } from '../components/colored_block_wrapper';
import { StepHeader } from '../components/steps_header';
import { SurveyDispatchContext } from '../survey_page';
import { ChevronRightIcon } from 'lucide-react';
export const NoEPCFoundStep = ({ adminEstimate }) => {
    // survey context and dispatch
    const dispatch = useContext(SurveyDispatchContext);
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage
        });
    };
    return _jsxs(_Fragment, { children: [_jsx(StepHeader, { headerText: `We cannot find an energy certificate for ${adminEstimate ? 'the' : 'your'} home` }), _jsx(ColoredBlockWrapper, { color: "blue", children: _jsxs("div", { className: "flex flex-col gap-6", children: [_jsx(Caption, { content: `Please tell us about ${adminEstimate ? 'the property' : 'your home'} in the following steps.` }), _jsx(Button, { size: 'LG', onClick: handleNavigateNextPage, children: _jsxs("div", { className: "flex items-center gap-x-2", children: [_jsxs("div", { children: ["Tell us about ", adminEstimate ? 'the property' : 'your home'] }), _jsx(ChevronRightIcon, { className: "h-6 w-6" })] }) })] }) })] });
};
