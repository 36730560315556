import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyContext } from '../survey_page';
import { StepHeader } from '../components/steps_header';
import { SubHeader } from '../components/sub_header';
import { Accordion } from '../../../components/content_display/accordion';
import { faqRows } from './get_contacts_step';
import { checkIfScottish } from '../../../code/models/address';
import { NextStepsComponent } from '../components/next_steps';
export const ThankYouStep = ({ companyPublicInfo }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    return _jsxs(_Fragment, { children: [_jsx(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "Enquiry Sent \uD83D\uDE4C", captionText: `Thanks ${survey.lead.customer?.first_name}! We'll send you an email with a detailed cost estimate within the next 48 hours. If you have any queries in the meantime, drop us an email at ${companyPublicInfo.notification_email}, or give us a call on ${companyPublicInfo.phone}.` }), _jsx(NextStepsComponent, { isScottish: checkIfScottish(survey.lead.property.postcode), isChecked: false }), _jsxs("div", { className: "w-full flex flex-col justify-start items-start gap-1", children: [_jsx(SubHeader, { content: "Common questions" }), _jsx(Accordion, { rows: faqRows() })] })] });
};
