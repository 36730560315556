import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../../components/buttons/button';
import { noop } from 'lodash';
import { ChevronRight } from 'lucide-react';
export const ButtonDemo = () => {
    return _jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Definition" }), _jsx("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto', children: _jsx("code", { children: codeBlock }) }), _jsx("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm', children: componentProps.map(x => _jsxs("div", { className: 'grid grid-cols-2 py-2', children: [_jsx("div", { className: 'font-bold text-gray-900', children: x.name }), _jsx("div", { children: x.description })] }, x.name)) })] }), _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'text-2xl font-bold text-gray-900', children: "Component" }), _jsx("div", { children: "Sizes" }), _jsxs("div", { className: 'flex justify-start items-end gap-4', children: [_jsx(Button, { onClick: noop, size: 'SM', colour: 'DARK', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'MD', colour: 'DARK', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'LG', colour: 'DARK', iconRight: ChevronRight, children: "Hello World" })] }), _jsx("div", { children: "Variants" }), _jsxs("div", { className: 'flex justify-start items-end gap-4', children: [_jsx(Button, { onClick: noop, size: 'MD', colour: 'DARK', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'MD', colour: 'LIGHT', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'MD', colour: 'GRAY', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'MD', colour: 'LOZENGE_DARK', iconRight: ChevronRight, children: "Hello World" }), _jsx(Button, { onClick: noop, size: 'MD', colour: 'LOZENGE_LIGHT', iconRight: ChevronRight, children: "Hello World" })] })] })] });
};
const componentProps = [
    { name: 'onClick', description: 'Action to perform when clicked' },
    { name: 'block', description: 'Should it fill the width of container or not' },
    { name: 'disabled', description: 'Is it disabled' },
    { name: 'size', description: 'SM | MD | LG' },
    { name: 'className', description: 'Any additional styling or positioning required' },
    { name: 'style', description: 'Any additional dynamic styling or positioning required' },
    { name: 'colour', description: 'DARK | LIGHT | GRAY | LOZENGE_LIGHT | LOZENGE_DARK' },
    { name: 'iconLeft', description: 'What icon should display to the left of the text' },
    { name: 'iconRight', description: 'What icon should display to the right of the text' },
    { name: 'confirmText', description: 'If populated, show a confirmation modal with the specified text before actioning the onClick' }
];
const codeBlock = `<Button
    onClick={noop}
    block={true}
    disabled={false}
    size='MD'
    className=''
    style={undefined}
    colour='DARK'
    iconLeft={undefined}
    iconRight={ChevronRight}
    confirmText=''
>Hello World</Button>`;
