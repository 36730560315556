import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { HLRContext } from './heatloss_report_page';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { ClickableCard } from '../../../components/content_display/card';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { sum } from 'lodash';
import { getRoomWatts } from '../../../code/models/heat_loss';
import { HLRFloorplanBlock } from './partials/hlr_floorplan';
export const HLRFloorHeatlossPage = ({ floor }) => {
    const hlrContext = React.useContext(HLRContext);
    const rooms = floor.rooms.flatMap(y => {
        return {
            name: y.name,
            heatLoss: Math.round(getRoomWatts(y, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey))
        };
    });
    const totalHeatLossW = sum(rooms.map(x => x.heatLoss));
    return (_jsxs("div", { className: "flex-col gap-6 flex", children: [_jsx(HLRSubheaderBlock, { section: "Heat loss by floor", title: floor.name }), _jsxs(ClickableCard, { variant: 'GREY', children: [_jsxs("div", { className: "gap-3 flex justify-between", children: [_jsx("div", { className: "text-gray-900 text-lg font-bold ", children: "Heat loss by room" }), _jsxs("div", { className: "text-lg", children: [totalHeatLossW, " W"] })] }), _jsx(ProgressChart, { items: rooms.map(x => ({
                            name: x.name,
                            value: x.heatLoss
                        })), total: totalHeatLossW })] }), _jsx(HLRFloorplanBlock, { floor: floor }), _jsxs("div", { className: "flex-col flex", children: [_jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "Rads: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "number of radiators" })] }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "UFH: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "underfloor heating in room" })] }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "\u00B0C: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "room temperature" })] }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "ACH: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "air changes per hour" })] }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "W: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "number of windows" })] }), _jsxs("div", { className: "", children: [_jsx("span", { className: "text-gray-900 text-xs tracking-tight font-bold", children: "D: " }), _jsx("span", { className: "text-gray-600 text-xs tracking-tight", children: "number of doors" })] })] })] }));
};
