import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { validatePasswordResetToken, updateUserPassword } from '../../code/models/auth';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { validatePassword, validatePasswordMatch } from '../../code/validators';
export const NewPasswordPage = ({ navigateTo }) => {
    // component specific
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    useEffect(() => {
        // get query params
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
            return;
        }
        setToken(token);
        const result = async () => {
            const valid = await validatePasswordResetToken(token);
            if (!valid) {
                navigateTo('/login');
            }
        };
        result();
    }, []);
    const handleSubmit = async (event) => {
        if (token.length === 0 || password.length === 0 || passwordRepeat.length === 0) {
            return;
        }
        if (password !== passwordRepeat) {
            return;
        }
        await updateUserPassword(token, password);
        navigateTo('/login');
    };
    const isValid = [validatePassword(password), validatePasswordMatch(password, passwordRepeat)].every(x => x.value);
    return _jsxs("div", { className: "flex flex-col items-center justify-center gap-16 max-w-80 mx-auto", children: [_jsx("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: SpruceLogoBlack }), _jsxs("div", { className: "flex flex-col gap-4 w-full", children: [_jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "text-slate-600 text-lg font-bold", children: "New password" }), _jsx(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPassword, type: "password", value: password, placeholder: 'Enter password', validator: () => validatePassword(password) })] }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "text-slate-600 text-lg font-bold", children: "Repeat password" }), _jsx(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPasswordRepeat, type: "password", value: passwordRepeat, placeholder: 'Repeat password', validator: () => validatePasswordMatch(password, passwordRepeat) })] }), _jsx(Button, { disabled: !isValid, onClick: handleSubmit, children: "Set password" })] })] });
};
