import { setError } from '../../components/indicators_and_messaging/toast';
import { apiUrl, axiosGetV2, axiosPatch, axiosPatchV2, axiosPost, axiosPostV2, client } from '../axios';
import { EpcScotlandSchema } from './epc_scotland';
import { DEFAULT_PROPERTY, PropertySchema } from './property';
import { AuthSDK } from '../utils/auth_provider';
import { EPCRecommendationSchema, EPCSchema } from './epc';
import { QuoteItemSchema } from '../calculate_quote';
import { z } from 'zod';
import { parseWithZod } from '../zod';
import { StatusSchema } from '../stage';
import { JobEventSchema } from './job_event';
import { UserJobWithUserPublicProfileSchema } from './user';
import { ProjectTypeSchema } from './project_type';
import { BusApplicationStatus, MCSApplicationStatus } from './paperwork';
export const CustomerDetailsSchema = z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    source: z.string().optional(),
    sourceCompany: z.string().optional()
});
export const JobInvitedCompanySchema = z.object({
    company_uuid: z.string().uuid(),
    job_uuid: z.string().uuid(),
    company_role_uuid: z.string().uuid(),
    company: z.object({
        uuid: z.string().uuid(),
        name: z.string(),
        subdomain: z.string()
    })
});
export const EPCChangesSchema = z.object({
    insulation: z.boolean(),
    loftConversion: z.boolean(),
    extension: z.boolean(),
    replacedWindows: z.boolean(),
    other: z.boolean(),
    noChanges: z.boolean()
});
export const HomeEnergyScotlandStatusSchema = z.object({
    scottishGrantIsInTouch: z.string(), // currently stored as "Yes" or "No". TODO later: change to boolean
    scottishGrantReason: z.string()
});
export const LeadSchema = z.object({
    // common fields
    uuid: z.string(),
    company_uuid: z.string(),
    companySubdomain: z.string().optional(),
    createdAt: z.coerce.date(),
    status_uuid: z.string(),
    order_by: z.number(),
    status: StatusSchema.optional(),
    job_name: z.string().optional(),
    projectType: ProjectTypeSchema,
    //   main field for the property
    property: PropertySchema,
    //   fields for the lead
    customer: CustomerDetailsSchema.optional(),
    installer_notes: z.string().optional(),
    heatPumpQuote: z.boolean().optional(), // variable about whether the lead is for a heat pump. Not used currently.
    heatPumpReason: z.string().optional(),
    notes: z.string().optional(),
    whenHeatPump: z.string().optional(),
    //   fields for the EPC data
    epcId: z.string().optional(),
    epc_scotland_id: z.string().optional(),
    epc_scotland: EpcScotlandSchema.optional(),
    epcData: EPCSchema.optional(),
    epc_recommendations: z.array(EPCRecommendationSchema).optional(),
    scottishGrant: HomeEnergyScotlandStatusSchema.optional(),
    scottish_rural: z.boolean().optional(),
    //   EPC changes made by the user, initially all false
    epcChanges: EPCChangesSchema.optional(),
    heat_pump_uuid: z.string().optional(),
    hot_water_cylinder_uuid: z.string().optional(),
    estimate_quote_items: z.array(QuoteItemSchema).optional(),
    flow_temperature_c: z.number().optional(),
    report_sections: z.number().optional(),
    report_recipients: z.string().optional(),
    quote_recipients: z.string().optional(),
    proposal_recipients: z.string().optional(),
    job_users: z.array(UserJobWithUserPublicProfileSchema).optional(),
    job_invited_companies: z.array(JobInvitedCompanySchema).optional(),
    history: z.array(JobEventSchema).optional(),
    // BUS grant voucher
    bus_application_status: z.nativeEnum(BusApplicationStatus).optional().nullable(),
    bus_voucher_id: z.string().optional().nullable(),
    bus_voucher_date: z.coerce.date().optional().nullable(),
    // MCS application
    mcs_application_status: z.nativeEnum(MCSApplicationStatus).optional().nullable(),
    total_quote_amount_override: z.number().optional().nullable(),
    mcs_certificate_number: z.string().optional().nullable(),
    mcs_certificate_url: z.string().optional().nullable()
});
// A limited subset of the lead schema that is used in the list of leads
export const LeadListItemSchema = z.object({
    uuid: z.string().uuid(),
    job_name: z.string().optional(),
    company_uuid: z.string().uuid(),
    createdAt: z.coerce.date(),
    status_uuid: z.string().optional(),
    order_by: z.number(),
    status: StatusSchema.optional(),
    property: z.object({
        address: z.string().optional(),
        postcode: z.string().optional()
    }),
    customer: z.object({
        first_name: z.string().optional(),
        last_name: z.string().optional(),
        email: z.string().optional(),
        phone: z.string().optional()
    }).optional(),
    company: z.object({
        uuid: z.string().uuid(),
        name: z.string()
    }).optional()
});
export const getAddressIncludingPostcode = (lead) => {
    return [lead.property.address, lead.property.postcode].filter(x => !!x).join(', ');
};
export const DEFAULT_LEAD = {
    uuid: '',
    company_uuid: '',
    createdAt: new Date(),
    property: DEFAULT_PROPERTY,
    projectType: 'retrofit',
    epcId: '',
    epc_scotland_id: '',
    status_uuid: '',
    order_by: 0,
    epcChanges: {
        insulation: false,
        loftConversion: false,
        extension: false,
        replacedWindows: false,
        other: false,
        noChanges: false
    }
};
// Mapping of override keys to their corresponding default keys
export const DEFAULT_KEYS = {
    designTempOverride: 'designTempDefault',
    internalTempOverride: 'internalTempDefault',
    airChangeOverride: 'airChangesDefault',
    externalWallUValueOverride: 'externalWallDefault',
    partyWallUValueOverride: 'partyWallDefault',
    windowsUValueOverride: 'windowDefault',
    floorUValueOverride: 'floorDefault',
    roofUValueOverride: 'roofDefault'
};
export const leadWithNewOverride = (lead, defaults, key, value) => {
    // If we're dealing with a key that has a corresponding default value, we need to handle it differently
    if (DEFAULT_KEYS[key]) {
        // If the value is undefined, we want to remove the override (it will be set to the default value by the calculateEstimate function)
        // If the value is the same as the corresponding default value, we want to remove the override (same as above)
        // Otherwise, we want to set the override to the new value.
        // All the defaults are currently numbers
        const defaultValue = defaults[DEFAULT_KEYS[key]];
        // Should be true that any values that are being compared to the defaults are numbers already, but coerce in case so the comparison is correct
        const numberValue = typeof value === 'string' ? parseFloat(value) : value;
        // Critical to check that value is not undefined here rather than just value ?
        // Using just value ? means that when value is 0 the override gets set to undefined, whereas really 0 is a valid override
        // and more importantly, when typing 0.5 you have to type 0. first and if you just test on value then this acts as
        // if the default has been cleared as you type, which is really jumpy and horrid
        const newValue = value !== undefined ? (numberValue === defaultValue) ? undefined : numberValue : undefined;
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: newValue
                }
            }
        };
    }
    else {
        // We don't need to worry about defaults here so we can just set the override to the new value.
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: value
                }
            }
        };
    }
};
export const duplicateLead = async ({ lead, name }) => {
    const result = await axiosPostV2(`jobs/${lead.uuid}/duplicate`, { name, company_uuid: lead.company_uuid });
    return { ...result.data.attributes, uuid: result.data.uuid };
};
export const postLead = async ({ lead, adminEstimate }) => {
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['version', 'id', 'extra', 'houseNotes', 'epcData'].includes(e[0])));
    const postRequest = { ...attributes, company_uuid: lead.company_uuid };
    return await axiosPost(`jobs${adminEstimate ? '?admin_estimate=true' : ''}`, postRequest, 'leads');
};
export const patchLead = async ({ uuid, lead, companyUUID }) => {
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['epcData', 'epc_scotland', 'lead_line_items', 'status', 'projectType'].includes(e[0])));
    const patchRequest = { ...attributes, company_uuid: companyUUID };
    await axiosPatch(`jobs/${uuid}${companyUUID ? `?company_uuid=${companyUUID}` : ''}`, patchRequest, uuid.toString(), 'leads');
};
export const bulkPatchLead = async ({ leads, companyUUID }) => {
    const data = leads.map(x => ({
        job_uuid: x.uuid,
        status_uuid: x.status_uuid,
        order_by: x.order_by
    }));
    await axiosPatchV2('jobs/bulk', data, { params: { company_uuid: companyUUID } });
};
export const getLeadsList = async (company_uuid) => {
    const result = await client.get(`${apiUrl}jobs${company_uuid ? `?company_uuid=${company_uuid}` : ''}`, {
        headers: {
            'x-auth-token': AuthSDK.getToken()
        }
    });
    return result.data;
};
export const getSurvey = (uuid, companyUUID) => axiosGetV2(`companies/${companyUUID}/surveys/${uuid}`);
export const getImages = (uuids, jobUUID, companyUUID) => axiosPostV2(`companies/${companyUUID}/jobs/${jobUUID}/files`, uuids);
export const getLeads = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        const parsedResult = result.data.data.map(x => parseWithZod(LeadSchema, ({ ...x.attributes, uuid: x.uuid })));
        return parsedResult;
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLead = async (leadUUID) => {
    try {
        const result = await client.get(`${apiUrl}jobs/${leadUUID}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        const parsedResult = parseWithZod(LeadSchema, ({ ...result.data.data.attributes, uuid: result.data.data.uuid }));
        return parsedResult;
    }
    catch (e) {
        console.error('Error loading Lead', e);
        return undefined;
    }
};
export const sendEstimate = async ({ leadUUID, companyUUID, emailHTML }) => {
    try {
        await axiosPostV2(`jobs/${leadUUID}/send_estimate`, { email_html: emailHTML }, { params: { company_uuid: companyUUID } });
    }
    catch (e) {
        setError(e);
    }
};
export const sendNudgeEmail = async ({ leadUUID, estimateUUID, companyUUID, emailHTML }) => {
    try {
        await axiosPostV2(`jobs/${leadUUID}/send_nudge`, { email_html: emailHTML, quoteUUID: estimateUUID }, { params: { company_uuid: companyUUID } });
    }
    catch (e) {
        setError(e);
    }
};
export const sendDeclined = async ({ leadUUID, companyUUID, message }) => {
    try {
        await axiosPostV2(`jobs/${leadUUID}/send_declined`, { email_html: message }, { params: { company_uuid: companyUUID } });
    }
    catch (e) {
        setError(e);
    }
};
export const addUserToJob = async ({ companyUuid, jobUuid, email, roleUuid, message }) => {
    try {
        const response = await client.post(`${apiUrl}jobs/${jobUuid}/users`, { email, role_uuid: roleUuid, company_uuid: companyUuid, message }, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return response.data;
    }
    catch (e) {
        console.error('Error adding user to job', e);
        return undefined;
    }
};
export const removeUserFromJob = async ({ companyUuid, jobUuid, jobUserUuid }) => {
    try {
        await client.delete(`${apiUrl}jobs/${jobUuid}/users/${jobUserUuid}`, { headers: { 'x-auth-token': AuthSDK.getToken() }, params: { company_uuid: companyUuid } });
        return true;
    }
    catch (e) {
        console.error('Error removing user from job', e);
        return false;
    }
};
export const updateUserJobRole = async ({ companyUuid, jobUuid, jobUserUuid, roleUuid }) => {
    try {
        const response = await client.patch(`${apiUrl}jobs/${jobUuid}/users/${jobUserUuid}`, { role_uuid: roleUuid, company_uuid: companyUuid }, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return response.data;
    }
    catch (e) {
        console.error('Error updating user job role', e);
        return undefined;
    }
};
export const addCompanyToJob = async ({ companyUuid, jobUuid, invitedCompanyUuid, roleUuid, message }) => {
    try {
        const response = await client.post(`${apiUrl}jobs/${jobUuid}/companies`, { invited_company_uuid: invitedCompanyUuid, role_uuid: roleUuid, company_uuid: companyUuid, message }, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return response.data;
    }
    catch (e) {
        console.error('Error adding user to job', e);
        return undefined;
    }
};
export const removeCompanyFromJob = async ({ companyUuid, jobUuid, invitedCompanyUuid }) => {
    try {
        await client.delete(`${apiUrl}jobs/${jobUuid}/companies/${invitedCompanyUuid}`, { headers: { 'x-auth-token': AuthSDK.getToken() }, params: { company_uuid: companyUuid } });
        return true;
    }
    catch (e) {
        console.error('Error removing user from job', e);
        return false;
    }
};
export const updateCompanyJobRole = async ({ companyUuid, jobUuid, invitedCompanyUuid, roleUuid }) => {
    try {
        const response = await client.patch(`${apiUrl}jobs/${jobUuid}/companies/${invitedCompanyUuid}`, { role_uuid: roleUuid }, { headers: { 'x-auth-token': AuthSDK.getToken() }, params: { company_uuid: companyUuid } });
        return response.data;
    }
    catch (e) {
        console.error('Error updating user job role', e);
        return undefined;
    }
};
