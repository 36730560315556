import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Loader } from '../components/indicators_and_messaging/loader';
import { getProposalPublicAPI, setProposalOpenedAPI } from '../code/models/proposal';
import { createJobEvent, JobEventType } from '../code/models/job_event';
import { ProposalPage } from './heat_loss/proposal/proposal_page';
export const ProposalPublicPage = (props) => {
    const [proposal, setProposal] = useState();
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    useEffect(() => {
        let timer;
        const asyncCall = async () => {
            const result = await getProposalPublicAPI(props.proposalUUID, props.companyPublicInfo.uuid);
            if (result) {
                setProposal(result);
            }
            if (!isInstallerClick && result?.is_opened === false) {
                // When we send this estimate to customers, their email clients are very likely to render/open (prefetch) the page, triggering the update to 'Opened' status
                // To avoid this, we wait for 3 seconds before sending the "Opened" status change, so be (pretty) certain that it's a human opening the page.
                timer = setTimeout(async () => {
                    await setProposalOpenedAPI(props.proposalUUID, props.companyPublicInfo.uuid);
                    createJobEvent({
                        event_type: JobEventType.enum.ProposalOpened,
                        company_uuid: props.companyPublicInfo.uuid,
                        job_uuid: result.lead_uuid,
                        extra_data: {
                            proposal_uuid: props.proposalUUID
                        }
                    });
                }, 3000);
            }
        };
        asyncCall();
        return () => {
            if (timer)
                clearInterval(timer);
        };
    }, []);
    if (!proposal) {
        return (_jsx("div", { className: "flex flex-col items-center justify-center mt-10 mx-auto", children: _jsx(Loader, {}) }));
    }
    const quoteSnapshot = proposal.snapshot.quote;
    const reportSnapshot = proposal.snapshot.report;
    return _jsxs(_Fragment, { children: [_jsx(ProposalPage, { proposal: proposal, setProposal: setProposal, quoteSnapshot: quoteSnapshot, reportSnapshot: reportSnapshot, companyPublicInfo: props.companyPublicInfo }), _jsx("div", { id: 'pdf-ready' })] });
};
