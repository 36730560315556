import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { Section } from '../../components/section';
import { InfoCard } from '../../components/info_card';
import { convertLatLngListToLatLongLiteral } from '../../../../../code/geocoding';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { Heading } from '../../../../../components/content_display/heading';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands';
import { contextSensitiveFormatDate, formatDate } from '../../../../../code/format_date';
import { Button } from '../../../../../components/buttons/button';
import { Info } from '../../../../../components/buttons/info';
import { SendDeclinedModalBlock } from '../components/send_declined_modal_block';
import { SendEstimateModalBlock } from '../components/send_estimate_modal_block';
import { AdminContext } from '../../../admin_layout';
import { estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { AlertCircle, AlertCircleIcon, Ban, CheckCircleIcon, Edit, Eye, Send, XCircleIcon } from 'lucide-react';
import { Snackbar } from '../../../../../components/indicators_and_messaging/snackbar';
import { BUILD_REG_AGE_BANDS, BUILD_REG_VALUE_TYPES } from '../../../../../code/models/building_regs';
import { PROJECT_TYPES } from '../../../../../code/models/project_type';
import { estimateStoreysFromPropertyType } from '../../../../../code/models/property_type';
export const BUSGrantInfo = ({ lead, notBusGrantEligible, busGrantEligibleReasons }) => {
    return _jsx(Info, { infoModalHeader: 'BUS grant eligibility', infoModalBody: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsxs("div", { className: 'space-y-2', children: [_jsxs("div", { className: 'text-gray-900 font-semibold', children: ["This property is ", notBusGrantEligible ? 'not currently' : 'likely to be', " eligible for a BUS grant."] }), _jsxs("div", { className: 'text-gray-600 text-sm', children: [lead.epcData ? _jsxs(_Fragment, { children: ["The latest EPC we have access to was lodged on ", _jsx("span", { className: 'font-bold', children: formatDate(lead.epcData.inspectionDate.toString()) }), "."] }) : '', " Please note that EPC data only updates monthly, typically during the final working week of the month."] })] }), lead.epcData?.lmkKey
                    ? _jsxs("div", { children: [_jsx("div", { className: 'space-y-2 text-gray-600', children: busGrantEligibleReasons.filter(x => !['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => _jsxs("div", { className: 'flex gap-x-2', children: [x.warning ? _jsx(AlertCircleIcon, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed
                                            ? _jsx(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                            : _jsx(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }), _jsx("div", { children: x.message })] }, i)) }), _jsxs("div", { className: 'mt-5 flex flex-col gap-2', children: [_jsx("div", { className: 'text-gray-900 font-semibold mt-5', children: "Energy efficiency opportunities" }), _jsx("div", { className: 'text-gray-600 text-sm', children: "On the 8th May 2024 the requirement to have no outstanding recommendations for loft or cavity wall insulation was removed. We continue to flag them here for your reference only." }), _jsx("div", { className: 'space-y-2 text-gray-600 mt-2', children: busGrantEligibleReasons.filter(x => ['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => _jsxs("div", { className: 'flex gap-x-2', children: [x.warning ? _jsx(AlertCircle, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed
                                                    ? _jsx(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                                    : _jsx(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }), _jsx("div", { children: x.message })] }, i)) })] })] })
                    : _jsx(Alert, { type: "WARNING", children: "No EPC found for this address. The property will need an EPC before it can be assessed for BUS grant eligibility." })] }) });
};
export const HESGrantInfo = ({ lead, notHesGrantEligible, hesGrantEligibleReasons }) => {
    return _jsx(Info, { infoModalHeader: 'HES grant eligibility', infoModalBody: _jsxs("div", { className: 'flex flex-col gap-4', children: [!lead?.epc_scotland &&
                    _jsxs("div", { className: 'flex flex-col gap-8', children: [_jsxs("div", { className: 'space-y-1', children: [_jsx("div", { className: 'text-gray-900 font-semibold', children: "This property cannot be assessed for a HES grant." }), _jsx("div", { className: 'text-gray-600 text-sm', children: "Please note that EPC data only updates quarterly." })] }), _jsx(Alert, { type: "WARNING", children: "No EPC found for this address so we cannot assess whether loft insulation or cavity wall insulation would be recommended in the post-install EPC." })] }), lead?.epc_scotland &&
                    _jsxs(_Fragment, { children: [_jsxs("div", { className: 'space-y-1', children: [_jsxs("div", { className: 'text-gray-900 font-semibold', children: ["This property ", notHesGrantEligible ? 'is unlikely to be' : 'may be', " eligible for a HES grant."] }), _jsxs("div", { className: 'text-gray-600 text-sm', children: [lead.epc_scotland ? _jsxs(_Fragment, { children: ["Data from the EPC lodged on ", _jsx("span", { className: 'font-bold', children: formatDate(lead.epc_scotland.inspection_date.toString()) }), "."] }) : '', " Please note that EPC data only updates quarterly."] })] }), lead?.epc_scotland?.improvements
                                ? _jsx("div", { className: 'space-y-2 text-gray-600', children: hesGrantEligibleReasons.map((x, i) => _jsxs("div", { className: 'flex gap-x-2', children: [x.hasPassed ? _jsx(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                                : _jsx(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }), _jsx("div", { children: x.message })] }, i)) })
                                : _jsx(Alert, { type: "WARNING", children: "No EPC found for this address. The property will need an EPC before it can be assessed for HES grant eligibility." })] })] }) });
};
export const EnquiryOverview = ({ lead, estimate, navigateTo, setLead, setEstimates, stages, company }) => {
    const adminContext = useContext(AdminContext);
    const [snackbarText, setSnackbarText] = useState();
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const { isScottish, busGrantEligibleReasons, hesGrantEligibleReasons } = estimate;
    const notBusGrantEligible = (!lead.epc_scotland && (!lead.epcData?.lmkKey || busGrantEligibleReasons.some(x => !x.hasPassed)));
    const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed);
    const handleEditEnquiryData = () => {
        const url = `/admin/quotes/${lead.uuid}/estimate/enquiry-data`;
        navigateTo(url);
    };
    const [isEstimateModalVisible, setIsEstimateModalVisible] = React.useState(false);
    const [declineModalVisible, setDeclineModalVisible] = React.useState(false);
    const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data, company);
    const notProvidedPlaceholder = _jsx("p", { className: 'text-light', children: "N/A" });
    const retrofitSummaryRows = [
        { key: 'Built', value: lead.property.houseOverrides?.construction_age_band_uuid ? ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.houseOverrides?.construction_age_band_uuid)?.name : ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)?.name || notProvidedPlaceholder },
        { key: 'Walls', value: [lead.property.houseOverrides?.wallGroup ?? lead.property.wallGroup, lead.property.houseOverrides?.wallType ?? lead.property.wallType].join(', ') || notProvidedPlaceholder },
        { key: 'Windows', value: lead.property.houseOverrides?.windowType ?? (lead.property.windowType || notProvidedPlaceholder) }
    ];
    const newBuildSummaryRows = lead.property.buildingRegs ? [
        // { key: 'New build jurisdiction', value: BUILD_REG_JURISDICTIONS.find(x => x.key === (lead.property.houseOverrides?.buildingRegs?.jurisdiction ?? lead.property.buildingRegs?.jurisdiction))?.label },
        { key: 'Building regs', value: BUILD_REG_AGE_BANDS.find(x => x.key === (lead.property.houseOverrides?.buildingRegs?.ageBand ?? lead.property.buildingRegs?.ageBand))?.label },
        { key: 'Design to', value: BUILD_REG_VALUE_TYPES.find(x => x.key === (lead.property.houseOverrides?.buildingRegs?.valueType ?? lead.property.buildingRegs?.valueType))?.label }
    ] : [];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsxs("div", { className: 'flex gap-4 overflow-x-auto justify-end', children: [_jsx(Button, { colour: "LIGHT", iconLeft: Eye, onClick: () => navigateTo(`/admin/quotes/${lead.uuid}/estimate/preview`), children: "Preview" }), _jsx(Button, { colour: "LIGHT", iconLeft: Ban, onClick: () => setDeclineModalVisible(true), children: "Decline" }), _jsx(Button, { colour: "DARK", iconLeft: Send, onClick: () => setIsEstimateModalVisible(true), disabled: !estimate.heatPump, children: "Send" })] }), _jsxs(Section, { title: "Enquiry overview", controls: _jsx(Button, { size: "SM", iconLeft: Edit, colour: 'LIGHT', onClick: handleEditEnquiryData, children: "Edit enquiry data" }), children: [_jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-5", children: [_jsx(InfoCard, { title: "Received", value: contextSensitiveFormatDate(new Date(lead.createdAt)) }), !isScottish && _jsx(InfoCard, { title: "BUS grant eligibility", value: notBusGrantEligible ? 'Not eligible' : 'Eligible', info: BUSGrantInfo({ lead, notBusGrantEligible, busGrantEligibleReasons }) }), isScottish && _jsx(InfoCard, { title: "HES grant eligibility", value: notHesGrantEligible ? 'Not eligible' : 'Eligible', info: HESGrantInfo({ lead, notHesGrantEligible, hesGrantEligibleReasons }) })] }), _jsxs("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-5", children: [_jsxs("div", { className: "flex flex-col cursor-pointer gap-5", onClick: handleEditEnquiryData, children: [_jsxs("div", { children: [_jsx(Heading, { size: "md", children: "Job details" }), _jsx(SummaryTable, { rows: [
                                                            { key: 'Job name', value: lead.job_name || notProvidedPlaceholder },
                                                            { key: 'Project type', value: PROJECT_TYPES.find(x => x.key === lead.projectType)?.name }
                                                        ] })] }), _jsxs("div", { children: [_jsx(Heading, { size: "md", children: "Contact details" }), _jsx(SummaryTable, { rows: [
                                                            { key: 'Name', value: `${lead.customer?.first_name ?? ''}${lead.customer?.last_name ? ` ${lead.customer.last_name}` : ''}` },
                                                            { key: 'Email', value: lead.customer?.email || notProvidedPlaceholder },
                                                            { key: 'Phone', value: lead.customer?.phone || notProvidedPlaceholder }
                                                        ] })] }), _jsxs("div", { children: [_jsx(Heading, { size: "md", children: "Property details" }), _jsx(SummaryTable, { rows: [
                                                            { key: 'Property type', value: [lead.property.houseOverrides?.propertyType ?? lead.property.propertyType, lead.property.houseOverrides?.builtForm ?? lead.property.builtForm].join(', ') || notProvidedPlaceholder },
                                                            ...(lead.projectType === 'new-build' ? newBuildSummaryRows : retrofitSummaryRows),
                                                            { key: 'Bedrooms', value: lead.property.houseOverrides?.noBedrooms ?? (lead.property.noBedrooms || notProvidedPlaceholder) },
                                                            { key: 'Bathrooms', value: lead.property.houseOverrides?.noBathrooms ?? (lead.property.noBathrooms || notProvidedPlaceholder) },
                                                            { key: 'Floors', value: lead.property.houseOverrides?.noStoreys ?? lead.property.noStoreys ?? estimateStoreysFromPropertyType(lead.property.propertyType) ?? notProvidedPlaceholder },
                                                            { key: 'Floor area', value: _jsxs("span", { children: [lead.property.houseOverrides?.floorArea ?? (lead.property.floorArea || notProvidedPlaceholder), " m", _jsx("sup", { children: "2" })] }) }
                                                        ] })] })] }), _jsx("div", { children: latLng
                                            ? _jsx(_Fragment, { children: _jsx(Map, { defaultZoom: 18, className: "h-[420px] w-full rounded overflow-hidden", mapTypeId: "satellite", defaultCenter: latLng, children: _jsx(Marker, { position: latLng }) }) })
                                            : _jsx(Alert, { type: "WARNING", children: "Sorry, Google Maps can't find this address." }) })] })] })] }), isEstimateModalVisible && _jsx(SendEstimateModalBlock, { company: company, setEstimates: setEstimates, adminContext: adminContext, setIsVisible: setIsEstimateModalVisible, estimate: estimate, calculatedEstimate: calculatedEstimate, lead: lead, setSnackbarText: setSnackbarText, stages: stages, setLead: setLead }), _jsx(SendDeclinedModalBlock, { company: company, adminContext: adminContext, isVisible: declineModalVisible, setIsVisible: setDeclineModalVisible, lead: lead, setSnackbarText: setSnackbarText, setLead: setLead, stages: stages }), snackbarText && _jsx(Snackbar, { text: snackbarText, onClose: () => {
                    setSnackbarText(undefined);
                } })] }));
};
